import React from 'react'
import { Award } from 'react-feather'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import MapPicker from 'react-google-map-picker'
import { useState } from 'react';
import {Modal, TimePicker} from 'antd'
import { activeandinactive, CheckUseConsumer, getAccount, getProfilNameByAccountId, InsertNotificationReceiver, Table_Get_All_City_by_State, Table_Get_All_Country, getUsersList, getRolesById,getAll_Active_alarm_template,UpdateNotificationReceiver, getGaById, getCircleById, getSubdevisionById, getDivisionById, getAreaById,onTrrigerCron, get_notification_script } from '../../Commoncomponet/Utils';
import { useEffect } from 'react';
import {toast} from 'react-toastify'
import Select from 'react-select';

import { BootstrapTooltip } from '../../Commoncomponet/common';
import swal from "sweetalert2";
import moment from 'moment';
window.Swal = swal;
const DefaultLocation = { lat: null, lng: null};
const DefaultZoom = 10;
export default function AddNotifyReceiver() {
  const params = useParams();

   const [disable,setDisable]=useState(true)

	const [AccounData,setAccountData]=useState([])
    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
    const [isModalVisible, setIsModalVisible] = useState(false);
const [AccountInput,serAccountInput]=useState({generated_by:'',account:[],Users:[],Median:'',Alarm_Type:[]})
  const [ScheduleDetails,setScheduleDetails]=useState({"schedule_time":'',"schedule_filter":{"CATEGORY0":[],"CATEGORY1":[],"CATEGORY2":[],"CATEGORY3":[],"CATEGORY4":[],"CATEGORY5":[]}})
  const [location, setLocation] = useState(defaultLocation);
  const [gaId, setGaId] = useState('');
  const [Status_notify, setStatus_notify] = useState('');
  const [flagBtn, setflegBtn] = useState(false);

  const [zoom, setZoom] = useState(DefaultZoom);
  const locations=useLocation()
  const history = useHistory();
  const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
  const [UserList,setUserList]=useState([])
  const [AlarmList,setAlarmList]=useState([])
  const [permision,setpermision]=useState({})
  const [SubdevisionData, setSubdevisionData] = useState([]);
  const [DivisionData, setDivisionData] = useState([]);
  const [CircleData, setCircleData] = useState([]);
  const [GaData, setGaData] = useState([]);
  const [AreaData, setAreaData] = useState([]);
  const [ScriptList, setScriptList] = useState([]);

  useEffect(() => {
    if (locations?.state) {
       serAccountInput({account:JSON.parse(locations?.state?.account_unique_id||'[]'),Users:JSON.parse(locations?.state?.users_unique_id||'[]'),Median:locations?.state?.median,Alarm_Type:JSON.parse(locations?.state?.notification_type_id||'[]'),generated_by:locations?.state?.generated_by})
       setScheduleDetails(JSON.parse(locations?.state?.schedule_details||JSON.stringify(ScheduleDetails)))
       setGaId(locations?.state?.unique_id)
       setStatus_notify(locations?.state?.status)
       setDisable(false)

    }
 }, [locations]);
  const handleCancel = () => {
    setIsModalVisible(false);
    setLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
};

  function handleChangeLocation (lat, lng){
    setLocation({lat:lat, lng:lng});
  }
  
  function handleChangeZoom (newZoom){
    setZoom(newZoom);
  }

  function handleResetLocation(){
    setDefaultLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
  }

  const onHandaleSelection=async()=>{
    const data=await getAccount()
    if(data.success===true){
      const options = []
			const optionArray = data?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0||userInfo?.ROLE==='ROLE1650614500708'))
			optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY0 })))
			setAccountData(options);
    }else{
      console.log('somthing want wrong')
    }
  }


  const ongetalarm_template=async()=>{
   const data=await getAll_Active_alarm_template()
   if(data.success==true){
      setAlarmList(data.data.data)
   }else{

   }
 }
  const ongetUsersList = async () => {
   const data = await getUsersList()
   if (data.success === true) {
       console.log("Data = ", data.data.data)
       setUserList(data.data.data)
   } else {
       console.log('something want wrong')
   }
}

const onHandaleRoles = async () => {
   const data = await getRolesById(userInfo?.ROLE)
   if (data.success === true) {
      const obj = data.data.data[0]
      const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4) }
      
      setpermision(Roles)
   } else {
      console.log('Something Went Wrong')
      
   }
}

const onget_notification_script=async()=>{
   const data=await get_notification_script({filter_type:true})
   if(data.success==true){
      setScriptList(data.data.data)
   }else{

   }
}

useEffect(()=>{
   onHandaleSelection()

},[permision])

  useEffect(() => {
   onHandaleRoles()
    ongetUsersList()
    ongetalarm_template()
    onget_notification_script()
  }, []);

  const onSubmitAccountData=async(e)=>{
    e.preventDefault()
    setflegBtn(true)
 if(AccountInput?.Users.length==0||AccountInput?.Median==''||AccountInput?.Alarm_Type?.length==0){
    setflegBtn(false)
   return toast.error('Please Fill Details')
 }
else{
   if(AccountInput?.generated_by=='NOTSRCL_1689681003'){
   if(AccountInput?.account.length==0){
      setflegBtn(false)
     return toast.error('Please Fill Details')
   }
   }
  if(AccountInput?.generated_by=='NOTSRCL_1686842512'){
   {
   if(ScheduleDetails?.schedule_filter?.CATEGORY0?.length==0){
      setflegBtn(false)
     return toast.error('Please Fill Details')
   }
   }
}

   if(AccountInput?.generated_by=='NOTSRCL_1689677802'){
      if(ScheduleDetails?.schedule_time==''||ScheduleDetails?.schedule_filter?.CATEGORY0.length==0){
         setflegBtn(false)
        return toast.error('Please Fill Details')
      }
   }

    if(gaId===''){
 const data=await InsertNotificationReceiver({...AccountInput,add_by:userInfo?.UNIQUE_ID,ScheduleDetails:JSON.stringify(ScheduleDetails)})
 if(data.success===true){
    toast.success(" Success Insert Notification Receiver")
    history.push(`/Notification`)
    setflegBtn(false)
 
 }else{
    toast.error("Something Wait Wrong")
    setflegBtn(false)
 
 }
    }
    else{
       const data=await UpdateNotificationReceiver({...AccountInput,add_by:userInfo?.UNIQUE_ID,ScheduleDetails:JSON.stringify(ScheduleDetails)},gaId)
 if(data.success===true){
    toast.success("Update Sucesss Notification Receiver")
    history.push(`/Notification`)
    setflegBtn(false)
 
 }else{
    toast.error("Something Wait Wrong")
    setflegBtn(false)
 
 }
    }
 }
   }

   const oninactive=async()=>{
     
         swal.fire({
            title: "Are You Sure InActive Receiver?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
         }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'notification_receiver_index',colName:'STATUS',value:'0',matchId:'unique_id'},gaId)
      if(data.success===true){
         if(AccountInput?.generated_by=='NOTSRCL_1689677802'){
         await onTrrigerCron()
         }
         toast.success('Success Inactive Receiver')
         history.push(`/Notification`)
      }else{
         toast.error('Something Went Wrong')
      }
   }
         })
   
     }
     const onactive=async()=>{
      swal.fire({
         title: "Are You Sure Active Receiver?",
         // text: "Once deleted, you will not be able to recover this User Details!",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'notification_receiver_index',colName:'STATUS',value:'1',matchId:'unique_id'},gaId)
      if(data.success===true){
         if(AccountInput?.generated_by=='NOTSRCL_1689677802'){
         await onTrrigerCron()
         }

         toast.success('Success Active Receiver')
         history.push(`/Notification`)
      }else{
         toast.error('Something Went Wrong')
      }
   }
      })
     }
   
     const [NameObj,setNameObj]=useState({})

     const ongetProfilNameByAccountId=async()=>{
        const data=await getProfilNameByAccountId({ID:params.accountId})
        if(data.success===true){
           setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME||'{}'))
        }else{
           console.log('Something Went Wrong')
        }
     }

     const ongetGaById = async (id) => {
		const data1 = await getGaById(id);
		if (data1.success === true) {
			const options = []
			const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => (items === data.CATEGORY1_UNIQUE_ID)).length > 0 ||(ScheduleDetails?.schedule_filter?.CATEGORY0?.includes(data?.CATEGORY0)&&ScheduleDetails?.schedule_filter?.CATEGORY1?.includes(data?.CATEGORY1))|| userInfo?.ROLE === 'ROLE1650614500708'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY1_UNIQUE_ID, label: item?.CATEGORY1_NAME })))
			setGaData(options)
		}
	};

	const ongetCircle = async (id) => {
		const data1 = await getCircleById(id);
		if (data1.success === true) {
			const options = []
			const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => (items === data.CATEGORY2_UNIQUE_ID)).length > 0 ||(ScheduleDetails?.schedule_filter?.CATEGORY0?.includes(data?.CATEGORY0)&&ScheduleDetails?.schedule_filter?.CATEGORY1?.includes(data?.CATEGORY1)&&ScheduleDetails?.schedule_filter?.CATEGORY2?.includes(data?.CATEGORY2))|| userInfo?.ROLE === 'ROLE1650614500708'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY2_UNIQUE_ID, label: item?.CATEGORY2_NAME })))
			setCircleData(options);
		}
	};
	const ongetSubdevisionById = async (id) => {
		const data1 = await getSubdevisionById(id);
		if (data1.success === true) {
			const options = []
			const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter((items) => (items === data.CATEGORY4_UNIQUE_ID)).length > 0 ||(ScheduleDetails?.schedule_filter?.CATEGORY0?.includes(data?.CATEGORY0)&&ScheduleDetails?.schedule_filter?.CATEGORY1?.includes(data?.CATEGORY1)&&ScheduleDetails?.schedule_filter?.CATEGORY2?.includes(data?.CATEGORY2)&&ScheduleDetails?.schedule_filter?.CATEGORY3?.includes(data?.CATEGORY3)) || userInfo?.ROLE === 'ROLE1650614500708'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY4_UNIQUE_ID, label: item?.CATEGORY4_NAME })))
			setSubdevisionData(options);
		}
	};
	const ongetDivisionById = async (id) => {
		const data3 = await getDivisionById(id);
		if (data3.success === true) {
			const options = []
			const optionArray = data3?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => (items === data.CATEGORY3_UNIQUE_ID)).length > 0 ||(ScheduleDetails?.schedule_filter?.CATEGORY0?.includes(data?.CATEGORY0)&&ScheduleDetails?.schedule_filter?.CATEGORY1?.includes(data?.CATEGORY1)&&ScheduleDetails?.schedule_filter?.CATEGORY2?.includes(data?.CATEGORY2)) || userInfo?.ROLE === 'ROLE1650614500708'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY3_UNIQUE_ID, label: item?.CATEGORY3_NAME })))
			setDivisionData(options);
		}
	};
	const ongetArea = async (id) => {
		const data3 = await getAreaById(id);
		if (data3.success === true) {
			const options = []
			const optionArray = data3?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 ||(ScheduleDetails?.schedule_filter?.CATEGORY0?.includes(data?.CATEGORY0)&&ScheduleDetails?.schedule_filter?.CATEGORY1?.includes(data?.CATEGORY1)&&ScheduleDetails?.schedule_filter?.CATEGORY2?.includes(data?.CATEGORY2)&&ScheduleDetails?.schedule_filter?.CATEGORY3?.includes(data?.CATEGORY3)&&ScheduleDetails?.schedule_filter?.CATEGORY4?.includes(data?.CATEGORY4)) || userInfo?.ROLE === 'ROLE1650614500708'));
			optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY5_NAME })))
			setAreaData(options);
		}
	};

	useEffect(() => {
		if (ScheduleDetails?.schedule_filter?.CATEGORY0?.length > 0) {
			ongetGaById(ScheduleDetails?.schedule_filter?.CATEGORY0);
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([])
		} else {
			setGaData([]);
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);
		}
	}, [ScheduleDetails?.schedule_filter.CATEGORY0]);

	useEffect(() => {
		if (ScheduleDetails?.schedule_filter?.CATEGORY1?.length > 0) {
			ongetCircle(ScheduleDetails?.schedule_filter?.CATEGORY1);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);

		} else {
			setCircleData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			setAreaData([]);


		}
	}, [ScheduleDetails?.schedule_filter.CATEGORY1]);

	useEffect(() => {
		if (ScheduleDetails?.schedule_filter?.CATEGORY2?.length > 0) {
			ongetDivisionById(ScheduleDetails?.schedule_filter?.CATEGORY2);
			setAreaData([]);
			setSubdevisionData([]);

		} else {
			setAreaData([]);
			setDivisionData([]);
			setSubdevisionData([]);
		}
	}, [ScheduleDetails?.schedule_filter.CATEGORY2]);

	useEffect(() => {
		if (ScheduleDetails?.schedule_filter?.CATEGORY3?.length > 0) {
			ongetSubdevisionById(ScheduleDetails?.schedule_filter?.CATEGORY3);
			setAreaData([]);
		} else {
			setSubdevisionData([]);
			setAreaData([]);
		}
	}, [ScheduleDetails?.schedule_filter.CATEGORY3]);

	useEffect(() => {
		if (ScheduleDetails?.schedule_filter?.CATEGORY4?.length > 0) {
			ongetArea(ScheduleDetails?.schedule_filter?.CATEGORY4);
		} else {
			setAreaData([]);

		}
	}, [ScheduleDetails?.schedule_filter.CATEGORY4]);
  return (
    <>
	<Modal title="Map" visible={isModalVisible} onOk={()=>(setIsModalVisible(false))} onCancel={handleCancel} width={800}>
    <MapPicker defaultLocation={{ lat: parseFloat(location?.lat||'19.506765954448635'), lng: parseFloat(location?.lng||'76.46875000000001')}}
    zoom={zoom}
    mapTypeId="roadmap"
    style={{height:'500px'}}
    onChangeLocation={handleChangeLocation} 
    onChangeZoom={handleChangeZoom}
    apiKey='AIzaSyCXFhk3O45n6rnm7yMXyN_bAzguizB_GjA'/>
    </Modal>
    <div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-deshboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to='/Notification'>
                            <Award className='header-logo' style={{ height: "16px" }} /> Notification
							</Link>
						</li>
						<li className="breadcrumb-item">
						<Link >
						Add Notification Receiver
						</Link>
					</li>
						
					</ol>
				</nav>
        <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>Add Notification Receiver</h4>
                           <div class="card-header-action">  

                           {gaId!==''?<div class="dropdown">
                                 <button class={`btn mr-2 ${Status_notify == 1 ? 'btn-success' :Status_notify == 0 ? 'btn-danger' : Status_notify == 2 ? 'btn-info' : Status_notify == 3 ? 'btn-dark' : 'btn-warning'} dropdown-toggle`} type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                                    {Status_notify == 1 ? 'Active' : Status_notify == 0 ? 'InActive' : Status_notify == 2 ? 'Under Maintenance' : Status_notify == 3 ? 'Stand By' : 'Pending Configuration'}
                                 </button>
                                 <div class="dropdown-menu !tw-left-[-50px]" aria-labelledby="dropdownMenuButton1">
                                    {Status_notify != 1 ? <a class="dropdown-item text-success" onClick={onactive} >Active</a> : ''}
                                    {Status_notify != 0 ? <a class="dropdown-item text-danger"  onClick={oninactive}>InActive</a> : ''}
                                 </div>
                              </div>:''}
                           {userInfo?.ROLE==='ROLE1650614500708'&&gaId!==''?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:gaId!==''&&locations?.state?.CATEGORY1_edit?.filter((items)=>(items===gaId)).length>0?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										
                              <Link to={`/Notification`} class="btn btn-info text-white">Back</Link>
                           </div>
                        </div>
                        <div class="card-body">
                           <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onSubmitAccountData}>
                              <div class="form-row">
                              <div class="form-group col-md-3">
                                        <label for="inputPassword4">Script:<span class="text-danger">*</span></label>
                                        <select class="form-control form-select" name="tag_color"  required="" onChange={(e)=>{serAccountInput({...AccountInput,generated_by:e.target.value})}} value={AccountInput?.generated_by} disabled={!disable}>
                                        <option value=''>Select Script</option>
                                                      {ScriptList?.map((item)=>(<option value={item?.unique_id}>{item?.title}</option>))}  
                                        </select>
                                    </div>
                            {AccountInput?.generated_by=='NOTSRCL_1689681003'? <div class="form-group col-md-3">
                                    <label for="inputEmail4">Account:<span class="text-danger">*</span></label>
                                    <Select value={AccounData.filter((item) => AccountInput?.account?.includes(item.value)) || []} placeholder='Select account...' name='account' isMulti options={AccounData} onChange={(e) => {
                                       serAccountInput({...AccountInput,account:e.map((item) => (item.value))}) 
									}} isDisabled={!disable}/>
                                   
                                 </div>:''}
                                 {AccountInput?.generated_by!=''? <div class="form-group col-md-3">
                                    <label for="inputPassword4">Users:<span class="text-danger">*</span></label>
                                       {console.log(UserList.filter((item)=>AccountInput?.Users.includes(item?.value)))}
                                    <Select  name='Table' isMulti options={UserList} onChange={(e)=>serAccountInput({ ...AccountInput, Users: e.map((item) => (item.value)) })} value={AccountInput?.Users.length>0?UserList.filter((item)=>AccountInput?.Users.includes(item?.value)):[]} isDisabled={!disable}/>

                                    
                                 </div>:''}
                                 {AccountInput?.generated_by!=''?<div class="form-group col-md-3">
                                    <label for="inputPassword4">Medium:<span class="text-danger">*</span></label>
                                    <select class="form-control form-select" name="accountname" id="accountname" value={AccountInput?.Median} onChange={(e)=>(serAccountInput({...AccountInput,Median:e.target.value}))} disabled={!disable}>
                                    <option value="">Select Medium</option>

                                       <option value="Whatsapp">Whatsapp</option>
                                       <option value="SMS">SMS</option>
                                       <option value="EMAIL">EMAIL</option>
                                                                           </select>
                                 </div>:''}

                                 {AccountInput?.generated_by!=''?<div class="form-group col-md-3">
                                    {console.log(AlarmList.filter((item)=>AccountInput?.Alarm_Type.includes(item?.notification_type_id)).map((item)=>({value:item?.notification_type_id,label:item?.notification_title})))}
                                    <label for="inputPassword4">Alarm Type :<span class="text-danger">*</span></label>
                                    <Select  name='Table' isMulti options={AlarmList.map((item)=>({value:item?.notification_type_id,label:item?.notification_title}))} onChange={(e)=>serAccountInput({ ...AccountInput, Alarm_Type: e.map((item) => (item.value)) })} value={AccountInput?.Alarm_Type.length>0?AlarmList.filter((item)=>AccountInput?.Alarm_Type.includes(item?.notification_type_id)).map((item)=>({value:item?.notification_type_id,label:item?.notification_title})):[]} isDisabled={!disable}/>
                                 </div>:''}

                                 {AccountInput?.generated_by=='NOTSRCL_1689677802'?<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Schedule Time:<span className='text-danger'>*</span> </label>
											<TimePicker format="HH:mm" name='start' style={{ width: '100%', height: '40px' }}
												showTime
												onChange={(e, dateString)=>(setScheduleDetails({...ScheduleDetails,schedule_time:dateString}))}
                                    value={ScheduleDetails?.schedule_time?moment(ScheduleDetails?.schedule_time,'HH:mm'):''}
                                    disabled={!disable}/>
										</div>:''}

                              {AccountInput?.generated_by=='NOTSRCL_1689677802'||AccountInput?.generated_by=='NOTSRCL_1686842512'? <div class="form-group col-md-3">
                                    <label for="inputEmail4">Account:<span class="text-danger">*</span></label>
                                    <Select value={AccounData.filter((item) => ScheduleDetails?.schedule_filter?.CATEGORY0?.includes(item.value)) || []} placeholder='Select account...' name='account' isMulti options={AccounData} onChange={(e) => {
                                       setScheduleDetails({...ScheduleDetails,schedule_filter:{...ScheduleDetails.schedule_filter,CATEGORY0:e.map((item) => (item.value))}})
									}} isDisabled={!disable}/>
                                 </div>:''}
                                 {AccountInput?.generated_by=='NOTSRCL_1689677802'||AccountInput?.generated_by=='NOTSRCL_1686842512'? <div class="form-group col-md-3">
                                    <label for="inputEmail4">Category1:</label>
                                    <Select value={GaData.filter((item) => ScheduleDetails?.schedule_filter?.CATEGORY1?.includes(item.value)) || []} placeholder='Select account...' name='account' isMulti options={GaData} onChange={(e) => {
                                       setScheduleDetails({...ScheduleDetails,schedule_filter:{...ScheduleDetails.schedule_filter,CATEGORY1:e.map((item) => (item.value))}})
									}} isDisabled={!disable}/>
                                 </div>:''}
                                 {AccountInput?.generated_by=='NOTSRCL_1689677802'||AccountInput?.generated_by=='NOTSRCL_1686842512'? <div class="form-group col-md-3">
                                    <label for="inputEmail4">Category2:</label>
                                    <Select value={CircleData.filter((item) => ScheduleDetails?.schedule_filter?.CATEGORY2?.includes(item.value)) || []} placeholder='Select account...' name='account' isMulti options={CircleData} onChange={(e) => {
                                       setScheduleDetails({...ScheduleDetails,schedule_filter:{...ScheduleDetails.schedule_filter,CATEGORY2:e.map((item) => (item.value))}})
									}} isDisabled={!disable}/>
                                 </div>:''}
                                 {AccountInput?.generated_by=='NOTSRCL_1689677802'||AccountInput?.generated_by=='NOTSRCL_1686842512'? <div class="form-group col-md-3">
                                    <label for="inputEmail4">Category3:</label>
                                    <Select value={DivisionData.filter((item) => ScheduleDetails?.schedule_filter?.CATEGORY3?.includes(item.value)) || []} placeholder='Select account...' name='account' isMulti options={DivisionData} onChange={(e) => {
                                       setScheduleDetails({...ScheduleDetails,schedule_filter:{...ScheduleDetails.schedule_filter,CATEGORY3:e.map((item) => (item.value))}})
									}} isDisabled={!disable}/>
                                 </div>:''}
                                 {AccountInput?.generated_by=='NOTSRCL_1689677802'||AccountInput?.generated_by=='NOTSRCL_1686842512'? <div class="form-group col-md-3">
                                    <label for="inputEmail4">Category4:</label>
                                    <Select value={SubdevisionData.filter((item) => ScheduleDetails?.schedule_filter?.CATEGORY4?.includes(item.value)) || []} placeholder='Select account...' name='account' isMulti options={SubdevisionData} onChange={(e) => {
                                       setScheduleDetails({...ScheduleDetails,schedule_filter:{...ScheduleDetails.schedule_filter,CATEGORY4:e.map((item) => (item.value))}})
									}} isDisabled={!disable}/>
                           
                                 </div>:''}
                                 {AccountInput?.generated_by=='NOTSRCL_1689677802'? <div class="form-group col-md-3">
                                    <label for="inputEmail4">Category5:</label>
                                    <Select value={AreaData.filter((item) => ScheduleDetails?.schedule_filter?.CATEGORY5?.includes(item.value)) || []} placeholder='Select account...' name='account' isMulti options={AreaData} onChange={(e) => {
                                       setScheduleDetails({...ScheduleDetails,schedule_filter:{...ScheduleDetails.schedule_filter,CATEGORY5:e.map((item) => (item.value))}})
									}} isDisabled={!disable}/>
                           
                                 </div>:''}
                              </div>
                              
                              <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                           <div class="form-group" style={{marginTop:"20px"}}>
                              <div class="text-left">
                                  <input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={gaId===''?'Submit':'Update'} id="submitvn" />
                                    
                                    <Link class={`btn btn-light text-dark ${flagBtn?'disabled':''}`} to={`/Notification`}>Cancel</Link>  
                              </div>
                           </div>
                           </form></div>
                     </div>
                  </div>
               </div>
            </div>
      </section>
      </div>
    </>
  )
}
