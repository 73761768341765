import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Flag, Users } from "react-feather";
import { BootstrapTooltip } from "../../Commoncomponet/common";
// import { getRolesUserData } from "../Apicommon";
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable"
import dt from "datatables.net-dt";
import { CSVLink } from "react-csv";
import { Modal } from "antd";
import { getAllRoles } from "../../Commoncomponet/Utils";
import moment from "moment";
import swal from 'sweetalert2';
window.Swal = swal;
$.fn.DataTable = dt;


export default function Roles() {
	const [rolesData, setRolesData] = useState([])
	const [filterrolesData, setfilterRolesData] = useState([])
	const [loader, setLoader] = useState(false)
	const history = useHistory()
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [roleFilter, setRoleFilter] = useState({ name: '', status: null })
	const [csvData, setCsvData] = useState([])
	const profiledata=JSON.parse(window.localStorage.getItem('user_info'))||{}

	const ongetRolesUserData = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getAllRoles(profiledata?.ROLE=='ROLE1650614500708'?false:true,profiledata?.ROLE,profiledata?.UNIQUE_ID)
		if (data.success === true) {
			setRolesData(data.data.data)
			setfilterRolesData(data.data.data)
		} else {
			console.log('somthing want wrong')
			setLoader(false)
		}
	}
	const csvLink = useRef();

	const downloadReport = async (isCsv) => {
		if (rolesData.length > 0) {
			const finalCsvData = await rolesData.map((item) => ({ 'SRNO': item.SRNO, 'Unique ID': item.ROLES_UNIQUE_ID, 'Created Time': moment(item.ROLES_CREATED_AT).format('DD-MM-YYYY'), 'Created By': item.ROLES_CREATED_BY, 'Role Name': item.ROLES_NAME, 'Status': (item.ROLES_STATUS === 1 ? 'Active' : 'InActive') }))
			setCsvData(finalCsvData)
			if (isCsv === true) {
				if (finalCsvData.length > 0) {
					csvLink.current.link.click();
				}
			}
			else {
				if (finalCsvData.length > 0) {
					const worksheet = XLSX.utils.json_to_sheet(finalCsvData);
					const workbook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
					XLSX.writeFile(workbook, `Roles-list.xlsx`);
				}
			}
		}
	}

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onhandleOk = () => {
		setIsModalVisible(false)
		if (roleFilter.name === '' && roleFilter.status === '') {
			return setRolesData(filterrolesData)
		}
		const filteData = filterrolesData && filterrolesData.filter((item) => (roleFilter.name !== '' ? item?.ROLES_NAME?.includes(roleFilter.name) : []) && (roleFilter.status !== '' ? item?.ROLES_STATUS == roleFilter.status : []))
		setRolesData(filteData)
	}

	const onChanegeHandale = (e) => {
		setRoleFilter({ ...roleFilter, name: e.target.value })
	}

	useEffect(() => {
		ongetRolesUserData().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({ "pageLength": 50, order: [[0, 'desc']] });
				setLoader(false);

			}, 700)
		);
	}, []);

	const exportPDF = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(15);

		const title = ` Roles and Users  Data`;
		const headers = [["Unique No", "Roles Name"]];

		const data = filterrolesData && filterrolesData.map(elt => [elt.ID, elt.ROLES_NAME]);

		let content = {
			startY: 50,
			head: headers,
			body: data
		};

		doc.text(title, marginLeft, 40);
		doc.autoTable(content);
		doc.save(`RolesUser.pdf`)
	}

	const reportDownload = async (isCsv) => {
		const worksheet = XLSX.utils.json_to_sheet(filterrolesData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
		//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
		XLSX.writeFile(workbook, "RolesSheet.xlsx");

	}

	const UpdateRolesUser = (item) => {
		history.push({ pathname: `/add-roles`, state: item })
	}

	return (
		<>
			<Modal title="Filter Roles" visible={isModalVisible} footer="" onCancel={handleCancel}>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputPassword4">Role Name:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={onChanegeHandale}
							value={roleFilter.name}
						>
							<option value="">Select Role</option>
							{filterrolesData && filterrolesData.map((item) => (<option value={item.ROLES_NAME}>{item.ROLES_NAME}</option>))}
						</select>
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">Role Status:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={(e) => (setRoleFilter({ ...roleFilter, status: e.target.value }))}
							value={roleFilter.status}
						>
							<option value="">Select Status</option>
							<option value={1}>Active</option>
							<option value={0}>InActive</option>
						</select>
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to={`/Roles`}>
						Reset
					</Link>
				</div>
			</Modal>
			<div className="main-content">
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/roles-users">
								<Users style={{ height: "16px" }} /> Roles & Users
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/roles-users">
								Roles
							</Link>
						</li>
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h4><span className="mr-2">Roles</span><BootstrapTooltip title={`Table Name is roles_index_dev`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div className="card-header-action">
											<Link to="/add-Roles" className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
											<button type="button" className="btn btn-info mr-2" onClick={() => (setIsModalVisible(true))}>
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											<div className="dropdown d-inline mr-2">
												<button
													className="btn btn-info dropdown-toggle mr-2"
													type="button"
													id="dropdownMenuButton3"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													data-placement="bottom"
													title="Download"
												>
													<i className="fa fa-download mr-1"></i>
												</button>
												<div className="dropdown-menu">
													<a className="dropdown-item" onClick={exportPDF}>
														PDF
													</a>
													<a onClick={() => downloadReport(false)} className="dropdown-item" >
														Excel
													</a>
													<a onClick={() => downloadReport(true)} className="dropdown-item">
														CSV
													</a>
													<CSVLink data={csvData} filename={"Roles-list.csv"} ref={csvLink} />
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table border stripe" id="table-1">
												<thead>
													<tr>
														<th className="text-center !tw-font-semibold" style={{ maxWidth: "100px" }}>Unique No</th>
														<th className="!tw-font-semibold" >Roles Name</th>
														<th className="!tw-font-semibold" >Created By</th>
														<th className="!tw-font-semibold" style={{ maxWidth: "100px" }}>Status</th>

													</tr>
												</thead>
												<tbody>
													{rolesData &&
														rolesData.map((item, id) => (
															<tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id} onClick={() => (item.ROLES_UNIQUE_ID === 'ROLE1650614500708' ? swal.fire('', 'Sorry, predefined roles cannot be edited.', 'error'): UpdateRolesUser(item))}>
																<td className="text-center">{item.SRNO}</td>
																<td>{item.ROLES_NAME}</td>
																<td>{item.CREATED_BY_NAME}</td>
																{item.ROLES_STATUS === 1 ? <td>
																	<div className="badge badge-success badge-shadow text-white">Active</div>
																</td> : <td>
																	<div className="badge badge-danger badge-shadow text-white">Inactive</div>
																</td>}
															</tr>
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
