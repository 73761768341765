import React, { useEffect, useState } from 'react'
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader'
import classNames from 'classnames';
import { getTaskTag, getUsersByGroups, getAllTaskAnalytics, getMyStatusAnalytics, getDueDateWiseAnalytics, getTaskAssignedToMeAnalytics } from '../../Commoncomponet/Utils'
import { Collapse } from 'antd';
import { DashboardChart } from './DashboardChart';
import { LuCalendarCheck2, LuCalendarDays, LuCalendarClock, LuEyeOff, LuTag } from "react-icons/lu";
import { LiaRedoSolid } from "react-icons/lia";



const Svgcard = ({ svgIllustration, numbers, title, color, onClick, borderColor }) => {
  return (
    <div onClick={onClick} className={classNames('tw-flex  tw-flex-col tw-mb-1 tw-relative !tw-border-b-8 tw-border tw-border-solid tw-rounded-lg tw-py-0.5 tw-cursor-pointer', color)} style={{ borderColor: borderColor }}>
      <div className='tw-flex tw-justify-between tw-items-center'>

        <div className='tw-ml-6 tw-text-start'>
          <h3 className='tw-mb-0'>{numbers ? numbers : 0}</h3>
          <span className='tw-text-sm tw-text-gray-600 tw-cursor-pointer'>
            {title}
          </span>
        </div>
        <div className='tw-h-16 tw-w-16 tw-m-1 tw-mr-4 tw-float-right tw-flex tw-justify-center tw-items-center tw-rounded-full '>
          {svgIllustration}
        </div>
      </div>
    </div>
  )
}

const TaskFilterButton = ({ label, count, onClick, mainStyle, countStyle, countinlinestyle = {}, mainInlineStyle = {}, icon }) => (
  <div style={{ ...mainInlineStyle, borderBottomStyle: 'dotted', borderBottomWidth: '1px' }}
    className={`tw-justify-center tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 tw-flex tw-gap-2 tw-text-base tw-items-center hover:tw-rounded tw-p-2 tw-cursor-pointer tw-duration-150 ${mainStyle ? mainStyle : ""} `} onClick={onClick}>
    <div className='' >{icon}</div>
    <label className="tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70 tw-mb-0">{label}</label>
    <div style={countinlinestyle} className={`tw-ml-auto tw-text-white tw-cursor-pointer tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-xs tw-font-semibold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1 ${countStyle ? countStyle : ""}`}>{count || 0}</div>
  </div>
);

const CardBody = ({ children, CardTitle, isBodyClass = true, className, bodyClassName }) => {
  return (
    <div className={`tw-text-card-foreground tw-shadow-md tw-w-full tw-my-4 tw-py-2 border tw-bg-card tw-rounded ${className ?? className}`} style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 5px 10px 0px" }} >
      <div className='tw-flex tw-flex-col tw-space-y-1.5' >
        <h5 className='tw-font-semibold tw-leading-none tw-tracking-tight border-bottom tw-px-2 tw-py-3 tw-mb-0' >
          {CardTitle}
        </h5>
      </div>
      <div className={`${isBodyClass ? "tw-grid tw-gap-1 tw-px-6 tw-pb-4" : ""} ${bodyClassName ?? bodyClassName}`} >
        {children}
      </div>
    </div>
  )
}

const TaskCountCard = ({ className, title, count, onClick }) => {
  return (
    <div onClick={onClick} className={`tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-2 tw-border tw-border-solid tw-rounded-lg tw-cursor-pointer ${className ?? null}`} >
      <div className='tw-mb-0 tw-font-semibold tw-text-base' >{title}</div>
      <div className='tw-text-xl tw-font-semibold tw-text-gray-800 tw-mb-0'>{count}</div>
    </div>
  )
}

const MyTaskDashboard = ({ setDefaultTab, setstoredFilterS, setstoredFilterType, setStoredFilter }) => {
  const options = [
    { id: 1, name: 'My Tasks' },
    { id: 2, name: 'All Tasks' }
  ]
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [chartdata, setchartdata] = useState([]);
  const [userDropdown, setUserDropdown] = useState(options[1]);
  const [userList, setuserList] = useState([]);
  const [TaskTags, setTaskTags] = useState([]);
  const [DueDateTable, setDueDateTable] = useState([]);
  const [TaskAssignedToMeAnalytics, setTaskAssignedToMeAnalytics] = useState([])
  const [isShowAllTags, setIsShowAllTags] = useState(false);

  let userInfo = JSON.parse(window.localStorage.getItem("user_info"));

  const baseFilterObj = {
    taskTitle: { type: "is", values: "" }, taskAssignTo: { type: "is", values: [userInfo.UNIQUE_ID] }, taskConsumerType: { type: "is", values: [] }, taskStatus: { type: "is", values: [] }, taskPriority: { type: "is", values: [] },
    taskType: { type: "is", values: [] }, taskTags: { type: "is", values: [] }, overdue: false, todays: false, taskCreatedBy: { type: "is", values: [] }, Plan: { type: "is", values: [] },
    taskAssignByMe: { "type": "is", "values": [] }, taskAssignToMe: { "type": "is", "values": '' },
  }

  const ongetUsersByGroups = async () => {
    setIsLoading(true)
    const data = await getUsersByGroups(userInfo.UNIQUE_ID)
    if (data.success == true) {
      setuserList(data?.data?.users?.map(item => item?.user_id))
    } else {

    }
  }

  const ongetMyStatusAnalytics = async () => {
    const data = await getMyStatusAnalytics({ user_id: userInfo.UNIQUE_ID });
    if (data.success == true) {
      setchartdata(data?.data?.analytics)
    } else {

    }
  }

  const ongetTaskList = async () => {

    setIsLoading(true)
    let data;
    if (userDropdown?.id == 1) {
      data = await getAllTaskAnalytics({ filters: { taskAssignToMe: userInfo.UNIQUE_ID }, ROLE: userInfo.ROLE })
    } else {
      data = await getAllTaskAnalytics({ filters: { taskAssignToMe: userInfo.UNIQUE_ID }, ROLE: userInfo.ROLE })
    }

    if (data.success === true) {
      console.log("getAllTaskDetails = ", data.data.data)
      setTasks(data.data.analytics)
      setIsLoading(false)
    } else {
      setIsLoading(false)
      console.log('something went wrong')
    }
  }

  useEffect(() => {
    if (userList.length > 0 || userInfo?.ROLE === 'ROLE1650614500708') {
      ongetTaskList()
      ongetDueDateanalytics()
    } else {
      setIsLoading(false)
    }
  }, [userDropdown, userList, TaskTags])

  const ongetDueDateanalytics = async () => {
    const data = await getDueDateWiseAnalytics({ user_id: userInfo.UNIQUE_ID })
    if (data.success === true) {
      console.log(data.data)
      setDueDateTable(data.data?.individualAnalytics)
    } else {
      console.log('something went wrong')
    }
  }

  const getTaskAssignedToMeAnalytic = async () => {
    const data = await getTaskAssignedToMeAnalytics({ filters: { taskAssignToMe: userInfo.UNIQUE_ID } });
    if (data.success === true) {
      setTaskAssignedToMeAnalytics(data.data.analytics)
    } else {
      console.log('something went wrong')
    }
  }

  useEffect(() => {
    ongetUsersByGroups()
    ongetTaskTags()
    ongetMyStatusAnalytics();
    getTaskAssignedToMeAnalytic();
  }, [])

  const ongetTaskTags = async () => {
    const data = await getTaskTag(true)
    if (data.success === true) {
      setTaskTags(data.data.data.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME, color: item?.TAG_COLOR, TYPE: item?.TAG_TYPE })))
    } else {
      console.log('Error in getting task tags', data.err)
    }
  }

  const onHandalTabFilter = (filter) => {
    console.log(filter)
    setstoredFilterS({ ...filter, DefaultTab: 'tab2' })
    setDefaultTab('tab2')
    setstoredFilterType('')
    setStoredFilter(filter)
  }

  const { Panel } = Collapse;
  const tagsWithCountGreaterThanZero = tasks?.custom_tags?.filter(tag => tag.count > 0) || [];
  const tagsWithCountZero = tasks?.custom_tags?.filter(tag => tag.count === 0) || [];

  const allTags = [...tagsWithCountGreaterThanZero, ...tagsWithCountZero];

  return (
    <div className='tw-my-2' >
      <div class=" tw-p-1 tw-px-4">
        <div className="">
          {isLoading ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
            <GoogleLoader bodyClassName={'tw-h-[70%]'} />
          </div>
            : <>

              <div className='tw-my-1'>

                <Collapse expandIconPosition={"end"} className='!tw-rounded-md ' style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 5px 10px 0px" }}>
                  <Panel header={<span className='!tw-font-medium !tw-text-lg !tw-text-gray-800'>Assigned To Me</span>} key="1" className='tw-bg-white !tw-rounded-md '>
                    <div className=' tw-overflow-y-auto !tw-text-base'>
                      <table className='!tw-w-full'>
                        <thead>
                          <tr class="border !tw-bg-gray-100">
                            <th class="!tw-px-4 !tw-py-2 !tw-text-gray-800 border tw-w-1/9">Assigned By</th>
                            <th class="!tw-px-4 !tw-py-2 !tw-text-gray-800 border tw-w-1/9">Open</th>
                            <th class="!tw-px-4 !tw-py-2 !tw-text-gray-800 border tw-w-1/9">Working On It</th>
                            <th class="!tw-px-4 !tw-py-2 !tw-text-gray-800 border tw-w-1/9">In Review</th>
                            <th class="!tw-px-4 !tw-py-2 !tw-text-gray-800 border tw-w-1/9">On Hold</th>
                            <th class="!tw-px-4 !tw-py-2 !tw-text-gray-800 border tw-w-1/9">All Pending</th>
                            <th class="!tw-px-4 !tw-py-2 !tw-text-gray-800 border tw-w-1/9">Completed</th>
                            <th class="!tw-px-4 !tw-py-2 !tw-text-gray-800 border tw-w-1/9">Cancelled</th>
                            <th class="!tw-px-4 !tw-py-2 !tw-text-gray-800 border tw-w-1/9">All Closed</th>
                            <th class="!tw-px-4 !tw-py-2 !tw-text-gray-800 border tw-w-1/9">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {TaskAssignedToMeAnalytics.length > 0 && TaskAssignedToMeAnalytics?.map((data, index) => {
                            return data?.assigned_by_name ? (
                              <tr key={index} className="tw-border" >
                                <td onClick={() => onHandalTabFilter({ ...baseFilterObj, taskAssignTo: { type: "is", values: [userInfo?.UNIQUE_ID] }, taskAssignByMe: { "type": "is", "values": [data?.TASK_ASSIGN_BY] }, taskStatus: { type: "is", values: ['-1', '0', '2', '3'] } })} className={`tw-px-4 tw-py-2 tw-border tw-cursor-pointer ${data.TASK_ASSIGN_BY === userInfo?.UNIQUE_ID ? 'tw-text-blue-600 tw-bg-indigo-50 hover:tw-bg-indigo-100' : 'tw-text-blue-500 hover:tw-bg-indigo-50'} `}>{data?.assigned_by_name}</td>

                                <td onClick={() => onHandalTabFilter({ ...baseFilterObj, taskAssignTo: { type: "is", values: [userInfo?.UNIQUE_ID] }, taskAssignByMe: { "type": "is", "values": [data?.TASK_ASSIGN_BY] }, taskStatus: { type: "is", values: ['-1'] } })} className={`tw-px-4 tw-py-2 tw-border tw-cursor-pointer ${data.TASK_ASSIGN_BY === userInfo?.UNIQUE_ID ? 'tw-text-blue-600 tw-bg-indigo-50 hover:tw-bg-indigo-100' : 'tw-text-blue-500 hover:tw-bg-indigo-50'} `}>{data?.OPEN_TASK_COUNT}</td>

                                <td onClick={() => onHandalTabFilter({ ...baseFilterObj, taskAssignTo: { type: "is", values: [userInfo?.UNIQUE_ID] }, taskAssignByMe: { "type": "is", "values": [data?.TASK_ASSIGN_BY] }, taskStatus: { type: "is", values: ['0'] } })} className={`tw-px-4 tw-py-2 tw-border tw-cursor-pointer ${data.TASK_ASSIGN_BY === userInfo?.UNIQUE_ID ? 'tw-text-blue-600 tw-bg-indigo-50 hover:tw-bg-indigo-100' : 'tw-text-blue-500 hover:tw-bg-indigo-50'} `}>{data?.WORKING_ON_IT_TASK_COUNT}</td>

                                <td onClick={() => onHandalTabFilter({ ...baseFilterObj, taskAssignTo: { type: "is", values: [userInfo?.UNIQUE_ID] }, taskAssignByMe: { "type": "is", "values": [data?.TASK_ASSIGN_BY] }, taskStatus: { type: "is", values: ['2'] } })} className={`tw-px-4 tw-py-2 tw-border tw-cursor-pointer ${data.TASK_ASSIGN_BY === userInfo?.UNIQUE_ID ? 'tw-text-blue-600 tw-bg-indigo-50 hover:tw-bg-indigo-100' : 'tw-text-blue-500 hover:tw-bg-indigo-50'} `}>{data?.IN_REVIEW_TASK_COUNT}</td>

                                <td onClick={() => onHandalTabFilter({ ...baseFilterObj, taskAssignTo: { type: "is", values: [userInfo?.UNIQUE_ID] }, taskAssignByMe: { "type": "is", "values": [data?.TASK_ASSIGN_BY] }, taskStatus: { type: "is", values: ['3'] } })} className={`tw-px-4 tw-py-2 tw-border tw-cursor-pointer ${data.TASK_ASSIGN_BY === userInfo?.UNIQUE_ID ? 'tw-text-blue-600 tw-bg-indigo-50 hover:tw-bg-indigo-100' : 'tw-text-blue-500 hover:tw-bg-indigo-50'} `}>{data?.ON_HOLD_TASK_COUNT}</td>

                                <td onClick={() => onHandalTabFilter({ ...baseFilterObj, taskAssignTo: { type: "is", values: [userInfo?.UNIQUE_ID] }, taskAssignByMe: { "type": "is", "values": [data?.TASK_ASSIGN_BY] }, taskStatus: { type: "is", values: ['-1', '0', '2', '3'] } })} className={`tw-px-4 tw-py-2 tw-border tw-cursor-pointer ${data.TASK_ASSIGN_BY === userInfo?.UNIQUE_ID ? 'tw-text-blue-600 tw-bg-indigo-50 hover:tw-bg-indigo-100' : 'tw-text-blue-500 hover:tw-bg-indigo-50'} `}>{data?.TOTAL_OPEN_TASKS}</td>

                                <td onClick={() => onHandalTabFilter({ ...baseFilterObj, taskAssignTo: { type: "is", values: [userInfo?.UNIQUE_ID] }, taskAssignByMe: { "type": "is", "values": [data?.TASK_ASSIGN_BY] }, taskStatus: { type: "is", values: ['4'] } })} className={`tw-px-4 tw-py-2 tw-border tw-cursor-pointer ${data.TASK_ASSIGN_BY === userInfo?.UNIQUE_ID ? 'tw-text-blue-600 tw-bg-indigo-50 hover:tw-bg-indigo-100' : 'tw-text-blue-500 hover:tw-bg-indigo-50'} `}>{data?.COMPLETE_TASK_COUNT}</td>

                                <td onClick={() => onHandalTabFilter({ ...baseFilterObj, taskAssignTo: { type: "is", values: [userInfo?.UNIQUE_ID] }, taskAssignByMe: { "type": "is", "values": [data?.TASK_ASSIGN_BY] }, taskStatus: { type: "is", values: ['1'] } })} className={`tw-px-4 tw-py-2 tw-border tw-cursor-pointer ${data.TASK_ASSIGN_BY === userInfo?.UNIQUE_ID ? 'tw-text-blue-600 tw-bg-indigo-50 hover:tw-bg-indigo-100' : 'tw-text-blue-500 hover:tw-bg-indigo-50'} `}>{data?.CANCEL_TASK_COUNT}</td>

                                <td onClick={() => onHandalTabFilter({ ...baseFilterObj, taskAssignTo: { type: "is", values: [userInfo?.UNIQUE_ID] }, taskAssignByMe: { "type": "is", "values": [data?.TASK_ASSIGN_BY] }, taskStatus: { type: "is", values: ['4', '1'] } })} className={`tw-px-4 tw-py-2 tw-border tw-cursor-pointer ${data.TASK_ASSIGN_BY === userInfo?.UNIQUE_ID ? 'tw-text-blue-600 tw-bg-indigo-50 hover:tw-bg-indigo-100' : 'tw-text-blue-500 hover:tw-bg-indigo-50'} `}>{data?.TOTAL_CLOSE_TASKS}</td>

                                <td onClick={() => onHandalTabFilter({ ...baseFilterObj, taskAssignTo: { type: "is", values: [userInfo?.UNIQUE_ID] }, taskAssignByMe: { "type": "is", "values": [data?.TASK_ASSIGN_BY] } })} className={`tw-px-4 tw-py-2 tw-border tw-cursor-pointer ${data.TASK_ASSIGN_BY === userInfo?.UNIQUE_ID ? 'tw-text-blue-600 tw-bg-indigo-50 hover:tw-bg-indigo-100' : 'tw-text-blue-500 hover:tw-bg-indigo-50'} `}>{data?.TOTAL_TASK_COUNT}</td>
                              </tr>
                            ) : null
                          })}
                        </tbody>
                      </table>

                    </div>
                  </Panel>
                </Collapse>

                <div className='tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-2 tw-my-2' >
                  <CardBody CardTitle='All Planned Analytics' >
                    <>
                      <TaskFilterButton
                        icon={<LuCalendarCheck2 />}
                        label="Today"
                        count={tasks?.today_tasks}
                        onClick={() => (onHandalTabFilter({ ...baseFilterObj, Plan: { type: "is", values: [1] }, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } }))}
                        mainStyle="!tw-text-red-600 hover:tw-bg-red-50 !tw-border-b-red-400"
                        countStyle="tw-bg-red-500"
                      />
                      <TaskFilterButton
                        icon={<LuCalendarDays />}
                        label="This week"
                        count={tasks?.this_week_tasks}
                        onClick={() => (onHandalTabFilter({ ...baseFilterObj, Plan: { type: "is", values: [0] }, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } }))}
                        mainStyle="!tw-text-yellow-600 hover:tw-bg-yellow-50 !tw-border-b-yellow-400"
                        countStyle="tw-bg-yellow-500"
                      />
                      <TaskFilterButton
                        icon={<LuCalendarClock />}
                        label="Yet to plan"
                        count={tasks?.yet_to_plan_tasks}
                        onClick={() => (onHandalTabFilter({ ...baseFilterObj, Plan: { type: "is", values: [-1] }, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } }))}
                        mainStyle="!tw-text-green-600 hover:tw-bg-green-50 !tw-border-b-green-400"
                        countStyle="tw-bg-green-500"
                      />
                    </>
                  </CardBody>
                  <CardBody CardTitle='Unread Analytics' >
                    <>
                      <TaskFilterButton
                        icon={<LuEyeOff />}
                        label="Unread"
                        count={tasks?.unread}
                        onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] }, UnreadOnly: true }))}
                        mainStyle="!tw-text-red-600 hover:tw-bg-red-50 !tw-border-b-red-400"
                        countStyle="tw-bg-red-500"
                      />
                    </>
                  </CardBody>
                  <CardBody CardTitle='Predefined Tags Analytics' >
                    {tasks?.predefined_tags?.map((tag, index) => {
                      return <TaskFilterButton
                        key={index}
                        icon={<LuTag />}
                        label={tag.TAG_NAME}
                        count={tag.count}
                        onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskTags: { type: "is", values: [tag?.UNIQUE_ID] }, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } }))}
                        mainStyle="hover:tw-bg-gray-50"
                        mainInlineStyle={{ borderBottom: `1px dotted ${tag?.TAG_COLOR}`, borderRadius: '2px', color: tag?.TAG_COLOR }}
                        countinlinestyle={{ backgroundColor: tag.TAG_COLOR }}
                      />
                    })}
                  </CardBody>

                </div>

                <div className='tw-grid tw-items-stretch md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-2' >
                  <CardBody
                    CardTitle='Task Count'
                    className={'!tw-mt-0'}
                    isBodyClass={false}
                  >
                    <div className='tw-grid tw-p-2 tw-divide-y' >
                      <div className='border-bottom tw-pb-2' >
                        <TaskCountCard
                          className='tw-border-gray-200 hover:tw-border-gray-400 tw-bg-gray-100'
                          title='All Task'
                          count={tasks?.total_tasks}
                          onClick={() => (onHandalTabFilter({ ...baseFilterObj }))}
                        />
                      </div>
                    </div>

                    <div className='tw-flex tw-w-full tw-divide-x md:tw-gap-3 tw-gap-2  tw-py-2 md:tw-px-3 tw-px-2' >
                      <div className='tw-grid tw-grid-cols-2 tw-gap-2 tw-w-1/2 tw-h-fit border-right md:tw-pr-3 tw-pr-2' >
                        <TaskCountCard
                          className='tw-border-indigo-200 tw-col-span-2  hover:tw-border-indigo-400 tw-bg-indigo-100'
                          title='All Pendding'
                          count={tasks?.total_open_tasks || 0}
                          onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } }))}
                        />
                        <TaskCountCard
                          className='tw-border-green-200 hover:tw-border-green-400 tw-bg-green-100 md:tw-col-span-1 tw-col-span-2'
                          title='Open'
                          count={tasks?.open_tasks || 0}
                          onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskStatus: { type: "is", values: ['-1'] } }))}
                        />
                        <TaskCountCard
                          className='tw-border-sky-200 hover:tw-border-sky-400 tw-bg-sky-100 md:tw-col-span-1 tw-col-span-2'
                          title='Working On it'
                          count={tasks?.in_progress_tasks || 0}
                          onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskStatus: { type: "is", values: ['0'] } }))}
                        />
                        <TaskCountCard
                          className='tw-border-cyan-200 hover:tw-border-cyan-400 tw-bg-cyan-100 md:tw-col-span-1 tw-col-span-2'
                          title='in Review'
                          count={tasks?.in_review_tasks || 0}
                          onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskStatus: { type: "is", values: ['2'] } }))}
                        />
                        <TaskCountCard
                          className='tw-border-yellow-200 hover:tw-border-yellow-400 tw-bg-yellow-100 md:tw-col-span-1 tw-col-span-2'
                          title='On Hold'
                          count={tasks?.on_hold_tasks || 0}
                          onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskStatus: { type: "is", values: ['3'] } }))}
                        />
                      </div>
                      <div className='tw-grid tw-grid-cols-2 tw-gap-2 tw-w-1/2 tw-h-fit' >
                        <TaskCountCard
                          className='tw-border-orange-200 hover:tw-border-orange-400 tw-bg-orange-100 md:tw-col-span-1 tw-col-span-2'
                          title='All Close'
                          count={tasks?.total_close_tasks || 0}
                          onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskStatus: { type: "is", values: ['4', '1'] } }))}
                        />
                        <TaskCountCard
                          className='md:tw-col-span-1 tw-col-span-2 tw-border-[#f16965]/40 hover:tw-border-[#f16965]/70  tw-bg-[#f16965]/30'
                          title='Complete'
                          count={tasks?.completed_tasks || 0}
                          onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskStatus: { type: "is", values: ['1'] } }))}
                        />
                        <TaskCountCard
                          className='md:tw-col-span-1 tw-col-span-2 tw-border-[#5a8dc8]/40 hover:tw-border-[#5a8dc8]/70 tw-bg-[#5a8dc8]/30'
                          title='Cancelled'
                          count={tasks?.cancelled_tasks || 0}
                          onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskStatus: { type: "is", values: ['4'] } }))}
                        />
                      </div>
                    </div>
                  </CardBody>

                  <CardBody
                    CardTitle='Duedate Analytics'
                    className={'!tw-mt-0'}
                    bodyClassName={' tw-py-2 md:tw-px-6 tw-px-3 tw-w-full md:tw-p-2 tw-font-semibold 2xl:tw-max-h-[340px] lg:tw-max-h-[380px] md:tw-max-h-[380px] tw-overflow-auto'}
                  >
                    <>
                      <TaskFilterButton
                        label="Past More then 45 days"
                        count={DueDateTable[0]?.PAST_ABOVE_45_DAYS}
                        onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: -4, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
                        mainStyle="tw-text-red-700 hover:tw-bg-red-50 tw-border-b-red-700"
                        countStyle="tw-bg-red-800"
                      />
                      <TaskFilterButton
                        label="Past 31-45 Days"
                        count={DueDateTable[0]?.PAST_31_45_Days}
                        onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: -3, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
                        mainStyle="tw-text-red-600 hover:tw-bg-red-50 tw-border-b-red-600"
                        countStyle="tw-bg-red-700"
                      />
                      <TaskFilterButton
                        label="Past 16-30 Days"
                        count={DueDateTable[0]?.PAST_16_30_Days}
                        onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: -2, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
                        mainStyle="tw-text-red-500 hover:tw-bg-red-50 tw-border-b-red-500"
                        countStyle="tw-bg-red-600"
                      />
                      <TaskFilterButton
                        label="Past 1-15 Days"
                        count={DueDateTable[0]?.PAST_1_15_Days}
                        onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: -1, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
                        mainStyle="tw-text-red-400 hover:tw-bg-red-50 tw-border-b-red-400"
                        countStyle="tw-bg-red-500"
                      />
                      <TaskFilterButton
                        label="Today"
                        count={DueDateTable[0]?.TODAY}
                        onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: 1, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
                        mainStyle="tw-text-yellow-600 hover:tw-bg-yellow-50 tw-border-b-yellow-400"
                        countStyle="tw-bg-yellow-500"
                      />
                      <TaskFilterButton
                        label="Next 1-15 Days"
                        count={DueDateTable[0]?.NEXT_1_15_DAYS}
                        onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: 2, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
                        mainStyle="tw-text-green-400 hover:tw-bg-green-50 tw-border-b-green-400"
                        countStyle="tw-bg-green-500"
                      />
                      <TaskFilterButton
                        label="Next 16-30 Days"
                        count={DueDateTable[0]?.NEXT_16_30_DAYS}
                        onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: 3, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
                        mainStyle="tw-text-green-500 hover:tw-bg-green-50 tw-border-b-green-500"
                        countStyle="tw-bg-green-600"
                      />
                      <TaskFilterButton
                        label="Next 31-45 Days"
                        count={DueDateTable[0]?.NEXT_31_45_DAYS}
                        onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: 4, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
                        mainStyle="tw-text-green-600 hover:tw-bg-green-50 tw-border-b-green-600"
                        countStyle="tw-bg-green-700"
                      />
                      <TaskFilterButton
                        label="More then 45 days"
                        count={DueDateTable[0]?.NEXT_ABOVE_45_DAYS}
                        onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: 5, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
                        mainStyle="tw-text-green-700 hover:tw-bg-green-50 tw-border-b-green-700"
                        countStyle="tw-bg-green-800"
                      />
                      <TaskFilterButton
                        label="No Deadline Dates tasks"
                        count={DueDateTable[0]?.No_Deadline_Dates_tasks}
                        onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: 6, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
                        mainStyle="tw-text-red-600 hover:tw-bg-red-50 tw-border-b-red-400"
                        countStyle="tw-bg-red-500"
                      />
                    </>
                  </CardBody>
                </div>

                <Collapse expandIconPosition={"end"} className='!tw-rounded-md ' style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 5px 10px 0px" }}>
                  <Panel header={<span className='!tw-font-bold !tw-text-lg !tw-text-gray-800'>Custom Tags</span>} key="1" className='tw-bg-white !tw-rounded-md '>
                    <div className="tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-grid-cols-1 md:tw-gap-4 tw-gap-y-3 md:tw-p-4 tw-p-1">
                      {(isShowAllTags ? allTags : allTags?.filter((v) => v.count > 0).length === 0 ? allTags.slice(0, 2) : allTags?.filter((v) => v.count > 0))?.map((tag, index) => {
                        return <TaskFilterButton
                          icon={<LuTag />}
                          key={index}
                          label={tag.TAG_NAME}
                          count={tag.count}
                          onClick={() => (onHandalTabFilter({ ...baseFilterObj, taskTags: { type: "is", values: [tag?.UNIQUE_ID] }, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } }))}
                          mainStyle="hover:tw-bg-gray-50"
                          mainInlineStyle={{ borderBottom: `1px dotted ${tag?.TAG_COLOR}`, borderRadius: '2px', color: tag?.TAG_COLOR }}
                          countinlinestyle={{ backgroundColor: tag.TAG_COLOR }}
                        />
                      })
                      }
                      <div className='tw-flex tw-items-center tw-h-full' >
                        <button onClick={(e) => setIsShowAllTags(!isShowAllTags)} className={`tw-inline-block tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-font-semibold tw-px-2 tw-py-3 border  tw-transition-all ${isShowAllTags ? 'tw-bg-red-100 !tw-border-red-500 hover:tw-bg-red-200' : 'tw-bg-indigo-100 !tw-border-indigo-500 hover:tw-bg-indigo-200'}`}>
                          {isShowAllTags ? "View Less" : `+ ${allTags?.length - allTags?.filter((v) => v.count > 0).length}`}
                        </button>
                      </div>
                    </div>
                  </Panel>
                </Collapse>

                <div className='tw-my-4 tw-py-2 border tw-rounded' style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 5px 10px 0px" }}>
                  <div className='border-bottom tw-my-1 md:tw-px-6 tw-px-3'>
                    <h5 className='tw-font-medium tw-text-gray-800'>Status Chart</h5>
                  </div>
                  <div className='tw-px-4 tw-my-4 tw-w-full'>
                    <DashboardChart analytics={chartdata} />
                  </div>
                </div>

              </div>

            </>}
        </div>

      </div>
    </div >
  )
}

export default MyTaskDashboard