import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BootstrapTooltip } from '../../Commoncomponet/common'
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader'
import { getTaskTag, insertTaskTag, updateTaskTag } from '../../Commoncomponet/Utils'
import { toast } from 'react-toastify'
import BasicSelect from '../../Commoncomponet/Select/BasicSelect'

const TaskTags = (props) => {
    let userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    const [isLoading, setIsLoading] = useState(false)
    const [tagsData, setTagsData] = useState([])
    const [isModal, setIsModal] = useState(false)
    const [tagName, setTagName] = useState("");
    const [tagColor, setTagColor] = useState("");
    const [preview, setPreview] = useState("");
    const [isActive, setIsActive] = useState(false)
    const [isEditModal, setIsEditModal] = useState(false)
    const [editTagId, setEditTagId] = useState("")
    const [Tag_Type, setTag_Type] = useState(2)
    const [TagFilter, setTagFilter] = useState('ALL')

    useEffect(() => {
		if(props.IsFilterRemove){
			const currentURL = new URL(window.location.href);
			currentURL.searchParams.delete('taskid');
			window.history.replaceState({}, '', currentURL.toString());
		}
		
},[])


    const getTagsData = async (value) => {
        setIsLoading(true)
        const tags = await getTaskTag(value);
        if (tags.success === true) {
            setIsLoading(false)
            setTagsData(tags.data.data)
        }
        else {
            setIsLoading(false)
            console.log("Error while getting tags data", tags.err)
        }
    }

    useEffect(() => {
        getTagsData()
    }, [])

    const handleOpenModal = () => {
        setIsModal(true)

    }

    const handleEditModal = (id) => {
        setIsEditModal(true)
        const tag = tagsData.find((tag) => tag.UNIQUE_ID === id);
        setEditTagId(id)
        setTagName(tag.TAG_NAME)
        setTagColor(tag.TAG_COLOR)
        setTag_Type(tag?.TAG_TYPE || 2)
        setPreview(
            <div id="tagpreviewdiv">
                <div className={`btn btn-icon icon-left`} style={{ backgroundColor: tag.TAG_COLOR, color: "white" }}>
                    <i className="fa fa-tag fa-lg" style={{ marginRight: '4px' }}></i>
                    {tag.TAG_NAME}
                </div>
            </div>
        );
    }

    const handleModalClose = () => {
        setTagColor("")
        setTagName("")
        setPreview("")
        setIsModal(false)
    }

    const handleEditModalClose = () => {
        setTagColor("")
        setTagName("")
        setPreview("")
        setEditTagId("")
        setIsEditModal(false)
    }

    const colors = [
        { name: "Gray", code: "#cdd3d8" },
        { name: "Orange", code: "#ffa426" },
        { name: "Blue", code: "#3abaf4" },
        { name: "Red", code: "#fc544b" },
        { name: "Green", code: "#54ca68" },
        { name: "Black", code: "#191d21" },
        { name: "White", code: "#e3eaef" },
    ];
    const handleTagNameChange = (event) => {
        setTagName(event.target.value);
        setPreview(
            <div id="tagpreviewdiv">
                {event.target.value && tagColor ? <div class={`btn btn-icon icon-left`} style={{ backgroundColor: tagColor, color: "white" }}><i class="fa fa-tag fa-lg" style={{ marginRight: '4px' }}></i>{event.target.value}</div> : "N/A"}
            </div>
        );
    };

    const handleTagColorChange = (event) => {
        setTagColor(event.target.value);
        if (tagName) {
            setPreview(
                <div id="tagpreviewdiv">
                    <div className={`btn btn-icon icon-left`} style={{ backgroundColor: event.target.value, color: "white" }}>
                        <i className="fa fa-tag fa-lg" style={{ marginRight: '4px' }}></i>
                        {tagName}
                    </div>
                </div>
            );
        } else {
            setPreview("N/A");
        }
    };

    const handleSubmit = async () => {
        const addTags = await insertTaskTag({ CREATED_BY: userInfo?.UNIQUE_ID, TAG_NAME: tagName, TAG_COLOR: tagColor, TAG_TYPE: Tag_Type });
        if (addTags.success === true) {
            getTagsData()
            handleModalClose()
        } else if (addTags.err.response.status === 503) {
            toast.error('Tag Is Already Added')
        } else {
            console.log("Error while adding tags", addTags.err)
        }
    };

    const updateTag = async (id) => {
        const updateTags = await updateTaskTag({ CREATED_BY: userInfo?.UNIQUE_ID, TAG_NAME: tagName, TAG_COLOR: tagColor, UNIQUE_ID: id, STATUS: isActive, TAG_TYPE: Tag_Type });
        if (updateTags.success === true) {
            getTagsData()
            handleEditModalClose()
        } else if (updateTags.err.response.status === 503) {
            toast.error('Tag Is Already Added')
        } else {
            console.log("Error while updating tags", updateTags.err)
        }
    }

    const handleStatusChange = (event) => {
        setIsActive(event.target.value)
    }

    const handleChangeType = (e) => {
        getTagsData(e.target.value)
        setTagFilter(e.target.value);
    };



    return (
        <>
            <Modal show={isModal} onHide={handleModalClose} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        New Tag
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title" class="tw-w-1/5 col-form-label tw-font-medium">Tag name<span className='text-danger'>*</span> :</label>
                            <input
                                class="form-control" name="tag" id="tag_name" type="text" placeholder="Enter Tag Name" required=""
                                value={tagName}
                                onChange={handleTagNameChange}
                            />

                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title" class="tw-w-1/5 col-form-label tw-font-medium">Tag color<span className='text-danger'>*</span> :</label>

                            <select class="form-control form-select" value={tagColor} onChange={handleTagColorChange}>
                                <option value="">Select Tag Color</option>
                                {colors.map((color) => (
                                    <option key={color.code} value={color.code}>
                                        {color.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title" class="tw-w-1/5 col-form-label tw-font-medium">Tag Type<span className='text-danger'>*</span> :</label>
                            <select class="form-control form-select" value={Tag_Type} onChange={(e) => (setTag_Type(e.target.value))}>
                                <option value={1}>Predefine Tag</option>
                                <option value={2}>Custom Tag</option>
                            </select>
                        </div>
                        <div>
                            <label for="title" class="tw-w-1/5 col-form-label tw-font-medium">Preview : </label>
                            <div style={{ marginBottom: "20px" }}>{preview}</div>
                        </div>

                        <p class="text-danger">Note : * Indicates Mandatory Fields</p>

                        <button disabled={!tagName || !tagColor} type="button" onClick={handleSubmit} className='btn btn-primary mr-2'>
                            Submit
                        </button>
                    </div>



                </Modal.Body>
            </Modal>

            <Modal show={isEditModal} onHide={handleEditModalClose} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Tag
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title" class="tw-w-1/5 col-form-label tw-font-medium">Tag name<span className='text-danger'>*</span> :</label>
                            <input
                                class="form-control" name="tag" id="tag_name" type="text" placeholder="Enter Tag Name" required=""
                                value={tagName}
                                onChange={handleTagNameChange}
                            />

                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title" class="tw-w-1/5 col-form-label tw-font-medium">Tag color<span className='text-danger'>*</span> :</label>

                            <select class="form-control form-select" value={tagColor} onChange={handleTagColorChange}>
                                <option value="">Select Tag Color</option>
                                {colors.map((color) => (
                                    <option key={color.code} value={color.code}>
                                        {color.name}
                                    </option>
                                ))}
                            </select>

                        </div>

                        <div>
                            <label for="title" class="tw-w-1/5 col-form-label tw-font-medium">Preview : </label>
                            <div style={{ marginBottom: "20px" }}>{preview}</div>
                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title" class="tw-w-1/5 col-form-label tw-font-medium">Tag Type<span className='text-danger'>*</span> :</label>
                            <select class="form-control form-select" value={Tag_Type} onChange={(e) => (setTag_Type(e.target.value))}>
                                <option value={1}>Predefine Tag</option>
                                <option value={2}>Custom Tag</option>
                            </select>
                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title" class="tw-w-1/5 col-form-label tw-font-medium">Status<span className='text-danger'>*</span> :</label>
                            <select class="form-control form-select" value={isActive} onChange={handleStatusChange}>
                                <option value="">Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>


                        <p class="text-danger">Note : * Indicates Mandatory Fields</p>

                        <button disabled={!tagName || !tagColor} type="button" onClick={() => updateTag(editTagId)} className='btn btn-primary mr-2'>
                            Submit
                        </button>
                    </div>

                </Modal.Body>
            </Modal>

            {
                isLoading ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                    <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                </div> : <>
                    <div >
                        <div className="border-bottom border-top">
                            <div className='tw-px-5 tw-py-3 tw-flex tw-justify-between tw-items-center '>

                                <h4 className='tw-mb-0'>Task Tags</h4>
                                <div className='tw-flex tw-flex-wrap tw-gap-2' >
                                    <div className='tw-min-w-[100px]'>
                                        <select class="form-control tw-w-full form-select" value={TagFilter} onChange={handleChangeType}>
                                            <option value=''>All</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>InActive</option>
                                        </select>
                                    </div>
                                    <div className="">
                                        <button type="button" className="btn btn-primary mr-1" onClick={handleOpenModal} >
                                            <BootstrapTooltip title="Task tag">
                                                <div className='tw-flex tw-items-center tw-gap-1 '>
                                                    <i class="fas fa-plus" aria-hidden="true"></i>
                                                    <span>
                                                        New Tag
                                                    </span>
                                                </div>
                                            </BootstrapTooltip>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="card-body">
                            <div className="tw-my-1 tw-mx-1  tw-rounded-sm" style={{ boxShadow: "0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.06)" }}>
                                <div className='card-header !tw-bg-indigo-50 !tw-border-gray-300 !tw-mb-0 tw-justify-between'>
                                    <h4 className='tw-mb-0'>Predefined Tags</h4>
                                </div>
                                <div className="table-responsive">
                                    <table className="tw-min-w-full tw-table tw-border-b tw-text-start">
                                        <thead className="border-bottom">
                                            <tr>
                                                <th className="text-center tw-text-gray-900 tw-px-2 tw-py-3" style={{ maxWidth: "100px", width: "6%" }} >Unique No</th>
                                                <th className="tw-text-gray-900 tw-px-2 tw-py-3">Tags Name</th>
                                                <th className="tw-text-gray-900 tw-px-2 tw-py-3">Tag Color</th>
                                                <th className="tw-text-gray-900 tw-px-2 tw-py-3">Tag type</th>
                                                <th className="tw-text-gray-900 tw-px-2 tw-py-3" style={{ maxWidth: "100px" }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tw-divide-y tw-divide-gray-200">
                                            {tagsData &&
                                                tagsData.map((item, id) => (
                                                    item?.TAG_TYPE == 1 && <tr key={id}>
                                                        <td className="tw-px-2 tw-text-center tw-py-3 tw-whitespace-nowrap " >{item.SRNO}</td>
                                                        <td className="tw-px-2 tw-py-3 tw-w-2/4"><button class={`btn btn-icon icon-left`} style={{ background: item?.TAG_COLOR, color: "white" }} onClick={() => item.TAG_TYPE == 1 && userInfo?.ROLE != 'ROLE1650614500708' ? '' : handleEditModal(item.UNIQUE_ID)}><i class="fa fa-tag " style={{ marginRight: '4px' }}>
                                                        </i>{item.TAG_NAME}</button></td>
                                                        <td className="tw-px-2 tw-py-3">{colors.filter((color) => color.code === item.TAG_COLOR)[0]?.name} ({item.TAG_COLOR})</td>
                                                        <td className="tw-px-2 tw-py-3">{item?.TAG_TYPE == 1 ? 'Predefined Tags' : 'Custom Tags'}</td>
                                                        <td className="tw-px-2 tw-py-3">
                                                            {item.STATUS === 1 ?
                                                                <div className="badge badge-success badge-shadow text-white">Active</div>
                                                                :
                                                                <div className="badge badge-danger badge-shadow text-white">Inactive</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="tw-my-4 tw-mx-1  tw-rounded-sm" style={{ boxShadow: "0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.06)" }}>
                                <div className='card-header !tw-bg-indigo-50 !tw-border-gray-300 !tw-mb-0 tw-justify-between'>
                                    <h4 className='tw-mb-0'>Custom Tags</h4>
                                </div>
                                <div className="table-responsive">
                                    <table className="tw-min-w-full tw-table tw-border-b tw-text-start">
                                        <thead className="border-bottom">
                                            <tr>
                                                <th className="text-center tw-text-gray-900 tw-px-2 tw-py-3" style={{ maxWidth: "100px", width: "6%" }} >Unique No</th>
                                                <th className="tw-text-gray-900 tw-px-2 tw-py-3">Tags Name</th>
                                                <th className="tw-text-gray-900 tw-px-2 tw-py-3">Tag Color</th>
                                                <th className="tw-text-gray-900 tw-px-2 tw-py-3">Tag type</th>
                                                <th className="tw-text-gray-900 tw-px-2 tw-py-3" style={{ maxWidth: "100px" }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tw-divide-y tw-divide-gray-200">
                                            {tagsData &&
                                                tagsData.map((item, id) => (
                                                    item?.TAG_TYPE != 1 && <tr key={id}>
                                                        <td className="tw-px-2 tw-text-center tw-py-3 tw-whitespace-nowrap " >{item.SRNO}</td>
                                                        <td className="tw-px-2 tw-py-3 tw-w-2/4"><button class={`btn btn-icon icon-left`} style={{ background: item?.TAG_COLOR, color: "white" }} onClick={() => item.TAG_TYPE == 1 && userInfo?.ROLE != 'ROLE1650614500708' ? '' : handleEditModal(item.UNIQUE_ID)}><i class="fa fa-tag " style={{ marginRight: '4px' }}>
                                                        </i>{item.TAG_NAME}</button></td>
                                                        <td className="tw-px-2 tw-py-3">{colors.filter((color) => color.code === item.TAG_COLOR)[0]?.name} ({item.TAG_COLOR})</td>
                                                        <td className="tw-px-2 tw-py-3">{item?.TAG_TYPE == 1 ? 'Predefined Tags' : 'Custom Tags'}</td>
                                                        <td className="tw-px-2 tw-py-3">
                                                            {item.STATUS === 1 ?
                                                                <div className="badge badge-success badge-shadow text-white">Active</div>
                                                                :
                                                                <div className="badge badge-danger badge-shadow text-white">Inactive</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default TaskTags