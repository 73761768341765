import React, { useEffect, useState } from 'react'
import Loadsurvey from './MeterData/Loadsurvey';
import Dailyload from './MeterData/Dailyload';
import MeteorologicalEvent from './MeterData/MeteorologicalEvent';
import Instantaneous from './MeterData/Instantaneous';

import EMTSTATICS from './MeterData/EMTSTATICS';
import chroma from 'chroma-js';
import ApplicationEvent from './MeterData/ApplicationEvent';
import MonthlyData from './MeterData/MonthlyData';

export default function MeterData(props) {
    const [buttonArray,setbuttonArray] = useState([])
	// ,"Voltevent","currentevent","powerevent","transectionevent","otherevent","nonrolloverevent",'controlevent'
	const [btnText, setButtontext] = useState("");
    const onHandalebtn = (text) => {
		setButtontext(text);
	};

	useEffect(()=>{
		if(props?.GSMdata?.METER_TYPE=="GSTMID1706880187688"){
			setbuttonArray(["Hourly","Daily Data","Instantaneous",'Monthly Data'])
		}else{
		setbuttonArray(["Hourly","Daily Data","Meteorological Event",'Application Event'])
		}
	},[])
  return (
  <>
 <div className="card-body" style={{ paddingTop: "0px",paddingLeft:'15px',paddingBottom:'5px' }}>
				<div className="d-flex justify-content-between">
					<div className="buttons">
						{buttonArray?.map((item, id) => (
							<button
							style={{
								backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#54ca68').alpha(0.4).css() : '',
								color: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#54ca68').darken(1.5).css() : '#54ca68',
								borderBottom: `1px solid ${'#54ca68'}`,
								boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${'#54ca68'}`  : '',
							}}
							className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
							onClick={() => onHandalebtn(item)}>
							{item}
						</button>
						))}
					</div>
                    </div>
                    </div>
					{btnText === 'Instantaneous' && <Instantaneous GSMdata={props.GSMdata}/>}
					{btnText==='Daily Data'&&<Dailyload GSMdata={props.GSMdata}/>}
					{btnText==='Hourly'&&<Loadsurvey GSMdata={props.GSMdata}/>}
					{btnText==='Meteorological Event'&&<MeteorologicalEvent GSMdata={props.GSMdata}/>}
					{btnText==='Application Event'&&<ApplicationEvent GSMdata={props.GSMdata}/>}
					{btnText==='Monthly Data'&&<MonthlyData GSMdata={props.GSMdata}/>}
  </>
  )
}
