import React, { useEffect, useState } from 'react'
import { Users } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { GetMssqlCardDetails } from '../../Commoncomponet/Utils'
import APIIcon from "../../assets/img/icons8-ui-design-99.png"
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader'
const Integrations = () => {
  const history = useHistory()
  const [CardDatails,setCardDatails]=useState([])
  const [Loader,setLoader]=useState(false)

  const handleManageClick = (id) => {
    history.push('/integrations/mssql-integrations/'+id)
  }

  const ongetcarddetail=async()=>{
    setLoader(true)

    const data=await GetMssqlCardDetails()
    if(data.success==true){
      setCardDatails(data.data.LogDetails)
      setLoader(false)
    }else{
      console.log('somthing wait wrong')
      setLoader(false)

    }
  }

  useEffect(()=>{
    ongetcarddetail()
  },[])
  return (
    <>
      <div className="main-content">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb ">
            <li className="breadcrumb-item">
              <Link to="/integration">
                <i className="fas fa-tachometer-alt"></i> Home
              </Link>
            </li>

            <li className="breadcrumb-item">
              <Link to="/integration">
                <i className="fa-solid fa-database tw-mr-2"></i> Plugins
              </Link>
            </li>
          </ol>
        </nav>
        {Loader==true ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                        <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                    </div>:<section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-12">
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active d-flex" style={{ flexWrap:'wrap' }} id="home" role="tabpanel" aria-labelledby="home-tab" >

                        {CardDatails?.map((item,id)=>(
                    <div className="col-4">
                      <div className="card">
                        <div className="card-body">
                        <ul className="list-unstyled list-unstyled-border user-list" id="message-list">
                        
                            <li className="media tw-flex tw-items-center tw-justify-center tw-gap-2">
                           {item?.TYPE=="API"?<img alt="image" src={APIIcon} className="mr-3 user-img-radious-style user-list-img" />:<><i className="fa-sharp tw-text-sky-400 fa-solid fa-database tw-h-8 tw-w-8"></i></>}
                              {/* <img alt="image" src="https://gums.memighty.com/assets/Upload/database-icon.jpg" className="mr-3 user-img-radious-style user-list-img" /> */}
                              <div className="media-body">
                                <div className="mt-0 font-weight-bold">{item?.PROFILE_NAME}</div>
                              </div>
                            </li>
                          </ul>

                          <div className="text-small tw-m-3">{item?.PROFILE_DESCRIPTION}</div>
                          <div id="set_up_btn"><button className="btn btn-outline-success" onClick={()=>handleManageClick(item?.UNIQUE_ID)}>Manage</button></div>
                        </div>
                      </div>
                    </div>
                        ))}
                          

                    <div id="Report_Default_div" className="row"></div>
                  </div>
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div id="Report_Favourite_div" className="row"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>}
      </div>
    </>
  )
}

export default Integrations