import React, { useState, useEffect } from 'react'

import chroma from 'chroma-js';
import AllDashboard from './AllDashboard';
import MainTaskPage from './MainTaskPage';
import MyTaskDashboard from './MyTaskDashboard';
import MyGroupDashboard from './MyGroupDashboard';
import TaskAssignByMeDashboeard from './TaskAssignByMeDashboeard';
import TaskShareWithMeDashboard from './TaskShareWithMeDashboard';


export default function Dashboard(props) {
	let userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const buttonArray = ["My", "My Group", "Assigned By Me","Shared With Me","All"];
	const [btnText, setButtontext] = useState("My");
	const onHandalebtn = (text) => {
		setButtontext(text);
	};

	useEffect(() => {
		if(props.IsFilterRemove){
			const currentURL = new URL(window.location.href);
			currentURL.searchParams.delete('taskid');
			window.history.replaceState({}, '', currentURL.toString());
		}
		
},[])
	return (
		<>
			<div className="card-body border-top border-bottom" style={{ paddingTop: "0px", paddingLeft: '15px', paddingBottom: '0px' }}>
				<div className="d-flex justify-content-between">
					<div className="buttons">
						{buttonArray.map((item, id) => (
							<button
								key={item}
								style={{
									// backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#22c55e').alpha(0.4).css() : '',
									// color: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#22c55e').darken(1.5).css() : '#22c55e',
									// borderBottom: `1px solid ${'#22c55e'}`,
									// boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${'#22c55e'}`  : '',
									borderBottom: buttonArray.findIndex((data) => data === btnText) === id ? `2px solid ${'#6366F1'}` : '2px solid transparent',
								}}
								className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? '' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
								onClick={() => onHandalebtn(item)}>
								{item}
							</button>
						))}
					</div>
				</div>
			</div>
			{btnText === 'All' && <AllDashboard setDefaultTab={(e) => { props.setDefaultTab('tab2') }} setstoredFilterS={(info) => props.setstoredFilterS(info)} setstoredFilterType={(info) => props.setstoredFilterType(info)} setStoredFilter={(info) => props.setStoredFilter(info)} />}
			{btnText === 'My' && <MyTaskDashboard setDefaultTab={(e) => { props.setDefaultTab('tab2') }} setstoredFilterS={(info) => props.setstoredFilterS(info)} setstoredFilterType={(info) => props.setstoredFilterType(info)} setStoredFilter={(info) => props.setStoredFilter(info)} />}
			{btnText === 'Assigned By Me'&&<TaskAssignByMeDashboeard setDefaultTab={(e)=>{props.setDefaultTab('tab2') }} setstoredFilterS={(info) => props.setstoredFilterS(info)} setstoredFilterType={(info) => props.setstoredFilterType(info)} setStoredFilter={(info) => props.setStoredFilter(info)} />}
			{btnText === 'Shared With Me'&&<TaskShareWithMeDashboard setDefaultTab={(e)=>{props.setDefaultTab('tab2') }} setstoredFilterS={(info) => props.setstoredFilterS(info)} setstoredFilterType={(info) => props.setstoredFilterType(info)} setStoredFilter={(info) => props.setStoredFilter(info)} />}
			{btnText === 'My Group' && <MyGroupDashboard setDefaultTab={(e) => { props.setDefaultTab('tab2') }} setstoredFilterS={(info) => props.setstoredFilterS(info)} setstoredFilterType={(info) => props.setstoredFilterType(info)} setStoredFilter={(info) => props.setStoredFilter(info)} />}

		</>
	)
}
