// @flow
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Input from "./InputOtp";
import useOtp from "./useOtp";
import './otp-input.css'

const OtpInput2 = ({
    OTPLength,
    disabled,
    autoFocus,
    value = "",
    onChange,
    otpType,
    secure,
    className,
    inputClassName,
    inputStyles,
    style,
    placeholder,
}) => {
    const {
        activeInput,
        getOtpValue,
        handleOnChange,
        handleOnKeyDown,
        handelOnInput,
        handleOnPaste,
        onInputFocus,
    } = useOtp({
        autoFocus,
        value,
        otpType,
        onChange,
        OTPLength,
    });

    // Needs to be memorized
    const renderInputs = useMemo(() => {
        const otp = getOtpValue();
        const inputs = [];

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < OTPLength; index++) {
            inputs.push(
                <Input
                    className={inputClassName + "code" + (activeInput === index ? " focus" : " ")}
                    inputStyles={inputStyles}
                    key={index}
                    focus={activeInput === index}
                    value={otp[index]}
                    onChange={handleOnChange}
                    onKeyDown={handleOnKeyDown}
                    onInput={handelOnInput}
                    onPaste={handleOnPaste}
                    onInputFocus={onInputFocus}
                    index={index}
                    // onBlur={() => setActiveInput(-1)}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    secure={secure}
                    data-testid="input"
                    otpType={otpType}
                    placeholder={placeholder && placeholder[index]}
                />
            );
        }

        return inputs;
    }, [
        getOtpValue,
        OTPLength,
        inputClassName,
        inputStyles,
        activeInput,
        handleOnChange,
        handleOnKeyDown,
        handelOnInput,
        handleOnPaste,
        onInputFocus,
        disabled,
        autoFocus,
        secure,
        otpType,
        placeholder,
    ]);

    return (
        <div
            style={{
                ...style,
            }}
            className={`${className} code-wrapper`}
            data-testid="otp-input-root"
        >
            {renderInputs}
        </div>
    );
};

OtpInput2.propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    OTPLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    secure: PropTypes.bool,
    otpType: PropTypes.oneOf(["number", "alpha", "alphanumeric", "any"]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inputStyles: PropTypes.object,
    style: PropTypes.object,
    placeholder: PropTypes.array,
};

OtpInput2.defaultProps = {
    className: "",
    inputClassName: "",
    OTPLength: 4,
    onChange: () => { },
    disabled: false,
    secure: false,
    autoFocus: false,
    value: "",
    otpType: "any",
    inputStyles: {},
    style: {},
    placeholder: undefined,
};

export default OtpInput2;