import React, { useEffect, useState } from 'react'
import ButtonTabs from '../../Commoncomponet/Tabs/ButtonTabs'
import TaskTags from './TaskTags'
import Dashboard from './Dashboard'
import Tasks from './Tasks'

const MainTaskPage = () => {
    const [storedFilter, setStoredFilter] = useState()
    const [storedShowFilter, setStoredShowFilter] = useState()
    const [storedFilterType, setstoredFilterType] = useState()
    const [storedFilterS, setstoredFilterS] = useState()
    const [DefaultTab, setDefaultTab] = useState("tab1")
    const [IsDefault, setIsDefault] = useState(false)

    const [IsFilterRemove,setIsFilterRemove] = useState(false)

    useEffect(() => {
        const currentURL = new URL(window.location.href);
        const taskid = currentURL.searchParams.get('taskid');
        if (taskid) {
            setDefaultTab("tab2")
            setIsFilterRemove(true)
        }
    }, [])
    return (
        <>
            <div className="main-content">
                <section className="section">
                    <div className="card !tw-rounded-none">

                        <div className='tw-mt-2 tw-w-full tw-mx-auto tw-px-0'>
                            <ButtonTabs color="indigo" className={''} defaultTab={storedFilterS?.DefaultTab || DefaultTab} setIsDefault={setIsDefault} IsDefault={IsDefault}>
                                <div name='Dashboard' value={'tab1'} color='#22c55e' onClick={() => setDefaultTab('tab1')}>
                                    <Dashboard setDefaultTab={(e) => {
                                        setDefaultTab('tab2')
                                        setIsDefault(true)
                                    }} IsFilterRemove={IsFilterRemove} setstoredFilterS={setstoredFilterS} setstoredFilterType={setstoredFilterType} setStoredFilter={setStoredFilter} />
                                </div>
                                
                                <div className='border-top' name='Tasks' value={'tab2'} color='#6366f1' onClick={()=>setDefaultTab('tab2')}>
                                    <Tasks
                                        storedFilter={storedFilter}
                                        onChangeStoredFilter={(newFilter) => {
                                            console.log("StoredFilter = ", newFilter)
                                            setStoredFilter(newFilter)
                                        }}
                                        storedShowFilter={storedShowFilter}
                                        onChangeShowFilter={(newFilter) => {
                                            setStoredShowFilter(newFilter)
                                        }}
                                        storedFilterType={storedFilterType}
                                        setstoredFilterType={setstoredFilterType}
                                        setstoredFilterS={setstoredFilterS}
                                        storedFilterS={storedFilterS}
                                    />
                                </div>
                                <div name='Tags' value={'tab3'} color='#ef4444' onClick={() => setDefaultTab('tab3')}>
                                    <TaskTags IsFilterRemove={IsFilterRemove} />
                                </div>
                            </ButtonTabs>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default MainTaskPage