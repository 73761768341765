import chroma from 'chroma-js'
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import GSM_Deshboard from './GSM_Deshboard';
import GSM_Custom_Dashboard from './GSM_Custom_Dashboard';
import { DashboardCardFilterGetTypeWise, getAllCategoryTableData, getRolesById } from '../../../Commoncomponet/Utils';
import { BootstrapTooltip } from '../../../Commoncomponet/common';

export default function GSM_Main_Dashboard() {
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
	const [loader, setLoader] = useState(false);
	const [activeTab, setActiveTab] = useState("");
	const [SubactiveTab, setSubActiveTab] = useState("");
	const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] });
	const [AllCategory, setAllCategory] = useState({});
	const [FilterDay, setFilterDay] = useState([])
	const [isRefDownload,setisRefDownload]=useState(false)
	const [isDownloadBtnShow,setisDownloadBtnShow]=useState(false)
	const [permision,setpermision]=useState({})
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));


	useEffect(() => {
		setProfile({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "",ins_no: [] })
		setisDownloadBtnShow(false)
	}, [activeTab, SubactiveTab])

	const ongetAllCategoryTableData = async (info) => {
		const data = await getAllCategoryTableData({})
		if (data.success === true) {
			setAllCategory(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}

	const onGetFilterDaywise = async () => {
		const data2 = await DashboardCardFilterGetTypeWise('GSM', userInfo?.UNIQUE_ID)
		if (data2.success == true) {
			setFilterDay(data2?.data?.data || [])
		}
	}

	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.ROLE);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({
				...obj,
				ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
			});
			
		}
	}


	useEffect(() => {
		onHandaleRoles()
		ongetAllCategoryTableData()

	}, [])

	useEffect(() => {
		if(Object.keys(permision).length&&(permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Custom_Dashboard')||userInfo?.ROLE === "ROLE1650614500708")){
			setActiveTab("Custom Dashboard")
		onGetFilterDaywise()
		}else if(Object.keys(permision).length&&(permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Static_Dashboard')||userInfo?.ROLE === "ROLE1650614500708")) {
			setActiveTab("Static Dashboard")

		}
	}, [permision])
	return (
		<>
			<div className="main-content">
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-data">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home/
							</Link>
						</li>
						<li className="breadcrumb-data">
							<Link to='/gsm_dashboard'> GSM Dashboard</Link>
						</li>
					</ol>
				</nav>
				<section class="section">
					<div class="section-body">
						<div class="row">

							<div class="col-12 col-sm-12 col-lg-12 tw-p-0">
								<div class="card ">
									<div class="card-header d-flex tw-border-b-0 " style={{ justifyContent: 'space-between' }}>
										<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: '20px', alignItems: 'center' }}>
										{permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Custom_Dashboard')||userInfo?.ROLE === "ROLE1650614500708"?<li>
												<button style={{
													backgroundColor: activeTab === "Custom Dashboard" ? chroma(btnColor[0]).alpha(0.4).css() : '',
													color: activeTab === "Custom Dashboard" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
													borderBottom: `1px solid ${btnColor[0]}`,
													boxShadow: activeTab === "Custom Dashboard" ? `0 2px 8px -3px ${btnColor[0]}` : '',
												}}
													className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Custom Dashboard" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => (setActiveTab('Custom Dashboard'))}>Custom Dashboard</button>
											</li>:''}
											{permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Static_Dashboard')||userInfo?.ROLE === "ROLE1650614500708"?<li>
												<button style={{
													backgroundColor: activeTab === "Static Dashboard" ? chroma(btnColor[2]).alpha(0.4).css() : '',
													color: activeTab === "Static Dashboard" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
													borderBottom: `1px solid ${btnColor[2]}`,
													boxShadow: activeTab === "Static Dashboard" ? `0 2px 8px -3px ${btnColor[2]}` : '',
												}}
													className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Static Dashboard" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={() => (setActiveTab('Static Dashboard'))}>Static Dashboard</button>
											</li>:''}
											{Object.keys(permision).length&&!permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Custom_Dashboard')&&!permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Static_Dashboard')&&userInfo?.ROLE !== "ROLE1650614500708"?<h4>Don't have permission any dashboard</h4>:''}
										</ul>
									</div>
								</div>
							</div>
							{activeTab == 'Static Dashboard' && <><div class="row">

								<div class="col-12 col-sm-12 col-lg-12 tw-p-0">
									<div class="card ">
										<div class="card-header d-flex tw-border-b-0 " style={{ justifyContent: 'space-between' }}>
											<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: '20px', alignItems: 'center' }}>
												<li>
													<button style={{
														backgroundColor: SubactiveTab === "Device Dashboard" ? chroma(btnColor[0]).alpha(0.4).css() : '',
														color: SubactiveTab === "Device Dashboard" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
														borderBottom: `1px solid ${btnColor[0]}`,
														boxShadow: SubactiveTab === "Device Dashboard" ? `0 2px 8px -3px ${btnColor[0]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${SubactiveTab === "Device Dashboard" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => (setSubActiveTab('Device Dashboard'))}>Modem Dashboard</button>
												</li>
												<li>
													<button style={{
														backgroundColor: SubactiveTab === "EVC/MC Meter Dashboard" ? chroma(btnColor[2]).alpha(0.4).css() : '',
														color: SubactiveTab === "EVC/MC Meter Dashboard" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
														borderBottom: `1px solid ${btnColor[2]}`,
														boxShadow: SubactiveTab === "EVC/MC Meter Dashboard" ? `0 2px 8px -3px ${btnColor[2]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${SubactiveTab === "EVC/MC Meter Dashboard" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={() => (setSubActiveTab('EVC/MC Meter Dashboard'))}>EVC/MC Meter Dashboard</button>
												</li>
											</ul>
											<div class="card-header-action d-flex">
											{SubactiveTab != ''?<BootstrapTooltip title={SubactiveTab != '' &&isDownloadBtnShow==true?'PDF Download in All Graph':"Pendding graph Load"}><button className={`btn btn-info !tw-border-none ${isRefDownload?'disabled btn-progress':''} ${SubactiveTab != '' &&isDownloadBtnShow==true?'':'disabled'}`} onClick={()=>setisRefDownload(true)}>
													<i class="fa fa-download" aria-hidden="true"></i>
												</button></BootstrapTooltip>:''}
											</div>
										</div>
									</div>
								</div>
							</div>
								{SubactiveTab != '' && <GSM_Deshboard Profile={Profile} setProfile={setProfile} activeTab={SubactiveTab} AccounData={AllCategory?.category0} GaData={AllCategory?.category1} CircleData={AllCategory?.category2} DivisionData={AllCategory?.category3} SubdevisionData={AllCategory?.category4} AreaData={AllCategory?.category5} AllCategoryJson={AllCategory} setisRefDownload={setisRefDownload} isRefDownload={isRefDownload} setisDownloadBtnShow={setisDownloadBtnShow} isDownloadBtnShow={isDownloadBtnShow}/>}</>}
							{activeTab == 'Custom Dashboard' && <GSM_Custom_Dashboard Profile={Profile} setProfile={setProfile} AccounData={AllCategory?.category0} GaData={AllCategory?.category1} CircleData={AllCategory?.category2} DivisionData={AllCategory?.category3} SubdevisionData={AllCategory?.category4} AreaData={AllCategory?.category5} AllCategoryJson={AllCategory} FilterDay={FilterDay} onGetFilterDaywise={onGetFilterDaywise} setFilterDay={setFilterDay} setisRefDownload={setisRefDownload} isRefDownload={isRefDownload} setisDownloadBtnShow={setisDownloadBtnShow} isDownloadBtnShow={isDownloadBtnShow}/>}
						</div>
					</div>
				</section>
			</div>
		</>
	)
}
