
import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Lock, Users } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { BootstrapTooltip } from '../../Commoncomponet/common'
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import dt from "datatables.net-dt";
import jsPDF from "jspdf";
import { getAllMeter, getAllModem, getGSMSmartMeter } from '../../Commoncomponet/Utils'
import { Modal } from 'antd'
import moment from 'moment'
import chroma from 'chroma-js'
import Category from './Category'
import Type from './Type'
import Make from './Make'
import Class from './Class'
import MeterNumber from './MeterNumber'
import Hardware from './Hardware'
import Firmware from './Firmware'
import Software from './Software'
$.fn.DataTable = dt;
export default function All() {
	const buttonArray = ["GSM Smart Meter", "GSM Smart Meter Type", "GSM Smart Meter Make","GSM Smart Meter Class",'GSM Smart Meter Category', "GSM Smart Meter Model Number", 'GSM Smart Meter Hardware Protocol','GSM Smart Meter Software Protocol'];
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece','#f59e0b','#fc544b']
	const [ModemData, setModemData] = useState([])
	const [filterrolesData, setfilterRolesData] = useState([])
	const [roleFilter, setRoleFilter] = useState({ name: '', gstm_status: '' })
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [loader, setLoader] = useState(false)
	const history = useHistory()
	const [csvData, setCsvData] = useState([])
	const [btnText, setButtontext] = useState("GSM Smart Meter");

	const ongetRolesUserData = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getGSMSmartMeter()
		if (data.success === true) {
			setModemData(data.data.data)
			setfilterRolesData(data.data.data)
		} else {
			console.log('somthing want wrong')
			setLoader(false)
		}
	}
	const csvLink = useRef();

	const downloadReport = async (isCsv) => {
		if (ModemData.length > 0) {
			const finalCsvData = await ModemData.map((item) => ({ 'srno': item.srno, 'Unique ID': item.gstm_unique_id, 'Created Time': moment(item.created_at).format('DD-MM-YYYY'), 'Created By': item.created_by, 'Name': item.gstm_profile_name, 'Status': (item.gstm_status == 1 ? 'Active' : 'InActive') }))
			setCsvData(finalCsvData)
			if (isCsv === true) {
				if (finalCsvData.length > 0) {
					csvLink.current.link.click();
				}
			}
			else {
				if (finalCsvData.length > 0) {
					const worksheet = XLSX.utils.json_to_sheet(finalCsvData);
					const workbook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
					XLSX.writeFile(workbook, `All-meter-data.xlsx`);
				}
			}
		}
	}
	useEffect(() => {
		ongetRolesUserData().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({ "pageLength": 50, order: [[0, 'desc']] });
				setLoader(false);

			}, 700)
		);
	}, []);
	const onHandalebtn = (text) => {
		setButtontext(text);
	};

	const UpdateRolesUser = (item) => {
		history.push({ pathname: `/Add-GSM-Smart-Meter-driver`, state: item })
	}
	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const onhandleOk = () => {
		setIsModalVisible(false)
		if (roleFilter.name === '' && roleFilter.gstm_status === '') {
			return setModemData(filterrolesData)
		}
		const filteData = filterrolesData && filterrolesData.filter((item) => (roleFilter.name !== '' ? item?.gstm_profile_name?.includes(roleFilter.name) : []) && (roleFilter.gstm_status !== '' ? item?.gstm_status == roleFilter.gstm_status : []))
		setModemData(filteData)
	}

	return (
		<>
			<Modal title="Filter GSM Smart Meter" visible={isModalVisible} footer="" onCancel={handleCancel}>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputState">GSM Smart Meter Name:</label>
						<input type="text" class="form-control" id="" onChange={(e) => (setRoleFilter({ ...roleFilter, name: e.target.value }))} value={roleFilter.name} name="FirstName" placeholder="Enter GSM Smart Meter Name" />
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">GSM Smart Meter Status:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={(e) => (setRoleFilter({ ...roleFilter, gstm_status: e.target.value }))}
							value={roleFilter.gstm_status}
						>
							<option value="">Select Status</option>
							<option value={1}>Active</option>
							<option value={0}>InActive</option>
						</select>
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to={`/allEVC`}>
						Reset
					</Link>
				</div>
			</Modal>
			<div className="main-content">
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								<Lock style={{ height: "16px" }} />Admin
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								GSM Smart Meter Driver
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								All GSM Smart Meter
							</Link>
						</li>
					</ol>
				</nav>
				<div className="row">
					<div className="col-12">
						<div className="card pb-2">
							<div className="col-12" style={{ marginTop: "15px" }}>
								<div className="buttons">
									{buttonArray.map((item, id) => (
										<button
											style={{
												backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
												color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
												borderBottom: `1px solid ${btnColor[id]}`,
												boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
											}}
											className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
											onClick={() => onHandalebtn(item)}>
											{item}
										</button>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
				{btnText=='GSM Smart Meter'&&<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h4><span className='mr-2'>GSM Smart Meter</span><BootstrapTooltip title={`Table Name is gsm_smart_meter_driver_index`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div className="card-header-action">
											<Link to="/Add-GSM-Smart-Meter-driver" className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
											<button type="button" className="btn btn-info mr-2" onClick={() => (setIsModalVisible(true))}>
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											<div className="dropdown d-inline mr-2">
												<button
													className="btn btn-info dropdown-toggle mr-2"
													type="button"
													id="dropdownMenuButton3"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													data-placement="bottom"
													title="Download"
												>
													<i className="fa fa-download mr-1"></i>
												</button>
												<div className="dropdown-menu">
													<a className="dropdown-item" >
														PDF
													</a>
													<a onClick={() => downloadReport(false)} className="dropdown-item" >
														Excel
													</a>
													<a onClick={() => downloadReport(true)} className="dropdown-item">
														CSV
													</a>
													<CSVLink data={csvData} filename={"All-meter-data.csv"} ref={csvLink} />
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table border stripe" id="table-1">
												<thead>
													<tr>
														<th className="text-center !tw-font-semibold" style={{ maxWidth: "100px" }}>Unique No</th>
														<th className='!tw-font-semibold'>Name</th>
														<th className='!tw-font-semibold'>Type</th>
														<th className='!tw-font-semibold'>Unique Id</th>
														<th className='!tw-font-semibold' style={{ maxWidth: "100px" }}>Status</th>
													</tr>
												</thead>
												<tbody>
													{ModemData &&
														ModemData.map((item, id) => (
															<tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id} onClick={() => (UpdateRolesUser(item))}>
																<td className="text-center">{item?.srno}</td>
																<td>{item?.gstm_profile_name}</td>
																<td>{item?.gstm_type_name}</td>
																<td>{item?.gstm_unique_id}</td>
																<td className="">
																	<div className={`badge ${item?.gstm_status == 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item?.gstm_status == 1 ? 'Active' : 'Inactive'}</div>
																</td>
															</tr>
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>}
				{btnText=='GSM Smart Meter Type'&&<Type/>}
				{btnText=='GSM Smart Meter Make'&&<Make/>}
				{btnText=='GSM Smart Meter Class'&&<Class/>}
				{btnText=='GSM Smart Meter Model Number'&&<MeterNumber/>}
				{btnText=='GSM Smart Meter Hardware Protocol'&&<Hardware/>}
				{btnText=='GSM Smart Meter Firmware'&&<Firmware/>}
				{btnText=='GSM Smart Meter Category'&&<Category/>}
				{btnText=='GSM Smart Meter Software Protocol'&&<Software/>}
			</div>
		</>
	)
}
