import React from 'react'
import addClientImage from '../../assets/img/API/api_docs_add_client.png'
import clientSecretImage from '../../assets/img/API/api_docs_client_secret.png'
import generateCodeImage from '../../assets/img/API/api_docs_generate_code.png'
import authCodeImage from '../../assets/img/API/api_docs_auth_code.png'

const OAuthDocs = () => {
    return (

        <div className='tw-text-base'>
            <h2 className='tw-text-center'>OAuth 2.0 Authentication</h2>
            <h4>Overview:</h4>
            <p className=''>OAuth (Open Authorization) is a secure way to grant third-party applications access to your API. This guide provides step-by-step instructions for generating OAuth tokens to access the API.Follow the steps below to generate OAuth tokens.</p>

            <h4 className='tw-text-lg'>Step 1: Registering a Client</h4>
            <ol>
                <li className='tw-my-2'>Go to  https://gumsops.memighty.com/api/api-console OR {"in Integration > API > API Console tab"}.</li>

                <li className='tw-my-2'>Click the "Add Client" button on the top right of the API console card.
                </li>
                <img src={addClientImage} alt='Add Client' className='tw-w-1/2 tw-h-1/2 tw-my-1 border' ></img>
                <li className='tw-my-2'>Click the "Client Secret" tab and copy the Client ID and Client Secret. Save them as you'll need them later.
                </li>
                <img src={clientSecretImage} alt='Client secret' className='tw-w-1/2 tw-h-1/2 tw-my-1 border' ></img>
            </ol>

            <h4 className='tw-text-lg'>Step 2: Making the Authorization Request</h4>
            <ol>
                <li className='tw-my-2'>On the "Generate Code" tab, enter the API name, scope, time duration for OAuth code, and scope description.
                </li>
                <img src={generateCodeImage} alt='Generate Code' className='tw-w-1/2 tw-h-1/2 tw-my-1 border' ></img>
                <li className='tw-my-2'>Verify that the entered details and selected scope are correct.</li>
                <li className='tw-my-2'>Click the "Create" button to generate an OAuth code.</li>
                <li className='tw-my-2'>A pop-up appears displaying the OAuth code. Copy the OAuth code and save it.
                </li>
                <img src={authCodeImage} alt='Generate Code' className='tw-w-1/2 tw-h-1/2 tw-my-1 border' ></img>
            </ol>

            <h4 className='tw-text-lg'>Step 3: Generating Access Tokens and Refresh Tokens</h4>
            <p>To generate access and refresh tokens, make a POST request with the following URL:</p>
            <pre><code> https://gumsops.memighty.com:7002/oauth/token</code></pre>
            <h3>Request Parameters: </h3>
            <p>In the body of your request as form-data, pass the following parameters:</p>
            <table className='api-input-params'>
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>grant_type</td>
                        <td>Enter the value as "authorization_code".</td>
                    </tr>
                    <tr>
                        <td>client_id</td>
                        <td>Specify the client ID obtained from the earlier step.</td>
                    </tr>
                    <tr>
                        <td>client_secret</td>
                        <td>Specify the client secret obtained from the earlier step.</td>
                    </tr>
                    <tr>
                        <td>code</td>
                        <td>Enter the OAuth code generated from the previous step.</td>
                    </tr>
                </tbody>
            </table>
            <h3>Example Request: </h3>
            <pre><code>{`POST https://gumsops.memighty.com:7002/oauth/token
Content-Type: application/x-www-form-urlencoded
grant_type=authorization_code&client_id={client_id}&client_secret={client_secret}&code={auth_code}`}</code></pre>
            <p>If the request is successful, you will receive the following response:</p>
            <pre>
                {`{
    "access_token": "{access_token}",
    "token_type": "Bearer",
    "expires_in": 3600,
    "refresh_token": "{refresh_token}"
}`}
            </pre>

            <h4 className='tw-text-lg'>Step 4: Refreshing Your Tokens</h4>
            <p>To generate new access tokens from the refresh token, make a POST request with the following URL:</p>
            <pre><code> https://gumsops.memighty.com:7002/oauth/token</code></pre>
            <h3>Request Parameters: </h3>
            <p>In the body of your request as form-data, pass the following parameters:</p>
            <table className='api-input-params'>
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>grant_type</td>
                        <td>Enter the value as "refresh_token".</td>
                    </tr>
                    <tr>
                        <td>client_id</td>
                        <td>Specify the client ID obtained from the earlier step.</td>
                    </tr>
                    <tr>
                        <td>client_secret</td>
                        <td>Specify the client secret obtained from the earlier step.</td>
                    </tr>
                    <tr>
                        <td>refresh_token</td>
                        <td>Enter the refresh token generated from the previous step.</td>
                    </tr>
                </tbody>
            </table>
            <h3>Example Request: </h3>
            <pre><code>{`POST https://gumsops.memighty.com:7002/oauth/token
Content-Type: application/x-www-form-urlencoded
grant_type=refresh_token&client_id={client_id}&client_secret={client_secret}&refresh_token={refresh_token}`}</code></pre>
            <p>If the request is successful, you will receive the following response:</p>
            <pre>
                {`{
    "access_token": "{new_access_token}",
    "expires_in": 3600,
    "token_type": "Bearer",
    "refresh_token": "{new_refresh_token}"
}`}
            </pre>
            <p><b>Note: </b>
                Access tokens and refresh tokens have a limited lifespan. To keep accessing the API endpoints, generate a new access token using the refresh token. Once you receive a new access token and refresh token, use the new access token to access the API endpoints and save the new refresh token for generating another access token when the current one expires.
            </p>
        </div>
    )
}
export default OAuthDocs


