import React, { Suspense, useEffect, useState } from 'react'
import { GetCountForMissingDataUserAndRoles, GetCountUseAndRolesDataAnalytics } from '../../Commoncomponet/Utils';
import chroma from 'chroma-js';
import { Spin } from 'antd';
import { Modal as BootstrapModal } from 'react-bootstrap';
import {Link} from 'react-router-dom'

export default function MissingDataInUserAndRoles() {
  const buttonArray = ["Users", "Roles"];
  const [BadData, setBadData] = useState({})

  const [TableData, setTableData] = useState({})
  const [isLoader, setisLoader] = useState(false)
  const [btnText, setButtontext] = useState("Users");
  const [ModalLoad, setModalLoad] = useState(false)
  const [ModelId, setModelId] = useState('')
  const [isModalVisible,setisModalVisible]= useState('')
  const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']

  const user_column = [{ lable: 'FIRST_NAME', key: 'FIRST_NAME' }, { lable: 'LAST_NAME', key: 'LAST_NAME' }, { lable: 'EMAIL', key: 'EMAIL' }, { lable: 'MOBILE', key: 'MOBILE' }, { lable: 'PASSWORD', key: 'PASSWORD' }, { lable: 'PROFILE_PHOTO', key: 'PROFILE_PHOTO' }, { lable: 'ROLE', key: 'ROLE' }]
  const roles_column = [{ lable: 'ROLES_NAME', key: 'ROLES_NAME' }, { lable: 'ROLES_DISCRIPTION', key: 'ROLES_DISCRIPTION' }, { lable: 'ROLES_PERMISSIONS_CATEGORY0', key: 'ROLES_PERMISSIONS_CATEGORY0' }, { lable: 'ROLES_PERMISSIONS_CATEGORY1', key: 'ROLES_PERMISSIONS_CATEGORY1' }, { lable: 'ROLES_PERMISSIONS_CATEGORY2', key: 'ROLES_PERMISSIONS_CATEGORY2' }, { lable: 'ROLES_PERMISSIONS_CATEGORY3', key: 'ROLES_PERMISSIONS_CATEGORY3' }, { lable: 'ROLES_PERMISSIONS_CATEGORY4', key: 'ROLES_PERMISSIONS_CATEGORY4' }, { lable: 'ROLES_PERMISSIONS_CATEGORY5', key: 'ROLES_PERMISSIONS_CATEGORY5' }]

  const roles_cat_lookup={
    ROLES_PERMISSIONS_CATEGORY0:'CATEGORY0_view',
    ROLES_PERMISSIONS_CATEGORY1:'CATEGORY1_view',
    ROLES_PERMISSIONS_CATEGORY2:'CATEGORY2_view',
    ROLES_PERMISSIONS_CATEGORY3:'CATEGORY3_view',
    ROLES_PERMISSIONS_CATEGORY4:'CATEGORY4_view',
    ROLES_PERMISSIONS_CATEGORY5:'CATEGORY5_view'
  }

  const onGetCountForMissingDataUserAndRoles = async () => {
    setisLoader(true)
    const data = await GetCountForMissingDataUserAndRoles({ tableName: btnText == 'Users' ? 'user_index_dev' : 'roles_index_dev' })
    if (data.success === true) {
      setBadData(data?.data?.data[0] || {})
      setisLoader(false)
    } else {
      setisLoader(false)
    }
  }

  useEffect(() => {
    onGetCountForMissingDataUserAndRoles()
  }, [btnText])

  const onHandalebtn = (text) => {
    setButtontext(text);
  };

  const GetConsumerIncolumeName = async ( col, colname, value) => {
    setisModalVisible(true)
    setModalLoad(true)

    setModelId(`${colname}`)
    const data =  await GetCountUseAndRolesDataAnalytics({StatusColName: col, value: value, tableName: btnText=='Users'?'user_index_dev':'roles_index_dev' })
    if (data.success === true) {
        setTableData(data.data.data)
        if (data.data.data.length > 0) {
        }
        setModalLoad(false)

    } else {
        setModalLoad(false)
        console.log('somthing want wrong')
    }
}

  return (
    <>
    {<Suspense fallback={<><Spin size="large" /></>}>
            <BootstrapModal className="my-modal-class" show={isModalVisible} onHide={() => { setisModalVisible(false)
            setTableData([]) }} size={"xl"} >
                <BootstrapModal.Header closeButton><BootstrapModal.Title style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                    {ModelId} Not Available
                   
						
                </BootstrapModal.Title></BootstrapModal.Header>
                <BootstrapModal.Body style={{ maxHeight: '90vh', overflow: 'auto', paddingTop: '0px' }}>
                    <div className="modal-table">
                        {ModalLoad ? (
                            <div className="example">
                                <Spin size="large" />
                            </div>
                        ) : <table className="table border table-borderless" id="table-1">
                            <thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
                                <tr>
                                    <th className="text-center">SR NO.</th>
                                    <th className="text-center">Unique Id</th>
                                    {btnText==='Users'?<th className="text-center">User Name</th>:<th className="text-center">Role Name</th>}
                                    {btnText==='Users'?<th className="text-center">Email</th>:''}
                                </tr>
                            </thead>
                            <tbody id="tbody">
                                <>
                                    {TableData.length > 0 ?
                                        TableData.map((data, id) => (
                                            <tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]" key={id} >
                                                <td className="text-center">{id + 1}</td>
                                                <td className="text-center">{btnText==='Users'?data?.UNIQUE_ID:data?.ROLES_UNIQUE_ID}</td>
                                                {btnText==='Users'? <td className="text-center">{data?.FIRST_NAME+data?.LAST_NAME}</td>:<td>{data?.ROLES_NAME}</td>}
                                                {btnText==='Users'?<td className="text-center">{data?.EMAIL}</td>:''}
                                            </tr>
                                        )) : <tr></tr>}
                                </>
                            </tbody>
                        </table>}
                        {TableData.length === 0 && !ModalLoad && <h4 className="text-center">No Data Found</h4>}
                    </div>
                </BootstrapModal.Body>
            </BootstrapModal>
        </Suspense>
        }
      <section className="section">
        <div class="section-body">
          <div class="row">
            <div class="col-12 col-sm-12 col-lg-12 tw-p-0">
              <div class="card">
                {/* <div class="card-header" > */}
                {/* <h3 className='mb-0'>Bad Data Analytics</h3> */}
                {/* </div> */}
                <div className="d-flex justify-content-between ml-4 mt-2 mb-4">
                  <div className="buttons">
                    {buttonArray.map((item, id) => (
                      <button
                        style={{
                          backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
                          color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
                          borderBottom: `1px solid ${btnColor[id]}`,
                          boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
                        }}
                        className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                        onClick={() => onHandalebtn(item)}>
                        {item}
                      </button>
                    ))}
                    </div>
                    </div>
                    <div className="table-responsive">
                      <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                        <div class="dataTables_length" id="table_id_length">

                        </div>
                        <table id="table_id" className="table table-bordered text-center   font-13">
                          <thead>
                            <tr>
                              <th>SRNO</th>
                              <th>Fields</th>
                              <th>Null Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(btnText==='Users'?user_column:roles_column).map((item, id) => (
                              <>
                                <tr className="" >
                                  <td >{id + 1}.</td>
                                  <td>{item?.lable}</td>
                                  <td><button class="tw-font-medium tw-text-white tw-px-2 tw-rounded hover:tw-bg-indigo-100 tw-border tw-border-transparent hover:tw-border-gray-300 tw-transition-all" style={{ backgroundColor: 'rgb(58, 186, 244)' }} onClick={()=>GetConsumerIncolumeName(btnText=='Users'?'STATUS':'ROLES_STATUS',item?.lable,btnText=='Users'?`${item?.key}='' OR ${item?.key}='[]' OR ${item?.key} IS null`:item?.key=='ROLES_NAME'||item?.key=='ROLES_DISCRIPTION'?`${item?.key}='' OR ${item?.key}='[]' OR ${item?.key} IS null`: `JSON_VALUE(${item?.key},'$.${roles_cat_lookup[item?.key]}')='' OR JSON_VALUE(${item?.key},'$.${roles_cat_lookup[item?.key]}')='[]' OR JSON_VALUE(${item?.key},'$.${roles_cat_lookup[item?.key]}') IS null`)}>{BadData[item?.key] || 0}</button></td>
                                </tr>
                              </>

                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
