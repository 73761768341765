import { Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader';
import { getApiHistory, GetRegisteredApiByUNIQUE_ID } from '../../Commoncomponet/Utils';
// import ReactJson from 'react-json-view'
export default function ApiHistory() {
    const [ApiData, setApidata] = useState([])
    const params = useParams();
    const [logData, setLogData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isConsumerInfo, setIsConsumerInfo] = useState(false)
    const [isDuplicateInfo, setIsDuplicateInfo] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [isNullData, setIsNullData] = useState(false)
    const [consumerInfoModalData, setConsumerInfoModalData] = useState([])
    const [duplicateInfoModalData, setDuplicateInfoModalData] = useState([])
    const [InfoModalData, setInfoModalData] = useState({})
    const [nullDataInfoModalData, setNullDataInfoModalData] = useState([])
    const [numberofPage, setnumberofPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const onGetApiInformation = async () => {
        const data = await GetRegisteredApiByUNIQUE_ID(params?.UNIQUE_ID)
        if (data.success == true) {
            setApidata(data.data.response)
            fetchData(JSON.parse(data?.data?.response[0]?.API_TABLE_NAME || '{}')?.HISTORY_TABLE)
        } else {

        }
    }

    useEffect(() => {
        onGetApiInformation()
    }, [])

    const fetchData = async (table) => {
        setIsLoading(true)
        const firstPageIndex = (currentPage - 1) * PageSize;
        const repData = await getApiHistory({ tableName: table, start: firstPageIndex, end: PageSize })
        if (repData.success === true) {
            console.log(repData.data.LogDetails)
            setLogData(repData.data.LogDetails)
            setnumberofPage(repData.data.count)
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
    }

    const refreshClick = () => {
        setCurrentPage(1)
        fetchData(JSON.parse(ApiData[0]?.API_TABLE_NAME || '{}')?.HISTORY_TABLE)
    }

    useEffect(() => {
        fetchData(JSON.parse(ApiData[0]?.API_TABLE_NAME || '{}')?.HISTORY_TABLE)
    }, [currentPage, PageSize]);

    const onConsumerInfoClick = (info) => {
        setConsumerInfoModalData(info)
        setIsConsumerInfo(true)

    }

    const onDuplicatevalueClick = (info) => {
        setDuplicateInfoModalData(info)
        setIsDuplicateInfo(true)

    }

    const onNullDataFoundClick = (info) => {
        setNullDataInfoModalData(info)
        setIsNullData(true)

    }
    const onInfoClick = (info) => {
        setInfoModalData(info)
        setIsInfo(true)

    }
    return (
        <>
            <Modal title="Request Parameter" visible={isConsumerInfo} footer='' onCancel={() => setIsConsumerInfo(false)} width={1500}>
                <pre>{JSON.stringify(consumerInfoModalData, null, 2)}</pre>
            </Modal>
            <Modal title="DUPLICATE_VALUE" visible={isDuplicateInfo} footer='' onCancel={() => setIsDuplicateInfo(false)} width={1500}>
                {duplicateInfoModalData.length === 0 ? <div className='tw-text-center'>No Data Found</div> : <div className='table-responsive'>
                    <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                        <thead>
                            <tr>
                                <th>UNIQUE_ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {duplicateInfoModalData.length > 0 && duplicateInfoModalData.map(info => (
                                <tr>
                                    <td>{info?.UNIQUE_ID}</td>
                                </tr>
                            ))}

                        </tbody>

                    </table>
                </div>}
            </Modal>
            <Modal title="Response" visible={isInfo} footer='' onCancel={() => setIsInfo(false)} width={1500}>
                <div className='' style={{ height: '100vh', overflow: 'scroll' }}>
                    <pre>{JSON.stringify(InfoModalData, null, 2)}</pre>
                </div>
                {/* <ReactJson src={InfoModalData} /> */}
                {/* <div className='table-responsive'>
        <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
            <thead>
                <tr>
                    {Object.keys(InfoModalData).length>0?Object.keys(InfoModalData&&InfoModalData?.DATA[0]||{}).map((item)=>(<th>{item}</th>)):''}
                </tr>
            </thead>
            <tbody>
                {Object.keys(InfoModalData).length>0?InfoModalData&&InfoModalData?.DATA?.map((info)=>(
                    <tr>
                       {Object.keys(InfoModalData&&InfoModalData?.DATA[0]||{}).map((item)=>(<td>{info[item]}</td>))}
                    </tr>
                )):''}
               
            </tbody>

        </table>
    </div> */}
            </Modal>
            <Modal title="NULL_DATA_FOUND" visible={isNullData} footer='' onCancel={() => setIsNullData(false)} width={1500}>

                {nullDataInfoModalData.length === 0 ? <div className='tw-text-center'>No Data Found</div> : <div className='table-responsive'>
                    <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                        <thead>
                            <tr>
                                <th>UNIQUE_ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nullDataInfoModalData.length > 0 && nullDataInfoModalData.map(info => (
                                <tr>
                                    <td>{info?.UNIQUE_ID}</td>
                                </tr>
                            ))}

                        </tbody>

                    </table>
                </div>}
            </Modal>
            <div className="main-content">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/all-dashboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link>Api History</Link>
                        </li>
                    </ol>
                </nav>
                <section class="section">
                    <div class="section-body">
                        <div class="row">

                            <div class="col-12 col-sm-12 col-lg-12 tw-p-0">
                                <div class="card">
                                    <div className='tw-mt-2 tw-w-full tw-mx-auto tw-px-0'></div>
                                    <div class=" tw-w-full">
                                        <div class="card-header">
                                            <h4 class="tw-w-full ">{ApiData[0]?.API_NAME}</h4>
                                            <div class="card-header-action">
                                                <div id="filtergrm">
                                                    <button class="btn btn-info text-white" onClick={refreshClick} ><i class="fa fa-refresh" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            {isLoading ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                                                <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                                            </div> :
                                                <div class="table-responsive">
                                                    <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                                        <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th>SRNO</th>
                                                                    <th>REQUEST_URL</th>
                                                                    <th>REQUEST PARAMETERS</th>
                                                                    <th>RESPONSE</th>
                                                                    <th>Entry Date & Time</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {logData.map(row => (
                                                                    <tr key={row.SR_NO}>
                                                                        <td className='tw-px-1 tw-py-3'>{row?.SRNO}</td>
                                                                        <td className='tw-px-1 tw-py-3'>{row?.REQUEST_URL}</td>
                                                                        <td className='tw-px-1 tw-py-3'><button className='btn btn-info btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onConsumerInfoClick(JSON.parse(row?.REQUEST_PARAMETERS))}><i className="fa-solid fa-info"></i></button></td>

                                                                        <td className='tw-px-1 tw-py-3'><button className='btn btn-primary btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onInfoClick(JSON.parse(row?.RESPONSE))}><i className="fa-solid fa-info"></i></button></td>
                                                                        <td className='tw-px-1 tw-py-3'>{row?.ENTRYTS ? moment.utc(row?.ENTRYTS).format("DD-MM-YYYY hh:mm:ss A") : '-'}</td>

                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                        <>
                                                            <div className="d-flex justify-content-between">
                                                                <div>Total Number Of Row {numberofPage}</div>
                                                                <div class="dataTables_length" id="table_id_length">
                                                                    <label>
                                                                        Show{" "}
                                                                        <select
                                                                            name="table_id_length"
                                                                            aria-controls="table_id"
                                                                            class=""
                                                                            onChange={(e) => setPageSize(e.target.value)}
                                                                            value={PageSize}
                                                                        >
                                                                            <option value={10}>10</option>
                                                                            <option value={25}>25</option>
                                                                            <option value={50}>50</option>
                                                                            <option value={100}>100</option>
                                                                        </select>{" "}
                                                                        entries
                                                                    </label>
                                                                </div>

                                                                <Pagination
                                                                    className="pagination-bar"
                                                                    currentPage={currentPage}
                                                                    totalCount={numberofPage || 1}
                                                                    pageSize={PageSize}
                                                                    onPageChange={(page) => setCurrentPage(page)}
                                                                />
                                                            </div>
                                                        </>
                                                    </div>

                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
