import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Loader.css';

function ChartLoaders({ className, ...rest }) {
    return (
        <div className={classNames('chart-loader', className)} {...rest}>
            <div className="chart-loader-bar chart-loader-bar-1"></div>
            <div className="chart-loader-bar chart-loader-bar-2"></div>
            <div className="chart-loader-bar chart-loader-bar-3"></div>
            <div className="chart-loader-bar chart-loader-bar-4"></div>
        </div>
    );
}

ChartLoaders.propTypes = {
    className: PropTypes.string,
};

export default ChartLoaders;
