import { useLocation } from "react-router-dom";
import './documentation.css'
import OAuthDocs from "./OAuthDocs";

function Documentation({ data }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    console.log("data 1 = ", data)
    console.log("data = ", id)
    // Find the index of the endpoint with the specified ID

    const index = data.endpoints.findIndex((endpoint) => endpoint.id == id);
    console.log("index = ", index)
    if (index === -1) {
        return (
            <> <div className="api"><OAuthDocs /></div></>
        )
    }
    const endpoint = index !== -1 ? data.endpoints[index] : '';

    return (
        <div className="api">
            <h2 className="tw-text-center">{endpoint.title}</h2>
            <h4>Overview:</h4>
            <p className="api-purpose"> {endpoint.purpose}</p>
            <h3>Endpoint URL: </h3>
            <pre><code className="url"> {endpoint.url} </code></pre>
            <h3>HTTP Method:</h3>
            <pre className="api-method"> {endpoint.method}</pre>
            <h3>Authentication:</h3>
            <p className="api-auth">Authentication: {endpoint.authentication}</p>
            {endpoint.input_parameters ? (
                <div>
                    <h3>Input Parameters {endpoint.id == 3 ? ' (JSON format) :' : ''}</h3>
                    <table className="api-input-params">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Required</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(endpoint.input_parameters).map((param, index) => (
                                <tr key={index}>
                                    <td>{param}</td>
                                    <td>{endpoint.input_parameters[param].required ? 'Yes' : 'No'}</td>
                                    <td>{endpoint.input_parameters[param].description.split('\n').map((text)=>(<>{text}<br/></>))}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) :
                <>
                    <h3>Input Parameters:</h3>

                    <p className="api-input-params">This endpoint does not require any input parameters.</p>
                </>
            }
            <h3>Output:</h3>
            <p className="api-output">Output: {endpoint.output}</p>

            <h3>Example Request:</h3>
            <pre>{endpoint.example_request}</pre>
            <h3>Example Response: </h3>
            <pre>{JSON.stringify(endpoint.example_response, null, 2)}</pre>
            {endpoint.error_codes ? (
                <div className="table-responsive">
                    <h3>Error Codes & Cause :</h3>
                    <table className="table border stripe tw-w-3/4">
                        <thead className="border-bottom">
                            <tr>
                                <th className="tw-w-1/3 border-right">Error Code</th>
                                <th className="tw-w-1/3 border-right">Cause</th>
                                <th className="tw-w-1/3">Description</th>

                            </tr>
                        </thead>
                        <tbody>
                            {(endpoint.error_codes).map((param, index) => (
                                <tr key={index}>
                                    <td className="tw-w-1/3 border-right">{param.code}</td>
                                    <td className="tw-w-1/3 border-right">{param.cause}</td>
                                    <td className="tw-w-1/3 ">{param.description}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) :
                null
            }
        </div>
    );
}

export default Documentation;
