import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Pagination from '../../../Commoncomponet/Pagination';
import { activeandinactive, cancalGSMBuffer, CommandGenerate, geModemIdWiseColumnName, getGSMBufferData, getGSMBufferDataAllCountByStatus, getGSMBufferDataByGSMID, getScriptCodeIdWiseColumnName, RetryGSMBuffer } from '../../../Commoncomponet/Utils';
import swal from 'sweetalert2';
import { BootstrapTooltip, socket } from '../../../Commoncomponet/common';
import { DatePicker, Modal, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
window.Swal = swal;
export default function Buffer(props) {

  const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
    const [BufferData,setBufferData]=useState([])
	const [loader, setLoader] = useState(true)
	const [BufferCount, setBufferCount] = useState(true)
	const [BufferCountShow, setBufferCountShow] = useState(false)
	const [ismodel,setModel]=useState(false)
	const [CMDDate,setCMDDate]=useState(moment().add(-moment().utcOffset(), "m").add(-1,'days').format('DD-MM-YYYY 06:00:00'))
	const [ResponsApi,setResponsApi]=useState({})
	const [CmdLoader,setCmdLoader]=useState(false)
	const [BufferStatus,setBufferStatus]=useState('')

const ongetGSMBufferData=async(info)=>{
    setLoader(true)
	setBufferData([])
    const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
    const data=await getGSMBufferDataByGSMID({ start: firstPageIndex, end: PageSize,tableName:props.GSMdata.UNIQUE_TABLE_NAME
		.gsmbuffer,info:info },props?.GSMdata?.UNIQUE_ID)
    if(data.success===true){
        setBufferData(data.data.data)
				setnumberofPage(parseInt(data.data.totalCount))
    setLoader(false)
    }else{
console.log('something want wrong')
        setLoader(false)
    }
}

useEffect(()=>{
	ongetGSMBufferData(BufferStatus)
},[BufferStatus])

const onHandalRetry=async(id)=>{
const data=await RetryGSMBuffer(id,props.GSMdata.UNIQUE_TABLE_NAME.gsmbuffer)
if(data.success===true){
    swal.fire('', 'Submit Success Retry Buffer', 'success');
    ongetGSMBufferData(BufferStatus)
}else{
    swal.fire('', 'Something Want Wrong', 'error');
}
}

const onHandalCancelBuffer=async(id)=>{
    const data=await cancalGSMBuffer(id,props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer)
    if(data.success===true){
        swal.fire('', 'Success Cancel Buffer', 'success');
        ongetGSMBufferData(BufferStatus)
    }else{
        swal.fire('', 'Something Want Wrong', 'error');
    }
    }

    const [ColumnName,setColumnName]=useState({})


	const ongetScriptCodeIdWiseColumnName=async()=>{
		const data=await geModemIdWiseColumnName(props?.GSMdata?.MODEM_TYPE)
        if(data.success===true){
            if(data?.data?.data?.length>0){
            setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
            }
        }else{
console.log('something want wrong');
        }
	}
useEffect(() => {
    ongetGSMBufferData(BufferStatus)
	ongetScriptCodeIdWiseColumnName()
	ongetGSMBufferDataAllCountByStatus()
}, []);

useEffect(() => {
    ongetGSMBufferData(BufferStatus)
	ongetGSMBufferDataAllCountByStatus()
}, [currentPage, PageSize])

const ongetGSMBufferDataAllCountByStatus=async()=>{
    const data=await getGSMBufferDataAllCountByStatus(props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer)//Buffer count data by status api
    if(data.success===true){
        setBufferCount(data?.data?.data)
        setBufferCountShow(true)
    }else{
        console.log("something want wrong")
    }
}
var [CommandmsgJson, setCommandmsgJson] = useState({})
useEffect(()=>{
	socket.on('mqttCommandResponse',(massges)=>{
		console.log(massges);
		setCommandmsgJson(massges)
	})
	
},[])

useEffect(()=>{
	onCheckMSG(CommandmsgJson)
},[CommandmsgJson])

const onCheckMSG=(massges)=>{
	console.log(massges,ResponsApi);
	if (massges?.REFRESH_STATUS == 'START' && ResponsApi?.MSGID == massges?.MSGID) {


	}else if(massges?.REFRESH_STATUS == "SUCCESS" && ResponsApi?.MSGID == massges?.MSGID){
		swal.fire('', massges?.REFRESH_STATUS, 'success')
		setCmdLoader(false)
		setModel(false)
	} else if (ResponsApi?.MSGID && ResponsApi?.MSGID == massges?.MSGID) {
		if (massges?.REFRESH_STATUS == 'IDLE') {
			
		} else if (massges?.REFRESH_STATUS == 'RUNNING') {
			
		} else {
			setCmdLoader(false)
			setModel(false)
		}
	}
	// if(ResponsApi?.MSGID==massges?.MSGID){
	// 	setLoader(false)
	// 	if(massges?.REFRESH_STATUS==='SUCCESS'){
	// 		toast.success()
	// 	}
	// }
}

const onCommandGenerate=async()=>{
	setCmdLoader(true)
	const data=await CommandGenerate({"CONSUMER_SRNO":props?.GSMdata?.SRNO,"DATA_RETRIVE_DATE":moment(CMDDate,'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),MSGID:new Date().getTime()})
	if(data.success==true){
		setResponsApi(data.data.data)
	}else{
		setCmdLoader(false)
	}
}

const onDeleteAllCommand = async () => {
	swal.fire({
	   title: "Are You Sure For Delete All Command",
	   icon: "warning",

	}).then(async (willDelete) => {
	   if (willDelete.isConfirmed) {
		  const data = await activeandinactive({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer, colName: 'STATUS', value: '2', matchId: BufferStatus=='RETRYCOUNT<0 AND STATUS=0'?'RETRYCOUNT<0 AND STATUS':BufferStatus=='RETRYCOUNT>= 0 AND STATUS=0'?'RETRYCOUNT>= 0 AND STATUS':'' }, '0')
		  if (data.success === true) {
			 toast.success('Success Delete All Command')
			 ongetGSMBufferData(BufferStatus)

		  } else {
			 toast.error('something want wrong')
		  }
	   }
	})
 }
  return (
    <>
	<Modal title="Generate Command" visible={ismodel} footer='' onCancel={()=>setModel(false)} width={500}>
	<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
									<div className="form-group col-md-12" id="strdate">
									{/* onChange={(e, dateString) => setReport({ ...report, start_date: dateString })}  */}
											<label for="inputPassword4">Select Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker format="DD-MM-YYYY HH:mm:ss" name='start' style={{ width: '100%', height: '40px' }}
												showTime
												onChange={(e, dateString)=>(setCMDDate(dateString))}
												defaultValue={CMDDate?moment(CMDDate,'DD-MM-YYYY HH:mm:ss'):''}
												disabledDate={(current) => {
													return current.isAfter(moment().add(-1, 'days'))
												}} />
										</div>
										</div>
										<div className="text-left ml-4">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${CmdLoader==true?'disabled btn-progress':''}`}  value="Generate" id="submitvn"  onClick={onCommandGenerate}/>
										<Link className="btn btn-light text-dark" onClick={()=>setModel(false)}>Cancel</Link>
									</div>
										</div>
										</div>
										</div>
										</section>
	</Modal>
      <section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
								<div className="card-header">
									<h4><BootstrapTooltip title={`Table Name is ${props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer}`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
									<div className="card-header-action">
										<div id="filtergrm">
											<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={()=>{ongetGSMBufferData(BufferStatus)
											ongetGSMBufferDataAllCountByStatus()}}>
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a>
											<a class="btn btn-danger text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Generate Command" onClick={()=>setModel(true)}>
												Generate Command
											</a>
										</div>
									</div>
								</div>
									<div className="card-body">
									
                                    {!loader&&<div id="allbtn " className='mb-3'> <button type="button" class="btn btn-dark btn-icon icon-left " onClick={()=>(setBufferStatus(''))}>All <span class="badge badge-transparent">{BufferCount?.total_length}</span></button>
            <button type="button" class="btn btn-success btn-icon icon-left ml-2" onClick={()=>(setBufferStatus('STATUS=1'))}>Success <span class="badge badge-transparent">{BufferCount?.success}</span></button>
            <button type="button" class="btn btn-warning btn-icon icon-left ml-2" onClick={()=>(setBufferStatus('RETRYCOUNT<0 AND STATUS=0'))}>Waiting For Downlink <span class="badge badge-transparent">{BufferCount?.wait_down}</span></button>
            <button type="button" class="btn btn-info btn-icon icon-left ml-2" onClick={()=>(setBufferStatus('STATUS=2'))}>Canceled By User <span class="badge badge-transparent">{BufferCount?.cancel}</span></button>
            <button type="button" class="btn btn-danger btn-icon icon-left ml-2" onClick={()=>(setBufferStatus('STATUS=-1'))}>Timeout <span class="badge badge-transparent">{BufferCount?.timeout}</span></button>
            <button type="button" class="btn btn-primary btn-icon icon-left ml-2" onClick={()=>(setBufferStatus('RETRYCOUNT>= 0 AND STATUS=0'))}>Waiting For Uplink <span class="badge badge-transparent">{BufferCount?.wait_up}</span></button>
			<button type="button" class="btn btn-dark btn-icon icon-left ml-2" onClick={()=>(setBufferStatus('STATUS=3'))}>CRC ERROR <span class="badge badge-transparent">{BufferCount?.CRC}</span></button>
			{BufferStatus=='RETRYCOUNT<0 AND STATUS=0'||BufferStatus=='RETRYCOUNT>= 0 AND STATUS=0'?<button className='btn btn-danger' style={{float:'right'}} onClick={onDeleteAllCommand}>All Command Cancel</button>:''}
        </div>}
		
		{loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)}
		{BufferData.length === 0 && !loader && <h4 className="text-center">No Data Found</h4>}

										{BufferData.length>0?<div className="table-responsive tw-h-screen tw-max-h-screen">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<table id="table_id" className="tableCustom text-center table table-striped font-10">
													<thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100's>
														<tr>
														{ ColumnName?.Modem_Data?.gsmbuffer?.length>0&&ColumnName?.Modem_Data?.gsmbuffer?.map((col)=>(col?.Display=="Yes"?<th className="" style={{ minWidth:col?.Type=='datetime'?'110px':'' }}><BootstrapTooltip title={col?.Column}><span>{col?.Header}</span></BootstrapTooltip></th>:''))}
															<th></th>
														</tr>
													</thead>
													<tbody>
														{BufferData.length > 0 && BufferData.map((item, id) => (
															<tr className="trheigth">
																 { ColumnName?.Modem_Data?.gsmbuffer?.length>0&&ColumnName?.Modem_Data?.gsmbuffer?.map((col)=>(col?.Display=="No"?'':col?.Type=='datetime'?col?.Column=='METER_STARTTS'||col?.Column=='METER_ENDTS'?<td style={{ minWidth: "100px" }}>{item[col.Column]?moment.unix(item[col.Column]).subtract(5.5, 'hours').format("DD-MM-YYYY hh:mm:ss A"):''}</td>:<td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>:col.Column=='STATUS'?<td>{item?.STATUS==1? <div class="badge badge-success badge-shadow">Success</div>:item?.RETRYCOUNT<0&&item?.STATUS==0?<div class="badge badge-warning badge-shadow">Waiting For Downlink</div>:item?.STATUS==2?<div class="badge badge-info badge-shadow">Canceled By User</div>:item?.STATUS==-1?<div class="badge badge-danger badge-shadow">Timeout</div>:item?.RETRYCOUNT>=0&&item?.STATUS==0?<div class="badge badge-primary text-white badge-shadow">Waiting For Uplink</div>:item?.STATUS==3?<div class="badge badge-secondary text-white badge-shadow">CRC ERROR</div>:''}</td>:col.Column=='TRIGGERSOURCE'?<td>{item?.TRIGGERSOURCE==0?<div class="badge badge-warning badge-shadow">EVC Script</div>:item?.TRIGGERSOURCE==1?<div class="badge badge-success badge-shadow">User</div>:''}</td>:<td>{item[col.Column]}</td>))}
																<td>{item?.STATUS==-1||item?.STATUS==3?<a onClick={()=>(onHandalRetry(item?.SRNO))} class='btn btn-icon btn-sm btn-info text-white' data-toggle='tooltip' title='Retry' data-placement='bottom'><i class='fa fa-repeat'></i></a>:item?.STATUS==0?<a class='btn btn-icon btn-sm btn-danger text-white' data-toggle='tooltip' onClick={()=>(onHandalCancelBuffer(item?.SRNO))} title='Canceled' data-placement='bottom'><i class='fa fa-times'></i></a>:item?.STATUS==2?<a onClick={()=>(onHandalRetry(item?.SRNO))} class='btn btn-icon btn-sm btn-info text-white' data-toggle='tooltip' title='Retry' data-placement='bottom'><i class='fa fa-repeat'></i></a>:''}</td>
															</tr>
														))}

													</tbody>
												</table>
												<><div className='d-flex justify-content-between'>
											<div>Total Number Of Row {numberofPage}</div>
											<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" class="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
											<Pagination
												className="pagination-bar"
												currentPage={currentPage}
												totalCount={numberofPage || 1}
												pageSize={PageSize}
												onPageChange={(page) => setCurrentPage(page)}
											/></div></>
											</div>
										</div>:''}
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
    </>
  )
}
