import React, { useEffect } from 'react'
import { useState } from 'react'
import {
   withScriptjs,
   withGoogleMap,
   GoogleMap,
   Marker,
   InfoWindow,
   Circle
} from "react-google-maps"
import { Link, useLocation, useParams,useHistory } from 'react-router-dom'
import mapicon from '../../assets/img/triangle-green.svg'
import mapBlueicon from '../../assets/img/tiangle-blue.svg'
import { getAllMapDataInGSM, getAllMapDataInGSMmapView, getAllMapDataInLorawan, getAllMapDataInLorawanmapView, getDataInLorawanGatewayList, getLorawanDataById,getAllMeter, getAllModem, getAreaById, getCircleById, getDivisionById, getGSMDataById, getGaById, getRolesById, getSubdevisionById, getTags,getAllMapDataInLoramapViewByFilter,getAccount } from '../../Commoncomponet/Utils'
import './Map.css'
import signal2 from '../../assets/img/secondsignal.png'
import signal3 from '../../assets/img/signal3.png'
import signal4 from '../../assets/img/signal4.png'
import signal5 from '../../assets/img/signal5.png'
import signal1 from '../../assets/img/onesignal.png'
import mapRedicon from '../../assets/img/triangle-red.svg'
import mapYellowicon from '../../assets/img/triangle-yellow.svg'
import tower from '../../assets/img/tower.png'
import moment from 'moment'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import MultiInput from '../../Commoncomponet/MultiInput/MultiInput'
import { DatePicker, Popover, Space, Switch } from 'antd'
import { BootstrapTooltip, exportExcelDownload } from '../../Commoncomponet/common';
import exports from '../../assets/img/export.png'

export default function LorawanMap() {
   const [mapInfo, setMapInfo] = useState([])
   const [GatewayInfo, setGatewayInfo] = useState([])
   const [permision, setpermision] = useState({})
   const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
   const [ModemInfoModem, setModemInfoModem] = useState(false)
   const [lastseen,setLastSeen]=useState('')
   const [lastseenIndate,setlastseenIndate]=useState('')
   const [GSMID, setGSMID] = useState('')
   const [GSMData, setGSMData] = useState({})
   const params = useLocation()
   const [isFilter, setIsFilter] = useState(false)
   const [filterObj, setFilterObj] = useState({})
   const [SubdevisionData, setSubdevisionData] = useState([]);
	const [DivisionData, setDivisionData] = useState([]);
	const [CircleData, setCircleData] = useState([]);
	const [GaData, setGaData] = useState([]);
	const [AccounData, setAccountData] = useState([]);
	const [AccountKey, setAccountKey] = useState([])
	const [AreaData, setAreaData] = useState([]);
	const [ModemData, setModemData] = useState([]);
	const [MeterData, setMeterData] = useState([]);
	const [tagsList, settagsList] = useState([])
	const [FilterLoader,setFilterLoader]=useState(false)
   const [isgatewayInfo,setisgatewayInfo]=useState(false)
   const [gatewayInfo,setgatewayInfo]=useState({})
   const [getwayIDHL,setgetwayIDHL]=useState('')
   const [isPoperover,setisPoperover]=useState(false)
   const [radiousDetail,setradiousDetail]=useState({isRedious:true,km:2,isConsumer:true})
	const [GatewayShowInfo, setGatewayShowInfo] = useState([])

	const history = useHistory()

   const [Profile, setProfile] = useState({
		accountuser: false,
		gauser: false,
		circleuser: false,
		areauser: false,
		divisionuser: false,
		subDivisionuser: false,
	})

	const [consumer, setConsumer] = useState({
		checktext1: false,
		checktext2: false,
		checktext3: false,
		checktext4: false,
		checktext5: false,
		checktext6: false,
		checktext7: false,
		checktextCS: false,
		checktextMP: false,
		checktextMOP: false,
		checktextTA: false,
		checktextInTS: false,
		checktextLSs: false,
		Consumer_Type: false,
		checktextDRTS:false
	})
	const [dates, setDates] = useState({ start: '', end: '' })
	const [modem, setModem] = useState({
		checktextRSSI: false,
		checktextLS: false,
		checktextBP: false,
	})


	const [modemStart, setModemState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})

	const [installState, setinstallState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})
	const [DRState, setDRState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})
	const [betteryPr, setbetteryPr] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})

	const [last, setlastSeen] = useState({
		LessEqual: false,
		GreaterEqual: false
	})

   useEffect(() => {
      if (params.search.split('?')[1]) {
         // console.log(params.search.split('last_seen')[1].split('=')[1]);
         if(params?.search?.split('last_seen')[1]){
         setLastSeen(parseInt(params?.search?.split('last_seen')[1]?.split('=')[1])||24)
         }else if(params?.search?.split('date')[1]){
            console.log(params?.search?.split('date')[1]?.split('=')[1]?.split('%22').join('"'));
            setlastseenIndate(JSON.parse(params?.search?.split('date')[1]?.split('=')[1]?.split('%22').join('"')))
         }else{
            setGSMID(params.search.split('?')[1])
         }
      }
   }, [params.search])

   const handleChangeS = (e) => {
		if (e.target.checked) {
			if (Profile.hasOwnProperty(e.target.id)) {
				setProfile({ ...Profile, [e.target.id]: true })
				setFilterObj({ ...filterObj, [e.target.name]: [] })

			}
			if (consumer.hasOwnProperty(e.target.id)) {
				setConsumer({ ...consumer, [e.target.id]: true })
				setFilterObj({ ...filterObj, [e.target.name]: '' })

			}
			if (modem.hasOwnProperty(e.target.id)) {
				setModem({ ...modem, [e.target.id]: true })
			}
			if (e.target.id == 'checktextLS') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextRSSI') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextInTS') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextBP') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextDRTS') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
		} else {
			if (Profile.hasOwnProperty(e.target.id)) {
				setProfile({ ...Profile, [e.target.id]: false })
				delete filterObj[e.target.name]
				// delete showfilterObj[e.target.name]
			}
			if (consumer.hasOwnProperty(e.target.id)) {
				setConsumer({ ...consumer, [e.target.id]: false })
				delete filterObj[e.target.name]
				// delete showfilterObj[e.target.name]
				if (e.target.id == 'checktextDRTS') {
					setDRState({ ...DRState, greaterThan: false, lessThan: false, Between: false })
				}
			}
			if (modem.hasOwnProperty(e.target.id)) {
				setModem({ ...modem, [e.target.id]: false })
				if (e.target.id == 'checktextRSSI') {
					delete filterObj[e.target.name]
					// delete showfilterObj[e.target.name]
					setModemState({ ...modemStart, greaterThan: false, lessThan: false, Between: false })
				}
				if (e.target.id == 'checktextBP') {
					delete filterObj[e.target.name]
					// delete showfilterObj[e.target.name]
					setbetteryPr({ ...betteryPr, greaterThan: false, lessThan: false, Between: false })
				}
				if (e.target.id == 'checktextLS') {
					console.log(e.target.id)
					delete filterObj[e.target.name]
					// delete showfilterObj[e.target.name]
					setlastSeen({ ...last, greaterThan: false, lessThan: false })
				}
			}
		}
	}

   const handleSelectChange = (e) => {
		const stateUpdate = Object.keys(modemStart)
		setModemState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, RSSI: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};

	const handleSelectChangeInstall = (e) => {
		const stateUpdate = Object.keys(modemStart)
		setinstallState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, Installation_Date: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};
	const handleSelectChangeDR = (e) => {
		const stateUpdate = Object.keys(modemStart)
		setDRState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, Dataretrival_Date: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};
	const handleBetteryPr = (e) => {
		const BpUpdate = Object.keys(betteryPr)
		setbetteryPr(BpUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, BAT_PER: BpUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })

	};

	const handleLastSeen = (e) => {
		const lastSeenUpdate = Object.keys(last)
		setLastSeen(lastSeenUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, [e.value]: 'True' } })

	};

   const onHandaleRoles = async () => {
      const data = await getRolesById(userInfo?.ROLE)
      if (data.success === true) {
         const obj = data.data.data[0]
         const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4) }
         if(params?.state?.detail){

         }else{
           ongetAllMapDataInGSM(Roles)
         }
         setpermision(Roles)
      } else {
         console.log('something want wrong')
      }
   }

   const ongetAllMapDataInGSM = async (roles) => {
      const data = await getAllMapDataInLorawanmapView(roles)
      if (data.success == true) {
         setMapInfo(data.data.data)
         
         

      } else {
         console.log('something want wrong')
      }
   }

   useEffect(()=>{
      if(GSMID){
      onHandalModelData(GSMID)
      }

},[mapInfo])

   const ongetAllMapDataInGSMFilter = async (info,data_info) => {
      setFilterLoader(true)
      var perameter_obj={}
   if(info=='true'){
      perameter_obj={ ...data_info, CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY5_view, roles: userInfo?.ROLE }
   }else{
      perameter_obj={ ...filterObj, CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY5_view, roles: userInfo?.ROLE }
   }
      const data = await getAllMapDataInLoramapViewByFilter(perameter_obj)
      if (data.success == true) {
         setMapInfo(data.data.data)
         setIsFilter(false)
         setFilterLoader(false)
   
      } else {
         console.log('something want wrong')
         setFilterLoader(false)
   
      }
    }

   const onGetLoraGatewayList=async()=>{
      const data = await getDataInLorawanGatewayList()
      if (data.success == true) {
         setGatewayInfo(data.data.data)
         setGatewayShowInfo(data?.data?.data?.map((item)=>(item?.GATEWAY_ID)))
      } else {
         console.log('something want wrong')
      }
   }

   const onHandalGatewayShow=(e,id)=>{
      if(e){
         setGatewayShowInfo([...GatewayShowInfo,id])
      }else{
         setGatewayShowInfo(GatewayShowInfo.filter((item)=>(item!==id)))

      }
   }

   useEffect(() => {
      onHandaleRoles()
      onGetLoraGatewayList()
      if(params?.state?.detail){
         setFilterObj({...params?.state?.detail})
         const lookUpobj={
            CATEGORY0:"accountuser",
            CATEGORY1:'gauser',
            CATEGORY2:'circleuser',
            CATEGORY3:'divisionuser',
            CATEGORY4:'subDivisionuser',
            CATEGORY5:'areauser',
            Installation_Number:'checktext1',
            Business_Number:'checktext2',Modem_Serial_No:'checktext3',Panel_Serial_No:'checktext4',Unique_ID:'checktext5',Distirbuter_ID:'checktext6',IMEI:'checktext7',Status:'checktextCS',Meter_Profile:'checktextMP',Modem_Profile:'checktextMOP',Tags:'checktextTA',Installation_Date:'checktextInTS',Dataretrival_Date:'checktextDRTS',Consumer_Type:'Consumer_Type',RSSI:'checktextRSSI',BAT_PER:'checktextBP',Last_Seen:"checktextLS"
         }
         var Profile_obj={...Profile}
         var consumer_obj={...consumer}
         var modem_obj={...modem}
         Object.keys(params?.state?.detail).map((item,id)=>{
            if (Profile.hasOwnProperty(lookUpobj[item])) {
               Profile_obj[lookUpobj[item]]=true
            }
            if (consumer.hasOwnProperty(lookUpobj[item])) {
               consumer_obj[lookUpobj[item]]=true
            }
            if (modem.hasOwnProperty(lookUpobj[item])) {
               modem_obj[lookUpobj[item]]=true
            }
            if(id+1==Object.keys(params?.state?.detail).length){
               setProfile({...Profile_obj})
               setConsumer({...consumer_obj})
               setModem({...modem_obj})
               ongetAllMapDataInGSMFilter('true',params?.state?.detail)
            }
            
      })
      }else{
      setFilterObj({...filterObj,Status:'1'})
      setConsumer({...consumer,checktextCS:true})
      }
   }, []);

   const onHandalModelData = async (id) => {
      setisgatewayInfo(false)
      setgetwayIDHL('')
      if (id && id !== '') {
         const data = await getLorawanDataById(id);
         if (data.success === true) {
            const objGSM = data.data.data[0];
            if (data.data.data.length > 0) {

               const lastHBDJson=JSON.parse(objGSM.HEARTBEAT_LAST_DATA||'{}')?.LGWRXINFO||[]
               const gateway_json=[]
               if(lastHBDJson?.length>0){
                  lastHBDJson?.map((item)=>{
                     const getwayID=base64ToHex(item?.gatewayID)
               console.log('lastHBDJson',getwayID,GatewayInfo)
                     GatewayInfo?.map((info)=>{
                        if(info?.GATEWAY_ID==getwayID.toLowerCase()){
                           gateway_json.push({...item,GATEWAY_DESCRIPTION:info?.GATEWAY_DESCRIPTION,GATEWAY_NAME:info?.GATEWAY_NAME,LATITUDE:info?.LATITUDE,LONGITUDE:info?.LONGITUDE})
                        }
                     })
                  })
               }

               setGSMData({
                  ...objGSM,
                  ADDRESS: JSON.parse(objGSM.ADDRESS||'{}'),
                  CONTACT_PERSON: JSON.parse(objGSM.CONTACT_PERSON),
                  INSTALLATION_DETAILS: JSON.parse(objGSM.INSTALLATION_DETAILS),
                  METER_DATA: JSON.parse(objGSM.METER_DATA||'{}'),
                  MODEM_DATA: JSON.parse(objGSM.MODEM_DATA||'{}'),
                  UNIQUE_TABLE_NAME: JSON.parse(objGSM.UNIQUE_TABLE_NAME||'{}'),
                  HEARTBEAT_LAST_DATA:JSON.parse(objGSM.HEARTBEAT_LAST_DATA||'{}'),
                  gateway_json:gateway_json
               })
               setModemInfoModem(true)
            }
         } else {
            console.log('something want wrong')
            setGSMData({})
         }
      } else {
         setGSMData({})
      }
   }

   const onHandalGateway=(info)=>{
      setModemInfoModem(false)
      setisgatewayInfo(true)
      const modemInfo=mapInfo.filter((item)=>{
         const modemGatewayArray=JSON.parse(item?.HEARTBEAT_LAST_DATA)?.LGWRXINFO||[]
         if(modemGatewayArray?.length>0){
            return  modemGatewayArray.filter((data)=>(info?.GATEWAY_ID==base64ToHex(data?.gatewayID).toLowerCase())).length>0
         }else{
            return false
         }
      }).map((item)=>({...item,distance:item?.LONGITUDE&&item?.LATITUDE&&latlondistance(item?.LATITUDE,item?.LONGITUDE,info?.LATITUDE,info?.LONGITUDE)||0}))
      console.log(modemInfo)
      setgatewayInfo({gateway_info:info,modemInfo:modemInfo})
   }

   function base64ToHex(str) {
    const raw = atob(str);
    let result = '';
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16);
      result += (hex.length === 2 ? hex : '0' + hex);
    }
    return result.toUpperCase();
  }

  const ongetGaById = async (id) => {
	const data1 = await getGaById(id);
	if (data1.success === true) {
		const options = []
		const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => (items === data.CATEGORY1_UNIQUE_ID)).length > 0||filterObj?.CATEGORY0?.includes(data?.CATEGORY0) || userInfo?.ROLE === 'ROLE1650614500708'));
		optionArray.map((item) => (options.push({ value: item?.CATEGORY1_UNIQUE_ID, label: item?.CATEGORY1_NAME })))
		setGaData(options)
	}
};

const ongetCircle = async (id) => {
	const data1 = await getCircleById(id);
	if (data1.success === true) {
		const options = []
		const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => (items === data.CATEGORY2_UNIQUE_ID)).length > 0||(filterObj?.CATEGORY0?.includes(data?.CATEGORY0)&&filterObj?.CATEGORY1?.includes(data?.CATEGORY1)) || userInfo?.ROLE === 'ROLE1650614500708'));
		optionArray.map((item) => (options.push({ value: item?.CATEGORY2_UNIQUE_ID, label: item?.CATEGORY2_NAME })))
		setCircleData(options);
	}
};
const ongetSubdevisionById = async (id) => {
	const data1 = await getSubdevisionById(id);
	if (data1.success === true) {
		const options = []
		const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter((items) => (items === data.CATEGORY4_UNIQUE_ID)).length > 0||(filterObj?.CATEGORY0?.includes(data?.CATEGORY0)&&filterObj?.CATEGORY1?.includes(data?.CATEGORY1)&&filterObj?.CATEGORY2?.includes(data?.CATEGORY2)&&filterObj?.CATEGORY3?.includes(data?.CATEGORY3)) || userInfo?.ROLE === 'ROLE1650614500708'));
		optionArray.map((item) => (options.push({ value: item?.CATEGORY4_UNIQUE_ID, label: item?.CATEGORY4_NAME })))
		setSubdevisionData(options);
	}
};
const ongetDivisionById = async (id) => {
	const data3 = await getDivisionById(id);
	if (data3.success === true) {
		const options = []
		const optionArray = data3?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => (items === data.CATEGORY3_UNIQUE_ID)).length > 0||(filterObj?.CATEGORY0?.includes(data?.CATEGORY0)&&filterObj?.CATEGORY1?.includes(data?.CATEGORY1)&&filterObj?.CATEGORY2?.includes(data?.CATEGORY2)) || userInfo?.ROLE === 'ROLE1650614500708'));
		optionArray.map((item) => (options.push({ value: item?.CATEGORY3_UNIQUE_ID, label: item?.CATEGORY3_NAME })))
		setDivisionData(options);
	}
};
const ongetArea = async (id) => {
	const data3 = await getAreaById(id);
	if (data3.success === true) {
		const options = []
		const optionArray = data3?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0||(filterObj?.CATEGORY0?.includes(data?.CATEGORY0)&&filterObj?.CATEGORY1?.includes(data?.CATEGORY1)&&filterObj?.CATEGORY2?.includes(data?.CATEGORY2)&&filterObj?.CATEGORY3?.includes(data?.CATEGORY3)&&filterObj?.CATEGORY4?.includes(data?.CATEGORY4)) || userInfo?.ROLE === 'ROLE1650614500708'));
		optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY5_NAME })))
		setAreaData(options);
	}
};

useEffect(() => {
   if (filterObj?.CATEGORY0?.length > 0) {
      ongetGaById(filterObj?.CATEGORY0);
      setCircleData([]);
      setSubdevisionData([]);
      setDivisionData([]);
      setAreaData([])
   } else {
      setGaData([]);
      setCircleData([]);
      setSubdevisionData([]);
      setDivisionData([]);
      setAreaData([]);
   }
}, [filterObj.CATEGORY0]);

useEffect(() => {
   if (filterObj?.CATEGORY1?.length > 0) {
      ongetCircle(filterObj?.CATEGORY1);
      setSubdevisionData([]);
      setDivisionData([]);
      setAreaData([]);

   } else {
      setCircleData([]);
      setDivisionData([]);
      setSubdevisionData([]);
      setAreaData([]);


   }
}, [filterObj.CATEGORY1]);

useEffect(() => {
   if (filterObj?.CATEGORY2?.length > 0) {
      ongetDivisionById(filterObj?.CATEGORY2);
      setAreaData([]);
      setSubdevisionData([]);

   } else {
      setAreaData([]);
      setDivisionData([]);
      setSubdevisionData([]);
   }
}, [filterObj.CATEGORY2]);

useEffect(() => {
   if (filterObj?.CATEGORY3?.length > 0) {
      ongetSubdevisionById(filterObj?.CATEGORY3);
      setAreaData([]);
   } else {
      setSubdevisionData([]);
      setAreaData([]);
   }
}, [filterObj.CATEGORY3]);

useEffect(() => {
   if (filterObj?.CATEGORY4?.length > 0) {
      ongetArea(filterObj?.CATEGORY4);
   } else {
      setAreaData([]);

   }
}, [filterObj.CATEGORY4]);

const onHandaleAccount = async () => {
   const data = await getAccount();
   if (data.success === true) {
      const options = []
      const optionArray = data?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708'))
      console.log(optionArray);
      optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY0 })))
      setAccountData(options);
      console.log('options = ', options)

      setAccountKey(data?.data?.data)
   } else {
      console.log("somthing want wrong");
   }
};

useEffect(() => {
   onHandaleAccount()

}, [permision])


const ongetAllModem = async () => {
   const data6 = await getAllModem(true);
   if (data6.success === true) {
      const options = []
      data6?.data?.data?.map((item) => (options.push({ value: item?.MODEM_UNIQUE_ID, label: item?.MODEM_PROFILE_NAME })))
      setModemData(options);
   } else {
      console.log("somthing want wrong");
   }
}
const ongetAllMeter = async () => {
   const data7 = await getAllMeter(true);
   if (data7.success === true) {
      const options = []
      data7?.data?.data?.map((item) => (options.push({ value: item?.METER_UNIQUE_ID, label: item?.METER_PROFILE_NAME })))
      setMeterData(options);
   } else {
      console.log("somthing want wrong");
   }
}

const onGetTags = async () => {
   const data = await getTags()
   if (data.success === true) {
      settagsList(data.data.data.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME })))
   } else {
      console.log('something want wrong');
   }
}
const customStyles = {
   menu: provided => ({ ...provided, zIndex: 9999 }),
   multiValue: (provided, { data }) => {
      return {
         ...provided,
         width: 'min-content',
         backgroundColor: "#6777ef",
      };
   },
   multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
   }),


}

const animatedComponents = makeAnimated();

const options1 = [
   { value: "account-adani", label: 'Adani Total Gas Limited' },
   { value: "meMighty", label: 'MEMIGHTY' },
]

const consumers = [
   { value: 'all', label: 'All' },
   { value: '1', label: 'Active' },
   { value: '0', label: 'InActive' },
   { value: '-1', label: 'Pending Configurations' },
   { value: '2', label: 'Under Maintenance' },
   { value: '3', label: 'Stand By' }

]

const dataWise = [
   { value: "Between", label: 'Is between' },
   { value: "LessEqual", label: 'Is Less than Equel to' },
   { value: "GreaterEqual", label: "Is Greater than Equel to" }
]
const lastSeenWise = [
   { value: "LessEqual", label: 'Less than' },
   { value: "GreaterEqual", label: 'Greater than' }
]
const lastSeenWiseOption = [
   { value: "Hours", label: 'Hours' },
   { value: "Minutes", label: 'Minutes' }
]
const lastSeenWiseShort = [
   { value: "desc", label: 'Desc' },
   { value: "asc", label: 'Asc' }
]
const consumerTypeArray = [
   { value: "Industrial", label: 'Industrial' },
   { value: "Residential", label: 'Residential' },
   { value: "Commercial", label: 'Commercial' },
   { value: "DRS", label: 'DRS' },
]

const onSearchMultiFilterJson = (newSearchTerms,json_name) => {
   setFilterObj({ ...filterObj, [json_name]: newSearchTerms })
   
};

const onItemClearedFilterJson  = (clearedIndex,json_name) => {
   // Create a new array without the cleared item
   const newSearchTerms = filterObj[json_name]?.filter((_, index) => index !== clearedIndex);
   // Update the search results based on the new search terms
   onSearchMultiFilterJson(newSearchTerms,json_name);
};
const onClearAllItemsFilterJson  = (arr,json_name) => {
   // Reset the search results to the original data
   onSearchMultiFilterJson([],json_name);

};

function latlondistance(lat1, lon1, lat2, lon2) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		dist = dist * 1.609344;
		return (dist).toFixed(2);
	}
}

function getSecondsTodayBydate(ts) {
   var now = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
   var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
   return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'second')
}

const Download_Logs=()=>{
   exportExcelDownload(gatewayInfo?.modemInfo?.sort((a,b)=>(a?.distance-b?.distance)).map((item,id)=>({SRNO:id+1,"Consumer Name":item?.address_compony_name,"Modem Serial No":item?.DEVICE_SERIAL_NO,"RSSI":JSON.parse(item?.HEARTBEAT_LAST_DATA)?.LRSSI,"SNR":JSON.parse(item?.HEARTBEAT_LAST_DATA)?.LSNR,"Last Seen":item?.HEARTBEAT_TS ? moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : '',distance:item?.distance?item?.distance+' km':'-'})),new Date().valueOf())
}
const onResetSetting=()=>{
   setGatewayShowInfo(GatewayInfo?.map((item)=>(item?.GATEWAY_ID)))
   setradiousDetail({isRedious:true,km:2,isConsumer:true})
}
   return (
      <>
         
         <div id="mySidenav" class={'sidenav'} style={{ width: "550px", height: "100%", visibility: ModemInfoModem ? 'inherit' : 'hidden' }}>
            <a class="btn btn-danger closebtn tw-text-white" style={{ color: 'white' }} id="" onClick={() => (setModemInfoModem(false))}>×</a>
            <a href={`/view_existing_consumer_lorawan/${GSMData?.UNIQUE_ID}`} target='_blank' class="btn btn-light ml-2" id="device_btn_info"><i className='fas fa-external-link' style={{ height:'20px' }}>
                </i></a>
            <div class="h-100 bottom-fix">
               <div class=" card-type" id="Device_Info_list">
                  <div class="card-header">
                     <div class="pull-left">
                        <h4 id="c_consumer_name" style={{ color: "#fff" }}>{GSMData?.ADDRESS?.address_compony_name}</h4>
                     </div>
                     <div class="clearfix"></div>
                  </div>
                  <div class="card-body">
                     <ul class="nav nav-pills" id="myTab3" role="tablist">
                        <li class="nav-item">
                           <a class="nav-link active" id="home-tab3" data-toggle="tab" href="#home3" role="tab" aria-controls="home" aria-selected="true">Modem Info</a>
                        </li>
                        <li class="nav-item">
                           <a class="nav-link" id="home-tab4" data-toggle="tab" href="#home4" role="tab" aria-controls="home" aria-selected="true">Gateway Info</a>
                        </li>
                     </ul>
                     <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home3" role="tabpanel" aria-labelledby="home-tab">
                           <div class="card">
                              <div class="card-header">
                                 <h4>Consumer Info</h4>
                              </div>
                              <div class="card-body">
                                 <div class="form-group mb-0">
                                    <label class="f-800">Business Partner Number : </label>
                                    <label id="c_busniess_partner_number">{GSMData?.BUSINESS_PARTNER_NUMBER}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Installation Number : </label>
                                    <label id="c_installtion_number">{GSMData?.INSTALLATION_NUMBER}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Modem Serial Number: </label>
                                    <label id="c_modem_serial_number">{GSMData?.DEVICE_SERIAL_NO}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Panel Serial Number : </label>
                                    <label id="c_panel_serial_number">{GSMData?.PANEL_SERIAL_NO}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">EUI : </label>
                                    <label id="c_eui">{GSMData?.IMEI}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Customer ID : </label>
                                    <label id="c_customer_id">{GSMData?.CUSTOMER_ID}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Consumer Unique ID : </label>
                                    <label id="c_unique_id">{GSMData?.UNIQUE_ID}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Consumer Status : </label>
                                    <label id="c_consumer_status">{GSMData?.CUSTOMER_STATUS == 1 ? 'Active' : GSMData?.CUSTOMER_STATUS == -1 ? 'Pending Configuration' :  GSMData?.CUSTOMER_STATUS == 0 ?'Inactive':GSMData?.CUSTOMER_STATUS == 2 ?'Under Maintenance':'Stand By'}</label>
                                 </div>
                              </div>
                           </div>
                           <div class="card">
                              <div class="card-header">
                                 <h4>Profile Info</h4>
                              </div>
                              <div class="card-body">
                                 <div class="form-group mb-0">
                                    <label class="f-800">Account</label>
                                    <label id="c_account">{GSMData?.ACCOUNT_NAME}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">GA : </label>
                                    <label id="c_ga">{GSMData?.GA_NAME}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Cicle : </label>
                                    <label id="c_circle">{GSMData?.CIRCLE_NAME}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Division : </label>
                                    <label id="c_division">{GSMData?.DIVISION_NAME}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Sub Division : </label>
                                    <label id="c_sub_division">{GSMData?.SUBDIVISION_NAME}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Area : </label>
                                    <label id="c_area">{GSMData?.AREA_NAME}</label>
                                 </div>
                              </div>
                           </div>
                           <div class="card">
                              <div class="card-header">
                                 <h4>Modem Info</h4>
                              </div>
                              <div class="card-body">
                                 
                                 <div class="form-group mb-0">
                                 <b>RSSI : </b>
										<div className="d-inline-block text-right ">
										{GSMData?.HEARTBEAT_LAST_DATA?.LRSSI >= -150 && GSMData?.HEARTBEAT_LAST_DATA?.LRSSI <= -111 ? (
																			<>
																				<img src={signal1} style={{height:'20px',width:"40px",border:'0',boxShadow:'none',borderRadius:'none'}}/>
																				<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.LRSSI} </span> 
																			</>
																		) : GSMData?.HEARTBEAT_LAST_DATA?.LRSSI >= -51 && GSMData?.HEARTBEAT_LAST_DATA?.LRSSI <= 0 ? (
																			<>
																			<img src={signal5} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																					<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA.LRSSI} </span> 
																			</>
																		) : GSMData?.HEARTBEAT_LAST_DATA?.LRSSI >= -80 && GSMData?.HEARTBEAT_LAST_DATA?.LRSSI <= -52 ? (
																			<>
																			<img src={signal4} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.LRSSI} </span> 
																			</>
																		) : GSMData?.HEARTBEAT_LAST_DATA?.LRSSI >= -95 && GSMData?.HEARTBEAT_LAST_DATA?.LRSSI <= -81 ? (
																			<>
																			<img src={signal3} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.LRSSI} </span> 
																			</>
																		) : GSMData?.HEARTBEAT_LAST_DATA?.LRSSI >= -110 && GSMData?.HEARTBEAT_LAST_DATA?.LRSSI <= -95 ? (
																			<>
																			<img src={signal2} style={{height:'20px',width:"40px",border:'0',borderRadius:'0',boxShadow:'none'}}/>
																			<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.LRSSI} </span> 
																			</>
																		) : (
																			"-"
																		)}
										</div>
                                 </div>
                                 <div class="form-group mb-0">
                                 <b>SNR : </b>{GSMData?.HEARTBEAT_LAST_DATA?.LSNR}
                                 </div>
                                 <div class="form-group mb-0">
                                 <b>SF : </b>{GSMData?.HEARTBEAT_LAST_DATA?.LSF}
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Last seen : </label>
                                    <label id="c_last_seen">{GSMData?.HEARTBEAT_TS?moment.utc(GSMData.HEARTBEAT_TS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm'):''}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Latitude : </label>
                                    <label id="c_latitude">{GSMData?.LATITUDE}</label>
                                 </div>
                                 <div class="form-group mb-0">
                                    <label class="f-800">Longitude : </label>
                                    <label id="c_longitude">{GSMData?.LONGITUDE}</label>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="tab-pane fade " id="home4" role="tabpanel" aria-labelledby="home-tab">
                           {console.log(GSMData)}
                        {GSMData?.gateway_json?.map((item)=>(
 <div class="card">
 <div class="card-header">
    <h4>{item?.GATEWAY_NAME}</h4>
 </div>
 <div class="card-body">
 <pre>{JSON.stringify({rssi:item?.rssi,loraSNR:item?.loRaSNR,distance:latlondistance(item?.LATITUDE,item?.LONGITUDE,GSMData?.LATITUDE,GSMData?.LONGITUDE)+' km'},null,1)}</pre>
 <pre>{JSON.stringify(JSON.parse(item?.GATEWAY_DESCRIPTION||'{}'),null,1)}</pre>
 </div>
 </div>
                        ))}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div id="mySidenav" class={'sidenav'} style={{ width: "660px", height: "100%", visibility: isgatewayInfo ? 'inherit' : 'hidden' }}>
            <a class="btn btn-danger closebtn tw-text-white" style={{ color: 'white' }} id="" onClick={() => {setisgatewayInfo(false)
            setgetwayIDHL('')}}>×</a>
            
            <div class="h-100 bottom-fix">
               <div class=" card-type" id="Device_Info_list">
                  <div class="card-header">
                     <div class="pull-left">
                        <h4 id="c_consumer_name" style={{ color: "#fff" }}>{gatewayInfo?.gateway_info?.GATEWAY_NAME}</h4>
                     </div>
                     <div class="clearfix"></div>
                  </div>
                  <div class="card-body">
                     <ul class="nav nav-pills" id="myTab3" role="tablist">
                        <li class="nav-item">
                           <a class="nav-link active" id="home-tab3" data-toggle="tab" href="#home5" role="tab" aria-controls="home" aria-selected="true">Gateway Info</a>
                        </li>
                        <li class="nav-item">
                           <a class="nav-link" id="home-tab4" data-toggle="tab" href="#home6" role="tab" aria-controls="home" aria-selected="true">Modem Info</a>
                        </li>
                     </ul>
                     <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home5" role="tabpanel" aria-labelledby="home-tab">
                           <div>
 <pre>{JSON.stringify(JSON.parse(gatewayInfo?.gateway_info?.GATEWAY_DESCRIPTION||'{}'),null,1)}</pre>
                           </div>
                        </div>
                        <div class="tab-pane fade " id="home6" role="tabpanel" aria-labelledby="home-tab">
                           
                           <div class="card">
                       
                              <>
                              {gatewayInfo?.modemInfo?.length>0?<div className='d-flex tw-justify-end mt-1'>
                                 <span className='btn btn-primary text-white ml-2 mr-2' onClick={Download_Logs}><i className='fas fa-download'></i></span>
                                 <span className='ml-2 mr-2'>Highlight:<Switch size="small" onChange={(e)=>(e==true?setgetwayIDHL(gatewayInfo?.gateway_info?.GATEWAY_ID):setgetwayIDHL(''))} checked={gatewayInfo?.gateway_info?.GATEWAY_ID==getwayIDHL}/></span>
                              </div>:''}
                              <div className="table-responsive mt-2">
								<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
									<table id="table_id" className="tableCustom table table-striped">
										<thead>
											<tr>
											<th>SRNO</th>
											<th>Consumer Name</th>
											<th>Modem Serial No</th>
											{/* <th>Installation Number</th> */}
											<th>RSSI</th>
											<th>SNR</th>
                                 <th>Last Seen</th>
                                 <th>distance</th>
											</tr>
										</thead>
										<tbody>
                              {gatewayInfo?.modemInfo?.sort((a,b)=>(a?.distance-b?.distance)).map((item,id)=>(
												<tr  className="trheigth" onMouseEnter={() => {
                                       var element = document.getElementById(id + 'GSM');
                                       element.classList.add('within');
                                       element.classList.remove('display-icon');
                                    }} onMouseLeave={() => {
                                       var element = document.getElementById(id + 'GSM');
                                       element.classList.add('display-icon');
                                       element.classList.remove('within');
                                    }} >
												 <td className="GSM_Link"><a href={`/view_existing_consumer_lorawan/${item?.UNIQUE_ID}`} target='_blank'>{id+1}</a>
                                     <a id={id + 'GSM'} className='display-icon' href={`/view_existing_consumer_lorawan/${item?.UNIQUE_ID}`} target='_blank'><img src={exports} style={{ height: "10px" }} /></a></td>
												 <td>{item?.address_compony_name}</td>
												 <td>{item?.DEVICE_SERIAL_NO}</td>
												 {/* <td>{item?.INSTALLATION_NUMBER}</td> */}
												 <td>{JSON.parse(item?.HEARTBEAT_LAST_DATA)?.LRSSI}</td>
												 <td>{JSON.parse(item?.HEARTBEAT_LAST_DATA)?.LSNR}</td>
												 <BootstrapTooltip title={item.HEARTBEAT_TS ? moment.utc(item.HEARTBEAT_TS).format("DD-MM-YYYY hh:mm:ss A") : ''} placement="top"><td>{getSecondsTodayBydate(item.HEARTBEAT_TS) < 86400 ? <div className="badge badge-success badge-shadow text-white">{item?.HEARTBEAT_TS ? moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</div> : <div className="badge badge-danger badge-shadow text-white">{item?.HEARTBEAT_TS ? moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</div>}</td></BootstrapTooltip>
                                    <td>{item?.distance?item?.distance+' km':'-'}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
                              </>
                        
                        </div>
                          
                          
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div id="mySidenav" class={'map_filter'} style={{ width: "760px", height: "100%", visibility: isFilter ? 'inherit' : 'hidden' }}>
         <a class="btn btn-danger closebtn tw-text-white" style={{ color: 'white' }} id="" onClick={() => {setIsFilter(false)
         }}>×</a>
         <div class="h-100 bottom-fix">
         <fieldset className="customLegend">
            <legend >Group Wise Filter</legend>
            <div className="row" style={{
               marginLeft: 10,
               marginRight: 10

            }} >
               <div className="col-12">
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="accountuser" name="CATEGORY0" className="custom-control-input " value="account" checked={Profile.accountuser} onChange={handleChangeS} />
                        <label className="custom-control-label" for="accountuser">CATEGORY0</label>
                     </div>
                     {Profile.accountuser && (<div className="form-group col-md-6 accountgrp" style={{ marginTop: -10 }} >
                        <Select value={AccounData.filter((item) => filterObj?.CATEGORY0?.includes(item.value)) || []} placeholder='Select account...' styles={customStyles} components={animatedComponents} name='account' isMulti options={AccounData} onChange={(e) => {
                           setFilterObj({ ...filterObj, CATEGORY0: e.map((item) => (item.value)) })
                        }} />
                     </div>)}
                  </div>

                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="gauser" name="CATEGORY1" className="custom-control-input" value="ga" checked={Profile.gauser} onChange={handleChangeS} />
                        <label className="custom-control-label" for="gauser">CATEGORY1</label>
                     </div>
                     {Profile.gauser && (<div className="form-group col-md-6 gagrp" style={{ marginTop: -10 }}>
                        <Select value={GaData.filter((item) => filterObj?.CATEGORY1?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={GaData} onChange={(e) => {
                           setFilterObj({ ...filterObj, CATEGORY1: e.map((item) => (item.value)) })
                        }} />
                     </div>)}
                  </div>

                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="circleuser" name="CATEGORY2" className="custom-control-input" checked={Profile.circleuser} value="circle" onChange={handleChangeS} />
                        <label className="custom-control-label" for="circleuser">CATEGORY2</label>
                     </div>
                     {Profile.circleuser && (<div className="form-group col-md-6 circlegrp" style={{ marginTop: -10 }}>
                        <Select value={CircleData.filter((item) => filterObj?.CATEGORY2?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={CircleData} onChange={(e) => {
                           setFilterObj({ ...filterObj, CATEGORY2: e.map((item) => (item.value)) })
                        }} />
                     </div>)}
                  </div>

                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="divisionuser" name="CATEGORY3" className="custom-control-input" checked={Profile.divisionuser} value="division" onChange={handleChangeS} />
                        <label className="custom-control-label" for="divisionuser">CATEGORY3</label>
                     </div>
                     {Profile.divisionuser && (<div className="form-group col-md-6 divisiongrp" style={{ marginTop: -10 }}>
                        <Select value={DivisionData.filter((item) => filterObj?.CATEGORY3?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={DivisionData} onChange={(e) => {
                           setFilterObj({ ...filterObj, CATEGORY3: e.map((item) => (item.value)) })
                        }} />

                     </div>)}
                  </div>

                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="subDivisionuser" name="CATEGORY4" className="custom-control-input" checked={Profile.subDivisionuser} value="subdivision" onChange={handleChangeS} />
                        <label className="custom-control-label " for="subDivisionuser">CATEGORY4</label>
                     </div>
                     {Profile.subDivisionuser && (<div className="form-group col-md-6 subdivisiongrp" style={{ marginTop: -10 }}>
                        <Select value={SubdevisionData.filter((item) => filterObj?.CATEGORY4?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={SubdevisionData} onChange={(e) => {
                           setFilterObj({ ...filterObj, CATEGORY4: e.map((item) => (item.value)) })
                        }} />

                     </div>)}
                  </div>

                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="areauser" name="CATEGORY5" className="custom-control-input" checked={Profile.areauser} value="area" onChange={handleChangeS} />
                        <label className="custom-control-label" for="areauser">CATEGORY5</label>
                     </div>
                     {Profile.areauser && (<div className="form-group col-md-6 areagrp" style={{ marginTop: -10 }}>
                        <Select value={AreaData.filter((item) => filterObj?.CATEGORY5?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={AreaData} onChange={(e) => {
                           setFilterObj({ ...filterObj, CATEGORY5: e.map((item) => (item.value)) })
                        }} />

                     </div>)}
                  </div>
               </div>

            </div>
         </fieldset>
         <fieldset className="customLegend ">
            <legend >Consumer Info Wise Filter</legend>
            <div className="row" style={{ marginLeft: 10 }}>
               <div className="col-12">
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktext1" name="Installation_Number" className="custom-control-input" checked={consumer.checktext1} value="installnumber" onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktext1">Installation Number</label>
                     </div>
                     {consumer.checktext1 &&<MultiInput
                        placeholder="search and enter..."
                        onChange={(arr)=>onSearchMultiFilterJson(arr,"Installation_Number")}
                        maxItems={null} // Allow any number of items
                        onClear={(arr)=>onItemClearedFilterJson(arr,"Installation_Number")}
                        onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Installation_Number")}
                     />}
                     
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktext2" name="Business_Number" className="custom-control-input" value="businessnu" checked={consumer.checktext2} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktext2">Business Partner Number</label>
                     </div>
                     {consumer.checktext2 &&<MultiInput
                        placeholder="search and enter..."
                        onChange={(arr)=>onSearchMultiFilterJson(arr,"Business_Number")}
                        maxItems={null} // Allow any number of items
                        onClear={(arr)=>onItemClearedFilterJson(arr,"Business_Number")}
                        onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Business_Number")}
                     />}
                     
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktext3" name="Modem_Serial_No" className="custom-control-input" value="modemserial" checked={consumer.checktext3} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktext3">Modem Serial No</label>
                     </div>
                     {consumer.checktext3 &&<MultiInput
                        placeholder="search and enter..."
                        onChange={(arr)=>onSearchMultiFilterJson(arr,"Modem_Serial_No")}
                        maxItems={null} // Allow any number of items
                        onClear={(arr)=>onItemClearedFilterJson(arr,"Modem_Serial_No")}
                        onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Modem_Serial_No")}
                     />}
                     
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktext4" name="Panel_Serial_No" className="custom-control-input" value="panelserno" checked={consumer.checktext4} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktext4">Panel Serial No</label>
                     </div>
                     {consumer.checktext4 &&<MultiInput
                        placeholder="search and enter..."
                        onChange={(arr)=>onSearchMultiFilterJson(arr,"Panel_Serial_No")}
                        maxItems={null} // Allow any number of items
                        onClear={(arr)=>onItemClearedFilterJson(arr,"Panel_Serial_No")}
                        onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Panel_Serial_No")}
                     />}
                     
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktext5" name="Unique_ID" className="custom-control-input" value="cnuniqueid" checked={consumer.checktext5} onChange={handleChangeS}/>
                        <label className="custom-control-label" for="checktext5">Consumer Unique Id</label>
                     </div>
                     {consumer.checktext5 &&<MultiInput
                        placeholder="search and enter..."
                        onChange={(arr)=>onSearchMultiFilterJson(arr,"Unique_ID")}
                        maxItems={null} // Allow any number of items
                        onClear={(arr)=>onItemClearedFilterJson(arr,"Unique_ID")}
                        onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Unique_ID")}
                        value={filterObj?.Unique_ID}
                     />}
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktext6" name="Distirbuter_ID" className="custom-control-input" value="distid" checked={consumer.checktext6} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktext6">Customer Id</label>
                     </div>
                     {consumer.checktext6 &&<MultiInput
                        placeholder="search and enter..."
                        onChange={(arr)=>onSearchMultiFilterJson(arr,"Distirbuter_ID")}
                        maxItems={null} // Allow any number of items
                        onClear={(arr)=>onItemClearedFilterJson(arr,"Distirbuter_ID")}
                        onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Distirbuter_ID")}
                     />}
                     
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktext7" name="EUI" className="custom-control-input" value="allimei" checked={consumer.checktext7} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktext7">EUI</label>
                     </div>
                     {consumer.checktext7 &&<MultiInput
                        placeholder="search and enter..."
                        onChange={(arr)=>onSearchMultiFilterJson(arr,"EUI")}
                        maxItems={null} // Allow any number of items
                        onClear={(arr)=>onItemClearedFilterJson(arr,"EUI")}
                        onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"EUI")}
                     />}
                     
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktextCS" name="Status" className="custom-control-input" value="filterstatus" checked={consumer.checktextCS} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktextCS">Consumer Status</label>
                     </div>
                     {consumer.checktextCS && (<div className="form-group col-md-3 checktextCS filterstatus" style={{ marginTop: -10 }}>
                        <Select styles={customStyles} components={animatedComponents} name='Status' options={consumers} onChange={(e) => {
                           if(!e&&e?.value!=1&&e?.value!=0){
                              delete filterObj["SubStatus"]
                           }
                           setFilterObj({ ...filterObj, Status: e?.value||'' })
                        }} isClearable value={[filterObj?.Status?consumers.filter((item)=>(item?.value==filterObj?.Status))[0]:[]]}/>

                     </div>)}
                     {consumer.checktextCS&&filterObj?.Status && filterObj?.Status==1 && <div className="form-group col-md-3 checktextCS filterstatus" style={{ marginTop: -10 }}>
                        <Select styles={customStyles} components={animatedComponents} name='Status' options={[{ value: '1', label: 'Live' },{ value: '2', label: 'Temporary Disconnection' }]} onChange={(e) => {
                           setFilterObj({ ...filterObj, SubStatus: e?.value||'' })
                        }} isClearable/>
                     </div>}
                     {consumer.checktextCS &&filterObj?.Status && filterObj?.Status==0 && <div className="form-group col-md-3 checktextCS filterstatus" style={{ marginTop: -10 }}>
                        <Select styles={customStyles} components={animatedComponents} name='Status' options={[{ value: '3', label: 'Permanent Disconnection' }]} onChange={(e) => {
                           setFilterObj({ ...filterObj, SubStatus: e?.value||'' })
                        }} isClearable/>
                     </div>}
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktextMP" name="Meter_Profile" className="custom-control-input" value="meterprofile" checked={consumer.checktextMP} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktextMP">EVC Profile</label>
                     </div>
                     {consumer.checktextMP && (<div className="form-group col-md-6 checktextMP meterprofile" style={{ marginTop: -10 }}>
                        <Select styles={customStyles} components={animatedComponents} name='Meter_Profile' options={MeterData} isMulti onChange={(e) => {
                           setFilterObj({ ...filterObj, Meter_Profile: e.map((item) => (item.value)) })
                        }} />

                     </div>)}
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktextMOP" name="Modem_Profile" className="custom-control-input" value="modemprofile" checked={consumer.checktextMOP} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktextMOP">Modem Profile</label>
                     </div>
                     {consumer.checktextMOP && (<div className="form-group col-md-6 checktextMOP modemprofile" style={{ marginTop: -10 }}>
                        <Select styles={customStyles} components={animatedComponents} name='Modem_Profile' options={ModemData} isMulti onChange={(e) => {
                           setFilterObj({ ...filterObj, Modem_Profile: e.map((item) => (item.value)) })
                        }} />

                     </div>)}
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktextTA" name="Tags" className="custom-control-input" value="tagsvalue" checked={consumer.checktextTA} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktextTA">Tags</label>
                     </div>
                     {consumer.checktextTA && (<div className="form-group col-md-6 checktextTA tagsvalue" style={{ marginTop: -10 }}>
                        <Select styles={customStyles} name='Tags' isMulti options={tagsList} onChange={(e) => {
                           setFilterObj({ ...filterObj, Tags: e.map((item) => (item.value)) })
                        }
                        } />

                     </div>)}

                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktextInTS" name="Installation_Date" className="custom-control-input" value="tagsvalue" checked={consumer.checktextInTS} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktextInTS">Installation Date</label>
                     </div>
                     {consumer.checktextInTS && (<div className="form-group col-md-3 checktextRSSI length rssival" style={{ marginTop: -10 }}>
                        <Select components={animatedComponents} placeholder="Select Condition..." name='RSSI' options={dataWise}
                           styles={customStyles} onChange={handleSelectChangeInstall} />

                     </div>)}
                     {consumer.checktextInTS&& installState?.Between && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
                        <Space direction="horizontal">
                           <div className="d-flex flex-column">Start In</div>
                           <DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
                              disabledDate={(current) => {
                                 return current.isAfter(moment())
                              }} />
                           <div className="d-flex flex-column" >End In</div>
                           <DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, End_Date: dateString } })} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
                              return current.isAfter(moment())
                           }} />
                        </Space>

                     </div>)}
                     {consumer.checktextInTS&& installState?.LessEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
                        <Space direction="horizontal">
                           <div className="d-flex flex-column">Date</div>
                           <DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
                              disabledDate={(current) => {
                                 return current.isAfter(moment())
                              }} />
                        </Space>

                     </div>)}
                     {consumer.checktextInTS&& installState?.GreaterEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
                        <Space direction="horizontal">
                           <div className="d-flex flex-column">Date</div>
                           <DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Installation_Date: { ...filterObj.Installation_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
                              disabledDate={(current) => {
                                 return current.isAfter(moment())
                              }} />
                        </Space>

                     </div>)}
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktextDRTS" name="Dataretrival_Date" className="custom-control-input" value="tagsvalue" checked={consumer.checktextDRTS} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktextDRTS">Data Retrival Date</label>
                     </div>
                     {consumer.checktextDRTS && (<div className="form-group col-md-3 checktextRSSI length rssival" style={{ marginTop: -10 }}>
                        <Select components={animatedComponents} placeholder="Select Condition..." name='RSSI' options={dataWise}
                           styles={customStyles} onChange={handleSelectChangeDR} />

                     </div>)}
                     {consumer.checktextDRTS&& DRState?.Between && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
                        <Space direction="horizontal">
                           <div className="d-flex flex-column">Start In</div>
                           <DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
                              disabledDate={(current) => {
                                 return current.isAfter(moment())
                              }} />
                           <div className="d-flex flex-column" >End In</div>
                           <DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, End_Date: dateString } })} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
                              return current.isAfter(moment())
                           }} />
                        </Space>

                     </div>)}
                     {consumer.checktextDRTS&& DRState?.LessEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
                        <Space direction="horizontal">
                           <div className="d-flex flex-column">Date</div>
                           <DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
                              disabledDate={(current) => {
                                 return current.isAfter(moment())
                              }} />
                        </Space>

                     </div>)}
                     {consumer.checktextDRTS&& DRState?.GreaterEqual && (<div className="form-group col-md-4 checktextTA tagsvalue" style={{ marginTop: -10 }}>
                        <Space direction="horizontal">
                           <div className="d-flex flex-column">Date</div>
                           <DatePicker onChange={(e, dateString) => setFilterObj({ ...filterObj, Dataretrival_Date: { ...filterObj.Dataretrival_Date, Start_Date: dateString } })} format='DD-MM-YYYY' name='start'
                              disabledDate={(current) => {
                                 return current.isAfter(moment())
                              }} />
                        </Space>

                     </div>)}
                  </div>
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="Consumer_Type" name="Consumer_Type" className="custom-control-input" value="area" checked={consumer.Consumer_Type} onChange={handleChangeS} />
                        <label className="custom-control-label" for="Consumer_Type">Consumer Type</label>
                     </div>
                     {consumer?.Consumer_Type && <div class="form-group col-md-3">
                        <Select styles={customStyles} components={animatedComponents} name='Consumer_Type' isMulti options={consumerTypeArray} onChange={(e) => {
                           setFilterObj({ ...filterObj, Consumer_Type: e.map((item) => (item.value)) })
                        }} />
                        {/* <select
                                          class="form-control form-select modemtypename anothermodemname"
                                          name="cust_sal"
                                          id="filter_course"
                                          required=""
                                          onChange={(e) => (setFilterObj({ ...filterObj, Consumertype: e.target.value }))}
                                          value={filterObj.Consumertype}
                                       >
                                          <option value="">Select Consumer Type </option>
                                          <option value="Residential">Residential</option>
                                          <option value="Industrial">Industrial</option>
                                          <option value="Commercial">Commercial</option>
                                       </select> */}
                     </div>}
                  </div>
               </div>
            </div>
         </fieldset>

         <fieldset className="customLegend ">
            <legend >Modem Data Wise Filter</legend>
            <div className="row" style={{ marginLeft: 10 }}>
               <div className="col-12">
                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktextRSSI" name="RSSI" className="custom-control-input" value="rssival" checked={modem.checktextRSSI} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktextRSSI">RSSI</label>
                     </div>
                     {modem.checktextRSSI && (<div className="form-group col-md-3 checktextRSSI length rssival" style={{ marginTop: -10 }}>
                        <Select components={animatedComponents} placeholder="Select Condition..." name='RSSI' options={dataWise}
                           styles={customStyles} onChange={handleSelectChange} />

                     </div>)}
                     {modemStart.Between && modem.checktextRSSI && (<div className="form-group col-md-3 checktext14" style={{ marginTop: -10 }}>
                        <input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, From: e.target.value } }) }} />
                     </div>)}
                     {modemStart.Between && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
                        <input type="text" className="form-control" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, To: e.target.value } }) }} />
                     </div>)}
                     {modemStart.LessEqual && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
                        <input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, Value: e.target.value } }) }} />
                     </div>)}
                     {modemStart.GreaterEqual && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
                        <input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, Value: e.target.value } }) }} />
                     </div>)}
                  </div>

                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktextBP" name="BAT_PER" className="custom-control-input" value="BAT_PER" checked={modem.checktextBP} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktextBP">Battery Percentage</label>
                     </div>
                     {modem.checktextBP && (<div className="form-group col-md-3 checktextBP length batperval" style={{ marginTop: -10 }}>
                        <Select components={animatedComponents} styles={customStyles} name='account' options={dataWise}
                           onChange={handleBetteryPr} placeholder="Select Condition..." />

                     </div>)}

                     {betteryPr.Between && modem.checktextBP && (<div className="form-group col-md-3 checktext14" style={{ marginTop: -10 }}>
                        <input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...filterObj, BAT_PER: { ...filterObj.BAT_PER, From: e.target.value } }) }} />
                     </div>)}
                     {betteryPr.Between && modem.checktextBP && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
                        <input type="text" className="form-control" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...filterObj, BAT_PER: { ...filterObj.BAT_PER, To: e.target.value } }) }} />
                     </div>)}
                     {betteryPr.LessEqual && modem.checktextBP && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
                        <input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...filterObj, BAT_PER: { ...filterObj.BAT_PER, Value: e.target.value } }) }} />
                     </div>)}
                     {betteryPr.GreaterEqual && modem.checktextBP && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
                        <input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...filterObj, BAT_PER: { ...filterObj.BAT_PER, Value: e.target.value } }) }} />
                     </div>)}
                  </div>

                  <div className="form-row">
                     <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="checktextLS" name="Last_Seen" className="custom-control-input" value="lastssen" checked={modem.checktextLS} onChange={handleChangeS} />
                        <label className="custom-control-label" for="checktextLS">Last seen</label>
                     </div>
                     {modem.checktextLS && (<div className="form-group col-md-3 checktextLS lastssen" style={{ marginTop: -10 }}>
                        <Select components={animatedComponents} styles={customStyles} name='Last_Seen' options={lastSeenWiseOption} placeholder="Select Condition..."
                           onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Last_Seen: e.value } }) }} />
                     </div>)}
                     {modem.checktextLS && (<div className="form-group col-md-3 checktextLS lastssen" style={{ marginTop: -10 }}>
                        <Select components={animatedComponents} styles={customStyles} name='Last_Seen' options={lastSeenWise} placeholder="Select Condition..."
                           onChange={handleLastSeen} />
                     </div>)}

                     {last.LessEqual && modem.checktextLS && (<div className="form-group col-md-3 checktext31" style={{ marginTop: -10 }}>
                        <input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="" onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Value: e.target.value } }) }} />
                     </div>)}

                     {last.GreaterEqual && modem.checktextLS && (<div className="form-group col-md-3 checktext31" style={{ marginTop: -10 }}>
                        <input type="text" className="form-control" name="filter_hours" id="filter_greater_hours" placeholder="" onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Value: e.target.value } }) }} />
                     </div>)}
                  </div>



               </div>
            </div>
         </fieldset>
         <div className="text-left mt-2 ml-2">
            <input type="submit" className={`btn btn-danger mr-2 ${FilterLoader == true ? 'btn-progress disabled' : ''}`} value="Filter" name="filter_customer_form"  onClick={ongetAllMapDataInGSMFilter }/>
            <Link className="btn btn-light text-dark" onClick={()=>{history.replace({ pathname: '/gsm-map-view', state: {} })}}>Reset</Link>
         </div>
         </div>
      </div>
      <div id="mySidenav" class={'map_filter'} style={{ width: "550px", height: "100%", visibility: isPoperover ? 'inherit' : 'hidden' }}>
      <a class="btn btn-danger closebtn tw-text-white" style={{ color: 'white' }} id="" onClick={() => {setisPoperover(false)
         }}>×</a>
      <fieldset className="customLegend">
            <legend >Settings</legend>
         
         <div className='ml-2'>
                                            <div className='d-flex '><span className='d-flex mr-2 tw-items-center'>Radius :</span><span><Switch size="small" onChange={(e)=>e==true?setradiousDetail({...radiousDetail,isRedious:true}):setradiousDetail({...radiousDetail,isRedious:false})} checked={radiousDetail?.isRedious}/></span></div>
                                            <div className='d-flex mt-2'><span className='d-flex mr-2  tw-items-center'>Radius km :</span><span><input
															class="form-control"
															id="km"
															name="km"
															type="number"
															placeholder="km"
                                             onChange={(e)=>setradiousDetail({...radiousDetail,km:e.target.value})}
                                             value={radiousDetail?.km}
														/></span></div><span></span>
                                          <div className='d-flex mt-2'><span className='d-flex mr-2 tw-items-center'>Consumer:</span><span><Switch size="small" onChange={(e)=>e==true?setradiousDetail({...radiousDetail,isConsumer:true}):setradiousDetail({...radiousDetail,isConsumer:false})} checked={radiousDetail?.isConsumer}/></span></div>
                                            </div>
                                            
                                            </fieldset>
      <fieldset className="customLegend">
            <legend ><div className=' d-flex'><span className='mr-2'><input type='checkbox'  onChange={(e)=>e.target.checked?setGatewayShowInfo(GatewayInfo.map((item)=>item?.GATEWAY_ID)):setGatewayShowInfo([])} checked={GatewayShowInfo.length==GatewayInfo.length}/></span>All Gateway List</div></legend>
           {GatewayInfo?.map((item)=>(
            <div className='ml-3 d-flex'><span className='mr-2'><input type='checkbox' id={item?.GATEWAY_ID} onChange={(e)=>onHandalGatewayShow(e.target.checked,item?.GATEWAY_ID)} checked={GatewayShowInfo?.filter((gateid)=>gateid===item?.GATEWAY_ID)?.length>0}/></span><span >{item?.GATEWAY_NAME}</span></div>
           ))}
      </fieldset>
      <div className='d-flex tw-justify-end'>
         <button className='btn btn-danger mt-3 mr-3' onClick={onResetSetting}>Reset</button>
      </div>
      </div>
      <div><div className='btn btn-primary filter-btn' onClick={()=>{setIsFilter(true)
		ongetAllMeter()
		ongetAllModem()
		onGetTags()}}><i className="fa fa-filter text-white"></i></div></div>
<div><button className='btn btn-info setting-btn' onClick={()=>{setisPoperover(true)
setisgatewayInfo(false)
setModemInfoModem(false)
setIsFilter(false)}}><i className="fas fa-cog text-white"></i></button></div>

      {/* <Popover
                                            arrowPointAtCenter={true}
                                            align={{ offset: [80, -5] }}
                                            overlayStyle={{ width: '24rem', borderRadius: '5px',left: '1473px',top: '104px',transformOrigin: '-80px 5px' }}
                                            overlayInnerStyle={{ width: '24rem', borderRadius: '5px',left: '1473px',top: '104px',transformOrigin: '-80px 5px' }}
                                            content={<>
                                            <div className='d-flex'><span className='d-flex mr-2 tw-items-center'>Radius :</span><span><Switch size="small" onChange={(e)=>e==true?setradiousDetail({...radiousDetail,isRedious:true}):setradiousDetail({...radiousDetail,isRedious:false})} checked={radiousDetail?.isRedious}/></span></div>
                                            <div className='d-flex mt-2'><span className='d-flex mr-2  tw-items-center'>Radius km :</span><span><input
															class="form-control"
															id="km"
															name="km"
															type="number"
															placeholder="km"
                                             onChange={(e)=>setradiousDetail({...radiousDetail,km:e.target.value})}
                                             value={radiousDetail?.km}
														/></span></div>
                                            </>}
                                            placement="bottom"
                                            trigger="click"
                                            visibility={isPoperover}
                                            >
</Popover> */}

         <div class="map-inner" style={{ height: '100vh', width: "100%" }}>
            {/*dev- AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8 */}
            {/*server- AIzaSyAo0xl_P_6Ga-ee6ggeYe1JAE2jq3PxU5s */}{/*<>testing mate aa kwy no use karvo nahi ok</>*/}
            <ArticlesMap
               googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAo0xl_P_6Ga-ee6ggeYe1JAE2jq3PxU5s&v=3.exp&libraries=geometry,drawing,places"
               loadingElement={<div style={{ height: '100%', width: '100%' }} />}
               containerElement={<div style={{ height: '100%', width: '100%' }} />}
               mapElement={<div style={{ height: '100%', width: '100%' }} />}
               onClick={(ev) => { console.log(ev) }}
               mapInfo={mapInfo}
               GSMID={GSMID}
               lastseen={lastseen}
               GSMData={GSMData}
               GatewayInfo={GatewayInfo}
               lastseenIndate={lastseenIndate}
               getwayIDHL={getwayIDHL}
               GatewayShowInfo={GatewayShowInfo}
               setModemInfoModem={(i) => onHandalModelData(i)}
               ModemInfoModem={(i) => setModemInfoModem(i)}
               onHandalGateway={(i)=>onHandalGateway(i)}
               stateModemInfoModem={ModemInfoModem}
               curentpos={{ lat: 21.727293496271507, lng: 75.5823933220894 }}
               radiousDetail={radiousDetail}
            />
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width="100%" height="300" frameborder="0"></iframe> */}
         </div>
      </>
   )

//       </div>
//    </div>
// </div>
//         <div class="map-inner" style={{ height: '100vh', width: "100%" }}>
// 												{/*dev- AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8 */}
// 												{/*server- AIzaSyDG0jH5rr6z6KU0Zo_ilALl5ZXUP5NSPSs */}
// 												<ArticlesMap
// 													googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8&v=3.exp&libraries=geometry,drawing,places"
// 													loadingElement={<div style={{ height: '100%', width: '100%' }} />}
// 													containerElement={<div style={{ height: '100%', width: '100%' }} />}
// 													mapElement={<div style={{ height: '100%', width: '100%' }} />}
// 													onClick={(ev) => { console.log(ev) }}
// 													mapInfo={mapInfo}
//                                                     GSMID={GSMID}
//                                                     setModemInfoModem={(i)=>onHandalModelData(i)}
//                                                     ModemInfoModem={(i)=>setModemInfoModem(i)}
// 													curentpos={{ lat: 21.727293496271507, lng: 75.5823933220894 }}
// 												/>
// 												{/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width="100%" height="300" frameborder="0"></iframe> */}
// 											</div>
//     </>
//   )

}

export const ArticlesMap = withScriptjs(withGoogleMap((props) => {
   const [jobDetail, setJobDetail] = useState({ activeMarker: {}, isOpen: false, Info: {} })
   const [gatewayDetails, setgatewayDetails] = useState({ activeMarker: {}, isOpen: false, Info: {} })
   const  {getwayIDHL,radiousDetail,GatewayShowInfo}=props

   const onMarkerClicked = (data, marker) => {
      setJobDetail({
         activeMarker: { lat: data?.latLng?.lat(), lng: data?.latLng?.lng() },
         isOpen: true,
         Info: marker
      })
   }

   const onMarkergateway = (data, marker) => {
      setgatewayDetails({
         activeMarker: { lat: data?.latLng?.lat(), lng: data?.latLng?.lng() },
         isOpen: true,
         Info: marker
      })
   }
   useEffect(() => {
      if (props.GSMData) {
         const Infoarray = props?.mapInfo?.filter((item) => (item?.UNIQUE_ID == props?.GSMData?.UNIQUE_ID))
         console.log('Infoarray',Infoarray, props?.mapInfo);
         const data = Infoarray[0]
            setJobDetail({
               activeMarker: { lat: parseFloat(props?.GSMData?.LATITUDE), lng: parseFloat(props?.GSMData?.LONGITUDE) },
               isOpen: true,
               Info: props?.GSMData
            })
      }
   }, [props.GSMData])


   const onToggleOpen = () => {
      setJobDetail({ ...jobDetail, isOpen: false })
      setgatewayDetails({...gatewayDetails, isOpen: false})
      props.ModemInfoModem(false)
   }
   const onOpenInfoModel = (id) => {
      props.setModemInfoModem(id)
   }
   function getSecondsTodayBydate(ts) {
		var now = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'hours')
	}
   function base64ToHex(str) {
    const raw = atob(str);
    let result = '';
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16);
      result += (hex.length === 2 ? hex : '0' + hex);
    }
    return result.toUpperCase();
  }
   return (<>
      <GoogleMap
         defaultZoom={5}
         defaultCenter={{ lat: props.curentpos.lat, lng: props.curentpos.lng }}
         onClick={(e) => { console.log("e.latLng.lat()", e.latLng.lat(), "e.latLng.lng()", e.latLng.lng()) }}
      >
         {props?.mapInfo?.map((item, id) => (radiousDetail?.isConsumer&&<><Marker
            defaultZoomOnClick={4}
            icon={{
               url:radiousDetail?.isConsumer==false?'': getwayIDHL&&JSON.parse(item?.HEARTBEAT_LAST_DATA||'{}')?.LGWRXINFO?.filter((data)=>(getwayIDHL==base64ToHex(data?.gatewayID).toLowerCase())).length>0?mapYellowicon:props?.stateModemInfoModem==true&&item?.UNIQUE_ID == props?.GSMData?.UNIQUE_ID?mapBlueicon:props?.lastseen?getSecondsTodayBydate(item?.HEARTBEAT_TS)<props?.lastseen?mapicon:mapRedicon:props?.lastseenIndate.start&&props?.lastseenIndate.end?moment(props?.lastseenIndate.start,'DD-MM-YYYY').format('DD-MM-YYYY')<=moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").format('DD-MM-YYYY')&&moment(props?.lastseenIndate.end,'DD-MM-YYYY').format('DD-MM-YYYY')>=moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").format('DD-MM-YYYY')?mapicon:mapRedicon:getSecondsTodayBydate(item?.HEARTBEAT_TS)<24?mapicon:mapRedicon,
               scaledSize: new window.google.maps.Size(25, 25)
            }}
            defaultOptions={{ optimized:false }}
            // onMouseOver={(data) => onMarkerClicked(data, item)}
            key={id}
            onClick={() => (onOpenInfoModel(item?.UNIQUE_ID))}
            position={{ lat: item?.LATITUDE?parseFloat(item?.LATITUDE):'', lng:item?.LONGITUDE?parseFloat(item?.LONGITUDE):''}}
         /></>))}
         {props?.GatewayInfo?.map((item, id) => (GatewayShowInfo?.filter(gateid=>gateid===item?.GATEWAY_ID)?.length>0&&<><Marker
            defaultZoomOnClick={4}
            icon={{
               url: tower,
               scaledSize: new window.google.maps.Size(25, 25)
            }}
            defaultOptions={{ optimized:false }}
            // onMouseOver={(data) => onMarkergateway(data, item)}
            key={id}
            onClick={() => (props.onHandalGateway(item))}
            position={{ lat: item?.LATITUDE?parseFloat(item?.LATITUDE):'', lng:item?.LONGITUDE?parseFloat(item?.LONGITUDE):''}}
         />
         {radiousDetail?.isRedious?getwayIDHL?getwayIDHL==item?.GATEWAY_ID? GatewayShowInfo?.filter(gateid=>gateid==item?.GATEWAY_ID)?.length>0&&<Circle
    onClick={(event) => {
     console.log("click");
    }}
    center={{ lat: item?.LATITUDE?parseFloat(item?.LATITUDE):'', lng:item?.LONGITUDE?parseFloat(item?.LONGITUDE):''}}
    radius={radiousDetail?.km*1000}
	options={{
		strokeColor: "#0000FF",
      fillColor: '#0000FF',
      fillOpacity: 0.1
	  }}
   />:'':GatewayShowInfo?.filter(gateid=>gateid===item?.GATEWAY_ID)?.length>0&&<Circle
   onClick={(event) => {
    console.log("click");
   }}
   center={{ lat: item?.LATITUDE?parseFloat(item?.LATITUDE):'', lng:item?.LONGITUDE?parseFloat(item?.LONGITUDE):''}}
   radius={radiousDetail?.km*1000}
  options={{
     strokeColor: "#0000FF",
     fillColor: '#0000FF',
     fillOpacity: 0.1
    }}
  />:''}</>))}
      </GoogleMap>
      {jobDetail?.activeMarker?.lat && jobDetail.isOpen &&
         <InfoWindow position={{ lat: jobDetail?.activeMarker?.lat, lng: jobDetail?.activeMarker?.lng }} onCloseClick={onToggleOpen} options={{
            pixelOffset: new window.google.maps.Size(
               0, -15
            )
         }} >
            <div className="detailCard">
               <div className="d-flex main-div">
                  <div className="d-inline-block">
                     <h1 className="font-20">{jobDetail?.Info?.address_compony_name}
                     </h1>
                     <div class="form-group mb-0"><label class="f-800">Business Partner Number : </label><label id="">{jobDetail?.Info?.BUSINESS_PARTNER_NUMBER}</label></div>
                     <div class="form-group mb-0"><label class="f-800">Installation Number : </label><label id="">{jobDetail?.Info?.INSTALLATION_NUMBER}</label></div>
                     <div class="form-group mb-0"><label class="f-800">Customer ID : </label><label id="">{jobDetail?.Info?.CUSTOMER_ID}</label></div>
                     <div class="form-group mb-0"><label class="f-800">Modem Serial Numbe : </label><label id="">{jobDetail?.Info?.DEVICE_SERIAL_NO}</label></div>
                  </div>
               </div>
            </div>
         </InfoWindow>}
         {gatewayDetails?.activeMarker?.lat && gatewayDetails.isOpen &&
         <InfoWindow position={{ lat: gatewayDetails?.activeMarker?.lat, lng: gatewayDetails?.activeMarker?.lng }} onCloseClick={onToggleOpen} options={{
            pixelOffset: new window.google.maps.Size(
               0, -15
            )
         }} >
            <div className="detailCard">
               <div className="d-flex main-div">
                  <div className="d-inline-block">
                     <h1 className="font-20">{gatewayDetails?.Info?.GATEWAY_NAME}
                     </h1>
                    
                  </div>
               </div>
            </div>
         </InfoWindow>}
   </>
   );
}
))