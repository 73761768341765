import React, { useState } from "react";
import { useTable } from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { HolderOutlined } from "@ant-design/icons";
import { Checkbox, InputNumber, Switch } from "antd";


export default function DraggabalTable({ columns, data, setitem, onDragEnd }) {
  const [records, setRecords] = React.useState(data || []);
  const [ShowIn, setShowIn] = useState('')

  const getRowId = React.useCallback((row) => {
    return row.Order;
  }, []);
  React.useEffect(() => {
    setRecords(data)
  }, [data])

  React.useEffect(() => {
    setitem(records)
  }, [records]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    data: data || [],
    columns,
    getRowId
  });

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord]
        ]
      })
    );
    if (typeof onDragEnd === 'function') {
      onDragEnd();
    }
  };

  const onHandalswitch = async (e, col) => {
    if (e == true) {
      setRecords(data.map((item) => (item?.Column == col ? { ...item, Display: "Yes" } : item)))
    } else {
      console.log(e, col, data.map((item) => (item?.Column == col ? { ...item, Display: "No" } : item)));
      setRecords(data.map((item) => (item?.Column == col ? { ...item, Display: "No" } : item)))
    }
  }
  const onHandalCheckboxDecimal = async (e, col) => {
    if (e.target.checked == true) {
      setRecords(data.map((item) => (item?.Column == col ? { ...item, Float: '' } : item)))
    } else {
      const removeProperty = (propKey, { [propKey]: propValue, ...rest }) => rest;
      setRecords(data.map((item) => (item?.Column == col ? removeProperty('Float', item) : item)))
    }
  }
  const onHandalMFCheck=async(e,col)=>{
    if(e.target.checked==true){
      setRecords(data.map((item)=>(item?.Column==col?{...item,CF:true}:item)))
    }else{
      setRecords(data.map((item)=>(item?.Column==col?{...item,CF:false}:item)))
    }
  }
  const onHandalCheckboxDivision = async (e, col) => {
    if (e.target.checked == true) {
      setRecords(data.map((item) => (item?.Column == col ? { ...item, Division: true } : item)))
    } else {
      setRecords(data.map((item) => (item?.Column == col ? { ...item, Division: false } : item)))
    }
  }

  const onHandalInput = async (e, col, Type) => {
    setRecords(data.map((item) => (item?.Column == col ? { ...item, [Type]: e.target.value } : item)))

  }
  return (
    <DndProvider backend={HTML5Backend}>
      <table {...getTableProps()} class="table table-striped fixed_header1" style={{ width: '900px' }}>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th></th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={{ minWidth: '250px' }}>{column.render("Header")}</th>
              ))}
              <th>Status</th>
              <th style={{ minWidth: '180px' }}>Decimal Number(Fraction Part)</th>
              <th style={{ minWidth: '180px' }}>Value Division Number</th>
              <th style={{ minWidth: '180px' }}>Is Apply Coversion Fector?</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map(
            (row, index) =>
              prepareRow(row) || (
                <Row
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  onHandalswitch={(e, col) => onHandalswitch(e, col)}
                  onHandalCheckboxDivision={(e, col) => onHandalCheckboxDivision(e, col)}
                  onHandalCheckboxDecimal={(e, col) => onHandalCheckboxDecimal(e, col)}
                  onHandalInput={(e, col, Type) => onHandalInput(e, col, Type)}
                  setShowIn={(e) => setShowIn(e)}
                  onHandalMFCheck={(e,col)=>onHandalMFCheck(e,col)}
                  ShowIn={ShowIn}
                  {...row.getRowProps()}
                />
              )
          )}
        </tbody>
      </table>
    </DndProvider>
  );
};

const DND_ITEM_TYPE = "row";

const Row = ({ row, index, moveRow, onHandalswitch, onHandalCheckboxDivision, onHandalCheckboxDecimal, onHandalInput, setShowIn, ShowIn,onHandalMFCheck,IS_MF }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveRow(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);
  return (
    <tr ref={dropRef} style={{ opacity }}>
      <td ref={dragRef} style={{ height: '45px' }}><HolderOutlined /></td>
      {row.cells.map((cell, id) => {
        return <td {...cell.getCellProps()} style={{ height: '45px' }}>{ShowIn == row?.original?.Column && row?.cells?.length - 1 == id ? '' : cell.render("Cell")}{ShowIn == row?.original?.Column && row?.cells?.length - 1 == id ? '' : row?.cells?.length - 1 == id ? <span onClick={() => setShowIn(row?.original?.Column)}><i className="fas fa-pen ml-2" style={{ cursor: 'pointer' }} /></span> : ''}{ShowIn == row?.original?.Column && row?.cells?.length - 1 == id ? <><input type='text' onChange={(e) => (onHandalInput(e, row?.original?.Column, "Header"))} value={row?.original?.Header} /><span className='ml-2' onClick={() => setShowIn('')} style={{ cursor: 'pointer' }}><i className="fas fa-times" /></span></> : ''}</td>;
      })}
      <td style={{ height: '45px' }} className='costom-switch'><Switch size="small" checked={row?.original?.Display == "Yes" ? true : false} onChange={(e) => {
        onHandalswitch(e, row?.original?.Column)
        console.log(row)
      }} /></td>
      <td style={{ height: '45px' }} className='costom-switch text-center'><Checkbox checked={row?.original?.Float !== undefined ? true : false} onChange={(e) => (onHandalCheckboxDecimal(e, row?.original?.Column))} disabled={row?.original?.Type == 'datetime' ? true : false} className='mr-2' />{row?.original?.Float !== undefined ? <InputNumber min={0} max={10} onChange={(ei) => onHandalInput({ target: { value: ei } }, row?.original?.Column, "Float")} value={row?.original?.Float} /> : ''}</td>
      <td style={{ height: '45px' }} className='costom-switch text-center'><Checkbox checked={row?.original?.Division ? row?.original?.Division : false} onChange={(e) => (onHandalCheckboxDivision(e, row?.original?.Column))} disabled={row?.original?.Type == 'datetime' ? true : false} className='mr-2' />{row?.original?.Division == true ? <InputNumber onChange={(ei) => onHandalInput({ target: { value: ei } }, row?.original?.Column, "DivisionNumber")} value={row?.original?.DivisionNumber} /> : ''}</td>
      <td style={{height:'45px'}} className='costom-switch text-center'><Checkbox checked={row?.original?.CF?row?.original?.CF:false} onChange={(e)=>(onHandalMFCheck(e,row?.original?.Column))} disabled={row?.original?.DataType=='datetime'?true:false} className='mr-2'/></td>
    </tr>
  );
};