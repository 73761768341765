import { DatePicker, Modal, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import Pagination from '../../../../Commoncomponet/Pagination';
import { geModemIdWiseColumnName, getHeardbeatData, getLoraTableWiseData, getScriptCodeIdWiseColumnName, getStartAndEndDateByTable, reportCreacteInTableName,getlora_unlock_activity ,UpdateLoraunlockConfig} from '../../../../Commoncomponet/Utils';
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import { BootstrapTooltip, exportCSVFile, exportExcelDownload } from '../../../../Commoncomponet/common';
import { toast } from 'react-toastify';
export default function Unlockreq(props) {

    const [PageSize, setPageSize] = useState(50);
	const [numberofPage, setnumberofPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [loader, setLoader] = useState(false);
	const [heartData, setHeartData] = useState([])
    const [ColumnName,setColumnName]=useState({})
	const [sortField,setsortField]=useState('SRNO')
	const [sortDirection,setsortDirection]=useState('desc')
	const [UnloackData, setUnloackData] = useState([]);
	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))

    const ongetHeardbeatData = async () => {
		setLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		const data = await getLoraTableWiseData({ start: firstPageIndex, end: PageSize }, { tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.unlockreq,changeCol:true,colName:sortField,sortDirection:sortDirection })
		if (data.success === true) {
			setHeartData(data.data.data)
			setnumberofPage(data.data.total)
			setLoader(false)
		} else {
			console.log('something want wrong')
			setLoader(false)

		}
	}
	const ongetScriptCodeIdWiseColumnName=async()=>{
		
		const data=await geModemIdWiseColumnName(props?.GSMdata?.MODEM_TYPE)
        if(data.success===true){
            if(data?.data?.data?.length>0){
            setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
            }
        }else{
console.log('something want wrong');
        }
	
    }

	useEffect(() => {
		ongetHeardbeatData();
	  }, [currentPage, PageSize]);
    useEffect(()=>{
        ongetHeardbeatData()
		ongetScriptCodeIdWiseColumnName()
    },[])

	const [report, setReport] = useState({ value: '', name: '', start_date: '', end_date: '' })
	  const [reportType, setReportType] = useState('')
	  const [ismodel, setModel] = useState(false)
	  const [ReportLoader,setReportLoader]=useState(false)
	  const [OtpShow,setOtpShow]=useState(false)
	  const ReportType=[{label:'View Table Info',value:'View_Table_Info'},{label:'All Table Info',value:'All_Table_Info'},{label:'Full Table',value:'Full_Table'}]
	  const [ExpireDate,setExpireDate]=useState({})
	const ongetStartAndEndDateByTable = async (tableName, col) => {
		console.log("Wanted to check if this is calling or not")
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.unlockreq, col: 'ENTRYTS' })
		if (data.success === true) {

			console.log("Expiry Dates == ", data.data)
			setExpireDate(data.data)
			setModel(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}
	  const onHandalReport = (e) => {
		setReport({ ...report, value: e.target.value, name: 'All GSM Consumer Details' })
	  }
		const ongetGenretedAllGSMReport=async(Iscsv)=>{
			if(reportType!=''&&report.value!==''){
				if(report?.value!='Full_Table'){
					if(report?.start_date&&report?.end_date){
						const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
			const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
			console.log(x>y,x,y);
						if(x>y){
						return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
						}
					}else{
						return	toast.warn('Please Select "Start Date" And "End Date"')
					}
				}
			setReportLoader(true)
			const data=await reportCreacteInTableName({tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.unlockreq,col:'ENTRYTS', start_date: moment(report.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"), end_date: moment(report.end_date, "DD-MM-YYYY").format("YYYY-MM-DD"),IsCondition:report?.value=='Full_Table'?false:true})
			if(data.success==true){
		  var arrayofData=[]
		  var headers={}
		   
		  if(data.data.data.length==0){
		setReportLoader(false)
		toast.warn('Data Not Found')
		  }
		  var TypeReport=report?.value
			data.data.data.map((item,id)=>{
			  arrayofData[id]={}
			  ColumnName?.Modem_Data?.unlockreq?.length>0&&ColumnName?.Modem_Data?.unlockreq?.map((col)=>{
				if(TypeReport=='View_Table_Info'){
				if(col?.Display=="Yes"){
				  if(id==0){headers[col.Header]=col.Header}
				  if(col?.Type=='datetime')
				  {
					arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
				  }else{
					if(col?.Float){
					  arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
					}else{
					arrayofData[id][col.Header]=item[col.Column]
					}
				  }
				}
			  }else if(report?.value=='All_Table_Info'||report?.value=='Full_Table'){
				if(id==0){headers[col.Header]=col.Header}
				if(col?.Type=='datetime')
				  {
					arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
				  }else{
					if(col?.Float){
					  arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
					}else{
					arrayofData[id][col.Header]=item[col.Column]
					}
				  }
			  }
				
			  })
			  if(id==(data.data.data.length-1)){
				if(reportType==='csv'){
					if(report?.value!='Full_Table'){
				  exportCSVFile(headers,arrayofData,`unlockreq_`+'('+report.start_date+')'+'_'+'('+report.end_date+')'+`_${new Date().getTime()}`)
					}else{
						exportCSVFile(headers,arrayofData,`unlockreq_${new Date().getTime()}`)

					}
		setReportLoader(false)
	
				 }else{
					if(report?.value!='Full_Table'){
				  exportExcelDownload(arrayofData,`unlockreq_`+'('+report.start_date+')'+'_'+'('+report.end_date+')'+`_${new Date().getTime()}`)
					}else{
						exportExcelDownload(arrayofData,`unlockreq_${new Date().getTime()}`)

					}
		setReportLoader(false)
	
				 }
				}
		  })
		  
				
			}else{
				console.log('something want wrong')
				setReportLoader(false)
			}
	  }else{
		return	toast.warn('Please Enter Mandatory Fields')
	}
		}

		const [isModalVisible, setIsModalVisible] = useState(false);
	const [AllData, setAllData] = useState({})
		const onAllShowData = (item) => {
			setIsModalVisible(true)
			setAllData(item)
		}
		const handleCancel = () => {
			setIsModalVisible(false);
			setAllData({})
		};

		const onSort = (field) => {
			if (sortField === field) {
				setsortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
			} else {
				setsortField(field);
				setsortDirection('asc');
			}
		};
	  
	  useEffect(()=>{
		ongetHeardbeatData()
	  },[sortDirection,sortField])

	  const [isOpenUnlockModal,setisOpenUnlockModal]=useState(false)
const [UnlockData,setUnlockdata]=useState({"UNLOCK_STATUS":0,"UNLOCK_TIME":0})
const onOpenModale=async()=>{
 await props?.onConsumerUpdateDate()
  setUnlockdata({"UNLOCK_STATUS":props?.GSMdata?.UNLOCK_STATUS||0,"UNLOCK_TIME":props?.GSMdata?.UNLOCK_TIMEOUT||0})
  setisOpenUnlockModal(true)
 ongetgsm_unlock_activity()

}

const onSubmitCommand=async()=>{
	if((UnlockData?.UNLOCK_TIME<=10&&UnlockData?.UNLOCK_TIME>0)||(UnlockData?.UNLOCK_STATUS!=0)){
	const data=await UpdateLoraunlockConfig({unlock_json:UnlockData,userId:userInfo?.UNIQUE_ID,consumer_id:props?.GSMdata?.UNIQUE_ID},props?.GSMdata?.UNIQUE_ID)
	if(data.success==true){
	  toast.success('Update Unlock Config Success')
	  setisOpenUnlockModal(false)
	 await props?.onConsumerUpdateDate()
	}else{
	  toast.error('Something Want Wrong')
	}
	}else{
	  toast.error('Unlock Time Must Be 1-10 Minute')
	}
	}
	
	const ongetgsm_unlock_activity = async () => {
	  
	  const data = await getlora_unlock_activity(props?.GSMdata?.UNIQUE_ID);
	  if (data.success === true) {
		setUnloackData(data.data.data)
	  } else {
		console.log("something want wrong");
	  }
	  };
  return (
    <>

<Modal title="Unlock Request" visible={isOpenUnlockModal} footer="" onCancel={() => {setisOpenUnlockModal(false)
      setUnloackData([])}} width={1000}>
      <div class="form-row">
      <div class="form-group col-md-3">
      <label for="inputState" className=' pr-2'>Unlock Status: {UnlockData?.UNLOCK_STATUS==1?'Unlocked':UnlockData?.UNLOCK_STATUS==0?'Locked':''}</label><br/>
      <a class={`btn ${UnlockData?.UNLOCK_STATUS==1?'btn-danger':'btn-success'} text-white mr-1`} data-toggle="tooltip" data-placement="bottom" title="Unlock Offline OTP" style={{marginLeft:'35px'}} onClick={(e)=>(setUnlockdata({...UnlockData,UNLOCK_STATUS:UnlockData?.UNLOCK_STATUS==1?0:1}))}>
      {UnlockData?.UNLOCK_STATUS==1?<span ><i style={{fontSize:"18px",cursor:'pointer'}} class="fa">&#xf09c;</i></span>:''}
      {UnlockData?.UNLOCK_STATUS==0?<span><i style={{fontSize:"18px",cursor:'pointer'}} class="fa">&#xf023;</i></span>:''}
      
      </a>
      {/* <Switch  checked={UnlockData?.UNLOCK_STATUS==1?true:false}  onChange={(e)=>(setUnlockdata({...UnlockData,UNLOCK_STATUS:e==true?1:0}))}/> */}
</div>
{UnlockData?.UNLOCK_STATUS==1?<div class="form-group col-md-4">
						<label for="inputState">Unlock Time (1-10 Minute):</label>
						<input type="number" class="form-control"  name="FirstName" onChange={(e)=>(setUnlockdata({...UnlockData,UNLOCK_TIME:e.target.value?parseInt(e.target.value):0}))} defaultValue={UnlockData?.UNLOCK_TIME}/>
					</div>:''}
          <div class="ml-2 mt-4">
					<input type="submit" class="btn btn-danger" value="Submit" name="filter_customer_form" onClick={onSubmitCommand} />
					
				</div>
          </div>
          
        <div className="table-responsive mt-5">
								<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
        <table id="table_id" className="tableCustom table table-striped ">
										<thead>
											<th>SRNO</th>
											<th>CREATED_AT</th>
											<th>CREATED_BY</th>
											<th>CONSUMER_UNIQUE_ID</th>
											<th>UNLOCK_STATUS</th>
											<th>UNLOCK_TIME_IN_MINUTE</th>
										</thead>
										<tbody>
											{UnloackData.length > 0 && UnloackData.map((item, id) => (
												<tr className="trheigth" >
												  <td>{item?.SRNO}</td>
											<td>{moment.utc(item?.CREATED_AT).format("DD-MM-YYYY hh:mm:ss A")}</td>
											<td>{item?.CREATED_BY}</td>
											<td>{item?.CONSUMER_UNIQUE_ID}</td>
											<td>{item?.UNLOCK_STATUS}</td>
											<td>{item?.UNLOCK_TIME_IN_MINUTE}</td>
												</tr>
											))}
										</tbody>
									</table>
                  </div>
                  </div>
			</Modal>
		<Modal title="All Data" visible={isModalVisible} onOk={() => (setIsModalVisible(false))} footer='' onCancel={handleCancel} width={1000}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Data</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
						{ ColumnName?.Modem_Data?.unlockreq?.map((col)=>(col?.Type=='datetime'?<tr>
								<td style={{ width: "50%" }}>{col?.Header}</td>
								<td style={{ width: "50%" }}>{AllData[col?.Column]?moment.utc(AllData[col?.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>
							</tr>:<tr>
								<td style={{ width: "50%" }}>{col?.Header}</td>
								<td style={col?.Column=='UNITS'||col?.Column=='ERRORBIN'||col?.Column=='RAWDATA'?{ maxWidth: '500px'}:{ width: "50%" }}>{AllData[col?.Column]}</td>
							</tr>))}
						</tbody>
					</table>
				</div>

			</Modal>
	<Modal title="Generate Reports" visible={ismodel} footer='' onCancel={()=>setModel(false)} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={onHandalReport}>
												<option value="">Report Type</option>
												{ReportType?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div>
										{report?.value!=''&&report?.value!='Full_Table'?<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
												}} />
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
													}} />
											</div></>:''}
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLS</option>
												<option value="csv">CSV</option>
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader==true?'disabled btn-progress':''}`} value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
										<Link className="btn btn-light text-dark" onClick={()=>setModel(false)}>Cancel</Link>
									</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
        <section className="card-diff-section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4><BootstrapTooltip title={`Table Name is ${props?.GSMdata?.UNIQUE_TABLE_NAME?.unlockreq}`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
									<div className="card-header-action">
										<div id="filtergrm">
										<a class={`btn ${props?.GSMdata?.UNLOCK_STATUS==1?'btn-danger':'btn-success'} text-white mr-1`} data-toggle="tooltip" data-placement="bottom" title="Unlock Offline OTP" onClick={()=>{onOpenModale()}} >
                                            
											{props?.GSMdata?.UNLOCK_STATUS==1?<span><i style={{fontSize:"18px"}} class="fa">&#xf09c;</i></span>:''}
											{props?.GSMdata?.UNLOCK_STATUS==0?<span><i style={{fontSize:"18px"}} class="fa">&#xf023;</i></span>:''}
											{props?.GSMdata?.UNLOCK_STATUS==null?<span><i style={{fontSize:"18px"}} class="fa">&#xf023;</i></span>:''}
											</a>
                                            {/* <a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Unlock Offline OTP" onClick={()=>{onGenrateOfflineOTP()}} >
                                                Unlock Offline OTP
                                            </a> */}
											<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh"  onClick={ongetHeardbeatData}>
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a>
											<div class="d-inline">
                      <button class="btn btn-info" onClick={()=>ongetStartAndEndDateByTable(true)}>
                      <i class="fa fa-download" aria-hidden="true"></i>
                      </button>

                    </div>
										</div>
									</div>
								</div>
								<div className="card-body">
									{loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)}
									{heartData.length === 0 && !loader && <h4 className="text-center">No Data Found</h4>}
									{heartData.length > 0 &&
										<div className="table-responsive tw-h-screen tw-max-h-screen">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												
												<table id="table_id" className="table border table-borderless table-striped font-10">
													<thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100'>
														<tr className="tw-border-b">
														{ ColumnName?.Modem_Data?.unlockreq?.length>0&&ColumnName?.Modem_Data?.unlockreq?.map((col)=>(col?.Display=="Yes"?<BootstrapTooltip title={col?.Column}><th onClick={()=>onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'>
                                        <div >{col?.Header}</div>
										{col?.Notes&&<BootstrapTooltip title={col?.Notes} placement='top'><span><i className="ml-2 fas fa-info-circle tw-text-xs"></i></span></BootstrapTooltip>}
                                        <div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                    </div></th></BootstrapTooltip>:''))}
														</tr>
													</thead>
													<tbody >
													{heartData.length > 0 && heartData.map((item, id) => (
															<tr className="trheigth  ">
															{ ColumnName?.Modem_Data?.unlockreq?.length>0&&ColumnName?.Modem_Data?.unlockreq
															?.map((col)=>(col?.Display=="Yes"?col?.Type=='datetime'?
															<td style={{ minWidth: "100px" }}>{item[col.Column]?
															moment.utc(item[col.Column]).format("DD/MM/YYYY hh:mm:ss A")
															:''}</td>:
															col?.Float==true?col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]/col?.DivisionNumber).toFixed(col?.Float)}</td>:<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{col?.Float&&item[col.Column]?item[col.Column].toFixed(col?.Float):item[col.Column]}</td>:
															col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]/col?.DivisionNumber}</td>:
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]}</td>
															:''))}
																{/* <td>{moment.utc(item.ENTRYTS).format("DD/MM/YYYY hh:mm:ss A")}</td> */}
																{/* <td className="font-13"><a class="action-icon text-success" onClick={() => onAllShowData(item)}><span data-toggle="tooltip" data-placement="bottom" title="AllData Data"><i class="fa fa-database fa-sm" aria-hidden="true"></i></span></a>&nbsp;</td> */}
															</tr>
														))}
													</tbody>
												</table>
												<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
											</div>
										</div>}

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
    </>
  )
}
