import React from 'react'
import { Award } from 'react-feather'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import MapPicker from 'react-google-map-picker'
import { useState } from 'react';
import {Modal} from 'antd'
import GeoLocation from '../../../Commoncomponet/GecodeLocation';
import { activeandinactive, CheckUseConsumer, getAccount, getProfilNameByAccountId, InsertGa, Table_Get_All_City_by_State, Table_Get_All_Country, Table_Get_All_State_by_Country, UpdateGa } from '../../../Commoncomponet/Utils';
import { useEffect } from 'react';
import {toast} from 'react-toastify'
import { BootstrapTooltip } from '../../../Commoncomponet/common';
import swal from "sweetalert2";
window.Swal = swal;
const DefaultLocation = { lat: null, lng: null};
const DefaultZoom = 10;
export default function AddGa() {
  const params = useParams();

   const [disable,setDisable]=useState(true)

	const [AccounData,setAccountData]=useState([])
    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
    const [isModalVisible, setIsModalVisible] = useState(false);
const [AccountInput,serAccountInput]=useState({name:'',account:params.accountId,country:'',state:'',city:''})
  const [location, setLocation] = useState(defaultLocation);
  const [gaId, setGaId] = useState('');
  const [flagBtn, setflegBtn] = useState(false);

  const [zoom, setZoom] = useState(DefaultZoom);
  const locations=useLocation()
  const history = useHistory();
  const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
  const [options,setoptions]=useState([])
  const [stateData,setstateData]=useState([])
  const [Citydata,setCitydata]=useState([])


  const getCountry=async()=>{
     const data=await Table_Get_All_Country()
     if(data.success==true){
        setoptions(data.data.data)
     }else{
        console.log('something want wrong')
     }
  }

  const getState=async(id)=>{
  const data=await Table_Get_All_State_by_Country(id)
  if(data.success==true){
     setstateData(data.data.data)
  }else{
     console.log('something want wrong')
  }
}

const getCity=async(id)=>{
  const data=await Table_Get_All_City_by_State(id)
  if(data.success==true){
     setCitydata(data.data.data)
  }else{
     console.log('something want wrong')
  }
}

useEffect(()=>{getCountry()},[])
  useEffect(() => {
    if (locations?.state) {
       serAccountInput({name:locations?.state?.CATEGORY1_NAME,account:locations?.state?.CATEGORY0
         ,country:locations?.state?.CATEGORY1_COUNTRY ,state:locations?.state?.CATEGORY1_STATE,city:locations?.state?.CATEGORY1_CITY,STATUS:locations?.state?.CATEGORY1_STATUS})
       setLocation({lat:locations?.state?.CATEGORY1_LAT_LONG?.split(',')[0], lng:locations?.state?.CATEGORY1_LAT_LONG?.split(',')[1]})
       setGaId(locations?.state?.CATEGORY1_UNIQUE_ID)
       setDisable(false)
       getState(locations?.state?.CATEGORY1_COUNTRY)
       getCity(locations?.state?.CATEGORY1_STATE)
    }
 }, [locations]);
  const handleCancel = () => {
    setIsModalVisible(false);
    setLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
};
  function handleChangeLocation (lat, lng){
    setLocation({lat:lat, lng:lng});
  }
  
  function handleChangeZoom (newZoom){
    setZoom(newZoom);
  }

  function handleResetLocation(){
    setDefaultLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
  }

  const onHandaleSelection=async()=>{
    const data=await getAccount()
    if(data.success===true){
        setAccountData(data.data.data)
    }else{
      console.log('somthing want wrong')
    }
  }

  useEffect(() => {
    onHandaleSelection()
    ongetProfilNameByAccountId()
  }, []);

  const onSubmitAccountData=async(e)=>{
    e.preventDefault()
    setflegBtn(true)
 if(AccountInput?.name===''){
    toast.error('Please Fill Name')
    setflegBtn(false)
 }else{
    if(gaId===''){
 const data=await InsertGa({...AccountInput,lat:location?.lat||'',lng:location?.lng||'',add_by:userInfo?.UNIQUE_ID,ROLES:userInfo?.ROLE})
 if(data.success===true){
    toast.success("Account GA Success")
    history.push(`/ga/${params?.accountId}`)
    setflegBtn(false)
 
 }else{
    toast.error("Something Went Wrong")
    setflegBtn(false)
 
 }
    }
    else{
       const data=await UpdateGa({...AccountInput,lat:location?.lat||'',lng:location?.lng||'',add_by:userInfo?.UNIQUE_ID},gaId)
 if(data.success===true){
    toast.success("Update GA Success")
    history.push(`/ga/${params?.accountId}`)
    setflegBtn(false)
 
 }else{
    toast.error("Something Went Wrong")
    setflegBtn(false)
 
 }
    }
 }
   }

   const oninactive=async()=>{
      const check=await CheckUseConsumer({colName:'CAT1_ID',value:gaId})
      if(check.success===true&&check.data.data[0].valid>0){
         swal.fire({
            title: `There are ${check.data.data[0].valid} consumers related to this ga. Please Deactive those Consumeres to deactive this ga.`,
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "error",
            showCancelButton: true,
         })
      }else{
         swal.fire({
            title: "Are You Sure InActive ga?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
         }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'category1',colName:'CATEGORY1_STATUS',value:'0',matchId:'CATEGORY1_UNIQUE_ID'},gaId)
      if(data.success===true){
         toast.success('Success Inactive ga')
         history.push(`/ga/${params?.accountId}`)
      }else{
         toast.error('something want wrong')
      }
   }
         })
   }
     }
     const onactive=async()=>{
      swal.fire({
         title: "Are You Sure Active ga?",
         // text: "Once deleted, you will not be able to recover this User Details!",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'category1',colName:'CATEGORY1_STATUS',value:'1',matchId:'CATEGORY1_UNIQUE_ID'},gaId)
      if(data.success===true){
         toast.success('Success Active ga')
         history.push(`/ga/${params?.accountId}`)
      }else{
         toast.error('something want wrong')
      }
   }
      })
     }
   
     const [NameObj,setNameObj]=useState({})

     const ongetProfilNameByAccountId=async()=>{
        const data=await getProfilNameByAccountId({ID:params.accountId})
        if(data.success===true){
           setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME||'{}'))
        }else{
           console.log('something want wrong')
        }
     }
  return (
    <>
	<Modal title="Map" visible={isModalVisible} onOk={()=>(setIsModalVisible(false))} onCancel={handleCancel} width={800}>
    <MapPicker defaultLocation={{ lat: parseFloat(location?.lat||'19.506765954448635'), lng: parseFloat(location?.lng||'76.46875000000001')}}
    zoom={zoom}
    mapTypeId="roadmap"
    style={{height:'500px'}}
    onChangeLocation={handleChangeLocation} 
    onChangeZoom={handleChangeZoom}
    apiKey='AIzaSyAo0xl_P_6Ga-ee6ggeYe1JAE2jq3PxU5s'/>
    </Modal>
    <div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
                            <Award className='header-logo' style={{ height: "16px" }} /> Profile
							</Link>
						</li>
						<li className="breadcrumb-item">
						<Link to={`/ga/${params?.accountId}`}>
                  {NameObj.CATEGORY1||'CATEGORY1'}
						</Link>
					</li>
						<li className="breadcrumb-item">
							<Link >{gaId!==''?'Update '+(NameObj.CATEGORY1||'CATEGORY1'):'Add '+(NameObj.CATEGORY1||'CATEGORY1')}</Link>
						</li>
					</ol>
				</nav>
        <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>{gaId!==''?'Update '+(NameObj.CATEGORY1||'CATEGORY1'):'Add '+(NameObj.CATEGORY1||'CATEGORY1')}</h4>
                           <div class="card-header-action">  
                           {userInfo?.ROLE==='ROLE1650614500708'&&gaId!==''?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:gaId!==''&&locations?.state?.CATEGORY1_edit?.filter((items)=>(items===gaId)).length>0?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{userInfo?.ROLE==='ROLE1650614500708'&&gaId!==''&&AccountInput?.STATUS==1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:gaId!==''&&AccountInput?.STATUS==1&&AccountInput?.STATUS==1&&locations?.state?.CATEGORY1_active.filter((item)=>(item===gaId)).length>0?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{userInfo?.ROLE==='ROLE1650614500708'&&gaId!==''&&AccountInput?.STATUS==0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:gaId!==''&&AccountInput?.STATUS==0&&AccountInput?.STATUS==0&&locations?.state?.CATEGORY1_active.filter((item)=>(item===gaId)).length>0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''} 
                              <Link to={`/ga/${params?.accountId}`} class="btn btn-info text-white">Back</Link>
                           </div>
                        </div>
                        <div class="card-body">
                           <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onSubmitAccountData}>
                              <div class="form-row">
                              <div class="form-group col-md-3">
                                    <label for="inputEmail4">Account:</label>
                                    <select class="form-control form-select" name="accountname" id="accountname" value={params?.accountId} disabled={true}>
                                       <option value="">Select Account</option>
                                                                         {AccounData&&AccounData.map((item)=>(<option value={item?.UNIQUE_ID}>{item?.CATEGORY0}</option>))  }
                                                                           </select>
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Name:<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" name="account_name" placeholder="Enter Account Name" required="" onChange={(e)=>(serAccountInput({...AccountInput,name:e.target.value}))} value={AccountInput?.name} disabled={!disable}/>
                                 </div>
                              </div>
                              <div class="row">
                                          <div class="col-lg-3">
                                          <div class="form-group">
                                       <label for="inputPassword4">Country</label>
                                       <select class="form-control second_meter_type form-select" onChange={(item) => {serAccountInput({ ...AccountInput, country: item.target.value })
                                    getState(item.target.value)}} value={AccountInput?.country} required="" name="meter_type" disabled={!disable}>
                                          <option value="">Select Country</option>
                                          {options && options.map((item) => <option value={item.id}>{item.country}</option>)}
                                       </select>
                                            
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                          <div class="form-group">
                                          <label for="inputPassword4">State</label>
                                       <select class="form-control second_meter_type form-select" onChange={(item) => {serAccountInput({ ...AccountInput, state: item.target.value })
                                    getCity(item.target.value)}} value={AccountInput?.state} required="" name="meter_type" disabled={!disable} >
                                          <option value="">Select State</option>
                                          {stateData && stateData.map((item) => <option value={item.id}>{item.state}</option>)}
                                       </select>
                                           
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                          <div class="form-group">
                                          <label for="inputPassword4">City</label>
                                       <select class="form-control second_meter_type form-select" onChange={(item) => (serAccountInput({ ...AccountInput, city: item.target.value }))} value={AccountInput?.city} required="" name="meter_type" disabled={!disable}>
                                          <option value="">Select City</option>
                                          {Citydata && Citydata.map((item) => <option value={item.id}>{item.city}</option>)}
                                       </select>
                                          </div>
                                          </div>
                                       </div>
                              <div class="form-row">
                                 <div class="form-group col-md-3">
                                    <label for="inputState">Latitude:</label>
                                    <input type="text" class="form-control MapLat" id="loca_lat" name="loca_lat" placeholder="Latitude" value={location?.lat||''} onChange={(e)=>(setLocation({...location,lat:e.target.value.replace(/[^0-9.]/gi, '')}))} disabled={!disable}/>    
                                    <p className='text-danger' 
 style={{ fontSize: "10px" }}>* Allowed Only Number (0-9) And Periods(.) And Format (D.D°)</p>

                                    <input type="hidden" class="form-control MapLat" id="btntypeforsubmit" name="submityper" />    
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputState">Longitude:</label>
                                    <input type="text" class="form-control MapLon" id="loca_long" name="loca_long" placeholder="Longitude" value={location?.lng||''} onChange={(e)=>(setLocation({...location,lng:e.target.value.replace(/[^0-9.]/gi, '')}))} disabled={!disable}/>  
                                    <p className='text-danger' 
 style={{ fontSize: "10px" }}>* Allowed Only Number (0-9) And Periods(.) And Format (D.D°)</p>

                                 </div>
                                 <div class="col-lg-1" style={{marginTop:"30px"}}>
                                    <a class="btn btn-primary" data-toggle="tooltip" data-placement="bottom" title="" onClick={()=>setIsModalVisible(true)} id="maplink" data-original-title="Map"><i class="fa fa-location-arrow"></i></a>
                                 </div>
                              </div>
                              <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                           <div class="form-group" style={{marginTop:"20px"}}>
                              <div class="text-left">
                                  {userInfo?.ROLE==='ROLE1650614500708'&&disable?<input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={gaId===''?'Submit':'Update'} id="submitvn" />:disable?<input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={gaId===''?'Submit':'Update'} id="submitvn" />:''}
                                    
                                    <Link class={`btn btn-light text-dark ${flagBtn?'disabled':''}`} to={`/ga/${params?.accountId}`}>Cancel</Link>  
                              </div>
                           </div>
                           </form></div>
                     </div>
                  </div>
               </div>
            </div>
      </section>
      </div>
    </>
  )
}
