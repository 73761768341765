import React, { useEffect, useState } from 'react'
import Customtabs, { Tab } from '../../Commoncomponet/Tabs/Customtabs';
import { activeandinactive, DeleteTable, FilterIdWiseNewFilterGenerate, getAccount, getAreaById, getCircleById, getDivisionById, getGaById, GetRegisteredApi, getRolesById, getSubdevisionById, getUsersByID, RegisterApiRequest } from '../../Commoncomponet/Utils';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DropdownSelect from '../../Commoncomponet/Tabs/DropdownSelect';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';
import swal from 'sweetalert2';
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader';
import './apiConsole.css'
import { BootstrapTooltip } from '../../Commoncomponet/common';
window.Swal = swal;

const duration = [{ id: 5, name: "5min" }, { id: 10, name: "10min" }, { id: 15, name: "15min " }, { id: 30, name: "30min" }, { id: 60, name: "60min" }]

function ClientDetails({ client, onClose, fetchData }) {
    const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
    const [selectedOption, setSelectedOption] = useState(duration[0].id);
    const [descriptions, setDescriptions] = useState("");
    const [clientId, setClientId] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [authCode, setAuthCode] = useState('')
    const [filterObj, setFilterObj] = useState({})
    const [SubdevisionData, setSubdevisionData] = useState([]);
    const [DivisionData, setDivisionData] = useState([]);
    const [CircleData, setCircleData] = useState([]);
    const [GaData, setGaData] = useState([]);
    const [AccounData, setAccountData] = useState([]);
    const [AccountKey, setAccountKey] = useState([])
    const [AreaData, setAreaData] = useState([]);
    const [Profile, setProfile] = useState({
        accountuser: false,
        gauser: false,
        circleuser: false,
        areauser: false,
        divisionuser: false,
        subDivisionuser: false,
    })
    const [permision, setpermision] = useState({})
    const [ApiName, setApiName] = useState("")
    const [isauth, setIsauth] = useState(false)

    const handleChangeS = (e) => {
        if (e.target.checked) {
            if (Profile.hasOwnProperty(e.target.id)) {
                setProfile({ ...Profile, [e.target.id]: true })
                setFilterObj({ ...filterObj, [e.target.name]: [] })
            }

        } else {
            if (Profile.hasOwnProperty(e.target.id)) {
                setProfile({ ...Profile, [e.target.id]: false })
                delete filterObj[e.target.name]
            }


        }
    }
    const onHandaleRoles = async () => {

        const data = await getRolesById(userInfo?.ROLE)
        if (data.success === true) {
            const obj = data.data.data[0]
            const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4) }

            setpermision(Roles)

        } else {
            console.log('something want wrong')

        }
    }
    const customStyles = {
        menu: provided => ({ ...provided, zIndex: 9999 }),
        multiValue: (provided, { data }) => {
            return {
                ...provided,
                width: 'min-content',
                backgroundColor: "#6777ef",
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "white",
        }),


    }

    const animatedComponents = makeAnimated();
    useEffect(() => {
        onHandaleRoles()
    }, []);

    const ongetGaById = async (id) => {
		const data1 = await getGaById(id);
		if (data1.success === true) {
			const options = []
			const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => (items === data.CATEGORY1_UNIQUE_ID)).length > 0||filterObj?.CAT0_ID?.includes(data?.CATEGORY0) || userInfo?.ROLE === 'ROLE1650614500708'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY1_UNIQUE_ID, label: item?.CATEGORY1_NAME })))
			setGaData(options)
		}
	};

	const ongetCircle = async (id) => {
		const data1 = await getCircleById(id);
		if (data1.success === true) {
			const options = []
			const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => (items === data.CATEGORY2_UNIQUE_ID)).length > 0||(filterObj?.CAT0_ID?.includes(data?.CATEGORY0)&&filterObj?.CAT1_ID?.includes(data?.CATEGORY1)) || userInfo?.ROLE === 'ROLE1650614500708'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY2_UNIQUE_ID, label: item?.CATEGORY2_NAME })))
			setCircleData(options);
		}
	};
	const ongetSubdevisionById = async (id) => {
		const data1 = await getSubdevisionById(id);
		if (data1.success === true) {
			const options = []
			const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter((items) => (items === data.CATEGORY4_UNIQUE_ID)).length > 0||(filterObj?.CAT0_ID?.includes(data?.CATEGORY0)&&filterObj?.CAT1_ID?.includes(data?.CATEGORY1)&&filterObj?.CAT2_ID?.includes(data?.CATEGORY2)&&filterObj?.CAT3_ID?.includes(data?.CATEGORY3)) || userInfo?.ROLE === 'ROLE1650614500708'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY4_UNIQUE_ID, label: item?.CATEGORY4_NAME })))
			setSubdevisionData(options);
		}
	};
	const ongetDivisionById = async (id) => {
		const data3 = await getDivisionById(id);
		if (data3.success === true) {
			const options = []
			const optionArray = data3?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => (items === data.CATEGORY3_UNIQUE_ID)).length > 0||(filterObj?.CAT0_ID?.includes(data?.CATEGORY0)&&filterObj?.CAT1_ID?.includes(data?.CATEGORY1)&&filterObj?.CAT2_ID?.includes(data?.CATEGORY2)) || userInfo?.ROLE === 'ROLE1650614500708'));
			optionArray.map((item) => (options.push({ value: item?.CATEGORY3_UNIQUE_ID, label: item?.CATEGORY3_NAME })))
			setDivisionData(options);
		}
	};
	const ongetArea = async (id) => {
		const data3 = await getAreaById(id);
		if (data3.success === true) {
			const options = []
			const optionArray = data3?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0||(filterObj?.CAT0_ID?.includes(data?.CATEGORY0)&&filterObj?.CAT1_ID?.includes(data?.CATEGORY1)&&filterObj?.CAT2_ID?.includes(data?.CATEGORY2)&&filterObj?.CAT3_ID?.includes(data?.CATEGORY3)&&filterObj?.CAT4_ID?.includes(data?.CATEGORY4)) || userInfo?.ROLE === 'ROLE1650614500708'));
			optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY5_NAME })))
			setAreaData(options);
		}
	};

    useEffect(() => {
        if (filterObj?.CAT0_ID?.length > 0) {
            ongetGaById(filterObj?.CAT0_ID);
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([])
        } else {
            setGaData([]);
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);
        }
    }, [filterObj.CAT0_ID]);

    useEffect(() => {
        if (filterObj?.CAT1_ID?.length > 0) {
            ongetCircle(filterObj?.CAT1_ID);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);

        } else {
            setCircleData([]);
            setDivisionData([]);
            setSubdevisionData([]);
            setAreaData([]);


        }
    }, [filterObj.CAT1_ID]);

    useEffect(() => {
        if (filterObj?.CAT2_ID?.length > 0) {
            ongetDivisionById(filterObj?.CAT2_ID);
            setAreaData([]);
            setSubdevisionData([]);

        } else {
            setAreaData([]);
            setDivisionData([]);
            setSubdevisionData([]);
        }
    }, [filterObj.CAT2_ID]);

    useEffect(() => {
        if (filterObj?.CAT3_ID?.length > 0) {
            ongetSubdevisionById(filterObj?.CAT3_ID);
            setAreaData([]);
        } else {
            setSubdevisionData([]);
            setAreaData([]);
        }
    }, [filterObj.CAT3_ID]);

    useEffect(() => {
        if (filterObj?.CAT4_ID?.length > 0) {
            ongetArea(filterObj?.CAT4_ID);
        } else {
            setAreaData([]);

        }
    }, [filterObj.CAT4_ID]);


    const onHandaleAccount = async () => {
        const data = await getAccount();
        if (data.success === true) {
            const options = []
            const optionArray = data?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708'))
            console.log(optionArray);
            optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY0 })))
            setAccountData(options);
            console.log('options = ', options)
            setAccountKey(data?.data?.data)
        } else {
            console.log("somthing want wrong");
        }
    };

    useEffect(() => {
        onHandaleAccount()
    }, [permision])



    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleApiNameChange = (e) => {
        setApiName(e.target.value);
    }
    const generateClientId = () => {
        const length = 32;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return result;
    };

    const showAuthCodeModal = () => {
        setIsauth(true)
    }

    const handleCancel = () => {
        setIsauth(false)
        onClose()

    }

    const handleCreate = async () => {
        const authCode = generateClientId()
        setAuthCode(authCode)
        if (filterObj?.CAT0_ID?.length > 0 && ApiName) {
            const sendApiReq = await RegisterApiRequest({ userId: userInfo?.UNIQUE_ID, apiName: ApiName, account: AccounData.map(item => item.value), createdBy: userInfo?.CREATED_BY, clientId: clientId, clinetSecret: clientSecret, scope: filterObj, duration: selectedOption, description: descriptions, oAuthCode: authCode })
            if (sendApiReq.success === true) {
                console.log("send = ", sendApiReq.data)
                fetchData()
                if (sendApiReq.data.status === 3000) {
                    showAuthCodeModal()
                }
            } else {
                console.log("Error while Adding API request = ", sendApiReq.err)
                toast.error("Error while Adding API request")
            }
        } else {
            toast.error("Please Input Scope And Name")
        }
    }


    useEffect(() => {
        const client_id = generateClientId();
        const client_secret = generateClientId();

        // setClientId(client_id);
        // setClientSecret(client_secret);
        ongetUsersByID()
    }, [])

    const ongetUsersByID = async () => {
        const res = await getUsersByID(userInfo?.UNIQUE_ID)
        if (res.success === true) {
            setClientId(res?.data?.data[0]?.CLIENT_ID);
            setClientSecret(res?.data?.data[0]?.CLIENT_SECRET);
        } else {
        }
    }

    const handleClientIdCopy = async () => {
        toast.success('Copy Success')
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(clientId);
        } else {
            document.execCommand("copy", true, clientId);
        }
    };

    const handleClientSecretCopy = async () => {
        toast.success('Copy Success')
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(clientSecret);
        } else {
            document.execCommand("copy", true, clientSecret);
        }
    };
    const handleAuthCodeCopy = async () => {
        toast.success('Copy Success')
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(authCode);
        } else {
            document.execCommand("copy", true, authCode);
        }
    };




    return (
        <>
            <Modal title="Generated Code" visible={isauth} footer='' onCancel={handleCancel} width={540}>
                <div className=''>
                    <div className='border !tw-border-indigo-500 tw-p-4'>
                        <p>This code for the above scope is valid for only {selectedOption} minutes</p>
                        <div class="tw-py-2 ">
                            <label >Auth Code : </label>
                            <input readOnly value={authCode} type="text" class="form-control" />
                        </div>
                        <button className='btn btn-primary' onClick={handleAuthCodeCopy}>COPY <i class="fa fa-copy"></i></button>

                    </div>

                </div>

            </Modal>

            <div className=" tw-z-10 tw-absolute tw-top-0 tw-right-0 tw-h-full tw-w-[70%] animate__fadeInRight_api">
                <div className="tw-flex tw-items-center tw-justify-end tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 sm:tw-block sm:tw-p-0">
                    {/* <div className="tw-fixed inset-0 tw-transition-opacity">
                        <div className="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75"></div>
                    </div> */}

                    <div className="tw-w-full tw-h-screen tw-overflow-scroll tw-transform tw-transition-all tw-bg-white tw-shadow-lg tw-rounded-lg">
                        <div className="tw-flex tw-items-center tw-justify-end px-4 py-2">
                            <button onClick={onClose} className="tw-text-gray-500 btn btn-light !tw-rounded-full">
                                <i className="fa fa-times"></i>
                            </button>
                        </div>
                        <div className="tw-px-4 tw-py-6">
                            <Customtabs activeTab="1" ulClassName=" tw-list-none" activityClassName="!tw-bg-indigo-500" >

                                <Tab title="Generate Code" className="mr-3">
                                    <div className="mt-1">
                                        <div class="tw-p-4 tw-w-2/5">
                                            <label>Api Name<span className="tw-text-red-500"> *</span> : </label>
                                            <input onChange={(e) => handleApiNameChange(e)} value={ApiName} type="text" class="form-control " />
                                        </div>
                                        <div className='tw-px-4 tw-py-2'>

                                            Scope :<span className="tw-text-red-500"> *</span>
                                            <fieldset className="customLegend !tw-mt-3">
                                                <legend className='tw-text-sm' >select scope : </legend>
                                                <div className="row" style={{
                                                    marginLeft: 10,
                                                    marginRight: 10

                                                }} >
                                                    <div className="col-12">
                                                        <div className="form-row">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" id="accountuser" name="CAT0_ID" className="custom-control-input " value="account" checked={Profile.accountuser} onChange={handleChangeS} />
                                                                <label className="custom-control-label" for="accountuser">CATEGORY0</label>
                                                            </div>
                                                            {Profile.accountuser && (<div className="form-group col-md-3 accountgrp" style={{ marginTop: -10 }} >
                                                                <Select value={AccounData.filter((item) => filterObj?.CAT0_ID?.includes(item.value)) || []} placeholder='Select account...' styles={customStyles} components={animatedComponents} name='account' isMulti options={AccounData} onChange={(e) => { setFilterObj({ ...filterObj, CAT0_ID: e.map((item) => (item.value)) }) }} />
                                                            </div>)}
                                                        </div>

                                                        <div className="form-row">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" id="gauser" name="CAT1_ID" className="custom-control-input" value="ga" checked={Profile.gauser} onChange={handleChangeS} />
                                                                <label className="custom-control-label" for="gauser">CATEGORY1</label>
                                                            </div>
                                                            {Profile.gauser && (<div className="form-group col-md-3 gagrp" style={{ marginTop: -10 }}>
                                                                <Select value={GaData.filter((item) => filterObj?.CAT1_ID?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={GaData} onChange={(e) => { setFilterObj({ ...filterObj, CAT1_ID: e.map((item) => (item.value)) }) }} />
                                                            </div>)}
                                                        </div>

                                                        <div className="form-row">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" id="circleuser" name="CAT2_ID" className="custom-control-input" checked={Profile.circleuser} value="circle" onChange={handleChangeS} />
                                                                <label className="custom-control-label" for="circleuser">CATEGORY2</label>
                                                            </div>
                                                            {Profile.circleuser && (<div className="form-group col-md-3 circlegrp" style={{ marginTop: -10 }}>
                                                                <Select value={CircleData.filter((item) => filterObj?.CAT2_ID?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={CircleData} onChange={(e) => { setFilterObj({ ...filterObj, CAT2_ID: e.map((item) => (item.value)) }) }} />
                                                            </div>)}
                                                        </div>

                                                        <div className="form-row">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" id="divisionuser" name="CAT3_ID" className="custom-control-input" checked={Profile.divisionuser} value="division" onChange={handleChangeS} />
                                                                <label className="custom-control-label" for="divisionuser">CATEGORY3</label>
                                                            </div>
                                                            {Profile.divisionuser && (<div className="form-group col-md-3 divisiongrp" style={{ marginTop: -10 }}>
                                                                <Select value={DivisionData.filter((item) => filterObj?.CAT3_ID?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={DivisionData} onChange={(e) => { setFilterObj({ ...filterObj, CAT3_ID: e.map((item) => (item.value)) }) }} />

                                                            </div>)}
                                                        </div>

                                                        <div className="form-row">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" id="subDivisionuser" name="CAT4_ID" className="custom-control-input" checked={Profile.subDivisionuser} value="subdivision" onChange={handleChangeS} />
                                                                <label className="custom-control-label " for="subDivisionuser">CATEGORY4</label>
                                                            </div>
                                                            {Profile.subDivisionuser && (<div className="form-group col-md-3 subdivisiongrp" style={{ marginTop: -10 }}>
                                                                <Select value={SubdevisionData.filter((item) => filterObj?.CAT4_ID?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={SubdevisionData} onChange={(e) => { setFilterObj({ ...filterObj, CAT4_ID: e.map((item) => (item.value)) }) }} />

                                                            </div>)}
                                                        </div>

                                                        <div className="form-row">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" id="areauser" name="CAT5_ID" className="custom-control-input" checked={Profile.areauser} value="area" onChange={handleChangeS} />
                                                                <label className="custom-control-label" for="areauser">CATEGORY5</label>
                                                            </div>
                                                            {Profile.areauser && (<div className="form-group col-md-3 areagrp" style={{ marginTop: -10 }}>
                                                                <Select value={AreaData.filter((item) => filterObj?.CAT5_ID?.includes(item.value)) || null} styles={customStyles} components={animatedComponents} name='account' isMulti options={AreaData} onChange={(e) => { setFilterObj({ ...filterObj, CAT5_ID: e.map((item) => (item.value)) }) }} />

                                                            </div>)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className='tw-px-4 tw-py-2 tw-w-2/5' >
                                            <DropdownSelect name={"duration"} options={duration} label={"Time Duration :"} value={selectedOption} onChange={(e) => handleSelectChange(e)} />
                                        </div>
                                        <div className='tw-px-4 tw-py-2 tw-w-2/5' >
                                            <label className="tw-text-gray-700" htmlFor="scope-desc">
                                                Scope Description :
                                            </label>
                                            <textarea onChange={(e) => setDescriptions(e.target.value)} value={descriptions} name="scope_desc" placeholder="Enter Scope Description" id="scope_desc" className='tw-block tw-appearance-none tw-w-full tw-border tw-border-indigo-200 tw-text-gray-700 tw-py-2 tw-px-2 tw-pr-8 tw-rounded tw-leading-tight focus:tw-outline-none focus:tw-bg-white focus:tw-border-indigo-500' ></textarea>
                                        </div>
                                        <div className='tw-px-4 tw-py-2 tw-w-2/5' >
                                            <button className='btn btn-success tw-px-4' onClick={handleCreate}>Create </button>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab title="Client Secret" className="mr-3">
                                    <div className="tw-mt-6">
                                        <div className="tw-px-5 tw-pb-2">
                                            <div class="tw-p-4 tw-w-2/5">
                                                <label >Client ID : </label>
                                                <div className='tw-flex'>

                                                    <input readOnly value={clientId} type="text" class="form-control" />
                                                    <button className='tw-border-none' onClick={handleClientIdCopy}><i class="fa fa-copy"></i></button>
                                                </div>
                                            </div>
                                            <div class="tw-p-4 tw-w-2/5">
                                                <label >Client Secret : </label>
                                                <div className='tw-flex'>

                                                    <input readOnly value={clientSecret} type="text" class="form-control " />
                                                    <button className='tw-border-none' onClick={handleClientSecretCopy}><i class="fa fa-copy"></i></button>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </Tab>

                            </Customtabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}



const ApiConsole = () => {
    const userInfo = JSON.parse(window.localStorage.getItem('user_info'))

    const [clientList, SetClientList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isApidataLoading, setIsApiDataLoading] = useState(false);
    const [ismodelFilterObj, setismodelFilterObj] = useState(false);
    const [FilterObject, setFilterObject] = useState({});
    function handleClientClick(client) {
        setSelectedClient(client);
    }

    function handleCloseClientDetails() {
        setSelectedClient(null);
    }
    const fetchData = async () => {
        setIsApiDataLoading(true)
        const getApiList = await GetRegisteredApi({ userId: userInfo?.UNIQUE_ID, roles: userInfo?.ROLE })
        if (getApiList.success === true) {

            if (getApiList.data.status === 3000) {
                SetClientList(getApiList.data.response)
                setIsApiDataLoading(false)

            }
        }
        else {
            toast.error("Error getting List of Your registered APIs", JSON.stringify(getApiList.err))
            setIsApiDataLoading(false)

        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    const onFilterIdWiseNewFilterGenerate = async (filterObj) => {
        const JsonObj = { CATEGORY0: filterObj?.CAT0_ID, CATEGORY1: filterObj?.CAT1_ID, CATEGORY2: filterObj?.CAT2_ID, CATEGORY3: filterObj?.CAT3_ID, CATEGORY4: filterObj?.CAT4_ID, CATEGORY5: filterObj?.CAT5_ID }
        const data = await FilterIdWiseNewFilterGenerate({ Filter_Obj: JSON.stringify(JsonObj) });
        if (data.success == true) {
            setismodelFilterObj(true);
            setFilterObject(data.data.data);
        } else {
            console.log("something wait wrong");
        }
    };
    const oninactive = async (id, tableName) => {
        swal.fire({
            title: "Are You Sure InActive API?",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await activeandinactive({ tableName: 'registered_api_list', colName: 'STATUS', value: '0', matchId: 'SRNO' }, id)
                const data2 = await DeleteTable({ tableName: tableName })
                if (data.success === true && data2.success == true) {
                    toast.success('Success Inactive API')
                    fetchData()
                } else {
                    toast.error('something went wrong')
                }
            }
        })
    }
    return (
        <>
            <Modal
                title="Scope Object"
                visible={ismodelFilterObj}
                footer=""
                onCancel={() => {
                    setismodelFilterObj(false);
                    setFilterObject({});
                }}
            >
                <pre>{JSON.stringify(FilterObject || "{}", null, 2)}</pre>
            </Modal>
            <div className="main-content">
                <section className="section">
                    <div className="card tw-min-h-screen">
                        <div className="card-header">
                            <h4>API console</h4>
                            <div className="card-header-action tw-text-indigo-500 tw-cursor-pointer tw-hover:tw-text-indigo-700 tw-font-semibold">
                                <button className='!tw-border-none tw-bg-transparent hover:tw-bg-[#f1f1f1] tw-transition-all' onClick={() => handleClientClick({ name: "1dd" })}>

                                    <i class="fa fa-circle-plus"></i> &nbsp;Add Client
                                </button>
                            </div>
                        </div>
                        <div className='card-body'>
                            {isApidataLoading ? <>
                                <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                                    <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                                </div>
                            </> : <div>
                                {clientList.length > 0 ? clientList.map((client, index) => (
                                    <>
                                        <div className="tw-flex tw-items-center border tw-p-3 tw-gap-4 tw-cursor-pointer hover:tw-bg-[#f1f1f1]">
                                            <div className="border-right tw-px-2">
                                                <h4 className='tw-mb-0 !tw-font-semibold'>{index + 1}</h4>
                                            </div>
                                            <div>
                                                <h5 className='tw-mb-0 !tw-font-semibold '><span className='mr-2'>{client.API_NAME}</span><BootstrapTooltip title='Scope Info' placement='top'><span onClick={() => onFilterIdWiseNewFilterGenerate(JSON.parse(client?.SCOPE || '{}'))}><i className='fas fa-info-circle info_circle '></i></span></BootstrapTooltip></h5>
                                                <p className='tw-mb-0'>Description : {client?.API_DESCRIPTION || "N/A"}</p>
                                                <p className='tw-mb-0'>Created by : {(client?.FIRST_NAME || "") + ' ' + (client?.LAST_NAME || "")} &nbsp;|&nbsp; Created at : {moment(client.CREATED_AT).format('DD-MM-YYYY')}</p>
                                            </div>

                                            <div className='tw-ml-auto' >
                                                <span><BootstrapTooltip title='History' placement='top'><span> <a href={`/api/api-history/${client?.UNIQUE_ID}`} target='_blank' class="" id="device_btn_info"><i className='fas fa-external-link mr-3' /></a></span></BootstrapTooltip></span>
                                                <span onClick={() => oninactive(client.SRNO, JSON.parse(client?.API_TABLE_NAME || '{}').HISTORY_TABLE)}><i class="fa-solid fa-trash"></i></span>

                                            </div>
                                        </div>
                                    </>
                                )) : <>
                                    <div className='tw-text-center'>
                                        No Registered Clients Found.
                                    </div>
                                </>}
                            </div>}

                        </div>

                    </div>
                    {selectedClient && <ClientDetails client={selectedClient} onClose={handleCloseClientDetails} fetchData={fetchData} />}
                </section>
            </div>
        </>
    )
}

export default ApiConsole