import React, { useState, useEffect, Suspense } from 'react';
import './SlidingTabs.css';

const Tab = ({ id, title, active, onTabClick }) => (
    <button
        onClick={() => onTabClick(id)}
        className={`${active ? "tw-bg-[#e0e7ff]" : "hover:tw-bg-gray-200/60"
            } tw-w-full tw-relative tw-rounded-sm tw-px-3 tw-py-2 tw-text-black tw-transition-all tw-duration-300 tw-ease-in`}
        style={{
            WebkitTapHighlightColor: "transparent",
            border:'none'
        }}
    >

        {active && <span
            // className="tw-absolute tw-inset-0 tw-z-10 tw-bg-white tw-mix-blend-difference"
            className='sl-underline'
            style={{ borderRadius: "4px" }}
            

        />}
        {title}

    </button>
);

const SlidingTabs = ({ children, activeTabId, onTabChange, TabButton }) => {
    const [activeTab, setActiveTab] = useState(activeTabId || children[0].props.id);

    useEffect(() => {
        onTabChange(activeTab);
    }, [activeTab]);

    const TabComponent = TabButton || Tab;

    return (
        <>
            <div className="st-container">
                <div className="st-tabs tw-border-b tw-border-gray-200">
                    {children.map((child) => (
                        <TabComponent
                            key={child.props.id}
                            id={child.props.id}
                            title={child.props.title}
                            active={child.props.id === activeTab}
                            onTabClick={setActiveTab}
                        />
                    ))}
                </div>
                <div className="st-tab-content">
                    <Suspense fallback={<div>Loading</div>}>
                        {children.map((child) => {
                            if (child.props.id === activeTab) {
                                return <div key={child.props.id}>{child}</div>;
                            }
                            return null;
                        })}
                    </Suspense>
                </div>
            </div>
        </>
    );
};





export default SlidingTabs