import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import swal from 'sweetalert2';
import Pagination from '../../../../Commoncomponet/Pagination';
import { getActivityLogs } from '../../../../Commoncomponet/Utils';
import './ActivityLog.css'
import {Modal} from 'antd'
window.Swal = swal;
export default function ActivityLogs(props) {

  const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
    const [BufferData,setBufferData]=useState([])
	const [loader, setLoader] = useState(true)
	const [IsNewJsonData,setIsNewJsonData]=useState(false)
	const [NewJsonInfo,setNewJsonInfo]=useState({})
	const [IsOldJsonData,setIsOldJsonData]=useState(false)
	const [OldJsonInfo,setOldJsonInfo]=useState({})
	const [loading,setloading]=useState(false)

const ongetGSMBufferData=async()=>{
    setLoader(true)
    const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
    const data=await getActivityLogs({ start: firstPageIndex, end: PageSize,tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.activitylog })
    if(data.success===true){
		const groupByDate = data.data.data?.reduce((acc, log) => {
			const date =moment.utc(log.created_at).format('DD-MM-YYYY');
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(log);
			return acc;
		}, {});
		const groupedLogs = Object.keys(groupByDate).map(date => {
			return {
				date,
				logs: groupByDate[date]
			}
		});
        setBufferData(groupedLogs)
		setnumberofPage(parseInt(data.data.totalCount))
    setLoader(false)
    }else{
console.log('something want wrong')
        setLoader(false)
    }
}

useEffect(() => {
    ongetGSMBufferData()
}, []);

useEffect(() => {
    ongetGSMBufferData()
}, [currentPage, PageSize])

const onHandalNewJson=(data,info)=>{
	setIsNewJsonData(true)
	setNewJsonInfo(data)
	setOldJsonInfo(info)

}

const onHandalOldJson=(data)=>{
	setIsOldJsonData(true)
	setOldJsonInfo(data)
}
  return (
    <>
	<Modal title="Activity Json" maskClosable={false} visible={IsNewJsonData} footer='' onCancel={() => setIsNewJsonData(false)} width={1500}>
	<table id="table_id" className="table border text-center table-borderless table-striped font-14">
													<thead>
														<tr >
															<th className="text-center" >Old Json</th>
															<th className="text-center">New Changed json</th>
														</tr>
													</thead>
													<tbody>
													<tr>
															<th className="text-center" ><pre> {JSON.stringify(OldJsonInfo, null, 2)}</pre></th>
															<th className="text-center"><pre> {JSON.stringify(NewJsonInfo, null, 2)}</pre></th>
														</tr>
													</tbody>
													</table>
            </Modal>
			<Modal title="Old Json" maskClosable={false} visible={IsOldJsonData} footer='' onCancel={() => setIsOldJsonData(false)} width={1500}>
              <pre> {JSON.stringify(OldJsonInfo, null, 2)}</pre>
            </Modal>
      <section className="card-diff-section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header ">
										<h4  >Activity Logs</h4>
										<div className="card-header-action">
                                        
										</div>
									</div>
									<div className="card-body ">
										{/* <div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
												</div>
												<table id="table_id" className="table border text-center table-borderless table-striped font-10">
													<thead>
														<tr >
															<th className="text-center" >SRNO</th>
															<th>Unique Id</th>
															<th>Created At</th>
															<th>Created by</th>
															<th>Activity Json</th>
														</tr>
													</thead>
													<tbody>
														{BufferData.length > 0 && BufferData.map((item, id) => (
															<tr className="trheigth" >
																<td>{item?.sr_no}</td>
																<td>{item?.unique_id}</td>
															<td>{moment.utc(item?.created_at).format("DD-MM-YYYY hh:mm:ss A")}</td>
															<td>{item?.created_by}</td>
															<td>{<button className='btn btn-info btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onHandalNewJson(JSON.parse(item?.new_changed_json),JSON.parse(item?.old_json))}><i className="fa-solid fa-info"></i></button> }</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div> */}

<div className="timeline-card">
                    {loading ? (
                        <p className="loading-message">Loading...</p>
                    ) : (
                        <>
                            {BufferData.length > 0 && BufferData.map((logP, index) => (
                                <div className="timeline">
                                    <div className="date-section" key={index}>
                                        <div className="date">{logP.date}</div>
                                    </div>
                                    {logP?.logs.map((log, index) => (
                                        <div className="timeline-item" key={index}>
                                            <div className="timeline-icon">{logP?.logs.length - index}</div>
                                            <div className="timeline-data">
                                                <div className="activity-log-avatar">
                                                    <span>{log?.created_by[0]}</span>
                                                </div>
                                                <div className="timeline-content">
                                                    <div className="activity-log-activity">
                                                        <p className="activity-log-activity-creator">{log?.created_by} has </p>
                                                        <p className="activity-log-activity-type">{log?.activity_type}</p>
                                                        <p className="activity-log-activity-static">Updated <b>({Object.keys(JSON.parse(log?.new_changed_json||'{}')).join(', ')})</b></p>
                                                        <p className="activity-log-activity-column">{log?.updated_columns && log?.updated_columns?.length > 0 ? log?.updated_columns?.join(", ") : ""}</p>
                                                    </div>
                                                    <div className="activity-log-changes">
													<div key={index}>
                                                                        <pre style={{width:'500px'}}>{JSON.stringify(JSON.parse(log?.old_json||'{}'), null, 2)}</pre>
                                                                        <span className="arrow">→</span>
                                                                        <pre style={{width:'500px'}}>{JSON.stringify(JSON.parse(log?.new_changed_json||'{}'), null, 2)}</pre>
                                                                    </div>
                                                    </div>

                                                    <p className="activity-log-date">at {moment.utc(log.created_at).format('hh:mm:ss A')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </>
                    )}
					</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
    </>
  )
}
