import { forwardRef} from "react"
import ReactApexChart from "react-apexcharts"

function ChartClone({ type, labels, series},ref) {
    const options = {
        chart: {
            height: 1000,
            width: '80%',
            type: type,
            border: '1px solid #000'
        },
		legend: {
			position: 'right',
			height:1000
		},
        series: series,
        labels: labels,
		dataLabels: {
			enabled: true,
			textAnchor: "start",
			formatter: function (val, opt) {
				return series[opt.seriesIndex];
			},
			offsetX: 0,
		},
        responsive: [{
			breakpoint: 1500,
			options: {
				chart: {
					width: "100%",
                    height: '1200px'
				},
				legend: {
					position: 'right',
					height: '1000px',
					width: "100%",
				}
			}
		}, {
			breakpoint: 1300,
			options: {
				chart: {
					// width: "100%",
					height: 'auto'
				},
				legend: {
					position: 'bottom',
					width: "100%",
					height: 'auto'
				}
			}
		}, {
			breakpoint: 1100,
			options: {
				chart: {
					// width: "100%",
					height: 'auto'
				},
				legend: {
					position: 'bottom',
					width: "100%",
					height: 'auto'
				}
			}
		}, {
			breakpoint: 700,
			options: {
				chart: {
					// width: "100%",
					height: 'auto'
				},
				legend: {
					position: 'bottom',
					width: "100%",
					height: 'auto'
				}
			}
		}, {
			breakpoint: 500,
			options: {
				chart: {
					width: "100%",
					height: 'auto'
				},
				legend: {
					show: false
				}
			}
		}]
    };

    return (
            <div ref={ref} className="tw-absolute tw-w-full -tw-top-[1000%] -tw-left-0 -tw-z-[99999]" >
                <ReactApexChart options={options} series={options.series} type={options.chart.type} height={1000}/>
            </div>
    )
}

export default forwardRef(ChartClone);