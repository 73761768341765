import { DatePicker, Modal, Space, Spin } from 'antd';
import moment from 'moment';
import React, { useRef } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Pagination from '../../../../Commoncomponet/Pagination';
import { getConfigretionCMD_TYPEWisePendding_Count_buffer, getConfigretionCMD_TYPEWisePendding_Data_buffer, getGsmSmartMeterTableData, getGsmSmartMeterWiseColumnName, getScriptCodeIdWiseConfigretionData, getStartAndEndDateByTable, onModemConfigByGSMConsumer, reportCreacteInTableName } from '../../../../Commoncomponet/Utils';
import useInterval from "use-interval";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import swal from 'sweetalert2';
import {BootstrapTooltip, exportCSVFile, exportExcelDownload, socket} from '../../../../Commoncomponet/common'
import clockPending from '../../../../assets/img/icons8-clock.gif'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
window.Swal = swal;

export default function Dailyload(props) {
    const [FlagMsg,setFlagMsg]=useState(false)
  const [modelTitle,setmodelTitle]=useState('')
  const [modal,setModal]=useState(false)
    const [MqttMsg,setMqttMsg]=useState('')
    const [time, setTime] = useState(60);
	const [run, setRun] = useState(false);
  const [modalMsg,setmodalMsg]=useState(false)
  const [Read1Msg,setRead1Msg]=useState(false)
  const [Read2Msg,setRead2Msg]=useState(false)
  const [cmd,setcmd]=useState({})
	const [dates,setDates]=useState({start:'',end:''})
    const [Index,setIndex]=useState()
    const [PageSize, setPageSize] = useState(50);
    const [numberofPage, setnumberofPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [heartData, setHeartData] = useState([])
    const [loader, setLoader] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [AllData, setAllData] = useState({})
    const [rowData, setRowData] = useState({})
    const [ErrorData, setErrorData] = useState({})
    const [DupData, setDupData] = useState({})
    const [isModalError, setIsModalError] = useState(false)
    const [isModalDup, setIsModalDup] = useState(false)
    const [isModalrow, setIsModalRow] = useState(false)
    const [ColumnName,setColumnName]=useState({})
    const [pendingBuffer,setpendingBuffer]=useState('')
    var [CommandJson,setCommandJson]=useState({})
    var [CommandmsgJson,setCommandmsgJson]=useState({})
    const [CommandStatus,setCommandStatus]=useState('')
    const [sortField,setsortField]=useState('scr_id_sn')
	const [sortDirection,setsortDirection]=useState('desc')
	const ongetConfigretionCMD_TYPEWisePendding_Count_buffer=async()=>{
		const data=await getConfigretionCMD_TYPEWisePendding_Count_buffer({CONSUMER_ID:props.GSMdata.UNIQUE_ID,CMD_TYPE:'DAILY_LOAD',tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer})
		if(data.success===true){
			setpendingBuffer(data.data.data)
		}else{
			console.log('something want wrong')
		}
	}
	const mytime = useRef(null)

    const ongetScriptCodeIdWiseConfigretionData=async()=>{
		const data=await getScriptCodeIdWiseConfigretionData(props?.GSMdata?.SCRIPTDECODE_ID,{tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer,type:'DAILY_LOAD'})
		if(data.success===true){
			const CMD=JSON.parse(data?.data?.data[0]?.METER_QUERY_CMD||'{}')
			if(CMD?.DAILY_LOAD?.READ_METHOD===3){
				const obj={MMD_ID_CMD:124,SCR_ID_CMD_TYPE:'DAILY_LOAD',"MMD_ID_OBIS_CODE": CMD?.DAILY_LOAD?.OBIS,"MMD_ID_OBIS_OBJTYPE": CMD?.DAILY_LOAD?.OBJ_TYPE,UNIQUE_ID:props?.GSMdata?.UNIQUE_ID,MMD_ID_IMEI:props?.GSMdata?.IMEI,SCRIPTDECODE_ID:props?.GSMdata?.SCRIPTDECODE_ID,MMD_ID_MSG:new Date().getTime()+1,MMD_ID_OBIS_READ_METHOD: CMD?.DAILY_LOAD?.READ_METHOD,GSM_BUFFER_TABLE:props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer}
                
				  setFlagMsg(false)
				  setmodelTitle(obj.SCR_ID_CMD_TYPE)
				  alert(JSON.stringify(obj))
                  setCommandJson(obj)

				  swal.fire({
					  title: "Are you sure?",
					  text: "This will initiate downlink",
					  icon: "warning",
					  showCancelButton: true,
				  }).then(async (willDelete) => {
					  if (willDelete.isConfirmed) {
				  setModal(true)
				  
			  
				  const data=await onModemConfigByGSMConsumer({config_info:JSON.stringify(obj)})
				  if(data.success===true){
                    mytime.current= setTimeout(() => {
                        if(Object.keys(CommandStatus).length===0)
                                             {
                                                 setCommandStatus({SCR_STATUS_CMD_ADD:'Time Out'})
                                             }
                                             },5000)
				  }else{
					 
				  }
			  }
			  })
			}else if(CMD?.DAILY_LOAD?.READ_METHOD===2){
				setcmd(CMD)
setRead1Msg(true)
			}else if(CMD?.DAILY_LOAD?.READ_METHOD===1){
				setcmd(CMD)
				setRead2Msg(true)
			}
			
		}
	}
	useInterval(
	  () => {
		  if (time <= 1) {
			  setRun(false);
			  setModal(false)
			  
		  }
		  setTime((preState) => preState - 1);
	  },
	  time && run ? 1000 : null
	);
	useEffect(()=>{
		socket.on('mqttbufferresponse',(massges)=>{
			console.log(massges);
			setCommandStatus(massges)
			
		})
		socket.on('mqttcommandresponse',(massges)=>{
			console.log(massges,CommandJson,massges.MSGID==CommandJson.MSGID,massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID,massges.UNIQUE_ID==CommandJson.UNIQUE_ID,massges.CMD_TYPE==CommandJson.CMD_TYPE,massges.DRXTYPE,!massges.STATUS);
			setCommandmsgJson(massges)
		})
	},[])
	const BfferError=(msg)=>{
        console.log(msg.MSGID,CommandJson.MSGID);
        if(msg.MMD_ID_MSG==CommandJson.MMD_ID_MSG){
        swal.fire('', msg?.SCR_STATUS_CMD_ADD, 'error');
        setCommandStatus('')
        setFlagMsg(true)
        setRun(false);
        setTime(0)
        setModal(false)
    }
    }
    useEffect(()=>{
        if(CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'&&Object.keys(CommandStatus).length>0){
            BfferError(CommandStatus)
            clearTimeout(mytime.current);
				mytime.current = null
        }else if(CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'){
            setTime(60)
        setRun(true);
        clearTimeout(mytime.current);
				mytime.current = null
        }
    },[CommandStatus])
	const getPopdata=(massges)=>{
		console.log(massges,CommandJson);
	if(massges.MMD_ID_MSG==CommandJson.MMD_ID_MSG&&massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID&&massges.UNIQUE_ID==CommandJson.UNIQUE_ID&&massges.SCR_ID_CMD_TYPE==CommandJson.SCR_ID_CMD_TYPE&&massges.MMD_ID_DRXTYPE&&!massges.STATUS){
			setTime(0)
			setRun(false);
		   setModal(false)
		   if(FlagMsg===false){
		   setmodalMsg(true)
		   setMqttMsg(JSON.stringify(massges))
			}
			
		}
	}
	
	useEffect(()=>{
			if(Object.keys(CommandmsgJson).length>0){
			getPopdata(CommandmsgJson)
			}
	},[CommandmsgJson])
	function zeroPad(num) {
	  var str = String(num);
	  if (str.length < 2) {
		  return "0" + str;
	  }
	
	  return str;
	}
	
	const CloseModel=()=>{
	  setFlagMsg(true)
	  setRun(false);
	  setTime(0)
	  setModal(false)
      setCommandmsgJson({})
      setCommandStatus('')
	}
    const onSubmitCommand=()=>{
        var obj
        if(cmd?.DAILY_LOAD?.READ_METHOD===1){
setRead2Msg(false)
            obj=  {MMD_ID_CMD:124,SCR_ID_CMD_TYPE:'DAILY_LOAD',"MMD_ID_OBIS_CODE": cmd?.DAILY_LOAD?.OBIS,"MMD_ID_OBIS_OBJTYPE": cmd?.DAILY_LOAD?.OBJ_TYPE,UNIQUE_ID:props?.GSMdata?.UNIQUE_ID,MMD_ID_IMEI:props?.GSMdata?.IMEI,SCRIPTDECODE_ID:props?.GSMdata?.SCRIPTDECODE_ID,MMD_ID_MSG:new Date().getTime()+1,MMD_ID_START_INDEX:parseInt(Index),MMD_CNT_INDEX:1,MMD_ID_OBIS_READ_METHOD: cmd?.DAILY_LOAD?.READ_METHOD,GSM_BUFFER_TABLE:props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer}
        }else{
setRead1Msg(false)
            obj=  {MMD_ID_CMD:124,SCR_ID_CMD_TYPE:'DAILY_LOAD',"MMD_ID_OBIS_CODE": cmd?.DAILY_LOAD?.OBIS,"MMD_ID_OBIS_OBJTYPE": cmd?.DAILY_LOAD?.OBJ_TYPE,UNIQUE_ID:props?.GSMdata?.UNIQUE_ID,MMD_ID_IMEI:props?.GSMdata?.IMEI,SCRIPTDECODE_ID:props?.GSMdata?.SCRIPTDECODE_ID,MMD_ID_MSG:new Date().getTime()+1,MMD_TS_START:parseInt(moment(dates?.start,'DD-MM-YYYY HH:mm:ss').format("X")),MMD_TS_END:parseInt(moment(dates?.end,'DD-MM-YYYY HH:mm:ss').format("X")),MMD_ID_OBIS_READ_METHOD: cmd?.DAILY_LOAD?.READ_METHOD,GSM_BUFFER_TABLE:props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer}
        }
				  setFlagMsg(false)
				  setmodelTitle(obj.SCR_ID_CMD_TYPE)
				  alert(JSON.stringify(obj))
                  setCommandJson(obj)
				  swal.fire({
					  title: "Are you sure?",
					  text: "This will initiate downlink",
					  icon: "warning",
					  showCancelButton: true,
				  }).then(async (willDelete) => {
					  if (willDelete.isConfirmed) {
				  setModal(true)
				  
			  
				  const data=await onModemConfigByGSMConsumer({config_info:JSON.stringify(obj)})
				  if(data.success===true){
                    mytime.current= setTimeout(() => {
                        if(Object.keys(CommandStatus).length===0)
                                             {
                                                 setCommandStatus({SCR_STATUS_CMD_ADD:'Time Out'})
                                             }
                                             },5000)
				  }else{
					 
				  }
                }
                })
    }

	const onChange = (date, dateString) => {
		setDates({...dates,start:dateString})
		  };
		  const onChanges = (date, dateString) => {
		setDates({...dates,end:dateString})
		  };
    const ongetScriptCodeIdWiseColumnName=async()=>{
        const data=await getGsmSmartMeterWiseColumnName(props?.GSMdata?.METER_TYPE)
        if(data.success===true){
            if(data?.data?.data?.length>0){
            setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
            }
        }else{
console.log('something want wrong');
        }
    }
    const [StatusCode,setStatusCode]=useState('')
    const ongetHeardbeatData = async () => {
       
            setLoader(true)
        
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        const data = await getGsmSmartMeterTableData({ start: firstPageIndex, end: PageSize }, { tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.dailydata,colName:sortField,sortDirection:sortDirection  })
        if (data.success === true) {
            setHeartData(data.data.data)
            setnumberofPage(data.data.total)
            setLoader(false)
        } else {
            console.log('something want wrong')
            setLoader(false)
            setStatusCode(data?.err?.response?.status)
        }
    }

    
    useEffect(() => {
        ongetScriptCodeIdWiseColumnName()
        ongetHeardbeatData()
        // ongetConfigretionCMD_TYPEWisePendding_Count_buffer()
    }, []);

    useEffect(() => {
        ongetHeardbeatData()
    }, [currentPage, PageSize])

    const onAllShowData = (item) => {
        setIsModalVisible(true)
        setAllData(item)
    }
    const onRowDate = (row) => {
        setRowData(row)
        setIsModalRow(true)
    }
    const onDuplicate = (dupli) => {
        setDupData(dupli)
        setIsModalDup(true)
    }
    const onError = (err) => {
        setErrorData(err)
        setIsModalError(true)
    }
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    var getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            // arr.push(new Date(dt).getFullYear()+'-'+'0'+(new Date(dt).getMonth() + 1)+'-'+'0'+new Date(dt).getDate());
            arr.push(
                new Date(dt).getFullYear() +
                "-" +
                `${new Date(dt).getMonth() + 1 < 10 ? "0" : ""}` +
                (new Date(dt).getMonth() + 1) +
                "-" +
                `${new Date(dt).getDate() < 10 ? "0" : ""}` +
                new Date(dt).getDate()
            );
        }
        return arr;
    };
	const csvLink = useRef();
    const [CsvData,setCsvData]=useState([])
    const [report, setReport] = useState({ value: '', name: '', start_date: '', end_date: '' })
    const [reportType, setReportType] = useState('')
    const [ismodel, setModel] = useState(false)
    const [ReportLoader,setReportLoader]=useState(false)
    const ReportType=[{label:'View Table Info',value:'View_Table_Info'},{label:'All Table Info',value:'All_Table_Info'},{label:'Full Table',value:'Full_Table'},{ label: 'Daily Consumption Report', value: 'Daily-Consumption-Report' }]
    const [ExpireDate,setExpireDate]=useState({})
	const ongetStartAndEndDateByTable = async (tableName, col) => {
        var columnTable=props?.GSMdata?.METER_TYPE==='GSTMID1706880187688'?'meter_endts':'clock$0_0_1_0_0_255'
		console.log("Wanted to check if this is calling or not")
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.dailydata, col: columnTable })
		if (data.success === true) {

			console.log("Expiry Dates == ", data.data)
			setExpireDate(data.data)
			setModel(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}
    const onHandalReport = (e) => {
      setReport({ ...report, value: e.target.value, name: 'All GSM Consumer Details' })
    }

    const columnWiseData = (value, column, header, ColumnNames, CF) => {
        const col = ColumnNames[header]?.filter((item) => (item?.Column == column))[0] || {}

        if (col?.Float!=null&&col?.Float!=undefined) {
            if (col?.Division == true) {
                if (col?.CF == true && CF) {
                    return (value * CF / col?.DivisionNumber).toFixed(col?.Float)
                } else {
                    return (value / col?.DivisionNumber).toFixed(col?.Float)
                }
            } else {
                if (col?.CF == true && CF) {
                    return (value * CF).toFixed(col?.Float)
                } else {
                    return (value).toFixed(col?.Float)
                }
            }
        } else {
            if (col?.CF == true && CF) {
                if (col?.Division == true) {
                    return (value * CF) / col?.DivisionNumber
                } else {
                    return value * CF
                }
            } else {
                if (col?.Division == true) {
                    if (col?.CF == true && CF) {
                        return (value * CF) / col?.DivisionNumber
                    } else {
                        return value / col?.DivisionNumber
                    }
                } else {
                    return value
                }
            }
        }
    }
      const ongetGenretedAllGSMReport=async(Iscsv)=>{
          if(reportType!=''&&report.value!==''){

            if(report?.value!='Full_Table'){
				if(report?.start_date&&report?.end_date){
					const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x>y,x,y);
					if(x>y){
					return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
					}
				}else{
					return	toast.warn('Please Select "Start Date" And "End Date"')
				}
			}
          setReportLoader(true)
        var columnTable=''
        if (props?.GSMdata?.SCRIPTDECODE_ID=='MODUID1671705090942_METUID1665810585135'||props?.GSMdata?.SCRIPTDECODE_ID=='MODUID1671705090942_METUID1676094508509'||props?.GSMdata?.SCRIPTDECODE_ID=='MODUID1671705090942_METUID1676094601754'||props?.GSMdata?.SCRIPTDECODE_ID=='MODUID1671705090942_METUID1676094645995') {
            columnTable='METER_STARTTS'
        }else{
            columnTable='METER_ENDTS'
        }
          const data=await reportCreacteInTableName({tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.dailydata,col:props?.GSMdata?.METER_TYPE==='GSTMID1706880187688'?'meter_endts':'clock$0_0_1_0_0_255', start_date: report?.value=='Daily-Consumption-Report'?moment(report.start_date, "DD-MM-YYYY").add(-1,'days').format("YYYY-MM-DD"):moment(report.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"), end_date: report?.value=='Daily-Consumption-Report'?moment(report.end_date, "DD-MM-YYYY").add(1,'days').format("YYYY-MM-DD"):moment(report.end_date, "DD-MM-YYYY").format("YYYY-MM-DD"),column_name:props?.GSMdata?.METER_TYPE==='GSTMID1706880187688'?'vb_diff_daily_vbd_d':'(Register$7_0_13_2_0_255 - LAG(Register$7_0_13_2_0_255) OVER (ORDER BY Clock$0_0_1_0_0_255))',IsCondition:report?.value=='Full_Table'?false:report?.value=='Daily-Consumption-Report'?'Column':true})
          if(data.success==true){
        var arrayofData=[]
        var headers={}
         
        if(data.data.data.length==0){
      setReportLoader(false)
      toast.warn('Data Not Found')
        }
        var TypeReport=report?.value
        if (TypeReport == 'Daily-Consumption-Report') {
        const date_bunch = getDaysArray(moment(report.start_date, "DD-MM-YYYY").format('YYYY-MM-DD'), moment(report.end_date, "DD-MM-YYYY").format('YYYY-MM-DD'))
                    const sapInfo = data?.data?.data || []
                  var  headers = {
                        "address_compony_name": "address_compony_name",
                        "INSTALLATION_NUMBER": "INSTALLATION_NUMBER",
                        "CATEGORY2_NAME":"CATEGORY2_NAME"
                    }
                  
                    date_bunch.map((item)=>{headers[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]=moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')})
                    var filter_date = [...date_bunch]

                        const findFloat = (keys) => {
                            return ColumnName.Meter_Data.dailydata.filter(into => into.Column == keys).length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == keys)[0].Float : ''
                        }
                        const result_obj={
                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                            "INSTALLATION_NUMBER": props?.GSMdata?.INSTALLATION_NUMBER,
                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME
                        }
                        filter_date.pop()
                        sapInfo.length > 0 &&
                            filter_date.map((item) => {
                                const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.date).add(-1, "days").format('YYYY-MM-DD') == item))
                                if(daily_data.length>0){
                                result_obj[moment(item, 'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')] = columnWiseData(Number(daily_data[0].Consumptions),props?.GSMdata.METER_TYPE == 'GSTMID1706880187688'?'vb_diff_daily_vbd_d':'Register$7_0_13_2_0_255','dailydata',ColumnName?.Meter_Data,1)
                                }else{
                                    result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]= 'Data Not Available'
                                }
                                // total_count[moment(item, 'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')] = total_count[moment(item, 'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')] + new Number(result_obj[moment(item, 'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')] || '0')
                            })

                            if (sapInfo.length > 0) {
                                arrayofData.push(result_obj)
                            } else {
                                filter_date.map((date) => {
                                        result_obj[moment(date,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]= 'Data Not Available'
                                })
                            }
                            if(reportType==='csv'){
                                exportCSVFile(headers,arrayofData,`Daily-Consumption-Report_`+'('+report.start_date+')'+'_'+'('+report.end_date+')')
                      setReportLoader(false)
                  
                               }else{
                                console.log(arrayofData);
                                exportExcelDownload(arrayofData,`Daily-Consumption-Report_`+'('+report.start_date+')'+'_'+'('+report.end_date+')')
                      setReportLoader(false)
                                
                            }
        }else{

          data.data.data.map((item,id)=>{
                arrayofData[id]={}
          
            ColumnName?.Meter_Data?.dailydata?.length>0&&ColumnName?.Meter_Data?.dailydata?.map((col)=>{
              if(TypeReport=='View_Table_Info'){
              if(col?.Display=="Yes"){
                if(id==0){headers[col.Header]=col.Header}
                if(col?.Type=='datetime'||col?.Column=='MAX_CONC_CONS_DATE'||col?.Column=='MAX_UNCONV_CONS_DATE')
                {
                    if(col.Column=="METER_ENDTS"||col.Column=="METER_STARTTS"||col.Column=="METER_BCD_TS"||col.Column=="METER_TS"){
                        if(col.Column=="METER_BCD_TS"||col.Column=="METER_TS"){
                        arrayofData[id]["Date"]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY"):''
                        arrayofData[id]["Time"]=item[col.Column]?moment.utc(item[col.Column]).format("HH:mm:ss"):''
                        }else{
                            if(col.Column=="METER_ENDTS"){
                                arrayofData[id]["Date"]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY"):''
                                arrayofData[id]["Time"]=item[col.Column]?moment.utc(item[col.Column]).format("HH:mm:ss"):''
                            }else{
                                arrayofData[id]["Date"]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY"):''
                                arrayofData[id]["Time"]=item[col.Column]?moment.utc(item[col.Column]).format("HH:mm:ss"):''
                            }
                        }
                    }else{
                  arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
                    }
                }else{
                    if(col?.Float!=null&&col?.Float!=undefined){
                        if(col?.DivisionNumber){
                            arrayofData[id][col.Header]=(Number(item[col.Column])/col?.DivisionNumber).toFixed(col?.Float)
                            }else{
                            arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
                            }
                      }else{
                        if(col?.DivisionNumber){
                      arrayofData[id][col.Header]=Number(item[col.Column])/col?.DivisionNumber
                        }else{
                      arrayofData[id][col.Header]=item[col.Column]
    
                        }
                      }
                }
              }
            } else if(report?.value=='All_Table_Info'||report?.value=='Full_Table'){
              if(id==0){headers[col.Header]=col.Header}
              if(col?.Type=='datetime'||col?.Column=='MAX_CONC_CONS_DATE'||col?.Column=='MAX_UNCONV_CONS_DATE')
                {
                    if(col.Column=="METER_ENDTS"||col.Column=="METER_STARTTS"||col.Column=="METER_BCD_TS"||col.Column=="METER_TS"){
                        if(col.Column=="METER_BCD_TS"||col.Column=="METER_TS"){
                        arrayofData[id]["Date"]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY"):''
                        arrayofData[id]["Time"]=item[col.Column]?moment.utc(item[col.Column]).format("HH:mm:ss"):''
                        }else{
                            if(col.Column=="METER_ENDTS"){
                                arrayofData[id]["Date"]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY"):''
                                arrayofData[id]["Time"]=item[col.Column]?moment.utc(item[col.Column]).format("HH:mm:ss"):''
                            }else{
                                arrayofData[id]["Date"]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY"):''
                                arrayofData[id]["Time"]=item[col.Column]?moment.utc(item[col.Column]).format("HH:mm:ss"):''
                            }
                        }
                    }else{
                  arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
                    }
                }else{
                    if(col?.Float!=null&&col?.Float!=undefined){
                        if(col?.DivisionNumber){
                            arrayofData[id][col.Header]=(Number(item[col.Column])/col?.DivisionNumber).toFixed(col?.Float)
                            }else{
                            arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
                            }
                      }else{
                        if(col?.DivisionNumber){
                      arrayofData[id][col.Header]=Number(item[col.Column])/col?.DivisionNumber
                        }else{
                      arrayofData[id][col.Header]=item[col.Column]
    
                        }
                      }
                }
            }
            })
            if(id==(data.data.data.length-1)){
              if(reportType==='csv'){
                if(report?.value!='Full_Table'){
                exportCSVFile(headers,arrayofData,`dailydata_`+'('+report.start_date+')'+'_'+'('+report.end_date+')')
                }else{
                exportCSVFile(headers,arrayofData,`dailydata`)

                }
      setReportLoader(false)
  
               }else{
                console.log(arrayofData);
                if(report?.value!='Full_Table'){
                exportExcelDownload(arrayofData,`dailydata_`+'('+report.start_date+')'+'_'+'('+report.end_date+')')
                }else{
                exportExcelDownload(arrayofData,`dailydata`)

                }
      setReportLoader(false)
  
               }
              }
        })
    }
        
              
          }else{
              console.log('something want wrong')
      setReportLoader(false)

          }
    }
      }
    
    const [BufferPageSize, setBufferPageSize] = useState(50)
    const [BuffernumberofPage, setBuffernumberofPage] = useState(0)
    const [BuffercurrentPage, setBufferCurrentPage] = useState(1);
      const [BufferPenddingData,setBufferPenddingData]=useState([])
      const [modalBufferDetails,setmodalBufferDetails]=useState(false)
  
    const onBufferPenddingData=async(col)=>{
      setmodelTitle(col)
    
      setmodalBufferDetails(true)
      const firstPageIndex = (BuffercurrentPage - 1) * BuffercurrentPage;
    
      const data=await getConfigretionCMD_TYPEWisePendding_Data_buffer({CONSUMER_ID:props.GSMdata.UNIQUE_ID,CMD_TYPE:col,start:firstPageIndex,end:BufferPageSize,tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer})
      if(data.success===true){
    setBuffernumberofPage(data.data.Total)
    setBufferPenddingData(data.data.data)
      }else{
      console.log('something want wrong')
    
      }
    }
    
    useEffect(() => {
      if(BufferPenddingData.length>0){
        onBufferPenddingData()
      }
     }, [BuffercurrentPage, BufferPageSize])

     const onSort = (field) => {
        if (sortField === field) {
            setsortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setsortField(field);
            setsortDirection('asc');
        }
    };

	useEffect(()=>{
		ongetHeardbeatData()
	},[sortDirection,sortField])
    return (
        <>

<Modal title="Generate Reports" visible={ismodel} footer='' onCancel={()=>setModel(false)} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={onHandalReport}>
												<option value="">Report Type</option>
												{ReportType?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div>
										{report?.value=='Daily-Consumption-Report'?
                                        <><div className="form-group col-md-3" id="strdate">
                                        <label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
                                        <DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
                                            disabledDate={(current) => {
                                                return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m") <= current);
                                            }} />
                                    </div>
                                        <div className="form-group col-md-3" id="enddate" >
                                            <label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
                                            <DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
                                                disabledDate={(current) => {
                                                    return (moment(ExpireDate.start_date) >= current) || (moment(ExpireDate.end_date) <= current);
                                                }} />
                                        </div></>
                                        :report?.value!=''&&report?.value!='Full_Table'?<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
												}} />
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
                                                        return (moment(ExpireDate.start_date) >= current) || (moment(ExpireDate.end_date).add(1, 'days') <= current);
                                                    }} />
											</div></>:''}
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLS</option>
												<option value="csv">CSV</option>
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader==true?'disabled btn-progress':''}`} value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
										<Link className="btn btn-light text-dark" onClick={()=>setModel(false)}>Cancel</Link>
									</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
        <Modal title={modelTitle+'('+(BuffernumberofPage?BuffernumberofPage:0)+')'}  visible={modalBufferDetails} className="my-modal-class" footer='' maskClosable={false} onCancel={() => (setmodalBufferDetails(false))} width={1500}>

<div className="table-responsive mt-2">
								<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
									<div class="dataTables_length ml-2" id="table_id_length">
										<label>
											Show{" "}
											<select name="table_id_length " aria-controls="table_id" class="" onChange={(e) => (setBufferPageSize(e.target.value))} value={BufferPageSize}>
												<option value={10}>10</option>
												<option value={25}>25</option>
												<option value={50}>50</option>
												<option value={100}>100</option>
											</select>{" "}
											entries
										</label>
									</div>
									
									<table id="table_id" className="tableCustom table table-striped">
										<thead>
											<tr>
											<th className="text-center" >Unique No</th>
                                            { ColumnName?.GSM_BUFFER?.length>0&&ColumnName?.GSM_BUFFER?.map((col)=>(col?.Display==true?<th className=""><BootstrapTooltip title={col?.Column}><span>{col?.Header}</span></BootstrapTooltip></th>:''))}
											</tr>
										</thead>
										<tbody>
											{BufferPenddingData.length > 0 && BufferPenddingData.map((item, id) => (
												<tr className="trheigth" >
												 { ColumnName?.GSM_BUFFER?.length>0&&ColumnName?.GSM_BUFFER?.map((col)=>(col?.Display==false?'':col?.DataType=='DATETIME'?<td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>:col.Column=='SCR_STATUS_CMD'?<td>{item?.SCR_STATUS_CMD==1? <div class="badge badge-success badge-shadow">Success</div>:item?.SCR_CNT_CMD_RETRY<0&&item?.SCR_STATUS_CMD==0?<div class="badge badge-warning badge-shadow">Waiting For Downlink</div>:item?.SCR_STATUS_CMD==2?<div class="badge badge-info badge-shadow">Canceled By User</div>:item?.SCR_STATUS_CMD==-1?<div class="badge badge-danger badge-shadow">Timeout</div>:item?.SCR_CNT_CMD_RETRY>=0&&item?.SCR_STATUS_CMD==0?<div class="badge badge-primary text-white badge-shadow">Waiting For Uplink</div>:''}</td>:col.Column=='SCR_ID_CMD_ADDED_BY'?<td>{item?.SCR_ID_CMD_ADDED_BY===0?<div class="badge badge-warning badge-shadow">Script</div>:<div class="badge badge-success badge-shadow">User</div>}</td>:<td>{item[col.Column]}</td>))}
															
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
							<><div className='d-flex justify-content-between'>
								<div>Total Number Of Row {BuffernumberofPage||0}</div><Pagination
									className="pagination-bar"
									currentPage={BuffercurrentPage}
									totalCount={BuffernumberofPage || 0}
									pageSize={BufferPageSize}
									onPageChange={(page) => setBufferCurrentPage(page)}
								/></div></>
</Modal>
      <Modal  visible={modal} footer='' maskClosable={false} onCancel={() => (setModal(false))} width={500}>
    <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting {modelTitle} Command</h5>
    <h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!CommandStatus?.SCR_STATUS_CMD_ADD&&CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<i className='fas fa-times mr-2' style={{color:'red'}}></i>:<i className='fas fa-check mr-2' style={{color:'green'}}></i>}{!CommandStatus?.SCR_STATUS_CMD_ADD?'Command Pending':CommandStatus?.SCR_STATUS_CMD_ADD}</h5>
    <div class=""> 
   { CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'? <div class="text-center" style={{marginTop:"10px",fontSize: "20px"}}>
                     <label style={{fontWeight:"400"}}>Waiting For Respose</label>
                     <div id="meter_data_mqtt_timer_status" style={{fontSize: "18px"}}>{`${zeroPad(time)}`} seconds left</div>
                  </div>:''}
               </div>
               <div class="text-center" style={{marginTop:"20px"}}>
                  <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={CloseModel}/>
               </div>
            </Modal>
        <Modal  visible={modalMsg} footer='' maskClosable={false} onCancel={() => (setmodalMsg(false))} width={500}>
			{MqttMsg}
            </Modal>
			<Modal title="" visible={Read1Msg} footer="" onCancel={() => setRead1Msg(false)} width={1000}>
			<div className="d-flex">
			<Space direction="horizontal">
		<div className="d-flex flex-column">Start In
    <DatePicker onChange={onChange} showTime  format='DD-MM-YYYY HH:mm:ss' disabledDate={(current) => {
              return current.isAfter(moment())
            }} name='start'  /></div>
	<div className="d-flex flex-column" >End In
    <DatePicker onChange={onChanges} name='end' showTime  format='DD-MM-YYYY HH:mm:ss' disabledDate={(current) => {
        const start = moment(dates.start,'DD-MM-YYYY HH:mm:ss')
            return  current.format('DD-MM-YYYY')<start.format('DD-MM-YYYY')||current.format('DD-MM-YYYY')>start.add(1,'days').format('DD-MM-YYYY')
    }} disabled={dates.start===''?true:false} /></div>
	</Space>

	<a className={`btn btn-danger text-white ml-3 mt-3 `}  onClick={onSubmitCommand}>Submit</a>
	</div>
    <div className="text-danger mt-1">* Maximum Duration between Start and End :  2 Days Only</div>
			
			</Modal>
			<Modal title="" visible={Read2Msg} footer="" onCancel={() => setRead2Msg(false)} width={1000}>
			<div class="form-row">
					<div class="form-group col-md-4">
						<label for="inputState">Index:</label>
						<input type="text" class="form-control" id="" name="FirstName" placeholder="Enter Index" onChange={(e)=>setIndex(e.target.value)} value={Index}/>
					</div>
					</div>
	<a className={`btn btn-danger text-white ml-3 `} onClick={onSubmitCommand}>Submit</a>

			</Modal>
            <Modal title="Error Data" visible={isModalError} footer='' onCancel={() => (setIsModalError(false))} width={1000}>
                {ErrorData}
            </Modal>
            <Modal title="Duplicate Data" visible={isModalDup} footer='' onCancel={() => (setIsModalDup(false))} width={1000}>
                {DupData}
            </Modal>
            <Modal title="Row Data" visible={isModalrow} footer='' onCancel={() => (setIsModalRow(false))} width={1000}>
                {rowData}
            </Modal>
            <Modal title="All Data" visible={isModalVisible} onOk={() => (setIsModalVisible(false))} footer='' onCancel={handleCancel} width={1000}>
                <div class="table-responsive">
                    <table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "50%" }}>Data</th>
                                <th style={{ width: "50%" }}>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                        { ColumnName?.Meter_Data?.dailydata?.map((col)=>(col?.Type=="datetime"?<tr>
								<td style={{ width: "50%" }}>{col?.Column}</td>
								<td style={{ width: "50%" }}>{AllData[col?.Column]?moment.utc(AllData[col?.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>
							</tr>:<tr>
								<td style={{ width: "50%" }}>{col?.Column}</td>
								<td style={col?.Column=='UNITS'||col?.Column=='ERRORBIN'||col?.Column=='RAWDATA'?{ maxWidth: '500px'}:{ width: "50%" }}>{AllData[col?.Column]}</td>
							</tr>))}
                        
                        </tbody>
                    </table>
                </div>
            </Modal>
            <section className="card-diff-section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4><BootstrapTooltip title={`Table Name is ${props?.GSMdata?.UNIQUE_TABLE_NAME?.dailydata}`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
                                    <div className="card-header-action">
                                        <div id="filtergrm">
                                       
                                            {/* <button type="button" id="mqtt_get_cmd_301" class="btn btn-info mr-1" onClick={ongetScriptCodeIdWiseConfigretionData}>
                                                Get Data
                                            </button> */}
                                            <a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={()=>{ongetHeardbeatData()
                                           }}>
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                            </a>
                                            <div class="d-inline">
                      <button class="btn btn-info" onClick={()=>ongetStartAndEndDateByTable()}>
                      <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                                            {/* <a class="btn btn-info text-white" data-toggle="tooltip" data-placement="bottom" title="Download" onClick={getreportCreacteInTableName}>
                                                <i class="fa fa-download" aria-hidden="true"></i>
                                            </a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {loader && (
                                        <div className="example">
                                            <Spin size="large" />
                                        </div>
                                    )}
                                    {heartData.length === 0 && !loader ? StatusCode==503?<h4 className="text-center">Table Not Found</h4> :<h4 className="text-center">No Data Found</h4>:''}
                                    {heartData.length > 0 &&
                                        <div className="table-responsive tw-h-screen tw-max-h-screen">
                                            <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                                
                                            <table id="table_id" className="table table-borderless table-striped font-10">
                                                    <thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100'>
                                                        <tr className="tw-border-b text-center">
                                                        { ColumnName?.Meter_Data?.dailydata?.length>0&&ColumnName?.Meter_Data?.dailydata?.map((col)=>(col?.Display=="Yes"?col.Column=="METER_ENDTS"||col.Column=="METER_STARTTS"||col.Column=="METER_BCD_TS"||col.Column=="METER_TS"?<><BootstrapTooltip title={col?.Column}><th className=""onClick={()=>onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'><span>{col.Column=="METER_BCD_TS"||col.Column=="METER_TS"?'Date':col.Column=="METER_ENDTS"?'Date':'Date'}</span><div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div></div></th></BootstrapTooltip><BootstrapTooltip title={col?.Column}><th className=""onClick={()=>onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'><span>{col.Column=="METER_BCD_TS"||col.Column=="METER_TS"?'Time':col.Column=="METER_ENDTS"?'Time':'Time'}</span><div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div></div></th></BootstrapTooltip></>:<th className="" onClick={()=>onSort(col?.Column)}><BootstrapTooltip title={col?.Column}><div className='tw-flex tw-items-center tw-justify-center'>
                                        <div >{col?.Header}</div>
                                        {col?.Notes&&<BootstrapTooltip title={col?.Notes} placement='top'><span><i className="ml-2 fas fa-info-circle tw-text-xs"></i></span></BootstrapTooltip>}
                                        <div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                    </div></BootstrapTooltip></th>:''))}
                                                        <th>ACTIONS</th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {heartData.length > 0 && heartData.map((item, id) => (
                                                            <tr className="trheigth text-center">
                                                            { ColumnName?.Meter_Data?.dailydata?.length>0&&ColumnName?.Meter_Data?.dailydata?.map((col)=>(col?.Display=="No"?'':col?.Type=="datetime"?col.Column=="METER_ENDTS"||col.Column=="METER_STARTTS"||col.Column=="METER_BCD_TS"||col.Column=="METER_TS"?<><td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY"):''}</td><td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("HH:mm:ss"):''}</td></>:<td style={{ minWidth: "100px" }}>{item[col.Column]?item[col.Column]!='0000-00-00 00:00:00'?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):item[col.Column]:''}</td>:col?.Float!=null&&col?.Float!=undefined?col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]/col?.DivisionNumber).toFixed(col?.Float)}</td>:<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{col?.Float!=null&&col?.Float!=undefined&&item[col.Column]?Number(item[col.Column]).toFixed(col?.Float):item[col.Column]}</td>:
															col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]/col?.DivisionNumber}</td>:
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]}</td>
															))}
                                                                <td style={{ minWidth: '100px' }}><a class="action-icon text-success" onClick={() => (onAllShowData(item))} ><span data-toggle="tooltip" data-placement="bottom" title="All Data"><i class="fa fa-database fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
                                                                    <a class="action-icon text-info" onClick={() => onRowDate(item?.RAWDATA)}><span data-toggle="tooltip" data-placement="bottom" title="Raw Data"><i class="fa fa-info-circle fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
                                                                    <a class="action-icon text-danger" onClick={() => (onDuplicate(item?.DUPLICATEBIN))}><span data-toggle="tooltip" data-placement="bottom" title="Duplicate Data"><i class="fa fa-clone fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
                                                                    <a class="action-icon text-dark" onClick={() => (onError(item?.ERRORBIN))}><span data-toggle="tooltip" data-placement="bottom" title="Error Data"><i class="fa fa-exclamation-triangle fa-lg font-13" aria-hidden="true"></i></span></a></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <>
                                                    <div className="d-flex justify-content-between">
                                                        <div>Total Number Of Row {numberofPage}</div>
                                                        <div class="dataTables_length" id="table_id_length">
                                                    <label>
                                                        Show{" "}
                                                        <select
                                                            name="table_id_length"
                                                            aria-controls="table_id"
                                                            class=""
                                                            onChange={(e) => setPageSize(e.target.value)}
                                                            value={PageSize}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>{" "}
                                                        entries
                                                    </label>
                                                </div>
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPage}
                                                            totalCount={numberofPage || 1}
                                                            pageSize={PageSize}
                                                            onPageChange={(page) => setCurrentPage(page)}
                                                        />
                                                    </div>
                                                </>
                                            </div>
                                        </div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

