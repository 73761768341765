import React, { useEffect, useState, useRef } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//  import { makeStyles } from '@mui/styles';

import Geonames from "geonames.js";
import PropTypes from "prop-types";

const geonames = new Geonames({
  username: "thalesandrade",
  lan: "en",
  encoding: "JSON"
});

// const useStyles = makeStyles(theme => ({
//   formControl: {
//     minWidth: "100%"
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

export default function GeoLocation(props) {
//   const classes = useStyles();
  const { locationTitle, geoId, onChange, isCountry,country,state,city,isState,isCity,disabled} = props;
  const [options, setOptions] = useState([]);
  const [currentItem, setCurrentItem] = useState("");
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    // setLabelWidth(inputLabel.current.offsetWidth);
    if(isCountry){
      country!==''&&setCurrentItem(country)
    }else if(isState){
      state!==''&&setCurrentItem(state)
    }else if(isCity){
      city!==''&&setCurrentItem(city)
    }
  }, [country,state,city]);

  useEffect(() => {
    try {
      const data = async () => {
        (await isCountry)
          ? geonames.countryInfo({}).then(res => {
              setOptions(res.geonames);
            })
          : geonames.children({ geonameId: geoId }).then(res => {

              if (res.totalResultsCount) setOptions(res.geonames);
            });
      };
      data();
    } catch (err) {
      console.error(err);
    }
  }, [geoId, isCountry]);

  const inputLabel = useRef(null);

  const handleChange = e => {
    setCurrentItem(e.target.value);
    onChange(e.target.value);
  };

  return (
        <>
        <div class="form-group">
        <label for="input4">{locationTitle}</label>
        <select class="form-control form-select" name="" id="" onChange={handleChange} value={currentItem} placeholder={locationTitle} disabled={disabled}>
        <option value="">Select {locationTitle}</option>
        {options.map((v, index) => (
            <option value={v.geonameId}>{isCountry ? v.countryName : v.name}</option>
        ))}
        </select></div>
        {/* <FormControl variant="outlined"  sx={{minWidth: "100%"}}>
      <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
        {locationTitle}
      </InputLabel>

      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={currentItem}
        onChange={handleChange}
        labelWidth={labelWidth}
      >
        <MenuItem value="">
          <em>-</em>
        </MenuItem>
        {options.map((v, index) => (
          <MenuItem key={index} value={v.geonameId}>
            {isCountry ? v.countryName : v.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl> */}
    </>
  );
}

GeoLocation.propTypes = {
  locationTitle: PropTypes.string,
  geoId: PropTypes.node,
  isCountry: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isState:PropTypes.bool,
  isCity:PropTypes.bool,
  disabled:PropTypes.bool,
};

GeoLocation.defaultProps = {
  onChange: undefined
};
