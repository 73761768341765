import React, {  useState } from 'react'
import chroma from 'chroma-js';
import Schedulelist from './Schedulelist';
import ScheduleDashboard from './ScheduleDashboard';

export default function ScheduleIndex(props) {
	const [buttonArray,setbuttonArray] = useState(["Dashboard*",'Schedules'])
	const [btnText, setButtontext] = useState("Schedules");
    const onHandalebtn = (text) => {
		setButtontext(text);
	};
	// const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
	
  return (
    <>
        <div className="card-body"  style={{ paddingTop: "0px",paddingLeft:'15px',paddingBottom:'5px' }}>
				<div className="d-flex justify-content-between">
					<div className="buttons">
						{buttonArray.map((item, id) => (
							<button
							style={{
								backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#3abaf4').alpha(0.4).css() : '',
								color: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#3abaf4').darken(1.5).css() : '#3abaf4',
								borderBottom: `1px solid ${'#3abaf4'}`,
								boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${'#3abaf4'}`  : '',
							}}
							className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
							onClick={() => onHandalebtn(item)}>
							{item}
						</button>
						))}
					</div>
                    </div>
                    {btnText=='Schedules'&&<Schedulelist credentialdetails={props?.credentialdetails}/>}
                    {btnText=='Dashboard*'&&<ScheduleDashboard credentialdetails={props?.credentialdetails}/>}
                    </div>
    </>
  )
}
