// export const Svgcard = ({ svgIllustration, numbers, title, color, onClick, borderColor }) => {
//     return (
//         <div onClick={onClick} className={classNames('tw-flex  tw-flex-col tw-mb-1 tw-relative !tw-border-b-8 tw-border tw-border-solid tw-rounded-lg tw-py-0.5 tw-cursor-pointer', color)} style={{ borderColor: borderColor }}>
//             <div className='tw-flex tw-justify-between tw-items-center'>

//                 <div className='tw-ml-6 tw-text-start'>
//                     <h3 className='tw-mb-0'>{numbers ? numbers : 0}</h3>
//                     <span className='tw-text-sm tw-text-gray-600 tw-cursor-pointer'>
//                         {title}
//                     </span>
//                 </div>
//                 <div className='tw-h-16 tw-w-16 tw-m-1 tw-mr-4 tw-float-right tw-flex tw-justify-center tw-items-center tw-rounded-full '>
//                     {svgIllustration}
//                 </div>
//             </div>
//         </div>
//     )
// }

export const TaskFilterButton = ({ label, count, onClick, mainStyle, countStyle, countinlinestyle = {}, mainInlineStyle = {}, icon }) => (
    <div style={{ ...mainInlineStyle, borderBottomStyle: 'dotted', borderBottomWidth: '1px' }}
        className={`tw-justify-center tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 tw-flex tw-gap-2 tw-text-base tw-items-center hover:tw-rounded tw-p-2 tw-cursor-pointer tw-duration-150 ${mainStyle ? mainStyle : ""} `} onClick={onClick}>
        <div className='' >{icon}</div>
        <label className="tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70 tw-mb-0">{label}</label>
        <div style={countinlinestyle} className={`tw-ml-auto tw-text-white tw-cursor-pointer tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-xs tw-font-semibold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1 ${countStyle ? countStyle : ""}`}>{count || 0}</div>
    </div>
);

export const CardBody = ({ children, CardTitle, isBodyClass = true, className, bodyClassName }) => {
    return (
        <div className={`tw-text-card-foreground tw-shadow-md tw-w-full tw-my-4 tw-py-2 border tw-bg-card tw-rounded ${className ?? className}`} style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 5px 10px 0px" }} >
            <div className='tw-flex tw-flex-col tw-space-y-1.5' >
                <h5 className='tw-font-semibold tw-leading-none tw-tracking-tight border-bottom tw-px-2 tw-py-3 tw-mb-0' >
                    {CardTitle}
                </h5>
            </div>
            <div className={`${isBodyClass ? "tw-grid tw-gap-1 tw-px-6 tw-pb-4" : ""} ${bodyClassName ?? bodyClassName}`} >
                {children}
            </div>
        </div>
    )
}

export const TaskCountCard = ({ className, title, count, onClick }) => {
    return (
        <div onClick={onClick} className={`tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-2 tw-border tw-border-solid tw-rounded-lg tw-cursor-pointer ${className ?? null}`} >
            <div className='tw-mb-0 tw-font-semibold tw-text-base' >{title}</div>
            <div className='tw-text-xl tw-font-semibold tw-text-gray-800 tw-mb-0'>{count}</div>
        </div>
    )
}