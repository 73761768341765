import React, { useEffect, useState } from 'react'
import { InstanceScheduleInPush, UpdateApiSchedule, UpdateApiScheduleStatus, getAccount, getAreaById, getCircleById, getDivisionById, getGSMDataFilterDataIntegration, getGaById, getMSSQLlogs, getProfilNameByAccountId, getRolesById, getScheduleActivityLogs, getScheduleList, getScheduleLogHistory, getSubdevisionById } from '../../../Commoncomponet/Utils'
import moment from 'moment'
import { Modal, Spin, TimePicker } from 'antd'
import Pagination from '../../../Commoncomponet/Pagination'
import GoogleLoader from '../../../Commoncomponet/Loaders/GoogleLoader'
import { useParams } from 'react-router-dom'
import { BootstrapTooltip, exportExcelDownload } from '../../../Commoncomponet/common'
import { object } from 'prop-types'
import Schedule from './Schedule'
import chroma from 'chroma-js'
import SchedualLog from './SchedualLog'
import swal from 'sweetalert2';
import './ActivityLogs.css';
import classNames from 'classnames'
import Select from 'react-select';
import MultiInput from '../../../Commoncomponet/MultiInput/MultiInput'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SlidingTabs from '../../../Commoncomponet/Full_Tabs/SlidingTabs'
import TabPane from '../../../Commoncomponet/Full_Tabs/TabPane'

window.Swal = swal;


const Schedulelist = (props) => {
    const [report, setReport] = useState({ start_date: '', end_date: '', Consumer_Type: [],Consumer:[] })
    const [logData, setLogData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isConsumerInfo, setIsConsumerInfo] = useState(false)
    const [isDuplicateInfo, setIsDuplicateInfo] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [isNullData, setIsNullData] = useState(false)
    const [consumerInfoModalData, setConsumerInfoModalData] = useState([])
    const [duplicateInfoModalData, setDuplicateInfoModalData] = useState([])
    const [API_SEND_DATAModal, setAPI_SEND_DATAModal] = useState(false)
    const [ApiSendData, setApiSendData] = useState([])

    const [InfoModalData, setInfoModalData] = useState({})
    const [nullDataInfoModalData, setNullDataInfoModalData] = useState([])
    const [numberofPage, setnumberofPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [ComponetTime, setComponetTime] = useState('')
    const [StatusFilter, setStatusFilter] = useState('1')
    const [UpdateconsumerList, setUpdateconsumerList] = useState({ list: [], Filter_obj: {}, all_info: {} })
    const [ScheduleUniqueId, setScheduleUniqueId] = useState('')
    const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
    const [activeTab, setActiveTab] = useState("Schedules");
    const [LogDetails, setLogDetails] = useState([])
    const [LogModal, setLogModal] = useState(false)
    const [LogLoader, setLogLoader] = useState(false)
    const [LogTitle, setLogTitle] = useState(false)
    const params = useParams();
    const [ActivityData, setActivityData] = useState([])
    const [isSideExapnded, setIsSideExapnded] = useState(false)
    const [Loader, setLoader] = useState(false)
    const [ScheduleDetails, setScheduleDetails] = useState({})
    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" });
    const [SubdevisionData, setSubdevisionData] = useState([]);
    const [DivisionData, setDivisionData] = useState([]);
    const [CircleData, setCircleData] = useState([]);
    const [GaData, setGaData] = useState([]);
    const [AccounData, setAccountData] = useState([]);
    const [AreaData, setAreaData] = useState([]);
    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const [permision, setpermision] = useState({});
    const [ConsumerSearchData, setConsumerSearchData] = useState([])
    const [LoraConsumerSearchData, setLoraConsumerSearchData] = useState([])
    const [loraSearchdata, setloraSearchdata] = useState([])
    const [FilterSerchLoader, setFilterSerchLoader] = useState(false)
    const [ConsumerUniqIdArray, setConsumerUniqIdArray] = useState([])
    const [ConsumerData, setConsumerData] = useState([])
    const [searchTerms, setSearchTerms] = useState([]);
    const [SchedualModal, setSchedualModal] = useState(false)
    const [SchedualInput, setSchedualInput] = useState({ SCHEDULE_NAME: '', SCHEDULE_TYPE: '', SCHEDULE_TIME: '' })
    const [Schedualbtn, setSchedualbtn] = useState(false)
    const ongetActivityData = async (id) => {
        setLoader(true)
        const data = await getScheduleActivityLogs({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.activitylog, schedule_id: id })
        if (data.success === true) {
            const groupByDate = data.data.data?.reduce((acc, log) => {
                const date = moment.utc(log.created_at).format('DD-MM-YYYY');
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(log);
                return acc;
            }, {});
            const groupedLogs = Object.keys(groupByDate).map(date => {
                return {
                    date,
                    logs: groupByDate[date]
                }
            });
            console.log(groupedLogs)
            setAPI_SEND_DATAModal(true)
            setActivityData(groupedLogs)
            setLoader(false)
        } else {
            console.log('something want wrong')
            setLoader(false)
        }
    }

    const fetchData = async () => {
        setIsLoading(true)
        const firstPageIndex = (currentPage - 1) * PageSize;
        const repData = await getScheduleList({ Roles: userInfo?.ROLE, userId: userInfo?.UNIQUE_ID, start: firstPageIndex, end: PageSize, tableName: JSON.parse(props?.credentialdetails?.TABLE_JSON || '{}')?.schedule, StatusFilter: StatusFilter })
        if (repData.success === true) {
            console.log(repData.data.LogDetails)
            setLogData(repData.data.LogDetails)
            setnumberofPage(repData.data.count)
            setIsLoading(false)
            if(isSideExapnded==true){
                setScheduleDetails(repData?.data?.LogDetails?.filter((item)=>(item?.UNIQUE_ID==ScheduleDetails?.UNIQUE_ID))[0])
            }
        } else {
            setIsLoading(false)

        }
    }
    const onHandaleChangeComponet = (row) => {
        setUpdateconsumerList({ list: JSON.parse(row?.SELECTED_CONSUMER_IDS || '[]'), Filter_obj: JSON.parse(row?.FILTER_OBJECT), all_info: row })
        setScheduleUniqueId(row?.UNIQUE_ID)
        setComponetTime('Update')
    }
    useEffect(() => {
        fetchData()
    }, [StatusFilter])

    useEffect(() => {
        if (ComponetTime == '') {
            fetchData()
        }
    }, [ComponetTime])

    const refreshClick = () => {
        setCurrentPage(1)
        fetchData()
    }

    useEffect(() => {
        fetchData()
    }, [currentPage, PageSize]);



    const Download_Logs = () => {
        exportExcelDownload(consumerInfoModalData.map((item) => ({ UNIQUE_ID: item?.UNIQUE_ID, INSTALLATION_NUMBER: item?.INSTALLATION_NUMBER, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, SCRIPTDECODE_ID_FOUND: item?.SCRIPTDECODE_ID_FOUND, DAILY_TABLE_EXIST: item?.DAILY_TABLE_EXIST, DAILY_DATA_FOUND: item?.DAILY_DATA_FOUND, INSERT_SUCCESS: item?.INSERT_SUCCESS, RESPONSE_CODE: item?.RESPONSE_CODE })), 'CONSUMER_INFO_' + (new Date().valueOf()))
    }



    const onHandalClean = () => {
        setComponetTime('')
        setScheduleUniqueId('')
        setUpdateconsumerList({ list: [], Filter_obj: {}, all_info: {} })

    }

    useEffect(() => {
        if (activeTab === 'Schedules') {
            setComponetTime('')
        }
    }, [activeTab])

    const onHandaleSchedulePush = (item) => {
        swal.fire({
            title: `! Are You Sure, Run ${item?.SCHEDULE_NAME}`,
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                var Browser_Details = {}
                Browser_Details.browser = window?.navigator?.userAgent
                Browser_Details.browser_version = window?.navigator?.appVersion
                Browser_Details.screen_details = window?.screen
                const FilterObj = JSON.parse(item?.FILTER_OBJECT || '{}')
                var obj = {
                    MSID: params?.MSID,
                    roles_id: userInfo?.ROLE,
                    UNIQUE_ID: item.UNIQUE_ID,
                    Category0: FilterObj?.CATEGORY0,
                    Category1: FilterObj?.CATEGORY1,
                    Category2: FilterObj?.CATEGORY2,
                    Category3: FilterObj?.CATEGORY3,
                    Category4: FilterObj?.CATEGORY4,
                    Category5: FilterObj?.CATEGORY5,
                    Consumer_Unique_ID: JSON.parse(item?.SELECTED_CONSUMER_IDS || '[]'),
                    start_date: moment().format('DD-MM-YYYY'),
                    end_date: moment().format('DD-MM-YYYY'),
                    User_ID: userInfo?.UNIQUE_ID,
                    TYPE: props?.credentialdetails?.TYPE,
                    Browser_Details: Browser_Details
                }
                const data = await InstanceScheduleInPush(obj, JSON.parse(props?.credentialdetails?.TABLE_JSON || '{}')?.schedule_log)
                if (data.success == true) {
                    swal.fire('', 'Proccess Start', 'success');

                } else {
                    swal.fire('', 'Server Error', 'error');
                }
            }
        })
    }

    const onHandaleScheduleLogs = async (id, title) => {
        setLogModal(true)
        setLogTitle(title)
        setLogLoader(true)
        const data = await getScheduleLogHistory({ tableName: JSON.parse(props?.credentialdetails?.TABLE_JSON || '{}')?.schedule_log, SCHEDULE_ID: id })
        if (data.success == true) {
            setLogDetails(data?.data?.data)
            setLogLoader(false)

        } else {
            setLogLoader(false)

        }
    }
    const ongetGaById = async (id) => {
        const data1 = await getGaById(id);
        if (data1.success === true) {
            setGaData(data1.data.data);
        }
    };
    const onHandaleSelection = async () => {
        const data = await getAccount();
        if (data.success === true) {
            setAccountData(data.data.data);
        } else {
            console.log("somthing want wrong");
        }
    };
    const onHandaleRoles = async () => {
        const data = await getRolesById(userInfo?.ROLE);
        if (data.success === true) {
            const obj = data.data.data[0];
            setpermision({
                ...obj,
                ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
                ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
                ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
                ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
                ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
                ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
                ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
            });
            onHandaleSelection()
        }
    }

    const ongetCircle = async (id) => {
        const data1 = await getCircleById(id);
        if (data1.success === true) {
            setCircleData(data1.data.data);
        }
    };
    const ongetSubdevisionById = async (id) => {
        const data1 = await getSubdevisionById(id);
        if (data1.success === true) {
            setSubdevisionData(data1.data.data);
        }
    };
    const ongetDivisionById = async (id) => {
        const data3 = await getDivisionById(id);
        if (data3.success === true) {
            setDivisionData(data3.data.data);
        }
    };
    const ongetArea = async (id) => {
        const data3 = await getAreaById(id);
        if (data3.success === true) {
            setAreaData(data3.data.data);
        }
    };
    const [NameObj, setNameObj] = useState({})
    const ongetProfilNameByAccountId = async (id) => {
        const data = await getProfilNameByAccountId({ ID: id })
        if (data.success === true) {
            setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
        } else {
            console.log('something want wrong')
        }
    }
    useEffect(() => {

        if (Profile.account !== "") {
            ongetGaById(Profile.account);
            setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([])

            ongetProfilNameByAccountId(Profile.account)

        } else {
            setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
            setGaData([]);
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);
            if (Object.keys(permision).length > 0) {

            }
            setNameObj({})
        }
    }, [Profile.account]);

    useEffect(() => {
        if (Profile.ga !== "") {
            ongetCircle(Profile.ga);
            setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);

        } else {
            setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
            setCircleData([]);
            setDivisionData([]);
            setSubdevisionData([]);
            setAreaData([]);
            if (Object.keys(permision).length > 0) {

            }

        }
    }, [Profile.ga]);

    useEffect(() => {

        if (Profile.circle !== "") {
            ongetDivisionById(Profile.circle);
            setProfile({ ...Profile, division: "", subdivision: "", area: "" });
            setAreaData([]);
            setSubdevisionData([]);

        } else {
            setProfile({ ...Profile, division: "", subdivision: "", area: "" });
            setAreaData([]);
            setDivisionData([]);
            setSubdevisionData([]);
            if (Object.keys(permision).length > 0) {

            }

        }
    }, [Profile.circle]);

    useEffect(() => {

        if (Profile.division !== "") {
            ongetSubdevisionById(Profile.division);
            setAreaData([]);
            setProfile({ ...Profile, subdivision: "", area: "" });

        } else {
            setProfile({ ...Profile, subdivision: "", area: "" });
            setSubdevisionData([]);
            setAreaData([]);

        }
    }, [Profile.division]);

    useEffect(() => {

        if (Profile.subdivision !== "") {
            ongetArea(Profile.subdivision);
            setProfile({ ...Profile, area: "" });

        } else {
            setProfile({ ...Profile, area: "" });
            setAreaData([]);

        }
    }, [Profile.subdivision]);

    useEffect(() => {

        if (Profile.area !== "") {

        } else {

        }
    }, [Profile.area]);

    useEffect(() => {
        onHandaleRoles()
    }, [])
    const consumerTypeArray = [
        { value: "Industrial", label: 'Industrial' },
        { value: "Residential", label: 'Residential' },
        { value: "Commercial", label: 'Commercial' },
        { value: "DRS", label: 'DRS' },
    ]
    const consumerArray = [
        { value: "GSM", label: 'GSM' },
        { value: "Lorawan", label: 'Lorawan' }
    ]
    const onHandal = (e, id) => {
        var arrayCon = ConsumerUniqIdArray
        if (e.target.checked == true) {
            console.log(e.target.checked);
            arrayCon = [...arrayCon, id]
        } else {
            arrayCon = arrayCon.filter((item) => (item != id))
        }
        setConsumerUniqIdArray(arrayCon)
    }
    const ongetGSMDataFilterDataIntegration = async (pera_obj) => {
        setIsConsumerInfo(true)
        setFilterSerchLoader(true)
        var obj = {}
        if (pera_obj) {
            obj = { CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5_view: permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view, CATEGORY0: pera_obj?.account, CATEGORY1: pera_obj?.ga, CATEGORY2: pera_obj?.circle, CATEGORY3: pera_obj?.division, CATEGORY4: pera_obj?.subdivision, CATEGORY5: pera_obj?.area, Roles: userInfo?.ROLE, Consumer_Type: pera_obj?.Consumer_Type, Consumer: pera_obj?.Consumer||[] }
        } else {
            obj = { CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5_view: permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view, CATEGORY0: Profile?.account, CATEGORY1: Profile?.ga, CATEGORY2: Profile?.circle, CATEGORY3: Profile?.division, CATEGORY4: Profile?.subdivision, CATEGORY5: Profile?.area, Roles: userInfo?.ROLE,Consumer_Type: report?.Consumer_Type, Consumer: report?.Consumer||[] }
        }
        console.log(obj, pera_obj);
        const data = await getGSMDataFilterDataIntegration(obj)
        if (data.success === true) {
            setConsumerData(data?.data?.data)
            setConsumerSearchData(data?.data?.data)
            setloraSearchdata(data?.data?.loradata)
            setLoraConsumerSearchData(data?.data?.loradata)

            if (isSideExapnded) {
                setConsumerUniqIdArray(JSON.parse(ScheduleDetails?.SELECTED_CONSUMER_IDS))

            } else {
                setConsumerUniqIdArray([...data?.data?.data.map((item) => (item?.UNIQUE_ID)), ...data?.data?.loradata.map((item) => (item?.UNIQUE_ID))])
            }
            setFilterSerchLoader(false)
        } else {
            setFilterSerchLoader(false)
            setConsumerData([])
            setConsumerSearchData([])
            setLoraConsumerSearchData([])
            setConsumerUniqIdArray([])
        }
    }
    const onHandalSearchMulti = (newSearchTerms) => {
        setSearchTerms(newSearchTerms);
        if (newSearchTerms.length > 0) {
            const filteredData = [...ConsumerSearchData].filter((item) => {
                const addressCompanyName = JSON.parse(item?.ADDRESS || "{}").address_compony_name?.toLowerCase();
                const searchDataLowerCase = newSearchTerms.map((term) => term.toLowerCase());

                return searchDataLowerCase.some((searchTerm) => {
                    return (
                        addressCompanyName?.includes(searchTerm) ||
                        item?.BUSINESS_PARTNER_NUMBER.toLowerCase().includes(searchTerm) ||
                        item?.DEVICE_SERIAL_NO.toLowerCase().includes(searchTerm) ||
                        item?.IMEI.toLowerCase().includes(searchTerm) ||
                        item?.INSTALLATION_NUMBER.toLowerCase().includes(searchTerm) ||
                        item?.PANEL_SERIAL_NO.toLowerCase().includes(searchTerm)
                    );
                });
            });

            const LorafilteredData = [...LoraConsumerSearchData].filter((item) => {
                const addressCompanyName = JSON.parse(item?.ADDRESS || "{}").address_compony_name?.toLowerCase();
                const searchDataLowerCase = newSearchTerms.map((term) => term.toLowerCase());

                return searchDataLowerCase.some((searchTerm) => {
                    return (
                        addressCompanyName?.includes(searchTerm) ||
                        item?.BUSINESS_PARTNER_NUMBER.toLowerCase().includes(searchTerm) ||
                        item?.DEVICE_SERIAL_NO.toLowerCase().includes(searchTerm) ||
                        item?.IMEI.toLowerCase().includes(searchTerm) ||
                        item?.INSTALLATION_NUMBER.toLowerCase().includes(searchTerm) ||
                        item?.PANEL_SERIAL_NO.toLowerCase().includes(searchTerm)
                    );
                });
            });
            setLoraConsumerSearchData(LorafilteredData)
            setConsumerData(filteredData);
        } else {
            console.log("clear all")
            setLoraConsumerSearchData(loraSearchdata)
            setConsumerData(ConsumerSearchData);
        }
    };

    const onItemCleared = (clearedIndex) => {
        // Create a new array without the cleared item
        const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
        // Update the search results based on the new search terms
        onHandalSearchMulti(newSearchTerms);
    };

    const onClearAllItems = () => {
        // Reset the search results to the original data
        onHandalSearchMulti([]);

    };

    const OnScheduleUpdateSlider = (row) => {
        setIsSideExapnded(true)
        setScheduleDetails(row)
        setProfile({ account: JSON.parse(row?.FILTER_OBJECT || '{}')?.CATEGORY0 || '', ga: JSON.parse(row?.FILTER_OBJECT || '{}')?.CATEGORY1 || '', circle: JSON.parse(row?.FILTER_OBJECT || '{}')?.CATEGORY2 || '', division: JSON.parse(row?.FILTER_OBJECT || '{}')?.CATEGORY3 || '', subdivision: JSON.parse(row?.FILTER_OBJECT || '{}')?.CATEGORY4 || '', area: JSON.parse(row?.FILTER_OBJECT || '{}')?.CATEGORY5 || '' })
        setReport({ ...report, Consumer_Type: JSON.parse(row?.FILTER_OBJECT || '{}')?.Consumer_Type || [],Consumer:JSON.parse(row?.FILTER_OBJECT || '{}')?.Consumer||[] })
        setConsumerUniqIdArray(JSON.parse(row?.SELECTED_CONSUMER_IDS))
        setSchedualInput({ SCHEDULE_NAME: row?.SCHEDULE_NAME, SCHEDULE_TYPE: row?.SCHEDULE_TYPE, SCHEDULE_TIME: `${row?.SCHEDULE_TIME.split(' ')[1]}:${row?.SCHEDULE_TIME.split(' ')[0]}` })
    }

    const onHadalSchedule = () => {
        setSchedualModal(true)
        setSchedualInput({ SCHEDULE_NAME: ScheduleDetails?.SCHEDULE_NAME, SCHEDULE_TYPE: ScheduleDetails?.SCHEDULE_TYPE, SCHEDULE_TIME: `${ScheduleDetails?.SCHEDULE_TIME.split(' ')[1]}:${ScheduleDetails?.SCHEDULE_TIME.split(' ')[0]}` })
    }

    function range(start, end) {
        const arr = [0, 1, 2, 3, 4, 5, 6, 16, 17, 18, 19, 20, 21, 22, 23];
        return arr;
    }

    const onHandelSchedule = async (pop) => {
        var Browser_Details = {}
        Browser_Details.browser = window?.navigator?.userAgent
        Browser_Details.browser_version = window?.navigator?.appVersion
        Browser_Details.screen_details = window?.screen
        // Browser_Details.screen_details=window?.navigator?.plugins
        console.log(SchedualInput)
        if (Profile?.account != '') {
            if (SchedualInput?.SCHEDULE_NAME == '') {
                return toast.warn("Please Enter Schedule Name")

            } else if (SchedualInput?.SCHEDULE_TYPE == '') {
                return toast.warn("Please Enter Schedule Type")

            } else if (SchedualInput?.SCHEDULE_TIME == '') {
                return toast.warn("Please Enter Schedule Time")

            }
            setSchedualbtn(true)
            var obj = {
                roles_id: userInfo?.ROLE,
                SCHEDULE_NAME: SchedualInput?.SCHEDULE_NAME, SCHEDULE_TYPE: SchedualInput?.SCHEDULE_TYPE, SELECTED_CONSUMER_IDS: JSON.stringify(ConsumerUniqIdArray), FILTER_OBJECT: JSON.stringify({ CATEGORY0: Profile?.account, CATEGORY1: Profile?.ga, CATEGORY2: Profile?.circle, CATEGORY3: Profile?.division, CATEGORY4: Profile?.subdivision, CATEGORY5: Profile?.area, Consumer_Type: report?.Consumer_Type,Consumer: report?.Consumer||[] }), CREATED_BY: userInfo?.UNIQUE_ID, SCHEDULE_TIME: `${SchedualInput?.SCHEDULE_TIME.split(':')[1]} ${SchedualInput?.SCHEDULE_TIME.split(':')[0]} * * *`, tableName: JSON.parse(props?.credentialdetails?.TABLE_JSON || '{}')?.schedule,
            }
            const old_json = {
                SCHEDULE_NAME: ScheduleDetails?.SCHEDULE_NAME,
                SCHEDULE_TYPE: ScheduleDetails?.SCHEDULE_TYPE,
                SELECTED_CONSUMER_IDS: JSON.stringify(JSON.parse(ScheduleDetails?.SELECTED_CONSUMER_IDS)),
                FILTER_OBJECT: JSON.stringify(JSON.parse(ScheduleDetails?.FILTER_OBJECT || '{}')),
                SCHEDULE_TIME: ScheduleDetails?.SCHEDULE_TIME,
            }
            var old_diff_json = {}
            var new_diff_json = {}

            Object.keys(old_json).map((item) => {
                if (old_json[item] != obj[item]) {
                    old_diff_json[item] = old_json[item]
                    new_diff_json[item] = obj[item]
                }
            })
            if (pop === true) {
                swal.fire({
                    title: `Are You Sure, Total ${ConsumerUniqIdArray.length} Consumer Data Push Schedule Update`,
                    icon: "warning",
                    showCancelButton: true,
                }).then(async (willDelete) => {
                    if (willDelete.isConfirmed) {
                        const data = await UpdateApiSchedule({ ...obj, old_json: JSON.stringify(old_diff_json), new_json: JSON.stringify(new_diff_json), CREATED_BY: userInfo?.UNIQUE_ID }, ScheduleDetails?.UNIQUE_ID)
                        if (data.success === true) {
                            swal.fire('', 'Update Schedule Success', 'success');
                            setSchedualbtn(false)
                            setSchedualModal(false)
                            setIsConsumerInfo(false)
                            fetchData()
                        } else {
                            swal.fire('', 'Server Error', 'error');
                            setSchedualbtn(false)
                        }
                    } else {
                        setSchedualbtn(false)
                    }
                })
            } else {
                const data = await UpdateApiSchedule({ ...obj, old_json: JSON.stringify(old_diff_json), new_json: JSON.stringify(new_diff_json), CREATED_BY: userInfo?.UNIQUE_ID }, ScheduleDetails?.UNIQUE_ID)
                if (data.success === true) {
                    swal.fire('', 'Update Schedule Success', 'success');
                    setSchedualbtn(false)
                    setSchedualModal(false)
                    fetchData()
                } else {
                    swal.fire('', 'Server Error', 'error');
                    setSchedualbtn(false)
                }
            }
        } else {
            toast.error("Please Select Account")
        }
    }

    const onActive = async () => {
        swal.fire({
           title: "!Are you Sure,Schedule Status Change In Active",
           icon: "warning",
    
        }).then(async (willDelete) => {
           if (willDelete.isConfirmed) {
              const data = await UpdateApiScheduleStatus({ SCHEDULE_STATUS:1,tableName:JSON.parse(props?.credentialdetails?.TABLE_JSON||'{}')?.schedule,old_json:JSON.stringify({SCHEDULE_STATUS:0}),new_json:JSON.stringify({SCHEDULE_STATUS:1}),CREATED_BY:userInfo?.UNIQUE_ID },ScheduleDetails?.UNIQUE_ID)
              if (data.success === true) {
                 toast.success('Schedule is Active')
                 setIsSideExapnded(false)

                 fetchData()
              } else {
                 toast.error('something want wrong')
              }
           }
        })
     }
    
     const onInActive = async () => {
        swal.fire({
           title: "!Are you Sure,Schedule Status Change In InActive",
           icon: "warning",
    
        }).then(async (willDelete) => {
           if (willDelete.isConfirmed) {
              const data = await UpdateApiScheduleStatus({ SCHEDULE_STATUS:0,tableName:JSON.parse(props?.credentialdetails?.TABLE_JSON||'{}')?.schedule,old_json:JSON.stringify({SCHEDULE_STATUS:1}),new_json:JSON.stringify({SCHEDULE_STATUS:0}),CREATED_BY:userInfo?.UNIQUE_ID },ScheduleDetails?.UNIQUE_ID)
              if (data.success === true) {
                 toast.success('Schedule is InActive')
                 setIsSideExapnded(false)
                 fetchData()
    
              } else {
                 toast.error('something want wrong')
              }
           }
        })
     }
    
    return (
        <>
            <Modal title={'Update Schedule'} maskClosable={false} visible={SchedualModal} footer='' onCancel={() => { setSchedualModal(false) }} width={500}>
                <section className="section mt-3">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-row" >
                                    <div className="form-group col-md-12" id="grpeui">
                                        <label for="inputPassword4 ">Schedule Name :<span className='text-danger'>*</span> </label>
                                        <input type="text" class="form-control present_street_area" name="address_compony_name" id="address_compony_name" placeholder="Schedule Name" onChange={(e) => { setSchedualInput({ ...SchedualInput, SCHEDULE_NAME: e.target.value }) }} value={SchedualInput?.SCHEDULE_NAME} />
                                    </div>
                                    <div className="form-group col-md-12" id="grpeui">
                                        <label for="inputPassword4 ">Schedule Type:<span className='text-danger'>*</span> </label>
                                        <select className="form-control form-select" name="reporttype" required="required" onChange={(e) => { setSchedualInput({ ...SchedualInput, SCHEDULE_TYPE: e.target.value }) }} value={SchedualInput?.SCHEDULE_TYPE}>
                                            <option value="">Select Schedule Type</option>
                                            <option value="1">Daily</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-12" id="grpeui">
                                        <label for="inputPassword4 ">Schedule Time:<span className='text-danger'>*</span> </label>
                                        <TimePicker format="HH:mm" name='start' style={{ width: '100%', height: '40px' }}
                                            showTime
                                            onChange={(e, dateString) => (setSchedualInput({ ...SchedualInput, SCHEDULE_TIME: dateString }))}
                                            disabledHours={() => range(15, 7)}
                                            value={SchedualInput?.SCHEDULE_TIME ? moment(SchedualInput?.SCHEDULE_TIME, "HH:mm") : ''}
                                        />
                                    </div>
                                </div>
                                <p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
                                <div className="text-left">
                                    <input type="submit" name="submit" className={`btn btn-danger ${Schedualbtn == true ? 'disabled btn-progress' : ''} mr-2`} value={"Update"} id="submitvn" onClick={() => onHandelSchedule(false)} />
                                    <Link className="btn btn-light text-dark" onClick={() => setSchedualModal(false)}>Cancel</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </Modal>
            <Modal title={<h4>{LogTitle + ` (${LogDetails.length})`}</h4>} visible={LogModal} maskClosable={false} footer='' onCancel={() => setLogModal(false)} width={800}>
                <div>
                    <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                        <thead>
                            <tr>
                                <th>SRNO</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {LogDetails.length > 0 && LogDetails.map((info, id) => (
                                <tr>
                                    <td>{info?.SRNO}</td>
                                    <td>{moment.utc(info?.CREATED_AT).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                    <td>{moment.utc(info?.UPDATED_TIME).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                    <td>{info?.STATUS === -1 ? moment(moment.utc().add(moment().utcOffset(), "m").format()).diff(moment(moment.utc(info?.CREATED_AT).format()), 'minutes') > 5 ? <span class="badge badge-secondary">Time Out</span> : <span class="badge badge-primary">Process Start</span> : info?.STATUS === 2 ? <span class="badge badge-danger">Failed</span> : info?.STATUS === 3 ? <span class="badge badge-secondary">Time Out</span> : <span class="badge badge-success">Success</span>}</td>

                                </tr>
                            ))}

                        </tbody>

                    </table>
                </div>
            </Modal>
            <Modal title={<div><span>CONSUMER LIST (Selected : {[...ConsumerData.map((item) => (item?.UNIQUE_ID)), ...LoraConsumerSearchData.map((item) => (item?.UNIQUE_ID))].filter(((item)=>(ConsumerUniqIdArray.includes(item)))).length})</span></div>} visible={isConsumerInfo} maskClosable={false} footer='' onCancel={() => setIsConsumerInfo(false)} width={1500}>
                {FilterSerchLoader ? <div className="example">
                    <Spin size="large" />
                </div> : <>
                    <div className='tw-h-[100vh] tw-overflow-scroll'>

                        <div className="card">
                            <div className="card-header">
                                <h4 className="modemconfigurationstatus d-none"></h4>
                                <div className="card-header-action">
                                    <div id="filtergrm">
                                        <MultiInput
                                            placeholder="search and enter..."
                                            onChange={onHandalSearchMulti}
                                            maxItems={null} // Allow any number of items
                                            onClear={onItemCleared}
                                            onClearAll={onClearAllItems}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="table-responsive meterdata">
                                    <table className="table table-striped tw-text-xs tw-w-full">
                                        <thead>
                                            <tr>
                                                <th><input type="checkbox" className="chk_MSSQL1" name="checkAll" checked={ConsumerUniqIdArray.length == (ConsumerData.length + LoraConsumerSearchData.length) ? true : false} onClick={(e) => (e.target.checked == true ? setConsumerUniqIdArray([...ConsumerData.map((item) => (item?.UNIQUE_ID)), ...LoraConsumerSearchData.map((item) => (item?.UNIQUE_ID))]) : setConsumerUniqIdArray([]))} /></th>
                                                <th>SRNO</th>
                                                <th>Consumer Name</th>
                                                <th>Modem Serial No</th>
                                                <th>Installation Number</th>
                                                <th>Panel Serial Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...ConsumerData, ...LoraConsumerSearchData].map((item, id) => (<tr>
                                                <th><input type="checkbox" className="chk_MSSQL1" name="checkAll" checked={ConsumerUniqIdArray.filter((info) => (info == item?.UNIQUE_ID)).length > 0 ? true : false} onChange={(e) => onHandal(e, item?.UNIQUE_ID)} /></th>
                                                <th>{id + 1}</th>
                                                <th>{JSON.parse(item?.ADDRESS || '{}').address_compony_name}</th>
                                                <th>{item?.DEVICE_SERIAL_NO}</th>
                                                <th>{item?.INSTALLATION_NUMBER}</th>
                                                <th>{item?.PANEL_SERIAL_NO}</th>
                                            </tr>))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                    <div className="text-center mt-2">
                        <input type="submit" name="submit" className={`btn btn-danger ${Schedualbtn == true ? 'disabled btn-progress' : ''} mr-2`} value={"Update"} id="submitvn" onClick={() => onHandelSchedule(true)} />
                        <Link className="btn btn-light text-dark" onClick={() => setIsConsumerInfo(false)}>Cancel</Link>
                    </div>
                </>}
            </Modal>
            <Modal title="API RESPONSE" visible={isDuplicateInfo} maskClosable={false} footer='' onCancel={() => setIsDuplicateInfo(false)} width={1500}>
                {duplicateInfoModalData}
            </Modal>
            <Modal title="Activity Log" visible={API_SEND_DATAModal} maskClosable={false} footer='' onCancel={() => {
                setAPI_SEND_DATAModal(false)
                setApiSendData({})
            }} width={1500}>
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">

                            <div className="timeline-card">
                                {Loader ? (
                                    <p className="loading-message">Loading...</p>
                                ) : (
                                    <>
                                        {ActivityData.length > 0 && ActivityData.map((logP, index) => (
                                            <div className="timeline">
                                                <div className="date-section" key={index}>
                                                    <div className="date">{logP.date}</div>
                                                </div>
                                                {logP?.logs.map((log, index) => (
                                                    <div className="timeline-item" key={index}>
                                                        <div className="timeline-icon">{logP?.logs.length - index}</div>
                                                        <div className="timeline-data">
                                                            <div className="activity-log-avatar">
                                                                <span>{log?.created_by[0]}</span>
                                                            </div>
                                                            <div className="timeline-content">
                                                                <div className="activity-log-activity">
                                                                    <p className="activity-log-activity-creator">{log?.created_by} has </p>
                                                                    <p className="activity-log-activity-type">{log?.activity_type}</p>
                                                                    <p className="activity-log-activity-static">Updated <b>({Object.keys(JSON.parse(log?.new_changed_json || '{}')).join(', ')})</b></p>
                                                                    <p className="activity-log-activity-column">{log?.updated_columns && log?.updated_columns?.length > 0 ? log?.updated_columns?.join(", ") : ""}</p>
                                                                </div>
                                                                <div className="activity-log-changes">
                                                                    <div key={index}>
                                                                        <pre style={{ width: '500px' }}>{JSON.stringify(JSON.parse(log?.old_json || '{}'), null, 2)}</pre>
                                                                        <span className="arrow">→</span>
                                                                        <pre style={{ width: '500px' }}>{JSON.stringify(JSON.parse(log?.new_changed_json || '{}'), null, 2)}</pre>
                                                                    </div>
                                                                </div>
                                                                <p className="activity-log-date">at {moment.utc(log?.created_at).format('hh:mm:ss A')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                        {ActivityData.length == 0 && <h6>No Activity</h6>}

                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal title="API USER REQUEST INFO" visible={isInfo} maskClosable={false} footer='' onCancel={() => setIsInfo(false)} width={1500}>
                <div className='table-responsive'>
                    <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                        <thead>
                            <tr>
                                <th>start_date</th>
                                <th>end_date</th>
                                <th>MSID</th>
                                <th>User_ID</th>
                                <th>Browser_Details</th>
                                <th>Category0</th>
                                <th>Category1</th>
                                <th>Category2</th>
                                <th>Consumer_Unique_ID</th>
                                <th>roles_id</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{InfoModalData?.start_date}</td>
                                <td>{InfoModalData?.end_date}</td>
                                <td>{InfoModalData?.MSID}</td>
                                <td>{InfoModalData?.User_ID}</td>
                                <td>{JSON.stringify(InfoModalData?.Browser_Details)}</td>
                                <td>{InfoModalData?.Category0}</td>
                                <td>{InfoModalData?.Category1}</td>
                                <td>{InfoModalData?.Category2}</td>
                                <td className='tw-max-w-lg'>{InfoModalData?.Consumer_Unique_ID?.join(",")}</td>
                                <td>{InfoModalData?.roles_id}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </Modal>
            <Modal title="NULL_DATA_FOUND" visible={isNullData} maskClosable={false} footer='' onCancel={() => setIsNullData(false)} width={1500}>

                {nullDataInfoModalData.length === 0 ? <div className='tw-text-center'>No Data Found</div> : <div className='table-responsive'>
                    <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                        <thead>
                            <tr>
                                <th>UNIQUE_ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nullDataInfoModalData.length > 0 && nullDataInfoModalData.map(info => (
                                <tr>
                                    <td>{info?.UNIQUE_ID}</td>
                                </tr>
                            ))}

                        </tbody>

                    </table>
                </div>}
            </Modal>
            <div class="row  border-top py-2">
                <div class="d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
                    <ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
                        <li>
                            <button
                                style={{
                                    backgroundColor: activeTab === "Schedules" ? chroma(btnColor[0]).alpha(0.4).css() : '',
                                    color: activeTab === "Schedules" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
                                    borderBottom: `1px solid ${btnColor[0]}`,
                                    boxShadow: activeTab === "Schedules" ? `0 2px 8px -3px ${btnColor[0]}` : '',
                                }}
                                className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Schedules" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                                data-toggle="tab"
                                href="#home"
                                role="tab"
                                aria-controls="home"
                                aria-selected="true"
                                onClick={() => setActiveTab("Schedules")}
                            >
                                Schedules List
                            </button>
                        </li>
                        <li>
                            <button
                                style={{
                                    backgroundColor: activeTab === "Logs" ? chroma(btnColor[2]).alpha(0.4).css() : '',
                                    color: activeTab === "Logs" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
                                    borderBottom: `1px solid ${btnColor[2]}`,
                                    boxShadow: activeTab === "Logs" ? `0 2px 8px -3px ${btnColor[2]}` : '',
                                }}
                                className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Logs" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
                                id="profile-tab"
                                data-toggle="tab"
                                href="#profile"
                                role="tab"
                                aria-controls="profile"
                                aria-selected="false"
                                onClick={() => setActiveTab("Logs")}
                            >
                                Logs
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            {activeTab == 'Schedules' && <>
                {ComponetTime == 'New' ? <Schedule credentialdetails={props?.credentialdetails} setComponetTime={setComponetTime} /> : ComponetTime == 'Update' ? <Schedule credentialdetails={props?.credentialdetails} UpdateconsumerList={UpdateconsumerList} ScheduleUniqueId={ScheduleUniqueId} setComponetTime={setComponetTime} onHandalClean={onHandalClean} /> : <div class=" tw-w-full">
                    <div class="card-header d-flex tw-justify-between">
                        <div class="form-group mb-0 ">
                            <select
                                id="inputState"
                                class="form-select form-control"
                                name="modem_name"
                                onChange={(e) => {setStatusFilter(e.target.value)
                                    setIsSideExapnded(false)
                                setScheduleDetails({})}}
                                value={StatusFilter}
                                style={{ width: '300px', height: '35px', padding: '0px 15px' }}
                            >
                                <option value={'All'}>All</option>
                                <option value={'1'}>Active</option>
                                <option value={'0'}>Inactive</option>

                            </select>
                        </div>
                        <div class="card-header-action">
                            <div id="filtergrm">
                                <button class="btn btn-info text-white" onClick={refreshClick} ><i class="fa fa-refresh" aria-hidden="true"></i></button>
                                <button class="btn btn-primary ml-2" onClick={() => { setComponetTime('New') }}>+ Add New</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">

                        {isLoading ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                            <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                        </div> : <>
                            <div className='d-flex'>
                                <div class="table-responsive">
                                    <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                        <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                                            <thead>
                                                <tr>
                                                    <th>SRNO</th>
                                                    <th>UNIQUE ID</th>
                                                    <th>SCHEDULE NAME</th>
                                                    <th>SCHEDULE TIME</th>
                                                    <th>SCHEDULE TYPE</th>
                                                    {/* <th>CREATED AT</th>
                                            <th>CREATED BY</th> */}
                                                    <th>STATUS</th>
                                                    <th>Action</th>
                                                    {/* <th>Activity Logs</th> */}
                                                    {/* <th style={{ width: '100px' }}>Schedule Run History</th> */}
                                                    <th>INFO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {logData.map(row => (
                                                    <tr key={row.SR_NO} className={`${ScheduleDetails?.UNIQUE_ID==row?.UNIQUE_ID?'tw-border-b !tw-border-gray-300 !tw-bg-indigo-100':''}`}>
                                                        {/* <td className='tw-px-1 tw-py-3' style={{cursor:'pointer',color:'#3abaf4'}} onClick={()=>onHandaleChangeComponet(row)}>{row?.SRNO}</td> */}
                                                        <td className={`tw-px-1 tw-py-3 `} style={{ cursor: 'pointer', color: '#3abaf4' }} onClick={() => {
                                                            OnScheduleUpdateSlider(row)

                                                        }} >{row?.SRNO}</td>
                                                        <td className='tw-px-1 tw-py-3'>{row?.UNIQUE_ID}</td>
                                                        <td className='tw-px-1 tw-py-3'>{row?.SCHEDULE_NAME}</td>
                                                        <td className='tw-px-1 tw-py-3'>{row?.SCHEDULE_TIME ? (row?.SCHEDULE_TIME?.split(' ')[1] + ':' + row?.SCHEDULE_TIME?.split(' ')[0]) : ''}</td>
                                                        <td className='tw-px-1 tw-py-3'>{row?.SCHEDULE_TYPE == 1 ? 'Daily' : '-'}</td>
                                                        {/* <td className='tw-px-1 tw-py-3'>{moment.utc(row?.CREATED_AT).format("DD-MM-YYYY hh:mm:ss A")}</td> */}
                                                        <td className='tw-px-1 tw-py-3'>{row?.SCHEDULE_STATUS === 1 ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}</td>
                                                        <td className='tw-px-1 tw-py-3'><div className={`btn btn-info ${row?.SCHEDULE_STATUS == 0 ? 'disabled' : ''}`} onClick={() => onHandaleSchedulePush(row)}>Run</div></td>
                                                        {/* <td className='tw-px-1 tw-py-3'><div className={`btn btn-danger `} onClick={() => ongetActivityData(row?.UNIQUE_ID)}>Logs</div></td> */}
                                                        {/* <td className='tw-px-1 tw-py-3'><div className={`btn btn-primary `} onClick={() => onHandaleScheduleLogs(row?.UNIQUE_ID, row?.SCHEDULE_NAME)}><i className='fas fa-history' /></div></td> */}
                                                        <td><BootstrapTooltip title={<><span>CREATED_AT:{moment.utc(row?.CREATED_AT).format("DD-MM-YYYY hh:mm:ss A")}</span><br />
                                                            <span>CREATED_BY:{row?.CREATED_BY}</span><br />
                                                            <span>UNIQUE_ID:{row?.UNIQUE_ID}</span></>} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                        <>
                                            <div className="d-flex justify-content-between">
                                                <div>Total Number Of Row {numberofPage}</div>
                                                <div class="dataTables_length" id="table_id_length">
                                                    <label>
                                                        Show{" "}
                                                        <select
                                                            name="table_id_length"
                                                            aria-controls="table_id"
                                                            class=""
                                                            onChange={(e) => setPageSize(e.target.value)}
                                                            value={PageSize}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>{" "}
                                                        entries
                                                    </label>
                                                </div>

                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={numberofPage || 1}
                                                    pageSize={PageSize}
                                                    onPageChange={(page) => setCurrentPage(page)}
                                                />
                                            </div>
                                        </>
                                    </div>

                                </div>
                                {isSideExapnded && <div className='tw-flex tw-relative  tw-w-full tw-overflow-auto tw-grow tw-bg-[#f8f8f8]'>
                                    <div className={classNames('!tw-border-r-gray-300 tw-h-screen', isSideExapnded ? 'animate__fadeInRight_grow border-end tw-w-[100%]' : 'animate__fadeInLeft tw-flex-grow')}>
                                        <div className='tw-flex tw-flex-col tw-w-full'>
                                            <div className='tw-w-full tw-flex tw-items-center border-bottom !tw-border-b-gray-300 lg:tw-pl-5 tw-pr-2 tw-py-3 md:tw-px-0'>
                                                <span className='tw-text-md '> SRNO : <b>{ScheduleDetails?.SRNO}</b></span>
                                                <div class="ml-auto"></div>
                                                <div class="dropdown">
                                 <button class={`btn ${ScheduleDetails?.SCHEDULE_STATUS == 1 ? 'btn-success' : ScheduleDetails?.SCHEDULE_STATUS == 0 ? 'btn-danger' : ScheduleDetails?.SCHEDULE_STATUS == 2 ? 'btn-info' : ScheduleDetails?.SCHEDULE_STATUS == 3 ? 'btn-dark' : 'btn-warning'} dropdown-toggle`} type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                                    {ScheduleDetails?.SCHEDULE_STATUS == 1 ? 'Active' : ScheduleDetails?.SCHEDULE_STATUS == 0 ? 'InActive' : ScheduleDetails?.SCHEDULE_STATUS == 2 ? 'Under Maintenance' : ScheduleDetails?.SCHEDULE_STATUS == 3 ? 'Stand By' : 'Pending Configuration'}
                                 </button>
                                 <div class="dropdown-menu !tw-left-[-50px]" aria-labelledby="dropdownMenuButton1">
                                    {ScheduleDetails?.SCHEDULE_STATUS != 1 ? <a class="dropdown-item text-success" onClick={onActive} >Active</a> : ''}
                                    {ScheduleDetails?.SCHEDULE_STATUS != 0 ? <a class="dropdown-item text-danger"  onClick={onInActive}>InActive</a> : ''}
                                 </div>
                              </div>
                                                <span onClick={() => {
                                                    setIsSideExapnded(false)
                                                    setScheduleDetails({})
                                                }}
                                                    className='border-start !tw-border-l-gray-300 tw-float-right tw-text-[#777] tw-ml-2 tw-cursor-pointer hover:tw-text-[#212529]'>
                                                    <svg className='tw-h-8 lg:tw-w-10 md:tw-w-7' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /> </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='tw-overflow-y-auto tw-pb-10 m-3' style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 10px 0px' }}>
                                            <div className='tw-float-right mr-2 mt-2'>
                                                <button style={{ marginLeft: '5px' }} className="tw-border tw-py-1 tw-px-[0.4rem] tw-text-[#212529] tw-border-solid tw-border-[#ddd] tw-rounded " onClick={() => { onHadalSchedule() }} >
                                                    <i className='fas fa-edit'></i>
                                                </button>
                                            </div>
                                            <div className='tw-px-4 '>
                                                <div className='tw-grid tw-grid-cols-5 tw-gap-4 tw-mt-4'>

                                                    <div className=''>
                                                        Schedule Unique Id:-
                                                    </div>
                                                    <div className='tw-col-span-4'>
                                                        <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.UNIQUE_ID}</b>
                                                    </div>
                                                    <div className=''>
                                                        Schedule Name:-
                                                    </div>
                                                    <div className='tw-col-span-4'>
                                                        <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.SCHEDULE_NAME}</b>
                                                    </div>
                                                    <div className=''>
                                                        Schedule Time:-
                                                    </div>
                                                    <div className='tw-col-span-4'>
                                                        <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.SCHEDULE_TIME ? (ScheduleDetails?.SCHEDULE_TIME?.split(' ')[1] + ':' + ScheduleDetails?.SCHEDULE_TIME?.split(' ')[0]) : ''}</b>
                                                    </div>
                                                    <div className=''>
                                                        Schedule Type:-
                                                    </div>
                                                    <div className='tw-col-span-4'>
                                                        <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.SCHEDULE_TYPE == 1 ? 'Daily' : '-'}</b>
                                                    </div>
                                                    <div className=''>
                                                        Schedule Created By:-
                                                    </div>
                                                    <div className='tw-col-span-4'>
                                                        <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.CREATED_BY}</b>
                                                    </div>
                                                    <div className=''>
                                                        Schedule Created At:-
                                                    </div>
                                                    <div className='tw-col-span-4'>
                                                        <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.CREATED_AT}</b>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='tw-overflow-y-auto tw-pb-10 m-3' style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 10px 0px' }}>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div
                                                        class="form-row "
                                                        style={{
                                                            borderColor: "#e3e6fc !important",
                                                            position: "relative",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        <div class="col-md-4 ">
                                                            <div class="form-group">
                                                                <label htmlFor="inputPassword4">
                                                                    Account<span class="text-danger">*</span>
                                                                </label>
                                                                {/* <Select name='Consumertype' isMulti options={AccounData.map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																		{value:item.UNIQUE_ID,label:item.CATEGORY0}
																	) :{}
																)} onChange={(e) => { setProfile({ ...Profile, account: e.map((item) => (item.value)) }) }} /> */}
                                                                <select
                                                                    class="form-control form-select accountoga ga_calueacc"
                                                                    name="accountid"
                                                                    required=""
                                                                    id="accountid"
                                                                    onChange={(e) => setProfile({ ...Profile, account: e.target.value })}
                                                                    value={Profile?.account}

                                                                >
                                                                    <option value="">Select Account</option>
                                                                    {AccounData &&
                                                                        AccounData.map((item, id) =>
                                                                            permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
                                                                                (items) => items === item.UNIQUE_ID
                                                                            ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                                                <option key={id} value={item.UNIQUE_ID}>{item.CATEGORY0}</option>
                                                                            ) : (
                                                                                ""
                                                                            )
                                                                        )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {Profile.account !== "" && (
                                                            <div class="col-md-4 gagrp">
                                                                <div class="form-group">
                                                                    <label htmlFor="inputPassword4">{NameObj?.CATEGORY1 || 'CATEGORY1'}</label>
                                                                    <select
                                                                        class="form-control form-select gatocircle ga_calue"
                                                                        name="address_location_d"
                                                                        id="address_location_d"
                                                                        onChange={(e) => setProfile({ ...Profile, ga: e.target.value })}
                                                                        value={Profile?.ga}
                                                                    >
                                                                        <option value="">Select GA</option>
                                                                        {GaData &&
                                                                            GaData.map((item, id) =>
                                                                                permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter(
                                                                                    (items) => items === item.CATEGORY1_UNIQUE_ID
                                                                                ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                                                    <option key={id} value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option>
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {Profile.ga !== "" && Profile.account !== "" && (
                                                            <div class="col-md-4 circlegrp">
                                                                <div class="form-group">
                                                                    <label htmlFor="inputPassword4">{NameObj?.CATEGORY2 || 'CATEGORY2'}</label>
                                                                    <select
                                                                        class="form-control circletodivision form-select"
                                                                        name="circle_id"
                                                                        onChange={(e) => setProfile({ ...Profile, circle: e.target.value })}
                                                                        value={Profile?.circle}
                                                                    >
                                                                        <option value="">Select Circle</option>
                                                                        {CircleData &&
                                                                            CircleData.map((item, id) =>
                                                                                permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter(
                                                                                    (items) => items === item.CATEGORY2_UNIQUE_ID
                                                                                ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                                                    <option key={id} value={item.CATEGORY2_UNIQUE_ID}>{item.CATEGORY2_NAME}</option>
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
                                                            <div class="col-md-4 divisiongrp">
                                                                <div class="form-group">
                                                                    <label htmlFor="inputPassword4">{NameObj?.CATEGORY3 || 'CATEGORY3'}</label>
                                                                    <select
                                                                        class="form-control divisiontosubdivision form-select"
                                                                        name="division_id"
                                                                        id="address_location_d"
                                                                        onChange={(e) => setProfile({ ...Profile, division: e.target.value })}
                                                                        value={Profile?.division}
                                                                    >
                                                                        <option value="">Select Division</option>
                                                                        {DivisionData &&
                                                                            DivisionData.map((item, id) =>
                                                                                permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter(
                                                                                    (items) => items === item.CATEGORY3_UNIQUE_ID
                                                                                ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                                                    <option key={id} value={item.CATEGORY3_UNIQUE_ID}>{item.CATEGORY3_NAME}</option>
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
                                                            <div class="col-md-4 subdivisiongrp">
                                                                <div class="form-group">
                                                                    <label htmlFor="inputPassword4">{NameObj?.CATEGORY4 || 'CATEGORY4'}</label>
                                                                    <select
                                                                        class="form-control subdivisiontoarea form-select"
                                                                        name="sub_division_id"
                                                                        id="address_location_d"
                                                                        onChange={(e) => setProfile({ ...Profile, subdivision: e.target.value })}
                                                                        value={Profile?.subdivision}
                                                                    >
                                                                        <option value="">Select Sub Division</option>
                                                                        {SubdevisionData &&
                                                                            SubdevisionData.map((item, id) =>
                                                                                permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter(
                                                                                    (items) => items === item.CATEGORY4_UNIQUE_ID
                                                                                ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                                                    <option key={id} value={item.CATEGORY4_UNIQUE_ID}>
                                                                                        {item.CATEGORY4_NAME}
                                                                                    </option>
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {Profile.subdivision !== "" &&
                                                            Profile.division !== "" &&
                                                            Profile.circle !== "" &&
                                                            Profile.ga !== "" &&
                                                            Profile.account !== "" && (
                                                                <div class="col-md-4 areagrp">
                                                                    <div class="form-group">
                                                                        <label htmlFor="inputPassword4">{NameObj?.CATEGORY5 || 'CATEGORY5'}</label>
                                                                        <select
                                                                            class="form-control form-select lastarea cust_ara"
                                                                            name="area_id"
                                                                            id="area_id"
                                                                            onChange={(e) => setProfile({ ...Profile, area: e.target.value })}
                                                                            value={Profile?.area}
                                                                        >
                                                                            <option value="">Select Area</option>
                                                                            {AreaData &&
                                                                                AreaData.map((item, id) =>
                                                                                    permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter(
                                                                                        (items) => items === item.UNIQUE_ID
                                                                                    ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                                                        <option key={id} value={item.UNIQUE_ID}>{item.CATEGORY5_NAME}</option>
                                                                                    ) : (
                                                                                        ""
                                                                                    )
                                                                                )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div class="col-md-12 areagrp d-flex tw-items-center tw-gap-2">
                                                        <div class="form-group" style={{ width: '100%' }}>
                                                            <label htmlFor="inputPassword4">Consumer Type</label>
                                                            <Select name='Consumer_Type' value={report?.Consumer_Type?.length > 0 ? consumerTypeArray.filter((item) => report?.Consumer_Type?.includes(item?.value)) : []} isMulti options={consumerTypeArray} onChange={(e) => {
                                                                setReport({ ...report, Consumer_Type: e.map((item) => (item.value)) })
                                                            }} />
                                                        </div>
                                                        <div class="form-group" style={{ width: '100%' }}>
                                                            <label htmlFor="inputPassword4">Consumer</label>
                                                            <Select placeholder='All Consumer' value={report?.Consumer?.length > 0 ? consumerArray.filter((item) => report?.Consumer?.includes(item?.value)) : []} isMulti options={consumerArray} onChange={(e) => {
                                                                setReport({ ...report, Consumer: e.map((item) => (item.value)) })
                                                            }} />
                                                        </div>
                                                        <div className={`btn btn-primary  ${FilterSerchLoader == true ? 'btn-progress disabled' : ''}`} style={{ height: '37px' }} onClick={() => ongetGSMDataFilterDataIntegration()}><i className='fas fa-search'></i></div>
                                                        {/* <b className='col-md-6'>
                                                        Total Selected Consumer :- {ConsumerUniqIdArray?.length}
                                                        </b> */}
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <SlidingTabs onTabChange={(e) => { console.log(e) }}>
                                            <TabPane id="1" title="Activity Logs">
                                                <div className='tw-overflow-y-auto tw-pb-10 m-3' style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 10px 0px' }}>
                                                    <ActivityLogs ScheduleDetails={ScheduleDetails} />
                                                </div>
                                            </TabPane>
                                            <TabPane id="2" title="Schedule Run History">
                                             <div className='tw-overflow-y-auto tw-pb-10 m-3' style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 10px 0px' }}>
                                                            <ScheduleRunHistory credentialdetails={props?.credentialdetails} ScheduleDetails={ScheduleDetails}/>
                                             </div>
                                            </TabPane>
                                        </SlidingTabs>
                                    </div>
                                </div>}
                            </div>
                        </>}
                    </div>
                </div>}
            </>}

            {activeTab == 'Logs' && <SchedualLog TYPE={props?.credentialdetails?.TYPE} credentialdetails={props?.credentialdetails} scheduleList={logData} />}

        </>
    )
}

export default Schedulelist

const ActivityLogs = (props) => {
    const [ActivityData, setActivityData] = useState([])
    const [Loader, setLoader] = useState(false)


    const ongetActivityData = async () => {
        setLoader(true)
        const data = await getScheduleActivityLogs({ schedule_id: props?.ScheduleDetails?.UNIQUE_ID })
        if (data.success === true) {
            const groupByDate = data.data.data?.reduce((acc, log) => {
                const date = moment.utc(log.created_at).format('DD-MM-YYYY');
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(log);
                return acc;
            }, {});
            const groupedLogs = Object.keys(groupByDate).map(date => {
                return {
                    date,
                    logs: groupByDate[date]
                }
            })
            setActivityData(groupedLogs)
            setLoader(false)
        } else {
            console.log('something want wrong')
            setLoader(false)
        }
    }

    useEffect(() => {
        ongetActivityData()
    }, [props?.ScheduleDetails])
    return (
        <>
            {Loader ? <div className="example">
                <Spin size="large" />
            </div> : <>
                <div className="row tw-overflow-y-auto tw-w-[100%]">
                    <div className="timeline-card">

                        {ActivityData.length > 0 && ActivityData.map((logP, index) => (
                            <div className="timeline">
                                <div className="date-section" key={index}>
                                    <div className="date">{logP.date}</div>
                                </div>
                                {logP?.logs.map((log, index) => (
                                    <div className="timeline-item" key={index}>
                                        <div className="timeline-icon">{logP?.logs.length - index}</div>
                                        <div className="timeline-data">
                                            <div className="activity-log-avatar">
                                                <span>{log?.created_by[0]}</span>
                                            </div>
                                            <div className="timeline-content">
                                                <div className="activity-log-activity">
                                                    <p className="activity-log-activity-creator">{log?.created_by} has </p>
                                                    <p className="activity-log-activity-type">{log?.activity_type}</p>
                                                    <p className="activity-log-activity-static">Updated <b>({Object.keys(JSON.parse(log?.new_changed_json || '{}')).join(', ')})</b></p>
                                                    <p className="activity-log-activity-column">{log?.updated_columns && log?.updated_columns?.length > 0 ? log?.updated_columns?.join(", ") : ""}</p>
                                                </div>
                                                <div className="activity-log-changes">
                                                    <div key={index}>
                                                        <pre style={{ width: '300px' }}>{JSON.stringify(JSON.parse(log?.old_json || '{}'), null, 2)}</pre>
                                                        <span className="arrow">→</span>
                                                        <pre style={{ width: '300px' }}>{JSON.stringify(JSON.parse(log?.new_changed_json || '{}'), null, 2)}</pre>
                                                    </div>
                                                </div>

                                                <p className="activity-log-date">at {moment.utc(log.created_at).format('hh:mm:ss A')}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                {ActivityData.length == 0 && <h4 className='tw-text-center mt-2'>No Activity</h4>}
            </>}
        </>
    )
}

const ScheduleRunHistory=(props)=>{
    const [LogDetails, setLogDetails] = useState([])
    const [LogModal, setLogModal] = useState(false)
    const [LogLoader, setLogLoader] = useState(false)
    const onHandaleScheduleLogs = async (id, title) => {
        setLogModal(true)
        setLogLoader(true)
        const data = await getScheduleLogHistory({ tableName: JSON.parse(props?.credentialdetails?.TABLE_JSON || '{}')?.schedule_log, SCHEDULE_ID: props?.ScheduleDetails?.UNIQUE_ID })
        if (data.success == true) {
            setLogDetails(data?.data?.data)
            setLogLoader(false)

        } else {
            setLogLoader(false)

        }
    }

    useEffect(()=>{
        onHandaleScheduleLogs()
    },[props?.credentialdetails])
    return (
        <>
         {LogLoader ? <div className="example">
                <Spin size="large" />
            </div> : <><div className=''>
                    <table class="table table-striped border tw-text-xs tw-w-full tw-text-center ">
                        <thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100'>
                            <tr>
                                <th>SRNO</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {LogDetails.length > 0 && LogDetails.map((info, id) => (
                                <tr>
                                    <td>{info?.SRNO}</td>
                                    <td>{moment.utc(info?.CREATED_AT).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                    <td>{moment.utc(info?.UPDATED_TIME).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                    <td>{info?.STATUS === -1 ? moment(moment.utc().add(moment().utcOffset(), "m").format()).diff(moment(moment.utc(info?.CREATED_AT).format()), 'minutes') > 5 ? <span class="badge badge-secondary">Time Out</span> : <span class="badge badge-primary">Process Start</span> : info?.STATUS === 2 ? <span class="badge badge-danger">Failed</span> : info?.STATUS === 3 ? <span class="badge badge-secondary">Time Out</span> : <span class="badge badge-success">Success</span>}</td>

                                </tr>
                            ))}

                        </tbody>

                    </table>
                </div></>}
        </>
    )
}