import React, { useEffect, useState } from 'react'
import { getMSSQLlogs,getlogsDataByColumnName } from '../../../Commoncomponet/Utils'
import moment from 'moment'
import { Modal,Spin } from 'antd'
import Pagination from '../../../Commoncomponet/Pagination'
import GoogleLoader from '../../../Commoncomponet/Loaders/GoogleLoader'
import { useParams } from 'react-router-dom'
import { BootstrapTooltip, exportExcelDownload } from '../../../Commoncomponet/common'
import { object } from 'prop-types'

const IntegrationLog = (props) => {
    const [logData, setLogData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isConsumerInfo, setIsConsumerInfo] = useState(false)
    const [isDuplicateInfo, setIsDuplicateInfo] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [isNullData, setIsNullData] = useState(false)
    const [consumerInfoModalData, setConsumerInfoModalData] = useState([])
    const [duplicateInfoModalData, setDuplicateInfoModalData] = useState([])
    const [API_SEND_DATAModal, setAPI_SEND_DATAModal] = useState(false)
    const [ApiSendData, setApiSendData] = useState('{}')

    const [InfoModalData, setInfoModalData] = useState({})
    const [nullDataInfoModalData, setNullDataInfoModalData] = useState([])
    const [numberofPage, setnumberofPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [Loader1, setLoader1] = useState(false);
    const [Loader2, setLoader2] = useState(false);
    const [Loader3, setLoader3] = useState(false);
    const [Loader4, setLoader4] = useState(false);
    const [popDetails, setpopDetails] = useState({});

    const params = useParams();

	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setIsLoading(true)
        const firstPageIndex = (currentPage - 1) * PageSize;
        const repData = await getMSSQLlogs({Roles:userInfo?.ROLE,userId:userInfo?.UNIQUE_ID, start: firstPageIndex, end: PageSize,tableName:params?.MSID+'_logs' })
        if (repData.success === true) {
            console.log(repData.data.LogDetails)
            setLogData(repData.data.LogDetails)
            setnumberofPage(repData.data.count)
            setIsLoading(false)
        }
    }

    const refreshClick = () => {
        setCurrentPage(1)
        fetchData()
    }

    useEffect(() => {
        fetchData()
    }, [currentPage, PageSize]);

    const onConsumerInfoClick = async(SRNO,info) => {
        setpopDetails(info)
        setIsConsumerInfo(true)
        setLoader1(true)
        const data=await getlogsDataByColumnName({col:'CONSUMER_INFO',tableName:params?.MSID+'_logs',SRNO:SRNO})
        if(data.success==true){
        setConsumerInfoModalData(JSON.parse(data?.data?.data[0]?.LOGS_INFO||'[]'))
        setLoader1(false)
        }else{
            setLoader1(false)

        }

    }

    const onDuplicatevalueClick = async(SRNO,info) => {
        setpopDetails(info)
        setLoader2(true)
        setIsDuplicateInfo(true)
        const data=await getlogsDataByColumnName({col:'RESPONSE_JSON',tableName:params?.MSID+'_logs',SRNO:SRNO})
        if(data.success==true){
        setDuplicateInfoModalData(data?.data?.data[0]?.LOGS_INFO)
        setLoader2(false)
    }else{
        setLoader2(false)
    }
    }
const onAPISendDataClick = async(SRNO,info) => {
    setpopDetails(info)
        setAPI_SEND_DATAModal(true)
        setLoader3(true)
    const data=await getlogsDataByColumnName({col:'API_SEND_DATA',tableName:params?.MSID+'_logs',SRNO:SRNO})
        if(data.success==true){
        setApiSendData(data?.data?.data[0]?.LOGS_INFO)
        setLoader3(false)

    }else{
        setLoader3(false)
    }

    }
    const onNullDataFoundClick = async(SRNO,info) => {
        setpopDetails(info)
        setIsNullData(true)
        setLoader4(true)
        const data=await getlogsDataByColumnName({col:'NULL_DATA_FOUND',tableName:params?.MSID+'_logs',SRNO:SRNO})
        if(data.success==true){
        setNullDataInfoModalData(JSON.parse(data?.data?.data[0]?.LOGS_INFO||'[]'))
    }else{
        setLoader4(false)

    }
    setLoader4(false)

    }
    const onInfoClick = (info,data) => {
        setpopDetails(data)
        setInfoModalData(info)
        setIsInfo(true)

    }

    const Download_Logs=()=>{
        exportExcelDownload(consumerInfoModalData.map((item)=>({UNIQUE_ID:item?.UNIQUE_ID,INSTALLATION_NUMBER:item?.INSTALLATION_NUMBER,DEVICE_SERIAL_NO:item?.DEVICE_SERIAL_NO,address_compony_name:item?.address_compony_name,SCRIPTDECODE_ID_FOUND:item?.SCRIPTDECODE_ID_FOUND,DAILY_TABLE_EXIST:item?.DAILY_TABLE_EXIST,DAILY_DATA_FOUND:item?.DAILY_DATA_FOUND,INSERT_SUCCESS:item?.INSERT_SUCCESS,RESPONSE_CODE:item?.RESPONSE_CODE})),'CONSUMER_INFO_'+(new Date().valueOf()))
    }
    const Download_send_data_Logs=()=>{
        exportExcelDownload(JSON.parse(ApiSendData)?.row,'API_SEND_DATA_'+(new Date().valueOf()))
    }
    return (
        <>
            <Modal title={<div><b>SRNO </b>: {popDetails?.SRNO}<br/><b>Title : </b>CONSUMER WISE INFO <br/><b>Download : </b><span className='btn btn-primary text-white ml-2' onClick={Download_Logs}><i className='fas fa-download'></i></span></div>} visible={isConsumerInfo} maskClosable={false} footer='' onCancel={() => {setIsConsumerInfo(false)
            setConsumerInfoModalData([])}} width={1500}>
                {Loader1==true&&<div className="example">
											<Spin size="large" />
										</div>}
                    {Loader1==false?
<div className='table-responsive tw-h-screen tw-max-h-screen'>
                    <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                        <thead className='tw-sticky tw-z-50 tw-top-0 tw-text-xs tw-bg-gray-100'>
                            <tr>
                                <th>UNIQUE_ID</th>
                                {props?.TYPE=='API'? <th>INSTALLATION_NUMBER</th>:''}
                                <th>DEVICE_SERIAL_NO</th>
                                <th>address_compony_name</th>
                                <th>SCRIPTDECODE_ID_FOUND</th>
                                <th>DAILY_TABLE_EXIST</th>
                                <th>DAILY_DATA_FOUND</th>
                                {/* <th>INSERT_SUCCESS</th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {consumerInfoModalData.length > 0 ? consumerInfoModalData.map(info => (
                                <tr>
                                    <td>{info?.UNIQUE_ID}</td>
                                    {props?.TYPE=='API'?<td>{info?.INSTALLATION_NUMBER}</td>:''}
                                    <td>{info?.DEVICE_SERIAL_NO}</td>
                                    <td>{info?.address_compony_name}</td>
                                    <td>{info?.SCRIPTDECODE_ID_FOUND !== undefined ? info?.SCRIPTDECODE_ID_FOUND === true ? <span className="badge badge-success">&#10004;</span> : <span className="badge badge-danger">&#x2716;</span> : '-'}</td>
                                    <td>{info?.DAILY_TABLE_EXIST !== undefined ? info?.DAILY_TABLE_EXIST === true ? <span className="badge badge-success">&#10004;</span> : <span className="badge badge-danger">&#x2716;</span> : '-'}</td>
                                    <td>{info?.DAILY_DATA_FOUND !== undefined ? info?.DAILY_DATA_FOUND === true ? <span className="badge badge-success">&#10004;</span> : <span className="badge badge-danger">&#x2716;</span> : '-'}</td>
                                    {/* <td>{info?.INSERT_SUCCESS !== undefined ? info?.INSERT_SUCCESS === true ? <span className="badge badge-success"><i className='fas fa-check '></i></span> : <span className="badge badge-danger"><i className="fas fa-times " /></span> : '-'}</td> */}
                                </tr>
                            )):<h4 className="text-center">No Data Found</h4>}

                        </tbody>

                    </table>
                </div>:''}
            </Modal>
            <Modal title={<div><b>SRNO </b>: {popDetails?.SRNO}<br/><b>Title : </b>API RESPONSE<br/></div>} maskClosable={false} visible={isDuplicateInfo} footer='' onCancel={() => {setIsDuplicateInfo(false)
            setDuplicateInfoModalData({})}} width={1500}>
              {Loader2==true?<div className="example">
											<Spin size="large" />
										</div>:duplicateInfoModalData}
            </Modal>
            <Modal title={<div><b>SRNO </b>: {popDetails?.SRNO}<br/><b>Title : </b>API SEND DETAILS <br/><b>Download : </b>{JSON.parse(ApiSendData)?.row?.length>0?<span className='btn btn-primary text-white ml-2' onClick={Download_send_data_Logs}><i className='fas fa-download'></i></span>:''}</div>} maskClosable={false} visible={API_SEND_DATAModal} footer='' onCancel={() => {setAPI_SEND_DATAModal(false)
            setApiSendData('{}')}} width={1500}>
               {Loader3==true?<div className="example">
											<Spin size="large" />
										</div>:ApiSendData}
            </Modal>
            <Modal title={<div><b>SRNO </b>: {popDetails?.SRNO}<br/><b>Title : </b>API USER REQUEST INFO<br/></div>} maskClosable={false} visible={isInfo} footer='' onCancel={() => setIsInfo(false)} width={1500}>
                <div className='table-responsive tw-h-screen tw-max-h-screen'>
                    <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                        <thead className='tw-sticky tw-z-50 tw-top-0 tw-text-xs tw-bg-gray-100'>
                            <tr>
                                <th>start_date</th>
                                <th>end_date</th>
                                <th>MSID</th>
                                <th>User_ID</th>
                                <th>Browser_Details</th>
                                <th>Category0</th>
                                <th>Category1</th>
                                <th>Category2</th>
                                <th>Consumer_Unique_ID</th>
                                <th>roles_id</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{InfoModalData?.start_date}</td>
                                <td>{InfoModalData?.end_date}</td>
                                <td>{InfoModalData?.MSID}</td>
                                <td>{InfoModalData?.User_ID}</td>
                                <td>{JSON.stringify(InfoModalData?.Browser_Details)}</td>
                                <td>{InfoModalData?.Category0}</td>
                                <td>{InfoModalData?.Category1}</td>
                                <td>{InfoModalData?.Category2}</td>
                                <td className='tw-max-w-lg'>{InfoModalData?.Consumer_Unique_ID?.join(",")}</td>
                                <td>{InfoModalData?.roles_id}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </Modal>
            <Modal title={<div><b>SRNO </b>: {popDetails?.SRNO}<br/><b>Title : </b>NULL DATA FOUND<br/></div>} maskClosable={false} visible={isNullData} footer='' onCancel={() => setIsNullData(false)} width={1500}>
            {Loader4==true?<div className="example">
											<Spin size="large" />
										</div>:  <pre> {JSON.stringify(nullDataInfoModalData, null, 2)}</pre>}
            </Modal>
            <div class=" tw-w-full">
                <div class="card-header">
                    <h4 class="tw-w-full  "><BootstrapTooltip title={`Table Name is ${params?.MSID+'_logs'}`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
                    <div class="card-header-action">
                    
                        <div id="filtergrm">
                        
                            <button class="btn btn-info text-white ml-2" onClick={refreshClick} ><i class="fa fa-refresh" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-body">

                    {isLoading ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                        <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                    </div> :
                        <div class="table-responsive tw-h-screen tw-max-h-screen">
                            <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                <table class="table table-striped tw-text-xs tw-w-full tw-text-center">
                                    <thead className='tw-sticky tw-z-50 tw-top-0 tw-text-xs tw-bg-gray-100'>
                                        <tr>
                                            <th>SRNO</th>
                                            {/* <th>INSTALLATION_NUMBER</th> */}
                                            <th style={{ width:'100px' }}>CREATED_AT</th>
                                            <th>CREATED_BY</th>
                                            {props?.TYPE=='API'?<th>RESPONSE STATUS</th>:<th>RESPONSE STATUS</th>}
                                            {props?.TYPE=='API'?<th>API RESPONSE CODE</th>:''}
                                            <th>CONSUMER WISE INFO</th>
                                            {/* <th>DUPLICATE_VALUE</th> */}
                                            {props?.TYPE=='API'? <th>API USER REQUEST INFO</th>:<th>INFO</th>}
                                            <th style={{ width:'100px' }}>UPLOADED TIME</th>
                                            <th>NULL_DATA_FOUND</th>
                                            {props?.TYPE=='API'?  <th>API SEND DETAILS</th>:''}
                                           {props?.TYPE=='API'?  <th>API RESPONSE DETAILS</th>:''}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logData.map(row => (
                                            <tr key={row.SR_NO} className={`${row?.SRNO==popDetails?.SRNO?'tw-border-b !tw-border-gray-300 !tw-bg-indigo-100':''}`}>
                                                <td className={`tw-px-1 tw-py-3`}>{row?.SRNO}</td>
                                                {/* <td className='tw-px-1 tw-py-3'>{row?.INSTALLATION_NUMBER}</td> */}
                                                <td className='tw-px-1 tw-py-3'>{moment.utc(row?.CREATED_AT).format("DD-MM-YYYY hh:mm:ss A")}</td>
                                                <td className='tw-px-1 tw-py-3'>{row?.CREATED_BY}</td>
                                                <td className='tw-px-1 tw-py-3'>{row?.STATUS === -1 ?<span class="badge badge-primary">Process Start</span> : row?.STATUS === 2 ? <><span class="badge badge-danger">Fail</span></> :row?.STATUS === 3 ? <span class="badge badge-secondary">Time Out</span>:row?.RESPONSE_STATUS==202?<span class="badge badge-success">Success</span>:<div><span class="badge badge-danger">Fail</span></div>}</td>
                                                {props?.TYPE=='API'?<td className='tw-px-1 tw-py-3'>{row?.RESPONSE_STATUS?row?.RESPONSE_STATUS:'-'}</td>:''}
                                                <td className='tw-px-1 tw-py-3'>{row?.STATUS === -1 ? '-' : <button className='btn btn-info btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onConsumerInfoClick(row?.SRNO,row)}><i className="fa-solid fa-info"></i></button>}</td>
                                                {/* <td className='tw-px-1 tw-py-3'>{row?.STATUS === -1 ? '-' :<button className='btn btn-warning btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onDuplicatevalueClick(JSON.parse(row?.DUPLICATE_VALUE))}><i className="fa-solid fa-info"></i></button>}</td> */}
                                                <td className='tw-px-1 tw-py-3'>{<button className='btn btn-primary btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onInfoClick(JSON.parse(row?.INFO),row)}><i className="fa-solid fa-info"></i></button>}</td>
                                                <td className='tw-px-1 tw-py-3'>{row?.UPDATED_TIME ? moment.utc(row?.UPDATED_TIME).format("DD-MM-YYYY hh:mm:ss A") : '-'}</td>
                                                <td className='tw-px-1 tw-py-3'>{<button className='btn btn-danger btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onNullDataFoundClick(row?.SRNO,row)}><i className="fa-solid fa-info"></i></button> }</td>
                                                {props?.TYPE=='API'?  <td className='tw-px-1 tw-py-3'>{ <button className='btn btn-warning  btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onAPISendDataClick(row?.SRNO,row)}><i className="fa-solid fa-info"></i></button> }</td>:''}
                                                {props?.TYPE=='API'?  <td className='tw-px-1 tw-py-3'>{ <button className='btn btn-warning  btn-sm !tw-px-3 hover:tw-text-white' onClick={() => onDuplicatevalueClick(row?.SRNO,row)}><i className="fa-solid fa-info"></i></button>}</td>:''}
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                                <>
                                    <div className="d-flex justify-content-between">
                                        <div>Total Number Of Row {numberofPage}</div>
                                        <div class="dataTables_length" id="table_id_length">
                                            <label>
                                                Show{" "}
                                                <select
                                                    name="table_id_length"
                                                    aria-controls="table_id"
                                                    class=""
                                                    onChange={(e) => setPageSize(e.target.value)}
                                                    value={PageSize}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>{" "}
                                                entries
                                            </label>
                                        </div>

                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={numberofPage || 1}
                                            pageSize={PageSize}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                </>
                            </div>

                        </div>}
                </div>
            </div>


        </>
    )
}

export default IntegrationLog