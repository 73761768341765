import { DatePicker, Modal, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import Pagination from '../../../../Commoncomponet/Pagination';
import { geModemIdWiseColumnName, getHeardbeatData, getLoraTableWiseData, getScriptCodeIdWiseColumnName, getStartAndEndDateByTable, reportCreacteInTableName } from '../../../../Commoncomponet/Utils';
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import { BootstrapTooltip, exportCSVFile, exportExcelDownload } from '../../../../Commoncomponet/common';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';


export default function Alert(props) {

    const [PageSize, setPageSize] = useState(50);
	const [numberofPage, setnumberofPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [loader, setLoader] = useState(false);
	const [heartData, setHeartData] = useState([])
    const [ColumnName,setColumnName]=useState({})
	const [sortField,setsortField]=useState('SRNO')
	const [sortDirection,setsortDirection]=useState('desc')
    const ongetHeardbeatData = async () => {
		setLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		const data = await getLoraTableWiseData({ start: firstPageIndex, end: PageSize }, { tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.alertdata,changeCol:true,colName:sortField,sortDirection:sortDirection })
		if (data.success === true) {
			setHeartData(data.data.data)
			setnumberofPage(data.data.total)
			setLoader(false)
		} else {
			console.log('something want wrong')
			setLoader(false)

		}
	}
	const ongetScriptCodeIdWiseColumnName=async()=>{
		
		const data=await geModemIdWiseColumnName(props?.GSMdata?.MODEM_TYPE)
        if(data.success===true){
            if(data?.data?.data?.length>0){
            setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
            }
        }else{
console.log('something want wrong');
	}
    }
    useEffect(()=>{
        ongetHeardbeatData()
		ongetScriptCodeIdWiseColumnName()
    },[])

	useEffect(() => {
		ongetHeardbeatData();
	  }, [currentPage, PageSize]);

	  const [report, setReport] = useState({ value: '', name: '', start_date: '', end_date: '' })
	  const [reportType, setReportType] = useState('')
	  const [ismodel, setModel] = useState(false)
	  const [ReportLoader,setReportLoader]=useState(false)
	  const ReportType=[{label:'View Table Info',value:'View_Table_Info'},{label:'All Table Info',value:'All_Table_Info'},{label:'Full Table',value:'Full_Table'}]
	  const [ExpireDate,setExpireDate]=useState({})
	const ongetStartAndEndDateByTable = async (tableName, col) => {
		console.log("Wanted to check if this is calling or not")
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.alertdata, col: 'ENTRYTS' })
		if (data.success === true) {

			console.log("Expiry Dates == ", data.data)
			setExpireDate(data.data)
			setModel(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}
	  const onHandalReport = (e) => {
		setReport({ ...report, value: e.target.value, name: 'All GSM Consumer Details' })
	  }
		const ongetGenretedAllGSMReport=async(Iscsv)=>{
			if(reportType!=''&&report.value!==''){
				if(report?.value!='Full_Table'){
					if(report?.start_date&&report?.end_date){
						const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
			const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
			console.log(x>y,x,y);
						if(x>y){
						return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
						}
					}else{
						return	toast.warn('Please Select "Start Date" And "End Date"')
					}
				}
			setReportLoader(true)
			const data=await reportCreacteInTableName({tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.alertdata,col:'ENTRYTS', start_date: moment(report.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"), end_date: moment(report.end_date, "DD-MM-YYYY").format("YYYY-MM-DD"),IsCondition:report?.value=='Full_Table'?false:true})
			if(data.success==true){
		  var arrayofData=[]
		  var headers={}
		   
		  if(data.data.data.length==0){
		setReportLoader(false)
		toast.warn('Data Not Found')
		  }
		  var TypeReport=report?.value
			data.data.data.map((item,id)=>{
			  arrayofData[id]={}
			  ColumnName?.Modem_Data?.alertdata?.length>0&&ColumnName?.Modem_Data?.alertdata?.map((col)=>{
				if(TypeReport=='View_Table_Info'){
				if(col?.Display=="Yes"){
				  if(id==0){headers[col.Header]=col.Header}
				  if(col?.Type=='datetime')
				  {
					arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
				  }else{
					if(col?.Float){
					  arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
					}else{
					arrayofData[id][col.Header]=item[col.Column]
					}
				  }
				}
			  }else if(report?.value=='All_Table_Info'||report?.value=='Full_Table'){
				if(id==0){headers[col.Header]=col.Header}
				if(col?.Type=='datetime')
				  {
					arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
				  }else{
					if(col?.Float){
					  arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
					}else{
					arrayofData[id][col.Header]=item[col.Column]
					}
				  }
			  }
				
			  })
			  if(id==(data.data.data.length-1)){
				if(reportType==='csv'){
					if(report?.value!='Full_Table'){
				  exportCSVFile(headers,arrayofData,`alertdata_`+'('+report.start_date+')'+'_'+'('+report.end_date+')'+`_${new Date().getTime()}`)
					}else{
						exportCSVFile(headers,arrayofData,`alertdata_${new Date().getTime()}`)
					}
		setReportLoader(false)
	
				 }else{
					if(report?.value!='Full_Table'){
				  exportExcelDownload(arrayofData,`alertdata_`+'('+report.start_date+')'+'_'+'('+report.end_date+')'+`_${new Date().getTime()}`)
					}else{
						exportExcelDownload(arrayofData,`alertdata_${new Date().getTime()}`)
					}
		setReportLoader(false)
	
				 }
				}
		  })
		  
				
			}else{
				console.log('something want wrong')
				setReportLoader(false)
			}
	  }else{
		return	toast.warn('Please Enter Mandatory Fields')
	}
		}
		const onSort = (field) => {
			if (sortField === field) {
				setsortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
			} else {
				setsortField(field);
				setsortDirection('asc');
			}
		};
	  
	  useEffect(()=>{
		ongetHeardbeatData()
	  },[sortDirection,sortField])
    
  return (
    <>
	<Modal title="Generate Reports" visible={ismodel} footer='' onCancel={()=>setModel(false)} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={onHandalReport}>
												<option value="">Report Type</option>
												{ReportType?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div>
										{report?.value!=''&&report?.value!='Full_Table'?<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
												}} />
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
													}} />
											</div></>:''}
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLS</option>
												<option value="csv">CSV</option>
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader==true?'disabled btn-progress':''}`} value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
										<Link className="btn btn-light text-dark" onClick={()=>setModel(false)}>Cancel</Link>
									</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
        <section className="card-diff-section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4><BootstrapTooltip title={`Table Name is ${props?.GSMdata?.UNIQUE_TABLE_NAME?.alertdata}`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
									<div className="card-header-action">
										<div id="filtergrm">
											
											<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh"  onClick={ongetHeardbeatData}>
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a>
											<div class="d-inline">
                      <button class="btn btn-info" onClick={()=>ongetStartAndEndDateByTable()}>
                      <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
										</div>
									</div>
								</div>
								<div className="card-body">
									{loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)}
									{heartData.length === 0 && !loader && <h4 className="text-center">No Data Found</h4>}
									{heartData.length > 0 &&
										<div className="table-responsive tw-h-screen tw-max-h-screen">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												
												<table id="table_id" className="table border table-borderless table-striped font-10">
													<thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100'>
														<tr className="tw-border-b">
														{ ColumnName?.Modem_Data?.alertdata?.length>0&&ColumnName?.Modem_Data?.alertdata?.map((col)=>(col?.Display=="Yes"?<BootstrapTooltip title={col?.Column}><th onClick={()=>onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'>
                                        <div >{col?.Header}</div>
										{col?.Notes&&<BootstrapTooltip title={col?.Notes} placement='top'><span><i className="ml-2 fas fa-info-circle tw-text-xs"></i></span></BootstrapTooltip>}
                                        <div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                    </div></th></BootstrapTooltip>:''))}
														</tr>
													</thead>
													<tbody >
														
																{heartData.length > 0 && heartData.map((item, id) => (
															<tr className="trheigth  ">
															{ ColumnName?.Modem_Data?.alertdata?.length>0&&ColumnName?.Modem_Data?.alertdata
															?.map((col)=>(col?.Display=="Yes"?col?.Type=='datetime'?
															<td style={{ minWidth: "100px" }}>{item[col.Column]?
															moment.utc(item[col.Column]).format("DD/MM/YYYY hh:mm:ss A")
															:''}</td>:
															col?.Float==true?col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]/col?.DivisionNumber).toFixed(col?.Float)}</td>:<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{col?.Float&&item[col.Column]?item[col.Column].toFixed(col?.Float):item[col.Column]}</td>:
															col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]/col?.DivisionNumber}</td>:
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]}</td>
															:''))}
																{/* <td>{moment.utc(item.ENTRYTS).format("DD/MM/YYYY hh:mm:ss A")}</td> */}
																
															</tr>
														))}
                                                            
															{/* <td><div style={{fontSize:'10px'}} className={`badge ${item?.DEVICE_STATUS === 'online' ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{heartData[id+1]?.ENTRY_TS?moment.duration(moment(heartData[id+1]?.ENTRY_TS).diff(moment(heartData?.ENTRY_TS),'minutes')).minutes()+' minutes ago':''}</div></td> */}
															
															
													</tbody>
												</table>
												<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
											</div>
										</div>}

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
    </>
  )
}
