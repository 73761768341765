import React, { useState } from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';
import { toast } from 'react-toastify';
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });
  const [currentSerchPage, setCurrentSerchPage] = useState(1);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  function keyHandler(e) {
    if (e.key === "Enter") {
      const pn = parseInt(currentSerchPage);
      const totalPage = Math.ceil(totalCount/pageSize)
      if (pn <= totalPage && pn !== 0) {
        onPageChange(pn)
      } else {
        toast.error(`Page Not Exist....!`);
        setCurrentSerchPage(currentPage)
      }
    }
  }

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
    <div className='tw-flex  tw-gap-x-2' >
        <label className='tw-mb-0' htmlFor='pa' >Enter Page :</label>
        <input type='text' value={currentSerchPage} onChange={(e) => setCurrentSerchPage(e.target.value)} onKeyDown={keyHandler} id="pa" style={{ border: "1px solid" }} className='tw-w-1/5 tw-border-gray-200 tw-rounded-md focus:tw-outline-none tw-px-2 tw-h-6 focus:tw-border-gray-300  ' ></input>
      </div>
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
    </>
  );
};

export default Pagination;
