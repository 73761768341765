import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./MultiInput.css";

const MultiInput = ({
    placeholder,
    onChange,
    onEnter,
    onClear,
    value,
    className,
    renderItem,
    maxItems,
    itemValidator,
    onClearAll,
    inputComponent: InputComponent,
    clearButtonComponent: ClearButtonComponent,
    onHandalSerch
}) => {
    const [inputs, setInputs] = useState(value || []);
    const [warning, setWarning] = useState(null);

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "Enter") {
                const inputValue = event.target.value.trim();

                if (inputValue && (!maxItems || inputs.length < maxItems)) {
                    if (itemValidator && !itemValidator(inputValue)) {
                        setWarning("Invalid input");
                        return;
                    }

                    const newInputs = [...inputs, inputValue];
                    setInputs(newInputs);
                    onEnter && onEnter(inputValue);
                    onChange && onChange(newInputs);
                }else{
                    onHandalSerch()
                }
                event.target.value = "";
            }
        },
        [inputs, maxItems, itemValidator, onChange, onEnter]
    );

    const handleClear = useCallback(
        (index) => {
            const newInputs = [...inputs];
            newInputs.splice(index, 1);
            setInputs(newInputs);
            onClear && onClear(index);
        },
        [inputs, onClear]
    );

    const handleClearAll = useCallback(() => {
        setInputs([]);
        onClear && onClear();
        onClearAll && onClearAll();
    }, [onClearAll]);

    const handleInput = useCallback((event) => {
        const inputValue = event.target.value.trim();

        if (itemValidator && !itemValidator(inputValue)) {
            setWarning("Invalid characters are not allowed.");
        } else {
            setWarning(null);
        }
    }, [itemValidator]);

    const renderInputItems = useMemo(() => {
        return inputs.map((input, index) => {
            const item = renderItem ? renderItem(input) : input;
            return (
                <div className="multi-input__item" key={index}>
                    <span className="multi-input__item-value">{item}</span>
                    {ClearButtonComponent ? (
                        <ClearButtonComponent onClick={() => handleClear(index)} />
                    ) : (
                        <button
                            className="multi-input__item-clear !tw-border-none"
                            onClick={() => handleClear(index)}
                            aria-label="Clear item"
                        >
                            &times;
                        </button>
                    )}
                </div>
            );
        });
    }, [inputs, renderItem, handleClear, ClearButtonComponent]);

    return (
        <div className={classNames("multi-input", className)}>
            <div className="multi-input__items">
                <div className="tw-w-full tw-flex tw-flex-wrap">
                    {renderInputItems}
                        {InputComponent ? (
                            <InputComponent
                                className="multi-input__input"
                                placeholder={placeholder}
                                onKeyDown={handleKeyDown}
                                onInput={handleInput}
                                aria-label="Multi Input"
                            />
                        ) : (
                            <input
                                className="multi-input__input"
                                placeholder={placeholder}
                                onKeyDown={handleKeyDown}
                                onInput={handleInput}
                                aria-label="Multi Input"
                                style={{border:0}}
                            />
                        )}
                        {warning && (
                            <div className="multi-input__warning-tooltip">{warning}</div>
                        )}
                </div>

                {inputs.length > 0 && (
                    <button
                        className="multi-input__clear-all !tw-border-none"
                        onClick={handleClearAll}
                        aria-label="Clear all"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /> </svg>
                    </button>
                )}
            </div>
        </div>
    );
};

MultiInput.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onEnter: PropTypes.func,
    onClear: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    renderItem: PropTypes.func,
    maxItems: PropTypes.number,
    itemValidator: PropTypes.func,
    onClearAll: PropTypes.func,
    inputComponent: PropTypes.elementType,
    clearButtonComponent: PropTypes.elementType,
};

MultiInput.defaultProps = {
    placeholder: "Enter value",
    onEnter: () => { },
    onClear: () => { },
    value: [],
    className: "",
    renderItem: null,
    maxItems: null,
    itemValidator: null,
    onClearAll: () => { },
    inputComponent: null,
    clearButtonComponent: null,
};

export default MultiInput;
