import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Lock } from 'react-feather'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BootstrapTooltip } from '../../../Commoncomponet/common'
import { activeandinactive, InsertFirmware, InsertGsmSmartMetercategory, Insertmeter_category, UpdateFirmware, UpdateGsmSmartMetercategory, Updatemeter_category } from '../../../Commoncomponet/Utils'
import swal from "sweetalert2";
window.Swal = swal;
export default function AddCategory() {

    const [name,setName]=useState('')
    const [FirmwareId,setFirmwareId]=useState('')
    const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
    const [flagBtn, setflegBtn] = useState(false);
   const [disable,setDisable]=useState(true)
   const [status,setStatus]=useState(null)
    const history = useHistory();
    const locations=useLocation()
    useEffect(() => {
        if (locations?.state) {
          setName(locations?.state?.name)
          setFirmwareId(locations?.state?.unique_id)
           setDisable(false)
           setStatus(locations?.state?.status)
        }
     }, [locations]);
    const onHandaleSubmitData=async(e)=>{
        e.preventDefault()
        setflegBtn(true)
      if(name===''){
        setflegBtn(false)
          toast.error('Please Enter Name')
      }else{
        if(FirmwareId===''){
        const data=await InsertGsmSmartMetercategory({name:name,add_by:userInfo?.UNIQUE_ID})
        if(data.success===true){
            toast.success('Add GSM Smart Meter Category Success')
        setflegBtn(false)
        history.push('/GSM-Smart-Meter-Driver')
        }else{
            toast.error('Something Wait Wrong')
        setflegBtn(false)

        }
    }else{
    const data2=await UpdateGsmSmartMetercategory({name:name},FirmwareId)
    if(data2.success===true){
        toast.success('Update GSM Smart Meter Category Success')
    setflegBtn(false)
    history.push('/GSM-Smart-Meter-Driver')
    }else{
        toast.error('Something Wait Wrong')
    setflegBtn(false)

    }
    }
      }
    }

    const oninactive=async()=>{
      swal.fire({
			title: "Are You Sure InActive GSM Smart Meter Category?",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'gsm_smart_meter_category',colName:'status',value:'0',matchId:'unique_id'},FirmwareId)
      if(data.success===true){
         toast.success('Success Inactive GSM Smart Meter Category')
         history.push('/GSM-Smart-Meter-Driver')
      }else{
         toast.error('something want wrong')
      }
   }
})
     }
     const onactive=async()=>{
      swal.fire({
			title: "Are You Sure GSM Smart Meter Category?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'gsm_smart_meter_category',colName:'status',value:'1',matchId:'unique_id'},FirmwareId)
      if(data.success===true){
         toast.success('Success Active GSM Smart Meter Category')
         history.push('/GSM-Smart-Meter-Driver')
      }else{
         toast.error('something want wrong')
      }
   }
})
     }
  return (
    <>
    <div className="main-content">
    <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
                            <Lock className='header-logo' style={{ height: "16px" }} /> Admin
							</Link>
						</li>
						<li className="breadcrumb-item">
						<Link to='/GSM-Smart-Meter-Driver'>
							 GSM Smart Meter Driver
						</Link>
					</li>
                    <li className="breadcrumb-item">
							<Link >GSM Smart Meter Category</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >{FirmwareId===''?'Add GSM Smart Meter Category':'Update GSM Smart Meter Category'}</Link>
						</li>
					</ol>
				</nav>
        <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>{FirmwareId===''?'Add Modem Category':'Update Modem Category'}</h4>
                           <div class="card-header-action">  
                           {FirmwareId!==''?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{FirmwareId!==''&&status==1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{FirmwareId!==''&&status==0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''}
                              <Link to="/GSM-Smart-Meter-Driver" class="btn btn-info text-white align-left">
                              Back
                              </Link>
                           </div>
                        </div>
                        <div class="card-body">
                           <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onHandaleSubmitData}>
                              <div class="form-row" style={{marginLeft:"10px"}}>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">GSM Smart Meter Category Name : <span class="text-danger">*</span></label>
                                    <input class="form-control" name="metermakename" type="text" placeholder="Enter GSM Smart Meter Category Name" required="" onChange={(e)=>(setName(e.target.value))} value={name} disabled={!disable}/>
                                    <input type="hidden" class="form-control MapLat" id="btntypeforsubmit" name="submityper"/>    
                                 </div>
                              </div>
                              <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                              <div class="text-left">
                                    <input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={FirmwareId===''?'Submit':'Update'} id="submitvn"/>
                                 <Link class={`btn btn-light text-dark ${flagBtn?'disabled':''}`} to="/GSM-Smart-Meter-Driver">Cancel</Link>  
                              </div>
                        </form></div>
                     </div>
                  </div>
               </div>
            </div>
      </section>
      </div>
    </>
  )
}
