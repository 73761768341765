import { DatePicker, Modal, Space, TimePicker } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getAccount, getAreaById, getCircleById, getDivisionById, getGaById, getGSMDataFilterDataIntegration, getProfilNameByAccountId, getRolesById, getSubdevisionById, Insert_consumer_data } from '../../../Commoncomponet/Utils'
import swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { style } from '@mui/system';
import MultiInput from '../../../Commoncomponet/MultiInput/MultiInput';
import chroma from 'chroma-js';
import IntegrationLog from './IntegrationLog';
import { toast } from 'react-toastify';
window.Swal = swal;
const Push = (props) => {
    const [report, setReport] = useState({ start_date:'',end_date:'',Consumer_Type:'',Consumer:'' })
    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" });
    const [SubdevisionData, setSubdevisionData] = useState([]);
	const [DivisionData, setDivisionData] = useState([]);
	const [CircleData, setCircleData] = useState([]);
	const [GaData, setGaData] = useState([]);
	const [AccounData, setAccountData] = useState([]);
	const [AreaData, setAreaData] = useState([]);
    const [permision, setpermision] = useState({});
    const [ConsumerData,setConsumerData]=useState([])
	const [ConsumerSearchData,setConsumerSearchData]=useState([])
	const [LoraConsumerSearchData,setLoraConsumerSearchData]=useState([])
	const [loraSearchdata,setloraSearchdata]=useState([])
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const [ConsumerUniqIdArray,setConsumerUniqIdArray]=useState([])
    const [LoraConsumerUniqIdArray,setLoraConsumerUniqIdArray]=useState([])
	const [FilterSerchLoader,setFilterSerchLoader]=useState(false)
	const [searchTerms, setSearchTerms] = useState([]);
	const [SchedualModal,setSchedualModal]=useState(false)
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
	const [activeTab, setActiveTab] = useState("Push");

    const params = useParams();
    const history = useHistory();

	const ongetGaById = async (id) => {
		const data1 = await getGaById(id);
		if (data1.success === true) {
			setGaData(data1.data.data);
		}
	};
    const onHandaleSelection = async () => {
		const data = await getAccount();
		if (data.success === true) {
			setAccountData(data.data.data);
		} else {
			console.log("somthing want wrong");
		}
	};
	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.ROLE);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({
				...obj,
				ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
				ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
				ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
				ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
				ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
				ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
				ROLES_OTHER_PERMISSIONS:JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
			});
            onHandaleSelection()
        }
    }

	const ongetCircle = async (id) => {
		const data1 = await getCircleById(id);
		if (data1.success === true) {
			setCircleData(data1.data.data);
		}
	};
	const ongetSubdevisionById = async (id) => {
		const data1 = await getSubdevisionById(id);
		if (data1.success === true) {
			setSubdevisionData(data1.data.data);
		}
	};
	const ongetDivisionById = async (id) => {
		const data3 = await getDivisionById(id);
		if (data3.success === true) {
			setDivisionData(data3.data.data);
		}
	};
	const ongetArea = async (id) => {
		const data3 = await getAreaById(id);
		if (data3.success === true) {
			setAreaData(data3.data.data);
		}
	};
	const [NameObj,setNameObj]=useState({})
	const ongetProfilNameByAccountId=async(id)=>{
		const data=await getProfilNameByAccountId({ID:id})
		if(data.success===true){
		   setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME||'{}'))
		}else{
		   console.log('something want wrong')
		}
	 }
	useEffect(() => {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
		if (Profile.account !== "") {
			ongetGaById(Profile.account);
			setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([])
		
			ongetProfilNameByAccountId(Profile.account)
			
		} else {
			setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
			setGaData([]);
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);
			if (Object.keys(permision).length > 0) {
			
			}
			setNameObj({})
		}
	}, [Profile.account]);

	useEffect(() => {
		if (Profile.ga !== "") {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
			ongetCircle(Profile.ga);
			setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);
			
		} else {
			setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
			setCircleData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			setAreaData([]);
			if (Object.keys(permision).length > 0) {
			
			}

		}
	}, [Profile.ga]);

	useEffect(() => {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
		if (Profile.circle !== "") {
			ongetDivisionById(Profile.circle);
			setProfile({ ...Profile, division: "", subdivision: "", area: "" });
			setAreaData([]);
			setSubdevisionData([]);

		} else {
			setProfile({ ...Profile, division: "", subdivision: "", area: "" });
			setAreaData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			if (Object.keys(permision).length > 0) {
			
			}

		}
	}, [Profile.circle]);

	useEffect(() => {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
		if (Profile.division !== "") {
			ongetSubdevisionById(Profile.division);
			setAreaData([]);
			setProfile({ ...Profile, subdivision: "", area: "" });
			
		} else {
			setProfile({ ...Profile, subdivision: "", area: "" });
			setSubdevisionData([]);
			setAreaData([]);
			
		}
	}, [Profile.division]);

	useEffect(() => {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
		if (Profile.subdivision !== "") {
			ongetArea(Profile.subdivision);
			setProfile({ ...Profile, area: "" });

		} else {
			setProfile({ ...Profile, area: "" });
			setAreaData([]);
			
		}
	}, [Profile.subdivision]);

	useEffect(() => {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
		if (Profile.area !== "") {
		
		} else {
			
		}
	}, [Profile.area]);

    useEffect(()=>{
        onHandaleRoles()
		
},[])

const ongetGSMDataFilterDataIntegration=async()=>{
	setFilterSerchLoader(true)
    const data=await getGSMDataFilterDataIntegration({CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY1_view:permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY5_view:permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view,CATEGORY0:Profile?.account,CATEGORY1:Profile?.ga,CATEGORY2:Profile?.circle,CATEGORY3:Profile?.division,CATEGORY4:Profile?.subdivision,CATEGORY5:Profile?.area,Roles:userInfo?.ROLE,Consumer_Type:report?.Consumer_Type,Consumer:report?.Consumer})
    if(data.success===true){
        setConsumerData(data?.data?.data)
        setConsumerSearchData(data?.data?.data)
		setloraSearchdata(data?.data?.loradata)
		setLoraConsumerSearchData(data?.data?.loradata)
        setConsumerUniqIdArray([...data?.data?.data.map((item)=>(item?.UNIQUE_ID)),...data?.data?.loradata.map((item)=>(item?.UNIQUE_ID))])
		setLoraConsumerUniqIdArray([])
	setFilterSerchLoader(false)

    }else{
		setFilterSerchLoader(false)
		setConsumerData([])
        setConsumerSearchData([])
		setLoraConsumerSearchData([])
        setConsumerUniqIdArray([])
    }
}

const onHandal=(e,id)=>{
    var arrayCon=ConsumerUniqIdArray
if(e.target.checked==true){
    console.log(e.target.checked);
    arrayCon=[...arrayCon,id]
}else{
    arrayCon=arrayCon.filter((item)=>(item!=id))
}
setConsumerUniqIdArray(arrayCon)
}
function dateDiffInDays(a, b) {
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
const onPushMssqldata=async()=>{
	var Browser_Details={}
	Browser_Details.browser=window?.navigator?.userAgent
	Browser_Details.browser_version=window?.navigator?.appVersion
	Browser_Details.screen_details=window?.screen
	// Browser_Details.screen_details=window?.navigator?.plugins

	if(report?.start_date!=''&&report?.end_date!=''&&Profile?.account!=''){
		const x = new Date(moment(report.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(report.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		const difference =dateDiffInDays(x, y)
		if (difference >30) {
			return toast.warn('Data download restricted to a maximum of 30 days. Please select a date range within the last 30 days.')
			
		}
    var obj={
        MSID:params?.MSID,
        roles_id:userInfo?.ROLE,
        Category0:Profile?.account,
         Category1:Profile?.ga,
         Category2:Profile?.circle,
         Category3:Profile?.division,
         Category4:Profile?.subdivision,
         Category5:Profile?.area,
         Consumer_Unique_ID:ConsumerUniqIdArray,
         start_date:report?.start_date,
         end_date:report?.end_date,
		 User_ID:userInfo?.UNIQUE_ID,
		 TYPE:props?.credentialdetails?.TYPE,
		 Browser_Details:Browser_Details
    }
    swal.fire({
        title: `Total ${ConsumerUniqIdArray.length} Consumer Data Push`,
        icon: "warning",
        showCancelButton: true,
     }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
    const data=await Insert_consumer_data(obj)
    if(data.success===true){
				swal.fire('', 'Proccess Start', 'success');
    }else if (data.err.response.status === 500) {
		swal.fire('', 'Script Already Running', 'error');
	}else if (data.err.response.status === 203) {
		swal.fire('', 'API Data Not Found', 'error');
	}else{
				swal.fire('', 'Server Error', 'error');
    }
}
})
	}else{
		if(report?.start_date&&report?.end_date&&report?.account){
			return toast.error('"Start Date" , "End Date" , "Account" Is Required')
		}else if(report?.start_date&&report?.end_date){
			return toast.error('"Start Date" , "End Date" Is Required')
		}else if(report?.start_date&&report?.account){
			return toast.error('"Start Date" , "Account" Is Required')
		}else if(report?.end_date&&report?.account){
			return toast.error('"End Date" , "Account" Is Required')
		}else if(report?.account){
			return toast.error('"Account" Is Required')
		}else if(report?.start_date){
			return toast.error('"Start Date" Is Required')
		}else if(report?.end_date){
			return toast.error('"End Date" Is Required')
		}
	}
}

const onHandalSearchMulti = (newSearchTerms) => {
	setSearchTerms(newSearchTerms);
	if (newSearchTerms.length > 0) {
		const filteredData = [...ConsumerSearchData].filter((item) => {
			const addressCompanyName = JSON.parse(item?.ADDRESS || "{}").address_compony_name?.toLowerCase();
			const searchDataLowerCase = newSearchTerms.map((term) => term.toLowerCase());

			return searchDataLowerCase.some((searchTerm) => {
				return (
					addressCompanyName?.includes(searchTerm) ||
					item?.BUSINESS_PARTNER_NUMBER.toLowerCase().includes(searchTerm) ||
					item?.DEVICE_SERIAL_NO.toLowerCase().includes(searchTerm) ||
					item?.IMEI.toLowerCase().includes(searchTerm) ||
					item?.INSTALLATION_NUMBER.toLowerCase().includes(searchTerm) ||
					item?.PANEL_SERIAL_NO.toLowerCase().includes(searchTerm)
				);
			});
		});

		const LorafilteredData = [...LoraConsumerSearchData].filter((item) => {
			const addressCompanyName = JSON.parse(item?.ADDRESS || "{}").address_compony_name?.toLowerCase();
			const searchDataLowerCase = newSearchTerms.map((term) => term.toLowerCase());

			return searchDataLowerCase.some((searchTerm) => {
				return (
					addressCompanyName?.includes(searchTerm) ||
					item?.BUSINESS_PARTNER_NUMBER.toLowerCase().includes(searchTerm) ||
					item?.DEVICE_SERIAL_NO.toLowerCase().includes(searchTerm) ||
					item?.IMEI.toLowerCase().includes(searchTerm) ||
					item?.INSTALLATION_NUMBER.toLowerCase().includes(searchTerm) ||
					item?.PANEL_SERIAL_NO.toLowerCase().includes(searchTerm)
				);
			});
		});
		setLoraConsumerSearchData(LorafilteredData)
		setConsumerData(filteredData);
	} else {
		console.log("clear all")
		setLoraConsumerSearchData(loraSearchdata)
		setConsumerData(ConsumerSearchData);
	}
};

const onItemCleared = (clearedIndex) => {
	// Create a new array without the cleared item
	const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
	// Update the search results based on the new search terms
	onHandalSearchMulti(newSearchTerms);
};

const onClearAllItems = () => {
	// Reset the search results to the original data
	onHandalSearchMulti([]);

};

const consumerTypeArray = [
	{ value: "Industrial", label: 'Industrial' },
	{ value: "Residential", label: 'Residential' },
	{ value: "Commercial", label: 'Commercial' },
	{ value: "DRS", label: 'DRS' },
]

const consumerArray = [
	{ value: "GSM", label: 'GSM' },
	{ value: "Lorawan", label: 'Lorawan' }
]
    return (
        <>
		<Modal title="Add Schedule" visible={SchedualModal} footer='' onCancel={() => {setSchedualModal(false)}} width={500}>
		<section className="section mt-3">
					<div className="section-body">
         
                          
						<div className="row">
							<div className="col-12">
									<div className="form-row" >
                    <div className="form-group col-md-12" id="grpeui">
											<label for="inputPassword4 ">Schedule Title :<span className='text-danger'>*</span> </label>
											<input type="text" class="form-control present_street_area" name="address_compony_name"	id="address_compony_name"	placeholder="Schedule Title"/>
										</div>
                    <div className="form-group col-md-12" id="grpeui">
											<label for="inputPassword4 ">Schedule:<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" >
												<option value="">Select Schedule</option>
												<option value="1">Daily</option>
											</select>
										</div>
										<div className="form-group col-md-12" id="grpeui">
											<label for="inputPassword4 ">Schedule Time:<span className='text-danger'>*</span> </label>
											<TimePicker format="HH:mm" name='start' style={{ width: '100%', height: '40px' }}
												showTime
												// onChange={(e, dateString)=>(setCMDDate(dateString))}
												 />
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className="btn btn-danger mr-2" value="Add" id="submitvn"/>
										<Link className="btn btn-light text-dark" onClick={()=>setSchedualModal(false)}>Cancel</Link>
									</div>
                  </div>
							</div>
						</div>

				</section>
            </Modal>
			
			<div class="row ml-2 border-top py-2">
									<div class="d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
										<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
											<li>
												<button
													style={{
														backgroundColor: activeTab === "Push" ? chroma(btnColor[0]).alpha(0.4).css() : '',
														color:activeTab === "Push" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
														borderBottom: `1px solid ${btnColor[0]}`,
														boxShadow: activeTab === "Push" ? `0 2px 8px -3px ${btnColor[0]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Push" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													data-toggle="tab"
													href="#home"
													role="tab"
													aria-controls="home"
													aria-selected="true"
													onClick={() => setActiveTab("Push")}
												>
													Push
												</button>
											</li>
											<li>
												<button
													style={{
														backgroundColor: activeTab === "Logs" ? chroma(btnColor[2]).alpha(0.4).css() : '',
														color:activeTab === "Logs" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
														borderBottom: `1px solid ${btnColor[2]}`,
														boxShadow: activeTab === "Logs" ? `0 2px 8px -3px ${btnColor[2]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Logs" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													id="profile-tab"
													data-toggle="tab"
													href="#profile"
													role="tab"
													aria-controls="profile"
													aria-selected="false"
													onClick={() => setActiveTab("Logs")}
												>
													Logs
												</button>
											</li>
										</ul>
									</div>
								</div>
								
								{activeTab=='Push'&&<><div className="row">
                    <div className="col-12">
                        <div className="">
                            <div className="card-body ">
                                <div className="form-row" >
								<div className="form-group col-md-3" id="strdate">
                                    <label for="inputPassword4">Select Date<span class="text-danger">*</span> : </label><br></br>
									<DatePicker onChange={(e,dateString)=>setReport({ ...report, start_date: dateString})} format='DD-MM-YYYY' name='start'  style={{ width:'100%',height:'40px' }}
           disabledDate={(current) => {
              return current.isAfter(moment())
            }} />
                                 </div>
                                {/* <div className="form-group col-md-3" id="strdate">
                                    <label for="inputPassword4">Start Date<span class="text-danger">*</span> : </label><br></br>
									<DatePicker onChange={(e,dateString)=>setReport({ ...report, start_date: dateString})} format='DD-MM-YYYY' name='start'  style={{ width:'100%',height:'40px' }}
           disabledDate={(current) => {
              return current.isAfter(moment())
            }} />
                                 </div>*/}
                                 <div className="form-group col-md-3" id="strdate">
                                    <label for="inputPassword4">End Date<span class="text-danger">*</span> : </label><br></br>
									<DatePicker onChange={(e,dateString)=>setReport({ ...report, end_date: dateString})} format='DD-MM-YYYY' name='start'  style={{ width:'100%',height:'40px' }}
           disabledDate={(current) => {
              return current.isAfter(moment())
            }} />
                                 </div> 
                                </div>
                                <div class="row">
								<div class="col-12">
											<div
												class="form-row "
												style={{
													borderColor: "#e3e6fc !important",
													position: "relative",
													marginTop: "5px",
												}}
											>
												<div class="col-md-2 ">
													<div class="form-group">
														<label htmlFor="inputPassword4">
															Account<span class="text-danger">*</span>
														</label>
														{/* <Select name='Consumertype' isMulti options={AccounData.map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																		{value:item.UNIQUE_ID,label:item.CATEGORY0}
																	) :{}
																)} onChange={(e) => { setProfile({ ...Profile, account: e.map((item) => (item.value)) }) }} /> */}
														<select
															class="form-control form-select accountoga ga_calueacc"
															name="accountid"
															required=""
															id="accountid"
															onChange={(e) => setProfile({ ...Profile, account: e.target.value })}
															value={Profile?.account}
															
														>
															<option value="">Select Account</option>
															{AccounData &&
																AccounData.map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																		<option key={id} value={item.UNIQUE_ID}>{item.CATEGORY0}</option>
																	) : (
																		""
																	)
																)}
														</select>
													</div>
												</div>
												{Profile.account !== "" && (
													<div class="col-md-2 gagrp">
														<div class="form-group">
															<label htmlFor="inputPassword4">{NameObj?.CATEGORY1||'CATEGORY1'}</label>
															<select
																class="form-control form-select gatocircle ga_calue"
																name="address_location_d"
																id="address_location_d"
																onChange={(e) => setProfile({ ...Profile, ga: e.target.value })}
																value={Profile?.ga}
															>
																<option value="">Select GA</option>
																{GaData &&
																	GaData.map((item, id) =>
																		permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter(
																			(items) => items === item.CATEGORY1_UNIQUE_ID
																		).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																			<option key={id} value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option>
																		) : (
																			""
																		)
																	)}
															</select>
														</div>
													</div>
												)}
												{Profile.ga !== "" && Profile.account !== "" && (
													<div class="col-md-2 circlegrp">
														<div class="form-group">
															<label htmlFor="inputPassword4">{NameObj?.CATEGORY2||'CATEGORY2'}</label>
															<select
																class="form-control circletodivision form-select"
																name="circle_id"
																onChange={(e) => setProfile({ ...Profile, circle: e.target.value })}
																value={Profile?.circle}
															>
																<option value="">Select Circle</option>
																{CircleData &&
																	CircleData.map((item, id) =>
																		permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter(
																			(items) => items === item.CATEGORY2_UNIQUE_ID
																		).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																			<option key={id} value={item.CATEGORY2_UNIQUE_ID}>{item.CATEGORY2_NAME}</option>
																		) : (
																			""
																		)
																	)}
															</select>
														</div>
													</div>
												)}
												{Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
													<div class="col-md-2 divisiongrp">
														<div class="form-group">
															<label htmlFor="inputPassword4">{NameObj?.CATEGORY3||'CATEGORY3'}</label>
															<select
																class="form-control divisiontosubdivision form-select"
																name="division_id"
																id="address_location_d"
																onChange={(e) => setProfile({ ...Profile, division: e.target.value })}
																value={Profile?.division}
															>
																<option value="">Select Division</option>
																{DivisionData &&
																	DivisionData.map((item, id) =>
																		permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter(
																			(items) => items === item.CATEGORY3_UNIQUE_ID
																		).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																			<option key={id} value={item.CATEGORY3_UNIQUE_ID}>{item.CATEGORY3_NAME}</option>
																		) : (
																			""
																		)
																	)}
															</select>
														</div>
													</div>
												)}
												{Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
													<div class="col-md-2 subdivisiongrp">
														<div class="form-group">
															<label htmlFor="inputPassword4">{NameObj?.CATEGORY4||'CATEGORY4'}</label>
															<select
																class="form-control subdivisiontoarea form-select"
																name="sub_division_id"
																id="address_location_d"
																onChange={(e) => setProfile({ ...Profile, subdivision: e.target.value })}
																value={Profile?.subdivision}
															>
																<option value="">Select Sub Division</option>
																{SubdevisionData &&
																	SubdevisionData.map((item, id) =>
																		permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter(
																			(items) => items === item.CATEGORY4_UNIQUE_ID
																		).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																			<option key={id} value={item.CATEGORY4_UNIQUE_ID}>
																				{item.CATEGORY4_NAME}
																			</option>
																		) : (
																			""
																		)
																	)}
															</select>
														</div>
													</div>
												)}
												{Profile.subdivision !== "" &&
													Profile.division !== "" &&
													Profile.circle !== "" &&
													Profile.ga !== "" &&
													Profile.account !== "" && (
														<div class="col-md-2 areagrp">
															<div class="form-group">
																<label htmlFor="inputPassword4">{NameObj?.CATEGORY5||'CATEGORY5'}</label>
																<select
																	class="form-control form-select lastarea cust_ara"
																	name="area_id"
																	id="area_id"
																	onChange={(e) => setProfile({ ...Profile, area: e.target.value })}
																	value={Profile?.area}
																>
																	<option value="">Select Area</option>
																	{AreaData &&
																		AreaData.map((item, id) =>
																			permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter(
																				(items) => items === item.UNIQUE_ID
																			).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																				<option key={id} value={item.UNIQUE_ID}>{item.CATEGORY5_NAME}</option>
																			) : (
																				""
																			)
																		)}
																</select>
															</div>
														</div>
														
													)}
													
										</div>
										<div class="col-md-6 areagrp d-flex tw-items-center tw-gap-2">
															<div class="form-group" style={{ width:'100%' }}>
																<label htmlFor="inputPassword4">Consumer Type</label>
																<Select  name='Consumer_Type' isMulti options={consumerTypeArray} onChange={(e) => {
										setReport({ ...report, Consumer_Type: e.map((item) => (item.value)) })
									}} />
																</div>
																<div class="form-group" style={{ width: '100%' }}>
                                                            <label htmlFor="inputPassword4">Consumer</label>
                                                            <Select placeholder='All Consumer' value={report?.Consumer?.length > 0 ? consumerArray.filter((item) => report?.Consumer?.includes(item?.value)) : []} isMulti options={consumerArray} onChange={(e) => {
                                                                setReport({ ...report, Consumer: e.map((item) => (item.value)) })
                                                            }} />
                                                        </div>
																<div className={`btn btn-primary  ${FilterSerchLoader==true?'btn-progress disabled':''}`} style={{ height:'37px' }} onClick={ongetGSMDataFilterDataIntegration}><i className='fas fa-search'></i></div>
																</div>
									
											</div>
							</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='tw-px-4'>

                    <div className="card">
                        <div className="card-header">
                            <h4 className="modemconfigurationstatus d-none"></h4>
                            <div className="card-header-action">
                                <div id="filtergrm">
								<MultiInput
									placeholder="search and enter..."
									onChange={onHandalSearchMulti}
									maxItems={null} // Allow any number of items
									onClear={onItemCleared}
									onClearAll={onClearAllItems}
								/>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive meterdata">
                                <table className="table table-striped tw-text-xs tw-w-full">
                                    <thead>
                                        <tr>
                                            <th><input type="checkbox" className="chk_MSSQL1" name="checkAll" checked={ConsumerUniqIdArray.length==(ConsumerData.length+LoraConsumerSearchData.length)?true:false} onClick={(e)=>(e.target.checked==true?setConsumerUniqIdArray([...ConsumerData.map((item)=>(item?.UNIQUE_ID)),...LoraConsumerSearchData.map((item)=>(item?.UNIQUE_ID))]):setConsumerUniqIdArray([]))}/></th>
                                            <th>SRNO</th>
                                            <th>Consumer Name</th>
                                            <th>Modem Serial No</th>
                                            <th>Installation Number</th>
                                            <th>Panel Serial Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                  {[...ConsumerData,...LoraConsumerSearchData].map((item,id)=>(<tr>
                                            <th><input type="checkbox" className="chk_MSSQL1" name="checkAll" checked={ConsumerUniqIdArray.filter((info)=>(info==item?.UNIQUE_ID)).length>0?true:false} onChange={(e)=>onHandal(e,item?.UNIQUE_ID)}/></th>
                                            <th>{id+1}</th>
                                            <th>{JSON.parse(item?.ADDRESS||'{}').address_compony_name}</th>
                                            <th>{item?.DEVICE_SERIAL_NO}</th>
                                            <th>{item?.INSTALLATION_NUMBER}</th>
                                            <th>{item?.PANEL_SERIAL_NO}</th>
                                        </tr>))}  
                                    </tbody>
                                </table>
                            </div>
                        </div>

                       {ConsumerUniqIdArray.length>0?<div className="form-group col-md-12 text-center">
                            <input type="submit" className="btn btn-danger mr-2" value="Send Data" id="sync_btn" name="sync_btn" onClick={onPushMssqldata}/>
                            {/* <input type="submit" className="btn btn-primary" value="Add schedule" id="sync_btn" name="sync_btn" onClick={()=>{setSchedualModal(true)}}/> */}

                        </div>:''}
                    </div>
                </div></>}
				{activeTab=='Logs'&&<IntegrationLog TYPE={props?.credentialdetails?.TYPE}/>}




        </>
    )
}

export default Push