import React, { useEffect, useState } from 'react'
import { activeandinactive, getAccount, getArea, getCircle, getDivision, getGa, getGsmCustomDashboadList, getLoraCustomDashboadList, GetReportType, getRolesById, getSubdevision, InsertsRoles, UpdateRoles } from '../../Commoncomponet/Utils'
import { toast } from 'react-toastify'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { User } from 'react-feather'
import { BootstrapTooltip, MENULIST } from '../../Commoncomponet/common'
import swal from "sweetalert2";
import _  from 'lodash' 
import { Popover, Switch, Table } from 'antd'
window.Swal = swal;

export default function AddRoles() {

    const [SideBarTabs,setSideBarTabs]=useState(MENULIST)
    const userInfo = JSON.parse(window.localStorage.getItem("user_info") || {})

   const columns=[
    {
        title: '',
        dataIndex: '',
        key: '',
        width:'1%',
      },
    Table.EXPAND_COLUMN,
        {
        title: <b>Tab Name</b>,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: <b>View</b>,
        dataIndex: '',
        key: 'x',
        render: (_, record) => <><Switch onChange={(e)=>{onHandalSwitch(record.key, e)}} checked={record?.view} disabled={!disable}/></>,
      }
   ]

//    const onHandalSwitch=(menu,targetId)=>{
//     console.log(menu)
//         const foundItem = _.find(menu, { 'id': targetId });
//         console.log(foundItem)
//         if (foundItem) {
//             return foundItem;
//         }
    
//        const TabJson= menu.map((menuItem,id)=>{
//             if (menuItem.children) {
//                 const foundInChildren = onHandalSwitch(menuItem.children, targetId);
//                 if (foundInChildren) {
                    
//                     console.log({...foundInChildren})
//                 //    return {...menuItem,children:[...menuItem.children,{...foundInChildren,view:true}]}
//                 }
//             }else{
//                 return {...menuItem}

//             }
//         }
//         )
//         // setSideBarTabs(TabJson)
//    }
const onHandalSwitch = (key, checked) => {
    console.log(key)
    const updateData = (items) => items.map(item => {
        if (item.key === key) {
            return { ...item, view: checked };
        }
        if (item.children) {
            return { ...item, children: updateData(item.children) };
        }
        return item;
    });

    const newData = updateData(SideBarTabs);
    setSideBarTabs(newData);
};
    const TabsGroup=[{name:'Modem Details',val:'Modem_Details'}]
    const ConsumerConfigTabs=[{name:'Modem Configuration',val:'Modem_Configuration'},{name:'Meter Configuration',val:'Meter_Configuration'},{name:'Web Configuration',val:'Web_Configuration'}]
    
    const [roles, setroles] = useState({ ROLES_NAME: '', Descriptions: '' })
    const [Paccount, setPaccount] = useState({ "CATEGORY0_create": false, "CATEGORY0_export": false, "CATEGORY0_view": [], "CATEGORY0_edit": [], "CATEGORY0_active": [],CATEGORY0_name:'',EDIT_NAME:false,CATEGORY0_config:[] })
    const [Pga, setPga] = useState({ "CATEGORY1_create": false, "CATEGORY1_export": false, "CATEGORY1_view": [], "CATEGORY1_edit": [], "CATEGORY1_active": [],EDIT_NAME:false,CATEGORY1_config:[] })
    const [Pcircle, setPcircle] = useState({ "CATEGORY2_create": false, "CATEGORY2_export": false, "CATEGORY2_view": [], "CATEGORY2_edit": [], "CATEGORY2_active": [],EDIT_NAME:false,CATEGORY2_config:[] })
    const [Pdivision, setPdivision] = useState({ "CATEGORY3_create": false, "CATEGORY3_export": false, "CATEGORY3_view": [], "CATEGORY3_edit": [], "CATEGORY3_active": [],EDIT_NAME:false,CATEGORY3_config:[] })
    const [Psubdivision, setPsubdivision] = useState({ "CATEGORY4_create": false, "CATEGORY4_export": false, "CATEGORY4_view": [], "CATEGORY4_edit": [], "CATEGORY4_active": [],EDIT_NAME:false,CATEGORY4_config:[] })
    const [Parea, setParea] = useState({ "CATEGORY5_create": false, "CATEGORY5_export": false, "CATEGORY5_view": [], "CATEGORY5_edit": [], "CATEGORY5_active": [],EDIT_NAME:false,CATEGORY5_config:[] })
    const [SubdevisionData, setSubdevisionData] = useState([])
    const [DivisionData, setDivisionData] = useState([])
    const [CircleData, setCircleData] = useState([])
    const [GaData, setGaData] = useState([])
    const [AccounData, setAccountData] = useState([])
    const [AreaData, setAreaData] = useState([])
    const [rolesId, setRolesId] = useState('')
    const [STATUS, setStatus] = useState(0)
    const [disable, setDisable] = useState(true)
    const [NameObj,setNameObj]=useState({})
    const [OTHER_PERMIT,setOTHER_PERMIT]=useState({"GSM_GROUP_DASH":{"MODEM":{"view":[]}},"GSM_CONSUMER_DASH":{"MODEM":{"view":[]}},GSM_CONSUMER:{},Tags:{},Task:{},Report:[],Tabs:[],ConsumerTabs:[],"LORA_GROUP_DASH":{"MODEM":{"view":[]},"METER":{"view":[]}},"CUSTOM_DASHBOARD":{"create":false,"edit":false,"card_add_in_dashboard":false,"dashboard_view":[]},"LORA_CUSTOM_DASHBOARD":{"create":false,"edit":false,"card_add_in_dashboard":false,"dashboard_view":[]}})
    const locations = useLocation()
    const history = useHistory();
    const [btnLoader,setbtnLoader]=useState(false)
    const [ReportTypes,setReportTypes]=useState([])
    const [DashTabs,setDashTabs]=useState([])
    const [LoraDashTabs,setLoraDashTabs]=useState([])
    const [permision,setPermission]=useState({})
    const profiledata=JSON.parse(window.localStorage.getItem('user_info'))||{}

	const onGetReportTypeGSM=async(info)=>{
		const data=await GetReportType({type:'GSM',allGetReportType:true})
		if(data.success==true){
            var arrayReport = []
			data?.data?.data?.map((item) => (info?.ROLES_OTHER_PERMISSIONS?.Report?.filter((info) => (info == item?.BACK_TYPE_NAME)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? arrayReport.push({ value: item?.BACK_TYPE_NAME, label: item?.FRONT_TYPE_NAME }) : ''))
			setReportTypes(arrayReport)
		}else{

		}
	}

     
    useEffect(() => {
        if (locations?.state) {
            setPaccount(JSON.parse(locations?.state?.ROLES_PERMISSIONS_CATEGORY0))
            setPga(JSON.parse(locations?.state?.ROLES_PERMISSIONS_CATEGORY1))
            setPcircle(JSON.parse(locations?.state?.ROLES_PERMISSIONS_CATEGORY2))
            setPdivision(JSON.parse(locations?.state?.ROLES_PERMISSIONS_CATEGORY3))
            setPsubdivision(JSON.parse(locations?.state?.ROLES_PERMISSIONS_CATEGORY4))
            setParea(JSON.parse(locations?.state?.ROLES_PERMISSIONS_CATEGORY5))
            setroles({ ROLES_NAME: locations?.state?.ROLES_NAME, Descriptions: locations?.state?.ROLES_DISCRIPTION })
            setRolesId(locations?.state?.ROLES_UNIQUE_ID)
            setStatus(locations?.state?.ROLES_STATUS)
            setDisable(false)
            setOTHER_PERMIT(JSON.parse(locations?.state?.ROLES_OTHER_PERMISSIONS||'{}'))
            setSideBarTabs(locations?.state?.ROLES_MENU_PERMISSIONS?JSON.parse(locations?.state?.ROLES_MENU_PERMISSIONS):MENULIST)
        }
    }, [locations]);
    const onHandaleAccount = (e, id) => {
        var Paccounts = Paccount[e.target.name]
        if (e.target.name === 'account_fullAccess') {
            if (e.target.checked) {
                // Paccounts.push(id)
                const obj = { "CATEGORY0_view": Paccount.CATEGORY0_view.filter((item) => (item == id)).length===0?[...Paccount.CATEGORY0_view, id]:[...Paccount.CATEGORY0_view], "CATEGORY0_edit": Paccount.CATEGORY0_edit.filter((item) => (item == id)).length===0?[...Paccount.CATEGORY0_edit, id]:[...Paccount.CATEGORY0_edit], "CATEGORY0_active": Paccount.CATEGORY0_active.filter((item) => (item == id)).length===0?[...Paccount.CATEGORY0_active, id]:[...Paccount.CATEGORY0_active] }
                setPaccount({...Paccount,...obj})
            } else {
                const obj = { "CATEGORY0_view": Paccount.CATEGORY0_view.filter((item) => (item !== id)), "CATEGORY0_edit": Paccount.CATEGORY0_edit.filter((item) => (item !== id)), "CATEGORY0_active": Paccount.CATEGORY0_active.filter((item) => (item !== id)) }
                setPaccount({...Paccount,...obj})
            }
        } else {
            if (e.target.checked) {
                if(!Paccounts){
                    Paccounts=[]
                }
                Paccounts.push(id)
            } else {
                Paccounts = Paccounts.filter((item) => (item !== id))
            }
            setPaccount({ ...Paccount, [e.target.name]: Paccounts })

        }

    }

    const onHandaleGa = (e, id) => {
        var Pgas = Pga[e.target.name]
        if (e.target.name === 'ga_fullAccess') {
            if (e.target.checked) {
                // Paccounts.push(id)
                const obj = { "CATEGORY1_view": Pga.CATEGORY1_view.filter((item) => (item == id)).length===0?[...Pga.CATEGORY1_view, id]:[...Pga.CATEGORY1_view], "CATEGORY1_edit": Pga.CATEGORY1_edit.filter((item) => (item == id)).length===0?[...Pga.CATEGORY1_edit, id]:[...Pga.CATEGORY1_edit], "CATEGORY1_active": Pga.CATEGORY1_active.filter((item) => (item == id)).length===0?[...Pga.CATEGORY1_active, id]:[...Pga.CATEGORY1_active] }
                setPga({...Pga,...obj})
            } else {
                const obj = { "CATEGORY1_view": Pga.CATEGORY1_view.filter((item) => (item !== id)), "CATEGORY1_edit": Pga.CATEGORY1_edit.filter((item) => (item !== id)), "CATEGORY1_active": Pga.CATEGORY1_active.filter((item) => (item !== id)) }
                setPga({...Pga,...obj})
            }
        } else {
            if (e.target.checked) {
                if(!Pgas){
                    Pgas=[]
                }
                Pgas.push(id)
            } else {
                Pgas = Pgas.filter((item) => (item !== id))
            }
            setPga({ ...Pga, [e.target.name]: Pgas })
        }
    }

    const onHandaleCircle = (e, id) => {
        var Pcircles = Pcircle[e.target.name]
        if (e.target.name === 'circle_fullAccess') {
            if (e.target.checked) {
                // Paccounts.push(id)
                const obj = { "CATEGORY2_view": Pcircle.CATEGORY2_view.filter((item) => (item == id)).length===0?[...Pcircle.CATEGORY2_view, id]:[...Pcircle.CATEGORY2_view], "CATEGORY2_edit": Pcircle.CATEGORY2_edit.filter((item) => (item == id)).length===0?[...Pcircle.CATEGORY2_edit, id]:[...Pcircle.CATEGORY2_edit], "CATEGORY2_active": Pcircle.CATEGORY2_active.filter((item) => (item == id)).length===0?[...Pcircle.CATEGORY2_active, id]:[...Pcircle.CATEGORY2_active] }
                setPcircle({...Pcircle,...obj})
            } else {
                const obj = { "CATEGORY2_view": Pcircle.CATEGORY2_view.filter((item) => (item !== id)), "CATEGORY2_edit": Pcircle.CATEGORY2_edit.filter((item) => (item !== id)), "CATEGORY2_active": Pcircle.CATEGORY2_active.filter((item) => (item !== id)) }
                setPcircle({...Pcircle,...obj})
            }
        } else {
            if (e.target.checked) {
                if(!Pcircles){
                    Pcircles=[]
                }
                Pcircles.push(id)
            } else {
                Pcircles = Pcircles.filter((item) => (item !== id))
            }
            setPcircle({ ...Pcircle, [e.target.name]: Pcircles })
        }
    }

    const onHandaleDivision = (e, id) => {
        var Pdivisions = Pdivision[e.target.name]
        if (e.target.name === 'division_fullAccess') {
            if (e.target.checked) {
                // Paccounts.push(id)
                const obj = { "CATEGORY3_view": Pdivision.CATEGORY3_view.filter((item) => (item == id)).length==0?[...Pdivision.CATEGORY3_view, id]:[...Pdivision.CATEGORY3_view], "CATEGORY3_edit": Pdivision.CATEGORY3_edit.filter((item) => (item == id)).length==0?[...Pdivision.CATEGORY3_edit, id]:[...Pdivision.CATEGORY3_edit], "CATEGORY3_active": Pdivision.CATEGORY3_active.filter((item) => (item == id)).length==0?[...Pdivision.CATEGORY3_active, id]:[...Pdivision.CATEGORY3_active] }
                setPdivision({...Pdivision,...obj})
            } else {
                const obj = { "CATEGORY3_view": Pdivision.CATEGORY3_view.filter((item) => (item !== id)), "CATEGORY3_edit": Pdivision.CATEGORY3_edit.filter((item) => (item !== id)), "CATEGORY3_active": Pdivision.CATEGORY3_active.filter((item) => (item !== id)) }
                setPdivision({...Pdivision,...obj})
            }
        } else {
            if (e.target.checked) {
                if(!Pdivisions){
                    Pdivisions=[]
                }
                Pdivisions.push(id)
            } else {
                Pdivisions = Pdivisions.filter((item) => (item !== id))
            }
            setPdivision({ ...Pdivision, [e.target.name]: Pdivisions })
        }
    }
    
    const onHandaleSubdivision = (e, id) => {
        var Psubdivisions = Psubdivision[e.target.name]
        if (e.target.name === 'subdivision_fullAccess') {
            if (e.target.checked) {
                // Paccounts.push(id)
                const obj = { "CATEGORY4_view": Psubdivision.CATEGORY4_view.filter((item) => (item == id)).length==0?[...Psubdivision.CATEGORY4_view, id]:[...Psubdivision.CATEGORY4_view], "CATEGORY4_edit": Psubdivision.CATEGORY4_edit.filter((item) => (item == id)).length==0?[...Psubdivision.CATEGORY4_edit, id]:[...Psubdivision.CATEGORY4_edit], "CATEGORY4_active": Psubdivision.CATEGORY4_active.filter((item) => (item == id)).length==0?[...Psubdivision.CATEGORY4_active, id]:[...Psubdivision.CATEGORY4_active] }
                setPsubdivision({...Psubdivision,...obj})
            } else {
                const obj = { "CATEGORY4_view": Psubdivision.CATEGORY4_view.filter((item) => (item !== id)), "CATEGORY4_edit": Psubdivision.CATEGORY4_edit.filter((item) => (item !== id)), "CATEGORY4_active": Psubdivision.CATEGORY4_active.filter((item) => (item !== id)) }
                setPsubdivision({...Psubdivision,...obj})
            }
        } else {
            if (e.target.checked) {
                if(!Psubdivisions){
                    Psubdivisions=[]
                }
                Psubdivisions.push(id)
            } else {
                Psubdivisions = Psubdivisions.filter((item) => (item !== id))
            }
            setPsubdivision({ ...Psubdivision, [e.target.name]: Psubdivisions })
        }
    }

    const onHandaleArea = (e, id) => {
        var Pareas = Parea[e.target.name]
        if (e.target.name === 'area_fullAccess') {
            if (e.target.checked) {
                // Paccounts.push(id)
                const obj = { "CATEGORY5_view": Parea.CATEGORY5_view.filter((item) => (item == id)).length==0?[...Parea.CATEGORY5_view, id]:[...Parea.CATEGORY5_view], "CATEGORY5_edit": Parea.CATEGORY5_edit.filter((item) => (item == id)).length==0?[...Parea.CATEGORY5_edit, id]:[...Parea.CATEGORY5_edit], "CATEGORY5_active": Parea.CATEGORY5_active.filter((item) => (item == id)).length==0?[...Parea.CATEGORY5_active, id]:[...Parea.CATEGORY5_active] }
                setParea({...Parea,...obj})
            } else {
                const obj = { "CATEGORY5_view": Parea.CATEGORY5_view.filter((item) => (item !== id)), "CATEGORY5_edit": Parea.CATEGORY5_edit.filter((item) => (item !== id)), "CATEGORY5_active": Parea.CATEGORY5_active.filter((item) => (item !== id)) }
                setParea(...Parea,...obj)
            }
        } else {
            if (e.target.checked) {
                if(!Pareas){
                    Pareas=[]
                }
                Pareas.push(id)
            } else {
                Pareas = Pareas.filter((item) => (item !== id))
            }
            setParea({ ...Parea, [e.target.name]: Pareas })
        }
    }

    const onHandaleList = async () => {
        var Category0=[]
        var Roles=[]
        if(userInfo?.ROLE!=='ROLE1650614500708'){
        const RolesDetails = await getRolesById(userInfo?.ROLE)
		if (RolesDetails.success === true) {
			const obj = RolesDetails.data.data[0]
             Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj?.ROLES_PERMISSIONS_CATEGORY0||'{}'),
				ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj?.ROLES_PERMISSIONS_CATEGORY5||'{}'),
				ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj?.ROLES_PERMISSIONS_CATEGORY2||'{}'),
				ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj?.ROLES_PERMISSIONS_CATEGORY3||'{}'),
				ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj?.ROLES_PERMISSIONS_CATEGORY1||'{}'),
				ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj?.ROLES_PERMISSIONS_CATEGORY4||'{}'),
				ROLES_OTHER_PERMISSIONS: JSON.parse(obj?.ROLES_OTHER_PERMISSIONS||'{}'),
                ROLES_MENU_PERMISSIONS:JSON.parse(obj?.ROLES_MENU_PERMISSIONS||'{}')}
            Category0=Roles?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view
            
            setPermission(Roles)
            var arr1=SideBarTabs
            var arr2=Roles?.ROLES_MENU_PERMISSIONS
            if(!locations?.state){
            function filterObjectsWithViewTrue(items) {
                return items.reduce((acc, item) => {
                    if (item.view === true) {
                        // Clone the object to avoid modifying the original array
                        let newItem = { ...item };
             
                        // If the item has children, filter them recursively
                        if (newItem.children && newItem.children.length > 0) {
                            newItem.children = filterObjectsWithViewTrue(newItem.children);
                        }
             
                        acc.push({...newItem,view:false});
                    }
                    return acc;
                }, []);
            };
              
              const filteredArr2 = filterObjectsWithViewTrue(arr2);
              setSideBarTabs(filteredArr2)
        }
        }else{

        }
    }else{
        onHandaleAccountList(JSON.parse(locations?.state?.ROLES_PERMISSIONS_CATEGORY0||'{}').CATEGORY0_name)

    }
    onGetReportTypeGSM(Roles)
        onLoadCosutomDashboardList(Roles)
        onLoadLoraCosutomDashboardList(Roles)
        const data = await getAccount()
        if (data.success === true) {
            
            setAccountData(data?.data?.data.filter((data) => (Category0?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708')))
            if(locations?.state?.ROLES_PERMISSIONS_CATEGORY0){
            setNameObj(JSON.parse(data.data.data.filter((item)=>(item?.UNIQUE_ID==JSON.parse(locations?.state?.ROLES_PERMISSIONS_CATEGORY0||'{}').CATEGORY0_name ))[0]?.PROFILE_NAME||'{}'))
            }
        } else {
            console.log('somthing want wrong')
        }
       
    }

   

    const onHandaleAccountList= async (AccountId)=>{
        if(AccountId){
            console.log(permision)
        const data1 = await getGa({roleSerach:true,CATEGORY0:AccountId})
        if (data1.success === true) {
            setGaData(data1?.data?.data.filter((data) => ((permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => (items === data.CATEGORY1_UNIQUE_ID)).length > 0||AccountId===data?.CATEGORY0 || userInfo?.ROLE === 'ROLE1650614500708'))))
            const data2 = await getCircle({roleSerach:true,CATEGORY0:AccountId})
            if (data2.success === true) {
                setCircleData(data2?.data?.data.filter((data) => ((permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => (items === data.CATEGORY2_UNIQUE_ID)).length > 0||AccountId===data?.CATEGORY0 || userInfo?.ROLE === 'ROLE1650614500708'))))
                const data3 = await getDivision({roleSerach:true,CATEGORY0:AccountId})
                if (data3.success === true) {
                    setDivisionData(data3?.data?.data.filter((data) => ((permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => (items === data.CATEGORY3_UNIQUE_ID)).length > 0 ||AccountId===data?.CATEGORY0|| userInfo?.ROLE === 'ROLE1650614500708'))))
                    const data4 = await getSubdevision({roleSerach:true,CATEGORY0:AccountId})
                    if (data4.success === true) {
                        setSubdevisionData(data4?.data?.data.filter((data) => ((permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter((items) => (items === data.CATEGORY4_UNIQUE_ID)).length > 0||AccountId===data?.CATEGORY0 || userInfo?.ROLE === 'ROLE1650614500708'))))
                    }
                    const data5 = await getArea({roleSerach:true,CATEGORY0:AccountId})
                    if (data5.success === true) {
                        setAreaData(data5?.data?.data.filter((data) => ((permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 ||AccountId===data?.CATEGORY0|| userInfo?.ROLE === 'ROLE1650614500708'))))
                    }
                    console.log(data5)
                }
            }
        }else {
            console.log('somthing want wrong')
        }
    }
    }

    useEffect(()=>{
        if(locations?.state&&Object.keys(permision||{}).length>0){
            console.log('useEffect',permision)
            onHandaleAccountList(JSON.parse(locations?.state?.ROLES_PERMISSIONS_CATEGORY0||'{}').CATEGORY0_name)
        }
    
    },[permision])
    const onLoadCosutomDashboardList=async(info)=>{
        const data=await getGsmCustomDashboadList()
        if(data.success==true){
            setDashTabs(data?.data?.data?.filter((item)=>(info?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.dashboard_view?.includes(item?.unique_id)||userInfo?.ROLE == 'ROLE1650614500708')))
        }else{
            console.log('something want wrong')
        }
    }

    const onLoadLoraCosutomDashboardList=async(info)=>{
        const data=await getLoraCustomDashboadList()
        if(data.success==true){
            setLoraDashTabs(data?.data?.data?.filter((item)=>(info?.ROLES_OTHER_PERMISSIONS?.LORA_CUSTOM_DASHBOARD?.dashboard_view?.includes(item?.unique_id)||userInfo?.ROLE == 'ROLE1650614500708')))
        }else{
            console.log('something want wrong')
        }
    }
    useEffect(() => {
       onHandaleList()
        
    }, [])

   
    const onHandaleSubmit = async (e) => {
        e.preventDefault()
        setbtnLoader(true)
        console.log(SideBarTabs)
        if (roles.ROLES_NAME !== ''&&Paccount?.CATEGORY0_name) {
            if (rolesId === '') {
                const obj = {
                    ROLES_PERMISSIONS_CATEGORY0: JSON.stringify(Paccount),
                    ROLES_PERMISSIONS_CATEGORY1: JSON.stringify(Pga),
                    ROLES_PERMISSIONS_CATEGORY2: JSON.stringify(Pcircle),
                    ROLES_PERMISSIONS_CATEGORY3: JSON.stringify(Pdivision),
                    ROLES_PERMISSIONS_CATEGORY4: JSON.stringify(Psubdivision),
                    ROLES_PERMISSIONS_CATEGORY5: JSON.stringify(Parea),
                    ROLES_CREATED_BY: userInfo?.UNIQUE_ID,
                    ROLES_NAME: roles.ROLES_NAME,
                    ROLES_DISCRIPTION: roles.Descriptions,
                    ROLES_OTHER_PERMISSIONS:JSON.stringify(OTHER_PERMIT),
                    ROLES_MENU_PERMISSIONS:JSON.stringify(SideBarTabs)
                }
                const data = await InsertsRoles(obj)
                if (data.success === true) {
                    toast.success('Roles Successfull Add')
                    history.push('/Roles')
        setbtnLoader(false)

                } else {
                    toast.error('Something want Wrong')
        setbtnLoader(false)

                }
            } else {
                const obj2 = {
                    ROLES_PERMISSIONS_CATEGORY0: JSON.stringify(Paccount),
                    ROLES_PERMISSIONS_CATEGORY1: JSON.stringify(Pga),
                    ROLES_PERMISSIONS_CATEGORY2: JSON.stringify(Pcircle),
                    ROLES_PERMISSIONS_CATEGORY3: JSON.stringify(Pdivision),
                    ROLES_PERMISSIONS_CATEGORY4: JSON.stringify(Psubdivision),
                    ROLES_PERMISSIONS_CATEGORY5: JSON.stringify(Parea),
                    ROLES_CREATED_BY: userInfo?.UNIQUE_ID,
                    ROLES_NAME: roles.ROLES_NAME,
                    ROLES_DISCRIPTION: roles.Descriptions,
                    ROLES_OTHER_PERMISSIONS:JSON.stringify(OTHER_PERMIT),
                    ROLES_MENU_PERMISSIONS:JSON.stringify(SideBarTabs)

                }
                const data = await UpdateRoles(obj2, rolesId)
                if (data.success === true) {
                    toast.success('Roles Successfull Update')
                    history.push('/Roles')
        setbtnLoader(false)

                } else {
                    toast.error('Something want Wrong')
        setbtnLoader(false)

                }

            }
        } else {
            toast.error('Please Fill Proper Details')
        setbtnLoader(false)

        }

    }

    const oninactive = async () => {
        swal.fire({
            title: "Are You Sure InActive Roles?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await activeandinactive({ tableName: 'roles_index_dev', colName: 'ROLES_STATUS', value: '0', matchId: 'ROLES_UNIQUE_ID' }, rolesId)
                if (data.success === true) {
                    toast.success('Success Inactive Roles')
                    history.push('/Roles')
                } else {
                    toast.error('something want wrong')
                }
            }
        })
    }
    const onactive = async () => {
        swal.fire({
            title: "Are You Sure Roles Active?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await activeandinactive({ tableName: 'roles_index_dev', colName: 'ROLES_STATUS', value: '1', matchId: 'ROLES_UNIQUE_ID' }, rolesId)
                if (data.success === true) {
                    toast.success('Success Active Roles')
                    history.push('/Roles')
                } else {
                    toast.error('something want wrong')
                }
            }
        })
    }
    const onChangeGSMModemGroupDashboadChart=async(e)=>{
        var arrayChart=OTHER_PERMIT.GSM_GROUP_DASH.MODEM[e.target.id]||[]
        if(e.target.checked){
            arrayChart.push(e.target.name)
        }else{
            console.log(arrayChart,e.target.name);
            arrayChart=await arrayChart.filter((item)=>(item!=e.target.name))
            console.log(arrayChart);

        }
        setOTHER_PERMIT({...OTHER_PERMIT,GSM_GROUP_DASH:{...OTHER_PERMIT.GSM_GROUP_DASH,MODEM:{...OTHER_PERMIT.GSM_GROUP_DASH.MODEM,[e.target.id]:arrayChart}}})
    }

    const onChangeLoraModemGroupDashboadChart=async(e)=>{
        var arrayChart=OTHER_PERMIT?.LORA_GROUP_DASH?.MODEM[e.target.id]||[]
        if(e.target.checked){
            arrayChart.push(e.target.name)
        }else{
            console.log(arrayChart,e.target.name);
            arrayChart=await arrayChart.filter((item)=>(item!=e.target.name))
            console.log(arrayChart);
        }
        setOTHER_PERMIT({...OTHER_PERMIT,LORA_GROUP_DASH:{...OTHER_PERMIT.LORA_GROUP_DASH,MODEM:{...OTHER_PERMIT?.LORA_GROUP_DASH?.MODEM,[e.target.id]:arrayChart}}})
    }
    const onChangeReportChart=async(e)=>{
        var arrayChart=OTHER_PERMIT.Report||[]
        if(e.target.checked){
            arrayChart.push(e.target.id)
        }else{
            arrayChart=await arrayChart.filter((item)=>(item!=e.target.id))

        }
        setOTHER_PERMIT({...OTHER_PERMIT,Report:arrayChart})
    }

    const onChangeTabs=async(e)=>{
        var arrayChart=OTHER_PERMIT.Tabs||[]
        if(e.target.checked){
            arrayChart.push(e.target.id)
        }else{
            arrayChart=await arrayChart.filter((item)=>(item!=e.target.id))

        }
        setOTHER_PERMIT({...OTHER_PERMIT,Tabs:arrayChart})
    }

    const onChangeConsumerTabs=async(e)=>{
        var arrayChart=OTHER_PERMIT.ConsumerTabs||[]
        if(e.target.checked){
            arrayChart.push(e.target.id)
        }else{
            arrayChart=await arrayChart.filter((item)=>(item!=e.target.id))

        }
        setOTHER_PERMIT({...OTHER_PERMIT,ConsumerTabs:arrayChart})
    }
    const onChangeGSMModemConsumerDashboadChart=async(e)=>{
        var arrayChart={}
        if(OTHER_PERMIT.GSM_CONSUMER_DASH){
            arrayChart=OTHER_PERMIT.GSM_CONSUMER_DASH.MODEM[e.target.id]
        }else{
            arrayChart=[]
        }

        if(e.target.checked){
            arrayChart.push(e.target.name)
        }else{
            arrayChart=await arrayChart.filter((item)=>(item!=e.target.name))
            console.log(arrayChart);

        }
        setOTHER_PERMIT({...OTHER_PERMIT,GSM_CONSUMER_DASH:{...OTHER_PERMIT.GSM_CONSUMER_DASH,MODEM:{...OTHER_PERMIT.GSM_CONSUMER_DASH.MODEM,[e.target.id]:arrayChart}}})
    }

    const onChangeLoraMorePermissionDashboadChart=async(e,name)=>{
        var arrayChart={}
        if(OTHER_PERMIT?.LORA_GROUP_DASH?.MORE_PERMISSION){
            arrayChart=OTHER_PERMIT?.LORA_GROUP_DASH?.MORE_PERMISSION[name]||[]
        }else{
            arrayChart=[]
        }

        if(e.target.checked){
            arrayChart.push(e.target.name)
        }else{
            arrayChart=await arrayChart.filter((item)=>(item!=e.target.name))
            console.log(arrayChart);

        }
        setOTHER_PERMIT({...OTHER_PERMIT,LORA_GROUP_DASH:{...OTHER_PERMIT.LORA_GROUP_DASH,MORE_PERMISSION:{...OTHER_PERMIT.LORA_GROUP_DASH.MORE_PERMISSION||{},[name]:arrayChart}}})
    }

    const rowSelection= {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        },
      };
    return (
        <>
            <div className="main-content">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/all-dashboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link >
                                <User className='header-logo' style={{ height: "16px" }} /> Roles
                            </Link>
                        </li>

                        <li className="breadcrumb-item">
                            <Link >{rolesId === '' ? 'Add Roles' : 'Update Roles'}</Link>
                        </li>
                    </ol>
                </nav>
                <section class="section">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card !tw-pb-0">
                                    <div class="card-header">
                                        <h4>{rolesId === '' ? 'Add Roles' : 'Update Roles'}</h4>
                                        <div class="card-header-action">
                                            {rolesId !== '' ? <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div> : ''}
                                            {rolesId !== '' && STATUS === 1 ? <div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div> : ''}
                                            {rolesId !== '' && STATUS === 0 ? <div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div> : ''}
                                            <Link to="/Roles" class="btn btn-info text-white">
                                                Back
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="card-body !tw-mb-0 !tw-pb-0">
                                        <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data">
                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label for="inputPassword4">Name:<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" name="rolesname" placeholder="Enter Role name" required="" onChange={(e) => setroles({ ...roles, ROLES_NAME: e.target.value })} value={roles?.ROLES_NAME} disabled={!disable} />
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label for="inputPassword4">Description:</label>
                                                    <textarea class="form-control" name="rolediscription" onChange={(e) => setroles({ ...roles, Descriptions: e.target.value })} value={roles?.Descriptions} disabled={!disable}></textarea>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                            <div class="form-group col-md-3 ">
						<label for="inputPassword4">Account Name:<span class="text-danger">*</span></label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={(e) => {
                                setPaccount({ "CATEGORY0_create": false, "CATEGORY0_export": false, "CATEGORY0_view": [], "CATEGORY0_edit": [], "CATEGORY0_active": [],CATEGORY0_name:e.target.value,EDIT_NAME:false,CATEGORY0_config:[] })
                                setPga({ "CATEGORY1_create": false, "CATEGORY1_export": false, "CATEGORY1_view": [], "CATEGORY1_edit": [], "CATEGORY1_active": [],EDIT_NAME:false,CATEGORY1_config:[] })
                                setPcircle({ "CATEGORY2_create": false, "CATEGORY2_export": false, "CATEGORY2_view": [], "CATEGORY2_edit": [], "CATEGORY2_active": [],EDIT_NAME:false,CATEGORY2_config:[] })
                                setPdivision({ "CATEGORY3_create": false, "CATEGORY3_export": false, "CATEGORY3_view": [], "CATEGORY3_edit": [], "CATEGORY3_active": [],EDIT_NAME:false,CATEGORY3_config:[] })
                                setPsubdivision({ "CATEGORY4_create": false, "CATEGORY4_export": false, "CATEGORY4_view": [], "CATEGORY4_edit": [], "CATEGORY4_active": [],EDIT_NAME:false,CATEGORY4_config:[] })
                                setParea({ "CATEGORY5_create": false, "CATEGORY5_export": false, "CATEGORY5_view": [], "CATEGORY5_edit": [], "CATEGORY5_active": [],EDIT_NAME:false,CATEGORY5_config:[] })
        onHandaleAccountList(e.target.value)
        setNameObj(JSON.parse(AccounData.filter((item)=>(item?.UNIQUE_ID==e.target.value))[0].PROFILE_NAME))
                            }}
							value={Paccount.CATEGORY0_name}
                            disabled={!disable}
						>
							<option value="">Select Account Name</option>
							{AccounData&&AccounData.map((item)=>(<option value={item.UNIQUE_ID}>{item?.CATEGORY0}</option>))}
							
						</select>
					</div>
                    </div>
                                            {/* <h4>Profiles</h4> */}
                                           {Paccount.CATEGORY0_name? <div className="table-responsive card col-md-12 col-xl-9 col-lg-9 col-sm-12 col-xs-12 tw-text-sm px-4 pt-3">
                                                <h4 className='px-1 tw-pt-2 tw-text-xl tw-font-bold'>Group (This will give access releted to consumer list as well)</h4>

                                                <table class="table border tw-text-center" >

                                                    <tbody className='tw-w-full'>

                                                        <tr className='' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                            <th scope="row" className='tw-w-1/3 tw-text-start' >
                                                                <h5 className='tw-text-lg tw-font-normal tw-mb-0 '>Account</h5></th>
                                                                {permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_create === true || userInfo?.ROLE === "ROLE1650614500708"?<td className=' tw-text-start tw-font-normal tw-py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setPaccount({ ...Paccount, CATEGORY0_create: e.target.checked }))} checked={Paccount?.CATEGORY0_create} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Create</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                           {permision?.ROLES_PERMISSIONS_CATEGORY0?.EDIT_NAME === true || userInfo?.ROLE === "ROLE1650614500708"? <td className=' tw-text-start tw-font-normal tw-py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setPaccount({ ...Paccount, EDIT_NAME: e.target.checked }))} checked={Paccount?.EDIT_NAME} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Edit Name</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_export === true || userInfo?.ROLE === "ROLE1650614500708"?<td className=' tw-py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e) => (setPaccount({ ...Paccount, CATEGORY0_export: e.target.checked }))} checked={Paccount?.CATEGORY0_export} disabled={!disable} />
                                                                    <div class="state p-info-o  ml-2">
                                                                        <label className='mb-0'> Export/Print</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            
                                                        </tr>
                                                        <tr class="accounttrgrp" className='rounded'>
                                                            <td colspan="4">
                                                                <table class="table-bordered table-striped w-100 mb-4 mt-2" >
                                                                    <thead>
                                                                        <tr className=' '>
                                                                            <th scope="col" class="thalignleft  text-start" style={{ width: "30%", }} >Account Name</th>
                                                                            <th scope="col" class="thalign " style={{ width: "12%", }}>Full Access</th>

                                                                            <th scope="col" class="thalign " style={{ width: "12%", }} >View</th>
                                                                            <th scope="col" class="thalign " style={{ width: "12%", }} >Edit</th>
                                                                            <th scope="col" class="thalign " style={{ width: "12%", }}>Active/Inactive</th>
                                                                            <th scope="col" class="thalign ">Configuration</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {AccounData && AccounData.map((item) => (
                                                                            item.UNIQUE_ID===Paccount?.CATEGORY0_name?
                                                                            <tr>
                                                                                <td scope="row" class="thalignleft text-start font-normal" ><><span className={item?.STATUS==1?'text-success':'text-danger'}><i className='fas fa-circle'></i></span></>{item?.CATEGORY0}</td>

                                                                                {userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="account_fullAccess" id="account_full_access" onClick={(e) => (onHandaleAccount(e, item.UNIQUE_ID))} disabled={!disable} />
                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => items === item.UNIQUE_ID).length > 0||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY0_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e) => (onHandaleAccount(e, item.UNIQUE_ID))} checked={Paccount?.CATEGORY0_view?.filter((items) => items === item.UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}

                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_edit?.filter((items) => items === item.UNIQUE_ID).length > 0||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY0_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e) => (onHandaleAccount(e, item.UNIQUE_ID))} checked={Paccount?.CATEGORY0_edit?.filter((items) => items === item.UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />
                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_active?.filter((items) => items === item.UNIQUE_ID).length > 0||userInfo?.ROLE === "ROLE1650614500708"?<td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY0_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleAccount(e, item.UNIQUE_ID))} checked={Paccount?.CATEGORY0_active?.filter((items) => items === item.UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />
                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_config?.filter((items) => items === item.UNIQUE_ID).length > 0||userInfo?.ROLE === "ROLE1650614500708"?<td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY0_config" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleAccount(e, item.UNIQUE_ID))} checked={Paccount?.CATEGORY0_config?.filter((items) => items === item.UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />
                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                            </tr>:''
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr className='' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                            <td className='text-start' scope="row">
                                                                <h5 className='tw-text-base tw-font-normal tw-mb-0 '>{NameObj?.CATEGORY1||'CATEGORY1'}</h5></td>
                                                                {permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_create === true || userInfo?.ROLE === "ROLE1650614500708"? <td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setPga({ ...Pga, CATEGORY1_create: e.target.checked }))} checked={Pga?.CATEGORY1_create} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Create</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY1?.EDIT_NAME === true || userInfo?.ROLE === "ROLE1650614500708"?<td className=' tw-text-start tw-font-normal tw-py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setPga({ ...Pga, EDIT_NAME: e.target.checked }))} checked={Pga?.EDIT_NAME} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Edit Name</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_export === true || userInfo?.ROLE === "ROLE1650614500708"?<td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e) => (setPga({ ...Pga, CATEGORY1_export: e.target.checked }))} checked={Pga?.CATEGORY1_export} disabled={!disable} />
                                                                    <div class="state p-info-o  ml-2">
                                                                        <label className='mb-0'> Export/Print</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                        </tr>
                                                        <tr class="gatrgrp">
                                                            <td colspan="4">
                                                                <table class="table-bordered table-striped w-100 mb-4 mt-2" >
                                                                    <thead>
                                                                        <tr className=''>

                                                                            <th scope="col" class="thalignleft text-start " style={{ width: "30%", }}>{NameObj?.CATEGORY1||'CATEGORY1'} Name</th>
                                                                            <th scope="col" class="thalignleft " style={{ width: "12%", }} >Full Access</th>
                                                                            <th scope="col" class="thalignleft " style={{ width: "12%", }} >View</th>
                                                                            <th scope="col" class="thalignleft " style={{ width: "12%", }}>Edit</th>
                                                                            <th scope="col" class="thalignleft " style={{ width: "12%", }} >Active/Inactive</th>
                                                                            <th scope="col" class="thalignleft " >Configuration</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {GaData && GaData.map((item) => (
                                                                            <tr>
                                                                                <td scope="row" class="thalignleft text-start"><><span className={item?.CATEGORY1_STATUS==1?'text-success':'text-danger'}><i className='fas fa-circle'></i></span></>{item?.CATEGORY1_NAME}</td>
                                                                                {userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="ga_fullAccess" id="ga_fullAccess" onClick={(e) => (onHandaleGa(e, item.CATEGORY1_UNIQUE_ID))} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => items === item.CATEGORY1_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY1_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e) => (onHandaleGa(e, item.CATEGORY1_UNIQUE_ID))} checked={Pga?.CATEGORY1_view?.filter((items) => items === item.CATEGORY1_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_edit?.filter((items) => items === item.CATEGORY1_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY1_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e) => (onHandaleGa(e, item.CATEGORY1_UNIQUE_ID))} checked={Pga?.CATEGORY1_edit?.filter((items) => items === item.CATEGORY1_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />
                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_active?.filter((items) => items === item.CATEGORY1_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY1_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleGa(e, item.CATEGORY1_UNIQUE_ID))} checked={Pga?.CATEGORY1_active?.filter((items) => items === item.CATEGORY1_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_config?.filter((items) => items === item.CATEGORY1_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY1_config" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleGa(e, item.CATEGORY1_UNIQUE_ID))} checked={Pga?.CATEGORY1_config?.filter((items) => items === item.CATEGORY1_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />
                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr className='' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                            <th className='text-start' scope="row" ><h5 className=' tw-text-base tw-font-normal tw-mb-0'>{NameObj?.CATEGORY2||'CATEGORY2'}</h5></th>
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_create === true || userInfo?.ROLE === "ROLE1650614500708"?<td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setPcircle({ ...Pcircle, CATEGORY2_create: e.target.checked }))} checked={Pcircle?.CATEGORY2_create} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Create</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY2?.EDIT_NAME === true || userInfo?.ROLE === "ROLE1650614500708"?<td className=' tw-text-start tw-font-normal tw-py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setPcircle({ ...Pcircle, EDIT_NAME: e.target.checked }))} checked={Pcircle?.EDIT_NAME} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Edit Name</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_export === true || userInfo?.ROLE === "ROLE1650614500708"? <td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e) => (setPcircle({ ...Pcircle, CATEGORY2_export: e.target.checked }))} checked={Pcircle?.CATEGORY2_export} disabled={!disable} />
                                                                    <div class="state p-info-o  ml-2">
                                                                        <label className='mb-0'> Export/Print</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                        </tr>
                                                        <tr class="circletrgrp">
                                                            <td colspan="4">
                                                                <table class="table-bordered table-striped w-100 mb-4 mt-2" >
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="thalignleft text-start" style={{ width: "30%" }}>{NameObj?.CATEGORY2||'CATEGORY2'} Name</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }} >Full Access</th>

                                                                            <th scope="col" class="thalign" style={{ width: "12%" }} >View</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }} >Edit</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }} >Active/Inactive</th>
                                                                            <th scope="col" class="thalignleft">Configuration</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {CircleData && CircleData.map((item) => (
                                                                            <tr>
                                                                                <td scope="row" class="thalignleft text-start "><><span className={item?.CATEGORY2_STATUS==1?'text-success':'text-danger'}><i className='fas fa-circle'></i></span></>{item?.CATEGORY2_NAME}</td>
                                                                                {userInfo?.ROLE === "ROLE1650614500708"?<td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="circle_fullAccess" id="circle_fullAccess" onClick={(e) => (onHandaleCircle(e, item.CATEGORY2_UNIQUE_ID))} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => items === item.CATEGORY2_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign text-center">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY2_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e) => (onHandaleCircle(e, item.CATEGORY2_UNIQUE_ID))} checked={Pcircle?.CATEGORY2_view?.filter((items) => items === item.CATEGORY2_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY2_edit?.filter((items) => items === item.CATEGORY2_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign text-center">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY2_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e) => (onHandaleCircle(e, item.CATEGORY2_UNIQUE_ID))} checked={Pcircle?.CATEGORY2_edit?.filter((items) => items === item.CATEGORY2_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_active?.filter((items) => items === item.CATEGORY2_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1))||userInfo?.ROLE === "ROLE1650614500708"?<td class="thalign text-center">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY2_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleCircle(e, item.CATEGORY2_UNIQUE_ID))} checked={Pcircle?.CATEGORY2_active?.filter((items) => items === item.CATEGORY2_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_config?.filter((items) => items === item.UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign text-center">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY2_config" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleCircle(e, item.CATEGORY2_UNIQUE_ID))} checked={Pcircle?.CATEGORY2_config?.filter((items) => items === item.CATEGORY2_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />
                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr className='' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                            <th className='text-start ' scope="row"><h5 className=' tw-text-base tw-font-normal tw-mb-0'>{NameObj?.CATEGORY3||'CATEGORY3'}</h5></th>
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_create === true || userInfo?.ROLE === "ROLE1650614500708"?<td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setPdivision({ ...Pdivision, CATEGORY3_create: e.target.checked }))} checked={Pdivision?.CATEGORY3_create} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Create</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY3?.EDIT_NAME === true || userInfo?.ROLE === "ROLE1650614500708"?<td className=' tw-text-start tw-font-normal tw-py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setPdivision({ ...Pdivision, EDIT_NAME: e.target.checked }))} checked={Pdivision?.EDIT_NAME} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Edit Name</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_export === true || userInfo?.ROLE === "ROLE1650614500708"? <td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e) => (setPdivision({ ...Pdivision, CATEGORY3_export: e.target.checked }))} checked={Pdivision?.CATEGORY3_export} disabled={!disable} />
                                                                    <div class="state p-info-o  ml-2">
                                                                        <label className='mb-0'> Export/Print</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                        </tr>
                                                        <tr class="circletrgrp">
                                                            <td colspan="4">
                                                                <table class="table-bordered table-striped w-100 mb-4 mt-2"  >
                                                                    <thead >
                                                                        <tr >
                                                                            <th scope="col" class="thalignleft text-start" style={{ width: "30%" }}>{NameObj?.CATEGORY3||'CATEGORY3'} Name</th>
                                                                            <th scope="col" class="thalignleft" style={{ width: "12%" }}>Full Access</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>View</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>Edit</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>Active/Inactive</th>
                                                                            <th scope="col" class="thalign" >Configuration</th>


                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {DivisionData && DivisionData.map((item) => (
                                                                            <tr>
                                                                                <td scope="row" class="thalignleft text-start "><><span className={item?.CATEGORY3_STATUS==1?'text-success':'text-danger'}><i className='fas fa-circle'></i></span></>{item?.CATEGORY3_NAME}</td>

                                                                               { userInfo?.ROLE === "ROLE1650614500708"?<td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="division_fullAccess" id="division_fullAccess" onClick={(e) => (onHandaleDivision(e, item.CATEGORY3_UNIQUE_ID))} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => items === item.CATEGORY3_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY3_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e) => (onHandaleDivision(e, item.CATEGORY3_UNIQUE_ID))} checked={Pdivision?.CATEGORY3_view?.filter((items) => items === item.CATEGORY3_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_edit?.filter((items) => items === item.CATEGORY3_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY3_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e) => (onHandaleDivision(e, item.CATEGORY3_UNIQUE_ID))} checked={Pdivision?.CATEGORY3_edit?.filter((items) => items === item.CATEGORY3_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />
                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_active?.filter((items) => items === item.CATEGORY3_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY3_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleDivision(e, item.CATEGORY3_UNIQUE_ID))} checked={Pdivision?.CATEGORY3_active?.filter((items) => items === item.CATEGORY3_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />
                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_config?.filter((items) => items === item.CATEGORY3_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2))||userInfo?.ROLE === "ROLE1650614500708"?  <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY3_config" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleDivision(e, item.CATEGORY3_UNIQUE_ID))} checked={Pdivision?.CATEGORY3_config?.filter((items) => items === item.CATEGORY3_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />
                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr className='' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                            <th className='text-start' scope="row"><h5 className=' tw-text-base tw-font-normal tw-mb-0'>{NameObj?.CATEGORY4||'CATEGORY4'}</h5></th>
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_create === true || userInfo?.ROLE === "ROLE1650614500708"?<td className='py-3'>
                                                            <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setPsubdivision({ ...Psubdivision, CATEGORY4_create: e.target.checked }))} checked={Psubdivision?.CATEGORY4_create} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Create</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY4?.EDIT_NAME === true || userInfo?.ROLE === "ROLE1650614500708"? <td className=' tw-text-start tw-font-normal tw-py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setPsubdivision({ ...Psubdivision, EDIT_NAME: e.target.checked }))} checked={Psubdivision?.EDIT_NAME} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Edit Name</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_export === true || userInfo?.ROLE === "ROLE1650614500708"? <td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e) => (setPsubdivision({ ...Psubdivision, CATEGORY4_export: e.target.checked }))} checked={Psubdivision?.CATEGORY4_export} disabled={!disable} />
                                                                    <div class="state p-info-o  ml-2">
                                                                        <label className='mb-0'> Export/Print</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                        </tr>
                                                        <tr class="circletrgrp">
                                                            <td colspan="4">
                                                                <table class="table-bordered table-striped w-100 m-0 mb-4 mt-2" style={{ marginTop: "10px" }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="thalignleft text-start" style={{ width: "30%" }}>{NameObj?.CATEGORY4||'CATEGORY4'} Name</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>Full Access</th>

                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>View</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>Edit</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>Active/Inactive</th>
                                                                            <th scope="col" class="thalign">Configuration</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {SubdevisionData && SubdevisionData.map((item) => (
                                                                            <tr>
                                                                                <td scope="row" class="thalignleft text-start font-normal"><><span className={item?.CATEGORY4_STATUS==1?'text-success':'text-danger'}><i className='fas fa-circle'></i></span></>{item?.CATEGORY4_NAME}</td>
                                                                                {userInfo?.ROLE === "ROLE1650614500708"?<td class="thalign text-center">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="subdivision_fullAccess" id="subdivision_fullAccess" onClick={(e) => (onHandaleSubdivision(e, item.CATEGORY4_UNIQUE_ID))} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter((items) => items === item.CATEGORY4_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2)&&permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.includes(item?.CATEGORY3))||userInfo?.ROLE === "ROLE1650614500708"?   <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY4_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e) => (onHandaleSubdivision(e, item.CATEGORY4_UNIQUE_ID))} checked={Psubdivision?.CATEGORY4_view?.filter((items) => items === item.CATEGORY4_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_edit?.filter((items) => items === item.CATEGORY4_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2)&&permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.includes(item?.CATEGORY3))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY4_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e) => (onHandaleSubdivision(e, item.CATEGORY4_UNIQUE_ID))} checked={Psubdivision?.CATEGORY4_edit?.filter((items) => items === item.CATEGORY4_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_active?.filter((items) => items === item.CATEGORY4_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2)&&permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.includes(item?.CATEGORY3))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY4_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleSubdivision(e, item.CATEGORY4_UNIQUE_ID))} checked={Psubdivision?.CATEGORY4_active?.filter((items) => items === item.CATEGORY4_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_config?.filter((items) => items === item.CATEGORY4_UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2)&&permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.includes(item?.CATEGORY3))||userInfo?.ROLE === "ROLE1650614500708"?  <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY4_config" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleSubdivision(e, item.CATEGORY4_UNIQUE_ID))} checked={Psubdivision?.CATEGORY4_config?.filter((items) => items === item.CATEGORY4_UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr className='' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                            <th className='w-1/3 text-start' scope="row"><h5 className=' tw-text-base tw-font-normal tw-mb-0'>{NameObj?.CATEGORY5||'CATEGORY5'}</h5></th>
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_create === true || userInfo?.ROLE === "ROLE1650614500708"? <td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setParea({ ...Parea, CATEGORY5_create: e.target.checked }))} checked={Parea?.CATEGORY5_create} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Create</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY5?.EDIT_NAME === true || userInfo?.ROLE === "ROLE1650614500708"?  <td className=' tw-text-start tw-font-normal tw-py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e) => (setParea({ ...Parea, EDIT_NAME: e.target.checked }))} checked={Parea?.EDIT_NAME} disabled={!disable} />
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Edit Name</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_export === true || userInfo?.ROLE === "ROLE1650614500708"?  <td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e) => (setParea({ ...Parea, CATEGORY5_export: e.target.checked }))} checked={Parea?.CATEGORY5_export} disabled={!disable} />
                                                                    <div class="state p-info-o  ml-2">
                                                                        <label className='mb-0'> Export/Print</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                        </tr>
                                                        <tr class="circletrgrp">
                                                            <td colspan="4">
                                                                <table class="table-bordered table-striped w-100 m-0 mb-4 mt-2" >
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="thalignleft text-start" style={{ width: "30%" }}>{NameObj?.CATEGORY5||'CATEGORY5'} Name</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>Full Access</th>

                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>View</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>Edit</th>
                                                                            <th scope="col" class="thalign" style={{ width: "12%" }}>Active/Inactive</th>
                                                                            <th scope="col" class="thalign" >Configuration</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {AreaData && AreaData.map((item) => (
                                                                            <tr>
                                                                                <td scope="row" class="thalignleft text-start font-normal"><><span className={item?.STATUS==1?'text-success':'text-danger'}><i className='fas fa-circle'></i></span></>{item?.CATEGORY5_NAME}</td>
                                                                                {userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="area_fullAccess" id="area_fullAccess" onClick={(e) => (onHandaleArea(e, item.UNIQUE_ID))} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                               { permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter((items) => items === item.UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2)&&permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.includes(item?.CATEGORY3)&&permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.includes(item?.CATEGORY4))||  userInfo?.ROLE === "ROLE1650614500708"?<td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY5_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e) => (onHandaleArea(e, item.UNIQUE_ID))} checked={Parea?.CATEGORY5_view?.filter((items) => items === item.UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_edit?.filter((items) => items === item.UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2)&&permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.includes(item?.CATEGORY3)&&permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.includes(item?.CATEGORY4))||userInfo?.ROLE === "ROLE1650614500708"?  <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY5_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e) => (onHandaleArea(e, item.UNIQUE_ID))} checked={Parea?.CATEGORY5_edit?.filter((items) => items === item.UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_active?.filter((items) => items === item.UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2)&&permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.includes(item?.CATEGORY3)&&permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.includes(item?.CATEGORY4))||userInfo?.ROLE === "ROLE1650614500708"?  <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY5_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleArea(e, item.UNIQUE_ID))} checked={Parea?.CATEGORY5_active?.filter((items) => items === item.UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                                { permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_config?.filter((items) => items === item.UNIQUE_ID).length > 0||(permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.includes(item?.CATEGORY0)&&permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.includes(item?.CATEGORY1)&&permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.includes(item?.CATEGORY2)&&permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.includes(item?.CATEGORY3)&&permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.includes(item?.CATEGORY4))||userInfo?.ROLE === "ROLE1650614500708"? <td class="thalign">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="CATEGORY5_config" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e) => (onHandaleArea(e, item.UNIQUE_ID))} checked={Parea?.CATEGORY5_config?.filter((items) => items === item.UNIQUE_ID).length > 0 ? true : false} disabled={!disable} />

                                                                                    </div>
                                                                                </td>:<td></td>}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <hr className='p-1'/>
                                                <h4 className='px-1 tw-pt-2 tw-text-xl tw-font-bold'>Tabs Permision</h4>
                                                <div className='mt-2'>
                                                <Table
                                                        columns={columns}
                                                        dataSource={SideBarTabs}
                                                        pagination={false}
                                                    />
                                                    </div>
                                                <hr className='p-1'/>
                                                <h4 className='px-1 tw-pt-2 tw-text-xl tw-font-bold'>Dashboard Access</h4>
                                                <table class="table border tw-text-center mt-4 " >
                                                    <tbody className='tw-w-full'>
                                                <tr className='' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                            <th className='text-start' scope="row"><h5 className=' tw-text-base tw-font-normal tw-mb-0'>Dashboard</h5></th>
                                                        </tr>
                                                        <tr class="circletrgrp">
                                                            <td colspan="4">
                                                                <table class="table-bordered table-striped w-100 m-0 mb-4 mt-2" style={{ marginTop: "10px" }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="thalignleft text-start tw-font-normal" style={{ width: "30%" }}>GSM Dashboard Name</th>
                                                                            <th scope="col" class="thalign tw-font-normal" style={{ width: "12%" }}>Access</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {[{name:"Custom Dashboard",value:"Custom_Dashboard"},{name:"Static Dashboard",value:"Static_Dashboard"}].map((item) => (
                                                                           permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes(item?.value)||userInfo?.ROLE === "ROLE1650614500708"? <tr>
                                                                                <td scope="row" class="thalignleft text-start font-normal">{item?.name}</td>
                                                                                <td class="thalign text-center">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="subdivision_fullAccess" id="subdivision_fullAccess" disabled={!disable}  onChange={(e)=>{
                                                                                            if(e.target.checked){
                                                                                            setOTHER_PERMIT({...OTHER_PERMIT,GSM_DASHBOARD:[...OTHER_PERMIT?.GSM_DASHBOARD||[],item?.value]})
                                                                                            }else{
                                                                                                setOTHER_PERMIT({...OTHER_PERMIT,GSM_DASHBOARD:OTHER_PERMIT?.GSM_DASHBOARD?.filter(inf=>inf!=item?.value)})
                                                                                            }
                                                                                        
                                                                                        }} checked={OTHER_PERMIT?.GSM_DASHBOARD?.filter(inf=>inf==item?.value).length>0}/>

                                                                                    </div>
                                                                                </td>
                                                                                
                                                                            </tr>:''
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr class="circletrgrp">
                                                            <td colspan="4">
                                                                <table class="table-bordered table-striped w-100 m-0 mb-4 mt-2" style={{ marginTop: "10px" }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="thalignleft text-start tw-font-normal" style={{ width: "30%" }}>Lorawan Dashboard Name</th>
                                                                            <th scope="col" class="thalign tw-font-normal" style={{ width: "12%" }}>Access</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {[{name:"Custom Dashboard",value:"Custom_Dashboard"},{name:"Static Dashboard",value:"Static_Dashboard"}].map((item) => (
                                                                           permision?.ROLES_OTHER_PERMISSIONS?.LORA_DASHBOARD?.includes(item?.value)||userInfo?.ROLE === "ROLE1650614500708"? <tr>
                                                                                <td scope="row" class="thalignleft text-start font-normal">{item?.name}</td>
                                                                                <td class="thalign text-center">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="subdivision_fullAccess" id="subdivision_fullAccess" disabled={!disable}  onChange={(e)=>{
                                                                                            if(e.target.checked){
                                                                                            setOTHER_PERMIT({...OTHER_PERMIT,LORA_DASHBOARD:[...OTHER_PERMIT?.LORA_DASHBOARD||[],item?.value]})
                                                                                            }else{
                                                                                                setOTHER_PERMIT({...OTHER_PERMIT,LORA_DASHBOARD:OTHER_PERMIT?.LORA_DASHBOARD?.filter(inf=>inf!=item?.value)})
                                                                                            }
                                                                                        
                                                                                        }} checked={OTHER_PERMIT?.LORA_DASHBOARD?.filter(inf=>inf==item?.value).length>0}/>

                                                                                    </div>
                                                                                </td>
                                                                                
                                                                            </tr>:''
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                        </table>
                                                <table class="table border tw-text-center mt-4 " >
                                                    <tbody className='tw-w-full'>
                                                <tr className='' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>

                                                            <th className='text-start' scope="row"><h5 className=' tw-text-base tw-font-normal tw-mb-0'>GSM Custom Dashboard</h5></th>
                                                            {permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.create === true || userInfo?.ROLE === "ROLE1650614500708"?<td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create"  disabled={!disable} onChange={(e)=>setOTHER_PERMIT({...OTHER_PERMIT,CUSTOM_DASHBOARD:{...OTHER_PERMIT.CUSTOM_DASHBOARD,create:e.target.checked}})} checked={OTHER_PERMIT?.CUSTOM_DASHBOARD?.create}/>
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Create</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.edit === true || userInfo?.ROLE === "ROLE1650614500708"? <td className=' tw-text-start tw-font-normal tw-py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create"  disabled={!disable} onChange={(e)=>setOTHER_PERMIT({...OTHER_PERMIT,CUSTOM_DASHBOARD:{...OTHER_PERMIT.CUSTOM_DASHBOARD,edit:e.target.checked}})} checked={OTHER_PERMIT?.CUSTOM_DASHBOARD?.edit}/>
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Edit Name</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_OTHER_PERMISSIONS?.CUSTOM_DASHBOARD?.card_add_in_dashboard === true || userInfo?.ROLE === "ROLE1650614500708"? <td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export"  disabled={!disable} onChange={(e)=>setOTHER_PERMIT({...OTHER_PERMIT,CUSTOM_DASHBOARD:{...OTHER_PERMIT.CUSTOM_DASHBOARD,card_add_in_dashboard:e.target.checked}})} checked={OTHER_PERMIT?.CUSTOM_DASHBOARD?.card_add_in_dashboard}/>
                                                                    <div class="state p-info-o  ml-2">
                                                                        <label className='mb-0'>Add/Remove Card In Dashboard </label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                        </tr>
                                                        <tr class="circletrgrp">
                                                            <td colspan="4">
                                                                <table class="table-bordered table-striped w-100 m-0 mb-4 mt-2" style={{ marginTop: "10px" }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="thalignleft text-start tw-font-normal" style={{ width: "30%" }}>Custom Dashboard Name</th>
                                                                            <th scope="col" class="thalign tw-font-normal" style={{ width: "12%" }}>View</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {DashTabs && DashTabs.map((item) => (
                                                                            <tr>
                                                                                <td scope="row" class="thalignleft text-start font-normal">{item?.name}</td>
                                                                                <td class="thalign text-center">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="subdivision_fullAccess" id="subdivision_fullAccess" disabled={!disable}  onChange={(e)=>{
                                                                                            if(e.target.checked){
                                                                                            setOTHER_PERMIT({...OTHER_PERMIT,CUSTOM_DASHBOARD:{...OTHER_PERMIT.CUSTOM_DASHBOARD,dashboard_view:[...OTHER_PERMIT?.CUSTOM_DASHBOARD?.dashboard_view||[],item?.unique_id]}})
                                                                                            }else{
                                                                                                setOTHER_PERMIT({...OTHER_PERMIT,CUSTOM_DASHBOARD:{...OTHER_PERMIT.CUSTOM_DASHBOARD,dashboard_view:OTHER_PERMIT?.CUSTOM_DASHBOARD?.dashboard_view?.filter(inf=>inf!=item?.unique_id)}})
                                                                                            }
                                                                                        
                                                                                        }} checked={OTHER_PERMIT?.CUSTOM_DASHBOARD?.dashboard_view?.filter(inf=>inf==item?.unique_id).length>0}/>

                                                                                    </div>
                                                                                </td>
                                                                                
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                        </table>
                                                        <table class="table border tw-text-center" >

                                                    <tbody className='tw-w-full'>
                                                <tr className='' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                            <th className='text-start' scope="row"><h5 className=' tw-text-base tw-font-normal tw-mb-0'>Lorawan Custom Dashboard</h5></th>
                                                            {permision?.ROLES_OTHER_PERMISSIONS?.LORA_CUSTOM_DASHBOARD?.create === true || userInfo?.ROLE === "ROLE1650614500708"? <td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create"  disabled={!disable} onChange={(e)=>setOTHER_PERMIT({...OTHER_PERMIT,LORA_CUSTOM_DASHBOARD:{...OTHER_PERMIT.LORA_CUSTOM_DASHBOARD,create:e.target.checked}})} checked={OTHER_PERMIT?.LORA_CUSTOM_DASHBOARD?.create}/>
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Create</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_OTHER_PERMISSIONS?.LORA_CUSTOM_DASHBOARD?.create === true || userInfo?.ROLE === "ROLE1650614500708"? <td className=' tw-text-start tw-font-normal tw-py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create"  disabled={!disable} onChange={(e)=>setOTHER_PERMIT({...OTHER_PERMIT,LORA_CUSTOM_DASHBOARD:{...OTHER_PERMIT.LORA_CUSTOM_DASHBOARD,edit:e.target.checked}})} checked={OTHER_PERMIT?.LORA_CUSTOM_DASHBOARD?.edit}/>
                                                                    <div class="state p-info-o ml-2">
                                                                        <label className='mb-0'> Edit Name</label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                            {permision?.ROLES_OTHER_PERMISSIONS?.LORA_CUSTOM_DASHBOARD?.create === true || userInfo?.ROLE === "ROLE1650614500708"? <td className='py-3'>
                                                                <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                                    <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export"  disabled={!disable} onChange={(e)=>setOTHER_PERMIT({...OTHER_PERMIT,LORA_CUSTOM_DASHBOARD:{...OTHER_PERMIT.LORA_CUSTOM_DASHBOARD,card_add_in_dashboard:e.target.checked}})} checked={OTHER_PERMIT?.LORA_CUSTOM_DASHBOARD?.card_add_in_dashboard}/>
                                                                    <div class="state p-info-o  ml-2">
                                                                        <label className='mb-0'>Add/Remove Card In Dashboard </label>
                                                                    </div>
                                                                </div>
                                                            </td>:''}
                                                        </tr>
                                                        <tr class="circletrgrp">
                                                            <td colspan="4">
                                                                <table class="table-bordered table-striped w-100 m-0 mb-4 mt-2" style={{ marginTop: "10px" }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="thalignleft text-start tw-font-normal" style={{ width: "30%" }}>Dashboard Name</th>
                                                                            <th scope="col" class="thalign tw-font-normal" style={{ width: "12%" }}>View</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {LoraDashTabs && LoraDashTabs.map((item) => (
                                                                            <tr>
                                                                                <td scope="row" class="thalignleft text-start font-normal">{item?.name}</td>
                                                                                <td class="thalign text-center">
                                                                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                                        <input type="checkbox" name="subdivision_fullAccess" id="subdivision_fullAccess" disabled={!disable}  onChange={(e)=>{
                                                                                            if(e.target.checked){
                                                                                            setOTHER_PERMIT({...OTHER_PERMIT,LORA_CUSTOM_DASHBOARD:{...OTHER_PERMIT.LORA_CUSTOM_DASHBOARD,dashboard_view:[...OTHER_PERMIT?.LORA_CUSTOM_DASHBOARD?.dashboard_view||[],item?.unique_id]}})
                                                                                            }else{
                                                                                                setOTHER_PERMIT({...OTHER_PERMIT,LORA_CUSTOM_DASHBOARD:{...OTHER_PERMIT.LORA_CUSTOM_DASHBOARD,dashboard_view:OTHER_PERMIT?.LORA_CUSTOM_DASHBOARD?.dashboard_view?.filter(inf=>inf!=item?.unique_id)}})
                                                                                            }
                                                                                        
                                                                                        }} checked={OTHER_PERMIT?.LORA_CUSTOM_DASHBOARD?.dashboard_view?.filter(inf=>inf==item?.unique_id).length>0}/>

                                                                                    </div>
                                                                                </td>
                                                                                
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                        </table>
                                                        <hr className='p-1'/>
                                                <h4 className='px-1 tw-pt-2 tw-text-xl tw-font-bold'>Other Permissions</h4>
                                                <div class="row py-2">
                                                <table class="table border  mt-4 ">
                                                        <tbody>  
                                                            <tr>  
                                                    <td class="col-5">
                                                        <h4 className='px-1 pt-1 tw-text-lg tw-font-bold'>GSM Consumer</h4>
                                                    </td>
                                                    {permision?.ROLES_OTHER_PERMISSIONS?.GSM_CONSUMER?.add === true || userInfo?.ROLE === "ROLE1650614500708"? <td class="col-2 tw-mt-2 align-left">
                                                        <div class="pretty p-icon p-curve p-pulse vertical-align thalign d-flex">
                                                            <input type="checkbox" name="View" id="report_view" value="yes" disabled={!disable} onChange={(e)=>{setOTHER_PERMIT({...OTHER_PERMIT,GSM_CONSUMER:{...OTHER_PERMIT.GSM_CONSUMER,add:e.target.checked}})}} checked={OTHER_PERMIT?.GSM_CONSUMER?.add===true?true:false}/>
                                                            <div class="state p-info-o  ml-2">
                                                                <label className='mb-0'>Add</label>
                                                            </div>
                                                        </div>
                                                    </td>:''}
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    </tr>
                                                    <tr>
                                                    <td class="col-5">
                                                        <h4 className='px-1 pt-1 tw-text-lg tw-font-bold'>Lorawan Consumer</h4>
                                                    </td>
                                                    {permision?.ROLES_OTHER_PERMISSIONS?.LORA_CONSUMER?.add === true || userInfo?.ROLE === "ROLE1650614500708"? <td class="col-2 tw-mt-2 align-left">
                                                        <div class="pretty p-icon p-curve p-pulse vertical-align thalign d-flex">
                                                            <input type="checkbox" name="View" id="report_view" value="yes" disabled={!disable} onChange={(e)=>{setOTHER_PERMIT({...OTHER_PERMIT,LORA_CONSUMER:{...OTHER_PERMIT.LORA_CONSUMER,add:e.target.checked}})}} checked={OTHER_PERMIT?.LORA_CONSUMER?.add===true?true:false}/>
                                                            <div class="state p-info-o  ml-2">
                                                                <label className='mb-0'>Add</label>
                                                            </div>
                                                        </div>
                                                    </td>:''}
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    </tr>
                                                    <tr>
                                                    <td class="col-5">
                                                        <h4 className='px-1 pt-1 tw-text-lg tw-font-bold'>Tags</h4>
                                                    </td>
                                                    {permision?.ROLES_OTHER_PERMISSIONS?.Tags?.add === true || userInfo?.ROLE === "ROLE1650614500708"? <td class=" tw-mt-2 align-left">
                                                        <div class="pretty p-icon p-curve p-pulse vertical-align thalign d-flex">
                                                            <input type="checkbox" name="View" id="report_view" value="yes" disabled={!disable} onChange={(e)=>{setOTHER_PERMIT({...OTHER_PERMIT,Tags:{...OTHER_PERMIT.Tags,add:e.target.checked}})}} checked={OTHER_PERMIT?.Tags?.add===true?true:false}/>
                                                            <div class="state p-info-o  ml-2">
                                                                <label className='mb-0'>Add</label>
                                                            </div>
                                                        </div>
                                                    </td>:''}
                                                    {permision?.ROLES_OTHER_PERMISSIONS?.Tags?.edit === true || userInfo?.ROLE === "ROLE1650614500708"? <td class=" tw-mt-2 align-left">
                                                        <div class="pretty p-icon p-curve p-pulse vertical-align thalign d-flex">
                                                            <input type="checkbox" name="report_view" id="report_view" value="yes" disabled={!disable} onChange={(e)=>{setOTHER_PERMIT({...OTHER_PERMIT,Tags:{...OTHER_PERMIT.Tags,edit:e.target.checked}})}} checked={OTHER_PERMIT?.Tags?.edit===true?true:false}/>
                                                            <div class="state p-info-o  ml-2">
                                                                <label className='mb-0'>Edit</label>
                                                            </div>
                                                        </div>
                                                    </td>:''}
                                                    {permision?.ROLES_OTHER_PERMISSIONS?.Tags?.active_inactive === true || userInfo?.ROLE === "ROLE1650614500708"? <td class=" tw-mt-2 align-left">
                                                        <div class="pretty p-icon p-curve p-pulse vertical-align thalign d-flex">
                                                            <input type="checkbox" name="report_view" id="report_view" value="yes" disabled={!disable} onChange={(e)=>{setOTHER_PERMIT({...OTHER_PERMIT,Tags:{...OTHER_PERMIT.Tags,active_inactive:e.target.checked}})}} checked={OTHER_PERMIT?.Tags?.active_inactive===true?true:false}/>
                                                            <div class="state p-info-o  ml-2">
                                                                <label className='mb-0'>Active/InActive</label>
                                                            </div>
                                                        </div>
                                                    </td>:''}
                                                    </tr>
                                                    <tr>
                                                    <td class="col-5">
                                                        <h4 className='px-1 pt-1 tw-text-lg tw-font-bold'>Task</h4>
                                                    </td>
                                                    {permision?.ROLES_OTHER_PERMISSIONS?.Task?.add === true || userInfo?.ROLE === "ROLE1650614500708"?<td class=" tw-mt-2 align-left">
                                                        <div class="pretty p-icon p-curve p-pulse vertical-align thalign d-flex">
                                                            <input type="checkbox" name="report_view" id="report_view" value="yes" disabled={!disable} onChange={(e)=>{setOTHER_PERMIT({...OTHER_PERMIT,Task:{...OTHER_PERMIT.Task,add:e.target.checked}})}} checked={OTHER_PERMIT?.Task?.add===true?true:false}/>
                                                            <div class="state p-info-o  ml-2">
                                                                <label className='mb-0'>Add</label>
                                                            </div>
                                                        </div>
                                                    </td>:''}
                                                    {permision?.ROLES_OTHER_PERMISSIONS?.Task?.edit === true || userInfo?.ROLE === "ROLE1650614500708"?  <td class=" tw-mt-2 align-left">
                                                        <div class="pretty p-icon p-curve p-pulse vertical-align thalign d-flex">
                                                            <input type="checkbox" name="report_view" id="report_view" value="yes" disabled={!disable} onChange={(e)=>{setOTHER_PERMIT({...OTHER_PERMIT,Task:{...OTHER_PERMIT.Task,edit:e.target.checked}})}} checked={OTHER_PERMIT?.Task?.edit===true?true:false}/>
                                                            <div class="state p-info-o  ml-2">
                                                                <label className='mb-0'>Edit</label>
                                                            </div>
                                                        </div>
                                                    </td>:''}
                                                    {permision?.ROLES_OTHER_PERMISSIONS?.Task?.active_inactive === true || userInfo?.ROLE === "ROLE1650614500708"? <td class=" tw-mt-2 align-left">
                                                        <div class="pretty p-icon p-curve p-pulse vertical-align thalign d-flex">
                                                            <input type="checkbox" name="report_view" id="report_view" value="yes" disabled={!disable} onChange={(e)=>{setOTHER_PERMIT({...OTHER_PERMIT,Task:{...OTHER_PERMIT.Task,active_inactive:e.target.checked}})}} checked={OTHER_PERMIT?.Task?.active_inactive===true?true:false}/>
                                                            <div class="state p-info-o  ml-2">
                                                                <label className='mb-0'>Active/Inactive</label>
                                                            </div>
                                                        </div>
                                                    </td>:''}
                                                    </tr>
                                                    </tbody>
                                                    </table>
                                                </div>
                                               
                                               
                                                
                                                
                                                <table class="table table-borderless border">
                                                    <thead className='border leading-8' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                        <tr>
                                                            <th scope="col" class="thalignleft text-start" style={{ width: "40%" }}>New Modem Registration Details (For Memighty Master Admin)</th>
                                                            <th scope="col" class="thalign text-start" style={{ width: "20%" }}>Access</th>
                                                            <th scope="col" class="thalign" ></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='leading-8 ' >
                                                        
                                                    {TabsGroup?.map((item)=>( permision?.ROLES_OTHER_PERMISSIONS?.Tabs?.filter((info)=>(info==item?.val)).length>0 || userInfo?.ROLE === "ROLE1650614500708"?<tr>
                                                            <th scope='row' className='text-start tw-font-normal'>
                                                                {item?.name}
                                                            </th>
                                                            <th className=' text-start font-normal'>
                                                            <input className='' type={"checkbox"} name="adani SAP Lorawan Billing Report" id={item?.val} onChange={onChangeTabs} checked={OTHER_PERMIT?.Tabs?.filter((info)=>(info==item?.val)).length>0?true:false} disabled={!disable}
                                                                ></input>
                                                            </th>
                                                            <th className='tw-cursor-pointer tw-text-blue-600'></th>
                                                        </tr>:''))}
                                                    </tbody>
                                                </table>
                                                <table class="table table-borderless border">
                                                    <thead className='border leading-8' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                        <tr>
                                                            <th scope="col" class="thalignleft  text-start" style={{ width: "40%" }}>Configuration Tabs</th>
                                                            <th scope="col" class="thalign  text-start" style={{ width: "20%" }}>Access</th>
                                                            <th scope="col" class="thalign" ></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='leading-8 ' >
                                                        
                                                    {ConsumerConfigTabs?.map((item)=>( permision?.ROLES_OTHER_PERMISSIONS?.ConsumerTabs?.filter((info)=>(info==item?.val)).length>0 || userInfo?.ROLE === "ROLE1650614500708"? <tr>
                                                            <th scope='row' className='text-start tw-font-normal'>
                                                                {item?.name}
                                                            </th>
                                                            <th className=' text-start font-normal'>
                                                            <input className='' type={"checkbox"} name="adani SAP Lorawan Billing Report" id={item?.val} onChange={onChangeConsumerTabs} checked={OTHER_PERMIT?.ConsumerTabs?.filter((info)=>(info==item?.val)).length>0?true:false} disabled={!disable}
                                                                ></input>
                                                            </th>
                                                            <th className='tw-cursor-pointer tw-text-blue-600'></th>
                                                        </tr>:''))}
                                                    </tbody>
                                                </table>
                                                <hr className='p-1'/>
                                                <h4 className='px-1 tw-pt-2 tw-text-xl tw-font-bold'>Reports Permissions</h4>
                                                <div class="row py-2">
                                                    <div class="col-5">
                                                        <h4 className='px-1 pt-1 tw-text-lg tw-font-bold'>Reports</h4>
                                                    </div>
                                                    <div class="col-5 tw-mt-2 align-left">
                                                        <div class="pretty p-icon p-curve p-pulse vertical-align thalign d-flex">
                                                            <input type="checkbox" name="report_view" id="report_view" value="yes" disabled={!disable} />
                                                            <div class="state p-info-o  ml-2">
                                                                <label className='mb-0'>View</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                                <table class="table table-borderless border">
                                                    <thead className='border leading-8' style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                                                        <tr>
                                                            <th scope="col" class="thalignleft text-start" style={{ width: "40%" }}>Report Type</th>
                                                            <th scope="col" class="thalign text-start" style={{ width: "20%" }}>Genrate</th>
                                                            <th scope="col" class="thalign" ></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='leading-8 ' >
                                                    {ReportTypes?.map((item)=>( <tr>
                                                            <th scope='row' className='text-start tw-font-normal'>
                                                                {item?.label}
                                                            </th>
                                                            <th className=' text-start font-normal'>
                                                            <input className='' type={"checkbox"} name="adani SAP Lorawan Billing Report" id={item?.value} onChange={onChangeReportChart} checked={OTHER_PERMIT?.Report?.filter((info)=>(info==item?.value)).length>0?true:false} disabled={!disable}
                                                                ></input>
                                                            </th>
                                                            <th scope="col" class="thalign" ></th>

                                                        </tr>))}
                                                       
                                                    </tbody>
                                                </table>
                                            </div>:''}
                                            <p class="text-danger">Note : * Indicates Mandatory Fields</p>
                                                <div class="form-group">
                                                    <div class="text-left">
                                                        <input type="submit" name="submit" class={`btn btn-danger ${btnLoader?'btn-progress disabled':''}`} value={rolesId === '' ? 'submit' : 'Update'} id="submitvn" onClick={onHandaleSubmit} disabled={!disable} />
                                                        <Link class="btn btn-light text-dark ml-2" to="/Roles">Cancel</Link>
                                                    </div>
                                                </div>
                                            {/* <div class="table-responsive">
                                 <table class="table table-striped">
                                    <thead>
                                       <tr>
                                          <th scope="col" style={{width: "50%"}}></th>
                                          <th scope="col" class="thalign" style={{width: "25%"}}> </th>
                                          <th scope="col" class="thalign" style={{width: "25%"}}> </th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <th scope="row "><h5>Account</h5></th>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e)=>(setPaccount({...Paccount,CATEGORY0_create:e.target.checked}))} checked={Paccount?.CATEGORY0_create} disabled={!disable}/>
                                                <div class="state p-info-o ml-2">
                                                   <label className='mb-0'> Create</label>
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e)=>(setPaccount({...Paccount,CATEGORY0_export:e.target.checked}))} checked={Paccount?.CATEGORY0_export} disabled={!disable}/>
                                                <div class="state p-info-o  ml-2">
                                                   <label className='mb-0'> Export/Print</label>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr class="accounttrgrp">
                                          <td colspan="4">
                                             <table class="table border" style={{marginTop:"10px"}}>
                                                <thead>
                                                <tr>
                                                      <th scope="col" class="thalignleft" style={{width: "30%"}}>Account Name</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>View</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Edit</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Active/Inactive</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                 {AccounData&&AccounData.map((item)=>(
                                                <tr>
                                                            <th scope="row" class="thalignleft">{item?.ACCOUNT}</th>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY0_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e)=>(onHandaleAccount(e,item.UNIQUE_ID))} checked={Paccount?.CATEGORY0_view?.filter((items)=>items===item.UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                    
                                                               </div>
                                                            </td>
                                                            
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY0_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e)=>(onHandaleAccount(e,item.UNIQUE_ID))} checked={Paccount?.CATEGORY0_edit?.filter((items)=>items===item.UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY0_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e)=>(onHandaleAccount(e,item.UNIQUE_ID))} checked={Paccount?.CATEGORY0_active?.filter((items)=>items===item.UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                     
                                                               </div>
                                                            </td>
                                                         </tr>
                                                 ))}  
                                                  </tbody>
                                             </table>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row"><h5>GA</h5></th>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e)=>(setPga({...Pga,CATEGORY1_create:e.target.checked}))} checked={Pga?.CATEGORY1_create} disabled={!disable}/>
                                                <div class="state p-info-o ml-2">
                                                   <label className='mb-0'> Create</label>
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e)=>(setPga({...Pga,CATEGORY1_export:e.target.checked}))} checked={Pga?.CATEGORY1_export} disabled={!disable}/>
                                                <div class="state p-info-o  ml-2">
                                                   <label className='mb-0'> Export/Print</label>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr class="gatrgrp">
                                          <td colspan="4">
                                             <table class="table border" style={{marginTop:"10px"}}>
                                                <thead>
                                                   <tr>
                                                      <th scope="col" class="thalignleft" style={{width: "30%"}}>GA Name</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>View</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Edit</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Active/Inactive</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                {GaData&&GaData.map((item)=>(
                                                <tr>
                                                            <th scope="row" class="thalignleft">{item?.GA_NAME}</th>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY1_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e)=>(onHandaleGa(e,item.CATEGORY1_UNIQUE_ID))}  checked={Pga?.CATEGORY1_view?.filter((items)=>items===item.CATEGORY1_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                    
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY1_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e)=>(onHandaleGa(e,item.CATEGORY1_UNIQUE_ID))} checked={Pga?.CATEGORY1_edit?.filter((items)=>items===item.CATEGORY1_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY1_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e)=>(onHandaleGa(e,item.CATEGORY1_UNIQUE_ID))} checked={Pga?.CATEGORY1_active?.filter((items)=>items===item.CATEGORY1_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                     
                                                               </div>
                                                            </td>
                                                         </tr>
                                                 ))}                                                     
                                                                                                   </tbody>
                                             </table>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row"><h5>Circle</h5></th>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e)=>(setPcircle({...Pcircle,CATEGORY2_create:e.target.checked}))} checked={Pcircle?.CATEGORY2_create}  disabled={!disable}/>
                                                <div class="state p-info-o ml-2">
                                                   <label className='mb-0'> Create</label>
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e)=>(setPcircle({...Pcircle,CATEGORY2_export:e.target.checked}))} checked={Pcircle?.CATEGORY2_export} disabled={!disable}/>
                                                <div class="state p-info-o  ml-2">
                                                   <label className='mb-0'> Export/Print</label>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr class="circletrgrp">
                                          <td colspan="4">
                                             <table class="table border" style={{marginTop:"10px"}}>
                                                <thead>
                                                   <tr>
                                                      <th scope="col" class="thalignleft" style={{width: "30%"}}>Circle Name</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>View</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Edit</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Active/Inactive</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                {CircleData&&CircleData.map((item)=>(
                                                <tr>
                                                            <th scope="row" class="thalignleft">{item?.CIRCLE_NAME}</th>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY2_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e)=>(onHandaleCircle(e,item.CATEGORY2_UNIQUE_ID))} checked={Pcircle?.CATEGORY2_view?.filter((items)=>items===item.CATEGORY2_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                    
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY2_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e)=>(onHandaleCircle(e,item.CATEGORY2_UNIQUE_ID))} checked={Pcircle?.CATEGORY2_edit?.filter((items)=>items===item.CATEGORY2_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                     
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY2_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e)=>(onHandaleCircle(e,item.CATEGORY2_UNIQUE_ID))} checked={Pcircle?.CATEGORY2_active?.filter((items)=>items===item.CATEGORY2_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                     
                                                               </div>
                                                            </td>
                                                         </tr>
                                                 ))}  
                                                                                                        
                                                                                                   </tbody>
                                             </table>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row"><h5>Division</h5></th>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e)=>(setPdivision({...Pdivision,CATEGORY3_create:e.target.checked}))} checked={Pdivision?.CATEGORY3_create} disabled={!disable}/>
                                                <div class="state p-info-o ml-2">
                                                   <label className='mb-0'> Create</label>
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e)=>(setPdivision({...Pdivision,CATEGORY3_export:e.target.checked}))} checked={Pdivision?.CATEGORY3_export} disabled={!disable}/>
                                                <div class="state p-info-o  ml-2">
                                                   <label className='mb-0'> Export/Print</label>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr class="circletrgrp">
                                          <td colspan="4">
                                             <table class="table border" style={{marginTop:"10px"}}>
                                                <thead>
                                                   <tr>
                                                      <th scope="col" class="thalignleft" style={{width: "30%"}}>Division Name</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>View</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Edit</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Active/Inactive</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                {DivisionData&&DivisionData.map((item)=>(
                                                <tr>
                                                            <th scope="row" class="thalignleft">{item?.DIVISION_NAME}</th>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY3_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e)=>(onHandaleDivision(e,item.CATEGORY3_UNIQUE_ID))} checked={Pdivision?.CATEGORY3_view?.filter((items)=>items===item.CATEGORY3_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                    
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY3_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e)=>(onHandaleDivision(e,item.CATEGORY3_UNIQUE_ID))} checked={Pdivision?.CATEGORY3_edit?.filter((items)=>items===item.CATEGORY3_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY3_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e)=>(onHandaleDivision(e,item.CATEGORY3_UNIQUE_ID))} checked={Pdivision?.CATEGORY3_active?.filter((items)=>items===item.CATEGORY3_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                 ))}                                            
                                               </tbody>
                                             </table>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row"><h5>Sub Division</h5></th>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e)=>(setPsubdivision({...Psubdivision,CATEGORY4_create:e.target.checked}))} checked={Psubdivision?.CATEGORY4_create} disabled={!disable}/>
                                                <div class="state p-info-o ml-2">
                                                   <label className='mb-0'> Create</label>
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e)=>(setPsubdivision({...Psubdivision,CATEGORY4_export:e.target.checked}))} checked={Psubdivision?.CATEGORY4_export} disabled={!disable}/>
                                                <div class="state p-info-o  ml-2">
                                                   <label className='mb-0'> Export/Print</label>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr class="circletrgrp">
                                          <td colspan="4">
                                             <table class="table border" style={{marginTop:"10px"}}>
                                                <thead>
                                                   <tr>
                                                      <th scope="col" class="thalignleft" style={{width: "30%"}}>Sub Division Name</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>View</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Edit</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Active/Inactive</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                {SubdevisionData&&SubdevisionData.map((item)=>(
                                                <tr>
                                                            <th scope="row" class="thalignleft">{item?.SUB_DIVISION_NAME}</th>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY4_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e)=>(onHandaleSubdivision(e,item.CATEGORY4_UNIQUE_ID))} checked={Psubdivision?.CATEGORY4_view?.filter((items)=>items===item.CATEGORY4_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                    
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY4_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e)=>(onHandaleSubdivision(e,item.CATEGORY4_UNIQUE_ID))} checked={Psubdivision?.CATEGORY4_edit?.filter((items)=>items===item.CATEGORY4_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                     
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY4_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e)=>(onHandaleSubdivision(e,item.CATEGORY4_UNIQUE_ID))} checked={Psubdivision?.CATEGORY4_active?.filter((items)=>items===item.CATEGORY4_UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                     
                                                               </div>
                                                            </td>
                                                         </tr>
                                                 ))}  
                                                   </tbody>
                                             </table>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row"><h5>Area</h5></th>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_create" id="CATEGORY5_create" onChange={(e)=>(setParea({...Parea,CATEGORY5_create:e.target.checked}))} checked={Parea?.CATEGORY5_create} disabled={!disable}/>
                                                <div class="state p-info-o ml-2">
                                                   <label className='mb-0'> Create</label>
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <div class="pretty p-icon p-curve p-pulse vertical-align d-flex">
                                                <input type="checkbox" name="CATEGORY5_export" id="CATEGORY5_export" onChange={(e)=>(setParea({...Parea,CATEGORY5_export:e.target.checked}))} checked={Parea?.CATEGORY5_export} disabled={!disable}/>
                                                <div class="state p-info-o  ml-2">
                                                   <label className='mb-0'> Export/Print</label>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr class="circletrgrp">
                                          <td colspan="4">
                                             <table class="table border" style={{marginTop:"10px"}}>
                                                <thead>
                                                   <tr>
                                                      <th scope="col" class="thalignleft" style={{width: "30%"}}>Area Name</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>View</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Edit</th>
                                                      <th scope="col" class="thalign" style={{width: "12%"}}>Active/Inactive</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                {AreaData&&AreaData.map((item)=>(
                                                <tr>
                                                            <th scope="row" class="thalignleft">{item?.NAME}</th>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY5_view" id="CATEGORY0_view_CAT01655984720942" onClick={(e)=>(onHandaleArea(e,item.UNIQUE_ID))} checked={Parea?.CATEGORY5_view?.filter((items)=>items===item.UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                    
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY5_edit" id="CATEGORY0_edit_CAT01655984720942" onClick={(e)=>(onHandaleArea(e,item.UNIQUE_ID))} checked={Parea?.CATEGORY5_edit?.filter((items)=>items===item.UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                     
                                                               </div>
                                                            </td>
                                                            <td class="thalign">
                                                               <div class="pretty p-icon p-curve p-pulse vertical-align thalign">
                                                                     <input type="checkbox" name="CATEGORY5_active" id="CATEGORY0_activeinactive_CAT01655984720942" onClick={(e)=>(onHandaleArea(e,item.UNIQUE_ID))} checked={Parea?.CATEGORY5_active?.filter((items)=>items===item.UNIQUE_ID).length>0?true:false} disabled={!disable}/>
                                                                     
                                                               </div>
                                                            </td>
                                                         </tr>
                                                 ))}  
                                                                                                   </tbody>
                                             </table>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div class="row">
                                 <div class="col-7">
                                    <h4>Reports *</h4>
                                 </div>
                                 <div class="col-5 align-left">
                                    <div class="pretty p-icon p-curve p-pulse vertical-align thalign d-flex">
                                          <input type="checkbox" name="report_view" id="report_view" value="yes" disabled={!disable}/>
                                          <div class="state p-info-o  ml-2">
                                             <label className='mb-0'>View</label>
                                          </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="table-responsive">
                                 <table class="table table-striped border">
                                    <thead>
                                       <tr>
                                          <th scope="col" class="thalignleft" style={{width: "30%"}}>Report Type</th>
                                          <th scope="col" class="thalign" style={{width: "12%"}}>Create</th>
                                          <th scope="col" class="thalign" style={{width: "12%"}}></th>
                                          <th scope="col" class="thalign" style={{width: "12%"}}></th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                     </tbody>
                                 </table>
                              </div> */}
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
