// import mqtt from 'mqtt'
import { DatePicker, Modal, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useInterval from "use-interval";
import { BootstrapTooltip, exportCSVFile, exportExcelDownload } from "../../../Commoncomponet/common";
import Pagination from "../../../Commoncomponet/Pagination";
import { geModemIdWiseColumnName, getConfigretionCMD_TYPEWisePendding_Count_buffer, getConfigretionCMD_TYPEWisePendding_Data_buffer, getHeardbeatData, getScriptCodeIdWiseColumnName, getScriptCodeIdWiseConfigretionData, getStartAndEndDateByTable, onModemConfigByGSMConsumer, reportCreacteInTableName } from "../../../Commoncomponet/Utils";
import Onlineoffline from "./ModemData/Onlineoffline";
import swal from 'sweetalert2';
import { CSVLink } from "react-csv";
import clockPending from '../../../assets/img/icons8-clock.gif'
import {socket} from '../../../Commoncomponet/common'
import * as XLSX from 'xlsx';
import Relayontime from "./ModemData/Relayontime";
import ScheduleEvent from "./ModemData/ScheduleEvent";
import ModemAlert from "./ModemData/ModemAlert";
import chroma from 'chroma-js';
import Unloackreq from "./ModemData/Unloackreq";
import { toast } from "react-toastify";
window.Swal = swal;
// import Instantaneous from "./MeterData/Instantaneous";
export default function ModemData(props) {
	const [buttonArray,setbuttonArray] = useState(["Heartbeat"]);
	const [FlagMsg,setFlagMsg]=useState(false)
  const [modelTitle,setmodelTitle]=useState('')
  const [modal,setModal]=useState(false)
    const [MqttMsg,setMqttMsg]=useState('')
    const [time, setTime] = useState(60);
	const [run, setRun] = useState(false);
  const [modalMsg,setmodalMsg]=useState(false)
	const [PageSize, setPageSize] = useState(50);
	const [numberofPage, setnumberofPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [heartData, setHeartData] = useState([])
	const [loader, setLoader] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [AllData, setAllData] = useState({})
	const [btnText, setButtontext] = useState("");
    const [ColumnName,setColumnName]=useState({})
	const [pendingBuffer,setpendingBuffer]=useState('')
	var [CommandJson,setCommandJson]=useState({})
    var [CommandmsgJson,setCommandmsgJson]=useState({})
    const [CommandStatus,setCommandStatus]=useState('')
	const [sortField,setsortField]=useState('SRNO')
	const [sortDirection,setsortDirection]=useState('desc')
	const mytime = useRef(null)

	useEffect(()=>{
		if(props?.GSMdata?.MODEM_TYPE=='MODUID1671705090942')
		{
			setbuttonArray([...buttonArray,"Modem Alert","Unlock request"])
			
		}else{}
	},[])

	const ongetConfigretionCMD_TYPEWisePendding_Count_buffer=async()=>{
		const data=await getConfigretionCMD_TYPEWisePendding_Count_buffer({CONSUMER_ID:props.GSMdata.UNIQUE_ID,CMD_TYPE:'HEARTBEAT',tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer})
		if(data.success===true){
			setpendingBuffer(data.data.data)
		}else{
			console.log('something want wrong')
		}
	}
	const onHandalebtn = (text) => {
		setButtontext(text);
	};


	const ongetScriptCodeIdWiseConfigretionData=async()=>{
		const data=await getScriptCodeIdWiseConfigretionData(props?.GSMdata?.SCRIPTDECODE_ID)
		if(data.success===true){
			const CMD=JSON.parse(data?.data?.data[0]?.METER_QUERY_CMD||'{}')
			const obj={MMD_ID_CMD:52,SCR_ID_CMD_TYPE:'HEARTBEAT',UNIQUE_ID:props?.GSMdata?.UNIQUE_ID,MMD_ID_IMEI:props?.GSMdata?.IMEI,SCRIPTDECODE_ID:props?.GSMdata?.SCRIPTDECODE_ID,MMD_ID_MSG:new Date().getTime()+1,TIMEZONE:props?.GSMdata?.TIMEZONE,GSM_BUFFER_TABLE:props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer}
			  setFlagMsg(false)
			  setmodelTitle(obj.SCR_ID_CMD_TYPE)
			//   alert(JSON.stringify(obj))
			  setCommandJson(obj)

			  swal.fire({
				  title: "Are you sure?",
				  text: "This will initiate downlink",
				  icon: "warning",
				  showCancelButton: true,
			  }).then(async (willDelete) => {
				  if (willDelete.isConfirmed) {
			  setModal(true)
			  
			  const data=await onModemConfigByGSMConsumer({config_info:JSON.stringify(obj)})
			  if(data.success===true){
				mytime.current =setTimeout(() => {
					console.log(CommandStatus);
					if(Object.keys(CommandStatus).length===0)
										 	{
											 setCommandStatus({SCR_STATUS_CMD_ADD:'Time Out'})
										 }
										 },5000)  
			  }else{
				 
			  }
		  }
		  })
		}
	}
	useInterval(
	  () => {
		  if (time <= 1) {
			  setRun(false);
			  setModal(false)
			 
		  }
		  setTime((preState) => preState - 1);
	  },
	  time && run ? 1000 : null
	);
	
	function zeroPad(num) {
	  var str = String(num);
	  if (str.length < 2) {
		  return "0" + str;
	  }
	
	  return str;
	}
	
	const CloseModel=()=>{
	  setFlagMsg(true)
	  setRun(false);
	  setTime(0)
	  setModal(false)
	  setCommandmsgJson({})
    setCommandStatus('')
	//   swal.fire('', 'Command Add In Buffer', 'success');
	}
	const [StatusCode,setStatusCode]=useState('')
	const ongetHeardbeatData = async () => {
		setLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		const data = await getHeardbeatData({ start: firstPageIndex, end: PageSize }, { tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.heartbeatdata,colName:sortField,sortDirection:sortDirection })
		if (data.success === true) {
			setHeartData(data.data.data)
			setnumberofPage(data.data.total)
			setLoader(false)

		} else {
			setStatusCode(data?.err?.response?.status)
			console.log('something want wrong')
			setLoader(false)

		}
	}
	useEffect(()=>{
		ongetHeardbeatData()
	},[sortDirection,sortField])
	const ongetScriptCodeIdWiseColumnName=async()=>{
		
		const data=await geModemIdWiseColumnName(props?.GSMdata?.MODEM_TYPE)
        if(data.success===true){
            if(data?.data?.data?.length>0){
            setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
            }
        }else{
console.log('something want wrong');
        }
    }
	useEffect(() => {
		if (btnText === 'Heartbeat') {
			ongetHeardbeatData()
			// ongetConfigretionCMD_TYPEWisePendding_Count_buffer()
			
		}
		ongetScriptCodeIdWiseColumnName()
	}, [btnText]);
			useEffect(() => {
				socket.on('mqttbufferresponse',(massges)=>{
					console.log(massges);
					setCommandStatus(massges)
					
				})
				socket.on('mqttcommandresponse',(massges)=>{
					console.log(massges,CommandJson,massges.MSGID==CommandJson.MSGID,massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID,massges.UNIQUE_ID==CommandJson.UNIQUE_ID,massges.CMD_TYPE==CommandJson.CMD_TYPE,massges.DRXTYPE,!massges.STATUS);
					setCommandmsgJson(massges)
				})
			}, []);
	const BfferError=(msg)=>{
		console.log(msg.MSGID,CommandJson.MSGID);
		if(msg.MMD_ID_MSG==CommandJson.MMD_ID_MSG){
		swal.fire('', msg?.SCR_STATUS_CMD_ADD, 'error');
		setCommandStatus('')
		setFlagMsg(true)
		setRun(false);
		setTime(0)
		setModal(false)
	}
	}
	useEffect(()=>{
		if(CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'&&Object.keys(CommandStatus).length>0){
		
			BfferError(CommandStatus)
				clearTimeout(mytime.current);
				mytime.current = null
		}else if(CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'){
			
			setTime(60)
		setRun(true);
			clearTimeout(mytime.current);
			clearTimeout(mytime.current);
				mytime.current = null
		}
	},[CommandStatus])
	const getPopdata=(massges)=>{
		console.log(massges,CommandJson);
	if(massges.MMD_ID_MSG==CommandJson.MMD_ID_MSG&&massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID&&massges.UNIQUE_ID==CommandJson.UNIQUE_ID&&massges.SCR_ID_CMD_TYPE==CommandJson.SCR_ID_CMD_TYPE&&massges.MMD_ID_DRXTYPE&&!massges.STATUS){
			setTime(0)
			setRun(false);
		   setModal(false)
		   if(FlagMsg===false){
		   setmodalMsg(true)
		   setMqttMsg(JSON.stringify(massges))
			}
			
		}
	}
	
	useEffect(()=>{
			if(Object.keys(CommandmsgJson).length>0){
			getPopdata(CommandmsgJson)
			}
	},[CommandmsgJson])
	useEffect(() => {
		if (btnText === 'Heartbeat') {
			ongetHeardbeatData()
		}
	}, [currentPage, PageSize])
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onAllShowData = (item) => {
		setIsModalVisible(true)
		setAllData(item)
	}

	const csvLink = useRef();
    const [CsvData,setCsvData]=useState([])
	const [report, setReport] = useState({ value: '', name: '', start_date: '', end_date: '' })
	const [reportType, setReportType] = useState('')
	const [ismodel, setModel] = useState(false)
	const [ReportLoader,setReportLoader]=useState(false)
	const [ExpireDate,setExpireDate]=useState({})
	const ongetStartAndEndDateByTable = async (tableName, col) => {
		console.log("Wanted to check if this is calling or not")
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.heartbeatdata, col: 'ENTRYTS' })
		if (data.success === true) {

			console.log("Expiry Dates == ", data.data)
			setExpireDate(data.data)
			setModel(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}
	const ReportType=[{label:'View Table Info',value:'View_Table_Info'},{label:'All Table Info',value:'All_Table_Info'},{label:'Full Table',value:'Full_Table'}]
	const onHandalReport = (e) => {
		setReport({ ...report, value: e.target.value, name: 'All GSM Consumer Details' })
	}
    const ongetGenretedAllGSMReport=async(Iscsv)=>{
		if(report?.value!=''&&reportType!=''){
			if(report?.value!='Full_Table'){
				if(report?.start_date&&report?.end_date){
					const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x>y,x,y);
					if(x>y){
					return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
					}
				}else{
					return	toast.warn('Please Select "Start Date" And "End Date"')
				}
			}
			setReportLoader(true)
        const data=await reportCreacteInTableName({tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.heartbeatdata,col:'ENTRYTS', start_date: moment(report.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"), end_date: moment(report.end_date, "DD-MM-YYYY").format("YYYY-MM-DD"),IsCondition:report?.value=='Full_Table'?false:true})
        if(data.success==true){
			var arrayofData=[]
			var headers={}
			 
			if(data.data.data.length==0){
		setReportLoader(false)
		toast.warn('Data Not Found')
			}
			var TypeReport=report?.value
				data.data.data.map((item,id)=>{
					arrayofData[id]={}
					ColumnName?.Modem_Data?.heartbeatdata?.length>0&&ColumnName?.Modem_Data?.heartbeatdata?.map((col)=>{
						if(TypeReport=='View_Table_Info'){
						if(col?.Display=="Yes"){
							if(id==0){headers[col.Header]=col.Header}
							if(col?.Type=='datetime')
							{
								arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
							}else{
								if(col?.Float!=null&&col?.Float!=undefined){
									arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
								}else{
								arrayofData[id][col.Header]=item[col.Column]
								}
							}
						}
					}else if(report?.value=='All_Table_Info'||report?.value=='Full_Table'){
						if(id==0){headers[col.Header]=col.Header}
						if(col?.Type=='datetime')
							{
								arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
							}else{
								if(col?.Float!=null&&col?.Float!=undefined){
									arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
								}else{
								arrayofData[id][col.Header]=item[col.Column]
								}
							}
					}
						
					})
					if(id==(data.data.data.length-1)){
						if(reportType==='csv'){
							if(report?.value!='Full_Table'){
							exportCSVFile(headers,arrayofData,`Heartbeat_`+'('+report.start_date+')'+'_'+'('+report.end_date+')')
							}else{
								exportCSVFile(headers,arrayofData,`Heartbeat`)

							}
		setReportLoader(false)

						 }else{
							if(report?.value!='Full_Table'){
							exportExcelDownload(arrayofData,`Heartbeat_`+'('+report.start_date+')'+'_'+'('+report.end_date+')')
							}else{
								exportExcelDownload(arrayofData,`Heartbeat`)
							}
		setReportLoader(false)
						 }
						}
			})
			
            
        }else{
            console.log('something want wrong')
			setReportLoader(false)
        }
	}else{
		return	toast.warn('Please Enter Mandatory Fields')
	}
    }
	
	const [BufferPageSize, setBufferPageSize] = useState(50)
    const [BuffernumberofPage, setBuffernumberofPage] = useState(0)
    const [BuffercurrentPage, setBufferCurrentPage] = useState(1);
      const [BufferPenddingData,setBufferPenddingData]=useState([])
      const [modalBufferDetails,setmodalBufferDetails]=useState(false)
  
    const onBufferPenddingData=async(col)=>{
      setmodelTitle(col)
    
      setmodalBufferDetails(true)
      const firstPageIndex = (BuffercurrentPage - 1) * BuffercurrentPage;
    
      const data=await getConfigretionCMD_TYPEWisePendding_Data_buffer({CONSUMER_ID:props.GSMdata.UNIQUE_ID,CMD_TYPE:col,start:firstPageIndex,end:BufferPageSize,tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer})
      if(data.success===true){
    setBuffernumberofPage(data.data.Total)
    setBufferPenddingData(data.data.data)
      }else{
      console.log('something want wrong')
    
      }
    }
    
    useEffect(() => {
      if(BufferPenddingData.length>0){
        onBufferPenddingData()
      }
     }, [BuffercurrentPage, BufferPageSize])

	 const onSort = (field) => {
        if (sortField === field) {
            setsortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setsortField(field);
            setsortDirection('asc');
        }
    };
	return (
		<>

<Modal title="Generate Reports" visible={ismodel} footer='' onCancel={()=>setModel(false)} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={onHandalReport}>
												<option value="">Report Type</option>
												{ReportType?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div>
										{report?.value!=''&&report?.value!='Full_Table'?<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
												}} />
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
													}} />
											</div></>:''}
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLS</option>
												<option value="csv">CSV</option>
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader==true?'disabled btn-progress':''}`} value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
										<Link className="btn btn-light text-dark" onClick={()=>setModel(false)}>Cancel</Link>
									</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
		<Modal title={modelTitle+'('+(BuffernumberofPage?BuffernumberofPage:0)+')'}  visible={modalBufferDetails} className="my-modal-class" footer='' maskClosable={false} onCancel={() => (setmodalBufferDetails(false))} width={1500}>

<div className="table-responsive mt-2">
								<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
									<div class="dataTables_length ml-2" id="table_id_length">
										<label>
											Show{" "}
											<select name="table_id_length " aria-controls="table_id" class="" onChange={(e) => (setBufferPageSize(e.target.value))} value={BufferPageSize}>
												<option value={10}>10</option>
												<option value={25}>25</option>
												<option value={50}>50</option>
												<option value={100}>100</option>
											</select>{" "}
											entries
										</label>
									</div>
									
									<table id="table_id" className="tableCustom table table-striped">
										<thead>
											<tr>
											{ ColumnName?.GSM_BUFFER?.length>0&&ColumnName?.GSM_BUFFER?.map((col)=>(col?.Display==true?<th className=""><BootstrapTooltip title={col?.Column}>
                                        <div>{col?.Header}</div>
                                        </BootstrapTooltip></th>:''))}
											</tr>
										</thead>
										<tbody>
											{BufferPenddingData.length > 0 && BufferPenddingData.map((item, id) => (
												<tr className="trheigth" >
												 { ColumnName?.GSM_BUFFER?.length>0&&ColumnName?.GSM_BUFFER?.map((col)=>(col?.Display==false?'':col?.DataType=='DATETIME'?<td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>:col.Column=='SCR_STATUS_CMD'?<td>{item?.SCR_STATUS_CMD==1? <div class="badge badge-success badge-shadow">Success</div>:item?.SCR_CNT_CMD_RETRY<0&&item?.SCR_STATUS_CMD==0?<div class="badge badge-warning badge-shadow">Waiting For Downlink</div>:item?.SCR_STATUS_CMD==2?<div class="badge badge-info badge-shadow">Canceled By User</div>:item?.SCR_STATUS_CMD==-1?<div class="badge badge-danger badge-shadow">Timeout</div>:item?.SCR_CNT_CMD_RETRY>=0&&item?.SCR_STATUS_CMD==0?<div class="badge badge-primary text-white badge-shadow">Waiting For Uplink</div>:''}</td>:col.Column=='SCR_ID_CMD_ADDED_BY'?<td>{item?.SCR_ID_CMD_ADDED_BY===0?<div class="badge badge-warning badge-shadow">Script</div>:<div class="badge badge-success badge-shadow">User</div>}</td>:<td>{item[col.Column]}</td>))}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
							<><div className='d-flex justify-content-between'>
								<div>Total Number Of Row {BuffernumberofPage||0}</div><Pagination
									className="pagination-bar"
									currentPage={BuffercurrentPage}
									totalCount={BuffernumberofPage || 0}
									pageSize={BufferPageSize}
									onPageChange={(page) => setBufferCurrentPage(page)}
								/></div></>
</Modal>
		  <Modal  visible={modal} footer='' maskClosable={false} onCancel={() => (setModal(false))} width={500}>
    <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting {modelTitle} Command</h5>
    <h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!CommandStatus?.SCR_STATUS_CMD_ADD&&CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<i className='fas fa-times mr-2' style={{color:'red'}}></i>:<i className='fas fa-check mr-2' style={{color:'green'}}></i>}{!CommandStatus?.SCR_STATUS_CMD_ADD?'Command Pending':CommandStatus?.SCR_STATUS_CMD_ADD}</h5>
    <div class=""> 
   { CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'? <div class="text-center" style={{marginTop:"10px",fontSize: "20px"}}>
                     <label style={{fontWeight:"400"}}>Waiting For Respose</label>
                     <div id="meter_data_mqtt_timer_status" style={{fontSize: "18px"}}>{`${zeroPad(time)}`} seconds left</div>
                  </div>:''}
               </div>
               <div class="text-center" style={{marginTop:"20px"}}>
                  <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={CloseModel}/>
               </div>
            </Modal>
            <Modal  visible={modalMsg} footer='' maskClosable={false} onCancel={() => (setmodalMsg(false))} width={500}>
    {MqttMsg}
            </Modal>
			<Modal title="All Data" visible={isModalVisible} onOk={() => (setIsModalVisible(false))} footer='' onCancel={handleCancel} width={1000}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Data</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
						{ ColumnName?.Modem_Data?.heartbeatdata?.map((col)=>(col?.Type=='datetime'?<tr>
								<td style={{ width: "50%" }}>{col?.Column}</td>
								<td style={{ width: "50%" }}>{AllData[col?.Column]?moment.utc(AllData[col?.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>
							</tr>:<tr>
								<td style={{ width: "50%" }}>{col?.Column}</td>
								<td style={col?.Column=='UNITS'||col?.Column=='ERRORBIN'||col?.Column=='RAWDATA'?{ maxWidth: '500px'}:{ width: "50%" }}>{AllData[col?.Column]}</td>
							</tr>))}
						</tbody>
					</table>
				</div>

			</Modal>
			<div className="card-body" style={{ paddingTop: "0px", paddingLeft: '15px', paddingBottom: '5px' }}>
				<div className="d-flex justify-content-between">
					<div className="buttons">
						{buttonArray.map((item, id) => (
							<button
							style={{
								backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#3abaf4').alpha(0.4).css() : '',
								color: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#3abaf4').darken(1.5).css() : '#3abaf4',
								borderBottom: `1px solid ${'#3abaf4'}`,
								boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${'#3abaf4'}`  : '',
							}}
							className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
							onClick={() => onHandalebtn(item)}>
							{item}
						</button>
						))}
					</div>
				</div>
			</div>
			
			{btnText === 'Heartbeat' && <section className="card-diff-section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4><BootstrapTooltip title={`Table Name is ${props?.GSMdata?.UNIQUE_TABLE_NAME?.heartbeatdata}`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
									<div className="card-header-action">
										<div id="filtergrm">
										
											{/* <button type="button" id="mqtt_get_cmd_301" class="btn btn-info mr-1" onClick={ongetScriptCodeIdWiseConfigretionData}>
												Get Data
											</button> */}
											<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={()=>{ongetHeardbeatData()
											ongetConfigretionCMD_TYPEWisePendding_Count_buffer()}}>
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a>
											<div class="d-inline">
                      <button class="btn btn-info" onClick={()=>ongetStartAndEndDateByTable()}>
                      <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                     
                    </div>
										</div>
									</div>
								</div>
								<div className="card-body">
									{loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)}
									{heartData.length === 0 && !loader ? StatusCode==503?<h4 className="text-center">Table Not Found</h4> :<h4 className="text-center">No Data Found</h4>:''}
									{heartData.length > 0 &&
										<div className="table-responsive tw-h-screen tw-max-h-screen">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												
												<table id="table_id" className="table border text-center table-borderless table-striped font-10">
													<thead className='tw-sticky tw-z-10 tw-top-0  tw-bg-gray-100'>
														<tr className="tw-border-b text-center">
														{ ColumnName?.Modem_Data?.heartbeatdata?.length>0&&ColumnName?.Modem_Data?.heartbeatdata?.map((col)=>(col?.Display=="Yes"?<BootstrapTooltip title={col?.Column}><th onClick={()=>onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'>
                                        <div >{col?.Header}</div>
										{col?.Notes&&<BootstrapTooltip title={col?.Notes} placement='top'><span><i className="ml-2 fas fa-info-circle tw-text-xs"></i></span></BootstrapTooltip>}
                                        <div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                    </div></th>
									</BootstrapTooltip>:''))}
															<th>ACTION</th>
														</tr>
													</thead>
													<tbody >
														{heartData.length > 0 && heartData.map((item, id) => (
															<tr className="trheigth  ">
															{ ColumnName?.Modem_Data?.heartbeatdata?.length>0&&ColumnName?.Modem_Data?.heartbeatdata
															?.map((col)=>(col?.Display=="Yes"?col?.Type=='datetime'?
															<td style={{ minWidth: "100px" }}>{item[col.Column]?
															item[col.Column]!='0000-00-00 00:00:00'?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):item[col.Column]
															:''}</td>:
															col?.Float!=null&&col?.Float!=undefined?col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]/col?.DivisionNumber).toFixed(col?.Float)}</td>:<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{col?.Float!=null&&col?.Float!=undefined&&item[col.Column]?item[col.Column].toFixed(col?.Float):item[col.Column]}</td>:
															col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]/col?.DivisionNumber}</td>:
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]}</td>
															:''))}
																{/* <td>{moment.utc(item.ENTRYTS).format("DD/MM/YYYY hh:mm:ss A")}</td> */}
																<td className="font-13"><a class="action-icon text-success" onClick={() => onAllShowData(item)}><span data-toggle="tooltip" data-placement="bottom" title="AllData Data"><i class="fa fa-database fa-sm" aria-hidden="true"></i></span></a>&nbsp;</td>
															</tr>
														))}
													</tbody>
												</table>
												<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
											</div>
										</div>}

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>}
			{btnText === 'Modem Alert' &&<ModemAlert GSMdata={props.GSMdata}/>}
			{btnText==='Unlock request'&&<Unloackreq GSMdata={props.GSMdata} onConsumerUpdateDate={props?.onConsumerUpdateDate}/>}

		</>
	);
}
