import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import Pagination from '../Pagination';
import classnames from 'classnames';

const DataTable = ({ data, columns, initialSortField, initialSortDirection, onRowClick, filterable, pageSize, theadClassName, tbodyClassName, thClassName, tableClassName = '', headerRowClassName = '', rowClassName = '', cellClassName = '', filterClassName = '' }) => {
    const [filter, setFilter] = useState('');
    const [sortField, setSortField] = useState(initialSortField || columns[0].field);
    const [sortDirection, setSortDirection] = useState(initialSortDirection || 'asc');
    const [pageSizeState, setPageSizeState] = useState(pageSize || data.length);
    const [currentPage, setCurrentPage] = useState(0);

    const filteredData = useMemo(() => {
        if (!filterable || !filter) {
            return data;
        }

        const normalizedFilter = filter.toLowerCase().trim();
        return data.filter((row) => {
            return columns.some((column) => {
                const value = String(row[column.field]).toLowerCase();
                return value.includes(normalizedFilter);
            });
        });
    }, [data, columns, filterable, filter]);

    const onSort = (field) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const sortedData = useMemo(() => {
        const direction = sortDirection === 'asc' ? 1 : -1;
        const sorted = [...filteredData].sort((a, b) => {
            const valueA = a[sortField];
            const valueB = b[sortField];

            if (valueA === valueB) {
                return 0;
            }

            if (valueA === null || valueA === undefined) {
                return direction;
            }

            if (valueB === null || valueB === undefined) {
                return -direction;
            }

            if (typeof valueA === 'string' && typeof valueB === 'string') {
                return direction * valueA.localeCompare(valueB);
            }

            return direction * (valueA < valueB ? -1 : 1);
        });

        return sorted;
    }, [filteredData, sortField, sortDirection]);

    const pageCount = useMemo(() => Math.ceil(sortedData.length / pageSizeState), [sortedData, pageSizeState]);


    const formattedDate = (value) => {
        const date = moment(value);
        if (date.isValid()) {
            if (date.hours() || date.minutes() || date.seconds()) {
                return date.format('DD-MM-YY hh:mm:ss A');
            } else {
                return date.format('DD-MM-YYYY');
            }
        }
        return '';
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const startRow = currentPage * pageSizeState;
    const endRow = (currentPage + 1) * pageSizeState;

    const visibleColumns = useMemo(() => {
        return columns.filter((column) => column.isVisible !== false);
    }, [columns]);

    const handleRowClick = (row) => {
        if (onRowClick) {
            onRowClick(row);
        }
    };

    const renderCell = (row, column) => {
        const value = row[column.field];
        if (column.cellRenderer) {
            return column.cellRenderer(value, row, column);
        } else {
            return value;
        }
    };

    return (
        <div className='table-responsive'>
            {filterable && (
                <div className={classnames('tw-float-right', filterClassName)}>
                    <label className='tw-p-2'>
                        <b className='tw-cursor-pointer'>Search:</b>
                        <input className='tw-ml-2 border !tw-border-indigo-200 tw-p-1 tw-rounded focus:!tw-outline-none focus:!tw-border-indigo-500' type="text" value={filter} onChange={(e) => setFilter(e.target.value)} />
                    </label>
                </div>
            )}
            <div className="dataTables_wrapper no-footer">
                <table className={classnames('table table-stripe', tableClassName)}>
                    <thead className={classnames(theadClassName)}>
                        <tr className={classnames(headerRowClassName)}>
                            {visibleColumns.map((column) => (
                                <th className={classnames(thClassName)} key={column.field} onClick={() => onSort(column.field)}>
                                    <div className='tw-flex tw-items-center'>
                                        <div>{column.label}</div>
                                        <div>{sortField === column.field && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={classnames(tbodyClassName)}>
                        {sortedData.slice(startRow, endRow).map((row, i) => (
                            <tr key={i} className={classnames(rowClassName)} onClick={() => handleRowClick(row)} >
                                {visibleColumns.map((column) => (
                                    <td key={column.field} className={classnames(cellClassName)}>{column.type === 'datetime' || column.type === 'date' ? formattedDate(row[column.field]) : renderCell(row, column)}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>

                </table>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {filterable && (
                        <div>
                            Showing {startRow + 1}-{Math.min(endRow, filteredData.length)} of {filteredData.length} records
                        </div>
                    )}
                    {pageSize && (
                        <div>
                            Show{' '}
                            <select value={pageSizeState} onChange={(e) => setPageSizeState(Number(e.target.value))}>
                                {[10, 20, 50, 100].map((size) => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>{' '}
                            records per page
                        </div>
                    )}
                    <div>
                        {pageCount > 1 && (
                            <div>
                                {/* {[...Array(pageCount).keys()].map((page) => (
                                                <button key={page} onClick={() => onPageChange(page)} disabled={page === currentPage}>
                                                    {page + 1}
                                                </button>
                                            ))} */}

                                <Pagination className="pagination-bar" currentPage={currentPage + 1} totalCount={filteredData?.length} onPageChange={(page) => onPageChange(page - 1)} pageSize={pageSizeState} />
                            </div>
                        )}

                    </div>
                </div>
            </div>

        </div>
    );
};

export default DataTable;