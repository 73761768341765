import React from 'react'
import Documentation from './Documentation'
import apiDocsjson from './apiDocs.json'
import { Link } from 'react-router-dom'

const ShowApi = () => {
    return (
        <>
            <div className="main-content">
                <section className="section">
                    <div className="card">
                        <div className="card-header">
                            <h4></h4>
                            <div className="card-header-action tw-text-indigo-500 tw-cursor-pointer tw-hover:tw-text-indigo-700 tw-font-semibold">
                                <Link to='/api/documentationList' className='btn btn-primary'>Back</Link>
                            </div>
                        </div>
                        <div className='card-body'>
                            <Documentation data={apiDocsjson} />
                        </div>

                    </div>
                </section>
            </div>
        </>
    )
}

export default ShowApi