import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { GetMssqlCardDetailsById } from '../../../Commoncomponet/Utils'

const Credentials = () => {

    const [credentialdetails,setcredentialdetails]=useState({})
    const params = useParams();

    const getCredential=async()=>{
        const data=await GetMssqlCardDetailsById(params?.MSID)
        if(data.success==true){
            setcredentialdetails(data?.data?.data)
        }else{
            console.log('something want wrong')
    }
}
    useEffect(()=>{
        getCredential()
    },[])
    return (
        <>
            <div className="Notcard">
                <div className="row ">
                    <div className="tw-px-6">
                        <form method="post" name="main_menu_add" id="main_menu_add">
                            <fieldset className="customLegend">
                                <legend>Profile Details</legend>
                                <div className="form-row rounded p-3" style={{ marginLeft: '10px', borderColor: '#e3e6fc !important', position: 'relative' }}>
                                    <div className="form-group col-md-3">
                                        <label for="inputPassword4">Profile Name</label>
                                        <input className="form-control" id="profile_name" name="profile_name" type="text" disabled value={credentialdetails?.PROFILE_NAME} placeholder="Profile Name" />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label for="profile_description">Profile Description</label>
                                        <input className="form-control" id="profile_description" name="profile_description" disabled type="text" value={credentialdetails?.PROFILE_DESCRIPTION} placeholder="Profile Description" />
                                    </div>

                                </div>
                            </fieldset>

                            <div className="col-lg-12 tw-mt-3" >
                                <ul className="nav nav-pills border" id="myTab3" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="profile-tab3" data-toggle="tab" href="#profile3" role="tab" aria-controls="profile" aria-selected="false">Credential</a>
                                    </li>
                                    {credentialdetails?.TYPE=='API'?'':<li className="nav-item">
                                        <a className="nav-link" id="contact-tab3" data-toggle="tab" href="#contact3" role="tab" aria-controls="contact" aria-selected="false">Scheduler</a>
                                    </li>}

                                    <li className="nav-item">
                                        <a className="nav-link" id="others-tab3" data-toggle="tab" href="#others3" role="tab" aria-controls="others" aria-selected="false" onClick={{}}>Others</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent2">
                                    <div className="tab-pane fade show active" id="profile3" role="tabpanel" aria-labelledby="profile-tab3">
                                        <div className="row">
                                        {credentialdetails?.TYPE=='API'?<div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="db_host_name">API Endpoint:</label>
                                                    <input type="text" className="form-control present_home_no" name="db_host_name" disabled id="db_host_name" placeholder="Host Name" value={JSON.parse(credentialdetails?.CREDENTIALS||'{}')?.api_endpoint} />
                                                </div>
                                            </div>:<div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="db_host_name">Host Name:</label>
                                                    <input type="text" className="form-control present_home_no" name="db_host_name" disabled id="db_host_name" placeholder="Host Name" value={JSON.parse(credentialdetails?.CREDENTIALS||'{}')?.server} />
                                                </div>
                                            </div>}
                                            {credentialdetails?.TYPE!='API'?<div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="db_database_name">Database Name:</label>
                                                    <input type="text" className="form-control" name="db_database_name" id="db_database_name" disabled placeholder="Database Name" value={JSON.parse(credentialdetails?.CREDENTIALS||'{}')?.options?.database} />
                                                </div>
                                            </div>:<br/>}

                                            {credentialdetails?.TYPE=='API'?  <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="db_user_name">User Name:</label>
                                                    <input type="text" className="form-control" name="db_user_name" disabled id="db_user_name" placeholder="User Name" value={JSON.parse(credentialdetails?.CREDENTIALS||'{}')?.auth?.username} />
                                                </div>
                                            </div>:<div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="db_user_name">User Name:</label>
                                                    <input type="text" className="form-control" name="db_user_name" disabled id="db_user_name" placeholder="User Name" value={JSON.parse(credentialdetails?.CREDENTIALS||'{}')?.authentication?.options?.userName} />
                                                </div>
                                            </div>}
                                            {credentialdetails?.TYPE=='API'?  <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="db_user_name">Password:</label>
                                                    <input type="Password" className="form-control" name="db_user_name" disabled id="db_user_name" placeholder="User Name" value={JSON.parse(credentialdetails?.CREDENTIALS||'{}')?.auth?.password} />
                                                </div>
                                            </div>:<div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="db_password">Password:</label>
                                                    <input type="password" className="form-control" name="db_password" disabled id="db_password" placeholder="Password" value={JSON.parse(credentialdetails?.CREDENTIALS||'{}')?.authentication?.options?.password} />
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="contact3" role="tabpanel" aria-labelledby="contact-tab3">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Schedule Event</label>

                                                    <input type="text" id="schedule_event" disabled name="schedule_event" className="form-control" placeholder="Schedule Event" value={JSON.parse(credentialdetails?.SCHEDULE_DETAILS||'{}')?.Schedule_Event} />
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-group" id="schedule_timer" >
                                                    <label>Schedule Time</label>
                                                    <input type="text" id="schedule_time_picker" disabled name="schedule_time_picker" className="form-control timepicker" placeholder="Time (HH:MM)" value={JSON.parse(credentialdetails?.SCHEDULE_DETAILS ||'{}')?.Schedule} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="others3" role="tabpanel" aria-labelledby="others-tab3">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="software_name">Software Name:</label>
                                                    <input type="text" className="form-control present_home_no" name="software_name" disabled id="software_name" placeholder="Software Name" value={JSON.parse(credentialdetails?.OTHERS_DETAILS ||'{}')?.Software_Name} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label for="software_name">Data Accept Code:</label>
                                                    <input type="text" className="form-control present_home_no" name="software_name" disabled id="software_name" placeholder="Data Accept Code" value={JSON.parse(credentialdetails?.OTHERS_DETAILS||'{}')?.Data_Accept_Code} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Credentials