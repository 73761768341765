import React from 'react';
import PropTypes from 'prop-types';

const DropdownSearch = ({ searchPlaceholder, onSearch }) => {
    const handleChange = (event) => {
        onSearch(event);
    };

    return (
        <div className="my-dropdown__search tw-flex tw-gap-2">
            <i class="fas fa-search tw-text-indigo-600"></i>
            <input
                type="text"
                placeholder={searchPlaceholder}
                onChange={handleChange}
            />
        </div>
    );
};

DropdownSearch.propTypes = {
    searchPlaceholder: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired,
};

export default DropdownSearch;
