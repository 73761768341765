import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Lock } from 'react-feather'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { activeandinactive, getmechanical_update_data, getMechanicalMeterBrand, getMechanicalMeterCategory, getMechanicalMeterClass, getMechanicalMeterManufacturer, getMechanicalMeterModalNo, getMechanicalMeterType, getmeter_category, getmeter_class, getmeter_firmware, getmeter_hardware_protocol, getmeter_make, getmeter_model_number, getmeter_software_protocol, getmeter_type, getmeter_update_data, getModemFirmware, getModemHardwareVersion, getModemMake, getModemModelNumber, getModemProductCode, getModemType, geturls3, Insertmechanical_meter_index, Insertmeter_index, Insertmodem_index, MechanicalFileuploads, MeterFileuploads, Updatemechanical_index_attachments, Updatemechanical_meter_index, Updatemeter_index, Updatemeter_index_attachments, Updatemodem_index, uploadsFiles } from '../../../Commoncomponet/Utils'
import { toast } from 'react-toastify'
import { AttachmanyUrl, BootstrapTooltip, MeterUrl } from '../../../Commoncomponet/common'
import swal from "sweetalert2";
import moment from 'moment'
import fileDownload from 'js-file-download'
import { Modal } from 'antd'
import axios from 'axios'
import DraggabalTable from '../../../Commoncomponet/DraggabalTable'
window.Swal = swal;
export default function Add_All() {

    const [Brand,setBrand]=useState([])
    const [Category,setCategory]=useState([])
    const [ModelNumber,setModelNumber]=useState([])
    const [Class,setClasss]=useState([])
    const [Manufacturer,setManufacturer]=useState([])
    const [Type,setType]=useState([])
    const [meterModemType,setmeterModemType]=useState([])
    const [Hardware,setHardware]=useState([])
const [MeterClass,setmeterClass]=useState([])
const [loginErrors, setValidation] = useState({});
const [flagBtn, setflegBtn] = useState(false);
const [ModemId,setModemId]=useState('')
const [disable,setDisable]=useState(true)
const [status,setStatus]=useState(null)
const [UrlImg,setUrlImg]=useState('')
const [ChangesJson,setChangesJson]=useState({})

const [inputs,setInputs]=useState({Name:'',Type:'',Class:'',ModelNumber:'',Brand:'',Category:'',memoryDay:'',UNITS:'',Manufacturer:'',METER_CAPACITY:'',MAX_RATE_QMAX:'',MIN_RATE_QMIN:'', NOMINAL_RATE_Q:'',MAX_PMAX:'',METER_CAPACITY_UNIT:'',MAX_RATE_QMAX_UNIT:'',MIN_RATE_QMIN_UNIT:'', NOMINAL_RATE_Q_UNIT:'',MAX_PMAX_UNIT:'',METER_DIGIT_COUNT:0,METER_PULSE_DIVISION:0})
const history = useHistory();
const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
const [Attachment, setAttachment] = useState([]);
const [isModalImage,setisModalImage]=useState(false)
   const [imageName,setImageName]=useState('')
const [TableKey,setTableKey]=useState('dailydata')
const [AttachLoader,setAttachLoader]=useState(false)

const locations=useLocation()
      useEffect(() => {
          if (locations?.state) {
             setInputs({Name:locations?.state?.MECHANICAL_PROFILE_NAME,Type:locations?.state?.MECHANICAL_METER_TYPE,Class:locations?.state?.MECHANICAL_METER_CLASS,ModelNumber:locations?.state?.MECHANICAL_MODEL_NO,Brand:locations?.state?.MECHANICAL_BRAND,Category:locations?.state?.MECHANICAL_CATEGORY,memoryDay:locations?.state?.MECHANICAL_PROFILE_NAME,UNITS:locations?.state?.MECHANICAL_UNITS,Manufacturer:locations?.state?.MECHANICAL_MANUFACTURER,METER_CAPACITY:locations?.state?.MECHANICAL_METER_CAPACITY,MAX_RATE_QMAX:locations?.state?.MECHANICAL_MAX_RATE_QMAX||'',MIN_RATE_QMIN:locations?.state?.MECHANICAL_MIN_RATE_QMIN||'', NOMINAL_RATE_Q:locations?.state?.MECHANICAL_NOMINAL_RATE_Q||'',MAX_PMAX:locations?.state?.MECHANICAL_MAX_PMAX||'',METER_CAPACITY_UNIT:locations?.state?.MECHANICAL_METER_CAPACITY_UNIT||'',MAX_RATE_QMAX_UNIT:locations?.state?.MECHANICAL_MAX_RATE_QMAX_UNIT||'',MIN_RATE_QMIN_UNIT:locations?.state?.MECHANICAL_MIN_RATE_QMIN_UNIT||'', NOMINAL_RATE_Q_UNIT:locations?.state?.MECHANICAL_NOMINAL_RATE_Q_UNIT||'',MAX_PMAX_UNIT:locations?.state?.MECHANICAL_MAX_PMAX_UNIT||'',METER_DIGIT_COUNT:locations?.state?.METER_DIGIT_COUNT||0,METER_PULSE_DIVISION:locations?.state?.METER_PULSE_DIVISION||0})
             setModemId(locations?.state?.MECHANICAL_UNIQUE_ID)
             setDisable(false)
             setStatus(locations?.state?.STATUS)
             setAttachment(JSON.parse(locations?.state?.MECHANICAL_ATTACHMENTS||'[]'))
            //  setChangesJson(JSON.parse(locations?.state?.TABLE_JSON||'{}'))

          }
       }, [locations]);
    const onHandaleSelection=async()=>{
        const data=await getMechanicalMeterBrand()
        if(data.success===true){
            setBrand(data.data.data)
            const data1=await getMechanicalMeterCategory()
        if(data1.success===true){
            setCategory(data1.data.data)
            const data2=await getMechanicalMeterClass()
        if(data2.success===true){
            setClasss(data2.data.data)
            const data3=await getMechanicalMeterManufacturer()
        if(data3.success===true){
            setManufacturer(data3.data.data)
            const data4=await getMechanicalMeterModalNo()
        if(data4.success===true){
            setModelNumber(data4.data.data)
        }
        const data5=await getMechanicalMeterType()
        if(data5.success===true){
            setType(data5.data.data)
        }
        }
        }
        }
        }else{
          console.log('somthing want wrong')
        }
      }

      const onAddAttachment = () => {
         const initial = {SRNO: Attachment.length+1,title: "", description: "", file: "",created_time:moment().format(),modified_time:'' };
         // if(contactDetails.length>0){
         const task = Attachment[Attachment.length - 1];
         Attachment[Attachment.length] = initial;
         setAttachment([...Attachment]);
         // }
         };
      const onDeleteAttachment =async (id) => {
         swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Card!",
            icon: "warning",
            showCancelButton: true,
         }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
         const test = [...Attachment];
         const data=test.filter((item,ids)=>(ids!==id))
         setAttachment([...data]);
         await Updatemeter_index_attachments(ModemId,{Attachment:data})
            }
            })
      };
      const handleChangeAttachment = async (e) => {
         const { name, value, id } = e.target;
         let updatedTask = [...Attachment];
         if(name==='file'){
            var totalSizeMB = value.target.files[0].size / Math.pow(1024, 2)
            if (totalSizeMB >= 50) {
               swal.fire('', 'File size should be under 50 MB', 'error');
       }else{
            updatedTask[id][name] = value.target.files[0];
       }
         }else{
            updatedTask[id][name] = value.target.value.replace(/[\/\\"`\t\n]/g, '');
         }
         setAttachment([...updatedTask]);
      };
      useEffect(() => {
        onHandaleSelection()
      }, []);

      const validation = (name, value) => {
         switch (name) {
             case 'Name':
                 if (!value) {
                     return 'Please inputMechanical Meter Name!'
                 } else {
                     return '';
                   }
                //    case 'Type':
                //  if (!value) {
                //      return 'Please inputMechanical Meter Type!'
                //  } else {
                //      return '';
                //    }
                //    case 'Make':
                //  if (!value) {
                //      return 'Please inputMechanical Meter Make!'
                //  } else {
                //      return '';
                //    }
                //    case 'ModelNumber':
                //  if (!value) {
                //      return 'Please inputMechanical Meter Model Number!'
                //  } else {
                //      return '';
                //    }
                //    case 'Firmware':
                //  if (!value) {
                //      return 'Please inputMechanical Meter Firmware!'
                //  } else {
                //      return '';
                //    }
                //    case 'Category':
                //  if (!value) {
                //      return 'Please inputMechanical Meter Category!'
                //  } else {
                //      return '';
                //    }
                //    case 'Software':
                //  if (!value) {
                //      return 'Please inputMechanical Meter Software!'
                //  } else {
                //      return '';
                //    }
                //    case 'Hardware':
                //  if (!value) {
                //      return 'Please inputMechanical Meter Hardware!'
                //  } else {
                //      return '';
                //    }
                //    case 'meterModemType':
                //  if (!value) {
                //      return 'Please inputMechanical Meter Mechanical Meter Modem Type!'
                //  } else {
                //      return '';
                //    }
                   
                   default: {
                     return null;
                 }
             }
             }

             const onUploadFile=async(id)=>{
               setAttachLoader(true)
            var attechmentsobj=[]
                  const formData = new FormData()
                  formData.append('Images', Attachment[id].file)
                  const uploadImges=await MechanicalFileuploads(formData)
                  if(uploadImges.success===true){
                     Attachment[id].file=uploadImges?.data?.originalname
                     const data=await Updatemechanical_index_attachments(ModemId,{Attachment:Attachment})
                     if(data.success==true) {
                        ongetmechanical_update_data()
               setAttachLoader(false)
                        toast.success('File Successfully Upload')
                     }
                  }else{
               setAttachLoader(false)
                     toast.error('File Not Uploaded')
                     // attechmentsobj.push({...item,file:''})
                  }
             }

             const ongetmechanical_update_data=async()=>{
               const data=await getmechanical_update_data(ModemId)
               if(data.success==true){
                  const inFometer=data.data.data
                  setInputs({Name:inFometer?.MECHANICAL_PROFILE_NAME,Type:inFometer?.MECHANICAL_METER_TYPE,Class:inFometer?.MECHANICAL_METER_CLASS,ModelNumber:inFometer?.MECHANICAL_MODEL_NO,Brand:inFometer?.MECHANICAL_BRAND,Category:inFometer?.MECHANICAL_CATEGORY,memoryDay:inFometer?.MECHANICAL_PROFILE_NAME,UNITS:inFometer?.MECHANICAL_UNITS,Manufacturer:inFometer?.MECHANICAL_MANUFACTURER,METER_CAPACITY:inFometer?.MECHANICAL_METER_CAPACITY,MAX_RATE_QMAX:inFometer?.MECHANICAL_MAX_RATE_QMAX,MIN_RATE_QMIN:inFometer?.MECHANICAL_MIN_RATE_QMIN, NOMINAL_RATE_Q:inFometer?.MECHANICAL_NOMINAL_RATE_Q,MAX_PMAX:inFometer?.MECHANICAL_MAX_PMAX,METER_CAPACITY_UNIT:inFometer?.MECHANICAL_METER_CAPACITY_UNIT||'',MAX_RATE_QMAX_UNIT:inFometer?.MECHANICAL_MAX_RATE_QMAX_UNIT||'',MIN_RATE_QMIN_UNIT:inFometer?.MECHANICAL_MIN_RATE_QMIN_UNIT||'', NOMINAL_RATE_Q_UNIT:inFometer?.MECHANICAL_NOMINAL_RATE_Q_UNIT||'',MAX_PMAX_UNIT:inFometer?.MECHANICAL_MAX_PMAX_UNIT||'',METER_DIGIT_COUNT:inFometer?.METER_DIGIT_COUNT||0,METER_PULSE_DIVISION:inFometer?.METER_PULSE_DIVISION||0})
                  setModemId(inFometer?.MECHANICAL_UNIQUE_ID)
                  setDisable(false)
                  setStatus(inFometer?.STATUS)
                  setAttachment(JSON.parse(inFometer?.MECHANICAL_ATTACHMENTS||'[]'))
               }else{
                  console.log('something want wrong')
               }
             }

      const onHandaleSubmitData=async(e)=>{
         // e.preventDefault()
         setflegBtn(true)
         let allErrors = {};
      Object.keys(inputs).forEach(key => {
        const error = validation(key, inputs[key]);
        if (error && error.length) {
            allErrors[key] = error
        }
    });
  
    if (Object.keys(allErrors).length) {
      setflegBtn(false)
     return setValidation(allErrors)
       }else{
         if(ModemId===''){
           
         const data=await Insertmechanical_meter_index({...inputs,add_by:userInfo?.UNIQUE_ID})
         if(data.success===true){
             toast.success('Add Mechanical Meter Success')
         setflegBtn(false)
         history.push('/MechanicalMeter')
         }else{
             toast.error('Something Wait Wrong')
         setflegBtn(false)
         }
     }else{
     const data2=await Updatemechanical_meter_index({...inputs},ModemId)
     if(data2.success===true){
         toast.success('UpdateMechanical Meter Success')
     setflegBtn(false)
     history.push('/MechanicalMeter')
     }else{
         toast.error('Something Wait Wrong')
     setflegBtn(false)
 
     }
   
     }
       }
     }
     const columns=[{
      Header: "Id",
      accessor: "Order"
   },{
      Header: "Database Column",
      accessor: "Column"
   },{
      Header: "Header Column",
      accessor: "Header"
   }]
     const oninactive=async()=>{
      swal.fire({
			title: "Are You Sure InActive Mechanical Meter ?",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'mechanical_meter_index',colName:'STATUS',value:'0',matchId:'MECHANICAL_UNIQUE_ID'},ModemId)
      if(data.success===true){
         toast.success('Success Inactive Mechanical Meter ')
         history.push('/MechanicalMeter')
      }else{
         toast.error('something want wrong')
      }
   }
})
     }
     const onactive=async()=>{
      swal.fire({
			title: "Are You Sure Mechanical Meter ?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'mechanical_meter_index',colName:'STATUS',value:'1',matchId:'MECHANICAL_UNIQUE_ID'},ModemId)
      if(data.success===true){
         toast.success('Success Active Mechanical Meter ')
         history.push('/MechanicalMeter')
      }else{
         toast.error('something want wrong')
      }
   }
})
     }
     const onDownloadFiles=async(e,name)=>{
      // e.preventDefault()

      const data1=await geturls3({bucketName:'gums.memighty.com',objectKey:`Mechanical_Driver_Attachments/${name}`, expiration:60})
		console.log(data1);
		var link = document.createElement("a");
		link.href = data1.data.data;
		link.download = name;
		link.click();
     }

     const onViewAttachments=async(e,name)=>{
      // e.preventDefault()

      setisModalImage(true) 
      const data1=await geturls3({bucketName:'gums.memighty.com',objectKey:`Mechanical_Driver_Attachments/${name}`, expiration:60})
      setImageName(name)
      setUrlImg(data1.data.data)

     }

     const handleDragEnd = () => {
      setChangesJson({
         ...ChangesJson,
         Meter_Data: {
            ...ChangesJson?.Meter_Data,
            [TableKey]: ChangesJson?.Meter_Data[TableKey]
         }
      });
   };
     var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
     var imageExtensions=/(\.mp4|\.webm|\.ogg|\.mkv|\.mpg|\.m4v|\.m4p |\.mpv|\.vob|\.mpeg)$/i;
  return (
    <>
    <Modal title="Image" visible={isModalImage} footer='' onCancel={()=>(setisModalImage(false))} width={800}>
       {allowedExtensions.exec(imageName)?
         <img src={`${UrlImg}`} height={500} width={"100%"}></img>:imageExtensions.exec(imageName)?<video height={500} width={"100%"} controls><source src={`${UrlImg}`} type="video/mp4"/><source src={`${UrlImg}`} type="video/ogg"/></video>:''
       }
       </Modal>
    <div className="main-content">
    <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
                            <Lock className='header-logo' style={{ height: "16px" }} /> Admin
							</Link>
						</li>
						<li className="breadcrumb-item">
						<Link to='/MechanicalMeter'>
							Mechanical Meter Driver
						</Link>
					</li>
						<li className="breadcrumb-item">
							<Link >{ModemId===''?'Add Mechanical Meter ':'Update Mechanical Meter '}</Link>
						</li>
					</ol>
				</nav>
                <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>{ModemId===''?'Add Mechanical Meter ':'Update Mechanical Meter '}</h4>
                           <div class="card-header-action">  
                           {ModemId!==''?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{ModemId!==''&&status==1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{ModemId!==''&&status==0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''}
                              <Link to="/MechanicalMeter" class="btn btn-info text-white align-left">
                              Back
                              </Link>                        
                           </div>
                        </div>
                        <div class="card-body">
                           
                              <div class="form-row" style={{marginLeft:"10px"}}>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter  Profile Name: <span class="text-danger">*</span></label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Modem Profile Name" required="" onChange={(e)=>(setInputs({...inputs,Name:e.target.value}))} value={inputs.Name} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.Name}</span>    
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Mechanical Meter  Type</label>
                                    <select class="form-control form-select" name="modemtype" id="modemtype" onChange={(e)=>(setInputs({...inputs,Type:e.target.value}))} value={inputs.Type} disabled={!disable}>
                                       <option value="">SelectMechanical Meter Type</option>
                                                           {Type&&Type.map((item)=>(<option value={item.MECHANICAL_METER_TYPE_UNIQUE_ID}>{item?.MECHANICAL_METER_TYPE_NAME}</option>))}  
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Name}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Mechanical Meter  Class</label>
                                    <select class="form-control form-select" name="modemmake" id="modemmake" onChange={(e)=>(setInputs({...inputs,Class:e.target.value}))} value={inputs.Class} disabled={!disable}>
                                       <option value="">SelectMechanical Meter Class</option>
                                       {Class&&Class.map((item)=>(<option value={item.MECHANICAL_METER_CLASS_UNIQUE_ID}>{item?.MECHANICAL_METER_CLASS_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Class}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Mechanical Meter  Model Number</label>
                                    <select class="form-control form-select " name="modemmodalversion" id="modemmodalversion" onChange={(e)=>(setInputs({...inputs,ModelNumber:e.target.value}))} value={inputs.ModelNumber} disabled={!disable}>
                                       <option value="">SelectMechanical Meter Model Number</option>
                                       {ModelNumber&&ModelNumber.map((item)=>(<option value={item.MECHANICAL_METER_MODAL_UNIQUE_ID}>{item?.MECHANICAL_METER_MODAL_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.ModelNumber}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Mechanical Meter  Brand</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemfirmware[]" id="modemfirmware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,Brand:e.target.value}))} value={inputs.Brand} disabled={!disable}>
                                       <option value="">SelectMechanical Meter Brand</option>
                                       {Brand&&Brand.map((item)=>(<option value={item.MECHANICAL_METER_BRAND_UNIQUE_ID}>{item?.MECHANICAL_METER_BRAND_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Firmware}</span>    

                                 </div>
                                
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Mechanical Meter  Category</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemhardware[]" id="modemhardware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,Category:e.target.value}))} value={inputs.Category} disabled={!disable}>
                                       <option value="">SelectMechanical Meter Category</option>
                                       {Category&&Category.map((item)=>(<option value={item.MECHANICAL_METER_CATEGORY_UNIQUE_ID}>{item?.MECHANICAL_METER_CATEGORY_NAME}</option>))}
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Category}</span>   
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Mechanical Meter Manufacturer</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemhardware[]" id="modemhardware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,Manufacturer:e.target.value}))} value={inputs.Manufacturer} disabled={!disable}>
                                       <option value="">SelectMechanical Meter Manufacturer</option>
                                       {Manufacturer&&Manufacturer.map((item)=>(<option value={item.MECHANICAL_METER_MF_UNIQUE_ID}>{item?.MECHANICAL_METER_MF_NAME}</option>))}
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Manufacturer}</span>   
                                 </div>
                                 
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Units:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Mechanical Meter Units" required="" onChange={(e)=>(setInputs({...inputs,UNITS:e.target.value}))} value={inputs.UNITS} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.UNITS}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Capacity:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Mechanical Meter Capacity" required="" onChange={(e)=>(setInputs({...inputs,METER_CAPACITY:e.target.value}))} value={inputs.METER_CAPACITY} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.METER_CAPACITY}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Capacity Unit:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Mechanical Meter Capacity" required="" onChange={(e)=>(setInputs({...inputs,METER_CAPACITY_UNIT:e.target.value}))} value={inputs.METER_CAPACITY_UNIT} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.METER_CAPACITY}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Max flow Rate Qmax: </label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter >Mechanical Meter Max Rate Qmax" required="" onChange={(e)=>(setInputs({...inputs,MAX_RATE_QMAX:e.target.value}))} value={inputs.MAX_RATE_QMAX} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.MAX_RATE_QMAX}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Max flow Rate Qmax Unit: </label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter >Mechanical Meter Max Rate Qmax" required="" onChange={(e)=>(setInputs({...inputs,MAX_RATE_QMAX_UNIT:e.target.value}))} value={inputs.MAX_RATE_QMAX_UNIT} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.MAX_RATE_QMAX}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Min flow rate Qmin:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,MIN_RATE_QMIN:e.target.value}))} value={inputs.MIN_RATE_QMIN} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.MIN_RATE_QMIN}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Min flow rate Qmin Unit:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,MIN_RATE_QMIN_UNIT:e.target.value}))} value={inputs.MIN_RATE_QMIN_UNIT} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.MIN_RATE_QMIN}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Nominal flow rate Q:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,NOMINAL_RATE_Q:e.target.value}))} value={inputs.NOMINAL_RATE_Q} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.NOMINAL_RATE_Q}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Nominal flow rate Q Unit:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,NOMINAL_RATE_Q_UNIT:e.target.value}))} value={inputs.NOMINAL_RATE_Q_UNIT} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.NOMINAL_RATE_Q}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Max working pressure Pmax: </label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,MAX_PMAX:e.target.value}))} value={inputs.MAX_PMAX} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.MAX_PMAX}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Mechanical Meter Max working pressure Pmax Unit: </label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,MAX_PMAX_UNIT:e.target.value}))} value={inputs.MAX_PMAX_UNIT} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.MAX_PMAX}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Meter Digit Count : </label>
                                    <input class="form-control" name="modemname" id="modemname" type="number" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,METER_DIGIT_COUNT:e.target.value}))} value={inputs.METER_DIGIT_COUNT} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.MAX_PMAX}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Meter Pulse Divsion: </label>
                                    <input class="form-control" name="modemname" id="modemname" type="number" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,METER_PULSE_DIVISION:e.target.value}))} value={inputs.METER_PULSE_DIVISION} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.MAX_PMAX}</span>    
                                 </div>
                              </div>
                              
                              <div class="row">
															<div class="col-12">
																<div class="row" id="dynamic_attech">
																	{Attachment &&
																		Attachment.map((item, id) => (
																			<>
																				<div class="col-6" id="colls1">
																					<div class="card card-primary">
																						<div class="card-header">
																							<h4>{id+1}</h4>
                                                                     {!disable?<div class="card-header-action ">
                                                <div class="btn btn-warning text-white mr-2" onClick={(e)=>{onDownloadFiles(e,item.file)}}><i class="fa fa-download"></i></div>
                                                <a onClick={(e)=>{onViewAttachments(e,item.file)
                                                }} class="btn btn-info"><i class="fa fa-eye" ></i></a>
                                             </div>:
																							<div class="card-header-action">
                                                                    {item.file!==''&& typeof item.file==='string'? <><div class="btn btn-warning text-white mr-2" onClick={(e)=>{onDownloadFiles(e,item.file)}}><i class="fa fa-download" ></i></div>
                                                <a  onClick={(e)=>{onViewAttachments(e,item.file)}} class="btn btn-info mr-2 text-white"><i class="fa fa-eye"></i></a></>:''}
																								{disable?<><a
																									class="btn btn-danger text-white"
																									id="1"
                                                                           onClick={()=>(onDeleteAttachment(id))}
																								>
																									<i class='fas fa-xmark'></i>
																								</a></>:''} 
																							</div>}
																							{/* <div class="card-header-action">
																								<button
																									type="button"
																									class="btn btn-danger text-white btn_removes"
																									id="1"
                                                                           onClick={()=>(onDeleteAttachment(id))}
																								>
																									<i class="fa fa-times" aria-hidden="true"></i>
																								</button>
																							</div> */}
																						</div>
																						<div class="card-body">
																							<div class="form-group">
																								<div
																									class="form-group row"
																									style={{ marginTop: "-20px" }}
																								>
																									<label
																										for="attechmenttitle1"
																										class="col-sm-3 col-form-label"
																									>
																										Title
																									</label>
																									<div class="col-sm-9">
																										<input
																											type="text"
																											class="form-control"
																											placeholder="Title"
																											onChange={(value) =>
                                                                                    handleChangeAttachment({
																												target: {
																													name: "title",
																													value,
																													id,
																												},
																											})
																										}
                                                                              value={Attachment[id].title}
                                                                              disabled={!disable}
																										/>
																									</div>
																								</div>
																								<div
																									class="form-group row"
																									style={{ marginTop: "-20px" }}
																								>
																									<label
																										for="lastcontectname1"
																										class="col-sm-3 col-form-label"
																									>
																										Description
																									</label>
																									<div class="col-sm-9">
																										<textarea
																											class="form-control"
																											id="attechmentdescr1"
																											onChange={(value) =>
                                                                                    handleChangeAttachment({
																												target: {
																													name: "description",
																													value,
																													id,
																												},
																											})
																										}
                                                                              disabled={!disable}

                                                                              value={Attachment[id].description}
																										></textarea>
																									</div>
																								</div>
																								<div
																									class="form-group row"
																									style={{ marginTop: "-20px" }}
																								>
																									<label
																										for="lastcontectname1"
																										class="col-sm-3 col-form-label"
																									>
																										Attachment
																									</label>
																									<div class="col-sm-9">
                                                                           <div
                                                                           class="d-flex"
                                                                           style={{ padding:'2px' }}
                                                                           
                                                                        >
                                                                           <input
                                                                              type="file"
                                                                              class="form-control"
                                                                              id={"file-uploads"+id}
                                                                              accept="application/msword,image/gif,image/jpeg,application/pdf,image/png,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,.doc,.gif,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.zip"
                                                                              onChange={(value) =>
                                                                                 handleChangeAttachment({
                                                                                    target: {
                                                                                       name: "file",
                                                                                       value,
                                                                                       id,
                                                                                    },
                                                                                 })
                                                                              }
                                                                              hidden
                                                                              disabled={!disable}
                                                                           />
                                                                           <div className='btn btn-secondary text-dark mr-1' style={{ width:"120px" }} onClick= {()=>{document.getElementById('file-uploads'+id).click()}}>Upload File</div>
                                                                           <input type={'text'} class="form-control" value={item.file !== '' && typeof item.file === 'string' ?item.file:item?.file?.name?item?.file?.name:'No Choose File'}></input>
																									</div>
                                                                           </div>
																								</div>
                                                                        {ModemId!==''&&<spam>Created At: {item?.create_at!==''?moment(item?.create_at).format('DD-MM-YYYY hh:mm:ss A'):''}</spam>}<br/>
                                                                        {ModemId!==''&&<spam>Update At: {item?.update_at!==''?moment(item?.update_at).format('DD-MM-YYYY hh:mm:ss A'):''}</spam>}
																							</div>
                                                                     {item.file!==''&& typeof item.file!=='string'?  <div className={`btn btn-primary ${AttachLoader?'disabled btn-progress':''}`} onClick={()=>onUploadFile(id)}>Upload</div>:''}
																						</div>
																					</div>
																				</div>
																			</>
																		))}
																</div>
															</div>
														</div>
                             {ModemId!=''?<div class="col-md-12 p-4">
															<button
																type="button"
																name="add"
																id="addatt"
																class={`btn btn-info ${!disable?'disabled':""}`}
																onClick={onAddAttachment}
															>
																+Add Attachment
															</button>
														</div>:''}
                                          
                                   
                              <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                              <div class="text-left">
                                 <input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={ModemId===''?'Submit':'Update'} id="submitvn" onClick={onHandaleSubmitData}/>
                                 <Link class="btn btn-light text-dark" to="/MechanicalMeter">Cancel</Link>  
                              </div>
                        </div>
                     </div>
                  </div>
                </div>
            </div>
         </section>
      </div>
    </>
  )
}
