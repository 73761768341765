import React, { useEffect, useState,Suspense } from 'react'
import { getAccount, getAreaById, getCircleById, getDivisionById, getGaById, getLorawanDataAnalytics, getProfilNameByAccountId, getRolesById, getSubdevisionById } from '../../../Commoncomponet/Utils';
import { Link } from "react-router-dom";
import moment from 'moment';
import { DatePicker, Modal, Space, Spin } from 'antd';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { Bar, getElementsAtEvent, getElementAtEvent, getDatasetAtEvent, Pie } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	zoomPlugin, ChartDataLabels,ArcElement
);
export const LoraAnalytics = () => {
    const [SubdevisionData, setSubdevisionData] = useState([]);
    const [DivisionData, setDivisionData] = useState([]);
    const [CircleData, setCircleData] = useState([]);
    const [GaData, setGaData] = useState([]);
    const [AccounData, setAccountData] = useState([]);
    const [AreaData, setAreaData] = useState([]);
    const [permision, setpermision] = useState({});
    const [HeaderDate, setHeaderDate] = useState([]);
    const [dates, setDates] = useState({ start: moment().add(-7,'days').format('DD-MM-YYYY'), end: moment().format('DD-MM-YYYY') })
    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const [loader, setLoader] = useState(false);
    const [AnaData, setAnaData] = useState([]);
    const [GatewayList, setGatewayList] = useState([]);
    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" });
    const [TableData,setTableData]=useState([])
    const [isModalVisible,setisModalVisible]=useState(false)
    const [nameModal,setnameModal]=useState('')
    const [BufferModal,setBufferModal]=useState(false)
    const [LoraGraph,setLoraGraph]=useState({date:[],data:[]})
    const onHandaleSelection = async () => {
        const data = await getAccount();
        if (data.success === true) {
            setAccountData(data.data.data);
        } else {
            console.log("somthing want wrong");
        }
    };

    const onHandaleRoles = async () => {
        const data = await getRolesById(userInfo?.ROLE);
        if (data.success === true) {
            const obj = data.data.data[0];
            setpermision({
                ...obj,
                ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
                ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
                ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
                ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
                ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
                ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
                ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
            });
            const permissionViewCard = JSON.parse(obj?.ROLES_OTHER_PERMISSIONS)
            const infoRolesObj = { Roles: userInfo?.ROLE, Profile: { CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0).CATEGORY0_view, CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1).CATEGORY1_view, CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2).CATEGORY2_view, CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3).CATEGORY3_view, CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4).CATEGORY4_view, CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5).CATEGORY5_view }, permissionViewCard }
            onHandaleSelection()
            // loragetwayInfo({...infoRolesObj,start: moment().add(-7,'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD')})
        } else {
            console.log("something want wrong");
        }
    }

    const loragetwayInfo=async(info)=>{
        setLoader(true)
        const data=await getLorawanDataAnalytics(info)
        if(data.success==true){
            setAnaData(data.data.data)
            setGatewayList(data.data.gateway_list.sort((a,b)=>{
                if(a.GATEWAY_NAME < b.GATEWAY_NAME) { return -1; }
                if(a.GATEWAY_NAME > b.GATEWAY_NAME) { return 1; }
                return 0;
        }))
        setLoader(false)
        }else{
            console.log("something want wrong");
        setLoader(false)
        }
    }

    useEffect(() => {
        onHandaleRoles()
        setHeaderDate(getDaysArrayNotToday(moment().add(-7,'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')))
    }, [])
    var getDaysArrayNotToday = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            // arr.push(new Date(dt).getFullYear()+'-'+'0'+(new Date(dt).getMonth() + 1)+'-'+'0'+new Date(dt).getDate());
            arr.push(
                new Date(dt).getFullYear() +
                    "-" +
                    `${new Date(dt).getMonth() + 1 < 10 ? "0" : ""}` +
                    (new Date(dt).getMonth() + 1) +
                    "-" +
                    `${new Date(dt).getDate() < 10 ? "0" : ""}` +
                    new Date(dt).getDate()
            );
        }
        return arr.reverse();
    };
    const ongetGaById = async (id) => {
        const data1 = await getGaById(id);
        if (data1.success === true) {
            setGaData(data1.data.data);
        }
    };

    const ongetCircle = async (id) => {
        const data1 = await getCircleById(id);
        if (data1.success === true) {
            setCircleData(data1.data.data);
        }
    };
    const ongetSubdevisionById = async (id) => {
        const data1 = await getSubdevisionById(id);
        if (data1.success === true) {
            setSubdevisionData(data1.data.data);
        }
    };
    const ongetDivisionById = async (id) => {
        const data3 = await getDivisionById(id);
        if (data3.success === true) {
            setDivisionData(data3.data.data);
        }
    };
    const ongetArea = async (id) => {
        const data3 = await getAreaById(id);
        if (data3.success === true) {
            setAreaData(data3.data.data);
        }
    };
    const [NameObj, setNameObj] = useState({})
    const ongetProfilNameByAccountId = async (id) => {
        const data = await getProfilNameByAccountId({ ID: id })
        if (data.success === true) {
            setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
        } else {
            console.log('something want wrong')
        }
    }
    useEffect(() => {
        if (Profile.account !== "") {
            ongetGaById(Profile.account);
            setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([])
            // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
            ongetProfilNameByAccountId(Profile.account)

        } else {
            setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
            setGaData([]);
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);
            if (Object.keys(permision).length > 0) {
                // onChangesProfileWiseSerch({ CATEGORY0: '', CATEGORY1: '', CATEGORY2: '', CATEGORY3: '', CATEGORY4: '', CATEGORY5: '', ROLES: userInfo?.ROLE, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
            }
            setNameObj({})
        }
    }, [Profile.account]);

    useEffect(() => {
        if (Profile.ga !== "") {
            ongetCircle(Profile.ga);
            setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);
            // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
        } else {
            setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
            setCircleData([]);
            setDivisionData([]);
            setSubdevisionData([]);
            setAreaData([]);
            if (Object.keys(permision).length > 0) {
                // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
            }

        }
    }, [Profile.ga]);

    useEffect(() => {
        if (Profile.circle !== "") {
            ongetDivisionById(Profile.circle);
            setProfile({ ...Profile, division: "", subdivision: "", area: "" });
            setAreaData([]);
            setSubdevisionData([]);
            // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });

        } else {
            setProfile({ ...Profile, division: "", subdivision: "", area: "" });
            setAreaData([]);
            setDivisionData([]);
            setSubdevisionData([]);
            if (Object.keys(permision).length > 0) {
                // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
            }

        }
    }, [Profile.circle]);

    useEffect(() => {
        if (Profile.division !== "") {
            ongetSubdevisionById(Profile.division);
            setAreaData([]);
            setProfile({ ...Profile, subdivision: "", area: "" });
            // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
        } else {
            setProfile({ ...Profile, subdivision: "", area: "" });
            setSubdevisionData([]);
            setAreaData([]);
            if (Object.keys(permision).length > 0) {
                // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
            }
        }
    }, [Profile.division]);

    useEffect(() => {
        if (Profile.subdivision !== "") {
            ongetArea(Profile.subdivision);
            setProfile({ ...Profile, area: "" });
            // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
        } else {
            setProfile({ ...Profile, area: "" });
            setAreaData([]);
            if (Object.keys(permision).length > 0) {
                // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
            }
        }
    }, [Profile.subdivision]);

    useEffect(() => {
        if (Profile.area !== "") {
            // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
        } else {
            if (Object.keys(permision).length > 0) {
                // onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
            }
        }
    }, [Profile.area]);

    const onProfileWiseSearchClick = () => {
        loragetwayInfo({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view },permissionViewCard:permision?.ROLES_OTHER_PERMISSIONS ,start: moment(dates?.start,'DD-MM-YYYY').format('YYYY-MM-DD'), end: moment(dates?.end,'DD-MM-YYYY').format('YYYY-MM-DD')})
        setHeaderDate(getDaysArrayNotToday(moment(dates?.start,'DD-MM-YYYY').format('YYYY-MM-DD'), moment(dates?.end,'DD-MM-YYYY').format('YYYY-MM-DD')))

    }

    const onChange = (date, dateString) => {
		setDates({ ...dates, start: dateString })
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: dateString })
	};

    const optionsForChartJS = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {

				ticks: {
					maxRotation: 90,

				}
			}
		},
		animation: false,
		// animation: {
		// 	duration: 1000,
		// 	easing: 'easeInBounce',
		// 	onComplete: () => {
		// 		delayed = true;
		// 	},
		// 	delay: (context) => {
		// 		let delay = 0;
		// 		if (context.type === 'data' && context.mode === 'default' && !delayed) {
		// 			delay = context.dataIndex * 300 + context.datasetIndex * 100;
		// 		}
		// 		return delay;
		// 	},
		// },

		plugins: {
			legend: {
				position: 'bottom',
				fullSize: false,
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded',
					padding: 5,
					font: function (context) {
						var w = context.chart.width;
						return {
							size: w < 512 ? 8 : 11,

						};
					},
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};

    const dataForChartJs = {
		labels: LoraGraph?.date?.map((item)=>([item+` (${moment(item,'DD-MM-YYYY').format('ddd')})`])),
		datasets:[
			{
				label: "DailyData Success",
				data: LoraGraph?.data,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
			}
			
		],
	};

    return (
        <>
        {<Suspense fallback={<><Spin size="large" /></>}>
				<BootstrapModal className="my-modal-class" show={isModalVisible} onHide={()=>{setisModalVisible(false)}} size={"xl"} >
					<BootstrapModal.Header closeButton><BootstrapModal.Title style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
						{nameModal}
					</BootstrapModal.Title></BootstrapModal.Header>
					<BootstrapModal.Body style={{ maxHeight: '90vh', overflow: 'auto', paddingTop: '0px' }}>
						<div className="modal-table">
							 <table className="table border table-borderless" id="table-1">
								<thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
									<tr>
										<th className="text-center">SR NO.</th>
										<th className="text-center">Consumer Name</th>
										<th className="text-center">Modem Serial No</th>
										<th className="text-center">Installation Number</th>
									</tr>
								</thead>
								<tbody id="tbody">
									<>
										{TableData.length > 0 ?
											TableData.map((data, id) => (
												<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]" key={id} >
													<td className="text-center">{id + 1}</td>
													<td className="text-center">{data?.address_compony_name}</td>
													<td className="text-center">{data?.DEVICE_SERIAL_NO}</td>
													<td className="text-center">{data?.INSTALLATION_NUMBER}</td>

													
												</tr>
											)) : <tr></tr>}
									</>
								</tbody>
							</table>
							{TableData.length == 0  && <h4 className="text-center">No Data Found</h4>}

						</div>
					</BootstrapModal.Body>
				</BootstrapModal>
			</Suspense>}

            <Modal title=" " visible={BufferModal} footer='' maskClosable={false} onCancel={() => {setBufferModal(false)
            setLoraGraph({date:[],data:[]})}} width={800}>
                <div style={{height: "395px"}}>
                <Bar options={optionsForChartJS} data={dataForChartJs} />
                </div>
			</Modal>
            <div className="main-content">
               
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/all-dashboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to='/Lora_dashboard'>Lorawan Dashboard</Link>
                        </li>
                    </ol>
                </nav>
                <div class="row">
                    <div class="col-12">
                        <div class="card ">
                            <div class="card-body tw-flex tw-items-center tw-gap-3" style={{ padding: "2px 12px" }}>
                                <div class="tw-flex tw-gap-2"
                                    style={{

                                        borderColor: "#e3e6fc !important",
                                        position: "relative",
                                        marginTop: "5px",
                                    }}>
                                    <div class="tw-w-full tw-min-w-[13rem]">
                                        <div class="form-group">
                                            <label htmlFor="inputPassword4">
                                                Account<span class="text-danger">*</span>
                                            </label>
                                            {/* <Select name='Consumertype' isMulti options={AccounData.map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																		{value:item.UNIQUE_ID,label:item.CATEGORY0}
																	) :{}
																)} onChange={(e) => { setProfile({ ...Profile, account: e.map((item) => (item.value)) }) }} /> */}
                                            <select
                                                class="form-control form-select accountoga ga_calueacc"
                                                name="accountid"
                                                required=""
                                                id="accountid"
                                                onChange={(e) => setProfile({ ...Profile, account: e.target.value })}
                                                value={Profile?.account}

                                            >
                                                <option value="">Select Account</option>
                                                {AccounData &&
                                                    AccounData.map((item, id) =>
                                                        permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
                                                            (items) => items === item.UNIQUE_ID
                                                        ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                            <option key={id} value={item.UNIQUE_ID}>{item.CATEGORY0}</option>
                                                        ) : (
                                                            ""
                                                        )
                                                    )}
                                            </select>
                                        </div>
                                    </div>
                                    {Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] gagrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY1 || 'CATEGORY1'}</label>
													<select
														class="form-control form-select gatocircle ga_calue"
														name="address_location_d"
														id="address_location_d"
														onChange={(e) => setProfile({ ...Profile, ga: e.target.value, circle: "", division: "", subdivision: "", area: "" })}
														value={Profile?.ga}
													>
														<option value="">Select {NameObj?.CATEGORY1 || 'CATEGORY1'}</option>
														{GaData &&
															GaData.filter(item=>item.CATEGORY0==Profile.account).map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter(
																	(items) => items === item.CATEGORY1_UNIQUE_ID
																).length > 0 ||(item.CATEGORY0==Profile?.account)|| userInfo?.ROLE === 'ROLE1650614500708' ? (
																	<option key={id} value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.ga !== "" && Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] circlegrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY2 || 'CATEGORY2'}</label>
													<select
														class="form-control circletodivision form-select"
														name="circle_id"
														onChange={(e) => setProfile({ ...Profile, circle: e.target.value, division: "", subdivision: "", area: "" })}
														value={Profile?.circle}
													>
														<option value="">Select {NameObj?.CATEGORY2 || 'CATEGORY2'}</option>
														{CircleData &&
															CircleData.filter(item=>item.CATEGORY1==Profile.ga).map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter(
																	(items) => items === item.CATEGORY2_UNIQUE_ID
																).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga)|| userInfo?.ROLE === 'ROLE1650614500708' ? (
																	<option key={id} value={item.CATEGORY2_UNIQUE_ID}>{item.CATEGORY2_NAME}</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] divisiongrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY3 || 'CATEGORY3'}</label>
													<select
														class="form-control divisiontosubdivision form-select"
														name="division_id"
														id="address_location_d"
														onChange={(e) => setProfile({ ...Profile, division: e.target.value, subdivision: "", area: "" })}
														value={Profile?.division}
													>
														<option value="">Select {NameObj?.CATEGORY3 || 'CATEGORY3'}</option>
														{DivisionData &&
															DivisionData.filter(item=>item.CATEGORY2==Profile.circle).map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter(
																	(items) => items === item.CATEGORY3_UNIQUE_ID
																).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga)|| userInfo?.ROLE === 'ROLE1650614500708' ? (
																	<option key={id} value={item.CATEGORY3_UNIQUE_ID}>{item.CATEGORY3_NAME}</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] subdivisiongrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY4 || 'CATEGORY4'}</label>
													<select
														class="form-control subdivisiontoarea form-select"
														name="sub_division_id"
														id="address_location_d"
														onChange={(e) => setProfile({ ...Profile, subdivision: e.target.value, area: "" })}
														value={Profile?.subdivision}
													>
														<option value="">Select {NameObj?.CATEGORY4 || 'CATEGORY4'}</option>
														{SubdevisionData &&
															SubdevisionData.filter(item=>item.CATEGORY3==Profile.division).map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter(
																	(items) => items === item.CATEGORY4_UNIQUE_ID
																).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga&&item.CATEGORY2==Profile?.circle&&item.CATEGORY3==Profile?.division)|| userInfo?.ROLE === 'ROLE1650614500708' ? (
																	<option key={id} value={item.CATEGORY4_UNIQUE_ID}>
																		{item.CATEGORY4_NAME}
																	</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.subdivision !== "" &&
											Profile.division !== "" &&
											Profile.circle !== "" &&
											Profile.ga !== "" &&
											Profile.account !== "" && (
												<div class="tw-w-full tw-min-w-[12rem] areagrp">
													<div class="form-group">
														<label htmlFor="inputPassword4">{NameObj?.CATEGORY5 || 'CATEGORY5'}</label>
														<select
															class="form-control form-select lastarea cust_ara"
															name="area_id"
															id="area_id"
															onChange={(e) => setProfile({ ...Profile, area: e.target.value })}
															value={Profile?.area}
														>
															<option value="">Select {NameObj?.CATEGORY5 || 'CATEGORY5'}</option>
															{AreaData &&
																AreaData.filter(item=>item.CATEGORY4==Profile.subdivision).map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga&&item.CATEGORY2==Profile?.circle&&item.CATEGORY3==Profile?.division&&item.CATEGORY4==Profile?.subdivision)|| userInfo?.ROLE === 'ROLE1650614500708' ? (
																		<option key={id} value={item.UNIQUE_ID}>{item.CATEGORY5_NAME}</option>
																	) : (
																		""
																	)
																)}
														</select>
													</div>
												</div>
											)}
                                        
                                </div>
                                <div className="d-flex  mb-3">
                                        <Space direction="horizontal">
								<div className="d-flex flex-column">Start In
									<DatePicker value={dates?.start !== '' ? moment(dates.start, "DD-MM-YYYY") : null} onChange={onChange} format='DD-MM-YYYY' name='start' picker="date" disabledDate={(current) => {
													return current.isAfter(moment())
												}}/></div>
								<div className="d-flex flex-column" >End In
									<DatePicker value={dates?.end !== '' ? moment(dates?.end, "DD-MM-YYYY") : null} onChange={onChanges} name='end' format='DD-MM-YYYY' picker="date" disabledDate={(current) => {
														return current.isAfter(moment())
													}}/></div>
							</Space>
							</div>
                                <div>
                                
                                    <div class={`btn btn-primary ${loader==true?' btn-progress disabled':''}`} onClick={onProfileWiseSearchClick}> <i className="fa fa-search"></i> </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header ">
										<h4  >Lorawan Gateway analysis</h4>
									</div>
                                  
									<div className="card-body">
                                    {loader&& <div className="example">
									<Spin size="large" />
								</div>}
										<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
													
												</div>
                                                {loader==false&&GatewayList.length==0?<h6>*Please Select Account</h6>:''}
                               { loader==false&& GatewayList.length>0?<table id="table_id" className="tableCustom table table-striped">
													<thead>
														<tr>
															<th>Gateway List</th>
															<th>Gateway ID</th>
                                                            {[...HeaderDate].map((item)=>(
                                                            <th>{moment(item,'YYYY-MM-DD').format('DD-MM-YYYY')}</th>
                                                            ))}
                                                            <th>Option</th>
														</tr>
													</thead>
													<tbody>
														{GatewayList.length > 0 && GatewayList.map((item, id) => (
															<tr className="trheigth" >
																<td>{item?.GATEWAY_NAME}</td>
																<td>{item?.GATEWAY_ID}</td>
																{[...HeaderDate].map((date)=>(
                                                            <td onClick={()=>{setTableData(AnaData[item?.GATEWAY_ID][date])
                                                            setisModalVisible(true)
                                                        setnameModal(item?.GATEWAY_NAME+' '+(moment(item,'YYYY-MM-DD').format('DD-MM-YYYY')))}} className='tw-text-[#1890ff]' style={{cursor:'pointer'}}>{AnaData[item?.GATEWAY_ID][date]?.length}</td>
                                                            ))}
                                                            <td><div className='btn btn-primary' onClick={()=>{setLoraGraph({date:HeaderDate,data:Object.values(AnaData[item?.GATEWAY_ID]||{}).map((data)=>(data.length))})
                                                            setBufferModal(true)
                                                            setnameModal(item?.GATEWAY_NAME)}}><i className='fas fa-chart-bar'/></div></td>
															</tr>
														))}


													</tbody>
												</table>:''}
											</div>
										</div>
										
									</div>
								</div>
							</div>
						</div>
            </div>
        </>
    )
}
