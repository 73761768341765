import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Lock } from 'react-feather'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { activeandinactive, getmeter_category, getmeter_class, getmeter_firmware, getmeter_hardware_protocol, getmeter_make, getmeter_model_number, getmeter_software_protocol, getmeter_type, getmeter_update_data, getModemFirmware, getModemHardwareVersion, getModemMake, getModemModelNumber, getModemProductCode, getModemType, geturls3, Insertmeter_index, Insertmodem_index, MeterFileuploads, Updatemeter_index, Updatemeter_index_attachments, Updatemodem_index, uploadsFiles } from '../../../Commoncomponet/Utils'
import { toast } from 'react-toastify'
import { AttachmanyUrl, BootstrapTooltip, MeterUrl } from '../../../Commoncomponet/common'
import swal from "sweetalert2";
import moment from 'moment'
import fileDownload from 'js-file-download'
import { Modal } from 'antd'
import axios from 'axios'
import DraggabalTable from '../../../Commoncomponet/DraggabalTable'
window.Swal = swal;
export default function AddAll() {

    const [type,setType]=useState([])
    const [make,setMake]=useState([])
    const [ModelNumber,setModelNumber]=useState([])
    const [Firmware,setFirmware]=useState([])
    const [Category,setCategory]=useState([])
    const [Software,setSoftware]=useState([])
    const [meterModemType,setmeterModemType]=useState([])
    const [Hardware,setHardware]=useState([])
const [MeterClass,setmeterClass]=useState([])
const [loginErrors, setValidation] = useState({});
const [flagBtn, setflegBtn] = useState(false);
const [ModemId,setModemId]=useState('')
const [disable,setDisable]=useState(true)
const [status,setStatus]=useState(null)
const [UrlImg,setUrlImg]=useState('')
const [ChangesJson,setChangesJson]=useState({})

const [inputs,setInputs]=useState({Name:'',Type:'',Make:'',ModelNumber:'',Firmware:'',Category:'',Hardware:'',memoryDay:'',Software :'',note:'',meterModemType:'',classs:'',Name_plate:'',Meter_Baudrate:'', CLIENT_ADDR:'',SERVER_ADDR:'',LOGICAL_NAME_USE:'',AUTH_TYPE:'',PASSWORD:'',EVC:0,Mechanical:0,BAT_DAYS:'',VD_ID:'',config:'',MECHANICAL_SRNO_ALLOW:0,EVC_SRNO_ALLOW:0})
const history = useHistory();
const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})
const [Attachment, setAttachment] = useState([]);
const [isModalImage,setisModalImage]=useState(false)
   const [imageName,setImageName]=useState('')
const [TableKey,setTableKey]=useState('dailydata')
const [AttachLoader,setAttachLoader]=useState(false)
const [AttachCompare,setAttachCompare]=useState([])

const locations=useLocation()
      useEffect(() => {
          if (locations?.state) {
             setInputs({Name:locations?.state?.METER_PROFILE_NAME,Type:locations?.state?.METER_TYPE,Make:locations?.state?.METER_MAKE,ModelNumber:locations?.state?.METER_MODEL,Firmware:locations?.state?.METER_FIRMWARE,Category:locations?.state?.METER_CATEGORY,Hardware:locations?.state?.METER_HARDWARE_PROTOCOL,memoryDay:locations?.state?.MEMORY_STORAGE_IN_DAYS,Software:locations?.state?.METER_SOFTWARE_PROTOCOL,note:locations?.state?.METER_NOTES,meterModemType:locations?.state?.METER_MODEM,classs:locations?.state?.METER_CLASS,Name_plate:locations?.state?.NAME_PLATE_DATA,Meter_Baudrate:locations?.state?.METER_BAUDRATE, CLIENT_ADDR:locations?.state?.CLIENT_ADDR,SERVER_ADDR:locations?.state?.SERVER_ADDR,LOGICAL_NAME_USE:locations?.state?.LOGICAL_NAME_USE,AUTH_TYPE:locations?.state?.AUTH_TYPE,PASSWORD:locations?.state?.PASSWORD,EVC:locations?.state?.EVC,Mechanical:locations?.state?.Mechanical,BAT_DAYS:locations?.state?.BAT_DAYS,VD_ID:locations?.state?.VD_ID,config:locations?.state?.EVC_CONFIG,MECHANICAL_SRNO_ALLOW:locations?.state?.MECHANICAL_SRNO_ALLOW||0,EVC_SRNO_ALLOW:locations?.state?.EVC_SRNO_ALLOW||0})
             setModemId(locations?.state?.METER_UNIQUE_ID)
             setDisable(false)
             setStatus(locations?.state?.METER_STATUS)
             setAttachment(JSON.parse(locations?.state?.METER_ATTECHMENT||'[]'))
             setChangesJson(JSON.parse(locations?.state?.TABLE_JSON||'{}'))
             setAttachCompare(JSON.parse(locations?.state?.METER_ATTECHMENT||'[]'))
          }
       }, [locations]);
    const onHandaleSelection=async()=>{
        const data=await getmeter_type()
        if(data.success===true){
            setType(data.data.data)
            const data1=await getmeter_make()
        if(data1.success===true){
            setMake(data1.data.data)
            const data2=await getmeter_firmware()
        if(data2.success===true){
            setFirmware(data2.data.data)
            const data3=await getmeter_category()
        if(data3.success===true){
            setCategory(data3.data.data)
            const data4=await getmeter_hardware_protocol()
        if(data4.success===true){
            setHardware(data4.data.data)
        }
        const data5=await getmeter_model_number()
        if(data5.success===true){
            setModelNumber(data5.data.data)
        }
        const data6=await getmeter_software_protocol()
        if(data6.success===true){
            setSoftware(data6.data.data)
        }
        const data7=await getModemType()
        if(data7.success===true){
            setmeterModemType(data7.data.data)
        }
        const data8=await getmeter_class()
        if(data8.success===true){
            setmeterClass(data8.data.data)
        }
        }
        }
        }
        }else{
          console.log('somthing want wrong')
        }
      }

      const onAddAttachment = () => {
         const initial = {SRNO: Attachment.length+1,title: "", description: "", file: "",created_time:moment().format(),modified_time:'' };
         // if(contactDetails.length>0){
         const task = Attachment[Attachment.length - 1];
         Attachment[Attachment.length] = initial;
         setAttachment([...Attachment]);
         // }
         };
      const onDeleteAttachment =async (id) => {
         swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Card!",
            icon: "warning",
            showCancelButton: true,
         }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
         const test = [...Attachment];
         const data=test.filter((item,ids)=>(ids!==id))
         setAttachment([...data]);
         await Updatemeter_index_attachments(ModemId,{Attachment:data})
            }
            })
      };
      const handleChangeAttachment = async (e) => {
         const { name, value, id } = e.target;
         let updatedTask = [...Attachment];
         if(name==='file'){
            var totalSizeMB = value.target.files[0].size / Math.pow(1024, 2)
            if (totalSizeMB >= 50) {
               swal.fire('', 'File size should be under 50 MB', 'error');
       }else{
            updatedTask[id][name] = value.target.files[0];
       }
         }else{
            updatedTask[id][name] = value.target.value.replace(/[\/\\"`\t\n]/g, '');
         }
         setAttachment([...updatedTask]);
      };
      useEffect(() => {
        onHandaleSelection()
      }, []);

      const validation = (name, value) => {
         switch (name) {
             case 'Name':
                 if (!value) {
                     return 'Please input EVC Name!'
                 } else {
                     return '';
                   }
                //    case 'Type':
                //  if (!value) {
                //      return 'Please input EVC Type!'
                //  } else {
                //      return '';
                //    }
                //    case 'Make':
                //  if (!value) {
                //      return 'Please input EVC Make!'
                //  } else {
                //      return '';
                //    }
                //    case 'ModelNumber':
                //  if (!value) {
                //      return 'Please input EVC Model Number!'
                //  } else {
                //      return '';
                //    }
                //    case 'Firmware':
                //  if (!value) {
                //      return 'Please input EVC Firmware!'
                //  } else {
                //      return '';
                //    }
                //    case 'Category':
                //  if (!value) {
                //      return 'Please input EVC Category!'
                //  } else {
                //      return '';
                //    }
                //    case 'Software':
                //  if (!value) {
                //      return 'Please input EVC Software!'
                //  } else {
                //      return '';
                //    }
                //    case 'Hardware':
                //  if (!value) {
                //      return 'Please input EVC Hardware!'
                //  } else {
                //      return '';
                //    }
                //    case 'meterModemType':
                //  if (!value) {
                //      return 'Please input EVC  EVC Modem Type!'
                //  } else {
                //      return '';
                //    }
                   
                   default: {
                     return null;
                 }
             }
             }
             function formatBytes(bytes) {
               var marker = 1024; // Change to 1000 if required
               var decimal = 2; // Change as required
               var kiloBytes = marker; // One Kilobyte is 1024 bytes
               var megaBytes = marker * marker; // One MB is 1024 KB
               var gigaBytes = marker * marker * marker; // One GB is 1024 MB
         
               // return bytes if less than a KB
               if (bytes < kiloBytes) return bytes + " Bytes";
               // return KB if less than a MB
               else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
               // return MB if less than a GB
               else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";
           }
             const onUploadFile=async(id,isAttach)=>{
               setAttachLoader(true)
               if(isAttach==true){
            var attechmentsobj=[]
                  const formData = new FormData()
                  formData.append('Images', Attachment[id].file)
                  const uploadImges=await MeterFileuploads(formData)
                  if(uploadImges.success===true){
                     Attachment[id].file=uploadImges?.data?.originalname
                     Attachment[id].file_size=formatBytes(uploadImges?.data?.size)
                     const data=await Updatemeter_index_attachments(ModemId,{Attachment:Attachment})
                     if(data.success==true) {
                        ongetmeter_update_data()
                        setAttachLoader(false)
                        toast.success('File Successfully Upload')
                     }
                  }else{
               setAttachLoader(false)
                     toast.error('File Not Uploaded')
                     // attechmentsobj.push({...item,file:''})
                  }
               }else{
                  const data=await Updatemeter_index_attachments(ModemId,{Attachment:Attachment})
                     if(data.success==true) {
                        ongetmeter_update_data()
               setAttachLoader(false)
                        toast.success('Successfully Updated')
                     }else{
               setAttachLoader(false)
                     toast.error('Server Issue')
                     // attechmentsobj.push({...item,file:''})
                  }
               }
             }

             const ongetmeter_update_data=async()=>{
               const data=await getmeter_update_data(ModemId)
               if(data.success==true){
                  const inFometer=data.data.data
                  setInputs({Name:inFometer?.METER_PROFILE_NAME,Type:inFometer?.METER_TYPE,Make:inFometer?.METER_MAKE,ModelNumber:inFometer?.METER_MODEL,Firmware:inFometer?.METER_FIRMWARE,Category:inFometer?.METER_CATEGORY,Hardware:inFometer?.METER_HARDWARE_PROTOCOL,memoryDay:inFometer?.MEMORY_STORAGE_IN_DAYS,Software:inFometer?.METER_SOFTWARE_PROTOCOL,note:inFometer?.METER_NOTES,meterModemType:inFometer?.METER_MODEM,classs:inFometer?.METER_CLASS,Name_plate:inFometer?.NAME_PLATE_DATA,Meter_Baudrate:inFometer?.METER_BAUDRATE, CLIENT_ADDR:inFometer?.CLIENT_ADDR,SERVER_ADDR:inFometer?.SERVER_ADDR,LOGICAL_NAME_USE:inFometer?.LOGICAL_NAME_USE,AUTH_TYPE:inFometer?.AUTH_TYPE,PASSWORD:inFometer?.PASSWORD,EVC:inFometer?.EVC,Mechanical:inFometer?.Mechanical,BAT_DAYS:inFometer?.BAT_DAYS,VD_ID:inFometer?.VD_ID,config:locations?.state?.EVC_CONFIG,MECHANICAL_SRNO_ALLOW:locations?.state?.MECHANICAL_SRNO_ALLOW||0,EVC_SRNO_ALLOW:locations?.state?.EVC_SRNO_ALLOW||0})
                  setModemId(inFometer?.METER_UNIQUE_ID)
                  setDisable(false)
                  setStatus(inFometer?.METER_STATUS)
                  setAttachment(JSON.parse(inFometer?.METER_ATTECHMENT||'[]'))
                  setAttachCompare(JSON.parse(inFometer?.METER_ATTECHMENT||'[]'))
               }else{
                  console.log('something want wrong')
               }
             }

      const onHandaleSubmitData=async(e)=>{
         // e.preventDefault()
         setflegBtn(true)
         let allErrors = {};
      Object.keys(inputs).forEach(key => {
        const error = validation(key, inputs[key]);
        if (error && error.length) {
            allErrors[key] = error
        }
    });
  
    if (Object.keys(allErrors).length) {
      setflegBtn(false)
     return setValidation(allErrors)
       }else{
         if(ModemId===''){
           
         const data=await Insertmeter_index({...inputs,add_by:userInfo?.UNIQUE_ID})
         if(data.success===true){
             toast.success('Add EVC Success')
         setflegBtn(false)
         history.push('/allEVC')
         }else{
             toast.error('Something Wait Wrong')
         setflegBtn(false)
         }
     }else{
     const data2=await Updatemeter_index({...inputs,ChangesJson:JSON.stringify(ChangesJson)},ModemId)
     if(data2.success===true){
         toast.success('Update EVC Success')
     setflegBtn(false)
     history.push('/allEVC')
     }else{
         toast.error('Something Wait Wrong')
     setflegBtn(false)
 
     }
   
     }
       }
     }
     const columns=[{
      Header: "Id",
      accessor: "Order"
   },{
      Header: "Database Column",
      accessor: "Column"
   },{
      Header: "Header Column",
      accessor: "Header"
   }]
     const oninactive=async()=>{
      swal.fire({
			title: "Are You Sure InActive EVC?",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'evc_index',colName:'EVC_STATUS',value:'0',matchId:'EVC_UNIQUE_ID'},ModemId)
      if(data.success===true){
         toast.success('Success Inactive EVC')
         history.push('/allEVC')
      }else{
         toast.error('something want wrong')
      }
   }
})
     }
     const onactive=async()=>{
      swal.fire({
			title: "Are You Sure EVC?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'evc_index',colName:'EVC_STATUS',value:'1',matchId:'EVC_UNIQUE_ID'},ModemId)
      if(data.success===true){
         toast.success('Success Active EVC')
         history.push('/allEVC')
      }else{
         toast.error('something want wrong')
      }
   }
})
     }
     const onDownloadFiles=async(e,name)=>{
      // e.preventDefault()

      const data1=await geturls3({bucketName:'gums.memighty.com',objectKey:`Meter_Driver_Attachments/${name}`, expiration:60})
		console.log(data1);
		var link = document.createElement("a");
		link.href = data1.data.data;
		link.download = name;
		link.click();
     }

     const onViewAttachments=async(e,name)=>{
      // e.preventDefault()

      setisModalImage(true) 
      const data1=await geturls3({bucketName:'gums.memighty.com',objectKey:`Meter_Driver_Attachments/${name}`, expiration:60})
      setImageName(name)
      setUrlImg(data1.data.data)

     }

     const handleDragEnd = () => {
      setChangesJson({
         ...ChangesJson,
         Meter_Data: {
            ...ChangesJson?.Meter_Data,
            [TableKey]: ChangesJson?.Meter_Data[TableKey]
         }
      });
   };
     var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
     var imageExtensions=/(\.mp4|\.webm|\.ogg|\.mkv|\.mpg|\.m4v|\.m4p |\.mpv|\.vob|\.mpeg)$/i;
  return (
    <>
    <Modal title="Image" visible={isModalImage} footer='' onCancel={()=>(setisModalImage(false))} width={800}>
       {allowedExtensions.exec(imageName)?
         <img src={`${UrlImg}`} height={500} width={"100%"}></img>:imageExtensions.exec(imageName)?<video height={500} width={"100%"} controls><source src={`${UrlImg}`} type="video/mp4"/><source src={`${UrlImg}`} type="video/ogg"/></video>:''
       }
       </Modal>
    <div className="main-content">
    <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
                            <Lock className='header-logo' style={{ height: "16px" }} /> Admin
							</Link>
						</li>
						<li className="breadcrumb-item">
						<Link to='/allEVC'>
							 EVC Driver
						</Link>
					</li>
						<li className="breadcrumb-item">
							<Link >{ModemId===''?'Add EVC':'Update EVC'}</Link>
						</li>
					</ol>
				</nav>
                <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>{ModemId===''?'Add EVC':'Update EVC'}</h4>
                           <div class="card-header-action">  
                           {ModemId!==''?<div  class="btn btn-info text-white btnedit mr-2" onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{ModemId!==''&&status==1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{ModemId!==''&&status==0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''}
                              <Link to="/allEVC" class="btn btn-info text-white align-left">
                              Back
                              </Link>                        
                           </div>
                        </div>
                        <div class="card-body">
                           
                              <div class="form-row" style={{marginLeft:"10px"}}>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">EVC Profile Name: <span class="text-danger">*</span></label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Modem Profile Name" required="" onChange={(e)=>(setInputs({...inputs,Name:e.target.value}))} value={inputs.Name} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.Name}</span>    
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">EVC Type</label>
                                    <select class="form-control form-select" name="modemtype" id="modemtype" onChange={(e)=>(setInputs({...inputs,Type:e.target.value}))} value={inputs.Type} disabled={!disable}>
                                       <option value="">Select EVC Type</option>
                                                           {type&&type.map((item)=>(<option value={item.UNIQUE_ID}>{item?.METER_TYPE_NAME}</option>))}  
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Name}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">EVC Make</label>
                                    <select class="form-control form-select" name="modemmake" id="modemmake" onChange={(e)=>(setInputs({...inputs,Make:e.target.value}))} value={inputs.Make} disabled={!disable}>
                                       <option value="">Select EVC Make</option>
                                       {make&&make.map((item)=>(<option value={item.METER_MAKE_UNIQUE_ID}>{item?.METER_MAKE_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Make}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">EVC Model Number</label>
                                    <select class="form-control form-select " name="modemmodalversion" id="modemmodalversion" onChange={(e)=>(setInputs({...inputs,ModelNumber:e.target.value}))} value={inputs.ModelNumber} disabled={!disable}>
                                       <option value="">Select EVC Model Number</option>
                                       {ModelNumber&&ModelNumber.map((item)=>(<option value={item.METER_MODEL_NUMBER_UNIQUE_ID}>{item?.METER_MODEL_NUMBER_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.ModelNumber}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">EVC Firmware</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemfirmware[]" id="modemfirmware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,Firmware:e.target.value}))} value={inputs.Firmware} disabled={!disable}>
                                       <option value="">Select EVC Firmware</option>
                                       {Firmware&&Firmware.map((item)=>(<option value={item.METER_FIRMWARE_UNIQUE_ID}>{item?.METER_FIRMWARE_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Firmware}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">EVC Software</label>
                                    <select class="form-control form-select " name="modemproductcode" id="modemproductcode" onChange={(e)=>(setInputs({...inputs,Software:e.target.value}))} value={inputs.Software} disabled={!disable}>
                                       <option value="">Select EVC Software</option>
                                       {Software&&Software.map((item)=>(<option value={item.METER_SOFTWARE_PROTOCOL_UNIQUE_ID}>{item?.METER_SOFTWARE_PROTOCOL_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Software}</span>    

                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">EVC Hardware Version</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemhardware[]" id="modemhardware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,Hardware:e.target.value}))} value={inputs.Hardware} disabled={!disable}>
                                       <option value="">Select EVC Hardware Version</option>
                                       {Hardware&&Hardware.map((item)=>(<option value={item.METER_HARDWARE_PROTOCOL_UNIQUE_ID}>{item?.METER_HARDWARE_PROTOCOL_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Hardware}</span>   
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">EVC Category</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemhardware[]" id="modemhardware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,Category:e.target.value}))} value={inputs.Category} disabled={!disable}>
                                       <option value="">Select EVC Category</option>
                                       {Category&&Category.map((item)=>(<option value={item.UNIQUE_ID}>{item?.METER_CATEGORY_NAME}</option>))}
                                    </select>
                                    <span className='text-danger'>{loginErrors?.Category}</span>   
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">EVC Modem Type</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemhardware[]" id="modemhardware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,meterModemType:e.target.value}))} value={inputs.meterModemType} disabled={!disable}>
                                       <option value="">Select EVC Modem Type</option>
                                       {meterModemType&&meterModemType.map((item)=>(<option value={item.UNIQUE_ID}>{item?.NAME}</option>))}
                                    </select>
                                    <span className='text-danger'>{loginErrors?.meterModemType}</span>   
                                 </div>
                                 {/* <div class="form-group col-md-3">
                                    <label for="inputPassword4">EVC class</label>
                                    <select class="form-control form-select select2-hidden-accessible" name="modemfirmware[]" id="modemfirmware" multiple="" tabindex="-1" aria-hidden="true" onChange={(e)=>(setInputs({...inputs,classs:e.target.value}))} value={inputs.classs} disabled={!disable}>
                                       <option value="">Select EVC Class</option>
                                       {MeterClass&&MeterClass.map((item)=>(<option value={item.METER_CLASS_UNIQUE_ID}>{item?.METER_CLASS_NAME}</option>))}
                                       	
                                    </select>
                                    <span className='text-danger'>{loginErrors?.classs}</span>    

                                 </div> */}
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Memory Storage In days: <span class="text-danger">*</span></label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,memoryDay:e.target.value}))} value={inputs.memoryDay} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Battery Days: <span class="text-danger">*</span></label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,BAT_DAYS:e.target.value}))} value={inputs.BAT_DAYS} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">VD ID:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="number" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,VD_ID:e.target.value}))} value={inputs.VD_ID} disabled={!disable}/>
                                 </div>
                              </div>
                              
                              <div class="form-row mb-3" style={{marginLeft:"10px",borderBottom:"1px solid black"}}>
                                 <div div="" class="form-group col-md-3">
                                    <label for="inputPassword4">EVC Notes: </label>
                                    <textarea class="form-control" name="modemnotes" id="modemnotes" type="text" placeholder="Enter EVC Notes" onChange={(e)=>(setInputs({...inputs,note:e.target.value}))} value={inputs.note} disabled={!disable}></textarea>
                                 </div>
                                 <div div="" class="form-group col-md-3">
                                    <label for="inputPassword4">Meter config: </label>
                                    <textarea class="form-control" name="modemnotes" id="modemnotes" type="text" placeholder="Enter Meter config" onChange={(e)=>(setInputs({...inputs,config:e.target.value}))} value={inputs.config} disabled={!disable}></textarea>
                                 </div>
                                  <div div="" class="form-group col-md-3">
                                    <label for="inputPassword4">Name Plate Data: </label>
                                    <textarea class="form-control" name="modemnotes" id="modemnotes" type="text" placeholder="Enter Name Plate Data" onChange={(e)=>(setInputs({...inputs,Name_plate:e.target.value}))} value={inputs.Name_plate} disabled={!disable}></textarea>
                                 </div>
                                 {/* <div div="" class="form-group col-md-3">
                                    <label for="inputPassword4">Name Plate Data: </label>
                                    <textarea class="form-control" name="modemnotes" id="modemnotes" type="text" placeholder="Enter Name Plate Data" onChange={(e)=>(setInputs({...inputs,Name_plate:e.target.value}))} value={inputs.Name_plate} disabled={!disable}></textarea>
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">EVC Baudrate: </label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,Meter_Baudrate:e.target.value}))} value={inputs.Meter_Baudrate} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Client Address:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,CLIENT_ADDR:e.target.value}))} value={inputs.CLIENT_ADDR} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Server Addr:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,SERVER_ADDR:e.target.value}))} value={inputs.SERVER_ADDR} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                  <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Logical Name Use: </label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,LOGICAL_NAME_USE:e.target.value}))} value={inputs.LOGICAL_NAME_USE} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Auth Type:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,AUTH_TYPE:e.target.value}))} value={inputs.AUTH_TYPE} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">Password:</label>
                                    <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Memory Storage In day" required="" onChange={(e)=>(setInputs({...inputs,PASSWORD:e.target.value}))} value={inputs.PASSWORD} disabled={!disable}/>
                                    <span className='text-danger'>{loginErrors?.memoryDay}</span>    
                                 </div> */}

<div className="custom-control custom-checkbox mr-4 ml-4 mt-2">
									<input type="checkbox" id="EVC" name="EVC" className="custom-control-input" onChange={(e)=>{e.target.checked?setInputs({...inputs,EVC:1}):setInputs({...inputs,EVC:0})}} checked={inputs?.EVC==1?true:false} disabled={!disable}/>
									<label className="custom-control-label" for="EVC" >EVC</label>
								</div>
                        <div className="custom-control custom-checkbox mt-2">
									<input type="checkbox" id="Mechanical" name="Mechanical" className="custom-control-input" onChange={(e)=>{e.target.checked?setInputs({...inputs,Mechanical:1}):setInputs({...inputs,Mechanical:0})}} checked={inputs?.Mechanical==1?true:false} disabled={!disable}/>
									<label className="custom-control-label" for="Mechanical" >Mechanical</label>
								</div>
                        <div className="custom-control custom-checkbox mr-4 ml-4   mb-4">
									<input type="checkbox" id="mechanicalNo" name="MECHANICAL_SRNO_ALLOW" className="custom-control-input" onChange={(e)=>{e.target.checked?setInputs({...inputs,MECHANICAL_SRNO_ALLOW:1}):setInputs({...inputs,MECHANICAL_SRNO_ALLOW:0})}} checked={inputs?.MECHANICAL_SRNO_ALLOW==1?true:false} disabled={!disable}/>
									<label className="custom-control-label" for="mechanicalNo" >Allow Mechanical Serial No.</label>
								</div>
                        <div className="custom-control custom-checkbox mr-4 ml-4   mb-4">
									<input type="checkbox" id="EVC_SRNO_ALLOW" name="EVC_SRNO_ALLOW" className="custom-control-input" onChange={(e)=>{e.target.checked?setInputs({...inputs,EVC_SRNO_ALLOW:1}):setInputs({...inputs,EVC_SRNO_ALLOW:0})}} checked={inputs?.EVC_SRNO_ALLOW==1?true:false} disabled={!disable}/>
									<label className="custom-control-label" for="EVC_SRNO_ALLOW" >Allow EVC Serial No.</label>
								</div>
                              </div>
                              
                              <div class="row">
															<div class="col-12">
																<div class="row" id="dynamic_attech">
																	{Attachment &&
																		Attachment.map((item, id) => (
																			<>
																				<div class="col-6" id="colls1">
																					<div class="card card-primary">
																						<div class="card-header">
																							<h4>{id+1}</h4>
                                                                     {!disable?<div class="card-header-action ">
                                                <div class="btn btn-warning text-white mr-2" onClick={(e)=>{onDownloadFiles(e,item.file)}}><i class="fa fa-download"></i></div>
                                                <a onClick={(e)=>{onViewAttachments(e,item.file)
                                                }} class="btn btn-info"><i class="fa fa-eye" ></i></a>
                                             </div>:
																							<div class="card-header-action">
                                                                    {item.file!==''&& typeof item.file==='string'? <><div class="btn btn-warning text-white mr-2" onClick={(e)=>{onDownloadFiles(e,item.file)}}><i class="fa fa-download" ></i></div>
                                                <a  onClick={(e)=>{onViewAttachments(e,item.file)}} class="btn btn-info mr-2 text-white"><i class="fa fa-eye"></i></a></>:''}
																								{disable?<><a
																									class="btn btn-danger text-white"
																									id="1"
                                                                           onClick={()=>(onDeleteAttachment(id))}
																								>
																									<i class='fas fa-xmark'></i>
																								</a></>:''} 
																							</div>}
																							{/* <div class="card-header-action">
																								<button
																									type="button"
																									class="btn btn-danger text-white btn_removes"
																									id="1"
                                                                           onClick={()=>(onDeleteAttachment(id))}
																								>
																									<i class="fa fa-times" aria-hidden="true"></i>
																								</button>
																							</div> */}
																						</div>
																						<div class="card-body">
																							<div class="form-group">
																								<div
																									class="form-group row"
																									style={{ marginTop: "-20px" }}
																								>
																									<label
																										for="attechmenttitle1"
																										class="col-sm-3 col-form-label"
																									>
																										Title
																									</label>
																									<div class="col-sm-9">
																										<input
																											type="text"
																											class="form-control"
																											placeholder="Title"
																											onChange={(value) =>
                                                                                    handleChangeAttachment({
																												target: {
																													name: "title",
																													value,
																													id,
																												},
																											})
																										}
                                                                              value={Attachment[id].title}
                                                                              disabled={!disable}
																										/>
																									</div>
																								</div>
																								<div
																									class="form-group row"
																									style={{ marginTop: "-20px" }}
																								>
																									<label
																										for="lastcontectname1"
																										class="col-sm-3 col-form-label"
																									>
																										Description
																									</label>
																									<div class="col-sm-9">
																										<textarea
																											class="form-control"
																											id="attechmentdescr1"
																											onChange={(value) =>
                                                                                    handleChangeAttachment({
																												target: {
																													name: "description",
																													value,
																													id,
																												},
																											})
																										}
                                                                              disabled={!disable}

                                                                              value={Attachment[id].description}
																										></textarea>
																									</div>
																								</div>
																								<div
																									class="form-group row"
																									style={{ marginTop: "-20px" }}
																								>
																									<label
																										for="lastcontectname1"
																										class="col-sm-3 col-form-label"
																									>
																										Attachment
																									</label>
																									<div class="col-sm-9">
                                                                           <div
                                                                           class="d-flex"
                                                                           style={{ padding:'2px' }}
                                                                           
                                                                        >
                                                                           <input
                                                                              type="file"
                                                                              class="form-control"
                                                                              id={"file-uploads"+id}
                                                                              accept="application/msword,image/gif,image/jpeg,application/pdf,image/png,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,.doc,.gif,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.zip"
                                                                              onChange={(value) =>
                                                                                 handleChangeAttachment({
                                                                                    target: {
                                                                                       name: "file",
                                                                                       value,
                                                                                       id,
                                                                                    },
                                                                                 })
                                                                              }
                                                                              hidden
                                                                              disabled={!disable}
                                                                           />
                                                                           <div className='btn btn-secondary text-dark mr-1' style={{ width:"120px" }} onClick= {()=>{document.getElementById('file-uploads'+id).click()}}>Upload File</div>
                                                                           <input type={'text'} class="form-control" value={item.file !== '' && typeof item.file === 'string' ?item.file:item?.file?.name?item?.file?.name:'No Choose File'}></input>
																									</div>
                                                                           </div>
																								</div>
                                                                        {ModemId!==''&&<spam>Created At: {item?.create_at!==''?moment(item?.create_at).format('DD-MM-YYYY hh:mm:ss A'):''}</spam>}<br/>
                                                                        {ModemId!==''&&<spam>Update At: {item?.update_at!==''?moment(item?.update_at).format('DD-MM-YYYY hh:mm:ss A'):''}</spam>}
																							</div>

                                                                     {(Attachment[id].title!=AttachCompare[id]?.title||Attachment[id].description!=AttachCompare[id]?.description)&&(item.file===''|| typeof item.file==='string')?<div className={`btn btn-primary ${AttachLoader==true?'disabled btn-progress':''}`} onClick={()=>onUploadFile(id,false)}>Update</div>:''}

                                                                     {item.file!==''&& typeof item.file!=='string'?  <div className={`btn btn-primary ${AttachLoader?'disabled btn-progress':''}`} onClick={()=>onUploadFile(id,true)}>Upload</div>:''}
																						</div>
																					</div>
																				</div>
																			</>
																		))}
																</div>
															</div>
														</div>
                             {ModemId!=''?<div class="col-md-12 p-4">
															<button
																type="button"
																name="add"
																id="addatt"
																class={`btn btn-info ${!disable?'disabled':""}`}
																onClick={onAddAttachment}
															>
																+Add Attachment
															</button>
														</div>:''}
                                          {ModemId===''?'': ChangesJson?<><div class="form-group col-md-3 d-flex" id="grpeui" style={{paddingLeft:'0px'}}>
                                            <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e)=>(setTableKey(e.target.value))} value={TableKey}>
                                                {ChangesJson?.Meter_Data&&Object.keys(ChangesJson?.Meter_Data).length>0&&Object.keys(ChangesJson?.Meter_Data).map((item)=>(<option value={item} >{item}</option>))}                  
                                                </select>
                                            <div id="meteid"></div>
                                            
                                        </div></>:''}
                                    <div className='table-responsive'>
                                    {ModemId === ''
                                    ? ''
                                    : ChangesJson?.Meter_Data && ChangesJson?.Meter_Data[TableKey]?.length > 0
                                       ? (
                                          <DraggabalTable
                                             columns={columns}
                                             data={ChangesJson?.Meter_Data[TableKey]}
                                             setitem={(e) => setChangesJson({ ...ChangesJson, Meter_Data: { ...ChangesJson?.Meter_Data, [TableKey]: e } })}
                                             onDragEnd={handleDragEnd}
                                          />
                                       )
                                       : ''}
                                   </div>
                              <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                              <div class="text-left">
                                 <input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={ModemId===''?'Submit':'Update'} id="submitvn" onClick={onHandaleSubmitData}/>
                                 <Link class="btn btn-light text-dark" to="/allEVC">Cancel</Link>  
                              </div>
                           
                        </div>
                     </div>
                  </div>
                </div>
            </div>
         </section>
      </div>
    </>
  )
}
