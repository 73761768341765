import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useInterval from "use-interval";
import { useHistory } from "react-router-dom";
import { ContryCodeData } from "./Contrycode";
import { Modal } from "react-bootstrap";
import axios from "axios";
import {LoginUesr, LoginUserWithMobileNo, sendOtp} from '../../Commoncomponet/Utils'
import "./Login.css";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import OtpInput2 from "../../Commoncomponet/OtpReader";
import CryptoJS from 'crypto-js';
import swal from "sweetalert2";
window.Swal = swal;

const LoginWithOTP = (props) => {
	const [login, setLogin] = useState({ email: "", password: "",mobileno:'' });
	const [input, setInput] = useState({ email_otp: null, added_otp: null });
	const [errorOtp, setErrorOtp] = useState({ email_otp: "", added_otp: "" });
	const [loginErrors, setValidation] = useState({});
	const [typeLogin, setTypeLogin] = useState("logpass");
	const [flag, setFlag] = useState(false);
	const [conCode, setConCode] = useState("+91");
	const [time, setTime] = useState(60);
	const [run, setRun] = useState(false);
	const [isOtp, setIsOtp] = useState(0);
	const [enableOtp, setEnableOtp] = useState(false);
	const [showError, setShowError] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [OtpSent, setOtpSent] = useState(false)
const [model,setModel]=useState(false)
const [OTP, setOTP] = useState();
	const history = useHistory();
	var [userDetail,setuserDetail]=useState({})
	const onHandalChange = (e) => {
		setLogin({ ...login, [e.target.name]: e.target.value });
	};

	const inputHandler = (e) => {
		setInput({ ...input, [e.target.name]: e.target.value });
	};

	let token = window.localStorage.getItem("token");
	useEffect(() => {
		if(token&&token!==""){
		//   history.push('/all-dashboard')
		}
	}, []);
	const validation = (name, value) => {
		switch (name) {
			case "email":
				if (!value) {
					return "Please input email!";
				} else {
					return "";
				}
			case "password":
				if (!value) {
					return "Please input password!";
				} else {
					return "";
				}
			default: {
				return null;
			}
		}
	};

	

	const handleLogin = async() => {
		console.log(userDetail);
        const decrypted = CryptoJS.AES.decrypt(isOtp, 'Memighty@GUMS')
        if (OTP.length === 6 && OTP === decrypted.toString(CryptoJS.enc.Utf8)) {
			
				window.localStorage.setItem('token',userDetail?.token)
				const JsonData=userDetail
				window.localStorage.setItem('user_info',JSON.stringify({COMPONY_LOGO_DATA: JsonData?.COMPONY_LOGO_DATA,
					CREATED_AT:JsonData?.CREATED_AT,
					CREATED_BY:JsonData?.CREATED_BY,
					EMAIL:JsonData?.EMAIL,
					EMAIL_VERIFIED:JsonData?.EMAIL_VERIFIED,
					FIRST_NAME:JsonData?.FIRST_NAME,
					LAST_NAME:JsonData?.LAST_NAME,
					MOBILE:JsonData?.MOBILE,
					MOBILE_VERIFIED:JsonData?.MOBILE_VERIFIED,
					PASSWORD:JsonData?.PASSWORD,
					PASSWORD_CHANGE:JsonData?.PASSWORD_CHANGE,
					PROFILE_PHOTO:JsonData?.PROFILE_PHOTO,
					ROLE:JsonData?.ROLE,
					SRNO:JsonData?.SRNO,
					STATUS:JsonData?.STATUS,
					UNIQUE_ID:JsonData?.UNIQUE_ID,token:JsonData?.token}))
				window.localStorage.setItem('login_time',moment().utcOffset("+05:30"))
				swal.fire({
					title: 'Trust this browser?',
					text: "We Won`t ask you to verify your account with OTP Authenticator on this brower for the next 90 days.",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: 'Trust',
					cancelButtonText:'Not Now'
				  }).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						toast.success("Login Success")
						window.localStorage.setItem('trust_browser',true)
				props.history.push('/all-dashboard')

					} else  {
						toast.success("Login Success")
						window.localStorage.setItem('trust_browser',false)
				props.history.push('/all-dashboard')

					}
				  })
        }
        else {
            toast.error(`Oops....!! You have entered wrong OTP 😐`)
        }
    }

	const onFormSubmit = async (e) => {
		if(OtpSent==true){
			handleLogin()
		}else{
			if(typeLogin=='logpass'){
		e.preventDefault();
		setFlag(true);
		const userData = {
			email: login.email,
			password: login.password,
		};
		let allErrors = {};
		
		Object.keys(login).forEach((key) => {
			const error = validation(key, userData[key]);
			if (error && error.length) {
				allErrors[key] = error;
			}
		});
		if (Object.keys(allErrors).length>0) {
			setFlag(false);
			return setValidation(allErrors);
			
		} else {
			setValidation({});
			const resultData=await LoginUesr(userData)
			if(resultData.success===true){
				setuserDetail(resultData?.data?.userData[0])
				setFlag(false)
				send_otp()
			}else if(resultData?.err?.response?.status===401) {
				toast.error("Invalid Password And User Email")
				setFlag(false)
			  }else if(resultData?.err?.response?.status===400) {
				toast.error("Invalid Password And User Email")
				setFlag(false)
			  }else{
				setFlag(false)
			  }
		}
		}else{
			const userData = {
			mobileno: login.mobileno,
				password: login.password,
			};
			let allErrors = {};
			if((userData?.mobileno?.split('+')[1]?.replaceAll("\\s", ""))==''){
				allErrors["mobileno"]='Please input Mobile Number!'
			}
			if(userData?.password==''){
				allErrors["password"]='Please input password!'

			}
			if (Object.keys(allErrors).length) {
				setFlag(false);
				return setValidation(allErrors);
				
			} else {
			setValidation({});
			const resultData= await LoginUserWithMobileNo(userData)
			if(resultData.success===true){
				setuserDetail(resultData?.data?.userData[0])
				setFlag(false)
				send_otp()
			}else if(resultData?.err?.response?.status===401) {
				toast.error("Invalid Password And User Email")
				setFlag(false)
			  }else if(resultData?.err?.response?.status===400) {
				toast.error("Invalid Password And User Email")
				setFlag(false)
			  }else{
				setFlag(false)
			  }
			}
		}
	}
	};

	function zeroPad(num) {
		var str = String(num);
		if (str.length < 2) {
			return "0" + str;
		}

		return str;
	}

  const eventHandler = (e) => {
    if (input.added_otp == isOtp) {
      console.log('successfull Login')
    }else{
      setShowError(true);
    }
  }

	const send_otp = async(e) => {
		const randmOtp = Math.floor(100000 + Math.random() * 900000);

		var encrypted = CryptoJS.AES.encrypt(randmOtp.toString(), 'Memighty@GUMS').toString()
	const data=await sendOtp({ finalNumber: login?.mobileno&&login?.mobileno?.split(' ')?.join(''), hasedOtp: encrypted,login_type:typeLogin=='logpass'?'email':'mobile_no',finalemail:login?.email })
	// const data=await sendOtp({ finalNumber: login?.mobileno&&login?.mobileno?.split(' ')?.join(''), hasedOtp: encrypted,login_type:'email',finalemail:'mmitgaurav108@gmail.com'})
	if(data.success){
		setIsOtp(encrypted)
		setOtpSent(true)
		setIsOtpSent(!isOtpSent)
				setTime(60);
				setRun(true)
	}else{
		toast.error(`Error while sending OTP 😐`)
	}
		
	};

	useInterval(
		() => {
			if (time <= 1) {
				setRun(false);
			}
			setTime((preState) => preState - 1);
		},
		time && run ? 1000 : null
	);

	return (
		<>
			<section className="section">
				<div className="container mt-5">
					<div className="row">
						<div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
							<div className="card card-primary">
								<div className="card-header">
									<h4>Login</h4>
								</div>
								<div className="card-body">
									<div class="form-group d-flex ">
										<div>
											<input
												type="radio"
												name="login_type"
												id="logindefault_password"
												style={{ height: "20px", width: "20px", verticalAlign: "middle" }}
												onChange={() => setTypeLogin("logpass")}
												checked={typeLogin === "logpass" ? true : false}
											/>
											<label for="logindefault_password" style={{ marginLeft: "5px" }}>
												Login with Email
											</label>
										</div>
										<div className="ml-4">
											<input
												type="radio"
												name="login_type"
												id="logindefault_otp"
												style={{ height: "20px", width: "20px", verticalAlign: "middle" }}
												onChange={() => setTypeLogin("logotp")}
												checked={typeLogin === "logotp" ? true : false}
											/>
											<label for="logindefault_otp" style={{ marginLeft: "5px" }}>
												Login With Mobile No.
											</label>
										</div>
									</div>
									{typeLogin === "logpass" ? (
										<div className="needs-validation" >
											<div className="form-group">
												<label for="email">Email</label>
												<input
													id="email"
													type="email"
													className="form-control"
													name="email"
													tabIndex="1"
													onChange={onHandalChange}
													value={login.email}
												/>
												<p className="text-danger">{loginErrors?.email || ""}</p>
											</div>
											<div className="form-group">
												<div className="d-block">
													<label for="password" className="control-label">
														Password
													</label>
													<div className="float-right">
														<a className="text-small">Forgot Password?</a>
													</div>
												</div>
												<input
													id="password"
													type="password"
													className="form-control"
													name="password"
													tabIndex="2"
													onChange={onHandalChange}
													value={login.password}
												/>
												<p className="text-danger">{loginErrors?.password || ""}</p>
											</div>
											{/* <div className="form-group">
												<div className="custom-control custom-checkbox">
													<input
														type="checkbox"
														name="remember"
														className="custom-control-input"
														tabIndex="3"
														id="remember-me"
													/>
													<label className="custom-control-label" for="remember-me">
														Remember Me
													</label>
												</div>
											</div> */}
                                            {isOtpSent ? <div>

												<div className="form-group">
												<label for="otp">OTP</label>
												<div class='d-flex'>
												<input
													id="otp"
													type="number"
													className="form-control"
													name="email"
													tabIndex="1"
													onChange={(e)=>setOTP(e.target.value)}
													value={OTP}
												/>
												<div class="ml-2">
												<input
													type="button"
													className="btn btn-primary btn-lg btn-block"
													id="send_otp"
													tabIndex="4"
													onClick={(e) => send_otp(e)}
													value={run ? `00:${zeroPad(time)}` : time ? "Send" : "Resend"}
													disabled={run ? true : false}
												/>
												</div>
											</div>
											</div>

</div> : ''
}
											<div className="form-group">
												{flag ? (
													<button
														type="submit"
														className="btn btn-primary btn-lg btn-progress disabled "
														tabIndex="4"
														style={{ width: "100%" }}
													>
														progress
													</button>
												) : (
													<button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4" onClick={onFormSubmit}>
														Login
													</button>
												)}
											</div>
										</div>
									) : (<>
										
											<div class="form-group ">
												<label>Mobile Number</label>
												<div className="d-flex">
                                                <PhoneInput
                                                                    country={'in'}
                                                                    onChange={(value, data, event, formattedValue) => {
                                                                    console.log(value, data, event, formattedValue)
																	setLogin({ ...login, mobileno: formattedValue?.split('-')?.join('')||'' });
	                                                                }}
																	value={login?.mobileno?.split('+')[1]?.replaceAll("\\s", "")}
                                                                    countryCodeEditable={false}
                                                                    inputStyle={{width: '100%'}}
                                                />
												</div>
												<span className="text-danger">{loginErrors?.mobileno}</span>
											
                                            
										</div>
                                        <div className="form-group">
                                        <div className="d-block">
                                            <label for="password" className="control-label">
                                                Password
                                            </label>
                                            <div className="float-right">
                                                <a className="text-small">Forgot Password?</a>
                                            </div>
                                        </div>
                                        <input
                                            id="password"
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            tabIndex="2"
                                            onChange={onHandalChange}
                                            value={login.password}
                                        />
                                        <p className="text-danger">{loginErrors?.password || ""}</p>
                                    </div>
                                    {/* <div className="form-group">
												<div className="custom-control custom-checkbox">
													<input
														type="checkbox"
														name="remember"
														className="custom-control-input"
														tabIndex="3"
														id="remember-me"
													/>
													<label className="custom-control-label" for="remember-me">
														Remember Me
													</label>
												</div>
											</div> */} 
{isOtpSent ? <div>

<div className="form-group">
<label for="otp">OTP</label>
<div class='d-flex'>
<input
	id="otp"
	type="number"
	className="form-control"
	name="email"
	tabIndex="1"
	onChange={(e)=>setOTP(e.target.value)}
	value={OTP}
/>
<div class="ml-2">
<input
	type="button"
	className="btn btn-primary btn-lg btn-block"
	id="send_otp"
	tabIndex="4"
	onClick={(e) => send_otp(e)}
	value={run ? `00:${zeroPad(time)}` : time ? "Send" : "Resend"}
	disabled={run ? true : false}
/>
</div>
</div>
</div>

</div> : ''
}
											<div className="form-group">
												{flag ? (
													<button
														type="submit"
														className="btn btn-primary btn-lg btn-progress disabled "
														tabIndex="4"
														style={{ width: "100%" }}
													>
														progress
													</button>
												) : (
													<button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4" onClick={onFormSubmit}>
														Login
													</button>
												)}
											</div>
                                    </>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

        <Modal show={showError} id="myModalconform" onHide={() => setShowError(false)}>
				<div className="modal-confirm">
					<Modal.Header className="justify-content-center bg-danger">
						<div className="modal-headercom bg-danger">
							<div className="icon-box">
								<i className="fas fa-check"></i>
							</div>
						</div>
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => setShowError(false)}>
							&times;
						</button>
					</Modal.Header>
					<Modal.Body>
						<div className="modal-body text-center">
							<h4>Opps!</h4>
							<p>Enter Valid OTP</p>
							<button
								className="btn btn-denger"
								data-dismiss="modal"
								style={{ backgroundColor: "#fc544b" }}
								onClick={() => setShowError(false)}
							>
								<span>Okay</span> <i className="fas fa-arrow-right material-icons">&#xE5C8;</i>
							</button>
						</div>
					</Modal.Body>
				</div>
			</Modal>

			<Modal show={model} id="myModalconform" onHide={() => setModel(false)}>
				<div className="modal-confirm">
					<Modal.Header className="justify-content-center">
						<div className="modal-headercom">
							<div className="icon-box">
								<i className="fas fa-check material-icons">&#xf00c;</i>
							</div>
						</div>
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => setModel(false)}>
							&times;
						</button>
					</Modal.Header>
					<Modal.Body>
						<div className="modal-body text-center">
							<h4>Great!</h4>
							<p>
								Otp Has been sent to <span className="mobnumber">{input.email_otp}</span> mobile number
							</p>
							<button className="btn btn-success" data-dismiss="modal" onClick={() => setModel(false)}>
								<span>Okay</span> <i className="fas fa-arrow-right material-icons">&#xE5C8;</i>
							</button>
						</div>
					</Modal.Body>
				</div>
			</Modal>
			</section>
		</>
	);
};

export default LoginWithOTP;
