import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import socketio from "socket.io-client";
import * as XLSX from 'xlsx';
export const socket = socketio.connect('https://gumsops.memighty.com:7001/');
// socket.emit('join', 'Hello World from client');
export const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

   function convertToCSV(objArray) {
		var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		var str = '';
	
		for (var i = 0; i < array.length; i++) {
			var line = '';
			for (var index in array[i]) {
				if (line != '') line += ','
	
				line += array[i][index];
			}
	
			str += line + '\r\n';
		}
	
		return str;
	}

	export function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export function exportExcelDownload(data,filename){
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}.xlsx`);
}

export const MENULIST=[
  {
      key: 1,
      name: 'Dashboard',
      id:'MENU1',
      view:false,
      children: [
          {
              key: 11,
              name: 'All',
              id:'MENU1_1',
              view:false
          },
          {
              key: 12,
              name: 'GSM Modem',
              id:'MENU1_2',
              view:false
          },
          {
              key: 13,
              name: 'Lorawan Modem',
              id:'MENU1_3',
              view:false
          },
          {
              key: 14,
              name: 'GSM Smart Meter',
              id:'MENU1_4',
              view:false
          },
          {
              key: 15,
              name: 'Lorawan Smart Meter',
              id:'MENU1_5',
              view:false
          }
      ]
  },
  {
      key: 2,
      name: 'Group',
      id:'MENU2',
      view:false
  },
  {
      key: 3,
      name: 'Consumer',
      id:'MENU3',
      view:false,
      children: [
          {
              key: 16,
              name: 'All Consumer',
              id:'MENU3_1',
              view:false
          },
          {
              key: 17,
              name: 'GSM Modem',
              id:'MENU3_2',
              view:false
          },
          {
              key: 18,
              name: 'Lorawan Modem',
              id:'MENU3_3',
              view:false
          },
          {
              key: 19,
              name: 'GSM Smart Meter',
              id:'MENU3_4',
              view:false
          },
          {
              key: 20,
              name: 'Lorawan Smart Meter',
              id:'MENU3_5',
              view:false
          },

      ]
  },
  {
    key: 26,
    name: 'Roles & Users',
    id:'MENU11',
    view:false,
    children: [
        {
            key: 27,
            name: 'User',
            id:'MENU11_1',
            view:false
        },
        {
            key: 28,
            name: 'Roles',
            id:'MENU11_2',
            view:false
        },
        {
            key: 29,
            name: 'Users Group',
            id:'MENU11_3',
            view:false
        }

    ]
},
  {
      key: 4,
      name: 'Modem Triggers',
      id:'MENU4',
      view:false,
      children: [
          {
              key: 21,
              name: 'GSM Modem',
              id:'MENU4_1',
              view:false
          },
          {
              key: 22,
              name: 'Lorawan Modem',
              id:'MENU4_2',
              view:false
          },
      ]
  },
  {
      key: 5,
      name: 'Notification Manger',
      id:'MENU5',
      view:false
  },
  {
      key: 6,
      name: 'Tags',
      id:'MENU6',
      view:false
  },
  {
      key: 7,
      name:'Integration',
      id:'MENU7',
      view:false,
      children: [
          {
              key: 23,
              name: 'Api',
              id:'MENU7_1',
              view:false
          },
          {
              key: 24,
              name: 'Plugin',
              id:'MENU7_2',
              view:false
          }
      ]
  },
  {
      key: 8,
      name: 'Release Notes',
      id:'MENU8',
      view:false
  },
  {
      key: 9,
      name:'Web Tabs',
      id:'MENU9',
      view:false
  },{
      key: 10,
      name: 'Bad Data Analytics',
      id:'MENU10',
      view:false
  }]

  export const AttachmanyUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Attachmants/'
  export const ReportUrl='https://gums.memighty.com:7001/uploadfiles/Reports/'
  export const ReporLoratUrl='https://gums.memighty.com:7001/uploadfiles/Lorareport/'
  export const UserUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Users/'
  export const MeterUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Meterattachment/'
  export const TaskUrl='https://ccmsnew.memighty.com:6001/uploadfiles/Task/'
