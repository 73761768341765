import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { FilterIdWiseNewFilterGenerate, getLoraReportBytypeName, getReportBytypeName, getReportDataInConsumerDetails, GetReportType, geturls3 } from "../../Commoncomponet/Utils";
import { AttachmanyUrl, BootstrapTooltip, ReporLoratUrl, ReportUrl } from "../../Commoncomponet/common";
import moment from "moment";
import fileDownload from "js-file-download";
import { Users } from "react-feather";
import { Modal } from "antd";
import Pagination from "../../Commoncomponet/Pagination";
$.fn.DataTable = dt;
export default function LorawanReports() {
    const [reportData, setReportData] = useState([]);
    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const [loader, setLoader] = useState(false);
	const [ConsumerModel,setConsumerModel]=useState(false)

    const buttonArray = [
        //add array from report_type_index
        { Name: "All", Type: "All" },
        { Name: "All GSM Consumer Details", Type: "allgsmconsumerlist" },
    ];
    const report_type = ["All", "allgsmconsumerlist"];
    const [btnText, setButtontext] = useState("All");
    const [ReportType, setReportType] = useState("All");
    const [numberofPage, setnumberofPage] = useState(1);
    const [ReportTypes,setReportTypes]=useState([])
    const [ConsumerDetails,setConsumerDetails]=useState([])
	const [modalText,setmodalText]=useState('')
	const [SRNOReport, setSRNOReport] = useState({});

    const onGetReportTypeGSM=async()=>{
		const data=await GetReportType({type:'Lorawan'})
		if(data.success==true){
			setReportTypes(data?.data?.data?.map((item)=>({value:item?.BACK_TYPE_NAME,label:item?.FRONT_TYPE_NAME})))
		}else{

		}
	}

    const ongetReportUserData = async (ReportType) => {
        setLoader(true);
        const firstPageIndex = (currentPage - 1) * PageSize;
        const data = await getLoraReportBytypeName(userInfo?.UNIQUE_ID, userInfo?.ROLE, ReportType, { start: firstPageIndex, end: PageSize });
        if (data.success === true) {
            setReportData(data.data.data);
            setnumberofPage(data.data.total_count)
            setLoader(false)

        } else {
            console.log("something want wrong");
            setLoader(false);
        }
    };

    const [PageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        ongetReportUserData(ReportType)
        // .then(() =>
        // 	setTimeout(function () {
        // 		$("#table-1").DataTable({ destroy: true, pageLength: 50, order: [[0, "desc"]] });
        // 		setLoader(false);
        // 	}, 1100)
        // );
        onGetReportTypeGSM()
    }, [ReportType]);
    useEffect(() => {
        ongetReportUserData(ReportType)

    }, [currentPage, PageSize]);
    const onHandalebtn = (text) => {
        setButtontext(text);
        setReportType(text);
    };

    const onDownloadFiles = async (name) => {
        const data1=await geturls3({bucketName:'gums.memighty.com',objectKey:`LORAWAN_CONSUMER_REPORT/${name}`, expiration:60})
        const link = document.createElement("a");
		link.href =data1.data.data;
		link.download = name;
		link.click();
        // const myHeaders = new Headers();

        // const myRequest = new Request(data1.data, {
        //     method: "GET",
        //     headers: myHeaders,
        //     mode: "cors",
        //     cache: "default",
        // });
        // const data = await fetch(myRequest);
        // const blob = await data.blob();

        // if (data.status === 200) {
        //     await fileDownload(blob, name);
        // }
    };
    const history = useHistory();

    const showHistory = (reportType) => {
        console.log("first");
        history.push({ pathname: "/reportHistory", state: reportType });
    };

    const [info, setInfo] = useState({});
    const [ismodelFilter, setismodelFilter] = useState(false);
    const [InfoType, setInfoType] = useState("");
    const [ismodelFilterObj, setismodelFilterObj] = useState(false);
    const [FilterObject, setFilterObject] = useState({});
    const onChangeInfo = (info, add_by, add_at, TYPE, item) => {
        if (info) {
            const obj = JSON.parse(info || "{}");
            setInfo({ ...item, ...obj, add_by: add_by, add_at: add_at });
            setInfoType(TYPE);
            setismodelFilter(true);
            setSRNOReport(item)
        }
    };

    const onFilterIdWiseNewFilterGenerate = async (filterObj) => {
        setismodelFilterObj(true);
        const data = await FilterIdWiseNewFilterGenerate({ Filter_Obj: filterObj });
        if (data.success == true) {
            setFilterObject(data.data.data);
        } else {
            console.log("something wait wrong");
        }
    };

    const onRefreshBtn = () => {
        ongetReportUserData(ReportType)
        // .then(() =>
        // 	setTimeout(function () {
        // 		$("#table-1").DataTable({ destroy: true, pageLength: 50, order: [[0, "desc"]] });
        // 		setLoader(false);
        // 	}, 1100)
        // );
    };

    const onGetConsumerDetails=async(col,SRNO,text)=>{
		setmodalText(text)
		const data=await getReportDataInConsumerDetails({Column:col,TableName:'lora_reports',SRNO:SRNO})
		if(data.success==true){
			setConsumerDetails(JSON.parse(data?.data?.data||'{}'))
			setConsumerModel(true)
		}
	}
    return (
        <>
        <Modal title={modalText} visible={ConsumerModel} footer="" onCancel={() => {setConsumerModel(false)
			setConsumerDetails([])
			}} width={1000}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th>SRNO</th>
								<th>UNIQUE ID</th>
								<th>DEVICE SERIAL NO</th>
								<th>Address Compony Name</th>
							</tr>
						</thead>
						<tbody>
							{ConsumerDetails.length>0&&ConsumerDetails.map((item,id)=>(<tr>
								<td>{id+1}</td>
								<td>{item?.UNIQUE_ID}</td>
								<td>{item?.DEVICE_SERIAL_NO}</td>
								<td>{item?.address_compony_name}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Modal>
            <Modal title="Info" visible={ismodelFilter} footer="" onCancel={() => setismodelFilter(false)} width={800}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Fields</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
							{InfoType == "allconsumerlist"||InfoType == "alllorawanconsumer" ?(
                                <>
                                    <tr>
                                        <td style={{ width: "50%" }}>Report Type</td>
                                        <td style={{ width: "50%" }}>{info?.Report_Type}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50%" }}>Created BY</td>
                                        <td style={{ width: "50%" }}>{info?.CREATED_BY}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50%" }}>Created At</td>
                                        <td style={{ width: "50%" }}>{moment.utc(info.CREATED_AT).format("DD/MM/YYYY hh:mm:ss A")}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50%" }}>Generated AT</td>
                                        <td style={{ width: "50%" }}>{moment(info.Date_Time).format("DD/MM/YYYY hh:mm:ss A")}</td>
                                    </tr>
                                </>
                            ): (
								<>
									<tr>
										<td style={{ width: "50%" }}>Report Type</td>
										<td style={{ width: "50%" }}>{info?.NAME}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Start Date</td>
										<td style={{ width: "50%" }}>{moment(info.START_DATE).format("DD-MM-YYYY")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>End Date</td>
										<td style={{ width: "50%" }}>{moment(info.END_DATE).format("DD-MM-YYYY")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Created Date Time</td>
										<td style={{ width: "50%" }}>{moment.utc(info.CREATED_AT).format("DD/MM/YYYY hh:mm:ss A")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Created By</td>
										<td style={{ width: "50%" }}>{info?.CREATED_BY}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Generated Date Time</td>
										<td style={{ width: "50%" }}>{moment(info.Date_Time).format("DD/MM/YYYY hh:mm:ss A")}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Total Selected Consumer</td>
										<td style={{ width: "50%" }}>{info?.Total_Consumer_Count}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Found Data Table</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('REPORT_DAILY_EXIST',SRNOReport?.SRNO,'Found Data Table')}>{info?.Total_Daily_Consumer_Record_Exist}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Not Found Data Table</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('REPORT_DAILY_NOT_EXIST',SRNOReport?.SRNO,'Not Found Data Table')}>{info?.Total_Daily_Consumer_Record_Not_Exist}</td>
									</tr> 
									<tr>
										<td style={{ width: "50%" }}>Records Exist</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('REPORT_DATA_FOUND',SRNOReport?.SRNO,'Records Exist')}>{info?.Record_Found_from_Daily_Consumer}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Records Not Exist</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('REPORT_DATA_NOT_FOUND',SRNOReport?.SRNO,'Records Not Exist')}>{info?.Record_Not_Found_from_Daily_Consumer}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Null Value</td>
										<td style={{ width: "50%",cursor:'pointer' }} onClick={()=>onGetConsumerDetails('REPORT_DATA_FOUND_VALUE_NULL',SRNOReport?.SRNO,'Null Value')}>{info?.Record_Found_Value_Null_from_Daily_Consumer}</td>
									</tr>
									
									<tr>
										<td style={{ width: "50%" }}>Total Records Count</td>
										<td style={{ width: "50%" }}>{info?.Total_Records_Count}</td>
									</tr>
								</>
							) }
						</tbody>
					</table>
				</div>
			</Modal>
            <Modal
                title="Filter Object"
                visible={ismodelFilterObj}
                footer=""
                onCancel={() => {
                    setismodelFilterObj(false);
                    setFilterObject({});
                }}
            >
                <div>{JSON.stringify(FilterObject || "{}")}</div>
            </Modal>
            <div className="main-content" style={{ minHeight: "562px" }}>
                {loader ? (
                    <div className="main-content">
                        <div class="loader"></div>
                    </div>
                ) : (
                    ""
                )}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ">
                        <li className="breadcrumb-item">
                            <Link to="/all-dashboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link>
                                <Users style={{ height: "16px" }} /> Consumers
                            </Link>
                        </li>

                        <li className="breadcrumb-item">
                            <Link to='/All-Lorawan'>
                                Lorawan Consumers
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link >
                                Lorawan Report History
                            </Link>
                        </li>

                    </ol>
                </nav>

                <section class="section">
                    <section class="section-body">
                        {/* <div className="row">
					<div className="col-12">
						<div className="card pb-2">
							<div className="col-12" style={{ marginTop: "15px" }}>
								<div className="buttons">
									{buttonArray.map((item, id) => (
										<div
											className={`text-${item === 'Consumer Info' ? 'dark' : 'white'} btn ${item === "All" && "btn-primary"} ${item === "All GSM Consumer Details" && "btn-secondary"
												} ${item === "Configuration" && "btn-danger"} ${item === "Modem Data" && "btn-info"} ${item === "Meter Data" && "btn-success"
												}  ${item === "Buffer" && "btn-dark"}  ${item === "Extra" && "btn-purple "}  dashboard  ${buttonArray.findIndex((data) => data === btnText) === id ? "btn-lg btn-text-lg" : ""
												} mb-1 `}
											style={{ marginBottom: "8px" }}
											onClick={() => onHandalebtn(item,id)}
										>
											{item}
										</div>
									))}
									<div class="float-right text-right">
										
										<Link to="/All-GSM" class="btn btn-info text-white align-left">
											Back
										</Link>
									</div>
								</div>
							</div>
							</div>
							</div>
							</div> */}
                    </section>
                </section>
                <section class="section">
                    <section class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header p-2" style={{ justifyContent: "space-between" }}>
                                        <div class="form-group mb-0 mr-2">
                                            <select
                                                id="inputState"
                                                class="form-select form-control"
                                                name="modem_name"
                                                onChange={(e) => onHandalebtn(e.target.value)}
                                                value={ReportType}
                                                style={{ width: '300px', height: '35px', padding: '0px 15px' }}
                                            >
                                                <option value='All'>All</option>
                                               													{ReportTypes?.map((item)=>(<option value={item?.value}>{item?.label}</option>))}
                                            </select>
                                        </div>
                                        <div className="d-flex 	">
                                            <BootstrapTooltip title='Refresh'><div><a
                                                class="btn btn-info text-white mt-1"
                                                onClick={onRefreshBtn}
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title=""
                                                data-original-title="Refresh"
                                            // style={{height: '32px',display: 'flex',flexDirection: 'column',justifyContent: 'center',borderRadius:'2px'}}
                                            >
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                            </a></div></BootstrapTooltip>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        {reportData.length > 0 ? (
                                            <div className="table-responsive tw-h-screen tw-max-h-screen">
                                            <div id="table-1_wrapper" class="dataTables_wrapper no-footer">

                                                <table id="table_id" className="table border text-center table-borderless table-striped font-10">
                                                    <thead className='tw-sticky tw-z-10 tw-top-0  tw-bg-gray-100'>
                                                            <tr>
                                                                <th className="text-center !tw-font-semibold" style={{ maxWidth: "100px" }}>
                                                                    Unique No
                                                                </th>
                                                                <th className="!tw-font-semibold text-center">Report Name</th>
                                                                <th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
                                                                    START_DATE
                                                                </th>
                                                                <th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
                                                                    END_DATE
                                                                </th>
                                                                <th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
                                                                    Created At
                                                                </th>
                                                                <th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
                                                                    Created By
                                                                </th>
                                                                <th className="!tw-font-semibold text-center" style={{ maxWidth: "100px" }}>
                                                                    Status
                                                                </th>
                                                                <th className="!tw-font-semibold text-center">Download</th>
                                                                <th className="!tw-font-semibold text-center">Info</th>
                                                                <th className="!tw-font-semibold text-center">Filter Object</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {reportData &&
                                                                reportData.map((item, id) => (
                                                                    <tr style={{ height: "4rem" }} className="trheigth hover:tw-bg-[#D8D8D8]" key={id}>
                                                                        <td className="text-center">{item?.SRNO}</td>
                                                                        <td className="text-center">{item?.FILENAME}</td>

                                                                        <td className="text-center">
                                                                            {item?.START_DATE
                                                                                ? moment.utc(item?.START_DATE).format("DD/MM/YYYY")
                                                                                : "N/A"}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {item?.END_DATE
                                                                                ? moment.utc(item?.END_DATE).format("DD/MM/YYYY")
                                                                                : "N/A"}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {item?.CREATED_AT
                                                                                ? moment.utc(item.CREATED_AT).format("DD/MM/YYYY hh:mm:ss A")
                                                                                : "N/A"}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {item?.CREATED_BY}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <div
                                                                                className={`badge ${item?.STATUS == 1
                                                                                    ? "badge-success"
                                                                                    : item?.STATUS == 2 &&moment(moment.utc().add(moment().utcOffset(), "m").format()).diff(moment(moment.utc(item.CREATED_AT).format()),'minutes') > 25
                                                                                        ? "badge-danger"
                                                                                        : item?.STATUS == 2
                                                                                            ? "badge-warning"
                                                                                            : "badge-danger"
                                                                                    } badge-shadow text-white`}
                                                                            >
                                                                                {item?.STATUS == 1
                                                                                    ? "Success"
                                                                                    : item?.STATUS == 2 &&moment(moment.utc().add(moment().utcOffset(), "m").format()).diff(moment(moment.utc(item.CREATED_AT).format()),'minutes') > 25
                                                                                        ? "Failed"
                                                                                        : item?.STATUS == 2
                                                                                            ? "Pending Report"
                                                                                            : "Failed"}
                                                                            </div>
                                                                        </td>

                                                                        <td className="text-center">
                                                                          {item?.STATUS == 1? <a
                                                                                class="btn-sm btn-warning text-white"
                                                                                onClick={() => {
                                                                                    onDownloadFiles(item?.FILENAME);
                                                                                }}
                                                                                download=""
                                                                                target={"_blank"}
                                                                            >
                                                                                <i class="fa fa-download" aria-hidden="true"></i>
                                                                            </a>:''}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <a
                                                                                onClick={() =>
                                                                                    onChangeInfo(
                                                                                        item?.INFO,
                                                                                        item?.CREATED_BY,
                                                                                        item?.CREATED_AT,
                                                                                        item?.TYPE,
                                                                                        item
                                                                                    )
                                                                                }
                                                                                class="btn-sm btn-primary btn-sm font-14 text-center"
                                                                            >
                                                                                <i
                                                                                    class="fa fa-info-circle "
                                                                                    aria-hidden="true"
                                                                                    style={{ color: "white" }}
                                                                                ></i>
                                                                            </a>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <a
                                                                                class="btn-sm btn-info btn-sm font-14 text-center"
                                                                                onClick={() => onFilterIdWiseNewFilterGenerate(item?.REPORT_FILTERS)}
                                                                            >
                                                                                <i
                                                                                    class="
fas fa-filter"
                                                                                    aria-hidden="true"
                                                                                    style={{ color: "white" }}
                                                                                ></i>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                   

                                                </div>
                                            </div>

                                        ) : (
                                            ""
                                        )}
                                         <>
                                                        <div className="d-flex justify-content-between">
                                                            <div>Total Number Of Row {numberofPage}</div>
                                                            <div class="dataTables_length" id="table_id_length">
                                                                <label>
                                                                    Show{" "}
                                                                    <select
                                                                        name="table_id_length"
                                                                        aria-controls="table_id"
                                                                        class=""
                                                                        onChange={(e) => setPageSize(e.target.value)}
                                                                        value={PageSize}
                                                                    >
                                                                        <option value={10}>10</option>
                                                                        <option value={25}>25</option>
                                                                        <option value={50}>50</option>
                                                                        <option value={100}>100</option>
                                                                    </select>{" "}
                                                                    entries
                                                                </label>
                                                            </div>

                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={currentPage}
                                                                totalCount={numberofPage || 1}
                                                                pageSize={PageSize}
                                                                onPageChange={(page) => setCurrentPage(page)}
                                                            />
                                                        </div>
                                                    </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </>
    );
}

export function ReportHistory() {
    const [reportData, setReportData] = useState([]);
    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const [loader, setLoader] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const reportType = location.state;

    const buttonArray = ["All GSM Consumer Details"];
    const ongetReportUserData = async () => {
        setLoader(true);
        const data = await getReportBytypeName(userInfo?.UNIQUE_ID, userInfo?.ROLE, "allgsmconsumerlist");
        if (data.success === true) {
            setReportData(data.data.data);
        } else {
            console.log("something want wrong");
            setLoader(false);
        }
    };

    useEffect(() => {
        ongetReportUserData().then(() =>
            setTimeout(function () {
                $("#table-1").DataTable({ pageLength: 50, order: [[0, "desc"]] });
                setLoader(false);
            }, 1100)
        );
    }, []);

    const onDownloadFiles = async (name) => {
        const myHeaders = new Headers();

        const myRequest = new Request(ReportUrl + name, {
            method: "GET",
            headers: myHeaders,
            mode: "cors",
            cache: "default",
        });
        const data = await fetch(myRequest);
        const blob = await data.blob();

        if (data.status === 200) {
            await fileDownload(blob, name);
        }
    };
    return (
        <>
            <div className="main-content" style={{ minHeight: "562px" }}>
                {loader ? (
                    <div className="main-content">
                        <div class="loader"></div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </>
    );
}
