import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import GoogleLoader from '../../../Commoncomponet/Loaders/GoogleLoader';
import Pagination from '../../../Commoncomponet/Pagination';
import { Get_consumer_data } from '../../../Commoncomponet/Utils';

const ZISU_AMR_READING = () => {

    const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(0)
	const [currentPage, setCurrentPage] = useState(1);
	const history=useHistory()
    const [Loader,setLoader]=useState(false)
const [modemDList,setmodemDList]=useState([])
const params = useParams();

const onGet_consumer_data=async()=>{
    setLoader(true)
    const firstPageIndex = (currentPage - 1) * PageSize;
    const data=await Get_consumer_data({MSID:params?.MSID, start: firstPageIndex, end: PageSize })
    if(data.success==true){
        setmodemDList(data.data.data)
        setnumberofPage(data.data.count)
        setLoader(false)
    }else{
        setLoader(false)
    }
}
useEffect(() => {
    if(modemDList.length>0){
    onGet_consumer_data()
    }
}, [currentPage, PageSize])
    return (
        <section class="section">
				
        <div className="row">
            <div className="col-12 mt-5">
                
            {Loader==true ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                        <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                    </div>:  <div className="table-responsive">
                            <div id="table-1_wrapper" className="dataTables_wrapper no-footer">
                                <div className="dataTables_length ml-3" id="table_id_length">
                                    <label>
                                        Show{" "}
                                        <select name="table_id_length" aria-controls="table_id" className="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>{" "}
                                        entries
                                    </label>
                                </div>
                                <div className="dataTables_length mr-3" id="table_id_length" style={{ float:'right' }}>
                                    <div className='btn btn-info' onClick={onGet_consumer_data}><i className='fa fa-refresh'></i></div>
                                </div>
                                <table id="table_id" className="tableCustom table table-striped">
                                    <thead>
                                        <tr>
                                                <th class="GSM_Link">LOCATION</th>
                                                <th>
                                                SOFTWARE_NAME
                                                </th>
                                                <th>AMR_PRIM_KEY</th>
                                                <th>CUSTOMER_NAME</th>
                                                <th>GERNR</th>
                                                <th>GERNR1</th>
                                                <th>V_ZWSTAND1</th>
                                                <th>V_ZWSTAND2</th>
                                                <th>V_ZWSTAND3</th>
                                                <th>V_ZWSTAND4</th>
                                                <th>V_ZWSTAND5</th>
                                                <th>QBMIN</th>
                                                <th>QBMAX</th>
                                                <th>QCMIN</th>
                                                <th>QCMAX</th>
                                                <th>ADAT</th>
                                                <th>ATIM</th>
                                                <th>ADATSOLL</th>
                                                <th>ATIMSOLL</th>
                                                <th>FLAG</th>
                                                <th>CRDAT</th>
                                                <th>CRTIM</th>
                                                <th>SAP_UPDAT</th>
                                                <th>SAP_UPTIM</th>
                                                <th>SAP_FLAG</th>
                                                <th>LINKED_SERVER_NAME</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {modemDList.length>0&&modemDList.map((item)=>(
                                        <tr>
                                                <td class="GSM_Link">{item?.LOCATION}</td>
                                                <td>
                                                {item?.SOFTWARE_NAME}
                                                </td>
                                                <td>{item?.AMR_PRIM_KEY}</td>
                                                <td>{item?.CUSTOMER_NAME}</td>
                                                <td>{item?.GERNR}</td>
                                                <td>{item?.GERNR1}</td>
                                                <td>{item?.V_ZWSTAND1}</td>
                                                <td>{item?.V_ZWSTAND2}</td>
                                                <td>{item?.V_ZWSTAND3}</td>
                                                <td>{item?.V_ZWSTAND4}</td>
                                                <td>{item?.V_ZWSTAND5}</td>
                                                <td>{item?.QBMIN}</td>
                                                <td>{item?.QBMAX}</td>
                                                <td>{item?.QCMIN}</td>
                                                <td>{item?.QCMAX}</td>
                                                <td>{item?.ADAT}</td>
                                                <td>{item?.ATIM}</td>
                                                <td>{item?.ADATSOLL}</td>
                                                <td>{item?.ATIMSOLL}</td>
                                                <td>{item?.FLAG}</td>
                                                <td>{item?.CRDAT}</td>
                                                <td>{item?.CRTIM}</td>
                                                <td>{item?.SAP_UPDAT}</td>
                                                <td>{item?.SAP_UPTIM}</td>
                                                <td>{item?.SAP_FLAG}</td>
                                                <td>{item?.LINKED_SERVER_NAME}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        <><div className='d-flex justify-content-between'>
								<div>Total Number Of Row {numberofPage||0}</div><Pagination
									className="pagination-bar"
									currentPage={currentPage}
									totalCount={numberofPage || 0}
									pageSize={PageSize}
									onPageChange={(page) => setCurrentPage(page)}
								/></div></>
                    </div>
                
        </div>
    </section>
    )
}

export default ZISU_AMR_READING