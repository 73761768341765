import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import './Css/tabs.css'

export default function Customtabs({ activeTab, children, className, ulClassName, activityClassName, onClick }) {
    const [activeItem, setActiveItem] = useState(activeTab ? Number(activeTab) : 1);
    const tabref1 = useRef('');
    const tabref2 = useRef('');
    const tabref3 = useRef('');
    const tabRefs = [tabref1, tabref2, tabref3];
    const activeBorderRef = useRef(null);

    const handleClickOnTab = useCallback((event, index) => {
        setActiveItem(index + 1);
        if (typeof onClick !== "undefined") {
            onClick(event, index + 1);
        }
    }, [onClick]);

    const handleTabItems = (children) => {
        return (
            children.map((tab, key) =>
                <li ref={tabRefs[key]} key={key} onClick={(e) => handleClickOnTab(e, key)} className={'my-tabs-item ' + (tab.props.className ? tab.props.className : '') + ((activeItem === key + 1) ? ' active' : '')}>{tab.props.title}</li>
            )
        )
    };

    useEffect(() => {
        const activeTabRef = tabRefs[activeItem - 1].current;
        if (activeTabRef && activeBorderRef.current) {
            activeBorderRef.current.style.width = `${activeTabRef.clientWidth}px`;
            activeBorderRef.current.style.left = `${activeTabRef.offsetLeft}px`;
        }
    }, [activeItem, tabRefs]);

    return (
        <div className={'my-tabs ' + (className ? className : '')}>
            <div className="my-tabs-header">
                <ul className={'my-tabs-items ' + (ulClassName ? ulClassName : '')}>
                    {handleTabItems(children)}
                </ul>

                <div ref={activeBorderRef} className={'my-tabs-active-item ' + (activityClassName ? activityClassName : '')} />
            </div>

            <div className="my-tabs-content">
                {
                    children.map((item, key) =>
                        <div className={"my-tabs-content-item " + ((activeItem === key + 1) ? 'show' : '')} key={key}>{item.props.children}</div>
                    )
                }
            </div>
        </div>
    )
}

export function Tab({ children, title, className }) {
    return (
        <li>{title}</li>
    )
}
