import { data } from "jquery";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {Spin} from 'antd'
import { getCommentAndHistoryById, UpdateComentAndHistory } from "../../../../Commoncomponet/Utils";
import '../../Deshboard.css'

export default function Comment(props) {
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    const [showComment,setShowComment]=useState(false)
    const [coment,setcoment]=useState({COMMENT_BY:userInfo?.UNIQUE_ID,COMMENT:'',COMMENT_AT:''})
    const [commentData,setCommentData]=useState([])
    const [loader,setLoader]=useState(false)

    const ongetCommentAndHistoryById=async()=>{
        setLoader(true)
        const data =await getCommentAndHistoryById({tableName:'gsm_consumer_device_index',UNIQUE_ID:props?.GSMdata?.UNIQUE_ID})
        if(data.success===true){
            setCommentData(data.data.data)
        setLoader(false)
        }else{
            console.log("something want wrong");
        setLoader(false)
        }
    }

    const onHandalSubmit=async()=>{
        if(coment.COMMENT!==''){
        const data=await UpdateComentAndHistory({comment:{...coment,COMMENT_AT:moment().format(),COMMENT:coment.COMMENT},tableName:'gsm_consumer_device_index'},props?.GSMdata?.UNIQUE_ID)
        if(data.success===true){
            setcoment({...coment,COMMENT:'',COMMENT_AT:''})
            setShowComment(false)
    ongetCommentAndHistoryById()

        }else{
            console.log('something want wrong')
        }
    }
}

useEffect(() => {
    ongetCommentAndHistoryById()
}, []);
	return (
		<>
			<section className="card-diff-section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
                            <div className="card-body" style={{overflow:'scroll'}}>
                           {loader==true&& <div className="example">
                                            <Spin size="large" />
                                        </div>}
								<div class="containers" style={{paddingBottom:'0px',paddingTop:'0px',width:'1000px'}}>
                                {!loader&&commentData?.length>0&&commentData.map((item)=>(
                                    <div class="timeline-block timeline-block-right">
                                    <div class="mt-3 mr-5" style={{width:"171px"}}>
											<span className="mt-2">{moment(item?.COMMENT_AT).format('DD-MM-YYYY hh:mm A')}</span>
										</div>
										<div class="marker active">
                                        <i class="fas fa-clipboard-list active"></i>
										</div>
										<div class="timeline-content">
											<span className="d-flex"><p style={{marginTop:'14px',fontSize:'17px',fontWeight:500}}>{item?.COMMENT} </p>&nbsp; <p className="" style={{marginTop:'16px',color:'#787878',fontWeight:500}}> by {item?.COMMENT_BY}</p></span>
										</div>
									</div>
                                ))}
								</div>
                                {!loader&&commentData?.length==0&&<h4 className="text-center">Comment & History Not Found</h4>}

							</div>
                           {showComment? <div class="col-lg-6 remarkgrp" style={{marginLeft:"10px"}}>
                    <div class="form-group">
                        <label>Comment:</label>
                        <textarea class="form-control" name="cust_comments" id="remarks" onChange={(e)=>{setcoment({...coment,COMMENT:e.target.value.replace(/[\/\\"`\t\n]/g, '')})}} value={coment?.COMMENT}></textarea>
                    </div>
                    <div class="text-left pb-3">
                        <button class="btn btn-info" id="submitcomment" onClick={onHandalSubmit}>Add Comment</button>
                    </div>
            </div>:''}
                            {!showComment&&!loader&&<div class="col-md-12 p-4">
															<Link
																className="hover-underline"
                                                                onClick={()=>(setShowComment(true))}
															>
																+Add Comments
															</Link>
														</div>}
						</div>
					</div>
				</div>
                </div>
                
			</section>
		</>
	);
}
