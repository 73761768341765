import React, { useEffect, useState } from 'react'
import DataTable from '../../Commoncomponet/Datatable/Datatable';
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader';
import { getAllTableInfo, getDataInLorawanGatewayList } from '../../Commoncomponet/Utils';

const GatewayList = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        setIsLoading(true)
        const getDetails = await getDataInLorawanGatewayList()
        if (getDetails.success === true) {
            setTableData(getDetails.data.data)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            console.log("Error while getting all table info = ", getDetails.err)
        }
    }

    const columns = tableData.length > 0 ? Object.keys(tableData[0]).map(key => ({
        field: key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
        isVisible: key === "TABLE_CATALOG" ? false : true,
        type: key === "GATEWAY_UPDATED_AT" || key === "GATEWAY_CREATED_AT"||key=='LASTSEEN_AT'||key==='CREATED_AT' ? 'datetime' : 'text',
        cellRenderer: key === "total_size" ? (row) => `${row.toFixed(2)} mb` : null,
    })) : [];

    const onRowClick = (row) => {
        alert(JSON.stringify(row, null, 2))
    }


    return (
        <>
            <div className="main-content">
                <section className="section">
                    <div className="card !tw-rounded-none">
                        <div className='card-header'>
                            <h4>Gateway List</h4>
                        </div>

                        <div className='card-body !tw-p-0'>
                            {isLoading ? <> <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                                <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                            </div></> : <DataTable
                                data={tableData}
                                columns={columns}
                                initialSortField="total_size"
                                initialSortDirection="desc"
                                pageSize={50}
                                filterable
                                // onRowClick={(row) => {
                                //     console.log("row = ", row)
                                //     onRowClick(row)
                                // }}

                            />}
                        </div>
                    </div></section></div>
        </>
    )

}

export default GatewayList