import { DatePicker, Modal, Spin } from 'antd';
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import swal from 'sweetalert2';
import { BootstrapTooltip, exportCSVFile, exportExcelDownload } from '../../../../Commoncomponet/common';
import Pagination from '../../../../Commoncomponet/Pagination';
import chroma from 'chroma-js';

import { geScriptSRNOWiseColumnName, getConsumerOldHistoryTable, getEUIWiseModemColumnName, getGSMRejectedData, getHeardbeatData, getIMEIWiseModemColumnName, getLoraConsumerOldHistoryTable, getScriptCodeIdWiseColumnName, getStartAndEndDateByTable, reportCreacteInTableName } from '../../../../Commoncomponet/Utils';
import { toast } from 'react-toastify';
window.Swal = swal;
export default function Oldtablehistory(props) {

  const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
    const [BufferData,setBufferData]=useState({})
	const [loader, setLoader] = useState(true)
    const [ColumnName,setColumnName]=useState({})
	const [info,setinfo]=useState({tabelName:'',TableType:'',Tablediv:''})
	const [heartData, setHeartData] = useState([])
	const [modalDetails,setmodalDetails]=useState(false)
	const [report, setReport] = useState({ value: '', name: '', start_date: '', end_date: '' })
	const [reportType, setReportType] = useState('')
	const [ismodel, setModel] = useState(false)
	const [ReportLoader,setReportLoader]=useState(false)
	const ReportType=[{label:'View Table Info',value:'View_Table_Info'},{label:'All Table Info',value:'All_Table_Info'},{label:'Full Table',value:'Full_Table'}]
	const [ExpireDate,setExpireDate]=useState({})
	const buttonArray = ["Modem Table","Meter Table"];
	// ,"Voltevent","currentevent","powerevent","transectionevent","otherevent","nonrolloverevent",'controlevent'
	const [btnText, setButtontext] = useState("");
    const onHandalebtn = (text) => {
		setButtontext(text);	
	};

const ongetGSMBufferData=async()=>{
    setLoader(true)
    const data=await getLoraConsumerOldHistoryTable(props?.GSMdata?.UNIQUE_ID)
    if(data.success===true){
        setBufferData(JSON.parse(data.data.data[0].OLD_TABLE_HISTORY||'{}'))
        setLoader(false)
    }else{
console.log('something want wrong')
        setLoader(false)
    }
}

const tableNameWiseColumnLoad=async(IMEI,name)=>{
	const firstPageIndex = (currentPage - 1) * PageSize;
		const data=await getEUIWiseModemColumnName(IMEI)
		if(data.success==true){
			setColumnName(JSON.parse(data?.data?.data||'{}'))
			ongetTableNameWiseData(name)
		}else{
			console.log('something want wrong')
		}
}

const ScriptSRNOWiseColumnLoad=async(SRNO,name)=>{
	const firstPageIndex = (currentPage - 1) * PageSize;
		const data=await geScriptSRNOWiseColumnName(SRNO)
		if(data.success==true){
			const data1=await getScriptCodeIdWiseColumnName(data?.data?.data?.split('_')[1])
			if(data1.success===true){
				if(data1?.data?.data?.length>0){
				ongetTableNameWiseData(name)
            setColumnName(JSON.parse(data1?.data?.data[0]?.COLUMN_NAME||'{}'))
            }
        }else{
console.log('something want wrong');
        }
		}else{
			console.log('something want wrong')
		}
}

const ongetTableNameWiseData = async (name) => {

		setLoader(true)
	const firstPageIndex = (currentPage - 1) * PageSize;
	const data = await getHeardbeatData({ start: firstPageIndex, end: PageSize }, { tableName: name,colName:'SRNO' })
	if (data.success === true) {
		setHeartData(data.data.data)
		setnumberofPage(data.data.total)
		setLoader(false)
	} else {
		console.log('something want wrong')
		setLoader(false)
	}
}

useEffect(() => {
    ongetGSMBufferData()
}, []);

const onClickLoadData=(name,type,div)=>{
	tableNameWiseColumnLoad(name.split('_')[2],name)
	setinfo({tabelName:name,TableType:type,Tablediv:div})
	setmodalDetails(true)
}

const onClickLoadDataMeter=(name,type,div)=>{
	console.log(name.split('_')[3]);
	ScriptSRNOWiseColumnLoad(name.split('_')[3].split('S')[1],name)
	setinfo({tabelName:name,TableType:type,Tablediv:div})
	setmodalDetails(true)
}

useEffect(() => {
	ongetTableNameWiseData(info?.tabelName)
}, [currentPage, PageSize])

const ongetStartAndEndDateByTable = async (tableName, col) => {
	console.log("Wanted to check if this is calling or not")
	document.body.style.cursor = 'want'
	const data = await getStartAndEndDateByTable({ tableName: info?.tabelName, col: 'ENTRYTS' })
	if (data.success === true) {

		console.log("Expiry Dates == ", data.data)
		setExpireDate(data.data)
		setModel(true)
		document.body.style.cursor = 'default'
	} else {
		console.log("something want wrong")
		document.body.style.cursor = 'default'
return	toast.warn('Data Not Found')

	}
}
  const onHandalReport = (e) => {
	setReport({ ...report, value: e.target.value, name: 'All GSM Consumer Details' })
  }

  const ongetGenretedAllGSMReport=async(Iscsv)=>{
	if(reportType!=''&&report.value!==''){
		if(report?.value!='Full_Table'){
			if(report?.start_date&&report?.end_date){
				const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
	const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
	console.log(x>y,x,y);
				if(x>y){
				return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
				}
			}else{
				return	toast.warn('Please Select "Start Date" And "End Date"')
			}
		}
	setReportLoader(true)
	const data=await reportCreacteInTableName({tableName:info?.tabelName,col:'ENTRYTS', start_date: moment(report.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"), end_date: moment(report.end_date, "DD-MM-YYYY").format("YYYY-MM-DD"),IsCondition:report?.value=='Full_Table'?false:true})
	if(data.success==true){
  var arrayofData=[]
  var headers={}
   
  if(data.data.data.length==0){
setReportLoader(false)
toast.warn('Data Not Found')
  }
  var TypeReport=report?.value
	data.data.data.map((item,id)=>{
	  arrayofData[id]={}
	  ColumnName[info?.Tablediv][info?.TableType]?.length>0&&ColumnName[info?.Tablediv][info?.TableType]?.map((col)=>{
		if(TypeReport=='View_Table_Info'){
		if(col?.Display=="Yes"){
		  if(id==0){headers[col.Header]=col.Header}
		  if(col?.Type=='datetime')
		  {
			arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
		  }else{
			if(col?.Float){
			  arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
			}else{
			arrayofData[id][col.Header]=item[col.Column]
			}
		  }
		}
	  }else if(report?.value=='All_Table_Info'||report?.value=='Full_Table'){
		if(id==0){headers[col.Header]=col.Header}
		if(col?.Type=='datetime')
		  {
			arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
		  }else{
			if(col?.Float){
			  arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
			}else{
			arrayofData[id][col.Header]=item[col.Column]
			}
		  }
	  }
		
	  })
	  if(id==(data.data.data.length-1)){
		if(reportType==='csv'){
			if(report?.value!='Full_Table'){
		  exportCSVFile(headers,arrayofData,`${info?.TableType}_`+'('+report.start_date+')'+'_'+'('+report.end_date+')'+`_${new Date().getTime()}`)
			}else{
				exportCSVFile(headers,arrayofData,`${info?.TableType}_${new Date().getTime()}`)
			}
setReportLoader(false)

		 }else{
			if(report?.value!='Full_Table'){
		  exportExcelDownload(arrayofData,`${info?.TableType}_`+'('+report.start_date+')'+'_'+'('+report.end_date+')'+`_${new Date().getTime()}`)
			}else{
				exportExcelDownload(arrayofData,`${info?.TableType}_${new Date().getTime()}`)
			}
setReportLoader(false)

		 }
		}
  })
  
		
	}else{
		console.log('something want wrong')
		setReportLoader(false)
	}
}else{
return	toast.warn('Please Enter Mandatory Fields')
}
}
  return (
    <>
	<Modal title="Generate Reports" visible={ismodel} footer='' onCancel={()=>setModel(false)} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={onHandalReport}>
												<option value="">Report Type</option>
												{ReportType?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div>
										{report?.value!=''&&report?.value!='Full_Table'?<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
												}} />
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
													}} />
											</div></>:''}
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLS</option>
												<option value="csv">CSV</option>
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader==true?'disabled btn-progress':''}`} value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
										{/* <Link className="btn btn-light text-dark" onClick={()=>setModel(false)}>Cancel</Link> */}
									</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
	<Modal title={<>{info?.tabelName}<div class="ml-2 d-inline"><button class="btn btn-info" onClick={()=>ongetStartAndEndDateByTable()}><i class="fa fa-download" aria-hidden="true"></i> </button></div></>}  visible={modalDetails} className="my-modal-class" footer='' maskClosable={false} onCancel={() => {setmodalDetails(false)
	setHeartData([])
	setColumnName({})}} width={1500}>
		{loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)}
           {!loader&&<><div className="table-responsive ">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" class="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
												
												<table id="table_id" className="tableCustom table table-striped">
													<thead>
														<tr>
                                                        { Object.keys(ColumnName).length>0&&ColumnName[info?.Tablediv][info?.TableType]?.length>0&&ColumnName[info?.Tablediv][info?.TableType]?.map((col)=>(<BootstrapTooltip title={col?.Column}><th>{col?.Header}</th></BootstrapTooltip>))}
                                                        
														</tr>
													</thead>
													<tbody>
													{heartData.length > 0 && heartData.map((item, id) => (
															<tr className="trheigth  ">
															{ Object.keys(ColumnName).length>0&&ColumnName[info?.Tablediv][info?.TableType]?.length>0&&ColumnName[info?.Tablediv][info?.TableType]
															?.map((col)=>(col?.Type=='datetime'?
															<td style={{ minWidth: "100px" }}>{item[col.Column]?
															item[col.Column]!='0000-00-00 00:00:00'?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):item[col.Column]
															:''}</td>:<td >{item[col.Column]}</td>
															))}
																{/* <td>{moment.utc(item.ENTRYTS).format("DD/MM/YYYY hh:mm:ss A")}</td> */}
																{/* <td className="font-13"><a class="action-icon text-success" onClick={() => onAllShowData(item)}><span data-toggle="tooltip" data-placement="bottom" title="AllData Data"><i class="fa fa-database fa-sm" aria-hidden="true"></i></span></a>&nbsp;</td> */}
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
										<><div className='d-flex justify-content-between'>
											<div>Total Number Of Row {numberofPage}</div><Pagination
												className="pagination-bar"
												currentPage={currentPage}
												totalCount={numberofPage || 1}
												pageSize={PageSize}
												onPageChange={(page) => setCurrentPage(page)}
											/></div></></> }
            </Modal>
	<div className="d-flex justify-content-between" style={{ paddingTop: '0px', paddingLeft: '15px', paddingBottom: '5px' }}>
					<div className="buttons">
						{buttonArray.map((item, id) => (
							<button
							style={{
								backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#7c5295').alpha(0.4).css() : '',
								color: buttonArray.findIndex((data) => data === btnText) === id ? chroma('#7c5295').darken(1.5).css() : '#7c5295',
								borderBottom: `1px solid ${'#7c5295'}`,
								boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${'#7c5295'}`  : '',
							}}
							className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
							onClick={() => onHandalebtn(item)}>
							{item}
						</button>
						))}
					</div>
                    </div>
   {btnText? <section className="card-diff-section mt-4">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header ">
										<h4  ></h4>
										<div className="card-header-action">
                                        
										</div>
									</div>
									<div className="card-body">
					{btnText==='Modem Table'&&	<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
													
												</div>
												<table id="table_id" className="table border text-center  table-striped font-10">
													<thead>
														<tr >
															<th>Table Type</th>
															<th>Table Name</th>
															<th>Show Table</th>
														</tr>
													</thead>
													<tbody>
														{Object.keys(BufferData).length > 0 && Object.entries(BufferData).map(([key, value],id) => (
															value[0]&&value[0].split('_').length==5?<><tr className="trheigth" >
																
																<td rowSpan={`${value.length}`}>{key}</td>
																{[...value]?.map((Tname,id)=>(id==0?<><td>{Tname}</td><td><div className='btn btn-primary' onClick={()=>(onClickLoadData(Tname,key,'Modem_Data'))}>Show Table Data</div></td></>:''))}
															</tr>
                                                            {value.length>1? [...value]?.map((Tname,id)=>(id!=0?<><tr className="trheigth" >
                                                            <td>{Tname}</td><td><div className='btn btn-primary' onClick={()=>(onClickLoadData(Tname,key,'Modem_Data'))}>Show Table Data</div></td>
                                                        </tr></>:''))
														:''}</>:''
														))}
													</tbody>
												</table>
											</div>
										</div>}
										{btnText==='Meter Table'&&	<div className="table-responsive">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												<div class="dataTables_length" id="table_id_length">
												</div>
												<table id="table_id" className="table border text-center  table-striped font-10">
													<thead>
														<tr >
															<th>Table Type</th>
															<th>Table Name</th>
															<th>Show Table</th>
														</tr>
													</thead>
													<tbody>
														{Object.keys(BufferData).length > 0 && Object.entries(BufferData).map(([key, value],id) => (
															value[0]&&value[0].split('_').length==6?<><tr className="trheigth" >
																
																<td rowSpan={`${value.length}`}>{key}</td>
																{[...value]?.map((Tname,id)=>(id==0?<><td>{Tname}</td><td><div className='btn btn-primary' onClick={()=>(onClickLoadDataMeter(Tname,key,'Meter_Data'))}>Show Table Data</div></td></>:''))}
															</tr>
                                                            {value.length>1? [...value]?.map((Tname,id)=>(id!=0?<><tr className="trheigth" >
                                                            <td>{Tname}</td><td><div className='btn btn-primary' onClick={()=>(onClickLoadDataMeter(Tname,key,'Meter_Data'))}>Show Table Data</div></td>
                                                        </tr></>:''))
														:''}</>:''
														))}
													</tbody>
												</table>
											</div>
										</div>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>:''}
    </>
  )
}
