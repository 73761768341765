import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space, Typography } from 'antd'
import React from 'react'

export default function DownloadGraphImageComponet({ onClickDownloadImg, newMenu }) {

  const onclickDropdown = (e) => {
    if (e === '1') {
      onClickDownloadImg('png')
    } else if (e === '2') {
      onClickDownloadImg('jpg')

    }
  }
  const menu = (
    <Menu>
      <Menu.Item>
        <a onClick={() => onclickDropdown('1')}>
          Download in PNG
        </a>
      </Menu.Item>
      <Menu.Item >
        <a onClick={() => onclickDropdown('2')}>
          Download in JPG
        </a>
      </Menu.Item>
      {newMenu?.map((item) => (
        <Menu.Item >
          <a onClick={() => item.event()}>
            {item?.label}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <div >
        <Dropdown overlay={menu} trigger={['click']}>
          <button className='btn btn-info !tw-border-none'>
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
        </Dropdown>
      </div>
    </>
  )
}
