import { DatePicker, Modal, Spin, Switch } from 'antd'
import React, { useRef } from 'react'
import { getConfigretionCMD_TYPEWisePendding_Count_buffer, getConfigretionCMD_TYPEWisePendding_Data_buffer, getHeardbeatData, getLetestHeatbeatData, getScriptCodeIdWiseColumnName, getScriptCodeIdWiseConfigretionData, getStartAndEndDateByTable, onModemConfigByGSMConsumer, reportCreacteInTableName,Insertgsm_offline_otp,getgsm_offline_otp,UpdateunlockConfig,getgsm_unlock_activity } from '../../../../Commoncomponet/Utils'
import swal from 'sweetalert2';
import { useState } from 'react';
import { useEffect } from 'react';
import useInterval from 'use-interval';
import moment from 'moment';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import {BootstrapTooltip, exportCSVFile, exportExcelDownload, socket} from '../../../../Commoncomponet/common'
import clockPending from '../../../../assets/img/icons8-clock.gif'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Pagination from '../../../../Commoncomponet/Pagination';
window.Swal = swal;
export default function Unloackreq(props) {
  const [FlagMsg,setFlagMsg]=useState(false)
  const [modelTitle,setmodelTitle]=useState('')
  const [modal,setModal]=useState(false)
    const [MqttMsg,setMqttMsg]=useState('')
    const [time, setTime] = useState(60);
	const [run, setRun] = useState(false);
  const [modalMsg,setmodalMsg]=useState(false)
  const [PageSize, setPageSize] = useState(50);
	const [numberofPage, setnumberofPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [heartData, setHeartData] = useState([]);
	const [UnloackData, setUnloackData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [AllData, setAllData] = useState({});
	const [rowData, setRowData] = useState({});
	const [ErrorData, setErrorData] = useState({});
	const [DupData, setDupData] = useState({});
	const [isModalError, setIsModalError] = useState(false);
	const [isModalDup, setIsModalDup] = useState(false);
    const [ColumnName,setColumnName]=useState({})
	const [isModalrow, setIsModalRow] = useState(false);

	const [pendingBuffer,setpendingBuffer]=useState('')
	var [CommandJson,setCommandJson]=useState({})
    var [CommandmsgJson,setCommandmsgJson]=useState({})
    const [CommandStatus,setCommandStatus]=useState('')
    const [sortField,setsortField]=useState('SRNO')
	const [sortDirection,setsortDirection]=useState('desc')
	const mytime = useRef(null)
  const userInfo = JSON.parse(window.localStorage.getItem('user_info'))

	const ongetConfigretionCMD_TYPEWisePendding_Count_buffer=async()=>{
		const data=await getConfigretionCMD_TYPEWisePendding_Count_buffer({CONSUMER_ID:props.GSMdata.UNIQUE_ID,CMD_TYPE:'NAME_PLATE',tableName:props.GSMdata.UNIQUE_TABLE_NAME.GSM_BUFFER})
		if(data.success===true){
			setpendingBuffer(data.data.data)
		}else{
			console.log('something want wrong')
		}
	}
	useEffect(()=>{
		socket.on('mqttbufferresponse',(massges)=>{
			console.log(massges);
			setCommandStatus(massges)
			
		})
		socket.on('mqttcommandresponse',(massges)=>{
			console.log(massges,CommandJson,massges.MSGID==CommandJson.MSGID,massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID,massges.UNIQUE_ID==CommandJson.UNIQUE_ID,massges.CMD_TYPE==CommandJson.CMD_TYPE,massges.DRXTYPE,!massges.STATUS);
			setCommandmsgJson(massges)
		})
	},[])
	const BfferError=(msg)=>{
        console.log(msg.MSGID,CommandJson.MSGID);
        if(msg.MMD_ID_MSG==CommandJson.MMD_ID_MSG){
        swal.fire('', msg?.SCR_STATUS_CMD_ADD, 'error');
        setCommandStatus('')
        setFlagMsg(true)
        setRun(false);
        setTime(0)
        setModal(false)
    }
    }
    useEffect(()=>{
        if(CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'&&Object.keys(CommandStatus).length>0){
            BfferError(CommandStatus)
			clearTimeout(mytime.current);
				mytime.current = null
        }else if(CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'){
			setTime(60)
		setRun(true);
		clearTimeout(mytime.current);
				mytime.current = null
		}
    },[CommandStatus])
	const getPopdata=(massges)=>{
		console.log(massges,CommandJson);
	if(massges.MMD_ID_MSG==CommandJson.MMD_ID_MSG&&massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID&&massges.UNIQUE_ID==CommandJson.UNIQUE_ID&&massges.SCR_ID_CMD_TYPE==CommandJson.SCR_ID_CMD_TYPE&&massges.MMD_ID_DRXTYPE&&!massges.STATUS){
			setTime(0)
			setRun(false);
		   setModal(false)
		   if(FlagMsg===false){
		   setmodalMsg(true)
		   setMqttMsg(JSON.stringify(massges))
			}
			
		}
	}
	
	useEffect(()=>{
			if(Object.keys(CommandmsgJson).length>0){
			getPopdata(CommandmsgJson)
			}
	},[CommandmsgJson])
  const ongetScriptCodeIdWiseConfigretionData=async()=>{
    const data=await getScriptCodeIdWiseConfigretionData(props?.GSMdata?.SCRIPTDECODE_ID,{tableName:props.GSMdata.UNIQUE_TABLE_NAME.GSM_BUFFER,type:'NAME_PLATE'})
    if(data.success===true){
        const CMD=JSON.parse(data?.data?.data[0]?.METER_QUERY_CMD||'{}')
        const obj={MMD_ID_CMD:124,SCR_ID_CMD_TYPE:'NAME_PLATE',"MMD_ID_OBIS_CODE": CMD?.NAME_PLATE?.OBIS,"MMD_ID_OBIS_OBJTYPE": CMD?.NAME_PLATE?.OBJ_TYPE,"MMD_ID_OBIS_READ_METHOD": CMD?.NAME_PLATE?.READ_METHOD,UNIQUE_ID:props?.GSMdata?.UNIQUE_ID,MMD_ID_IMEI:props?.GSMdata?.IMEI,SCRIPTDECODE_ID:props?.GSMdata?.SCRIPTDECODE_ID,MMD_ID_MSG:new Date().getTime()+1,GSM_BUFFER_TABLE:props.GSMdata.UNIQUE_TABLE_NAME.GSM_BUFFER}
          setFlagMsg(false)
          setmodelTitle(obj.SCR_ID_CMD_TYPE)
          alert(JSON.stringify(obj))
		  setCommandJson(obj)

          swal.fire({
              title: "Are you sure?",
              text: "This will initiate downlink",
              icon: "warning",
              showCancelButton: true,
          }).then(async (willDelete) => {
              if (willDelete.isConfirmed) {
          setModal(true)
          
      
          const data=await onModemConfigByGSMConsumer({config_info:JSON.stringify(obj)})
          if(data.success===true){
			mytime.current=setTimeout(() => {
				if(Object.keys(CommandStatus).length===0)
									 {
										 setCommandStatus({SCR_STATUS_CMD_ADD:'Time Out'})
									 }
									 },5000)
          }else{
             
          }
      }
      })
    }
}
useInterval(
  () => {
      if (time <= 1) {
          setRun(false);
          setModal(false)
          swal.fire('', 'Command Add In Buffer', 'success');
      }
      setTime((preState) => preState - 1);
  },
  time && run ? 1000 : null
);

function zeroPad(num) {
  var str = String(num);
  if (str.length < 2) {
      return "0" + str;
  }

  return str;
}

const CloseModel=()=>{
  setFlagMsg(true)
  setRun(false);
  setTime(0)
  setModal(false)
  setCommandmsgJson({})
    setCommandStatus('')

}
const ongetScriptCodeIdWiseColumnName=async()=>{
  const data=await getScriptCodeIdWiseColumnName(props?.GSMdata?.METER_TYPE)
  if(data.success===true){
      if(data?.data?.data?.length>0){
      setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
      }
  }else{
console.log('something want wrong');
  }
}
const [StatusCode,setStatusCode]=useState('')
const ongetHeardbeatData = async () => {
  
    setLoader(true);
  
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const data = await getHeardbeatData({ start: firstPageIndex, end: PageSize }, { tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.unlockreq,colName:sortField,sortDirection:sortDirection  });
  if (data.success === true) {
    setHeartData(data.data.data);
    setnumberofPage(data.data.total);
    setLoader(false);
  } else {
    console.log("something want wrong");
    setLoader(false);
    setStatusCode(data?.err?.response?.status)
  }
};
const handleCancel = () => {
  setIsModalVisible(false);
};

const ongetgsm_offline_otp = async () => {
  
const data = await getgsm_offline_otp(props?.GSMdata?.UNIQUE_ID);
if (data.success === true) {
  setUnloackData(data.data.data)
} else {
  console.log("something want wrong");
}
};
useEffect(() => {
  ongetHeardbeatData();
}, [currentPage, PageSize]);

useEffect(async() => {
 await props?.onConsumerUpdateDate()

  ongetScriptCodeIdWiseColumnName()
ongetHeardbeatData();
ongetConfigretionCMD_TYPEWisePendding_Count_buffer()
setUnlockdata({"UNLOCK_STATUS":JSON.parse(props?.GSMdata?.UNLOCK_CONFIG||'{}')?.UNLOCK_STATUS,"UNLOCK_TIME":JSON.parse(props?.GSMdata?.UNLOCK_CONFIG||'{}')?.UNLOCK_TIME})
}, []);

const onAllShowData = (item) => {
  setIsModalVisible(true);
  setAllData(item);
};
const onRowDate = (row) => {
  setRowData(row);
  setIsModalRow(true);
};
const onDuplicate = (dupli) => {
  setDupData(dupli);
  setIsModalDup(true);
};
const onError = (err) => {
  setErrorData(err);
  setIsModalError(true);
};
const csvLink = useRef();
    const [CsvData,setCsvData]=useState([])
    const [report, setReport] = useState({ value: '', name: '', start_date: '', end_date: '' })
    const [reportType, setReportType] = useState('')
    const [ismodel, setModel] = useState(false)
    const [ReportLoader,setReportLoader]=useState(false)
    const ReportType=[{label:'View Table Info',value:'View_Table_Info'},{label:'All Table Info',value:'All_Table_Info'},{label:'Full Table',value:'Full_Table'}]
    const [ExpireDate,setExpireDate]=useState({})
	const ongetStartAndEndDateByTable = async (tableName, col) => {
		console.log("Wanted to check if this is calling or not")
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.unlockreq, col: 'ENTRYTS' })
		if (data.success === true) {

			console.log("Expiry Dates == ", data.data)
			setExpireDate(data.data)
			setModel(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}
   
    const onHandalReport = (e) => {
      setReport({ ...report, value: e.target.value, name: 'All GSM Consumer Details' })
    }
      const ongetGenretedAllGSMReport=async(Iscsv)=>{
        if(reportType!=''&&report.value!==''){
          if(report?.value!='Full_Table'){
            if(report?.start_date&&report?.end_date){
              const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
        const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
        console.log(x>y,x,y);
              if(x>y){
              return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
              }
            }else{
              return	toast.warn('Please Select "Start Date" And "End Date"')
            }
          }
          const data=await reportCreacteInTableName({tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.unlockreq,col:'ENTRYTS', start_date: moment(report.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"), end_date: moment(report.end_date, "DD-MM-YYYY").format("YYYY-MM-DD"),IsCondition:report?.value=='Full_Table'?false:true})
          if(data.success==true){
            var arrayofData=[]
            var headers={}
           
            setReportLoader(true)
        if(data.data.data.length==0){
      setReportLoader(false)
      toast.warn('Data Not Found')
        }
        var TypeReport=report?.value
          data.data.data.map((item,id)=>{
            arrayofData[id]={}
            ColumnName?.Modem_Data?.unlockreq?.length>0&&ColumnName?.Modem_Data?.unlockreq?.map((col)=>{
              if(TypeReport=='View_Table_Info'){
              if(col?.Display=="Yes"){
                if(id==0){headers[col.Header]=col.Header}
                if(col?.Type=='datetime')
                {
                  arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
                }else{
                  if(col?.Float!=null&&col?.Float!=undefined){
                    arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
                  }else{
                  arrayofData[id][col.Header]=item[col.Column]
                  }
                }
              }
            }else if(report?.value=='All_Table_Info'||report?.value=='Full_Table'){
              if(id==0){headers[col.Header]=col.Header}
              if(col?.Type=='datetime')
                {
                  arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
                }else{
                  if(col?.Float!=null&&col?.Float!=undefined){
                    arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
                  }else{
                  arrayofData[id][col.Header]=item[col.Column]
                  }
                }
            }
              
            })
            if(id==(data.data.data.length-1)){
              if(reportType==='csv'){
							if(report?.value!='Full_Table'){
                exportCSVFile(headers,arrayofData,`unlockreq_`+'('+report.start_date+')'+'_'+'('+report.end_date+')')
              }else{
                exportCSVFile(headers,arrayofData,`unlockreq`)
              }
      setReportLoader(false)
               }else{
							if(report?.value!='Full_Table'){
                exportExcelDownload(arrayofData,`unlockreq_`+'('+report.start_date+')'+'_'+'('+report.end_date+')')
              }else{
                exportExcelDownload(arrayofData,`unlockreq`)
              }
      setReportLoader(false)
  
               }
              }
        })
        
              
          }else{
              console.log('something want wrong')
              setReportLoader(false)
          }
    }
      }
  


    const [BufferPageSize, setBufferPageSize] = useState(50)
    const [BuffernumberofPage, setBuffernumberofPage] = useState(0)
    const [BuffercurrentPage, setBufferCurrentPage] = useState(1);
      const [BufferPenddingData,setBufferPenddingData]=useState([])
      const [modalBufferDetails,setmodalBufferDetails]=useState(false)
      const [OtpShow,setOtpShow]=useState(false)
  
    const onBufferPenddingData=async(col)=>{
      setmodelTitle(col)
    
      setmodalBufferDetails(true)
      const firstPageIndex = (BuffercurrentPage - 1) * BuffercurrentPage;
    
      const data=await getConfigretionCMD_TYPEWisePendding_Data_buffer({CONSUMER_ID:props.GSMdata.UNIQUE_ID,CMD_TYPE:col,start:firstPageIndex,end:BufferPageSize,tableName:props.GSMdata.UNIQUE_TABLE_NAME.GSM_BUFFER})
      if(data.success===true){
    setBuffernumberofPage(data.data.Total)
    setBufferPenddingData(data.data.data)
      }else{
      console.log('something want wrong')
    
      }
    }
    
    useEffect(() => {
      if(BufferPenddingData.length>0){
        onBufferPenddingData()
      }
     }, [BuffercurrentPage, BufferPageSize])

     function generateWiFiStaticPass(macstr,day,mon,year){
      console.log(macstr,day,mon,year);
      var time_arr=[];
      var tempAddr=[];
      var mac=hexToBytes(macstr);
      time_arr[0]=parseInt(day);
      time_arr[1]=parseInt(mon);
      time_arr[2]=parseInt(year);
      time_arr[3]=0;
      time_arr[4]=mac[3];
      time_arr[5]=mac[2];
      time_arr[6]=mac[1];
      time_arr[7]=mac[0];
      tempAddr[0]=CRC8(time_arr,4,0);
      tempAddr[1]=CRC8(time_arr,8,0);
      var passDec= (tempAddr[0]<<8 |tempAddr[1]);
      var zeros="";
      for(var i=0;i<5-passDec.toString().length;i++){
        zeros+="0";
      }
      return zeros+passDec.toString();
    }

    const [GTNOTP,setGTNOTP]=useState('')
    const [otpModel,setotpModel]=useState(false)

    function removeSpace(str1){
      var hex  = str1.toString();
      hex=hex.replace(/ /g,'');
      return hex;
    }
    
    // Convert a hex string to a byte array
    function hexToBytes(hex) {
        hex=removeSpace(hex);
        for (var bytes = [], c = 0; c < hex.length; c += 2)
            bytes.push(parseInt(hex.substr(c, 2), 16));
        return bytes;
    }
    
    function CRC8(data, len,start) {
        //console.log('crc8:'+data);
      var crc = 0x00;
        var index=start;
      while (len--) {
      var extract = data[index++];
        for (var tempI = 8; tempI; tempI--) {
          var sum = (crc ^ extract) & 0x01;
          crc >>= 1;
          if (sum) {
            crc ^= 0x8C;
          }
          extract >>= 1;
        }
      }
      return crc;
    }
    
    const onGenrateOfflineOTP=async()=>{
      const data=await getLetestHeatbeatData({IMEI:props?.GSMdata?.IMEI})
      if(data.success==true){
        if(data?.data?.data?.length>0&&data?.data?.data[0]?.WIFIMAC){
          const getotp=generateWiFiStaticPass(data?.data?.data[0]?.WIFIMAC,moment().format('D'),moment().format('M'),moment().format('YY'))+'03'
      setGTNOTP(getotp)
      
      setotpModel(true)
      ongetgsm_offline_otp()

    }else{
        toast.error('WIFIMAC Not Found')
        }
      }else{
        toast.error('WIFIMAC Not Found')
      }
    }

    const onSort = (field) => {
      if (sortField === field) {
          setsortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
          setsortField(field);
          setsortDirection('asc');
      }
  };

useEffect(()=>{
  ongetHeardbeatData()
},[sortDirection,sortField])

const [isOpenUnlockModal,setisOpenUnlockModal]=useState(false)
const [UnlockData,setUnlockdata]=useState({"UNLOCK_STATUS":0,"UNLOCK_TIME":0})
const onOpenModale=async()=>{
 await props?.onConsumerUpdateDate()
  setUnlockdata({"UNLOCK_STATUS":JSON.parse(props?.GSMdata?.UNLOCK_CONFIG||'{}')?.UNLOCK_STATUS||0,"UNLOCK_TIME":JSON.parse(props?.GSMdata?.UNLOCK_CONFIG||'{}')?.UNLOCK_TIME||0})
  setisOpenUnlockModal(true)
 
}

const onSubmitCommand=async()=>{
if((UnlockData?.UNLOCK_TIME<=10&&UnlockData?.UNLOCK_TIME>0)||(UnlockData?.UNLOCK_STATUS!=0)){
const data=await UpdateunlockConfig({unlock_json:UnlockData,userId:userInfo?.UNIQUE_ID,consumer_id:props?.GSMdata?.UNIQUE_ID},props?.GSMdata?.UNIQUE_ID)
if(data.success==true){
  toast.success('Update Unlock Config Success')
  setisOpenUnlockModal(false)
 await props?.onConsumerUpdateDate()
}else{
  toast.error('Something Want Wrong')
}
}else{
  toast.error('Unlock Time Must Be 1-10 Minute')
}
}

const ongetgsm_unlock_activity = async () => {
  swal.fire({
    title: 'Enter Comment',
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: true,
    confirmButtonText: 'Submit',
    showLoaderOnConfirm: true,
    preConfirm: (data) => {
       if(data){
      return data
       }else{
        swal.showValidationMessage(
         `Please Enter Comment`
         )
       }
    },
    allowOutsideClick: () => !swal.isLoading()
    }).then(async(result) => {
     if (result.isConfirmed) {
  setOtpShow(true)
  await Insertgsm_offline_otp({userId:userInfo?.UNIQUE_ID,consumer_id:props?.GSMdata?.UNIQUE_ID,otp:GTNOTP,COMMENT:result?.value})
  ongetgsm_offline_otp()
     }
    })
  
  // const data = await getgsm_unlock_activity(props?.GSMdata?.UNIQUE_ID);
  // if (data.success === true) {
  //   setUnloackData(data.data.data)
  // } else {
  //   console.log("something want wrong");
  // }
  };
  return (
    <>
    <Modal title="Generate Reports" visible={ismodel} footer='' onCancel={()=>setModel(false)} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={onHandalReport}>
												<option value="">Report Type</option>
												{ReportType?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div>
										{report?.value!=''&&report?.value!='Full_Table'?<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
												}} />
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
                            return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
                          }} />
											</div></>:''}
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLS</option>
												<option value="csv">CSV</option>
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader==true?'disabled btn-progress':''}`} value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
										<Link className="btn btn-light text-dark" onClick={()=>setModal(false)}>Cancel</Link>
									</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
    <Modal title={<span className='text-center'>Offline OTP</span>} visible={otpModel} footer="" onCancel={() => {setotpModel(false)
    setUnloackData([])
    setOtpShow(false)
  }} width={1000}>
				{OtpShow?<h4 className='text-center'>{GTNOTP}</h4>:<><div className='text-center d-flex tw-justify-center tw-items-center'><h4 >XXXXXXX</h4><button className='ml-3 mb-2 tw-border tw-border-indigo-500 tw-bg-transparent hover:tw-bg-indigo-100 tw-rounded' onClick={()=>ongetgsm_unlock_activity()}>
        <span class="tw-text-indigo-500">View</span>
                </button></div></>}
        <div className='text-center'><b>Generate At:-</b>{moment().format('DD-MM-YYYY hh:mm:ss A')}</div>
        <div className="table-responsive mt-5">
								<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
        <table id="table_id" className="tableCustom table table-striped ">
										<thead>
											<th>SRNO</th>
											<th>CREATED_AT</th>
											<th>CREATED_BY</th>
											<th>OTP</th>
											<th>COMMENT</th>
										</thead>
										<tbody>
											{UnloackData.length > 0 && UnloackData.map((item, id) => (
												<tr className="trheigth" >
												  <td>{item?.SRNO}</td>
											<td>{moment.utc(item?.CREATED_AT).format("DD-MM-YYYY hh:mm:ss A")}</td>
											<td>{item?.CREATED_BY}</td>
											<td>{item?.OTP}</td> 
											<td>{item?.COMMENT}</td>
												</tr>
											))}
										</tbody>
									</table>
                  </div>
                  </div>
			</Modal>
<Modal title={modelTitle+'('+(BuffernumberofPage?BuffernumberofPage:0)+')'}  visible={modalBufferDetails} className="my-modal-class" footer='' maskClosable={false} onCancel={() => (setmodalBufferDetails(false))} width={1500}>

<div className="table-responsive mt-2">
								<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
									<div class="dataTables_length ml-2" id="table_id_length">
										<label>
											Show{" "}
											<select name="table_id_length " aria-controls="table_id" class="" onChange={(e) => (setBufferPageSize(e.target.value))} value={BufferPageSize}>
												<option value={10}>10</option>
												<option value={25}>25</option>
												<option value={50}>50</option>
												<option value={100}>100</option>
											</select>{" "}
											entries
										</label>
									</div>
									
									<table id="table_id" className="tableCustom table table-striped">
										<thead>
											<tr>
											{ ColumnName?.GSM_BUFFER?.length>0&&ColumnName?.GSM_BUFFER?.map((col)=>(col?.Display==true?<th className=""><BootstrapTooltip title={col?.Column}><span>{col?.Header}</span></BootstrapTooltip></th>:''))}
											</tr>
										</thead>
										<tbody>
											{BufferPenddingData.length > 0 && BufferPenddingData.map((item, id) => (
												<tr className="trheigth" >
												  { ColumnName?.GSM_BUFFER?.length>0&&ColumnName?.GSM_BUFFER?.map((col)=>(col?.Display==false?'':col?.DataType=='DATETIME'?<td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>:col.Column=='SCR_STATUS_CMD'?<td>{item?.SCR_STATUS_CMD==1? <div class="badge badge-success badge-shadow">Success</div>:item?.SCR_CNT_CMD_RETRY<0&&item?.SCR_STATUS_CMD==0?<div class="badge badge-warning badge-shadow">Waiting For Downlink</div>:item?.SCR_STATUS_CMD==2?<div class="badge badge-info badge-shadow">Canceled By User</div>:item?.SCR_STATUS_CMD==-1?<div class="badge badge-danger badge-shadow">Timeout</div>:item?.SCR_CNT_CMD_RETRY>=0&&item?.SCR_STATUS_CMD==0?<div class="badge badge-primary text-white badge-shadow">Waiting For Uplink</div>:''}</td>:col.Column=='SCR_ID_CMD_ADDED_BY'?<td>{item?.SCR_ID_CMD_ADDED_BY===0?<div class="badge badge-warning badge-shadow">Script</div>:<div class="badge badge-success badge-shadow">User</div>}</td>:<td>{item[col.Column]}</td>))}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
							<><div className='d-flex justify-content-between'>
								<div>Total Number Of Row {BuffernumberofPage||0}</div><Pagination
									className="pagination-bar"
									currentPage={BuffercurrentPage}
									totalCount={BuffernumberofPage || 0}
									pageSize={BufferPageSize}
									onPageChange={(page) => setBufferCurrentPage(page)}
								/></div></>
</Modal>
<Modal title="Error Data" visible={isModalError} footer="" onCancel={() => setIsModalError(false)} width={1000}>
				{ErrorData}
			</Modal>
			<Modal title="Duplicate Data" visible={isModalDup} footer="" onCancel={() => setIsModalDup(false)} width={1000}>
				{DupData}
			</Modal>
			<Modal title="Row Data" visible={isModalrow} footer="" onCancel={() => setIsModalRow(false)} width={1000}>
				{rowData}
			</Modal>

      <Modal title="Unlock Request" visible={isOpenUnlockModal} footer="" onCancel={() => {setisOpenUnlockModal(false)
      setUnloackData([])}} width={1000}>
      <div class="form-row">
      <div class="form-group col-md-3">
      <label for="inputState" className=' pr-2'>Unlock Status: {UnlockData?.UNLOCK_STATUS==1?'Unlocked':UnlockData?.UNLOCK_STATUS==0?'Locked':''}</label><br/>
      <a class={`btn ${UnlockData?.UNLOCK_STATUS==1?'btn-danger':'btn-success'} text-white mr-1`} data-toggle="tooltip" data-placement="bottom" title="Unlock Offline OTP" style={{marginLeft:'35px'}} onClick={(e)=>(setUnlockdata({...UnlockData,UNLOCK_STATUS:UnlockData?.UNLOCK_STATUS==1?0:1}))}>
      {UnlockData?.UNLOCK_STATUS==1?<span ><i style={{fontSize:"18px",cursor:'pointer'}} class="fa">&#xf09c;</i></span>:''}
      {UnlockData?.UNLOCK_STATUS==0?<span><i style={{fontSize:"18px",cursor:'pointer'}} class="fa">&#xf023;</i></span>:''}
      
      </a>
      {/* <Switch  checked={UnlockData?.UNLOCK_STATUS==1?true:false}  onChange={(e)=>(setUnlockdata({...UnlockData,UNLOCK_STATUS:e==true?1:0}))}/> */}
</div>
{UnlockData?.UNLOCK_STATUS==1?<div class="form-group col-md-4">
						<label for="inputState">Unlock Time (1-10 Minute):</label>
						<input type="number" class="form-control"  name="FirstName" onChange={(e)=>(setUnlockdata({...UnlockData,UNLOCK_TIME:e.target.value?parseInt(e.target.value):0}))} defaultValue={UnlockData?.UNLOCK_TIME}/>
					</div>:''}
          <div class="ml-2 mt-4">
					<input type="submit" class="btn btn-danger" value="Submit" name="filter_customer_form" onClick={onSubmitCommand} />
					
				</div>
          </div>
          
        <div className="table-responsive mt-5">
								<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
        <table id="table_id" className="tableCustom table table-striped ">
										<thead>
											<th>SRNO</th>
											<th>CREATED_AT</th>
											<th>CREATED_BY</th>
											<th>CONSUMER_UNIQUE_ID</th>
											<th>UNLOCK_STATUS</th>
											<th>UNLOCK_TIME_IN_MINUTE</th>
										</thead>
										<tbody>
											{UnloackData.length > 0 && UnloackData.map((item, id) => (
												<tr className="trheigth" >
												  <td>{item?.SRNO}</td>
											<td>{moment.utc(item?.CREATED_AT).format("DD-MM-YYYY hh:mm:ss A")}</td>
											<td>{item?.CREATED_BY}</td>
											<td>{item?.CONSUMER_UNIQUE_ID}</td>
											<td>{item?.UNLOCK_STATUS}</td>
											<td>{item?.UNLOCK_TIME_IN_MINUTE}</td>
												</tr>
											))}
										</tbody>
									</table>
                  </div>
                  </div>
			</Modal>
			<Modal title="All Data" visible={isModalVisible} onOk={() => setIsModalVisible(false)} footer="" onCancel={handleCancel} width={1000}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Data</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
						{ ColumnName?.unlockreq?.map((col)=>(col?.DataType=='DATETIME'?<tr>
								<td style={{ width: "50%" }}>{col?.Column}</td>
								<td style={{ width: "50%" }}>{AllData[col?.Column]?moment.utc(AllData[col?.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>
							</tr>:<tr>
								<td style={{ width: "50%" }}>{col?.Column}</td>
								<td style={col?.Column=='UNITS'||col?.Column=='ERRORBIN'||col?.Column=='RAWDATA'?{ maxWidth: '500px'}:{ width: "50%" }}>{AllData[col?.Column]}</td>
							</tr>))}
                        
							
						</tbody>
					</table>
				</div>
			</Modal>
			<Modal  visible={modal} footer='' maskClosable={false} onCancel={() => (setModal(false))} width={500}>
    <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting {modelTitle} Command</h5>
    <h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!CommandStatus?.SCR_STATUS_CMD_ADD&&CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<i className='fas fa-times mr-2' style={{color:'red'}}></i>:<i className='fas fa-check mr-2' style={{color:'green'}}></i>}{!CommandStatus?.SCR_STATUS_CMD_ADD?'Command Pending':CommandStatus?.SCR_STATUS_CMD_ADD}</h5>
    <div class=""> 
   { CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'? <div class="text-center" style={{marginTop:"10px",fontSize: "20px"}}>
                     <label style={{fontWeight:"400"}}>Waiting For Respose</label>
                     <div id="meter_data_mqtt_timer_status" style={{fontSize: "18px"}}>{`${zeroPad(time)}`} seconds left</div>
                  </div>:''}
               </div>
               <div class="text-center" style={{marginTop:"20px"}}>
                  <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={CloseModel}/>
               </div>
            </Modal>
            <Modal  visible={modalMsg} footer='' maskClosable={false} onCancel={() => (setmodalMsg(false))} width={500}>
    {MqttMsg}
            </Modal>
            <section className="card-diff-section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4><BootstrapTooltip title={`Table Name is ${props?.GSMdata?.UNIQUE_TABLE_NAME?.unlockreq}`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
                                    <div className="card-header-action">
                                        <div id="filtergrm">
                                        {/* <button type="button" id="mqtt_get_cmd_402" class="btn btn-warning mr-1" onClick={()=>(onBufferPenddingData('NAME_PLATE'))}>Queue({pendingBuffer})</button> */}
                                            {/* <button type="button" id="mqtt_get_cmd_301" class="btn btn-info mr-1" onClick={ongetScriptCodeIdWiseConfigretionData}>
                                                Get Data
                                            </button> */}
                                            {/* <i style={{fontSize:"15px"}} class="fa">&#xf09c;</i>
                                            <i style={{fontSize:"15px"}} class="fa">&#xf023;</i> */}
                                            <a class={`btn ${JSON.parse(props?.GSMdata?.UNLOCK_CONFIG||'{}')?.UNLOCK_STATUS==1?'btn-danger':'btn-success'} text-white mr-1`} data-toggle="tooltip" data-placement="bottom" title="Unlock Offline OTP" onClick={()=>{onOpenModale()}} >
                                            
                                                {JSON.parse(props?.GSMdata?.UNLOCK_CONFIG||'{}')?.UNLOCK_STATUS==1?<span><i style={{fontSize:"18px"}} class="fa">&#xf09c;</i></span>:''}
                                                {JSON.parse(props?.GSMdata?.UNLOCK_CONFIG||'{}')?.UNLOCK_STATUS==0?<span><i style={{fontSize:"18px"}} class="fa">&#xf023;</i></span>:''}
                                                {props?.GSMdata?.UNLOCK_CONFIG==null?<span><i style={{fontSize:"18px"}} class="fa">&#xf023;</i></span>:''}
                                            </a>
                                            <a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Unlock Offline OTP" onClick={()=>{onGenrateOfflineOTP()}} >
                                                Unlock Offline OTP
                                            </a>
                                            <a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={()=>{ongetHeardbeatData()
											ongetConfigretionCMD_TYPEWisePendding_Count_buffer()}} >
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                            </a>
                                            <div class="d-inline">
                      <button class="btn btn-info" onClick={()=>ongetStartAndEndDateByTable()}>
                      <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                {loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)}
									{heartData.length === 0 && !loader ? StatusCode==503?<h4 className="text-center">Table Not Found</h4> :<h4 className="text-center">No Data Found</h4>:''}
									{heartData.length > 0 &&
                                        <div className="table-responsive tw-h-screen tw-max-h-screen">
                                            <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                                
                                                <table id="table_id" className="table table-borderless table-striped font-10">
                                                    <thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100'>
                                                        <tr className="tw-border-b text-center">
                                                        { ColumnName?.Meter_Data?.unlockreq?.length>0&&ColumnName?.Meter_Data?.unlockreq?.map((col)=>(col?.Display=="Yes"?<BootstrapTooltip title={col?.Column}><th onClick={()=>onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'>
                                        <div >{col?.Header}</div>
                                        {col?.Notes&&<BootstrapTooltip title={col?.Notes} placement='top'><span><i className="ml-2 fas fa-info-circle tw-text-xs"></i></span></BootstrapTooltip>}
                                        <div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                    </div></th></BootstrapTooltip>:''))}
                                                            {/* <th className="text-center">SRNO</th>
                                                            <th>CMDID</th>
                                                            <th>IMEI</th>
                                                            <th>VD</th>
                                                            <th>MSGID</th>
                                                            <th>CMD_TYPE</th>
                                                            <th style={{ minWidth: "100px" }}>DEVICETS</th>
                                                            <th>POTP</th>
                                                            <th>COTP</th>
                                                            <th>VERSTR</th>
                                                            <th>MSGDIR</th>
                                                            <th>DATE</th>
                                                            <th>STINDEX</th>
                                                            <th>LOAD</th>
                                                            <th>MHOURDINTERVAL</th>
                                                            <th>DRXTYPE</th>
                                                            <th>DATA_TIMEDIFF</th>
                                                            <th>STARTTS</th>
                                                            <th>ENDTS</th>
                                                            <th>MCOMST</th>
                                                            <th>MCOMTRY</th>
                                                            <th>CONVSERNUM</th>
                                                            <th>ASMETERSERNUM</th>
                                                            <th>DURATION</th>
                                                            <th>DATE_CHANGE</th>
                                                            <th>METROL_ALARM</th>
                                                            <th>TEMPERATURE_ALARM</th>
                                                            <th>PRESSURE_ALARM</th>
                                                            <th>METER_ENDTS</th>
                                                            <th>UNCONV_CONS</th>
                                                            <th>CONC_CONS</th>
                                                            <th>UNCONV_CONS_AL</th>
                                                            <th>CONV_CONS_AL</th>
                                                            <th>TMIN</th>
                                                            <th>TMAX</th>
                                                            <th>TAV</th>
                                                            <th>PMIN</th>
                                                            <th>PMAX</th>
                                                            <th>PAV</th>
                                                            <th>QUMIN</th>
                                                            <th>QUMAX</th>
                                                            <th>QCMIN</th>
                                                            <th>QCMAX</th>
                                                            <th>UNCONVERTED_INDEX</th>
                                                            <th>CONVERTED_INDEX</th>
                                                            <th>UNCONVERTED_COUNTER</th>
                                                            <th>CONVERTED_COUNTER</th>
                                                            <th>QUAV</th>
                                                            <th>QCAV</th>
                                                            <th>METER_STARTTS</th>
                                                            <th>ENTRYTS</th>
                                                            <th>DUPLICATECOUNT</th>
                                                            <th>ERRORCOUNT</th>
                                                            <th>DATARXSTATUS</th>
                                                            <th>DATASTATUS</th>*/}
                                                            <th>ACTIONS</th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {heartData.length > 0 &&
                                                            heartData.map((item, id) => (
                                                                <tr className="trheigth text-center">
                                                                { ColumnName?.Meter_Data?.unlockreq?.length>0&&ColumnName?.Meter_Data?.unlockreq?.map((col)=>(col?.Display=="No"?'':col?.Type=='datetime'?<td style={{ minWidth: "100px" }}>{item[col.Column]?item[col.Column]!='0000-00-00 00:00:00'?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):item[col.Column]:''}</td>:col?.Float?col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]/col?.DivisionNumber).toFixed(col?.Float)}</td>:<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{col?.Float&&item[col.Column]?Number(item[col.Column]).toFixed(col?.Float):item[col.Column]}</td>:
															col?.Division==true?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]/col?.DivisionNumber}</td>:
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]}</td>
															))}
                              <td style={{ minWidth: '100px' }}><a class="action-icon text-success" onClick={() => (onAllShowData(item))} ><span data-toggle="tooltip" data-placement="bottom" title="All Data"><i class="fa fa-database fa-lg font-13" aria-hidden="true"></i></span></a></td>
                                                                    {/* <td>{item?.SRNO}</td>
                                                                    <td>{item?.CMDID}</td>
                                                                    <td>{item?.IMEI}</td>
                                                                    <td>{item?.VD}</td>
                                                                    <td>{item?.MSGID}</td>
                                                                    <td>{item?.CMD_TYPE}</td>
                                                                    <td style={{ minWidth: "100px" }}>
                                                                        {moment.utc(item?.DEVICETS).format("DD/MM/YYYY hh:mm:ss A")}
                                                                    </td>
                                                                    <td>{item?.POTP}</td>
                                                                    <td>{item?.COTP}</td>
                                                                    <td>{item?.VERSTR}</td>
                                                                    <td>{item?.MSGDIR}</td>
                                                                    <td>{item?.DATE_}</td>
                                                                    <td>{item?.STINDEX}</td>
                                                                    <td>{item?.LOAD_}</td>
                                                                    <td>{item?.MHOURDINTERVAL}</td>
                                                                    <td>{item?.DRXTYPE}</td>
                                                                    <td>{item?.DATA_TIMEDIFF}</td>
                                                                    <td style={{ minWidth: "100px" }}>
                                                                        {moment.utc(item?.STARTTS).format("DD/MM/YYYY hh:mm:ss A")}
                                                                    </td>
                                                                    <td style={{ minWidth: "100px" }}>
                                                                        {moment.utc(item?.ENDTS).format("DD/MM/YYYY hh:mm:ss A")}
                                                                    </td>
                                                                    <td>{item?.MCOMST}</td>
                                                                    <td>{item?.MCOMTRY}</td>
                                                                    <td>{item?.CONVSERNUM?.split("\u0000")[0]}</td>
                                                                    <td>{item?.ASMETERSERNUM}</td>
                                                                    <td>{item?.DURATION}</td>
                                                                    <td>{item?.DATE_CHANGE}</td>
                                                                    <td>{item?.METROL_ALARM}</td>
                                                                    <td>{item?.TEMPERATURE_ALARM}</td>
                                                                    <td>{item?.PRESSURE_ALARM}</td>
                                                                    <td style={{ minWidth: "100px" }}>
                                                                        {moment.utc(item?.METER_ENDTS).format("DD/MM/YYYY hh:mm:ss A")}
                                                                    </td>
                                                                    <td>{item?.UNCONV_CONS}</td>
                                                                    <td>{item?.CONC_CONS}</td>
                                                                    <td>{item?.UNCONV_CONS_AL}</td>
                                                                    <td>{item?.CONV_CONS_AL}</td>
                                                                    <td>{item?.TMIN}</td>
                                                                    <td>{item?.TMAX}</td>
                                                                    <td>{item?.TAV}</td>
                                                                    <td>{item?.PMIN}</td>
                                                                    <td>{item?.PMAX}</td>
                                                                    <td>{item?.PAV}</td>
                                                                    <td>{item?.QUMIN}</td>
                                                                    <td>{item?.QUMAX}</td>
                                                                    <td>{item?.QCMIN}</td>
                                                                    <td>{item?.QCMAX}</td>
                                                                    <td>{item?.UNCONVERTED_INDEX}</td>
                                                                    <td>{item?.CONVERTED_INDEX}</td>
                                                                    <td>{item?.UNCONVERTED_COUNTER}</td>
                                                                    <td>{item?.CONVERTED_COUNTER}</td>
                                                                    <td>{item?.QUAV}</td>
                                                                    <td>{item?.QCAV}</td>
                                                                    <td style={{ minWidth: "100px" }}>
                                                                        {moment.utc(item?.METER_STARTTS).format("DD/MM/YYYY hh:mm:ss A")}
                                                                    </td>
                                                                    <td style={{ minWidth: "100px" }}>
                                                                        {moment.utc(item?.ENTRYTS).format("DD/MM/YYYY hh:mm:ss A")}
                                                                    </td>
                                                                    <td>{item?.DUPLICATECOUNT}</td>
                                                                    <td>{item?.ERRORCOUNT}</td>
                                                                    <td>{item?.DATARXSTATUS}</td>
                                                                    <td>{item?.DATASTATUS}</td> */}
                                                                    {/* <td style={{ minWidth: '100px' }}><a class="action-icon text-success" onClick={() => (onAllShowData(item))} ><span data-toggle="tooltip" data-placement="bottom" title="All Data"><i class="fa fa-database fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
                                                                        <a class="action-icon text-info" onClick={() => onRowDate(item?.RAWDATA)}><span data-toggle="tooltip" data-placement="bottom" title="Raw Data"><i class="fa fa-info-circle fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
                                                                        <a class="action-icon text-danger" onClick={() => (onDuplicate(item?.DUPLICATEBIN))}><span data-toggle="tooltip" data-placement="bottom" title="Duplicate Data"><i class="fa fa-clone fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
                                                                        <a class="action-icon text-dark" onClick={() => (onError(item?.ERRORBIN))}><span data-toggle="tooltip" data-placement="bottom" title="Error Data"><i class="fa fa-exclamation-triangle fa-lg font-13" aria-hidden="true"></i></span></a></td> */}
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                <>
                                                    <div className="d-flex justify-content-between">
                                                        <div>Total Number Of Row {numberofPage}</div>
                                                        <div class="dataTables_length" id="table_id_length">
                                                    <label>
                                                        Show{" "}
                                                        <select
                                                            name="table_id_length"
                                                            aria-controls="table_id"
                                                            class=""
                                                            onChange={(e) => setPageSize(e.target.value)}
                                                            value={PageSize}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>{" "}
                                                        entries
                                                    </label>
                                                </div>
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPage}
                                                            totalCount={numberofPage || 1}
                                                            pageSize={PageSize}
                                                            onPageChange={(page) => setCurrentPage(page)}
                                                        />
                                                    </div>
                                                </>
                                            </div>
                                        </div>}

                                </div>
                                </div>
                                </div></div></div></section>
    </>
  )
}
