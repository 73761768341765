import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import AdvancedDropdown from '../Dropdown/basic-dropdown/AdvancedDropdown';
import moment from 'moment';

const AdvancedDownload = ({ data, headers, filename, fileTypeOptions, onDownload, ...dropdownProps }) => {
    const [selectedFileType, setSelectedFileType] = useState(null);
    const [downloadData, setDownloadData] = useState(null);

    const escapeQuotes = (str) => {
        if (typeof str === 'string') {
            return str.replace(/"/g, '""');
        }
        return str;
    };

    const handleFileTypeSelection = async (option) => {
        const transformedData = data.map((item) => (
            {
                ...item,
                createdTime: moment().format('DD-MM-YYYY hh:mm:ss')
            }
        )
        );
        setDownloadData(transformedData);
        setSelectedFileType(option.value);
        onDownload(option.value);
        if (option.value === 'excel') {
            downloadExcel()
        }
    };

    const downloadExcel = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(downloadData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };

    const processDataForCSV = (data) => {
        return data.map((item) => {
            const processedItem = {};
            Object.keys(item).forEach((key) => {
                return typeof item[key] === 'object' ? processedItem[key] = escapeQuotes(JSON.stringify(item[key])) : processedItem[key] = escapeQuotes(item[key]);
            });
            return processedItem;
        });
    };


    return (
        <div>
            <AdvancedDropdown
                isForDownload={true}
                {...dropdownProps}
                options={fileTypeOptions}
                onSelect={handleFileTypeSelection}
            />
            {selectedFileType === 'csv' && (
                <CSVLink
                    data={processDataForCSV(data)}
                    filename={`${filename}.csv`}
                    className="hidden"
                    ref={link => link && link.link.click()}
                />
            )}

        </div>
    );
};

export default AdvancedDownload;
