import React from 'react'
import { Link } from 'react-router-dom'
import apiDocsjson from './apiDocs.json'

const ApiList = () => {
    return (
        <>
            <div className="main-content">
                <section className="section">
                    <div className="card tw-h-screen">
                        <div className="card-header">
                            <h4> API Documentation</h4>
                            <div className="card-header-action tw-text-indigo-500 tw-cursor-pointer tw-hover:tw-text-indigo-700 tw-font-semibold">

                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="table-responsive tw-mb-2">
                                <h4 className='tw-pl-4'>1. Authentication</h4>
                                <table className="tw-table border" id="table-1">
                                    <thead className='tw-bg-gray-100 border-bottom border-top'>
                                        <tr>
                                            <th className="tw-px-1 tw-py-4 text-center !tw-font-semibold tw-min-w-[50px]" style={{ maxWidth: "100px" }} >No.</th>
                                            <th className='tw-px-2 tw-py-4 !tw-font-semibold'>Title</th>
                                            <th className='tw-px-2 tw-py-4 !tw-font-semibold'>Purpose</th>
                                            <th className='tw-px-1 tw-py-4 text-center !tw-font-semibold'>Output</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr key={0} className='hover:tw-bg-indigo-50'>
                                            <td className='tw-px-1 tw-py-3 text-center'>{1}</td>
                                            <td className='tw-px-2 tw-cursor-pointer tw-py-3 tw-text-blue-500 tw-whitespace-nowrap'><div> <Link to={`/api/documentation?id=0`}>OAuth 2.0 Authentication</Link>
                                            </div> </td>
                                            <td className='tw-px-2 tw-py-3'>To get Auth tokens for api endpoints</td>
                                            <td className='tw-w-3/5 tw-px-1 tw-py-3'>The output will be in json format with the access token and the refresh token. The refresh token is used to get new access token when the current access token expires. </td>

                                        </tr>




                                    </tbody>
                                </table>

                                <div className="table-responsive tw-my-2" >

                                    <h3 className=' tw-pl-4'>2. API endpoints</h3>
                                    <table className='border'>
                                        <thead className='tw-bg-gray-100 border-bottom '>
                                            <tr>
                                                <th className="tw-px-1 tw-py-4 text-center !tw-font-semibold tw-min-w-[50px]" style={{ maxWidth: "100px" }} >No.</th>
                                                <th className='tw-px-2 tw-py-4 !tw-font-semibold'>Title</th>
                                                <th className='tw-px-2 tw-py-4 !tw-font-semibold'>Purpose</th>
                                                <th className='tw-px-1 tw-py-4 text-center !tw-font-semibold'>Output</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {apiDocsjson.endpoints.map((item, index) => (
                                                <tr key={item.id} className='hover:tw-bg-indigo-50'>
                                                    <td className='tw-px-1 tw-py-3 text-center'>{index + 1}</td>
                                                    <td className='tw-px-2 tw-cursor-pointer tw-py-3 tw-text-blue-500 tw-whitespace-nowrap'><div> <Link to={`/api/documentation?id=${item.id}`}>{item.title}</Link>
                                                    </div> </td>
                                                    <td className='tw-px-2 tw-py-3'>{item.purpose}</td>
                                                    <td className='tw-w-3/5 tw-px-1 tw-py-3'>{item.output}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>
            </div >
        </>
    )
}

export default ApiList