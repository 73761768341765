import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { BootstrapTooltip } from '../../Commoncomponet/common'

export default function ReleaseNotes() {
    const [isAdmin, setIsAdmin] = useState(true)
    return (
        <>

            <div className="main-content" style={{ minHeight: "562px", paddingRight: '30px' }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/group-dashboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to='/release-notes'>
                                <i className='fas fa-book'></i> Release Notes
                            </Link>
                        </li>

                    </ol>
                </nav>
                <section className="section">
                    <div className='card !tw-rounded-none'>
                        {/* <div>
                            What's New
                        </div>
                        <div>
                            Bug Fixes
                        </div>
                        <div>
                            Improvements
                        </div> */}

                        <div className='card-header'>
                            <h4> Release Notes</h4>
                            {isAdmin ? <div className="card-header-action">
                                <Link to='/add-realese-notes' className="btn btn-primary text-white mr-2">
                                    <BootstrapTooltip title="Add Release Notes">
                                        <span>
                                            <i className="fas fa-plus text-white"></i>
                                        </span>
                                    </BootstrapTooltip>
                                </Link>
                            </div> : ''}

                        </div>

                        <div className='card-body'>

                            <div className="accordion" id="accordionExample5">
                                <div className="accordion-item bg-white border border-gray-200">
                                    <h2 className="accordion-header !tw-p-0 " id="headingOne5">
                                        <button className="accordion-button tw-relative tw-flex tw-items-center tw-w-full tw-py-4 tw-px-5 tw-text-base tw-text-gray-800 tw-text-left tw-bg-white tw-border-0 tw-rounded-none tw-transition focus:!tw-outline-none focus:tw-shadow-none" type="button" data-toggle="collapse" data-target="#collapseOne5" aria-expanded="true" aria-controls="collapseOne5">
                                            {(moment(new Date()).format("Do MMMM, YYYY"))}
                                        </button>
                                    </h2>
                                    <div id="collapseOne5" className="accordion-collapse collapse show" aria-labelledby="headingOne5">
                                        <div className="accordion-body tw-py-4 tw-px-5">
                                            <div className="accordion-item bg-white border border-gray-200">
                                                <h2 className="accordion-header !tw-p-0" id="h2-whats-new">
                                                    <div className="accordion-button collapsed  focus:!tw-outline-none focus:tw-shadow-none" type="button" data-toggle="collapse" data-target="#col-whats-new-1" aria-expanded="false" aria-controls="col-whats-new-1">
                                                        <h4 className='!tw-text-base !tw-font-semibold'>What's New</h4>
                                                        {isAdmin ? <Link to='/add-realese-notes' className="btn btn-primary tw-ml-6 !tw-px-2 !tw-py-0 text-white mr-2">
                                                            <BootstrapTooltip title="Edit/Add What's New">
                                                                <span>
                                                                    <i className="far fa-edit text-white"></i>
                                                                </span>
                                                            </BootstrapTooltip>
                                                        </Link> : ''}
                                                    </div>
                                                </h2>
                                                <div id="col-whats-new-1" className="accordion-collapse collapse" aria-labelledby="h2-whats-new">
                                                    <div className="accordion-body py-4 px-5">
                                                        <ul className='tw-text-black'>
                                                            <li>sfdggfuiggasifgaufgs Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id asperiores neque, harum aliquam officiis optio sunt quisquam at dignissimos consectetur, ipsum blanditiis suscipit quam ex, pariatur quo quas a! Sit!</li>
                                                            <li>asfgvfgwghfuwhgifwfgwhi Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt, eos natus praesentium et consequuntur totam. Officia dolor in dicta aliquid deserunt nihil doloribus placeat, adipisci possimus suscipit tempora modi eos?</li>
                                                            <li>asfvjkghwsghwighkwh Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam esse ratione earum dolor numquam ipsa omnis placeat perferendis delectus suscipit doloremque, eveniet veniam sequi animi quisquam asperiores perspiciatis commodi neque. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius, accusantium non. Dolorum quis reiciendis atque voluptas error repudiandae. Error aut nam adipisci quae excepturi pariatur quasi sit ab soluta voluptate. </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item bg-white border border-gray-200">
                                                <h2 className="accordion-header !tw-p-0" id="h2-bug-fixes">
                                                    <div className="accordion-button collapsed focus:!tw-outline-none focus:tw-shadow-none" type="button" data-toggle="collapse" data-target="#col-bug-fixes-1" aria-expanded="false" aria-controls="col-bug-fixes-1">
                                                        <h4 className='!tw-text-base !tw-font-semibold'>Bug Fixes</h4>
                                                        {isAdmin ? <Link to='/add-realese-notes' className="btn btn-primary tw-ml-6 !tw-px-2 !tw-py-0 text-white mr-2">
                                                            <BootstrapTooltip title="Edit/Add Bug Fixes">
                                                                <span>
                                                                    <i className="far fa-edit text-white"></i>
                                                                </span>
                                                            </BootstrapTooltip>
                                                        </Link> : ''}
                                                    </div>
                                                </h2>
                                                <div id="col-bug-fixes-1" className="accordion-collapse collapse" aria-labelledby="h2-bug-fixes">
                                                    <div className="accordion-body py-4 px-5">
                                                        <ul className='tw-text-black'>
                                                            <li>sfdggfuiggasifgaufgs</li>
                                                            <li>asfgvfgwghfuwhgifwfgwhi</li>
                                                            <li>asfvjkghwsghwighkwh</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item bg-white border border-gray-200">
                                    <h2 className="accordion-header !tw-p-0" id="headingTwo5">
                                        <button className="accordion-button collapsed tw-relative tw-flex tw-items-center tw-w-full tw-py-4 tw-px-5 tw-text-base tw-text-gray-800 tw-text-left tw-bg-white tw-border-0 tw-rounded-none tw-transition focus:!tw-outline-none focus:tw-shadow-none" type="button" data-toggle="collapse" data-target="#collapseTwo5" aria-expanded="false" aria-controls="collapseTwo5">
                                            {(moment('01-11-2022', "DD-MM-YYYY").format("Do MMMM, YYYY"))}
                                        </button>
                                    </h2>
                                    <div id="collapseTwo5" className="accordion-collapse collapse" aria-labelledby="headingTwo5">
                                        <div className="accordion-body py-4 px-5">
                                            <strong>This is the second item's accordion body.</strong> It is hidden by default,
                                            until the collapse plugin adds the appropriate classes that we use to style each
                                            element. These classes control the overall appearance, as well as the showing and
                                            hiding via CSS transitions. You can modify any of this with custom CSS or overriding
                                            our default variables. It's also worth noting that just about any HTML can go within
                                            the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item bg-white border border-gray-200">
                                    <h2 className="accordion-header !tw-p-0" id="headingThree5">
                                        <button className="accordion-button collapsed tw-relative tw-flex tw-items-center tw-w-full tw-py-4 tw-px-5 tw-text-base tw-text-gray-800 tw-text-left tw-bg-white tw-border-0 tw-rounded-none tw-transition focus:!tw-outline-none focus:tw-shadow-none" type="button" data-toggle="collapse" data-target="#collapseThree5" aria-expanded="false" aria-controls="collapseThree5">
                                            {(moment('02-10-2022', "DD-MM-YYYY").format("Do MMMM, YYYY"))}
                                        </button>
                                    </h2>
                                    <div id="collapseThree5" className="accordion-collapse collapse" aria-labelledby="headingThree5">
                                        <div className="accordion-body py-4 px-5">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default,
                                            until the collapse plugin adds the appropriate classes that we use to style each
                                            element. These classes control the overall appearance, as well as the showing and
                                            hiding via CSS transitions. You can modify any of this with custom CSS or overriding
                                            our default variables. It's also worth noting that just about any HTML can go within
                                            the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </div>
        </>
    )
}
