import React, { useEffect, useState } from 'react'
import DataTable from '../../Commoncomponet/Datatable/Datatable';
import GoogleLoader from '../../Commoncomponet/Loaders/GoogleLoader';
import { getAllTableInfo, getDashboardDBTableInfo } from '../../Commoncomponet/Utils';
import chroma from 'chroma-js';
import ShowDbInfo from './ShowDbInfo';
import classNames from 'classnames'

const Svgcard = ({ svgIllustration, numbers, title, color, onClick, borderColor }) => {

    return (
        <div  className={classNames('tw-flex tw-flex-col tw-mb-1 tw-relative !tw-border-b-8 tw-border tw-border-solid tw-rounded-lg tw-py-0.5 tw-cursor-pointer', color)} style={{ borderColor: borderColor  }}>
            <div className='tw-flex tw-justify-between tw-items-center'>
  
                <div className='tw-ml-6 tw-text-start'>
                    <h3 className='tw-mb-0'>{numbers ? numbers : 0}</h3>
                    <span className='tw-text-sm tw-text-gray-600'>
                        {title}
                    </span>
                </div>
                <div className='tw-h-16 tw-w-16 tw-m-1 tw-mr-4 tw-float-right tw-flex tw-justify-center tw-items-center tw-rounded-full '>
                    {svgIllustration}
                </div>
            </div>
        </div>
    )
  }

const DashboardDbinfo = () => {
    const [tableData, setTableData] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [btnText, setButtontext] = useState("Dashboard");

    const buttonArray = ["Dashboard", "Database tables"];
    const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        setIsLoading(true)
        const getDetails = await getDashboardDBTableInfo()
        if (getDetails.success === true) {
            setTableData(getDetails.data)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            console.log("Error while getting all table info = ", getDetails.err)
        }
    }

    const columns = tableData?.table_info?.length > 0 ? Object.keys(tableData?.table_info[0]).map(key => ({
        field: key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
        isVisible: key === "TABLE_CATALOG" ? false : true,
        type: key === "CREATE_TIME" || key === "UPDATE_TIME" ? 'datetime' : 'text',
        cellRenderer: key === "total_size" ? (row) => `${row.toFixed(2)} mb` : null,
    })) : [];

    const onRowClick = (row) => {
        alert(JSON.stringify(row, null, 2))
    }

    const onHandalebtn = (text) => {
        setButtontext(text);

    };
    return (
        <>
            <div className="main-content">
                <section className="section">
                    <div className="card !tw-rounded-none">
                        <div className='card-header'>
                            {buttonArray.map((item, id) => (
                                <button
                                    style={{
                                        backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
                                        color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
                                        borderBottom: `1px solid ${btnColor[id]}`,
                                        boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
                                    }}
                                    className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}

                                    onClick={() => onHandalebtn(item)}>
                                    {item}
                                </button>
                            ))}
                        </div>

                        {btnText == 'Dashboard' && <div className='card-body !tw-p-0'>
                            {isLoading ? <> <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                                <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                            </div></> : <>
                                <div className='tw-my-4 tw-py-2 col-md-12' style={{ boxShadow: "0 5px 10px 0 rgba(0,0,0,.08)" }}>
                                    <div className='border-bottom tw-my-1 tw-px-4'>
                                        <h5 className='tw-font-medium tw-text-gray-800'>Database analytics</h5>
                                    </div>

                                    <div className='tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-2 lg:tw-gap-3 tw-px-4 tw-my-4'>
                                        <Svgcard svgIllustration={<></>} numbers={tableData?.results&&tableData?.results[0]?.total_table} title={'Number Of Total Table'} color={'!tw-border-red-200'}  />
                                        <Svgcard svgIllustration={<></>} numbers={tableData?.results&&tableData?.results[0]?.total_size+' mb'} title={"Total Database Occupied Memory"} color={'!tw-border-yellow-200'}  />
                                    </div>
                                </div>
                                <div className='tw-my-4 tw-py-2 col-md-12' style={{ boxShadow: "0 5px 10px 0 rgba(0,0,0,.08)" }}>
                                <div className='border-bottom tw-my-1 tw-px-4'>
                                        <h5 className='tw-font-medium tw-text-gray-800'>Highest Table Size Occupied Top 10 Table List</h5>
                                    </div>
                            <DataTable
                                data={tableData?.table_info || []}
                                columns={columns}
                                initialSortField="total_size"
                                initialSortDirection="desc"
                                pageSize={50}
                                filterable
                            /></div></>}
                    </div>}
                    {btnText == 'Database tables' && <ShowDbInfo />}
            </div></section ></div >
        </>
    )

}

export default DashboardDbinfo