import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BootstrapTooltip } from '../../../../Commoncomponet/common'
import { UpdateLoraWebConfigDetails,getAll_Active_alarm_template } from '../../../../Commoncomponet/Utils'
import { Switch } from 'antd'

export default function WebConfig(props) {

    const [config,setConfig]=useState({ALERT_DISABLE:{},EVC_DATA: {hourlydata:1}})
    const [disable,setDisable]=useState(true)
    const [btnFlag,setbtnFlag]=useState(false)
    const [AlarmList,setAlarmList]=useState(['TAMPER_FLG','EDGE_DETECTION','PLS_READ_ERR','CKSM_ERR','EPRM_ERR','INT_DOOR_STATUS','EXT_DOOR_STATUS','RTC_ERR','LADR'])
    useEffect(()=>{
        setDisable(false)
        const disable_alert=JSON.parse(props?.GSMdata?.ALERT_DISABLE||'{"TAMPER_FLG":1,"EDGE_DETECTION":1,"PLS_READ_ERR":1,"CKSM_ERR":1,"EPRM_ERR":1,"INT_DOOR_STATUS":1,"EXT_DOOR_STATUS":1,"RTC_ERR":1,"LADR":1}')
        setConfig({ALERT_DISABLE:{...disable_alert},EVC_DATA:JSON.parse(props?.GSMdata?.EVC_MC_METER_DATA_EN_DIS ||'{"hourlydata":1}')})
    },[])

    const onHandalUpdate=async()=>{
        setbtnFlag(true)
        const data=await UpdateLoraWebConfigDetails(config,props?.GSMdata?.UNIQUE_ID)
        if(data.success===true){
            toast.success('Successfully Updated Details')
            setDisable(false)
        setbtnFlag(false)
        props.onRefreshData()
        }else{
            toast.error('Something Went Wrong')
        setbtnFlag(false)
        }
    }
    
    const onHandalswitch=(e,id)=>{
      if(e==true){
         setConfig({...config,ALERT_DISABLE:{...config.ALERT_DISABLE,[id]:1}});
      }else{
         setConfig({...config,ALERT_DISABLE:{...config.ALERT_DISABLE,[id]:0}});

      }
    }
    const onHandalSwitchEVCData = (e, id) => {
      if (e == true) {
         setConfig({ ...config, EVC_DATA: { ...config.EVC_DATA, [id]: 1 } });
      } else {
         setConfig({ ...config, EVC_DATA: { ...config.EVC_DATA, [id]: 0 } });

      }
   }
  return (
    <div>
      <section class="card-diff-section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>Web Configuration</h4>
                           <div class="card-header-action">
                           <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'>
                                 <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                    <i class="fas fa-edit  text-white"></i>
                                 </span></BootstrapTooltip>
                              </div>
                           </div>
                           </div>
                           <div className='card-body'>
                                 <div class="d-flex mt-4">
												<div className=' ' style={{fontSize:'15px',width:'10%'}}><b>1] Alerts :- </b></div>
                                    <div >
                                    {AlarmList&&AlarmList.map((item)=>(
                                       <>
                                       <div class='d-flex '>
                                       <Switch size="small" checked={config?.ALERT_DISABLE[item]==1?true:false} onChange={(e)=>(onHandalswitch(e,item))} disabled={!disable}/>
                                          <h6 class='ml-2'>{item}</h6>
                                       </div>
                                       </>
                                    ))}
                                    </div>
                                    </div>
                                    <div class="d-flex mt-4">
                              <div className=' ' style={{ fontSize: '15px', width: '10%' }}><b>2] EVC/MC Meter Data :- </b></div>
                              <div>
                              <div class='d-flex '>
                                          <Switch size="small" checked={config?.EVC_DATA["hourlydata"] == 1 ? true : false} onChange={(e) => (onHandalSwitchEVCData(e, "hourlydata"))} disabled={!disable} />
                                          <h6 class='ml-2'>Hourly Data</h6>
                              </div>
                              </div>
                              </div >
                                    {disable ? <> 
                                 <div class="text-left mb-3 ml-3 mt-3">
                                    <input type="submit" name="Update" class={`btn btn-danger ${btnFlag ? 'disabled btn-progress' : ''} mr-4`} value="Submit" id="submitvn" onClick={onHandalUpdate}/>

                                    <Link class="btn btn-light text-dark " onClick={()=>setDisable(false)}>Cancel</Link>
                                 </div></> : ''}
                           </div>
                           </div>
                           </div>
                           </div>
                           </div>
                           </section>
    </div>
  )
}
