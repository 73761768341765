import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { BootstrapTooltip } from "../../Commoncomponet/common";
import Select from 'react-select';
import { Modal } from 'react-bootstrap'
import { createWebTab, getAllWebTabs, getUsersList, updateWebTabs } from "../../Commoncomponet/Utils";
import { toast } from "react-toastify";
import chroma from 'chroma-js';

export default function WebTabs() {
    let userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const btnColor=['#4f5ece','#f59e0b','#fc544b','#3abaf4','#54ca68','#191d21','#7c5295','#4f5ece']
    const [isWebAddModal,setisWebAddModal]=useState(false)
    const [InputWeb,setInputWeb]=useState({URL:'',NAME:'',USERS:[userInfo?.UNIQUE_ID],DESCRIPTION:''})
    const [UserList, setUserList] = useState([])
    const [TabList, setTabList] = useState([])
    const [TabDetail, setTabDetail] = useState({})
	const [btnText, setButtontext] = useState("");
	const [EditId, setEditId] = useState("");
	const [LoaderSubmit, setLoaderSubmit] = useState(false);
  const [Validated, setValidated] = useState({})

    const ongetUsersList = async () => {
        const data = await getUsersList()
        if (data.success === true) {
            console.log("Data = ", data.data.data)
            setUserList(data.data.data)
            
        } else {
            console.log('something want wrong')
        }
    }

    useEffect(()=>{
        getWebTabs()
        ongetUsersList()
    },[])

    const onSubmitWebTab=async()=>{
      var obj_error={}

      if(InputWeb?.URL==''){
        obj_error["URL"]='Please enter a URL'
      }else{
        var regexQuery = "^(https://)(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    var url = new RegExp(regexQuery,"i");
    const result= url.test(InputWeb?.URL);
        if(result==false){
          obj_error["URL"]='Please enter a Valid URL'
        }
      }
      if(InputWeb?.NAME==''){
        obj_error["NAME"]='Please enter a Web Tab Name'
      }
      if(InputWeb?.USERS?.length==0){
        obj_error["USERS"]='Please Select a User'
      }
      if(Object.keys(obj_error)?.length>0){
        setLoaderSubmit(false)
       return setValidated(obj_error)

      }else{
        setValidated({})
      }
      setLoaderSubmit(true)
        if(EditId){
          const data=await  updateWebTabs({...InputWeb,updated_by:userInfo?.UNIQUE_ID,status:1},EditId)
          if(data.success==true){
            toast.success('WebTab Updated successfully')
            setisWebAddModal(false)
            setEditId('')
            setInputWeb({URL:'',NAME:'',USERS:[],DESCRIPTION:''})
            getWebTabs()
            setLoaderSubmit(false)
            setValidated({})
        }else{
          toast.success('Server Issue')
          setLoaderSubmit(false)
          setValidated({})

        }
        }else{
        const data=await createWebTab({...InputWeb,created_by:userInfo?.UNIQUE_ID})
        if(data.success==true){
            toast.success('WebTab created successfully')
            setisWebAddModal(false)
            setInputWeb({URL:'',NAME:'',USERS:[],DESCRIPTION:''})
            setValidated({})
            getWebTabs()
            setLoaderSubmit(false)
        }else{
          toast.success('Server Issue')
          setLoaderSubmit(false)
          setValidated({})
        }
    }
    }

    const getWebTabs=async()=>{
        const data=await getAllWebTabs({USER:userInfo?.UNIQUE_ID,ROLE:userInfo?.ROLE})
        if(data.success){
            setTabList(data.data.data)
            if(Object.keys(TabDetail)?.length>0){
              setTabDetail(data?.data?.data?.filter((item)=>(TabDetail?.unique_id==item?.unique_id))[0])
              document.getElementById(TabDetail?.unique_id).src += ''
            }else{
              if(data?.data?.data?.length>0){
              setTabDetail(data?.data?.data[0])
              setButtontext(data?.data?.data[0]?.unique_id)
              }

            }
        }else{

        }
    }

    const onHandalebtn = (text) => {
		setButtontext(text?.unique_id);
        setTabDetail(text)
    }

    const onEditHandal=async()=>{
        setInputWeb({URL:TabDetail?.web_tab_url,NAME:TabDetail?.web_tab_name,USERS:JSON.parse(TabDetail?.access_user_list||'[]'),DESCRIPTION:TabDetail?.web_tab_description})
        setEditId(TabDetail?.unique_id)
        setisWebAddModal(true)

    }

    const ReloadIfram=(id)=>{
        document.getElementById(id).src += ''
    }
  return (
    <>
    <Modal show={isWebAddModal} onHide={()=>{setisWebAddModal(false)
            setInputWeb({URL:'',NAME:'',USERS:[],DESCRIPTION:''})
            setEditId('')
        }} >
                <Modal.Header closeButton>
                    <Modal.Title>
                    <h4 className="mb-0">Web Tab</h4>
                    <p class='!tw-text-[14px]'>Add websites important to your network members as web tabs.</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div >
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title2" class="tw-w-3/5 col-form-label tw-font-medium"><b>URL</b></label>
                            <input
                                class="form-control" name="tag" id="tag_name" type="text" placeholder="https://abcd.com/" required=""
                                onChange={(e)=>(setInputWeb({...InputWeb,URL:e.target.value}))}
                                value={InputWeb?.URL}
                            />
														<p className="text-danger">{Validated?.URL || ""}</p>
                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title2" class="tw-w-3/5 col-form-label tw-font-medium"><b>Name</b></label>
                            <input
                                class="form-control" name="tag" id="tag_name" type="text" placeholder="Give Your tab a name" required=""
                                onChange={(e)=>(setInputWeb({...InputWeb,NAME:e.target.value}))}
                                value={InputWeb?.NAME}
                            />
														<p className="text-danger">{Validated?.NAME || ""}</p>

                        </div>
                        <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                                <label for="assign-to" class="col-form-label required"><b> Users</b></label>
                                <div class="">
                                    <Select isClearable placeholder='Select User...' id='assign-to'  name='account' onChange={(e)=>(setInputWeb({...InputWeb,USERS:e.map((item)=>item.value)}))} options={UserList}  isMulti value={UserList?.filter((items) => (InputWeb?.USERS.includes(items.value) ))}/>
                                </div>
														<p className="text-danger">{Validated?.USERS || ""}</p>

                            </div>
                            <div class="tw-flex tw-flex-col tw-flex-wrap tw-mb-4">
                            <label for="title1" class="tw-w-3/5 col-form-label tw-font-medium"><b>Description</b>(Optional)</label>
                            <textarea
                                class="form-control" name="tag" id="tag_name" type="text" placeholder="Description" required=""  onChange={(e)=>(setInputWeb({...InputWeb,DESCRIPTION:e.target.value}))}
                                value={InputWeb?.DESCRIPTION}
                            />
                            
                        </div>
                        <button type="button"  className={`btn btn-primary mr-2 ${LoaderSubmit?'disabled btn-progress':''}`} onClick={onSubmitWebTab}>
                            Submit
                        </button>
                    </div>
                </Modal.Body>
                </Modal>
      <div className="main-content" style={{ minHeight: "562px" }}>
        {/* <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/group-dashboard">
                <i className="fas fa-tachometer-alt"></i> Home
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/group-dashboard">
                <span> configuration Dashboard</span>
              </Link>
            </li>
          </ol>
        </nav> */}
        <div className="row">
          <div className="col-12 col-sm-12 col-lg-12">
            <div className="card ">
              <div className="card-header d-flex justify-content-between">
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "100%" }}
                >
                  <ul
                    class="nav nav-pills"
                    id="myTab3"
                    role="tablist"
                    style={{
                      width: "1500px",
                      overflowX: "scroll",
                      flexWrap: "nowrap",
                    }}
                  >
                    {TabList?.length==0&&<h4>Web Tabs Not Found</h4>}
                                                  {TabList?.length>0&&TabList?.map((item,id)=>(
                                                    <li class="nav-item mr-2 ">
														<button
										style={{
											backgroundColor: TabList.findIndex((data) => data?.unique_id === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
											color: TabList.findIndex((data) => data?.unique_id === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
											borderBottom: `1px solid ${btnColor[id]}`,
											boxShadow: TabList.findIndex((data) => data?.unique_id === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}`  : '',
										}}
										className={`!tw-py-[2px] tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${TabList.findIndex((data) => data?.unique_id === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
										onClick={() => onHandalebtn(item)}>
										{item?.web_tab_name}
									</button>
                                                        </li>
                                                  ))}  

                  </ul>
                  <div className="card-header-action d-flex">
                  {Object?.keys(TabDetail)?.length>0&&
                  <><a href={TabDetail?.web_tab_url}  target='_blank' class="btn btn-outline-primary mr-2" style={{marginTop:'6px'}}><i className="fas fa-external-link-alt"></i></a>
                  <a class="btn btn-outline-danger mr-2" style={{marginTop:'6px'}} onClick={()=>(ReloadIfram(TabDetail?.unique_id))}><i className="fas fa-redo-alt"></i></a>
                  <a class="btn btn-outline-success mr-2" style={{marginTop:'6px'}} onClick={onEditHandal}><i className="fas fa-cog"></i></a></>}
											<BootstrapTooltip title="Add Tab" placement="top">
												<span
													className={`btn btn-primary`}
													style={{ height: "30px", padding: "2px 9px", marginTop: "6px" }}
													onClick={()=>setisWebAddModal(true)}
												>
													<i
														className="fas fa-plus "
														style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
													></i>
												</span>
											</BootstrapTooltip>
										</div>
                </div>
              </div>
              <>
              {btnText!==''&&
            <div class="card-body" style={{padding:'0px',borderTop:'1px solid #d5d5d5'}}>
            <iframe purpose="webTabIframe" src={TabDetail?.web_tab_url} configid={TabDetail?.unique_id}  allow="fullscreen;geolocation;microphone;camera;display-capture" style={{width:'100%',height:'100vh'}} id={TabDetail?.unique_id}></iframe>
            </div>
            }
              </>
            </div>
          </div>
        </div>
        {/* {btnText!==''&&<div className="row">
          <div className="col-12 col-sm-12 col-lg-12">
            <div className="card ">
            <div className="card-header">
                <h4>{TabDetail?.web_tab_name}</h4>
            <div className="card-header-action d-flex">
            <a href={TabDetail?.web_tab_url}  target='_blank' class="btn btn-outline-primary mr-2"><i className="fas fa-external-link-alt"></i></a>
            <a class="btn btn-outline-danger mr-2" onClick={()=>(ReloadIfram(TabDetail?.unique_id))}><i className="fas fa-redo-alt"></i></a>
            <a class="btn btn-outline-success" onClick={onEditHandal}><i className="fas fa-cog"></i></a>
                </div>
            </div>
            <div class="card-body" style={{padding:'0px'}}>
            <iframe purpose="webTabIframe" src={TabDetail?.web_tab_url} configid={TabDetail?.unique_id}  allow="fullscreen;geolocation;microphone;camera;display-capture" style={{width:'100%',height:'100vh'}} id={TabDetail?.unique_id}></iframe>
            </div>
            </div>
            </div>
            </div>} */}
      </div>
    </>
  );
}

