import chroma from 'chroma-js'
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import BadDataAnalytice from './BadDataAnalytice';
import MissingDataAnalytice from './MissingDataAnalytice';
import MissingDataInUserAndRoles from './MissingDataInUserAndRoles';

export default function DataAnalytice() {
    const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
    const [loader, setLoader] = useState(false);
    const [activeTab, setActiveTab] = useState("Missing Data Analytics");
    const [SubactiveTab, setSubActiveTab] = useState("Consumer");

    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    return (
        <>
            <div className="main-content">
                {loader ? (
                    <div className="main-content">
                        <div class="loader"></div>
                    </div>
                ) : (
                    ""
                )}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-data">
                            <Link to="/all-dashboard">
                                <i className="fas fa-tachometer-alt"></i> Home/
                            </Link>
                        </li>
                        <li className="breadcrumb-data">
                            <Link >Data Analytics</Link>
                        </li>
                    </ol>
                </nav>
                <section class="section">
                    <div class="section-body">
                        <div class="row">

                            <div class="col-12 col-sm-12 col-lg-12 tw-p-0">
                                <div class="card ">
                                    <div class="card-header d-flex tw-border-b-1 " style={{ justifyContent: 'space-between' }}>
                                        <ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: '20px', alignItems: 'center' }}>
                                        <li>
                                                <button style={{
                                                    backgroundColor: activeTab === "Missing Data Analytics" ? chroma(btnColor[2]).alpha(0.4).css() : '',
                                                    color: activeTab === "Missing Data Analytics" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
                                                    borderBottom: `1px solid ${btnColor[2]}`,
                                                    boxShadow: activeTab === "Missing Data Analytics" ? `0 2px 8px -3px ${btnColor[2]}` : '',
                                                }}
                                                    className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Missing Data Analytics" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={() => (setActiveTab('Missing Data Analytics'))}>Missing Data Analytics</button>
                                            </li>
                                            <li>
                                                <button style={{
                                                    backgroundColor: activeTab === "Bad Data Analytics" ? chroma(btnColor[0]).alpha(0.4).css() : '',
                                                    color: activeTab === "Bad Data Analytics" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
                                                    borderBottom: `1px solid ${btnColor[0]}`,
                                                    boxShadow: activeTab === "Bad Data Analytics" ? `0 2px 8px -3px ${btnColor[0]}` : '',
                                                }}
                                                    className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Bad Data Analytics" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => (setActiveTab('Bad Data Analytics'))}>Bad Data Analytics</button>
                                            </li>
                                            
                                        </ul>

                                    </div>
                                    <ul class="nav nav-pills tw-gap-2 ml-4 mb-2" role="tablist" style={{ lineHeight: '20px', alignItems: 'center' }}>
											<li>
												<button style={{
													backgroundColor: SubactiveTab === "Consumer" ? chroma(btnColor[0]).alpha(0.4).css() : '',
													color: SubactiveTab === "Consumer" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
													borderBottom: `1px solid ${btnColor[0]}`,
													boxShadow: SubactiveTab === "Consumer" ? `0 2px 8px -3px ${btnColor[0]}` : '',
												}}
													className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${SubactiveTab === "Consumer" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => (setSubActiveTab('Consumer'))}>Consumer</button>
											</li>
											<li>
												<button style={{
													backgroundColor: SubactiveTab === "User&Roles" ? chroma(btnColor[2]).alpha(0.4).css() : '',
													color: SubactiveTab === "User&Roles" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
													borderBottom: `1px solid ${btnColor[2]}`,
													boxShadow: SubactiveTab === "User&Roles" ? `0 2px 8px -3px ${btnColor[2]}` : '',
												}}
													className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${SubactiveTab === "User&Roles" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={() => (setSubActiveTab('User&Roles'))}>User&Roles</button>
											</li>
                                            
										</ul>
                                </div>
                            </div>
                                            {activeTab==='Missing Data Analytics'&&SubactiveTab==='Consumer'&&<MissingDataAnalytice/>}
                                            {activeTab==='Missing Data Analytics'&&SubactiveTab==='User&Roles'&&<MissingDataInUserAndRoles/>}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
