import { DatePicker, Modal, Space, Popover } from 'antd'
import React, { useState } from 'react'
import { Users } from 'react-feather'
import { Link } from 'react-router-dom'
import { BootstrapTooltip, TaskUrl } from '../../../../Commoncomponet/common'
import chroma from 'chroma-js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { usePopper } from 'react-popper';
import { toast, Slide } from "react-toastify";
import './ConsumerTask.css'
import moment from 'moment'
import { activeandinactive, getAccount, getAllTaskDetails, getAllTaskDetailsByConsumerId, getAllTaskDetailsByUniqueId, getAreaById, getCircleById, getDivisionById, getFilteredConsumerTaskDetails, getFilteredTaskDetails, getGaById, getGSMConsumerList, getProfilNameByAccountId, getSubdevisionById, getTaskCommentAndHistoryById, getTaskTag, geturls3, getUsersList, getUsersListForTask, InsertsTaskDetails, Taskuploads, UpdateConsumerStatusInMaintanace, UpdateLorawanConsumerStatusInMaintanace, UpdateTaskDetailesComentAndHistory, UpdateTaskDetails, uploadsDeleteFile, uploadsFiles } from '../../../../Commoncomponet/Utils'
import { useEffect } from 'react'
import Pagination from '../../../../Commoncomponet/Pagination'
import fileDownload from 'js-file-download'
import ConsumerTaskFilter from './ConsumerTaskFilter'
import GoogleLoader from '../../../../Commoncomponet/Loaders/GoogleLoader'
import { ReactComponent as NoTaskFoundSVG } from '../../../../assets/SVGs/No_tasks.svg'
import ButtonLoader from '../../../../Commoncomponet/Loaders/ButtonLoader'


export default function ConsumerTask(props) {
    const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false)
    const [isSideExapnded, setIsSideExpanded] = useState(false)
    const [priority, setPriority] = useState('Medium')
    const [status, setStatus] = useState('Not Started')
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [taskDetails, setTaskDetails] = useState({ Title: '', description: '', assignto: '', consumer: '', duedate: '', priority: '', consumer_type: '', task_type: '', Maintenance: false, tags: [] })
    const [flag, setFlag] = useState(false);
    const [Errors, setValidation] = useState({});
    const [UserList, setUserList] = useState([])
    const [ConsumerList, setConsumerList] = useState([])
    const [TaskList, setTaskList] = useState([])
    const [PageSize, setPageSize] = useState(50)
    const [numberofPage, setnumberofPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [TaskDetailsById, setTaskDetailsById] = useState({});
    const [UpdateId, setUpdateId] = useState('')
    const [Comment, setComment] = useState('')
    const userInfo = JSON.parse(window.localStorage.getItem("user_info") || {})
    const [commentData, setcommentData] = useState([])
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: "arrow",
                options: {
                    element: arrowElement,
                }
            },
            {
                name: "offset",
                options: {
                    offset: [60, 10]
                }
            },
            {
                name: 'flip',
                enabled: false,
            },
        ]
    });
    const [showPopper, setShowPopper] = useState(false);
    const [showAntdPopOver, setShowAntdPopover] = useState(false)
    const [files, setFiles] = useState([])
    const [sideFiles, setSideFiles] = useState([])
    const [taskTags, setTaskTags] = useState([])
    const [totalUserList, setTotalUserList] = useState([])
    const [filteredData, setFilteredData] = useState({})
    const [isFilterLoading, setIsFilterLoading] = useState(false)
    const [searchLoader, setsearchLoader] = useState(false)


    const handleOpenChange = (newOpen) => {
        setShowAntdPopover(!showAntdPopOver);
    };
    const hide = () => {
        setShowAntdPopover(false);
    };

    const ongetUsersList = async () => {
        const data = await getUsersList()
        if (data.success === true) {
            setUserList(data.data.data)
        } else {
            console.log('something want wrong')
        }
    }

    const ongetGSMConsumerList = async (info) => {
        setsearchLoader(true)

        const data = await getGSMConsumerList(info)
        if (data.success === true) {
            setConsumerList(data.data.data)
            setsearchLoader(false)
        } else {
            console.log('something want wrong')
            setsearchLoader(false)
        }
    }
    const handleFile = (e) => {

        let totalFiles = (Object.entries(e.target.files).length + files.length)
        if (totalFiles < 4) {
            console.log(e.target.files);
            Object.entries(e.target.files).map(([key, value]) => {
                let isExist = files.some(fileItem => fileItem.name === value.name && fileItem.lastModified == value.lastModified)
                if (!isExist) {
                    if (value.size < 2e+7) {
                        setFiles(files => [...files, value])
                    }
                    else {
                        toast.warn("Maximum file size allowed is 20 MB, please try with different file.", {
                            position: toast.POSITION.TOP_CENTER,
                            transition: Slide,
                            theme: "colored",
                            autoClose: false,
                        });
                    }
                }
                else {
                    toast.info('You have already selected this file. Please select different file', {
                        position: toast.POSITION.TOP_CENTER,
                        transition: Slide,
                        theme: "colored",
                        autoClose: false,
                    })
                }
            })
        }
        else {
            toast.warn(`You can upload only a maximum of 3 files`, {
                position: toast.POSITION.TOP_CENTER,
                transition: Slide,
                theme: "colored",
                autoClose: false,
            });
            // alert(`You can only select 3 files maximum... But You have selected ${totalFiles}.`)
        }
    }

    const handleSideFiles = (e) => {
        let totalFiles = (Object.entries(e.target.files).length + sideFiles.length)
        if (totalFiles < 4) {
            Object.entries(e.target.files).map(async ([key, value]) => {
                let isExist = sideFiles.some(fileItem => fileItem.name === value.name)
                console.log("isExist === ", isExist)

                if (!isExist) {
                    if (value.size < 2e+7) {
                        // setSideFiles(sideFiles => [...sideFiles, value])
                        var attechmentsobj = sideFiles
                        const formData = new FormData()
                        formData.append('Images', value)
                        const uploadImges = await Taskuploads(formData)
                        console.log(attechmentsobj);
                        if (uploadImges.success === true) {
                            attechmentsobj.push({ SRNO: attechmentsobj[attechmentsobj?.length - 1]?.SRNO || 0 + 1, file: uploadImges.data.originalname, size: value?.size })
                            await activeandinactive({ tableName: 'consumer_task_index', colName: 'ATTACHMENT', value: `'${JSON.stringify(attechmentsobj)}'`, matchId: 'TASK_UNIQUE_ID' }, TaskDetailsById?.TASK_UNIQUE_ID)
                            onGetTaskByUniqueId(TaskDetailsById?.TASK_UNIQUE_ID)

                        } else {

                        }

                    }
                    else {
                        toast.warn("Maximum file size allowed is 20 MB, please try with different file.", {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Slide,
                            theme: "colored",
                            autoClose: false,
                        });
                    }
                }
                else {
                    toast.info('You have already selected this file. Please select different file', {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Slide,
                        theme: "colored",
                        autoClose: false,
                    })
                }
            })
        }
        else {
            toast.warn(`You can upload only a maximum of 3 files`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Slide,
                theme: "colored",
                autoClose: false,
            });
        }

    }

    const deleteSelectedFile = (id) => {
        setFiles(files.filter(item => id != item))
    }
    function formatBytes(bytes) {
        var marker = 1024; // Change to 1000 if required
        var decimal = 2; // Change as required
        var kiloBytes = marker; // One Kilobyte is 1024 bytes
        var megaBytes = marker * marker; // One MB is 1024 KB
        var gigaBytes = marker * marker * marker; // One GB is 1024 MB

        // return bytes if less than a KB
        if (bytes < kiloBytes) return bytes + " Bytes";
        // return KB if less than a MB
        else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
        // return MB if less than a GB
        else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";
    }

    const onDeleteFile = async (file, SRNO) => {
        const data = await uploadsDeleteFile({ path: `TASK_ATTACHMENTS/${file}` })
        if (data.success == true) {
            const filterData = sideFiles.filter((item) => (item.SRNO !== SRNO))
            const data = await activeandinactive({ tableName: 'consumer_task_index', colName: 'ATTACHMENT', value: `'${JSON.stringify(filterData)}'`, matchId: 'TASK_UNIQUE_ID' }, TaskDetailsById?.TASK_UNIQUE_ID)
            if (data.success === true) {
                onGetTaskByUniqueId(TaskDetailsById?.TASK_UNIQUE_ID)
            } else {
            }
        }
    }
    const onOpenAddTaskModal = () => {
        setIsAddTaskModalOpen(true)
        ongetUsersList()
        ongetTaskTags()
        ongetGSMConsumerList({ type: 'GSM' })
        onHandaleSelection()
        setProfile({ account: props?.GSMdata?.CAT0_ID || '', ga: props?.GSMdata?.CAT1_ID || '', circle: props?.GSMdata?.CAT2_ID || '', division: props?.GSMdata?.CAT3_ID || '', subdivision: props?.GSMdata?.CAT4_ID || '', area: props?.GSMdata?.CAT5_ID || '' })
    }

    const onModelCancel = () => {
        setIsAddTaskModalOpen(false)
        setFiles([])
        setTaskDetails({ Title: '', description: '', assignto: '', consumer: '', duedate: '', priority: '', consumer_type: '', task_type: '', Maintenance: false, tags: [] })
        setValidation({})
        setUpdateId('')
    }
    const onDownloadFiles = async (name) => {
        const data1 = await geturls3({ bucketName: 'gums.memighty.com', objectKey: `TASK_ATTACHMENTS/${name}`, expiration: 60 })
        console.log(data1);
        var link = document.createElement("a");
        link.href = data1.data.data;
        link.download = name;
        link.click();
    }
    // const onUpdateTaskDetails=async()=>{
    // }

    const consumerSelectStyle = {
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: (base, state) => ({
            ...base,
            border: '1px solid #e4e6fc',
            boxShadow: state.isFocused ? "0 0 0 1px #8179f0" : 0,
            '&:hover': {
                border: '1px solid #e4e6fc',
                cursor: 'text'
            }
        }),
        clearIndicator: (base, state) => ({
            ...base,
            cursor: 'pointer'
        })
    }

    const taskSelectStyle = {
        menu: provided => ({
            ...provided, width: "max-content",
            minWidth: "100%", zIndex: 9999
        }), control: (base, state) => ({
            ...base,
            border: '1px solid #e4e6fc',
            boxShadow: state.isFocused ? "0 0 0 1px #8179f0" : 0,
            '&:hover': {
                border: '1px solid #e4e6fc',

            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
                color: isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
                    color: 'black'
                },
            };
        },
        singleValue: (provided, { data }) => {
            const color = chroma(data.color);
            return {
                ...provided,
                width: 'max-content',
                padding: '2px 4px',
                borderRadius: '4px',
                backgroundColor: color.alpha(0.2).css(),
                color: data.color,
            };
        },
        clearIndicator: (base, state) => ({
            ...base,
            cursor: 'pointer'
        })
    }

    const customStyles = {
        menu: provided => ({
            ...provided, width: "max-content",
            minWidth: "100%", zIndex: 9999
        }), control: (base, state) => ({
            ...base,
            border: '1px solid #e4e6fc',
            boxShadow: state.isFocused ? "0 0 0 1px #8179f0" : 0,
            '&:hover': {
                border: '1px solid #e4e6fc',

            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
                color: isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
                    color: 'black'
                },
            };
        },
        multiValue: (provided, { data }) => {
            console.log("Colorr ====== ", data)
            const color = chroma(data.color);
            return {
                ...provided,
                width: 'min-content',
                backgroundColor: color.alpha(0.2).css(),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),


    }
    const getAllUsers = async () => {
        const users = await getUsersListForTask()
        if (users.success === true) {
            setTotalUserList(users.data.data.map((item) => ({ value: item?.UNIQUE_ID, label: item?.FIRST_NAME + " " + item?.LAST_NAME, filtervalue: { taskAssignTo: item?.UNIQUE_ID } })))
        }
        else {
            console.log('Error in getting users', users.err)
        }
    }
    //get tasktags
    const ongetTaskTags = async () => {
        const data = await getTaskTag(true)
        if (data.success === true) {
            setTaskTags(data.data.data.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME, color: item?.TAG_COLOR })))
        } else {
            console.log('Error in getting task tags', data.err)
        }
    }
    const taskStatus = [
        { value: '-1', label: 'Not Started', color: '#fc544b' },
        { value: '0', label: 'In Progress', color: '#ffa426' },
        { value: '1', label: 'Completed', color: '#54ca68' }
    ]
    const taskPriority = [
        { value: 'High', label: 'High', color: '#fc544b' },
        { value: 'medium', label: 'Medium', color: '#ffa426' },
        { value: 'low', label: 'Low', color: '#54ca68' }
    ]

    const validation = (name, value) => {
        switch (name) {
            case "Title":
                if (!value) {
                    return "Please input Title!";
                } else {
                    return "";
                }

            case "assignto":
                if (!value) {
                    return "Please select assignto!";
                } else {
                    return "";
                }


            default: {
                return null;
            }
        }
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();

        let allErrors = {};

        Object.keys(taskDetails).forEach((key) => {
            const error = validation(key, taskDetails[key]);
            if (error && error.length) {
                allErrors[key] = error;
            }
        });
        var objProfile = { CATEGORY0: Profile?.account, CATEGORY1: Profile?.ga, CATEGORY2: Profile?.circle, CATEGORY3: Profile?.division, CATEGORY4: Profile?.subdivision, CATEGORY5: Profile?.area }
        if (Object.keys(allErrors).length) {
            setFlag(false);
            return setValidation(allErrors);

        } else {
            if (taskDetails.Maintenance === true) {
                await activeandinactive({ tableName: 'gsm_consumer_device_index', colName: 'CUSTOMER_STATUS', value: `2`, matchId: 'UNIQUE_ID' }, taskDetails?.consumer)
            }
            if (UpdateId === '') {
                const attechmentsobj = []

                if (files.length > 0) {
                    await new Promise(async (resolve) => files.length > 0 && files.map(async (item, id) => {
                        if (item) {
                            const formData = new FormData()
                            formData.append('Images', item)
                            const uploadImges = await Taskuploads(formData)

                            if (uploadImges.success === true) {
                                attechmentsobj.push({ SRNO: id + 1, file: uploadImges.data.originalname, size: item?.size })
                            } else {
                                attechmentsobj.push({ SRNO: id + 1, files: '' })
                            }
                        } else {
                            attechmentsobj.push({ SRNO: id + 1, files: '' })
                        }
                        if (attechmentsobj.length === files.length) {
                            resolve()
                        }
                    })
                    )
                }
                if (attechmentsobj.length === files.length) {
                    const data = await InsertsTaskDetails({ ...taskDetails, tags: taskDetails.tags.map(item => item.value), CREATED_BY: userInfo?.UNIQUE_ID, attechmentsobj: JSON.stringify(attechmentsobj), Filter_object: JSON.stringify(objProfile) })
                    if (data.success === true) {
                        ongetTaskList(true)
                        toast.success('Task Created', {
                            position: toast.POSITION.TOP_CENTER,
                            transition: Slide,
                            theme: "colored",
                        })
                        setFiles([])
                        setIsAddTaskModalOpen(false)
                        setTaskDetails({ Title: '', description: '', assignto: '', consumer: '', duedate: '', priority: '', consumer_type: '', task_type: '', Maintenance: false, tags: [] })
                        setFlag(false);
                        setValidation({})
                    } else {
                        toast.error('Server Issue', {
                            position: toast.POSITION.TOP_CENTER,
                            transition: Slide,
                            theme: "colored",
                        })
                        setFlag(false);
                        setValidation({})

                    }
                }
            } else {
                const data = await UpdateTaskDetails({ ...taskDetails, tags: taskDetails.tags.map(item => item.value), UPDATED_BY: userInfo?.UNIQUE_ID, Filter_object: JSON.stringify(objProfile) }, UpdateId)
                if (data.success === true) {
                    ongetTaskList(true)
                    toast.success('Task Updated', {
                        position: toast.POSITION.TOP_CENTER,
                        transition: Slide,
                        theme: "colored",
                    })
                    setIsAddTaskModalOpen(false)
                    setTaskDetails({ Title: '', description: '', assignto: '', consumer: '', duedate: '', priority: '', consumer_type: '', task_type: '', Maintenance: false, tags: [] })
                    setFlag(false);
                    setValidation({})
                    setUpdateId('')
                    onCommentTask(TaskDetailsById?.TASK_UNIQUE_ID)
                    onGetTaskByUniqueId(TaskDetailsById?.TASK_UNIQUE_ID)
                } else {
                    toast.error('Server Issue', {
                        position: toast.POSITION.TOP_CENTER,
                        transition: Slide,
                        theme: "colored",
                    })
                    setFlag(false);
                    setValidation({})

                }

            }
        }

    }

    const ongetTaskList = async (ifFilter) => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        let data
        if (ifFilter) {
            data = await getFilteredConsumerTaskDetails({ start: firstPageIndex, end: PageSize, consumerId: props?.GSMdata?.UNIQUE_ID, filters: filteredData })
        }
        else {
            data = await getAllTaskDetailsByConsumerId({ start: firstPageIndex, end: PageSize, ID: props?.GSMdata?.UNIQUE_ID })
        }

        if (data.success === true) {
            console.log("Data", data.data.data)
            setTaskList(data.data.data)
            setnumberofPage(data.data.totalCount)

        } else {
            console.log('something want wrong')
        }
    }

    useEffect(() => {
        ongetTaskList()
        setTaskDetails({ ...taskDetails, consumer_type: 'GSM', consumer: props?.GSMdata?.UNIQUE_ID })
        ongetTaskTags()
        getAllUsers()

    }, []);

    const onGetTaskByUniqueId = async (UniqueId) => {
        const data = await getAllTaskDetailsByUniqueId(UniqueId)
        if (data.success == true) {
            setIsSideExpanded(true)
            setTaskDetailsById(data.data.data)
            setSideFiles(JSON.parse(data?.data?.data?.ATTACHMENT || '[]'))
        } else {
            console.log('something want wrong')
        }
        onCommentTask(UniqueId)
    }
    const onactive = async (value, UID, type, isUnderMaintenance) => {
        const data = await activeandinactive({ tableName: 'consumer_task_index', colName: 'TASK_STATUS', value: value, matchId: 'TASK_UNIQUE_ID' }, UID)
        if (data.success === true) {
            onGetTaskByUniqueId(TaskDetailsById?.TASK_UNIQUE_ID)
            ongetTaskList()

        } else {
        }
        if (value == '1' && type === "GSM" && isUnderMaintenance) {
            await UpdateConsumerStatusInMaintanace(TaskDetailsById?.CONSUMER_ID)
            // await activeandinactive({tableName:'gsm_consumer_device_index',colName:'CUSTOMER_STATUS',value:`1`,matchId:'UNIQUE_ID'},taskDetails?.consumer)
        } else if (value == '1' && type === "LORAWAN" && isUnderMaintenance) {
            await UpdateLorawanConsumerStatusInMaintanace(TaskDetailsById?.CONSUMER_ID)
        }


    }
    const onCommentTask = async (id) => {
        const data = await getTaskCommentAndHistoryById({ UNIQUE_ID: id })
        if (data.success == true) {
            setcommentData(data.data.data)
        } else {
            console.log('something want wrong')
            setcommentData([])
        }
    }
    const onEditTask = () => {
        setIsAddTaskModalOpen(true)
        setTaskDetails({ assignto: TaskDetailsById?.TASK_ASSIGN_TO, consumer: TaskDetailsById?.CONSUMER_ID, consumer_type: TaskDetailsById?.TASK_CONSUMER_TYPE, description: TaskDetailsById?.TASK_DESCRIPTION, duedate: moment(TaskDetailsById?.DUE_DATE).format('DD-MM-YYYY'), priority: TaskDetailsById?.TASK_PRIORITY, Title: TaskDetailsById?.TASK_TITLE, task_type: TaskDetailsById?.TASK_TYPE, tags: TaskDetailsById?.TASK_TAGS?.map(item => ({ value: item?.TASK_TAGS_ID, label: item?.TAG_NAME, color: item?.TAG_COLOR })), Maintenance: TaskDetailsById?.IS_UNDER_MAINTENANCE })
        setUpdateId(TaskDetailsById?.TASK_UNIQUE_ID)
        if (UserList.length === 0) {
            ongetUsersList()
        }
        onHandaleSelection()

        const profileObj = JSON.parse(TaskDetailsById?.FILTER_OBJECT || '{}')
        setProfile({ account: profileObj?.CATEGORY0 || '', ga: profileObj?.CATEGORY1 || '', circle: profileObj?.CATEGORY2 || '', division: profileObj?.CATEGORY3 || '', subdivision: profileObj?.CATEGORY4 || '', area: profileObj?.CATEGORY5 || '' })
        ongetGSMConsumerList({ type: TaskDetailsById?.TASK_CONSUMER_TYPE })

    }

    const option_Type = [{ value: 'GSM', label: 'GSM' }, { value: 'LORAWAN', label: 'LORAWAN' }]
    const animatedComponents = makeAnimated();

    const addCommandTask = async () => {
        const data = await UpdateTaskDetailesComentAndHistory({ comment: { comment: Comment, created_by: userInfo?.UNIQUE_ID, created_at: moment() } }, TaskDetailsById?.TASK_UNIQUE_ID)
        if (data.success === true) {
            setComment('')
            onCommentTask(TaskDetailsById?.TASK_UNIQUE_ID)
        } else {
            console.log('something want wrong')
        }
    }

    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" });
    const [SubdevisionData, setSubdevisionData] = useState([]);
    const [DivisionData, setDivisionData] = useState([]);
    const [CircleData, setCircleData] = useState([]);
    const [GaData, setGaData] = useState([]);
    const [AccounData, setAccountData] = useState([]);
    const [AreaData, setAreaData] = useState([]);
    const buttonArray = ["All Tasks", "Task Dashboard"];
    const [btnText, setButtontext] = useState("All Tasks");
    const onHandalebtn = (text) => {
        setButtontext(text);
    };
    const [NameObj, setNameObj] = useState({})
    const ongetProfilNameByAccountId = async (id) => {
        const data = await getProfilNameByAccountId({ ID: id })
        if (data.success === true) {
            setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
        } else {
            console.log('something want wrong')
        }
    }
    const onHandaleSelection = async () => {
        const data = await getAccount();
        if (data.success === true) {
            setAccountData(data.data.data);
        } else {
            console.log("somthing want wrong");
        }
    };
    const ongetGaById = async (id) => {
        const data1 = await getGaById(id);
        if (data1.success === true) {
            setGaData(data1.data.data);
        }
    };

    const ongetCircle = async (id) => {
        const data1 = await getCircleById(id);
        if (data1.success === true) {
            setCircleData(data1.data.data);
        }
    };
    const ongetSubdevisionById = async (id) => {
        const data1 = await getSubdevisionById(id);
        if (data1.success === true) {
            setSubdevisionData(data1.data.data);
        }
    };
    const ongetDivisionById = async (id) => {
        const data3 = await getDivisionById(id);
        if (data3.success === true) {
            setDivisionData(data3.data.data);
        }
    };
    const ongetArea = async (id) => {
        const data3 = await getAreaById(id);
        if (data3.success === true) {
            setAreaData(data3.data.data);
        }
    };

    useEffect(() => {
        if (Profile.account !== "") {
            ongetGaById(Profile.account);
            setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([])
            ongetProfilNameByAccountId(Profile.account)

        } else {
            setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
            setGaData([]);
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);
            setNameObj({})
        }
    }, [Profile.account]);

    useEffect(() => {
        if (Profile.ga !== "") {
            ongetCircle(Profile.ga);
            setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);

        } else {
            setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
            setCircleData([]);
            setDivisionData([]);
            setSubdevisionData([]);
            setAreaData([]);

        }
    }, [Profile.ga]);

    useEffect(() => {
        if (Profile.circle !== "") {
            ongetDivisionById(Profile.circle);
            setProfile({ ...Profile, division: "", subdivision: "", area: "" });
            setAreaData([]);
            setSubdevisionData([]);

        } else {
            setProfile({ ...Profile, division: "", subdivision: "", area: "" });
            setAreaData([]);
            setDivisionData([]);
            setSubdevisionData([]);


        }
    }, [Profile.circle]);

    useEffect(() => {
        if (Profile.division !== "") {
            ongetSubdevisionById(Profile.division);
            setAreaData([]);
            setProfile({ ...Profile, subdivision: "", area: "" });

            setProfile({ ...Profile, subdivision: "", area: "" });
            setSubdevisionData([]);
            setAreaData([]);

        }
    }, [Profile.division]);

    useEffect(() => {
        if (Profile.subdivision !== "") {
            ongetArea(Profile.subdivision);
            setProfile({ ...Profile, area: "" });

        } else {
            setProfile({ ...Profile, area: "" });
            setAreaData([]);

        }
    }, [Profile.subdivision]);

    const getFilteredData = async (newFilter) => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        setIsFilterLoading(true)
        // const filteredData = await getFilteredTaskDetails({ filters: { ...selectedDropdownFilter?.filtervalue, ...selectedPriority?.filtervalue, ...selectedUser?.filtervalue }, start: firstPageIndex, end: PageSize })

        const filteredData = await getFilteredConsumerTaskDetails({ filters: newFilter, start: firstPageIndex, end: PageSize, consumerId: props?.GSMdata?.UNIQUE_ID })
        if (filteredData.success === true) {
            setTaskList(filteredData.data.data)
            setnumberofPage(filteredData.data.total)
            setIsFilterLoading(false)
        } else {
            setIsFilterLoading(false)
            console.log('Error:', filteredData.err)
        }
    }

    const handleFilter = (newFilter) => {
        console.log("Filter = ", newFilter)
        setFilteredData(newFilter);
        getFilteredData(newFilter);
    }

    return (
        <>
            <Modal title={UpdateId == '' ? 'Create Task' : 'Update Task'} style={{ top: 0 }} visible={isAddTaskModalOpen} footer='' onCancel={onModelCancel} width={860}>
                <div>
                    <form className='tw-w-full'>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label for="title" class="col-form-label required"> Title <sup className='text-danger'>*</sup> :</label>
                                <div class="">
                                    <input type="text" class="form-control" id="title" placeholder="Enter title" onChange={(e) => { setTaskDetails({ ...taskDetails, Title: e.target.value }) }} value={taskDetails?.Title} />
                                    <span className='text-danger pb-0'>{Errors?.Title || ''}</span>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="assign-to" class="col-form-label">Consumer Type :</label>
                                <div>
                                    <Select isClearable placeholder='Select Consumer...' id='consumer' styles={consumerSelectStyle} components={animatedComponents} name='account' options={option_Type} onChange={(e) => {
                                        setTaskDetails({ ...taskDetails, consumer_type: e?.value || '' })

                                    }} value={option_Type?.filter((items) => (items.value == taskDetails?.consumer_type))[0] || null} />

                                    <span className='text-danger pb-0'>{Errors?.consumer || ''}</span>

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label class="col-form-label">Due Date</label>
                                <div>
                                    <Space style={{ width: "100%", display: "block" }} direction="horizontal">
                                        <DatePicker style={{ width: "100%", padding: "7px 15px", borderColor: "#e4e6fc" }} id='due-date' format='DD-MM-YYYY' name='start' onChange={(e, dateString) => {
                                            setTaskDetails({ ...taskDetails, duedate: dateString })
                                        }} value={taskDetails?.duedate ? moment.utc(taskDetails?.duedate, 'DD-MM-YYYY') : ''} />
                                        <span className='text-danger pb-0'>{Errors?.duedate || ''}</span>
                                    </Space>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label for="assign-to" class="col-form-label required">Assign to <sup className='text-danger'>*</sup> : </label>
                                <div class="">
                                    <Select isClearable placeholder='Select User...' id='assign-to' styles={consumerSelectStyle} components={animatedComponents} name='account' options={UserList} onChange={(e) => { setTaskDetails({ ...taskDetails, assignto: e?.value || '' }) }} value={UserList?.filter((items) => (items.value == taskDetails?.assignto))[0] || null} />

                                    <span className='text-danger pb-0'>{Errors?.assignto || ''}</span>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="task-priority" class="col-form-label tw-font-medium ">Priority:</label>
                                <div class="">
                                    <Select isClearable id='task-priority' styles={taskSelectStyle} components={animatedComponents} options={taskPriority} onChange={(e) => { setTaskDetails({ ...taskDetails, priority: e?.value || '' }) }} value={taskPriority?.filter((items) => (items.value == taskDetails?.priority))[0] || null} />

                                    <span className='text-danger pb-0'>{Errors?.priority || ''}</span>
                                </div>
                            </div>
                            <div
                                class="col-lg-4">
                                <label for="task-status" class="col-form-label tw-font-medium">Tags :</label>
                                <div class="">
                                    <Select placeholder='Select Tags...' isMulti isClearable id='task-tags' styles={customStyles} components={animatedComponents} options={taskTags} onChange={(e) => { setTaskDetails({ ...taskDetails, tags: e.map((item) => (item)) }) }} value={taskDetails?.tags} />
                                </div>
                            </div>
                        </div>


                        <div class="form-group row">
                            <div className='col-lg-4'>
                                <div className='tw-flex tw-items-center tw-gap-2'>

                                    <label for="consumer" class="col-form-label tw-font-medium">Account:</label>
                                    {/* {Profile.subdivision === "" && Profile.division === "" && Profile.circle === "" && Profile.ga === "" && Profile.account !== "" && Profile?.area === '' &&
                                        <div className='tw-cursor-pointer' onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search' ></i></div>} */}
                                </div>
                                <div class="">
                                    <select
                                        class="form-control form-select accountoga ga_calueacc"
                                        name="accountid"
                                        required=""
                                        id="accountid"
                                        onChange={(e) => setProfile({ ...Profile, account: e.target.value })}
                                        value={Profile?.account}
                                    >
                                        <option value="">Select Account</option>
                                        {AccounData &&
                                            AccounData.map((item, id) =>
                                                props?.permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
                                                    (items) => items === item.UNIQUE_ID
                                                ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                    <option key={id} value={item.UNIQUE_ID}>{item.CATEGORY0}</option>
                                                ) : (
                                                    ""
                                                )
                                            )}
                                    </select>
                                </div>

                            </div>
                            {Profile.account !== "" && (<div class="col-lg-4">
                                <div className='tw-flex tw-items-center tw-gap-2'>
                                    <label for="consumer" class="col-form-label tw-font-medium">{NameObj?.CATEGORY1 || 'CATEGORY1'}:</label>
                                    {/* {Profile.subdivision === "" && Profile.division === "" && Profile.circle === "" && Profile.ga !== "" && Profile.account !== "" && Profile?.area === '' ? <div className='tw-cursor-pointer' onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search'></i></div> : ''} */}
                                </div>
                                <div class="">
                                    <select
                                        class="form-control form-select gatocircle ga_calue"
                                        name="address_location_d"
                                        id="address_location_d"
                                        onChange={(e) => setProfile({ ...Profile, ga: e.target.value })}
                                        value={Profile?.ga}
                                    >
                                        <option value="">Select {NameObj?.CATEGORY1 || 'CATEGORY1'}</option>
                                        {GaData &&
                                            GaData.map((item, id) =>
                                                props?.permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter(
                                                    (items) => items === item.CATEGORY1_UNIQUE_ID
                                                ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                    <option key={id} value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option>
                                                ) : (
                                                    ""
                                                )
                                            )}
                                    </select>
                                </div>
                            </div>)}

                            {Profile.ga !== "" && Profile.account !== "" && (
                                <div class="col-lg-4">
                                    <div className='tw-flex tw-items-center tw-gap-2'>
                                        <label for="consumer" class="col-form-label tw-font-medium">{NameObj?.CATEGORY2 || 'CATEGORY2'}:</label>

                                        {/* {Profile.subdivision === "" && Profile.division === "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && Profile?.area === '' && <div className="tw-cursor-pointer" onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search'></i></div>} */}
                                    </div>
                                    <div class="">
                                        <select
                                            class="form-control form-select circletodivision ga_calue"
                                            name="circle_id"
                                            onChange={(e) => setProfile({ ...Profile, circle: e.target.value })}
                                            value={Profile?.circle}>
                                            <option value="">Select {NameObj?.CATEGORY2 || 'CATEGORY2'}</option>
                                            {CircleData &&
                                                CircleData.map((item, id) =>
                                                    props?.permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter(
                                                        (items) => items === item.CATEGORY2_UNIQUE_ID
                                                    ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                        <option key={id} value={item.CATEGORY2_UNIQUE_ID}>{item.CATEGORY2_NAME}</option>
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                        </select>

                                    </div>

                                </div>)}
                        </div>

                        {Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && <div class="form-group row">
                            {Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
                                <div class="col-lg-4">
                                    <div className='tw-flex tw-items-center tw-gap-2'>
                                        <label for="consumer" class="col-form-label tw-font-medium">{NameObj?.CATEGORY3 || 'CATEGORY3'}:</label>
                                        {/* {Profile.subdivision === "" && Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && Profile?.area === '' &&
                                            <div className="tw-cursor-pointer" onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search'></i></div>} */}
                                    </div>
                                    <div className=''>
                                        <select
                                            class="form-control divisiontosubdivision form-select"
                                            name="division_id"
                                            id="address_location_d"
                                            onChange={(e) => setProfile({ ...Profile, division: e.target.value })}
                                            value={Profile?.division}
                                        >
                                            <option value="">Select {NameObj?.CATEGORY3 || 'CATEGORY3'}</option>
                                            {DivisionData &&
                                                DivisionData.map((item, id) =>
                                                    props?.permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter(
                                                        (items) => items === item.CATEGORY3_UNIQUE_ID
                                                    ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                        <option key={id} value={item.CATEGORY3_UNIQUE_ID}>{item.CATEGORY3_NAME}</option>
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                        </select>

                                    </div>
                                </div>)}
                            {Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
                                <div class="col-lg-4">
                                    <div className='tw-flex tw-items-center tw-gap-2'>
                                        <label for="consumer" class="col-form-label tw-font-medium">{NameObj?.CATEGORY4 || 'CATEGORY4'}:</label>
                                        {/* {Profile.subdivision !== "" && Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && Profile?.area === '' && <div className="tw-cursor-pointer" onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search'></i></div>} */}
                                    </div>
                                    <div class="">
                                        <select
                                            class="form-control subdivisiontoarea form-select"
                                            name="sub_division_id"
                                            id="address_location_d"
                                            onChange={(e) => setProfile({ ...Profile, subdivision: e.target.value })}
                                            value={Profile?.subdivision}
                                        >
                                            <option value="">Select {NameObj?.CATEGORY4 || 'CATEGORY4'}</option>
                                            {SubdevisionData &&
                                                SubdevisionData.map((item, id) =>
                                                    props?.permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter(
                                                        (items) => items === item.CATEGORY4_UNIQUE_ID
                                                    ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                        <option key={id} value={item.CATEGORY4_UNIQUE_ID}>
                                                            {item.CATEGORY4_NAME}
                                                        </option>
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                        </select>

                                    </div>
                                </div>)}
                            {Profile.subdivision !== "" && Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
                                <div class="col-lg-4">
                                    <div className='tw-flex tw-items-center tw-gap-2'>
                                        <label for="consumer" class=" col-form-label tw-font-medium">{NameObj?.CATEGORY5 || 'CATEGORY5'}:</label>
                                        {/* {Profile.subdivision !== "" && Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && Profile?.area !== '' && <div className="tw-cursor-pointer" onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search'></i></div>} */}
                                    </div>
                                    <div class="">
                                        <select
                                            class="form-control form-select lastarea cust_ara"
                                            name="area_id"
                                            id="area_id"
                                            onChange={(e) => setProfile({ ...Profile, area: e.target.value })}
                                            value={Profile?.area}
                                        >
                                            <option value="">Select {NameObj?.CATEGORY5 || 'CATEGORY5'}</option>
                                            {AreaData &&
                                                AreaData.map((item, id) =>
                                                    props?.permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter(
                                                        (items) => items === item.UNIQUE_ID
                                                    ).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
                                                        <option key={id} value={item.UNIQUE_ID}>{item.CATEGORY5_NAME}</option>
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                        </select>

                                    </div>
                                </div>)}
                        </div>}

                        <div className='form-group row'>
                            <div className='col-lg-6'>
                                <div className='tw-flex tw-items-center tw-gap-2'>
                                    <label for="consumer" class="col-form-label tw-font-medium">Consumer:</label>
                                    {Profile.account !== "" &&
                                        <ButtonLoader isLoading={searchLoader} onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}>
                                            <i className='fas fa-search'></i>
                                        </ButtonLoader>
                                    }
                                </div>
                                <div class="">
                                    <Select isClearable placeholder='Select Consumer...' id='consumer' styles={consumerSelectStyle} components={animatedComponents} name='account' options={ConsumerList} onChange={(e) => { setTaskDetails({ ...taskDetails, consumer: e?.value || '' }) }} value={ConsumerList?.filter((items) => (items.value == taskDetails?.consumer))[0] || null} />

                                    <span className='text-danger pb-0'>{Errors?.consumer || ''}</span>
                                    {taskDetails?.consumer ? <div className='d-flex '>
                                        <input type="checkbox" id="checktext2" name="Business_Number" className='mr-2' onChange={(e) => setTaskDetails({ ...taskDetails, Maintenance: e.target.checked === true ? true : false })} checked={taskDetails?.Maintenance} disabled={UpdateId ? true : false} />
                                        <label className='mt-2' for="checktext2">change status in Under Maintenance</label>
                                    </div> : ''}
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <label htmlFor="taskType" className="tw-w-1/5 col-form-label tw-font-medium">Task Type:</label>
                                <div className="">
                                    <div className="form-check form-check-inline !tw-mb-0">
                                        <input className="form-check-input" type="radio" name="taskType" id="taskType1" value="Development" onChange={(e) => { setTaskDetails({ ...taskDetails, task_type: e.target.value }) }} checked={taskDetails?.task_type === "Development"} />
                                        <label className="form-check-label" htmlFor="taskType1">Development</label>
                                    </div>
                                    <div className="form-check form-check-inline !tw-mb-0">
                                        <input className="form-check-input" type="radio" name="taskType" id="taskType2" value="Action" onChange={(e) => { setTaskDetails({ ...taskDetails, task_type: e.target.value }) }} checked={taskDetails?.task_type === "Action"} />
                                        <label className="form-check-label" htmlFor="taskType2">Action</label>
                                    </div>
                                    <div className="form-check form-check-inline !tw-mb-0">
                                        <input className="form-check-input" type="radio" name="taskType" id="taskType3" value="Bug" onChange={(e) => { setTaskDetails({ ...taskDetails, task_type: e.target.value }) }} checked={taskDetails?.task_type === "Bug"} />
                                        <label className="form-check-label" htmlFor="taskType3">Bug</label>
                                    </div>
                                    <span className="text-danger pb-0">{Errors?.task_type || ""}</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row border-top tw-pt-4">
                            <div class="col-lg-8">
                                <label class="col-form-label">Description :</label>
                                <div>

                                    <textarea rows={3} class="form-control" id="title" placeholder="Max. 1000 Character" onChange={(e) => { setTaskDetails({ ...taskDetails, description: e.target.value }) }} value={taskDetails?.description} />
                                    <span className='text-danger pb-0'>{Errors?.description || ''}</span>
                                </div>
                            </div>
                            {UpdateId === '' && <div class="col-lg-4">
                                <div id="ember836" class="form-group ember-view d-print-none">
                                    <p class="form-control-plaintext">
                                        <div class="font-xs">Attach File(s)</div>
                                        <div className='tw-flex tw-gap-3'>
                                            <label for="attachments" className={`${files.length > 2 ? 'hover:!tw-border-[#ddd] tw-text-stone-400' : 'hover:!tw-border-[#6777ef] tw-cursor-pointer'} tw-mb-0  tw-px-4 tw-py-2 tw-text-xs tw-font-semibold tw-border !tw-border-dashed tw-border-[#ddd] `}>
                                                <input disabled={files.length > 2 ? true : false} name="attachments" type="file" multiple id='attachments' className='tw-hidden' onChange={handleFile} onClick={e => (e.target.value = null)} />
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-4 tw-w-3" viewBox="0 0 512 512"> <path d="M262.4 512c-35.7 0-64.2-10.5-84.9-31.4-24.7-24.9-37.1-63.8-36.8-115.6.2-32.6-.3-248.7-1.4-268.1-.9-15.9 4.8-41.6 23.6-61.5 11.4-12 32-26.3 66-26.3 30.3 0 55.1 15.7 69.8 44.2 10.1 19.7 12.2 38.8 12.4 40.9l.1.9v.9l.5 236.9v.5c-1 19.2-15.9 47.6-53 47.6h-.7c-39.1-.4-53.7-30.4-56-46.2l-.2-1.3V179.6c0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5v150.5c.4 1.5 1.4 4.4 3.6 7.2s6.2 6.5 16 6.6c9.2.1 12.4-3.2 14.1-6 1.6-2.6 2.2-5.6 2.3-6.3l-.7-234.5c-.4-3-2.4-15.6-8.8-27.6-8.3-15.7-20.2-23.3-36.4-23.3-16.7 0-29.8 5-39.1 14.8-10.7 11.3-14 26.6-13.6 34 1.2 21.6 1.6 244.3 1.4 270.3-.2 41.6 8.5 71.7 26 89.3 13.5 13.6 33.2 20.4 58.7 20.4 17.2 0 31.8-5.9 43.5-17.7 18.9-18.9 30.1-53.4 30-92.2 0-19.6-.1-193.2-.1-250.9 0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5c0 57.6.1 231.2.1 250.8.1 49.1-14.8 92.3-40.8 118.4-18.6 18.7-42.7 28.6-69.6 28.6z" /> </svg>
                                                Upload File(s)
                                            </label>
                                            {files.length > 0 ? <span className='tw-flex tw-bg-indigo-500 tw-py-1 tw-px-3 tw-rounded' ref={setReferenceElement} onClick={() => setShowPopper(!showPopper)}>
                                                <div className='tw-flex tw-items-center tw-justify-center tw-text-white tw-cursor-pointer'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-4 tw-w-3" viewBox="0 0 512 512"> <path d="M262.4 512c-35.7 0-64.2-10.5-84.9-31.4-24.7-24.9-37.1-63.8-36.8-115.6.2-32.6-.3-248.7-1.4-268.1-.9-15.9 4.8-41.6 23.6-61.5 11.4-12 32-26.3 66-26.3 30.3 0 55.1 15.7 69.8 44.2 10.1 19.7 12.2 38.8 12.4 40.9l.1.9v.9l.5 236.9v.5c-1 19.2-15.9 47.6-53 47.6h-.7c-39.1-.4-53.7-30.4-56-46.2l-.2-1.3V179.6c0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5v150.5c.4 1.5 1.4 4.4 3.6 7.2s6.2 6.5 16 6.6c9.2.1 12.4-3.2 14.1-6 1.6-2.6 2.2-5.6 2.3-6.3l-.7-234.5c-.4-3-2.4-15.6-8.8-27.6-8.3-15.7-20.2-23.3-36.4-23.3-16.7 0-29.8 5-39.1 14.8-10.7 11.3-14 26.6-13.6 34 1.2 21.6 1.6 244.3 1.4 270.3-.2 41.6 8.5 71.7 26 89.3 13.5 13.6 33.2 20.4 58.7 20.4 17.2 0 31.8-5.9 43.5-17.7 18.9-18.9 30.1-53.4 30-92.2 0-19.6-.1-193.2-.1-250.9 0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5c0 57.6.1 231.2.1 250.8.1 49.1-14.8 92.3-40.8 118.4-18.6 18.7-42.7 28.6-69.6 28.6z" /> </svg>
                                                    {files.length}

                                                </div>
                                            </span> : ''}


                                            {files.length > 0 && showPopper ? <div className='tw-bg-white tw-w-96 tw-rounded-md' ref={setPopperElement} style={styles.popper} {...attributes.popper} id='tooltip'>
                                                <div className='' ref={setArrowElement} style={styles.arrow} id='arrow' />
                                                <div className='tw-border tw-border-solid tw-border-[#ddd] tw-px-2 tw-py-1 tw-rounded-md'>
                                                    <div class="tw-float-right tw-mr-2 tw-cursor-pointer" onClick={() => setShowPopper(!showPopper)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-3 tw-w-2" viewBox="0 0 512 512">
                                                            <path d="M455.2 9.2L256 208.4 56.8 9.2C44.5-3.1 24.6-3.1 12.2 9.2l-2.9 2.9C-3 24.4-3 44.4 9.3 56.7L208.4 256 9.2 455.2c-12.3 12.3-12.3 32.3 0 44.6l2.9 2.9c12.3 12.3 32.3 12.3 44.6 0L256 303.6l199.2 199.2c12.3 12.3 32.3 12.3 44.6 0l2.9-2.9c12.3-12.3 12.3-32.3 0-44.6L303.6 256 502.8 56.8c12.3-12.3 12.3-32.3 0-44.6l-2.9-2.9c-12.5-12.4-32.4-12.4-44.7-.1z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                    <div className='tw-box-border'>
                                                        {files.map((item, id) => <div id={id} className='tw-px-4 tw-pt-4 tw-pb-2 border-bottom !tw-border-b-gray-300 last:!tw-border-b-0'>
                                                            <div className='container'>
                                                                <div className='tw-flex tw-gap-4'>
                                                                    <div className="tw-flex tw-items-center fa-2xl">
                                                                        <i class="fas fa-file-alt" aria-hidden="true"></i>
                                                                    </div>

                                                                    <div className='tw-flex'>
                                                                        <div className=' tw-block'>
                                                                            <div className='tw-w-full tw-text-indigo-400 ' style={{ wordWrap: 'anywhere' }}>

                                                                                {item.name}
                                                                                <span className='tw-text-xs tw-text-[#777] tw-block '>
                                                                                    File Size : {formatBytes(item.size)}
                                                                                </span>
                                                                            </div>
                                                                            <div className='tw-mt-1'>
                                                                                <span onClick={() => deleteSelectedFile(item)} class="tw-flex tw-items-center tw-gap-1 tw-cursor-pointer tw-text-indigo-400 tw-text-sm">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill='#6366f1' viewBox="0 0 512 512" width="14" height="16" >
                                                                                        <path d="M207 415c8.8 0 16-7.2 16-16V175c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16z"></path>
                                                                                        <path d="M467 63H351V11c0-6.6-5.4-12-12-12H170.9c-6.6 0-12 5.4-12 12l.1 52H43c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h20v404c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12V95h20c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12zM191 31h128v32H191V31zm224 436c0 6.6-5.4 12-12 12H107c-6.6 0-12-5.4-12-12V95h320v372z">
                                                                                        </path>
                                                                                        <path d="M303 415c8.8 0 16-7.2 16-16V175c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16z">
                                                                                        </path>
                                                                                    </svg> Delete
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>

                                                </div>
                                            </div> : ''}
                                        </div>
                                        <div>
                                            <small className='tw-text-stone-400'>You can upload a maximum of 3 files, 20MB each</small>
                                        </div>
                                    </p>
                                </div>
                            </div>}
                        </div>

                        <div className='border-top !tw-border-b-gray-300 tw-flex'>
                            <div class="buttons tw-mt-5">
                                <button class="btn btn-danger" onClick={onFormSubmit}>{UpdateId === '' ? 'Create' : 'Update'}</button>
                                <Link class="btn btn-light text-dark" onClick={onModelCancel}>Cancel</Link>
                            </div>
                        </div>

                    </form>
                </div >
            </Modal >
            <div >

                <section className="section">
                    <div className="card !tw-rounded-none">
                        <div className='tw-flex tw-relative tw-w-full tw-overflow-y-auto tw-grow'>
                            <div className='tw-grow tw-shrink-0 tw-w-2/3 border-end !tw-border-r-gray-300 !tw-border-r-4'>
                                <div className="border-bottom !tw-border-b-gray-300">
                                    <div className='tw-px-5 tw-py-3 tw-flex tw-justify-between tw-items-center '>
                                        <div className='tw-flex tw-items-center'>
                                            {totalUserList.length > 0 && <ConsumerTaskFilter handleFilter={handleFilter} tagsTypsOptions={taskTags} taskAssignToOptions={totalUserList} currentUser={userInfo.UNIQUE_ID} />}

                                        </div>

                                        <div className="">
                                            {props?.permision?.ROLES_OTHER_PERMISSIONS?.Task?.add === true || userInfo?.ROLE === 'ROLE1650614500708' ? <button type="button" className="btn btn-primary mr-1" onClick={() => (onOpenAddTaskModal())}>
                                                <BootstrapTooltip title="Task">
                                                    <div className='tw-flex tw-items-center tw-gap-1 '>
                                                        <i class="fas fa-plus" aria-hidden="true"></i>
                                                        <span>
                                                            New
                                                        </span>
                                                    </div>
                                                </BootstrapTooltip>
                                            </button> : ''}
                                        </div>
                                    </div>

                                </div>

                                {isFilterLoading ? <><div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                                    <GoogleLoader msg={"Loading Filtered data"} bodyClassName={'tw-h-[70%]'} />
                                </div> </> : <div>
                                    <div className="tw-flex">

                                        <div className="table-responsive tw-h-screen tw-max-h-screen">
                                            <div id="table-1_wrapper" className="dataTables_wrapper no-footer">

                                                <div className="dataTables_length mt-2 ml-2" id="table_id_length">

                                                    <label>
                                                        Show{" "}
                                                        <select name="table_id_length" aria-controls="table_id" className="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>{" "}
                                                        entries
                                                    </label>
                                                </div>
                                                <table class="tw-w-full tw-text-sm text-center">
                                                    <thead className='tw-sticky tw-top-0 tw-text-xs tw-uppercase tw-bg-gray-100  border-bottom border-top !tw-border-b-gray-300 tw-rounded-lg' >
                                                        <tr>
                                                            <th className='tw-py-3 tw-rounded-l-lg ' style={{ width: '6%', maxWidth: '6%' }}>Task Number</th>
                                                            {/* <th style={{ width: '38%', maxWidth: '38%' }}>Subject</th> */}
                                                            <th className='tw-max-w-[20%] tw-w-[20%]' >Title</th>
                                                            <th>Consumer</th>
                                                            <th >Assign To</th>
                                                            <th>Created By</th>
                                                            <th >Status</th>
                                                            <th >Priority</th>
                                                            <th>Task Type</th>
                                                            <th>Tags</th>
                                                            <th style={{ width: '100px' }}>Due Date</th>
                                                            <th style={{ width: '100px' }}>Duration</th>
                                                            <th className='tw-py-3 tw-rounded-r-lg' style={{ width: '2%', maxWidth: '2%' }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {TaskList?.length > 0 && TaskList?.map((item, id) => (
                                                            <tr onClick={() => onGetTaskByUniqueId(item?.TASK_UNIQUE_ID)} className='hover:tw-bg-[#fafafa] border-bottom !tw-border-b-gray-300'>
                                                                <td className='tw-py-4'>{item?.SRNO}</td>
                                                                <td className='tw-max-w-[20%] tw-w-[20%]' >{item?.TASK_TITLE}</td>
                                                                <td> {item?.consumer_name}</td>
                                                                <td>{item?.assign_users}</td>
                                                                <td>{item?.CREATED_BY_USER}</td>
                                                                <td>{item?.TASK_STATUS === -1 ? <div className='badge badge-primary'>  Yet To Start
                                                                </div> : item?.TASK_STATUS === 0 ? <div className='badge badge-warning'> In Progress
                                                                </div> : item?.TASK_STATUS === 1 ? <div className='badge badge-success'> Completed
                                                                </div> : ''}
                                                                </td>
                                                                <td className='tw-text-center'>{item?.TASK_PRIORITY === 'medium' ? <div className="badge badge-warning">Medium</div> : item?.TASK_PRIORITY === 'low' ? <div className="badge badge-success">Low</div> : item?.TASK_PRIORITY === 'High' ? <div className="badge badge-danger">High</div> : ''}</td>
                                                                <td>{item?.TASK_TYPE ? item?.TASK_TYPE : ' - '}</td>
                                                                <td className='tw-min-w-[120px]'>{item?.TASK_TAGS && item?.TASK_TAGS?.length > 0 ? (
                                                                    <div className='tw-flex tw-gap-2 tw-flex-wrap tw-justify-center'>
                                                                        {item?.TASK_TAGS?.map(tag =>
                                                                            <div className={`btn btn-icon btn-sm icon-left ml-1 mb-1 !tw-py-0`} style={{
                                                                                color: "white", backgroundColor: tag?.TAG_COLOR, position: 'relative',
                                                                                display: 'inline-block',
                                                                                borderRadius: '1px',
                                                                            }}><BootstrapTooltip title={tag?.TAG_NAME} placement="top"><span className='show-read-more'>{tag?.TAG_NAME}</span></BootstrapTooltip><span style={{
                                                                                content: '',
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                right: '-10px',
                                                                                transform: 'translateY(-50%)',
                                                                                borderTop: '14px solid transparent',
                                                                                borderBottom: '14px solid transparent',
                                                                                borderLeft: `10px solid ${tag?.TAG_COLOR}`,
                                                                                borderRadius: '5px',
                                                                            }}>
                                                                                </span></div>
                                                                        )}
                                                                    </div>
                                                                )
                                                                    : ' - '}</td>
                                                                <td >{item?.DUE_DATE && item?.DUE_DATE !== '0000-00-00' ? moment(item?.DUE_DATE).format('DD-MM-YYYY') : '-'}</td>
                                                                <td>{item?.DUE_DATE && item?.DUE_DATE !== '0000-00-00' ? moment.utc(item?.DUE_DATE) > moment() ? <div className='badge badge-success'>{moment.utc(item?.DUE_DATE).diff(moment.utc(item?.CREATED_AT), 'days') + ' Day '}</div> : <div className='badge badge-danger'>{moment.utc(item?.DUE_DATE).diff(moment.utc(item?.CREATED_AT), 'days') + ' Day '}</div> : '-'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                {!isFilterLoading && TaskList?.length === 0 && <div className='d-flex tw-flex-col tw-justify-center tw-my-32 tw-items-center'><NoTaskFoundSVG /> <b className='tw-text-xl tw-text-indigo-500'>No Task Found !!</b></div>}
                                            </div>
                                        </div>
                                    </div>

                                    <><div className='d-flex justify-content-between'>
                                        <div>Total Number Of Row {numberofPage}</div><Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={numberofPage || 1}
                                            pageSize={PageSize}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        /></div>
                                    </>
                                </div>}
                            </div>


                            {isSideExapnded && <div className='tw-w-1/3 !tw-relative tw-overflow-y-auto tw-overflow-x-hidden tw-transition-all tw-h-screen tw-ease-linear tw-duration-300' >
                                <div className='tw-flex tw-flex-col tw-w-full'>
                                    <div className='tw-w-full tw-flex tw-items-center border-bottom !tw-border-b-gray-300 lg:tw-pl-5 tw-pr-2 tw-py-3 md:tw-px-0   '>
                                        <b className='tw-text-lg'>Task</b>
                                        <div class="ml-auto"></div>
                                        {/* <div className='tw-align-middle tw-inline-flex dropdown '>
                                            <button className='btn btn-primary tw-py-2 dropdown-toggle' data-toggle='dropdown'>Change Status</button>
                                            <div className='dropdown-menu show'>
                                                <button className='dropdown-item' type='button'>In Progress</button>
                                                <button className='dropdown-item' type='button'>Completed</button>

                                            </div>
                                        </div> */}
                                        {props?.permision?.ROLES_OTHER_PERMISSIONS?.Task?.active_inactive === true || userInfo?.ROLE === 'ROLE1650614500708' ? <div class="dropdown tw-inline-flex">
                                            <button class={`btn btn-primary dropdown-toggle`} type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                                                Change Status
                                            </button>
                                            <div class="dropdown-menu " aria-labelledby="dropdownMenuButton1">
                                                {TaskDetailsById?.TASK_STATUS !== 0 ? <button className='dropdown-item ' type='button' onClick={() => onactive('0', TaskDetailsById?.TASK_UNIQUE_ID, TaskDetailsById?.TASK_CONSUMER_TYPE, TaskDetailsById?.IS_UNDER_MAINTENANCE)}>In Progress</button> : ''}
                                                {TaskDetailsById?.TASK_STATUS !== 1 ? <button className='dropdown-item ' type='button' onClick={() => onactive('1', TaskDetailsById?.TASK_UNIQUE_ID, TaskDetailsById?.TASK_CONSUMER_TYPE, TaskDetailsById?.IS_UNDER_MAINTENANCE)}>Completed</button> : ''}
                                                {TaskDetailsById?.TASK_STATUS !== -1 ? <button className='dropdown-item ' type='button' onClick={() => onactive('-1', TaskDetailsById?.TASK_UNIQUE_ID, TaskDetailsById?.TASK_CONSUMER_TYPE, TaskDetailsById?.IS_UNDER_MAINTENANCE)}><span className=''>Yet To Start</span></button> : ''}
                                            </div>
                                        </div> : ''}
                                        <div className="tw-ml-1">
                                            {props?.permision?.ROLES_OTHER_PERMISSIONS?.Task?.edit === true || userInfo?.ROLE === 'ROLE1650614500708' ? <Popover
                                                arrowPointAtCenter={true}
                                                align={{ offset: [80, -5] }}
                                                overlayStyle={{ width: '24rem', borderRadius: '5px' }}
                                                overlayInnerStyle={{ borderRadius: '5px' }}
                                                content={<div className=''>
                                                    <div className=''>
                                                        <div class="tw-float-right tw-mr-2 tw-cursor-pointer" onClick={hide}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-3 tw-w-2" viewBox="0 0 512 512">
                                                                <path d="M455.2 9.2L256 208.4 56.8 9.2C44.5-3.1 24.6-3.1 12.2 9.2l-2.9 2.9C-3 24.4-3 44.4 9.3 56.7L208.4 256 9.2 455.2c-12.3 12.3-12.3 32.3 0 44.6l2.9 2.9c12.3 12.3 32.3 12.3 44.6 0L256 303.6l199.2 199.2c12.3 12.3 32.3 12.3 44.6 0l2.9-2.9c12.3-12.3 12.3-32.3 0-44.6L303.6 256 502.8 56.8c12.3-12.3 12.3-32.3 0-44.6l-2.9-2.9c-12.5-12.4-32.4-12.4-44.7-.1z">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                        <div className='tw-box-border'>
                                                            {sideFiles.map((item) => <div className='tw-px-1 tw-pt-1 tw-pb-2 border-bottom !tw-border-b-gray-300 last:!tw-border-b-0'>
                                                                <div className='container'>
                                                                    <div className='tw-flex tw-gap-4'>
                                                                        <div className="tw-flex tw-items-center fa-2xl">
                                                                            <i class="fas fa-file-alt" aria-hidden="true"></i>
                                                                        </div>

                                                                        <div className='tw-flex'>
                                                                            <div className=' tw-block'>
                                                                                <div className='tw-w-full tw-text-indigo-400 ' style={{ wordWrap: 'anywhere' }}>

                                                                                    {item.file}
                                                                                    <span className='tw-text-xs tw-text-[#777] tw-block '>
                                                                                        File Size : {formatBytes(item.size)}
                                                                                    </span>
                                                                                </div>
                                                                                <div className='tw-mt-1 tw-box-border tw-flex'>
                                                                                    <a className='!tw-text-indigo-400 tw-font-normal tw-px-2 border-right !tw-border-b-gray-300 ' onClick={() => (onDownloadFiles(item.file))}>Download</a>
                                                                                    <span class="tw-flex tw-items-center tw-px-2 tw-gap-1 tw-cursor-pointer tw-text-indigo-400 tw-text-sm" onClick={() => onDeleteFile(item.file, item.SRNO)}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#6366f1' viewBox="0 0 512 512" width="14" height="16" >
                                                                                            <path d="M207 415c8.8 0 16-7.2 16-16V175c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16z"></path>
                                                                                            <path d="M467 63H351V11c0-6.6-5.4-12-12-12H170.9c-6.6 0-12 5.4-12 12l.1 52H43c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h20v404c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12V95h20c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12zM191 31h128v32H191V31zm224 436c0 6.6-5.4 12-12 12H107c-6.6 0-12-5.4-12-12V95h320v372z">
                                                                                            </path>
                                                                                            <path d="M303 415c8.8 0 16-7.2 16-16V175c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16z">
                                                                                            </path>
                                                                                        </svg> Delete
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)}
                                                        </div>

                                                    </div>
                                                    <div className='tw-pb-2 tw-pt-5'>
                                                        <div className='tw-text-center'>
                                                            <p className='tw-text-stone-400 tw-text-sm tw-mb-0'>You can upload a maximum of 3 files, 20MB each</p>
                                                        </div>
                                                        <div className='tw-text-center tw-mt-2'>
                                                            <label for="side-attachments" className={`${sideFiles.length > 2 ? 'hover:!tw-border-[#ddd] tw-text-stone-400' : 'hover:!tw-border-[#6777ef] tw-cursor-pointer'} tw-mb-0  tw-px-4 tw-py-2 tw-text-xs tw-font-semibold tw-rounded tw-border tw-border-solid tw-border-[#ddd] tw-bg-[#f5f5f5]`}>
                                                                <input disabled={sideFiles.length > 2 ? true : false} name="side-attachments" type="file" multiple id='side-attachments' className='tw-hidden' onChange={handleSideFiles} onClick={e => (e.target.value = null)} />
                                                                <i className='fas fa-upload'></i>

                                                                <div className='tw-text-indigo-400 tw-p-1'>
                                                                    Add File
                                                                </div>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>}
                                                placement="bottomRight"
                                                trigger="click"
                                                visible={showAntdPopOver}>
                                                <button onClick={handleOpenChange} className="tw-border tw-border-solid tw-border-[#ddd] tw-rounded tw-py-1 tw-px-[0.4rem] ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-5 tw-w-4" viewBox="0 0 512 512"> <path d="M262.4 512c-35.7 0-64.2-10.5-84.9-31.4-24.7-24.9-37.1-63.8-36.8-115.6.2-32.6-.3-248.7-1.4-268.1-.9-15.9 4.8-41.6 23.6-61.5 11.4-12 32-26.3 66-26.3 30.3 0 55.1 15.7 69.8 44.2 10.1 19.7 12.2 38.8 12.4 40.9l.1.9v.9l.5 236.9v.5c-1 19.2-15.9 47.6-53 47.6h-.7c-39.1-.4-53.7-30.4-56-46.2l-.2-1.3V179.6c0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5v150.5c.4 1.5 1.4 4.4 3.6 7.2s6.2 6.5 16 6.6c9.2.1 12.4-3.2 14.1-6 1.6-2.6 2.2-5.6 2.3-6.3l-.7-234.5c-.4-3-2.4-15.6-8.8-27.6-8.3-15.7-20.2-23.3-36.4-23.3-16.7 0-29.8 5-39.1 14.8-10.7 11.3-14 26.6-13.6 34 1.2 21.6 1.6 244.3 1.4 270.3-.2 41.6 8.5 71.7 26 89.3 13.5 13.6 33.2 20.4 58.7 20.4 17.2 0 31.8-5.9 43.5-17.7 18.9-18.9 30.1-53.4 30-92.2 0-19.6-.1-193.2-.1-250.9 0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5c0 57.6.1 231.2.1 250.8.1 49.1-14.8 92.3-40.8 118.4-18.6 18.7-42.7 28.6-69.6 28.6z" /> </svg>{sideFiles?.length}
                                                </button>
                                            </Popover> : ''}
                                            <button style={{ marginLeft: '-1px' }} className="tw-border tw-py-1 tw-px-[0.4rem] tw-text-[#212529] tw-border-solid tw-border-[#ddd] tw-rounded dropdown-toggle" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                                                <BootstrapTooltip title="action">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-4 tw-w-3" viewBox="0 0 16 16"> <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" /> </svg>
                                                </BootstrapTooltip>
                                            </button>
                                            <div class="dropdown-menu " aria-labelledby="dropdownMenuButton1">
                                                <button className='dropdown-item ' type='button' onClick={onEditTask}>Edit</button>
                                            </div>
                                        </div>

                                        <span onClick={() => setIsSideExpanded(false)} className='border-start !tw-border-l-gray-300 tw-float-right tw-text-[#777] tw-ml-2 tw-cursor-pointer hover:tw-text-[#212529]'>
                                            <svg className='tw-h-8 lg:tw-w-10 md:tw-w-7' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /> </svg>
                                        </span>

                                    </div>

                                    <div className='tw-overflow-y-auto tw-pb-10'>
                                        <div className='tw-px-4 '>
                                            <div className='tw-w-max tw-border tw-font-medium tw-flex tw-border-solid tw-border-gray-300 tw-rounded tw-uppercase tw-text-xs tw-px-3 tw-py-2 tw-mt-4 tw-gap-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="tw-h-4 tw-w-3">
                                                    {TaskDetailsById?.TASK_PRIORITY === 'High' ? <path fill="#ff0018" d="M398.5 310.5l-108-156.6c-2.9-4.2-6.5-7.8-10.7-10.7-19-13.1-45.1-8.4-58.3 10.7l-108 156.6c-4.8 7-7.4 15.3-7.4 23.8 0 23.1 18.8 41.9 41.9 41.9h216.1c8.5 0 16.8-2.6 23.8-7.4 19-13.2 23.8-39.2 10.6-58.3z"></path>

                                                        : TaskDetailsById?.TASK_PRIORITY === 'medium' ? <path fill="#ffa426" d="M392.8 215L297 119.2c-22.7-22.7-59.4-22.7-82.1 0L119.2 215c-22.7 22.7-22.7 59.4 0 82.1l95.8 95.8c22.7 22.7 59.4 22.7 82.1 0l95.8-95.8c22.6-22.7 22.6-59.5-.1-82.1z"></path>
                                                            :
                                                            <path fill="#54ca68" d="M364.1 135.8H147.9c-8.5 0-16.8 2.6-23.8 7.4-19 13.1-23.8 39.2-10.7 58.3l108.1 156.6c2.9 4.2 6.5 7.8 10.7 10.7 19 13.1 45.1 8.4 58.3-10.7l108.1-156.6c4.8-7 7.4-15.3 7.4-23.8-.1-23.1-18.8-41.9-41.9-41.9z"></path>}
                                                </svg>
                                                {TaskDetailsById?.TASK_PRIORITY} Priority
                                            </div>
                                            <div className='tw-mt-4'>
                                                <b className='tw-text-base tw-mr-2'>{TaskDetailsById?.TASK_TITLE}</b>
                                                <span className={`tw-uppercase ${TaskDetailsById?.TASK_STATUS === -1 ? 'tw-bg-[#6777ef]' : TaskDetailsById?.TASK_STATUS === 0 ? 'tw-bg-[#ffa426]' : 'tw-bg-[#54ca68]'} tw-text-xs tw-text-white tw-py-1 tw-px-2`}>
                                                    {TaskDetailsById?.TASK_STATUS === -1 ? 'Yet To Start' : TaskDetailsById?.TASK_STATUS === 1 ? 'Completed' : 'In Progress'}</span>
                                            </div>
                                            <div className='tw-mt-4'>
                                                {TaskDetailsById?.TASK_DESCRIPTION}
                                            </div>

                                            <div className='tw-grid tw-grid-cols-3 tw-gap-4 tw-mt-4'>
                                                <div>
                                                    Due Date :
                                                </div>
                                                <div className='tw-col-span-2'>
                                                    {TaskDetailsById?.DUE_DATE && TaskDetailsById?.DUE_DATE !== '0000-00-00' ? moment(TaskDetailsById?.DUE_DATE).format('DD-MM-YYYY') : '-'}
                                                </div >

                                                <div>
                                                    Created By :
                                                </div>
                                                <div className='tw-col-span-2'>
                                                    {TaskDetailsById?.CREATED_BY_USER}

                                                </div>
                                                <div >
                                                    Assign To :
                                                </div>
                                                <div className='tw-col-span-2'>
                                                    {TaskDetailsById?.assign_users}

                                                </div>
                                                <div>
                                                    Consumer :
                                                </div>
                                                <div className='tw-col-span-2 d-flex '>
                                                    {TaskDetailsById?.consumer_name ? TaskDetailsById?.consumer_name : '-'}
                                                    {TaskDetailsById?.consumer_name && <a className='ml-2' href={TaskDetailsById?.TASK_CONSUMER_TYPE == "GSM" ? `/view_existing_consumer_gsm/${TaskDetailsById?.CONSUMER_ID}` : `/view_existing_consumer_lorawan/${TaskDetailsById?.CONSUMER_ID}`} target='_blank'><i className='
fas fa-external-link-alt text-info'></i></a>}
                                                </div>

                                                <div>
                                                    Created Date :
                                                </div>
                                                <div className='tw-col-span-2'>
                                                    {moment.utc(TaskDetailsById?.CREATED_AT).format('DD-MM-YYYY hh:mm:ss A')}
                                                </div>
                                                <div>
                                                    Duration :
                                                </div>
                                                <div className='tw-col-span-2'>{TaskDetailsById?.DUE_DATE && TaskDetailsById?.DUE_DATE !== '0000-00-00' ? moment.utc(TaskDetailsById?.DUE_DATE) > moment() ? <div className='badge badge-success'>{moment.utc(TaskDetailsById?.DUE_DATE).diff(moment.utc(TaskDetailsById?.CREATED_AT), 'days') + ' Day '}</div> : <div className='badge badge-danger'>{moment.utc(TaskDetailsById?.DUE_DATE).diff(moment.utc(TaskDetailsById?.CREATED_AT), 'days') + ' Day '}</div> : '-'}</div>

                                                <div>
                                                    Task Type :
                                                </div>
                                                <div className='tw-col-span-2'>
                                                    {TaskDetailsById?.TASK_TYPE ? TaskDetailsById?.TASK_TYPE : '-'}
                                                </div>
                                                <div>
                                                    Task Tags :
                                                </div>
                                                <div className='tw-col-span-2 tw-flex tw-gap-2'>
                                                    {TaskDetailsById?.TASK_TAGS ? TaskDetailsById?.TASK_TAGS?.map(tag =>
                                                        <div className={`btn btn-icon btn-sm icon-left ml-1 mb-1 !tw-py-0`} style={{
                                                            color: "white", backgroundColor: tag?.TAG_COLOR, position: 'relative',
                                                            display: 'inline-block',
                                                            borderRadius: '1px',
                                                        }}><BootstrapTooltip title={tag?.TAG_NAME} placement="top"><span className='show-read-more'>{tag?.TAG_NAME}</span></BootstrapTooltip><span style={{
                                                            content: '',
                                                            position: 'absolute',
                                                            top: '50%',
                                                            right: '-10px',
                                                            transform: 'translateY(-50%)',
                                                            borderTop: '14px solid transparent',
                                                            borderBottom: '14px solid transparent',
                                                            borderLeft: `10px solid ${tag?.TAG_COLOR}`,
                                                            borderRadius: '5px',
                                                        }}>
                                                            </span></div>)

                                                        : ' - '}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='tw-mb-0' />
                                        <div className='tw-p-5'>
                                            <div>
                                                <div className='tw-mb-4'>
                                                    <textarea rows="2" id="comment" class=" form-control" data-test-title="user-comment-box" onChange={(e) => (setComment(e.target.value.replace(/[\/\\"`\t\n]/g, '')))} value={Comment}></textarea>
                                                </div>
                                                <div className='tw-mb-4'>
                                                    <button className={`btn btn-sm btn-danger  ${Comment == '' ? 'disabled' : ''}`} onClick={addCommandTask}>
                                                        Add comment
                                                    </button>
                                                </div>
                                                <div style={{ overflow: 'auto' }}>
                                                    <div class="container" style={{ paddingBottom: '0px', paddingTop: '0px', width: '1000px' }}>
                                                        {commentData?.length > 0 && commentData.map((item) => (
                                                            <div class="timeline-block timeline-block-right">
                                                                <div class="mt-3 mr-3" style={{ width: "171px" }}>
                                                                    <span className="mt-2">{moment(item?.created_at).format('DD-MM-YYYY hh:mm A')}</span>
                                                                </div>
                                                                <div class="marker active">
                                                                    <i class="fas fa-clipboard-list active"></i>
                                                                </div>
                                                                <div class="timeline-content">
                                                                    <span className="d-flex"><p style={{ marginTop: '14px', fontSize: '17px', fontWeight: 500 }}>{item?.comment} </p>&nbsp; <p className="" style={{ marginTop: '16px', color: '#787878', fontWeight: 500 }}> By {item?.created_by}</p></span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
