import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import { geModemIdWiseColumnName, GetBuferCountInByStatus, getErrorbinAndDuplicatbinCountList, getEventCountList, getGSMBufferInDeshboardAllCountByStatus, getGSMDataById, getHeatbeatStatusWiseCount, getLoraBufferInDeshboardAllCountByStatus, getLorawanDataById, GetModemStatusByTable, GetModemStatusByTableAndDate, GetModemStatusDurationByTable, GetModemStatusDurationByTableAndDATE, getProfilNameByAccountId, getRolesById, getRSSITotalLora, getScriptCodeIdWiseColumnName, getStartAndEndDateByTable, GetLoraTableNameWiseChartDataByDate, GetTableNameWiseChartDataByStartDateEndDate, GetTableNameWiseChartInRelayonTime, GetTableNameWiseChartInRelayonTimeByDate, GetTableNameWiseChartRSSIDataByStartDateEndDate, getTags, getTagsInGsm, LetestCountAndLastDateByLoraTableName, UpdateTagsInGsm, getLoraErrorbinAndDuplicatbinCountList, getLoraHeatbeatStatusWiseCount, UpdateTagsInLorawan } from "../../Commoncomponet/Utils";
import Configuration from "./LoraViewDeshboard/Configration";
import ConsumerInfo from "./LoraViewDeshboard/Consumerinfo";
import ModemData from "./LoraViewDeshboard/ModemData";
import MeterData from "./LoraViewDeshboard/MeterData";
import Extra from "./LoraViewDeshboard/Extra";
// import Apexchart, { LongChart, SnrChart } from "./Apexchart";
import Chart from "react-apexcharts";
import { DatePicker, Modal, Space } from "antd";
import chroma from 'chroma-js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import swal from "sweetalert2";
import signal2 from '../../assets/img/secondsignal.png'
import signal3 from '../../assets/img/signal3.png'
import signal4 from '../../assets/img/signal4.png'
import signal5 from '../../assets/img/signal5.png'
import Cycle from '../../assets/img/icons8-cycle-64.png'
import serverConnec from '../../assets/img/icons8-server-network-53.png'
import otpreqest from '../../assets/img/icons8-otp-64.png'
import unloack from '../../assets/img/icons8-unlock-64.png'


import Buffer from './LoraViewDeshboard/Buffer'
import { BootstrapTooltip } from "../../Commoncomponet/common";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	InfoWindow
} from "react-google-maps"
import mapicon from '../../assets/img/triangle-green.svg'
import mapRedicon from '../../assets/img/triangle-red.svg'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	Decimation, TimeScale
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import heartbeat from '../../assets/img/heartbeat.png'
import hourglass from '../../assets/img/hourglass.png'
import calendar from '../../assets/img/calendar.png'
import timetable from '../../assets/img/timetable.png'
import alert from '../../assets/img/alert.png'
import server from '../../assets/img/server.png'
import signal1 from '../../assets/img/onesignal.png'

// import ConsumerTask from "./GsmViewDeshboard/ConsumerTask/ConsumerTask";
import { X } from "react-feather";
import ConsumerTask from "../Lora/LoraViewDeshboard/ConsumerTask/ConsumerTask";
import ChartLoaders from "../../Commoncomponet/Loaders/ChartLoader";
import { toast } from "react-toastify";
import { LoraMeterDashboard } from "./LoraMeterDashboard";


window.Swal = swal;

export default function Lora_deshboard() {
	const colors = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9"]
	const colors15 = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9", "#6794dcd9", "#67b7dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9"]
	const buttonArray = ["Dashboard", "Consumer Info", "Configuration", "Modem Data", "EVC/MC Meter Data", "Buffer", "Extra", 'Tasks'];
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
	const subbuttonArray = ["Modem", "EVC/MC Meter"];
	const [btnText, setButtontext] = useState("Dashboard");
	const [btnSubText, setbtnSubText] = useState("Modem");
	const [GSMData, setGSMData] = useState({});
	const [InstantaneousChart, setInstantaneousChart] = useState({ total: [], date: [] })
	const [RelayOntimeChart, setRelayOntimeChart] = useState({ load1: [], load2: [], load3: [], date: [] })
	const [RelayOntimeChartBydate, setRelayOntimeChartBydate] = useState({ load1: [], load2: [], load3: [], date: [] })

	const [LoadsurvayChart, setLoadsurvayChart] = useState({ total: [], date: [] })
	const [timesyncChart, settimesyncChart] = useState({ total: [], date: [] })
	const [serverConChart, setserverConChart] = useState({ total: [], date: [] })
	const [DailyConsuptionChart, setDailyConsuptionChart] = useState({ total: [], date: [] })
	const [DailyChart, setDailyChart] = useState({ total: [], date: [] })
	const [MonthlyChart, setMonthlyChart] = useState({ total: [], date: [] })
	const [HardbeatChart, setHardbeatChart] = useState({ total: [], date: [] })
	const [RSSIChart, setRSSIChart] = useState({ total: [], date: [] })
	const [SNRChart, setSNRChart] = useState({ total: [], date: [] })
	const [SFChart, setSFChart] = useState({ total: [], date: [] })
	const [BAT_PERChart, setBAT_PERChart] = useState({ total: [], date: [] })

	const [Instantaneous15Chart, setInstantaneous15Chart] = useState({ total: [], date: [] })
	const [RSSIChartInDate, setRSSIChartInDate] = useState({ total: [], date: [] })
	const [ModemStatus, setModemStatus] = useState({ online_count: [], offline_count: [], date: [] })
	const [ModemStatusDuration, setModemStatusDuration] = useState({ online_count: [], offline_count: [], date: [] })
	const [ModemStatusChartInDate, setModemStatusChartInDate] = useState({ online_count: [], offline_count: [], date: [] })
	const [isModalVisibleModemStatus, setisModalVisibleModemStatus] = useState(false)
	const [ismodalOpenRelayonTime, setismodalOpenRelayonTime] = useState(false)
	const [ModemSTChartInDate, setModemSTChartInDate] = useState({ online_count: [], offline_count: [], date: [] })
	const [isModalVisibleModemST, setisModalVisibleModemST] = useState(false)
	const [BufferChart, setBufferChart] = useState([])
	const [loader, setLoader] = useState(false);
	const [InstantaneousCount, setInstantaneousCount] = useState({})
	const [MonthlyCount, setMonthlyCount] = useState({})
	const [DailyCount, setDailyCount] = useState({})
	const [LoadsurvayCount, setLoadsurvayCount] = useState({})
	const [TimeSyncCount, setTimeSyncCount] = useState({})
	const [otpCount, setotpCount] = useState({})
	const [UnlockCount, setUnlockCount] = useState({})
	const [DailyETChart, setDailyETChart] = useState({ total: [], date: [] })

	const [serverconCount, setserverconCount] = useState({})
	const [HardbeatCount, setHardbeatCount] = useState()
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [dates, setDates] = useState({ start: '', end: '' })
	const [ModelHeader, setModelHeader] = useState('')
	const [isModalTags, setisModalTags] = useState(false)
	const [tagsList, settagsList] = useState([])
	const [AllBufferStatus, setAllBufferStatus] = useState([])
	const [tagArray, setTagArray] = useState([])
	const [TagBtn, setTagBtn] = useState([])
	const [ChartLoader, setChartLoader] = useState(false)
	const [expireDate, setExpireDate] = useState({})
	const [isModalVisibleRssi, setisModalVisibleRssi] = useState(false)
	const [isModalVisibleMonth, setisModalVisibleMonth] = useState(false)
	const [btnProccess, setbtnProccess] = useState(false)
	const [EventCount, setEventCount] = useState({})
	const [BinCount, setBinCount] = useState({})
	const [HbdStatus, setHbdStatus] = useState({})
	const [permision, setpermision] = useState({});
	const [ColumnName, setColumnName] = useState({})
	const [rolesGSMConfig, setrolesGSMConfig] = useState('false')
	const [BufferModal,setBufferModal]=useState(false)
	const [isChartsLoading, setIsChartLoading] = useState({ Heartbeat: false, GSM_RSSI: true, Battery_Percentage: true, Hourly: false, Dailyload: false, Monthbill: false, instantaneous: false, buffer: false, SNR: true, SF: true })
	const params = useParams();
	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))

	const onHandalebtn = (text) => {
		setButtontext(text);
		if (text !== 'Dashboard') {
			setbtnSubText('')
		} else if (text === 'Dashboard') {
			setbtnSubText('Modem')
		}
	};
	const handleCancel = () => {
		setIsModalVisible(false);
		setInstantaneous15Chart({ total: [], date: [] })
		setChartLoader(false)
	};
	const handleCancelModalRSSI = () => {
		setisModalVisibleRssi(false);
		setRSSIChartInDate({ total: [], date: [] })
		setChartLoader(false)

	}
	const handleCancelMonth = () => {
		setisModalVisibleMonth(false);
		setChartLoader(false)
		setInstantaneous15Chart({ total: [], date: [] })
	}

	const ongetHeatbeatStatusWiseCount = async (tableName) => {
		const data = await getLoraHeatbeatStatusWiseCount({ tableName: tableName })
		if (data.success == true) {
			setHbdStatus(data.data.data[0])
		} else {
			console.log('something want wrong')
		}
	}

	const onHandaleRoles = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getRolesById(userInfo?.ROLE);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({ ...obj, ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS) });
			const permissionViewCard = JSON.parse(obj?.ROLES_OTHER_PERMISSIONS)
			onDataInGsm(permissionViewCard, obj)
		} else {
			console.log('something want wrong')
		setLoader(false);

		}
	}

	const ongetScriptCodeIdWiseColumnName = async (SCRIPTDECODE_ID, MODEM_TYPE, METER_TYPE) => {

		const data = await geModemIdWiseColumnName(MODEM_TYPE)
		if (data.success === true) {
			if (data?.data?.data?.length > 0) {
				setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME || '{}'))
			}
		} else {
			console.log('something want wrong');
		}

	}

	const onConsumerUpdateDate=async()=>{
		const data = await getLorawanDataById(params.LoraID);
		if (data.success === true) {
			const objGSM = data.data.data[0];
			if (data.data.data.length > 0) {
				setGSMData({
					...objGSM,
					ADDRESS: JSON.parse(objGSM.ADDRESS),
					CONTACT_PERSON: JSON.parse(objGSM.CONTACT_PERSON),
					INSTALLATION_DETAILS: JSON.parse(objGSM.INSTALLATION_DETAILS),
					METER_DATA: JSON.parse(objGSM.METER_DATA),
					MODEM_DATA: JSON.parse(objGSM.MODEM_DATA),
					UNIQUE_TABLE_NAME: JSON.parse(objGSM.UNIQUE_TABLE_NAME),
					HEARTBEAT_LAST_DATA: JSON.parse(objGSM.HEARTBEAT_LAST_DATA)
				});
			}else{

			}
		}else{

		}
	}

	const onDataInGsm = async (permissionViewCard, permitObj) => {
		
		const data = await getLorawanDataById(params.LoraID);
		if (data.success === true) {
			const objGSM = data.data.data[0];
			if (data.data.data.length > 0) {
				setGSMData({
					...objGSM,
					ADDRESS: JSON.parse(objGSM.ADDRESS),
					CONTACT_PERSON: JSON.parse(objGSM.CONTACT_PERSON),
					INSTALLATION_DETAILS: JSON.parse(objGSM.INSTALLATION_DETAILS),
					METER_DATA: JSON.parse(objGSM.METER_DATA),
					MODEM_DATA: JSON.parse(objGSM.MODEM_DATA),
					UNIQUE_TABLE_NAME: JSON.parse(objGSM.UNIQUE_TABLE_NAME),
					HEARTBEAT_LAST_DATA: JSON.parse(objGSM.HEARTBEAT_LAST_DATA)
				});
				const groupObj = {
					CATEGORY0: objGSM?.CAT0_ID,
					CATEGORY1: objGSM?.CAT1_ID,
					CATEGORY2: objGSM?.CAT2_ID,
					CATEGORY3: objGSM?.CAT3_ID,
					CATEGORY4: objGSM?.CAT4_ID,
					CATEGORY5: objGSM?.CAT5_ID
				}
				var flagDul = 0
				if (userInfo?.ROLE != 'ROLE1650614500708') {


					Object.entries(groupObj).map(([key, value], id) => {
						if (value === '') {
							if (flagDul == 0) {
								console.log('ROLES_PERMISSIONS_' + Object.entries(groupObj)[id - 1][0], permitObj, permitObj['ROLES_PERMISSIONS_' + Object.entries(groupObj)[id - 1][0]]);
								const permit = JSON.parse(permitObj['ROLES_PERMISSIONS_' + Object.entries(groupObj)[id - 1][0]])
								setrolesGSMConfig(permit[Object.entries(groupObj)[id - 1][0] + '_config'].filter((item) => (item == Object.entries(groupObj)[id - 1][1])).length > 0 ? 'true' : 'false')

								flagDul = 1
							}
							return true
						}
					})
				}
				ongetScriptCodeIdWiseColumnName(objGSM?.SCRIPTDECODE_ID, objGSM?.MODEM_TYPE, objGSM?.METER_TYPE)
				ongetProfilNameByAccountId(objGSM?.CAT0_ID)
				// if (permissionViewCard?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item) => (item == 'Buffer')).length > 0 || userInfo?.ROLE == 'ROLE1650614500708') {
				// 	onGetBuferCountInByStatus(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.lorabuffer)
				// }
				onAllBufferdataByStatus(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.lorabuffer)
				ongetErrorbinAndDuplicatbinCountList(objGSM?.UNIQUE_TABLE_NAME)
				
					onGetTableNameWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.alertdata, objGSM?.SCRIPTDECODE_ID)
				
				onLetestCountAndLastDateByTableName(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.alertdata)
				
					onGetTabledailyWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.dailydata, objGSM?.SCRIPTDECODE_ID)
				
				onGetDataDailyTableInEntryts(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.dailydata, objGSM?.SCRIPTDECODE_ID)
				
					onGetTableLoadsurvayWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.hourlydata, objGSM?.SCRIPTDECODE_ID)
				
				onGetTableServerConnectWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.serverconnectreq, objGSM?.SCRIPTDECODE_ID)
				onGetTableTimesyncWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.timesync, objGSM?.SCRIPTDECODE_ID)
					onGetTablemonthWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.hourlydailydata, objGSM?.SCRIPTDECODE_ID)
				onTimesyncLetestCountAndLastDateByTableName(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.timesync)
				onServerConLetestCountAndLastDateByTableName(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.serverconnectreq)
				onUnlockrequestLetestCountAndLastDateByTableName(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.timesync)
				onOtprequestLetestCountAndLastDateByTableName(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.timesync)

				onLoadsurvayLetestCountAndLastDateByTableName(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.hourlydata)
				onDailyLetestCountAndLastDateByTableName(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.dailydata)
				onMonthlyLetestCountAndLastDateByTableName(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.hourlydailydata)
				
					onGetTableHardbeatWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.heartbeatdata, objGSM?.SCRIPTDECODE_ID)
				
				onHardbeatLetestCountAndLastDateByTableName(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.heartbeatdata)
				
					onGetTableRSSIWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.heartbeatdata, objGSM?.SCRIPTDECODE_ID)
				
				onGetTableSFWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.heartbeatdata, objGSM?.SCRIPTDECODE_ID)
				onGetTableSNRWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.heartbeatdata, objGSM?.SCRIPTDECODE_ID)
				onGetTableBATTARYWiseChartDataByDate(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.heartbeatdata, objGSM?.SCRIPTDECODE_ID)
				if (objGSM?.TAGS?.length > 0) {
					ongetTagsInGsm(JSON.parse(objGSM?.TAGS))
				}
				ongetHeatbeatStatusWiseCount(JSON.parse(objGSM?.UNIQUE_TABLE_NAME)?.heartbeatdata)
				setLoader(false);
			} else {
				setLoader(false);

			}
		} else {
			console.log("something want wrong");
			setLoader(false);
		}
	};


	const onRefreshBtn = () => {
		setbtnProccess(true)
		onGetTableNameWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME.alertdata, GSMData?.SCRIPTDECODE_ID)
		onLetestCountAndLastDateByTableName(GSMData?.UNIQUE_TABLE_NAME?.alertdata)
		onGetTabledailyWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME?.dailydata, GSMData?.SCRIPTDECODE_ID)
		onGetTableLoadsurvayWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME?.hourlydata, GSMData?.SCRIPTDECODE_ID)
		onLoadsurvayLetestCountAndLastDateByTableName(GSMData?.UNIQUE_TABLE_NAME?.hourlydata)
		onDailyLetestCountAndLastDateByTableName(GSMData?.UNIQUE_TABLE_NAME?.dailydata)
		onMonthlyLetestCountAndLastDateByTableName(GSMData?.UNIQUE_TABLE_NAME?.hourlydailydata)
		onGetTableHardbeatWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME?.heartbeatdata, GSMData?.SCRIPTDECODE_ID)
		onHardbeatLetestCountAndLastDateByTableName(GSMData?.UNIQUE_TABLE_NAME?.heartbeatdata)
		onGetTableRSSIWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME?.heartbeatdata, GSMData?.SCRIPTDECODE_ID)
		onGetTableSFWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME?.heartbeatdata, GSMData?.SCRIPTDECODE_ID)
		onGetTableSNRWiseChartDataByDate(GSMData?.UNIQUE_TABLE_NAME?.heartbeatdata, GSMData?.SCRIPTDECODE_ID)
		setbtnProccess(false)

	}
	const ongetEventCountList = async (tableName) => {
		const data = await getEventCountList({ UNIQUE_TABLE_NAME: tableName })
		if (data.success === true) {
			setEventCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}

	const ongetErrorbinAndDuplicatbinCountList = async (tableName) => {
		const data = await getLoraErrorbinAndDuplicatbinCountList({ UNIQUE_TABLE_NAME: tableName })
		if (data.success === true) {
			setBinCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}
	const onGetTablemonthWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setMonthlyChart({ total: [], date: [] })

		const data = await GetLoraTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'END_PLS_TS', fild: 'count(*)', TYPE: 'HourlyWiseDaily' })
		if (data.success === true) {
			setMonthlyChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (item.date)) })
		} else {
			console.log('something want wrong')
		}
	}
	const secondtohours = (totalSecond) => {
		// 		var duration = moment.duration(totalSecond, 'seconds');
		// var formatted = duration.format("HH.mm");

		return moment.utc(1000 * totalSecond).format('HH.mm')
	}

	const onGetTableNameWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setInstantaneousChart({ total: [], date: [] })
		const data = await GetLoraTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'ENTRYTS', fild: 'count(*)' })
		if (data.success === true) {
			setInstantaneousChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something want wrong')
		}
	}
	const onGetTableLoadsurvayWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		const data = await GetLoraTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'ENDTS', fild: 'count(*)' })
		if (data.success === true) {
			setLoadsurvayChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something want wrong')
		}
	}
	const onGetTableTimesyncWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		settimesyncChart({ total: [], date: [] })
		const data = await GetLoraTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'ENTRYTS', fild: 'count(*)' })
		if (data.success === true) {
			settimesyncChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something want wrong')
		}
	}
	const onGetTableServerConnectWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setserverConChart({ total: [], date:[] })
		const data = await GetLoraTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'ENTRYTS', fild: 'count(*)' })
		if (data.success === true) {
			setserverConChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something want wrong')
		}
	}

	const onGetTabledailyWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setDailyChart({ total: [], date: [] })
		const data = await GetLoraTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'END_PLS_TS', fild: 'count(*)' })
		if (data.success === true) {
			setDailyChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something want wrong')
		}
	}

	const onGetDataDailyTableInEntryts = async (tableName, SCRIPTDECODE_ID) => {
		setDailyETChart({ total: [], date: [] })
		const data = await GetLoraTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'ENTRYTS', fild: 'count(*)' })
		if (data.success === true) {
			setDailyETChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something want wrong')
			setDailyETChart({total:[],date:[],res_code:data?.err?.response?.status})
		}
	}

	const onGetTableHardbeatWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setHardbeatChart({ total: [], date: [] })
		const data = await GetLoraTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'ENTRYTS', fild: 'count(*)' })
		if (data.success === true) {
			setHardbeatChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something want wrong')
		}
	}



	const onGetTableRSSIWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setIsChartLoading((prevState) => ({
			...prevState,
			GSM_RSSI: true
		}));
		const data = await getRSSITotalLora({ tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'LRSSI' })
		if (data.success === true) {
			setRSSIChart({ total: data.data.data.map((item) => (item.GSM_RSSI)), date: data.data.data.map((item) => ([moment.utc(item.ENTRYTS).format("DD-MM-YYYY"), moment.utc(item.ENTRYTS).format("hh:mm:ss A"),`(${moment.utc(item.ENTRYTS).format("ddd")})`])) })
			setIsChartLoading((prevState) => ({
				...prevState,
				GSM_RSSI: false
			}));
		} else {
			console.log('something want wrong')
			setIsChartLoading((prevState) => ({
				...prevState,
				GSM_RSSI: false
			}));
		}
	}
	const onGetTableSNRWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setIsChartLoading((prevState) => ({
			...prevState,
			SNR: true
		}));
		const data = await getRSSITotalLora({ tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'LSNR' })
		if (data.success === true) {
			setSNRChart({ total: data.data.data.map((item) => (item.GSM_RSSI)), date: data.data.data.map((item) => ([moment.utc(item.ENTRYTS).format("DD-MM-YYYY"), moment.utc(item.ENTRYTS).format("hh:mm:ss A"),`(${moment.utc(item.ENTRYTS).format("ddd")})`])) })
			setIsChartLoading((prevState) => ({
				...prevState,
				SNR: false
			}));
		} else {
			console.log('something want wrong')
			setIsChartLoading((prevState) => ({
				...prevState,
				SNR: false
			}));
		}
	}
	const onGetTableSFWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setIsChartLoading((prevState) => ({
			...prevState,
			SF: true
		}));
		const data = await getRSSITotalLora({ tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'LSF' })
		if (data.success === true) {
			setSFChart({ total: data.data.data.map((item) => (item.GSM_RSSI)), date: data.data.data.map((item) => ([moment.utc(item.ENTRYTS).format("DD-MM-YYYY"), moment.utc(item.ENTRYTS).format("hh:mm:ss A"),`(${moment.utc(item.ENTRYTS).format("ddd")})`])) })
			setIsChartLoading((prevState) => ({
				...prevState,
				SF: false
			}));
		} else {
			setIsChartLoading((prevState) => ({
				...prevState,
				SF: false
			}));
			console.log('something want wrong')
		}
	}
	const onGetTableBATTARYWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setIsChartLoading((prevState) => ({
			...prevState,
			Battery_Percentage: true
		}));
		const data = await getRSSITotalLora({ tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'BAT_PER' })
		if (data.success === true) {
			setBAT_PERChart({ total: data.data.data.map((item) => (item.GSM_RSSI)), date: data.data.data.map((item) => ([moment.utc(item.ENTRYTS).format("DD-MM-YYYY"), moment.utc(item.ENTRYTS).format("hh:mm:ss A"),`(${moment.utc(item.ENTRYTS).format("ddd")})`])) })
			setIsChartLoading((prevState) => ({
				...prevState,
				Battery_Percentage: false
			}));
		} else {
			console.log('something want wrong')
			setIsChartLoading((prevState) => ({
				...prevState,
				Battery_Percentage: false
			}));
		}
	}
	const onGetBuferCountInByStatus = async (tableName) => {
		const data = await GetBuferCountInByStatus(params.LoraID, tableName)
		if (data.success === true) {
			setBufferChart(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}

	const onAllBufferdataByStatus = async (tableName) => {
		const data = await getLoraBufferInDeshboardAllCountByStatus(params.LoraID, tableName)
		if (data.success === true) {
			setAllBufferStatus(data.data.data)
		} else {
			console.log('somethig want wrong');
		}
	}
	useEffect(() => {
		onHandaleRoles()
	}, []);
	const onGetTableNameWiseChartDataBy15Date = async (tableName) => {
		const data = await GetLoraTableNameWiseChartDataByDate({ date: 15, tableName: GSMData?.UNIQUE_TABLE_NAME?.INSTANTANEOUS, scriptid: GSMData?.SCRIPTDECODE_ID, col: 'ENTRYTS', fild: 'count(*)' })
		if (data.success === true) {
			setInstantaneous15Chart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something want wrong')
		}
	}
	const onLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByLoraTableName({ tableName: tableName, col: 'ENTRYTS' })
		if (data.success === true) {
			setInstantaneousCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}
	const onLoadsurvayLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByLoraTableName({ tableName: tableName, col: 'ENDTS' })
		if (data.success === true) {
			setLoadsurvayCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}
	const onTimesyncLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByLoraTableName({ tableName: tableName, col: 'ENTRYTS' })
		if (data.success === true) {
			setTimeSyncCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}
	const onServerConLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByLoraTableName({ tableName: tableName, col: 'ENTRYTS' })
		if (data.success === true) {
			setserverconCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}

	const onOtprequestLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByLoraTableName({ tableName: tableName, col: 'ENTRYTS' })
		if (data.success === true) {
			setotpCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}

	const onUnlockrequestLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByLoraTableName({ tableName: tableName, col: 'ENTRYTS' })
		if (data.success === true) {
			setUnlockCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}
	const onDailyLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByLoraTableName({ tableName: tableName, col: 'END_PLS_TS' })
		if (data.success === true) {
			setDailyCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}
	const onMonthlyLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByLoraTableName({ tableName: tableName, col: 'END_PLS_TS' })
		if (data.success === true) {
			setMonthlyCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}

	const onHardbeatLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByLoraTableName({ tableName: tableName, col: 'ENTRYTS' })
		if (data.success === true) {
			setHardbeatCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}



	const Instantaneousstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: InstantaneousChart.date.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Alert",
				data: InstantaneousChart.total
			}
		]
	};
	const Loadsurveystate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: LoadsurvayChart.date.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Hourly",
				data: LoadsurvayChart.total
			}
		]
	};
	const TimeSyncstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: timesyncChart.date.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Timesync",
				data: timesyncChart.total
			}
		]
	};
	const ServerConnstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: serverConChart.date.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Server Connect",
				data: serverConChart.total
			}
		]
	};
	const Dailystate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: DailyChart.date.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Dailyload",
				data: DailyChart.total
			}
		]
	};

	const DailyETstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: DailyETChart.date.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Daily Data BackFill",
				data: DailyETChart.total
			}
		]
	};
	const Monthlytate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: MonthlyChart.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Monthbill",
				data: MonthlyChart.total
			}
		]
	};
	const Hardbeatstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: HardbeatChart.date.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Heartbeat",
				data: HardbeatChart.total
			}
		]
	};
	const BAT_PERstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: BAT_PERChart.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Battry Per.",
				data: BAT_PERChart.total
			}
		]
	};
	const RSSIstate = {
		options: {
			chart: {
				id: 'area-datetime',
				toolbar: {
					show: false
				}
			},
			dataLabels: {
				enabled: true,
				offsetY: -10,
				style: {

					// fontSize: "12px",
					fontSize: "10px",
					fontWeight: 100,
					colors: ["#0d94fa"]
				},
				background: {
					enabled: true,

				},
			},
			xaxis: {
				categories: RSSIChart.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 10,
					style: {
						fontSize: "9px"
					}
				},

			},
			yaxis: {
				forceNiceScale: true,
				// min: 0,
				// max: 32,
			},
			markers: {
				size: 7,
				colors: ["#0d94fa"],
				strokeWidth: 0,
				hover: {
					sizeOffset: 1
				}
			},
			grid: {
				padding: {
					top: 0,
					right: 30,
					bottom: 0,
					left: 30
				},

			},

			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 100]
				}
			},
		},

		series: [
			{
				name: "RSSI",
				data: RSSIChart.total
			}
		]
	};
	const SNRstate = {
		options: {
			chart: {
				id: 'area-datetime',
				toolbar: {
					show: false
				}
			},
			dataLabels: {
				enabled: true,
				offsetY: -10,
				style: {

					// fontSize: "12px",
					fontSize: "10px",
					fontWeight: 100,
					colors: ["#0d94fa"]
				},
				background: {
					enabled: true,

				},
			},
			xaxis: {
				categories: SNRChart.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 10,
					style: {
						fontSize: "9px"
					}
				},

			},
			yaxis: {
				forceNiceScale: true,
				// min: 0,
				// max: 32,
			},
			markers: {
				size: 7,
				colors: ["#0d94fa"],
				strokeWidth: 0,
				hover: {
					sizeOffset: 1
				}
			},
			grid: {
				padding: {
					top: 0,
					right: 30,
					bottom: 0,
					left: 30
				},

			},

			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 100]
				}
			},
		},

		series: [
			{
				name: "SNR",
				data: SNRChart.total
			}
		]
	};
	const SFstate = {
		options: {
			chart: {
				id: 'area-datetime',
				toolbar: {
					show: false
				}
			},
			dataLabels: {
				enabled: true,
				offsetY: -10,
				style: {

					// fontSize: "12px",
					fontSize: "10px",
					fontWeight: 100,
					colors: ["#0d94fa"]
				},
				background: {
					enabled: true,

				},
			},
			xaxis: {
				categories: SFChart.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 10,
					style: {
						fontSize: "9px"
					}
				},

			},
			yaxis: {
				forceNiceScale: true,
				// min: 0,
				// max: 32,
			},
			markers: {
				size: 7,
				colors: ["#0d94fa"],
				strokeWidth: 0,
				hover: {
					sizeOffset: 1
				}
			},
			grid: {
				padding: {
					top: 0,
					right: 30,
					bottom: 0,
					left: 30
				},

			},

			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 100]
				}
			},
		},

		series: [
			{
				name: "RSSI",
				data: SFChart.total
			}
		]
	};


	const Instantaneous15state = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 10,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: Instantaneous15Chart.date.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: true
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: ModelHeader,
				data: Instantaneous15Chart.total
			}
		]
	};
	const expandBat_per = {
		labels: RSSIChartInDate.date,
		datasets: [
			{
				label: ModelHeader,
				backgroundColor: ["rgba(103, 183, 220, 0.85)", "rgba(103, 148, 220, 0.85)", "rgba(103, 113, 220, 0.85)", "rgba(128, 103, 220, 0.85)", "rgba(163, 103, 220, 0.85)", "rgba(199, 103, 220, 0.85)", "rgba(220, 103, 206, 0.85)", "rgba(103, 113, 220, 0.85)"],
				data: RSSIChartInDate.total,
			},
		],
	};
	const expandChartData = {
		labels: Instantaneous15Chart.date.map((item)=>([item,`(${moment(item,'DD-MM-YYYY').format('ddd')})`])),
		datasets: [
			{
				label: ModelHeader,
				backgroundColor: ["rgba(103, 183, 220, 0.85)", "rgba(103, 148, 220, 0.85)", "rgba(103, 113, 220, 0.85)", "rgba(128, 103, 220, 0.85)", "rgba(163, 103, 220, 0.85)", "rgba(199, 103, 220, 0.85)", "rgba(220, 103, 206, 0.85)", "rgba(103, 113, 220, 0.85)"],
				data: Instantaneous15Chart.total,
			},
		],
	};
	const expandChartoptions = {
		responsive: true,
		maintainAspectRatio: false,

		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};

	const RSSIstateIndate = {
		options: {
			chart: {
				id: 'area-datetime',
				toolbar: {
					show: true
				}
			},
			dataLabels: {
				enabled: true,
				offsetY: 0,
				style: {

					// fontSize: "12px",
					fontSize: "10px",
					fontWeight: 100,
					colors: ["#0d94fa"]
				},
				background: {
					enabled: true,

				},
			},
			xaxis: {
				categories: RSSIChartInDate.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 10,
					style: {
						fontSize: "9px"
					}
				},

			},
			yaxis: {
				forceNiceScale: true,
				min: 0,
				max: 32,
			},
			markers: {
				size: 7,
				colors: ["#0d94fa"],
				strokeWidth: 0,
				hover: {
					sizeOffset: 1
				}
			},
			grid: {
				padding: {
					top: 0,
					right: 30,
					bottom: 0,
					left: 30
				},

			},

			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 100]
				}
			},
		},

		series: [
			{
				name: "RSSI",
				data: RSSIChartInDate.total
			}
		]
	};
	const ModemStatusState = {
		series: [{
			name: 'Online',
			data: ModemStatus.online_count
		}, {
			name: 'Offline',
			data: ModemStatus.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					columnWidth: ModemStatus.date.length < 3 ? 10 + (60 / (1 + 30 * Math.exp(-ModemStatus.date.length / 3))) : '60%',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			xaxis: {
				categories: ModemStatus?.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
				labels: {
					formatter: (value) => {
						return value.toFixed(0)
					},
				}
			}],
			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}

	const minutestohours = (totalMinutes) => {
		var minutes = Math.round(totalMinutes % 60);
		var hours = Math.round((totalMinutes - minutes) / 60);
		return hours + ':' + minutes;
	}

	const ModemStatusDurationState = {
		series: [{
			name: 'Online Duration',
			data: ModemStatusDuration.online_count
		}, {
			name: 'Offline Duration',
			data: ModemStatusDuration.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			xaxis: {
				categories: ModemStatusDuration?.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			tooltip: {
				shared: true,
				intersect: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					console.log(series, seriesIndex, dataPointIndex, w);
					return `<div style={{padding:'5px'}}>` + w.config.xaxis.categories[dataPointIndex] + `<br/>` + 'online:' + series[seriesIndex][dataPointIndex] + ' ' + 'Minutes' + `(${minutestohours(series[seriesIndex][dataPointIndex])} Hours)` + `<br/>` + 'offline:' + series[seriesIndex + 1][dataPointIndex] + ' ' + 'Minutes' + `(${minutestohours(series[seriesIndex + 1][dataPointIndex])} Hours)` + `</div>`
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
			}],

			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}
	const ModemStatusDurationStateByDate = {
		series: [{
			name: 'Online Duration',
			data: ModemStatusChartInDate.online_count
		}, {
			name: 'Offline Duration',
			data: ModemStatusChartInDate.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			xaxis: {
				categories: ModemStatusChartInDate?.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			tooltip: {
				shared: true,
				intersect: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					console.log(series, seriesIndex, dataPointIndex, w);
					return `<div style={{padding:'5px'}}>` + w.config.xaxis.categories[dataPointIndex] + `<br/>` + series[seriesIndex][dataPointIndex] + ' ' + 'Minutes' + `<br/>` + 'offline:' + series[seriesIndex + 1][dataPointIndex] + ' ' + 'Minutes' + `</div>`
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
			}],

			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}

	const ModemStatusStatebyDate = {
		series: [{
			name: 'Online',
			data: ModemSTChartInDate.online_count
		}, {
			name: 'Offline',
			data: ModemSTChartInDate.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					},
					columnWidth: '60%',
				},
			},
			xaxis: {
				categories: ModemSTChartInDate?.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
				labels: {
					formatter: (value) => {
						return value.toFixed(0)
					},
				}
			}],
			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}



	const onOpenTagsModal = async () => {
		const data = await getTags()
		if (data.success === true) {
			settagsList(data.data.data.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME, color: item?.TAG_COLOR })))
			setisModalTags(true)
		} else {
			console.log('something want wrong');
		}
	}
	const customStyles = {
		menu: provided => ({
			...provided, width: "max-content",
			minWidth: "100%", zIndex: 9999
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const color = chroma(data.color);
			return {
				...styles,
				backgroundColor: isSelected
					? color
					: isFocused
						? color.alpha(0.1).css()
						: undefined,
				color: color,

				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled
						? isSelected
							? color
							: color.alpha(0.3).css()
						: undefined,
				},
			};
		},
		multiValue: (provided, { data }) => {
			console.log("Colorr ====== ", data)
			const color = chroma(data.color);
			return {
				...provided,
				width: 'min-content',
				backgroundColor: color.alpha(0.2).css(),
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: data.color,
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.color,
			':hover': {
				backgroundColor: data.color,
				color: 'white',
			},
		}),


	}
	const ongetTagsInGsm = async (item) => {
		const data = await getTagsInGsm({ tags: item })
		if (data.success === true) {
			setTagBtn(data.data.data)
			setTagArray(data.data.data.map((items) => (items?.UNIQUE_ID)))
		} else {
			setTagBtn([])
			setTagArray([])
			console.log('something want wrong')
		}
	}

	const onSubmitTags = async () => {
		const data = await UpdateTagsInLorawan({ tags: tagArray }, params.LoraID)
		if (data.success === true) {
			ongetTagsInGsm(tagArray)
			swal.fire('Success', 'Update Tag Successfuly', 'success');
			setisModalTags(false)
		} else {
			console.log('something want wrong')
		}
	}
	const colorBuffer = ["#191d21", "#54ca68", "#6777ef", "#3abaf4", "#fc544b", "#ffa426",'#e17ed5']

	const series = [
		{
			name: "Total",
			type: "column",
			data: BufferChart?.map((item) => (item?.total_length))
		}, {
			name: "Success",
			type: "column",
			data: BufferChart?.map((item) => (item?.success))
		}, {
			name: "Waiting for downlink",
			type: "column",
			data: BufferChart?.map((item) => (item?.wait_down))
		}, {
			name: "Cancelled by user",
			type: "column",
			data: BufferChart?.map((item) => (item?.cancel))
		},
		{
			name: "Timeout",
			type: "column",
			data: BufferChart?.map((item) => (item?.timeout))
		},{
			name: "CRC Error",
			type: "column",
			data: BufferChart?.map((item) => (item?.crcerror))
		}, {
			name: "Waiting for Uplink",
			type: "column",
			data: BufferChart?.map((item) => (item?.wait_up))
		}

	];

	const seriesRelayTime = [
		{
			name: "Relay 1",
			type: "column",
			data: RelayOntimeChart?.load1
		}, {
			name: "Relay 2",
			type: "column",
			data: RelayOntimeChart?.load2
		}, {
			name: "Relay 3",
			type: "column",
			data: RelayOntimeChart?.load3
		}
	];

	const DailyConsumption = {
		options: {
			legend: {
				show: false,
			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: DailyConsuptionChart.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Daily Consumption",
				data: DailyConsuptionChart?.total
			}
		]
	};
	const seriesRelayTimeBydate = {
		labels: RelayOntimeChartBydate?.date,
		datasets: [
			{
				label: "Relay 1",
				data: RelayOntimeChartBydate?.load1,
				barPercentage: 1.0,
				backgroundColor: '#fc544b',
				borderRadius: 5,
			},
			{
				label: "Relay 2",
				data: RelayOntimeChartBydate?.load2,
				barPercentage: 1.0,
				backgroundColor: '#ffa426',
				borderRadius: 5,
			},
			{
				label: "Relay 3",
				data: RelayOntimeChartBydate?.load2,
				barPercentage: 1.0,
				backgroundColor: '#0000ff',
				borderRadius: 5,
			}
		],
	};

	const options = {
		chart: {
			id: "line"
		},

		plotOptions: {
			bar: {
				borderRadius: 5,
			}
		},
		dataLabels: {
			enabled: false
		},
		xaxis: {
			categories: BufferChart?.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))),
			//will be displayed on the x-asis
		}
		,
		colors: colorBuffer
	};

	const optionsRelayonTime = {
		chart: {
			id: "line"
		},
		legend: {
			show: false,

		},
		plotOptions: {
			bar: {
				borderRadius: 5,
			}
		},
		dataLabels: {
			enabled: false
		},
		xaxis: {
			categories: BufferChart?.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))),
			labels: {
				show: true,
				rotate: -90,
				rotateAlways: true,
				offsetX: 0,
				offsetY: 3,
			}
			//will be displayed on the x-asis
		}
		,
		colors: ['#fc544b', '#ffa426', '#0000ff']
	};

	const optionsRelayonTimeBydate = {
		chart: {
			id: "line"
		},
		legend: {
			show: false,

		},
		plotOptions: {
			bar: {
				borderRadius: 5,
			}
		},
		dataLabels: {
			enabled: false
		},
		xaxis: {
			categories: BufferChart?.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))),
			labels: {
				show: true,
				rotate: -90,
				rotateAlways: true,
				offsetX: 0,
				offsetY: 3,
			}
			//will be displayed on the x-asis
		}
		,
		colors: ['#fc544b', '#ffa426', '#0000ff']
	};


	const sum = (arr) => {
		const result = arr.reduce((total, currentValue) => total = total + currentValue, 0);
		return result
	}

	const onOpenModal = (tableName, col, header) => {
		// onGetTableNameWiseChartDataBy15Date()
		ongetStartAndEndDateByTable(tableName, col)
		setModelHeader(header)
	}
	const onOpenModalInRelayontime = async (tableName, col, header) => {
		ongetStartAndEndDateByTable(tableName, col, true)
		setismodalOpenRelayonTime(true)

	}
	const onLoadDataRelayontime = async () => {
		const data = await GetTableNameWiseChartInRelayonTimeByDate({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), col: 'MMD_INFO_RELAY_ON_HIST_DD', fild: 'MMD_PERIOD_RELAY_ON_HIST_LOAD1_S as load1,MMD_PERIOD_RELAY_ON_HIST_LOAD2_S as load2,MMD_PERIOD_RELAY_ON_HIST_LOAD3_S as load3', tableName: GSMData.UNIQUE_TABLE_NAME.RELAY_ON_TIME })
		if (data.success === true) {
			setRelayOntimeChartBydate({ load1: data.data.data.map((item) => (secondtohours(item?.lengths?.load1 || 0))), load2: data.data.data.map((item) => (secondtohours(item?.lengths?.load2 || 0))), load3: data.data.data.map((item) => (secondtohours(item?.lengths?.load3 || 0))), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('something want wrong')
		}
	}
	const ongetStartAndEndDateByTable = async (tableName, col, modal) => {
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			if (!modal) {
				setIsModalVisible(true)
			}
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}

	const ongetInStatusStartAndEndDateByTable = async (tableName, col) => {
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}
	const onOpenModalRssi = (tableName, col, header) => {
		// onGetTableNameWiseChartDataBy15Date()
		ongetStartAndEndDateInRssiByTable(tableName, col)
		setModelHeader(header)
	}
	const ongetStartAndEndDateInRssiByTable = async (tableName, col) => {
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			setisModalVisibleRssi(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}
	const onChange = (date, dateString) => {
		setDates({ ...dates, start: dateString })
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: dateString })
	};

	const onGetDateWiseChart = async () => {
		const x = new Date(moment(dates.start,'DD-MM-YYYY').format('YYYY-MM-DD'));
const y = new Date(moment(dates.end,'DD-MM-YYYY').format('YYYY-MM-DD'));
console.log(x>y,x,y);
            if(x>y){
            return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
            }
		setChartLoader(true)
		var tableName = ''
		var col = ''
		if (ModelHeader == 'Alert') {
			tableName = GSMData.UNIQUE_TABLE_NAME.alertdata
			col = 'ENTRYTS'
		} else if (ModelHeader == 'Heartbeat') {
			tableName = GSMData.UNIQUE_TABLE_NAME.heartbeatdata
			col = 'ENTRYTS'
		} else if (ModelHeader == 'Timesync') {
			tableName = GSMData.UNIQUE_TABLE_NAME.timesync
			col = 'ENTRYTS'
		} else if (ModelHeader == 'Server Connect') {
			tableName = GSMData.UNIQUE_TABLE_NAME.serverconnectreq
			col = 'METER_ENDTS'
		} else if (ModelHeader == 'Hourly') {
			tableName = GSMData.UNIQUE_TABLE_NAME.hourlydata
			col = 'ENDTS'
		} else if (ModelHeader == 'Daily Data') {
			tableName = GSMData.UNIQUE_TABLE_NAME.dailydata
			col = 'END_PLS_TS'
		} else if (ModelHeader == 'Hour Wise Daily') {
			tableName = GSMData.UNIQUE_TABLE_NAME.hourlydailydata
			col = 'END_PLS_TS'
		}else if (ModelHeader == 'Daily Data Backfill') {
			tableName = GSMData.UNIQUE_TABLE_NAME.dailydata
			col = 'ENTRYTS'
		}

		const data = await GetTableNameWiseChartDataByStartDateEndDate({ startDate: ModelHeader == 'Monthbill' ? dates.start : moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: ModelHeader == 'Monthbill' ? dates.end : moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: ModelHeader == 'Daily Consumtion History' ? 'EMT_PENRG_CUM_IMP_WH/' + (ColumnName?.DAILY_LOAD?.filter((item) => (item?.Column == 'EMT_PENRG_CUM_IMP_WH'))[0]?.DivisionNumber || 1000) : 'count(*)', col: col, scriptid: GSMData?.SCRIPTDECODE_ID, tableName: tableName, TYPE: ModelHeader == 'Monthbill' ? 'Monthly' : '',DATA_TYPE:'Lorawan' })
		if (data.success === true) {

			setInstantaneous15Chart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (ModelHeader == 'Monthbill' ? moment(item.date, "YYYY-MM").format("MMMM-YYYY") : moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })

			setChartLoader(false)

		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}

	const onGetDateWiseInRSSIChart = async () => {
		const x = new Date(moment(dates.start,'DD-MM-YYYY').format('YYYY-MM-DD'));
const y = new Date(moment(dates.end,'DD-MM-YYYY').format('YYYY-MM-DD'));
console.log(x>y,x,y);
            if(x>y){
            return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
            }
		setChartLoader(true)
		var tableName = GSMData.UNIQUE_TABLE_NAME.heartbeatdata
		var col = 'ENTRYTS'
		var fild = ''
		if (ModelHeader == 'RSSI') {
			fild = 'ENTRYTS as ENTRYTS,LRSSI as lengths'
		} else if (ModelHeader == 'SNR') {
			fild = 'ENTRYTS as ENTRYTS,LSNR as lengths'
		} else if (ModelHeader == 'SF') {
			fild = 'ENTRYTS as ENTRYTS,LSF as lengths'
		} else if (ModelHeader == 'Battery Percentage') {
			fild = 'ENTRYTS as ENTRYTS,BAT_PER as lengths'
		}
		const data = await GetTableNameWiseChartRSSIDataByStartDateEndDate({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: fild, col: col, scriptid: GSMData?.SCRIPTDECODE_ID, tableName: tableName })
		if (data.success === true) {
			setRSSIChartInDate({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => ([moment.utc(item.ENTRYTS).format("DD-MM-YYYY hh:mm:ss A"),moment.utc(item.ENTRYTS).format("ddd")])) })
			setChartLoader(false)
		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}

	const onGetDateWiseInModemStatusChart = async () => {
		const x = new Date(moment(dates.start,'DD-MM-YYYY').format('YYYY-MM-DD'));
const y = new Date(moment(dates.end,'DD-MM-YYYY').format('YYYY-MM-DD'));
console.log(x>y,x,y);
            if(x>y){
            return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
            }
		setChartLoader(true)
		var tableName = GSMData.UNIQUE_TABLE_NAME.ONLINE_OFFLINE
		var col = 'ENTRYTS'
		const data = await GetModemStatusDurationByTableAndDATE({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), tableName: tableName })
		if (data.success === true) {
			setModemStatusChartInDate({ online_count: data.data.data.map((item) => (item.online_total_duretion)), offline_count: data.data.data.map((item) => (item.offline_total_duretion)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
			setChartLoader(false)
		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}

	const onGetModemStatusByTableAndDate = async () => {
		const x = new Date(moment(dates.start,'DD-MM-YYYY').format('YYYY-MM-DD'));
const y = new Date(moment(dates.end,'DD-MM-YYYY').format('YYYY-MM-DD'));
console.log(x>y,x,y);
            if(x>y){
            return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
            }
		setChartLoader(true)
		var tableName = GSMData.UNIQUE_TABLE_NAME.ONLINE_OFFLINE
		var col = 'ENTRYTS'
		const data = await GetModemStatusByTableAndDate({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), tableName: tableName })
		if (data.success === true) {
			setModemSTChartInDate({ online_count: data.data.data.map((item) => (item.online_count)), offline_count: data.data.data.map((item) => (item.offline_count)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
			setChartLoader(false)
		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}

	
	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Filler,
		Legend,
		zoomPlugin, ChartDataLabels,
		Decimation, TimeScale
	);

	// let range_min = new Date(moment(RSSIChartInDate.date[RSSIChartInDate.date.length - 1], "DD-MM-YYYY hh:mm:ss A").format("MM-DD-YYYY hh:mm:ss A"))  //start date
	// range_min.setHours(range_min.getHours() - 10);

	// let range_max = new Date(moment(RSSIChartInDate.date[RSSIChartInDate.date.length - 1], "DD-MM-YYYY hh:mm:ss A").format("MM-DD-YYYY hh:mm:ss A")) //end date
	// range_max.setHours(range_max.getHours());

	// console.log("Datessss == ", moment(RSSIChartInDate.date[RSSIChartInDate.date.length - 1], "DD-MM-YYYY hh:mm:ss A").format("MM-DD-YYYY hh:mm:ss A"))
	// console.log("Datessss == ", range_min, range_max)

	const optionsRelayontime = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {

					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},

		// parsing: false,
		plugins: {
			decimation: {
				enabled: true,
				algorithm: 'lttb',
				samples: 800,
				threshold: 800
			},
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				backgroundColor: function (context) {
					return context.dataset.borderColor;
				},
				borderRadius: 4,
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		elements: {
			line: {
				fill: true,
				tension: 0.3
			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	};

	const optionsRSSI = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {

					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},

		// parsing: false,
		plugins: {
			decimation: {
				enabled: true,
				algorithm: 'lttb',
				samples: 800,
				threshold: 800
			},
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				backgroundColor: function (context) {
					return context.dataset.borderColor;
				},
				borderRadius: 4,
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		elements: {
			line: {
				fill: true,
				tension: 0.3
			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	};

	const dataRSSI = {
		labels: RSSIChartInDate.date,
		datasets: [
			{
				label: ModelHeader,
				data: RSSIChartInDate.total,
				fill: true,
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgba(53, 162, 235, 0.8)");
					gradient.addColorStop(1, "rgba(53, 162, 235, 0)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		]
	};
	const chartRef = useRef()
	const myChart = chartRef.current;
	const zoomIn = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(1.2);
		}
	}
	const zoomOut = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(0.8);
		}
	}

	const panLeft = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: 400 }, undefined, 'default');
		}
	}
	const panRight = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: -400 }, undefined, 'default');
		}
	}
	setTimeout(() => {
		if (myChart != undefined) {
			myChart.zoom(1.49);
			myChart.pan({
				x: 500
			}, undefined, 'default');

			// myChart.pan({
			// 	x: - (Number.MAX_SAFE_INTEGER)
			// }, undefined, 'default');

		}

	}, 10)
	const [NameObj, setNameObj] = useState({})
	const ongetProfilNameByAccountId = async (id) => {
		const data = await getProfilNameByAccountId({ ID: id })
		if (data.success === true) {
			setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
		} else {
			console.log('something want wrong')
		}
	}
	return (
		<>

			<Modal title={'Relay On Time'} visible={ismodalOpenRelayonTime} maskClosable={false} footer='' onCancel={() => setismodalOpenRelayonTime(false)} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(0, 'days') >= current) || (moment(expireDate.end_date).add(1, 'days') <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(0, 'days') >= current) || (moment(expireDate.end_date).add(1, 'days') <= current);
								}} /></div>
						</Space>
						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onLoadDataRelayontime())}>Submit</a>
						</div>

					</div>

					<div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div>

				</div>

				{RelayOntimeChart?.load1.length > 0 || RelayOntimeChart?.load2.length > 0 || RelayOntimeChart?.load3.length > 0 ? <div className=" tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={optionsRelayontime} data={seriesRelayTimeBydate} height={"500"} /> </div> : ''}

			</Modal>
			<Modal title="Tags" visible={isModalTags} footer='' maskClosable={false} onCancel={() => (setisModalTags(false))} width={500}>
				<div class="form-row">
					<div class="form-group col-md-12">
						<div>
							<label for="accountuser">Tags</label>
						</div>
						<Select placeholder='Select Tags...' defaultValue={TagBtn.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME, color: item?.TAG_COLOR }))} styles={customStyles} name='tags' isMulti options={tagsList} onChange={(e) => { setTagArray(e.map((item) => (item.value))) }} />
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Submit" name="filter_customer_form" onClick={onSubmitTags} />
					<Link class="btn btn-light text-dark ml-2" onClick={() => (setisModalTags(false))}>
						Close
					</Link>
				</div>
			</Modal>


			<Modal title={ModelHeader} visible={isModalVisible} footer='' maskClosable={false} onCancel={handleCancel} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment() <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment() <= current);
								}} /></div>
						</Space>
						<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseChart())}>Submit</a>
					</div>
					{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 && <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div>}
				</div>
				{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 ? <div className="tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={expandChartoptions} data={expandChartData} /> </div> : ''}
			</Modal>

			<Modal title={ModelHeader} visible={isModalVisibleRssi} maskClosable={false} footer='' onCancel={handleCancelModalRSSI} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment() <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment() <= current);
								}} /></div>
						</Space>
						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseInRSSIChart())}>Submit</a>
						</div>

					</div>

					{RSSIChartInDate.total.length > 0 && RSSIChartInDate.date.length > 0 ? <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div> : ''}

				</div>

				{RSSIChartInDate.total.length > 0 && RSSIChartInDate.date.length > 0 && <>
					<div className=" tw-h-[560px] tw-max-h-screen tw-px-5">
						{ModelHeader == "Battery Percentage" ? <Bar ref={chartRef} options={expandChartoptions} data={expandBat_per} /> : <Line ref={chartRef} options={optionsRSSI} data={dataRSSI} />} </div>
				</>
				}

			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleMonth} maskClosable={false} footer='' onCancel={handleCancelMonth} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='MM-YYYY' name='start' picker="month" disabledDate={(current) => current.isAfter(moment())} /></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='MM-YYYY' picker="month" disabledDate={(current) => current.isAfter(moment())} /></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={onGetDateWiseChart}>Submit</a>
				</div>
				{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 && sum(Instantaneous15Chart.total) > 0 ? <Chart options={Instantaneous15state.options} type="bar" series={Instantaneous15state.series}
					height={"500"} /> : ''}
			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleModemStatus} footer='' maskClosable={false} onCancel={() => setisModalVisibleModemStatus(false)} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start'
								disabledDate={(current) => current.isAfter(moment())} /></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => current.isAfter(moment())} /></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseInModemStatusChart())}>Submit</a>
				</div>
				{ModemStatusChartInDate.online_count.length > 0 && ModemStatusChartInDate.offline_count.length > 0 && ModemStatusChartInDate.date.length > 0 ? <Chart options={ModemStatusDurationStateByDate.options} type="bar" series={ModemStatusDurationStateByDate.series}
					height={"500"} /> : ''}
			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleModemST} footer='' maskClosable={false} onCancel={() => setisModalVisibleModemST(false)} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start'
								disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);
								}} /></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
								return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);
							}} /></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetModemStatusByTableAndDate())}>Submit</a>

				</div>

				{ModemSTChartInDate.online_count.length > 0 && ModemSTChartInDate.offline_count.length > 0 && ModemSTChartInDate.date.length > 0 ? <Chart options={ModemStatusStatebyDate.options} type="bar" series={ModemStatusStatebyDate.series}
					height={"500"} /> : ''}
			</Modal>
			<Modal title="Buffer Data" visible={BufferModal} footer='' maskClosable={false} onCancel={() => (setBufferModal(false))} width={800}>
										{BufferChart.length > 0 && BufferChart.length > 0 ? <Chart options={options} type="bar" series={series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
			</Modal>
			<div className="main-content">
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<div class="col-12 d-flex justify-content-between align-items-center pb-3 px-0">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb p-0 mb-0">
							<li class="breadcrumb-item">
								<a href="all-dashboard" class="open_rightsidebar">
									<i class="fas fa-tachometer-alt"></i> Home
								</a>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								<i class="fas fa-user-shield"></i> Consumer
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								<Link to="/All-Lorawan" class="open_rightsidebar">
									<i class="fas fa-list"></i> Lorawan{" "}
								</Link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								View Lorawan Consumer{" "}
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								{params.LoraID}
							</li>
						</ol>
					</nav>
				</div>
				<div class="row">
					<div class="col-xl-3 col-lg-5 col-md-5 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Consumer Info</h4>
								<div class="card-header-action">
								{GSMData?.LATITUDE&&GSMData?.LONGITUDE?<a href={GSMData?.LATITUDE&&GSMData?.LONGITUDE?`https://map.google.com/?q=${GSMData?.LATITUDE},${GSMData?.LONGITUDE}`:'#'} target={'_blank'} class={`btn btn-success btn-sm text-white font-5 mr-2 ${GSMData?.LATITUDE&&GSMData?.LONGITUDE?'':''}`}>
										<BootstrapTooltip title='Directions'>
											<a >
												<i class="fas fa-directions"></i>
											</a></BootstrapTooltip></a>:<BootstrapTooltip title='lat-long is not available'>
												<span className='mr-2'><a class={`btn btn-success btn-sm text-white font-5  ${GSMData?.LATITUDE&&GSMData?.LONGITUDE?'':'tw-opacity-30'}`}>
											<a >
												<i class="fas fa-directions"></i>
											</a></a></span></BootstrapTooltip>}
											{GSMData?.LATITUDE&&GSMData?.LONGITUDE?<a href={GSMData?.LATITUDE&&GSMData?.LONGITUDE?`/lorawan-map-view?${GSMData?.UNIQUE_ID}`:'#'} target={'_blank'}
										class={`btn btn-danger btn-sm text-white font-5 ${GSMData?.LATITUDE&&GSMData?.LONGITUDE?'':''}`}
									><BootstrapTooltip title='Location'>
											<a>
												<i class="fas fa-map-marker-alt"></i>
											</a>
										</BootstrapTooltip></a>:<BootstrapTooltip title='lat-long is not available'><span><a class={`btn btn-danger btn-sm text-white font-5 ${GSMData?.LATITUDE&&GSMData?.LONGITUDE?'':'tw-opacity-30'}`}
									>
											<a
											>
												<i class="fas fa-map-marker-alt"></i>
											</a>
										</a></span></BootstrapTooltip>}
								</div>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '152px' }}>
								<div class="row font-10">
									<div class="col-lg-12">
										<b>Consumer Name : </b>
										{GSMData?.ADDRESS?.address_compony_name}
										<br />
										<b>Business Partner Number : </b>
										{GSMData?.BUSINESS_PARTNER_NUMBER}
										<br />
										<b>Installation Number : </b>
										{GSMData?.INSTALLATION_NUMBER}
										<br />
										<b>Customer ID : </b>
										{GSMData?.CUSTOMER_ID}
										<br />
										<b>Consumer Unique ID : </b>
										{GSMData?.UNIQUE_ID}
										<br />
										<b>Consumer Status : </b>
										{GSMData?.CUSTOMER_STATUS == 1 ? (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-green mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill="currentColor"
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													Active
												</span>
												<br />
											</>
										) : GSMData?.CUSTOMER_STATUS == -1 ? (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-yellow mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill="currentColor"
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													Pending Configuration
												</span>
												<br />
											</>
										) : GSMData?.CUSTOMER_STATUS == 0 ? (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-red mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill="currentColor"
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													InActive
												</span>
												<br />
											</>
										) : GSMData?.CUSTOMER_STATUS == 2 ? (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-red mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill="#3abaf4"
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													Under Maintenance
												</span>
												<br />
											</>
										) : (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-red mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill=""
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													Stand By
												</span>
												<br />
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Group Info</h4>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '154px' }}>
								<div class="row font-10">
									<div class="col-lg-12">
										<b>Account : </b>
										{GSMData?.ACCOUNT_NAME || "-"}
										<br />
										<b>{NameObj?.CATEGORY1 || "CATEGORY1"} : </b>
										{GSMData?.GA_NAME || "-"}
										<br />
										<b>{NameObj?.CATEGORY2 || "CATEGORY2"} : </b> {GSMData?.CIRCLE_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY3 || "CATEGORY3"} : </b> {GSMData?.DIVISION_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY4 || "CATEGORY4"} : </b> {GSMData?.SUB_DIVISION_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY5 || "CATEGORY5"} : </b> {GSMData?.AREA_NAME || "-"}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Modem Info</h4>

							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '153px' }}>
								<div class="row font-10">
									<div class="">
										<b>Modem Serial No : </b>
										{GSMData?.DEVICE_SERIAL_NO}
										<br />
										<b>Panel Serial No : </b>
										{GSMData?.PANEL_SERIAL_NO}
										<br />
										<b>EUI : </b>
										{GSMData?.IMEI}
										<br />

										<b>RSSI : </b>
										<div className="d-inline-block text-right ">
											{GSMData?.HEARTBEAT_LAST_DATA?.LRSSI >= -150 && GSMData?.HEARTBEAT_LAST_DATA?.LRSSI <= -111 ? (
												<>
													<img src={signal1} style={{ height: '20px', width: "40px", border: '0', boxShadow: 'none', borderRadius: 'none' }} />
													<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.LRSSI} </span>
												</>
											) : GSMData?.HEARTBEAT_LAST_DATA?.LRSSI >= -51 && GSMData?.HEARTBEAT_LAST_DATA?.LRSSI <= 0 ? (
												<>
													<img src={signal5} style={{ height: '20px', width: "40px", border: '0', borderRadius: '0', boxShadow: 'none' }} />
													<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA.LRSSI} </span>
												</>
											) : GSMData?.HEARTBEAT_LAST_DATA?.LRSSI >= -80 && GSMData?.HEARTBEAT_LAST_DATA?.LRSSI <= -52 ? (
												<>
													<img src={signal4} style={{ height: '20px', width: "40px", border: '0', borderRadius: '0', boxShadow: 'none' }} />
													<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.LRSSI} </span>
												</>
											) : GSMData?.HEARTBEAT_LAST_DATA?.LRSSI >= -95 && GSMData?.HEARTBEAT_LAST_DATA?.LRSSI <= -81 ? (
												<>
													<img src={signal3} style={{ height: '20px', width: "40px", border: '0', borderRadius: '0', boxShadow: 'none' }} />
													<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.LRSSI} </span>
												</>
											) : GSMData?.HEARTBEAT_LAST_DATA?.LRSSI >= -110 && GSMData?.HEARTBEAT_LAST_DATA?.LRSSI <= -95 ? (
												<>
													<img src={signal2} style={{ height: '20px', width: "40px", border: '0', borderRadius: '0', boxShadow: 'none' }} />
													<span className="text-center">{GSMData?.HEARTBEAT_LAST_DATA?.LRSSI} </span>
												</>
											) : (
												"-"
											)}
										</div>
										<br />
										<b>SNR : </b>{GSMData?.HEARTBEAT_LAST_DATA?.LSNR} <br />
										<b>SF : </b>{GSMData?.HEARTBEAT_LAST_DATA?.LSF} <br />
										<b>Battery Percent : </b>{GSMData?.HEARTBEAT_LAST_DATA?.BAT_PER} %<br />
										<b>Heartbeat Last Seen : </b>{GSMData?.HEARTBEAT_TS ? moment.utc(GSMData.HEARTBEAT_TS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}{" "}<br />


									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Meter Info</h4>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '154px' }}>
								<div class=" font-10">
									<b>Mechanical Serial No : </b> - {GSMData?.MECHANICAL_UNIQUE_ID}
								</div>
							</div>
						</div>
					</div>

					<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Tags</h4>
								<div class="card-header-action">
									<BootstrapTooltip title='Tags'>
										<div>
											<a
												onClick={onOpenTagsModal}
												class="btn btn-primary text-white"
												data-toggle="tooltip"
												data-placement="Top"
												title=""
												data-original-title="Tags"
											>
												<i class="fa fa-tag"></i>
											</a>
										</div>
									</BootstrapTooltip>
								</div>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '150px' }}>
								<div class="row" style={{ marginTop: "10px" }}>
									<div class="col-lg-12">
										{TagBtn.length > 0 && TagBtn?.map((item) => (
											<div class={`btn btn-icon icon-left btn-sm  ml-1`} style={{ backgroundColor: item?.TAG_COLOR, color: "white" }}><i class="fa fa-tag fa-lg" style={{ marginRight: '4px' }}></i>{item.TAG_NAME}</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card pb-2">
							<div className="col-12" style={{ marginTop: "15px" }}>
								<div className="buttons">
									{/* <div
											className={`text-${item === 'Consumer Info' ? 'dark' : 'white'} btn ${item === "Dashboard" && "btn-primary"} ${item === "Consumer Info" && "btn-secondary"
												} ${item === "Configuration" && "btn-danger"} ${item === "Modem Data" && "btn-info"} ${item === "Meter Data" && "btn-success"
												}  ${item === "Buffer" && "btn-dark"}  ${item === "Extra" && "btn-purple "} ${item === "Tasks" && "btn-primary "} dashboard  ${buttonArray.findIndex((data) => data === btnText) === id ? "btn-lg btn-text-lg" : ""
												} mb-1 `}
											style={{ marginBottom: "8px" }}
											onClick={() => onHandalebtn(item)}
										>
											{item}
										</div> */}

									{/* <div
											className={`text-${item === 'Consumer Info' ? 'dark' : 'white'} btn ${item === "Dashboard" && "btn-primary"} ${item === "Consumer Info" && "btn-secondary"
												} ${item === "Configuration" && "btn-danger"} ${item === "Modem Data" && "btn-info"} ${item === "Meter Data" && "btn-success"
												}  ${item === "Buffer" && "btn-dark"}  ${item === "Extra" && "btn-purple "} ${item === "Tasks" && "btn-primary "} dashboard  ${buttonArray.findIndex((data) => data === btnText) === id ? "btn-lg btn-text-lg" : ""
												} mb-1 `}
											style={{ marginBottom: "8px" }}
											onClick={() => onHandalebtn(item)}
										>
											{item}
										</div> */}
									{buttonArray.map((item, id) => (
										item == 'Configuration' ? rolesGSMConfig === 'true' || userInfo?.ROLE == 'ROLE1650614500708' ? <button
											style={{
												backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
												color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
												borderBottom: `1px solid ${btnColor[id]}`,
												boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
											}}
											className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
											onClick={() => onHandalebtn(item)}>
											{item}
										</button> : '' : <button
											style={{
												backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
												color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
												borderBottom: `1px solid ${btnColor[id]}`,
												boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
											}}
											className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}

											onClick={() => onHandalebtn(item)}>
											{item}
										</button>
									))}
									<div class="float-right text-right">
										{btnText === "Dashboard" && <a class={`btn btn-info text-white ${btnProccess && 'btn-progress disabled'} align-left`} onClick={onRefreshBtn}>
											<BootstrapTooltip title="Refresh" placement="top"><i className="fas fa-redo"></i></BootstrapTooltip>
										</a>}
										<Link to="/All-Lorawan" class="btn btn-info text-white align-left">
											Back
										</Link>
									</div>
								</div>
							</div>
							{btnText === "Configuration" && <Configuration GSMdata={GSMData} />}
							{btnText === "Modem Data" && <ModemData GSMdata={GSMData} onConsumerUpdateDate={onConsumerUpdateDate}/>}
							{btnText === "EVC/MC Meter Data" && <MeterData GSMdata={GSMData} />}
							{btnText === "Extra" && <Extra GSMdata={GSMData} />}
							{btnText === "Dashboard" &&<div className="card-body"  style={{ paddingTop: "0px",paddingLeft:'15px',paddingBottom:'0px' }}>
				<div className="d-flex justify-content-between">
					<div className="buttons d-flex">
					{subbuttonArray.map((item, id) => (
							<div
							style={{
								backgroundColor: subbuttonArray.findIndex((data) => data === btnSubText) === id ? chroma(btnColor[0]).alpha(0.4).css() : '',
								color: subbuttonArray.findIndex((data) => data === btnSubText) === id ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
								borderBottom: `1px solid ${btnColor[0]}`,
								boxShadow: subbuttonArray.findIndex((data) => data === btnSubText) === id ? `0 2px 8px -3px ${btnColor[0]}` : '',
							}}
							className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${subbuttonArray.findIndex((data) => data === btnSubText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
								onClick={() => setbtnSubText(item)}
							>
								{item}
							</div>
						))}
						</div>
                    </div>
                    </div> }
						</div>
					</div>
				</div>
				{btnText === "Buffer" && <Buffer GSMdata={GSMData} />}
				{btnText === "Tasks" && <ConsumerTask GSMdata={GSMData} permision={permision} />}

				{btnText === "Consumer Info" && <ConsumerInfo GSMdata={GSMData} permision={permision} onConsumerUpdateDate={onConsumerUpdateDate}/>}
				{btnText === "Dashboard" && (
					btnSubText=='Modem'&&(<>
						<div class="row">

							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-info">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Heartbeat</span><BootstrapTooltip title='Find Data In heartbeatdata table ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.heartbeatdata, 'ENTRYTS', 'Heartbeat')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{HardbeatChart.date.length > 0 && HardbeatChart.total.length > 0 ? <Chart options={Hardbeatstate.options} type="bar" series={Hardbeatstate.series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Alert</span><BootstrapTooltip title='Find Data In alertdata table ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.alertdata, 'ENTRYTS', 'Alert')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{InstantaneousChart.date.length > 0 && InstantaneousChart.total.length > 0 ? <Chart options={Instantaneousstate.options} type="bar" series={Instantaneousstate.series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>

							</div>
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Daily Data</span><BootstrapTooltip title='Find Data In dailydata table END_PLS_TS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.dailydata, 'END_PLS_TS', 'Daily Data')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{DailyChart.date.length > 0 && DailyChart.total.length > 0 ? <Chart options={Dailystate.options} type="bar" series={Dailystate.series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div> 
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ marginBottom: "10px" }}>
								<div class="card card-danger " style={{ height: '95%' }}>
									<div class="card-header">
										<h4><span className="mr-2">Information</span> <BootstrapTooltip title='Find Data In gsm_consumer_device_index table(Column MODEM_TYPE,METER_TYPE,HEARTBEAT_LAST_DATA->VERINT,INSTALLATION_DETAILS->Installation_Date_And_Time) ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
									</div>
									<div class="card-body" style={{ overflow: 'auto', height: '334px' }}>
										<ul class="list-group">
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<span>Modem Profile</span>
												<span className={``}>{GSMData?.MODEM_TYPE_NAME}</span>
											</li>
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<span>EVC Profile</span>
												<span className={``}>{GSMData?.METER_TYPE_NAME}</span>
											</li>
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<span>Modem Firmware</span>
												<span className={``}>{GSMData?.HEARTBEAT_LAST_DATA?.VERINT}</span>
											</li>
											
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<span>Installation Date And Time</span>
												<BootstrapTooltip title={moment.utc(GSMData?.INSTALLATION_DETAILS?.Installation_Date_And_Time,['DD-MM-YYYY HH:mm:ss','DD-MM-YYYY hh:mm A']).format('DD-MM-YYYY hh:mm:ss A')}><span className={``}>{moment.utc(GSMData?.INSTALLATION_DETAILS?.Installation_Date_And_Time,['DD-MM-YYYY HH:mm:ss','DD-MM-YYYY hh:mm A']).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm')}</span></BootstrapTooltip>
											</li>
											
										</ul>
									</div>
								</div>
							</div>
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ marginBottom: "10px" }}>
								<div class="card card-info " style={{ height: '95%' }}>
									<div class="card-header">
										<h4><span className="mr-2">Last Alert Status</span><BootstrapTooltip title='Find Data In heartbeatdata table(Letest Data In Column TAMPER_FLG,EDGE_DETECTION,PLS_READ_ERR,EPRM_ERR,CKSM_ERR,INT_DOOR_STATUS,EXT_DOOR_STATUS,RTC_ERR,LADR) ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
									</div>
									<div class="card-body" style={{ overflow: 'auto', height: '334px' }}>
										{ColumnName?.Modem_Data?.heartbeatdata?.length > 0 ? (<ul class="list-group">
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<BootstrapTooltip title={'TAMPER_FLG'}><span>{ColumnName?.Modem_Data?.heartbeatdata?.filter((item) => (item?.Column == 'TAMPER_FLG'))[0]?.Header}</span></BootstrapTooltip>
												<BootstrapTooltip title={HbdStatus?.TAMPER_FLG}><div><span className={`badge ${HbdStatus?.TAMPER_FLG != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{HbdStatus?.TAMPER_FLG != undefined ? HbdStatus?.TAMPER_FLG == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip>
											</li>
											{GSMData?.MODEM_TYPE == 'MODUID1687764102670' ? <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<BootstrapTooltip title={'EDGE_DETECTION'}><span>{ColumnName?.Modem_Data?.heartbeatdata?.filter((item) => (item?.Column == 'EDGE_DETECTION'))[0]?.Header}</span></BootstrapTooltip>
												<BootstrapTooltip title={HbdStatus?.EDGE_DETECTION}><div><span className={`badge ${HbdStatus?.EDGE_DETECTION != 1 ? 'badge-danger' : 'badge-success'} badge-pill`}>{HbdStatus?.EDGE_DETECTION != undefined ? HbdStatus?.EDGE_DETECTION == 1 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip>
											</li> : <li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<BootstrapTooltip title={'EDGE_DETECTION'}><span>{ColumnName?.Modem_Data?.heartbeatdata?.filter((item) => (item?.Column == 'EDGE_DETECTION'))[0]?.Header}</span></BootstrapTooltip>
												<BootstrapTooltip title={HbdStatus?.EDGE_DETECTION}><div><span className={`badge ${HbdStatus?.EDGE_DETECTION != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{HbdStatus?.EDGE_DETECTION != undefined ? HbdStatus?.EDGE_DETECTION == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip>
											</li>}
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<BootstrapTooltip title={'PLS_READ_ERR'}><span>{ColumnName?.Modem_Data?.heartbeatdata?.filter((item) => (item?.Column == 'PLS_READ_ERR'))[0]?.Header}</span></BootstrapTooltip>
												<BootstrapTooltip title={HbdStatus?.PLS_READ_ERR}><div><span className={`badge ${HbdStatus?.PLS_READ_ERR != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{HbdStatus?.PLS_READ_ERR != undefined ? HbdStatus?.PLS_READ_ERR == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip>
											</li>
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<BootstrapTooltip title={'EPRM_ERR'}><span>{ColumnName?.Modem_Data?.heartbeatdata?.filter((item) => (item?.Column == 'EPRM_ERR'))[0]?.Header}</span></BootstrapTooltip>
												<BootstrapTooltip title={HbdStatus?.EPRM_ERR}><div><span className={`badge ${HbdStatus?.EPRM_ERR != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{HbdStatus?.EPRM_ERR != undefined ? HbdStatus?.EPRM_ERR == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip>
											</li>
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<BootstrapTooltip title={'CKSM_ERR'}><span>{ColumnName?.Modem_Data?.heartbeatdata?.filter((item) => (item?.Column == 'CKSM_ERR'))[0]?.Header}</span></BootstrapTooltip>
												<BootstrapTooltip title={HbdStatus?.CKSM_ERR}><div><span className={`badge ${HbdStatus?.CKSM_ERR != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{HbdStatus?.CKSM_ERR != undefined ? HbdStatus?.CKSM_ERR == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip>
											</li>
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<BootstrapTooltip title={'INT_DOOR_STATUS'}><span>{ColumnName?.Modem_Data?.heartbeatdata?.filter((item) => (item?.Column == 'INT_DOOR_STATUS'))[0]?.Header}</span></BootstrapTooltip>
												<BootstrapTooltip title={HbdStatus?.INT_DOOR_STATUS}><div><span className={`badge ${HbdStatus?.INT_DOOR_STATUS != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{HbdStatus?.INT_DOOR_STATUS != undefined ? HbdStatus?.INT_DOOR_STATUS == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip>
											</li>
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<BootstrapTooltip title={'EXT_DOOR_STATUS'}><span>{ColumnName?.Modem_Data?.heartbeatdata?.filter((item) => (item?.Column == 'EXT_DOOR_STATUS'))[0]?.Header}</span></BootstrapTooltip>
												<BootstrapTooltip title={HbdStatus?.EXT_DOOR_STATUS}><div><span className={`badge ${HbdStatus?.EXT_DOOR_STATUS != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{HbdStatus?.EXT_DOOR_STATUS != undefined ? HbdStatus?.EXT_DOOR_STATUS == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip>
											</li>
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<BootstrapTooltip title={'RTC_ERR'}><span>{ColumnName?.Modem_Data?.heartbeatdata?.filter((item) => (item?.Column == 'RTC_ERR'))[0]?.Header}</span></BootstrapTooltip>
												<BootstrapTooltip title={HbdStatus?.RTC_ERR}><div><span className={`badge ${HbdStatus?.RTC_ERR != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{HbdStatus?.RTC_ERR != undefined ? HbdStatus?.RTC_ERR == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip>
											</li>
											<li className="list-group-item d-flex justify-content-between align-items-center mb-0" style={{ padding: "4px 18px" }}>
												<BootstrapTooltip title={'LADR'}><span>{ColumnName?.Modem_Data?.heartbeatdata?.filter((item) => (item?.Column == 'LADR'))[0]?.Header}</span></BootstrapTooltip>
												<BootstrapTooltip title={HbdStatus?.LADR}><div><span className={`badge ${HbdStatus?.LADR != 1 ? 'badge-danger' : 'badge-success'} badge-pill`}>{HbdStatus?.LADR != undefined ? HbdStatus?.LADR == 1 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip>
											</li>
										</ul>) : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">
											<ChartLoaders className={'!tw-border-none'} />
										</div>
										}
									</div>
								</div>
							</div>

							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4>Hourly</h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.hourlydata, 'ENDTS', 'Hourly')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{LoadsurvayChart.date.length > 0 && LoadsurvayChart.total.length > 0 ? <Chart options={Loadsurveystate.options} type="bar" series={Loadsurveystate.series} height={"300"} /> :<div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>
							
							
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Timesync</span><BootstrapTooltip title='Find Data In timesync table ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.timesync, 'ENTRYTS', 'Timesync')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{timesyncChart.date.length > 0 && timesyncChart.total.length > 0 ? <Chart options={TimeSyncstate.options} type="bar" series={TimeSyncstate.series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>

							</div>
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Server Connect</span><BootstrapTooltip title='Find Data In serverconnectreq table ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.serverconnectreq, 'ENTRYTS', 'Server Connect')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{serverConChart.date.length > 0 && serverConChart.total.length > 0 ? <Chart options={ServerConnstate.options} type="bar" series={ServerConnstate.series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>

							</div>
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-info">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Battery Percentage</span><BootstrapTooltip title='Find Data In heartbeatdata table(column BAT_PER) ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onOpenModalRssi(GSMData.UNIQUE_TABLE_NAME.heartbeatdata, 'ENTRYTS', 'Battery Percentage') }}>Expand</a>
										</div>
									</div>
									<div class="card-body pl-2 pr-2" style={{ padding: "0px" }}>
										{/* {BAT_PERChart.date.length > 0 && BAT_PERChart.total.length > 0 ? <Chart options={BAT_PERstate.options} type="bar" series={BAT_PERstate.series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>} */}
										{isChartsLoading.Battery_Percentage ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div> : BAT_PERChart.date.length > 0 && BAT_PERChart.total.length > 0 ? <Chart options={BAT_PERstate.options} type="bar" series={BAT_PERstate.series} height={"300"} /> : <div className="tw-text-center tw-text-2xl tw-min-h-[315px]">No Data Available</div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>

							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-info">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">RSSI</span><BootstrapTooltip title='Find Data In heartbeatdata table(column LRSSI) ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onOpenModalRssi(GSMData.UNIQUE_TABLE_NAME.heartbeatdata, 'ENTRYTS', 'RSSI') }}>Expand</a>
										</div>
									</div>
									<div class="card-body pl-2 pr-2" style={{ padding: "0px" }}>
										{/* {RSSIChart.date.length > 0 && RSSIChart.total.length > 0 ? <Chart options={RSSIstate.options} type="area" series={RSSIstate.series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>} */}
										{isChartsLoading.GSM_RSSI ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div> : RSSIChart.date.length > 0 && RSSIChart.total.length > 0 ? <Chart options={RSSIstate.options} type="area" series={RSSIstate.series} height={"300"} /> : <div className="tw-text-center tw-text-2xl tw-min-h-[315px]">No Data Available</div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Line chart</p>
								</div>
							</div> 
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-info">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">SNR</span><BootstrapTooltip title='Find Data In heartbeatdata table(column LSNR) ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onOpenModalRssi(GSMData.UNIQUE_TABLE_NAME.heartbeatdata, 'ENTRYTS', 'SNR') }}>Expand</a>
										</div>
									</div>
									<div class="card-body pl-2 pr-2" style={{ padding: "0px" }}>
										{SNRChart.date.length > 0 && SNRChart.total.length > 0 ? <Chart options={SNRstate.options} type="area" series={SNRstate.series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Line chart</p>
								</div>
							</div>
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-info">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">SF</span><BootstrapTooltip title='Find Data In heartbeatdata table(column LSF) ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onOpenModalRssi(GSMData.UNIQUE_TABLE_NAME.heartbeatdata, 'ENTRYTS', 'SF') }}>Expand</a>
										</div>
									</div>
									<div class="card-body pl-2 pr-2" style={{ padding: "0px" }}>
										{SFChart.date.length > 0 && SFChart.total.length > 0 ? <Chart options={SFstate.options} type="area" series={SFstate.series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Line chart</p>
								</div>
							</div>
							

							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Daily Data Records</span><BootstrapTooltip title='Find Data In dailydata table ENTRYTS Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.dailydata, 'ENTRYTS', 'Daily Data Backfill')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{DailyETChart.date.length > 0 && DailyETChart.total.length > 0 ? <Chart options={DailyETstate.options} type="bar" series={DailyETstate.series} height={"300"} /> :DailyETChart?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>
							{/* {permision?.ROLES_OTHER_PERMISSIONS?.GSM_CONSUMER_DASH?.MODEM?.view?.filter((item)=>(item=='Monthbill')).length>0||userInfo?.ROLE=='ROLE1650614500708'?<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4>Hour Wise Daily</h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => {
												onOpenModal(GSMData.UNIQUE_TABLE_NAME.dailydata, 'END_PLS_TS', 'Hour Wise Daily')
											}}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{MonthlyChart.date.length > 0 && MonthlyChart.total.length > 0 ? <Chart options={Monthlytate.options} type="bar" series={Monthlytate.series} height={"300"} /> : <div style={{ height: '315px', textAlign: 'center', fontSize: '30px' }}>No Data Available</div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>:''} */}




<div className="row">
							<div class="col-lg-3" style={{ marginBottom: "10px" }}>
								<div class="card card-danger " style={{ height: '94.5%' }}>
									<div class="card-header">
										<h4 class="font-15">Buffer Count</h4>
										<div class="card-header-action">
										<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={()=>{setBufferModal(true)
										onGetBuferCountInByStatus(GSMData.UNIQUE_TABLE_NAME.lorabuffer)}}>Expand</a>
										</div>
									</div>
									<div class="card-body">
										<p class="font-12" style={{ lineHeight: "15px" }}>Total <span class="float-right"><b>{AllBufferStatus?.total_length}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Success <span class="float-right"><b>{AllBufferStatus?.success}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Pending <span class="float-right"><b>{AllBufferStatus?.pending}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Timeout <span class="float-right"><b>{AllBufferStatus?.timeout}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>CRC Error<span class="float-right"><b>{AllBufferStatus?.crcerror}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Cancelled By Consumer <span class="float-right"><b>{AllBufferStatus?.cancel}</b></span></p>
									</div>
								</div>
							</div>
							</div>
							
							
							{/* <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4>Battery Percentage*</h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={{}}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										<Apexchart />
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>

								</div>
							</div> */}

						</div>


						<div className="row">
							<div className="col-12">
								<div className="card  card-danger">
									<div className="card-header">
										<h4>
											GSM Consumer Map
										</h4>
									</div>
									<div class="card-body" style={{ height: '400px', width: "100%" }}>
										<div class="map-inner" style={{ height: '350px', width: "100%" }}>
											{/*dev- AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8 */}
											{/*server-AIzaSyAo0xl_P_6Ga-ee6ggeYe1JAE2jq3PxU5s */}
											<ConsumerArticlesMap
												googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAo0xl_P_6Ga-ee6ggeYe1JAE2jq3PxU5s&v=3.exp&libraries=geometry,drawing,places"
												loadingElement={<div style={{ height: '100%', width: '100%' }} />}
												containerElement={<div style={{ height: '100%', width: '100%' }} />}
												mapElement={<div style={{ height: '100%', width: '100%' }} />}
												onClick={(ev) => { console.log(ev) }}
												mapInfo={GSMData}
												curentpos={{ lat: 21.727293496271507, lng: 75.5823933220894 }}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* <div class="row">
							<div class="col-12"> */}
						<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-5">
							<div class="col">
								<div class="card card-info">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">Heartbeat</h5>
														<h2 class="font-18 col-blue">{HardbeatCount?.total || 0}</h2>
													</div>
												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={heartbeat}
															alt=""
															style={{ paddingTop: "15px" }}
															height="45px"
														/>
													</div>
												</div>
											</div>
											<div class="row ">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={HardbeatCount?.ENTRYTS ? moment(HardbeatCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{HardbeatCount?.ENTRYTS ? moment.utc(HardbeatCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col">
								<div class="card card-info">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">Timesync</h5>
														<h2 class="font-18 col-blue">{TimeSyncCount?.total || 0}</h2>
													</div>
												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={Cycle}
															alt=""
															style={{ paddingTop: "15px" }}
															height="45px"
														/>
													</div>
												</div>
											</div>
											<div class="row ">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={TimeSyncCount?.ENTRYTS ? moment(TimeSyncCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{TimeSyncCount?.ENTRYTS ? moment.utc(TimeSyncCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>


							<div class="col">
								<div class="card card-info">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">Server Connect</h5>
														<h2 class="font-18 col-blue">{ServerConnstate?.total || 0}</h2>
													</div>
												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={serverConnec}
															alt=""
															style={{ paddingTop: "15px" }}
															height="45px"
														/>
													</div>
												</div>
											</div>
											<div class="row ">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={ServerConnstate?.ENTRYTS ? moment(ServerConnstate?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{ServerConnstate?.ENTRYTS ? moment.utc(ServerConnstate?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col">
								<div class="card card-info">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">OTP Request</h5>
														<h2 class="font-18 col-blue">{otpCount?.total || 0}</h2>
													</div>
												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={otpreqest}
															alt=""
															style={{ paddingTop: "15px" }}
															height="45px"
														/>
													</div>
												</div>
											</div>
											<div class="row ">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={otpCount?.ENTRYTS ? moment(otpCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{otpCount?.ENTRYTS ? moment.utc(otpCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col">
								<div class="card card-info">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">Unlock Request</h5>
														<h2 class="font-18 col-blue">{UnlockCount?.total || 0}</h2>
													</div>
												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={unloack}
															alt=""
															style={{ paddingTop: "15px" }}
															height="45px"
														/>
													</div>
												</div>
											</div>
											<div class="row ">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={UnlockCount?.ENTRYTS ? moment(UnlockCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{UnlockCount?.ENTRYTS ? moment.utc(UnlockCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col">
								<div class="card card-success">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">Hourly</h5>
														<h2 class="font-18 col-blue">{LoadsurvayCount?.total || 0}</h2>
													</div>
												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={hourglass}
															alt=""
															style={{ paddingTop: "15px" }}
															height="45px"
														/>
													</div>
												</div>
											</div>
											<div class="row ">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={LoadsurvayCount?.ENTRYTS ? moment.utc(LoadsurvayCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{LoadsurvayCount?.ENTRYTS ? moment.utc(LoadsurvayCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col">
								<div class="card card-success">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">Daily Data</h5>
														<h2 class="font-18 col-blue">{DailyCount?.total || 0}</h2>
													</div>
												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={calendar}
															alt=""
															style={{ paddingTop: "15px" }}
															height="45px"
														/>
													</div>
												</div>
											</div>
											<div class="row ">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={DailyCount?.ENTRYTS ? moment.utc(DailyCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{DailyCount?.ENTRYTS ? moment.utc(DailyCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col">
								<div class="card card-success">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">Hour Wise Daily</h5>
														<h2 class="font-18 col-blue">{MonthlyCount?.total || 0}</h2>
													</div>
												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={timetable}
															alt=""
															style={{ paddingTop: "20px" }}
															height="45px"
														/>
													</div>
												</div>
											</div>
											<div class="row ">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={MonthlyCount?.ENTRYTS ? moment.utc(MonthlyCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{MonthlyCount?.ENTRYTS ? moment.utc(MonthlyCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col">
								<div class="card card-success">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="row ">
													<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
														<div class="card-content">
															<h5 class="font-14">Alert</h5>
															<h2 class="font-18 col-blue">{InstantaneousCount?.total || 0}</h2>
														</div>
													</div>
													<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
														<div class="banner-img">
															<img
																src={server}
																alt=""
																style={{ paddingTop: "15px" }}
																height="45px"
															/>
														</div>
													</div>
												</div>
												<div class="row ">
													<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
														<p class="mb-0 font-10">
															<BootstrapTooltip placement="top" title={InstantaneousCount.ENTRYTS ? moment.utc(InstantaneousCount.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{InstantaneousCount.ENTRYTS ? moment.utc(InstantaneousCount.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* </div>
						</div> */}
						<div className="row">
							<div class="col-lg-3" style={{ marginBottom: "10px" }}>
								<div class="card card-danger " style={{ height: '94.5%' }}>
									<div class="card-header">
										<h4 class="font-15">Buffer Count</h4>
									</div>
									<div class="card-body">
										<p class="font-12" style={{ lineHeight: "15px" }}>Total <span class="float-right"><b>{AllBufferStatus?.total_length}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Success <span class="float-right"><b>{AllBufferStatus?.success}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Pending <span class="float-right"><b>{AllBufferStatus?.pending}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Timeout <span class="float-right"><b>{AllBufferStatus?.timeout}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Cancelled By Consumer <span class="float-right"><b>{AllBufferStatus?.cancel}</b></span></p>
									</div>
								</div>
							</div>

						</div>



						<div class="row">


							<div class="col-lg-3" style={{ marginBottom: "10px" }}>
								<div class="card  card-danger" >
									<div class="card-header">
										<h4 class="font-15">Errorbin Count</h4>
									</div>
									<div class="card-body pt-0" >
										<p class="font-15 mb-0 text-center">Modem</p>
										<hr style={{ margin: '0px 0px 12px 0px' }} />
										<p class="font-12" style={{ lineHeight: "15px" }}>Hardbeat<span class="float-right"><b>{BinCount?.heartbeatdata?.ERC || 0}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Alert<span class="float-right"><b>{BinCount?.alertdata?.ERC || 0}</b></span></p>
										<p class="font-15 mb-0 text-center">EVC/MC Meter</p>
										<hr style={{ margin: '0px 0px 12px 0px' }} />
										<p class="font-12" style={{ lineHeight: "15px" }}>Hour Wise Daily<span class="float-right"><b>{BinCount?.hourlydailydata?.ERC || 0}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Daily<span class="float-right"><b>{BinCount?.dailydata?.ERC || 0}</b></span></p>


									</div>
								</div>
							</div>
							<div class="col-lg-3" style={{ marginBottom: "10px" }}>
								<div class="card  card-danger " >
									<div class="card-header">
										<h4 class="font-15">Duplicatebin Count</h4>
									</div>
									<div class="card-body pt-0" >
										<p class="font-15 mb-0 text-center">Modem</p>
										<hr style={{ margin: '0px 0px 12px 0px' }} />
										<p class="font-12" style={{ lineHeight: "15px" }}>Hardbeat<span class="float-right"><b>{BinCount?.heartbeatdata?.DUC || 0}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Alert<span class="float-right"><b>{BinCount?.alertdata?.DUC || 0}</b></span></p>
										<p class="font-15 mb-0 text-center">EVC/MC Meter</p>
										<hr style={{ margin: '0px 0px 12px 0px' }} />
										<p class="font-12" style={{ lineHeight: "15px" }}>Hour Wise Daily<span class="float-right"><b>{BinCount?.hourlydailydata?.DUC || 0}</b></span></p>
										<p class="font-12" style={{ lineHeight: "15px" }}>Daily<span class="float-right"><b>{BinCount?.dailydata?.DUC || 0}</b></span></p>
									</div>
								</div>
							</div>


						</div>
					</>)
				)}
				{btnText === "Dashboard" && (
					btnSubText=='EVC/MC Meter'&&
					(<LoraMeterDashboard GSMdata={GSMData}/>)
				)}
			</div>
		</>
	);
}
export const ConsumerArticlesMap = withScriptjs(withGoogleMap((props) => {
	const [jobDetail, setJobDetail] = useState({ activeMarker: {}, isOpen: false, Info: {} })
	const onMarkerClicked = (data, marker) => {
		console.log(data, marker);
		setJobDetail({
			activeMarker: { lat: data?.latLng?.lat(), lng: data?.latLng?.lng() },
			isOpen: true,
			Info: marker
		})
	}

	const onToggleOpen = () => {
		setJobDetail({ ...jobDetail, isOpen: false })
	}
	function getSecondsTodayBydate(ts) {
		var now = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'second')
	}

	return (<>
		<GoogleMap
			defaultZoom={5}
			defaultCenter={{ lat: props.curentpos.lat, lng: props.curentpos.lng }}
			onClick={(e) => { console.log("e.latLng.lat()", e.latLng.lat(), "e.latLng.lng()", e.latLng.lng()) }}
		>
			<><Marker
				defaultZoomOnClick={4}
				icon={{
					url: getSecondsTodayBydate(props.mapInfo.HEARTBEAT_TS) < 86400 ? mapicon : mapRedicon,
					scaledSize: new window.google.maps.Size(25, 25)
				}}
				onMouseOver={(data) => onMarkerClicked(data, props?.mapInfo)}
				position={{ lat: parseFloat(props?.mapInfo?.ADDRESS?.address_lattitude || ''), lng: parseFloat(props?.mapInfo?.ADDRESS?.address_longitude || '') }}
			/></>
		</GoogleMap>
		{jobDetail?.activeMarker?.lat && jobDetail.isOpen &&
			<InfoWindow position={{ lat: jobDetail?.activeMarker?.lat, lng: jobDetail?.activeMarker?.lng }} onCloseClick={onToggleOpen} options={{
				pixelOffset: new window.google.maps.Size(
					0, -15
				)
			}}>
				<div className="detailCard">
					<div className="d-flex main-div">
						<div className="d-inline-block">
							{console.log("first == ", jobDetail?.Info?.ADDRESS.address_compony_name)}
							<div className="text-black name">{jobDetail?.Info?.ADDRESS.address_compony_name}
							</div>
						</div>
					</div>
				</div>
			</InfoWindow>}
	</>
	);
}))
