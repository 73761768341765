import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { BootstrapTooltip } from "../../Commoncomponet/common";
import { Schedule_Base_Selles_statistics_Notification, getRolesById, get_notification_script, getalarm_template } from "../../Commoncomponet/Utils";
import chroma from 'chroma-js';
import moment from 'moment'
import * as $ from "jquery";
import dt from "datatables.net-dt";
import Pagination from "../../Commoncomponet/Pagination";
import { toast } from "react-toastify";
$.fn.DataTable = dt;
export default function NotificationScript() {

  const [tagsData,setTagsData]=useState([])
const [filterrolesData,setfilterRolesData]=useState([])
const [loader,setLoader]=useState(false)
const [permision,setpermision]=useState({})
const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
const [activeTab, setActiveTab] = useState("Notification");
const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
const [PageSize, setPageSize] = useState(50);
const [numberofPage, setnumberofPage] = useState(1);
const [currentPage, setCurrentPage] = useState(1);
const [isLoaderun, setisLoaderun] = useState(false);
const history=useHistory()
const onHandaleRoles = async () => {
	const data = await getRolesById(userInfo?.ROLE);
	if (data.success === true) {
		const obj = data.data.data[0];
		setpermision({...obj,ROLES_OTHER_PERMISSIONS:JSON.parse(obj.ROLES_OTHER_PERMISSIONS)});
	}else{
		console.log('Something Went Wrong')
	}
}

  const ongetTagsData=async()=>{
    setLoader(true);
	const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		$(".loader").fadeOut("slow");
const data=await get_notification_script()
if(data.success===true){
  setTagsData(data.data.data)

  setLoader(false)

}else{
  console.log('somthing want wrong')
  setLoader(false)
}
  }

  useEffect(() => {
	onHandaleRoles()
    ongetTagsData()
  }, []);
  const UpdateRolesUser=(item)=>{
		history.push({ pathname: `/add-tags`, state: {...item,permision} })
	}

	const ontrrigerRunscript=async()=>{
		setisLoaderun(true)
		const data=await Schedule_Base_Selles_statistics_Notification()
		if(data.success==true){
toast.success('Script Run Successfull')
setisLoaderun(false)
		}else{
  console.log('somthing want wrong')
  setisLoaderun(false)
		}
	}
	return (
		<div>
    {loader ? (
						<div className="main-content">
							<div class="loader"></div>
						</div>
					) : (
						""
					)}
            
				
				<section class="section">
                
					<div class="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header">
										<h4><span className="mr-2">Notification Script List</span><BootstrapTooltip title='Table Name is notification_script_list' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											
										</div>
									</div>
									<div class="card-body">
                                    <div className="table-responsive">
												
												<table id="table_id" className="table border table-borderless text-center table-striped font-12">
													<thead>
											
												<tr>
													<th className="text-center" style={{ maxWidth: "100px" }}>sr_no</th>
													<th className="text-center">unique_id</th>
													<th className="text-center" style={{ minWidth: "130px" }}>title</th>
													<th className="text-center" style={{ minWidth: "100px" }}>description</th>
													<th className="text-center" style={{ minWidth: "130px" }}>status</th>
													<th className="text-center" style={{ minWidth: "130px" }}>Action</th>
												</tr>
											</thead>
											<tbody>
												{tagsData &&
													tagsData.map((item, id) => (
														<tr style={{ height: "4rem" }} className="trheigth" key={id} >
															<td className="text-center">{item.sr_no}</td>
															<td className="text-center">{item.unique_id}</td>
															<td className="text-center">{item.title}</td>
                                                            <td className="text-center">{item.description}</td>
															{item.status===1?<td className="text-center">
																			<div className="badge badge-success badge-shadow text-white">Active</div>
																		</td>:<td className="text-center">
																			<div className="badge badge-danger badge-shadow text-white">Inactive</div>
																		</td>}
															<td className="text-center">{item.unique_id=='NOTSRCL_1686842512'&&<div className={`btn btn-info ${isLoaderun==true?'disabled btn-progress':''}`} onClick={ontrrigerRunscript}>Run</div>}</td>

														</tr>
													))}
											</tbody>
										</table>
										<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
									</div>
                  </div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		
	);
}
