import React, { useEffect, useState } from "react";
import { Users } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { BootstrapTooltip, exportCSVFile, exportExcelDownload } from "../../Commoncomponet/common";
import GoogleLoader from "../../Commoncomponet/Loaders/GoogleLoader";
import Pagination from "../../Commoncomponet/Pagination";
import {  DownloadModemConsumerDetailsByFilterLora, GetRSSIValue, getAllModem, getModemConsumerDetailsLora, getModemConsumerDetailsByFilterLora, getModemSearchTextConsumerDetailsLora } from "../../Commoncomponet/Utils";
import { Modal } from "antd";
import Select from 'react-select';
import MultiInput from "../../Commoncomponet/MultiInput/MultiInput";
import exports from '../../assets/img/export.png'
import moment from "moment";
import chroma from "chroma-js";
import { Slide, toast } from "react-toastify";

export default function ModemDetails() {
	const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
	const [ModLoader,setModLoader]=useState(false)
	const history=useHistory()
const [modemDList,setmodemDList]=useState([])
const [InputText, setInputText] = useState('')
const [serachLoader, setSerachLoader] = useState(false)
const [isModalVisible, setIsModalVisible] = useState(false);
	const [Filter, setFilter] = useState({ ModemList: [], EUI: '',DSRNO:'',PASRNO:'' })
	const [ModemData,setModemData]=useState([])
	const [filterLoader,setfilterLoader]=useState(false)
	const [searchTerms, setSearchTerms] = useState([]);
	const [API_SEND_DATAModal,setAPI_SEND_DATAModal]=useState(false)
	const [ApiSendData,setApiSendData]=useState([])
	const [ModalJson,setModalJson]=useState({})
	const [activeTab, setActiveTab] = useState("Test Result");
	const [RSSICHECK, setRSSICHECK] = useState({BAT_PER:0,GSM_RSSI:0,PULSE_CNT:0});
	const [CopyModal,setCopyModal]=useState(false)
	const [CopyDetails,setCopyDetails]=useState({})
    const [ModemFilter,setModemFilter]=useState('All')
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']

	const ongetModemConsumerDetails=async(ModemFilter)=>{
		setModLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const data=await getModemConsumerDetailsLora({ start: firstPageIndex, end: PageSize,ModemFilter:ModemFilter })
		if(data?.success===true){
			setmodemDList(data.data.data)
		setModLoader(false)
		setnumberofPage(data.data.Row_Count)
		}else{
		setModLoader(false)
			console.log('something Went wrong')
		}
	}



	const ongetModemConsumerDetailsByFilter=async()=>{
		setfilterLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const data=await getModemConsumerDetailsByFilterLora({ start: firstPageIndex, end: PageSize,Filter_Obj:Filter })
		if(data?.success===true){
			setmodemDList(data.data.data)
			setfilterLoader(false)
			setIsModalVisible(false)
		setnumberofPage(data.data.Row_Count)
		}else{
			setfilterLoader(false)
			console.log('something Went wrong')
		}
	}

	const ongCommonConfig = async () => {
		const data = await GetRSSIValue();
		if (data.success === true) {
			setRSSICHECK(data?.data?.data);
		} else {
			console.log("somthing want wrong");
		}
	}
	const ongetAllModem = async () => {
		const data6 = await getAllModem(true);
		if (data6.success === true) {
			const options = []
			data6?.data?.data?.map((item) => (item?.MODEM_TYPE=='MODTYID1657084967379'?options.push({ value: item?.MODEM_UNIQUE_ID, label: item?.MODEM_PROFILE_NAME }):''))
			setModemData(options);
		} else {
			console.log("somthing want wrong");
		}
	}
	useEffect(()=>{
		ongetAllModem()
		ongCommonConfig()
	},[])
    
    useEffect(()=>{
		ongetModemConsumerDetails(ModemFilter)
    },[ModemFilter])

	useEffect(()=>{
		if(Filter?.ModemList?.length>0|| Filter?.EUI||Filter?.DSRNO||Filter?.PASRNO){
		ongetModemConsumerDetailsByFilter()
		}else{
		ongetModemConsumerDetails(ModemFilter)
		}

	},[currentPage,PageSize])
	const UpdateRolesUser = (item) => {
		history.push({ pathname: `/AddModemDetailsInLorawan`, state: item })
	}

	const onHandalSerch = async () => {
		console.log("OnMultiSearch = ", searchTerms)
		setSerachLoader(true)
		if(searchTerms.length>0){
		const firstPageIndex = (currentPage - 1) * PageSize;

		const data=await getModemSearchTextConsumerDetailsLora({text:searchTerms, start: firstPageIndex, end: PageSize,Filter_Obj:Filter })
		if(data?.success===true){
			setmodemDList(data.data.data)
			setSerachLoader(false)

		setnumberofPage(data.data.Row_Count)
		}else{
			setSerachLoader(false)

			console.log('something Went wrong')
		}
	}else{
		ongetModemConsumerDetails(ModemFilter)
		setSerachLoader(false)
	}
	}

	const onDownloadModemConsumerDetailsByFilter=async(type)=>{
		const data=await DownloadModemConsumerDetailsByFilterLora({ Filter_Obj:Filter })
		if(data?.success===true){
			var headers={'SRNO':'SRNO','UNIQUE_ID':'UNIQUE_ID','MODEM_TYPE':'MODEM_TYPE','EUI':'EUI','DEVICE_SERIAL_NO':'DEVICE_SERIAL_NO','PANEL_SERIAL_NO':'PANEL_SERIAL_NO','MODEM_PROFILE_NAME':'MODEM_PROFILE_NAME'}
			if(type=='csv'){
				exportCSVFile(headers,data.data.data,`Modem_Details`)
			}else{
				exportExcelDownload(data.data.data,`Modem_Details`)
			}
		}else{
			console.log('something Went wrong')
		}
	}
	

	const onHandalSearchMulti = (newSearchTerms) => {
		setSearchTerms(newSearchTerms);
		
	};
	
	const onItemCleared = (clearedIndex) => {
		// Create a new array without the cleared item
		const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onHandalSearchMulti(newSearchTerms);
	};
	const onClearAllItems = () => {
		// Reset the search results to the original data
		onHandalSearchMulti([]);
	
	};

	const onHandalModal=(item,data)=>{
		setAPI_SEND_DATAModal(true)
		setApiSendData(JSON.parse(item)?.reverse())
		setModalJson({DEVICE_SERIAL_NO:data?.C5_DEVICE_SERIAL_NO,EUI:data?.EUI})
	}

	const onHandalCopyModal=(item)=>{
		// setCopyModal(true)
		toast.success('Copied', {
			position: toast.POSITION.TOP_CENTER,
			transition: Slide,
			theme: "colored",
			autoClose: 1000,
		});
		const epoch=Math.round(new Date().getTime() / 1000)
		setCopyDetails({...item,EPOCH:epoch})
		navigator.clipboard.writeText(`${item?.C5_DEVICE_SERIAL_NO}\t${item?.EUI}\t${epoch}`)
	}
	
	return (
		<>
		<Modal title="" visible={API_SEND_DATAModal} footer='' onCancel={() => {setAPI_SEND_DATAModal(false)
            setApiSendData([])
		setModalJson({})
	}} width={800}>
				<div class="row">
									<div class="d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
										<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
											<li>
												<button
													style={{
														backgroundColor: activeTab === "Test Result" ? chroma(btnColor[0]).alpha(0.4).css() : '',
														color:activeTab === "Test Result" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
														borderBottom: `1px solid ${btnColor[0]}`,
														boxShadow: activeTab === "Test Result" ? `0 2px 8px -3px ${btnColor[0]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Test Result" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													data-toggle="tab"
													href="#home"
													role="tab"
													aria-controls="home"
													aria-selected="true"
													onClick={() => setActiveTab("Test Result")}
												>
													Test Result
												</button>
											</li>
											<li>
												<button
													style={{
														backgroundColor: activeTab === "Raw Data" ? chroma(btnColor[2]).alpha(0.4).css() : '',
														color:activeTab === "Raw Data" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
														borderBottom: `1px solid ${btnColor[2]}`,
														boxShadow: activeTab === "Raw Data" ? `0 2px 8px -3px ${btnColor[2]}` : '',
													}}
														className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Raw Data" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
													id="profile-tab"
													data-toggle="tab"
													href="#profile"
													role="tab"
													aria-controls="profile"
													aria-selected="false"
													onClick={() => setActiveTab("Raw Data")}
												>
													Raw Data
												</button>
											</li>
										</ul>
									</div>
								</div>
								{activeTab=='Test Result'&&<div class="table-responsive">
									<div className="mt-2 d-flex">
									<div ><b>DEVICE SERIAL NO: </b>{ModalJson?.DEVICE_SERIAL_NO} , </div>
									<div  className="ml-2"><b>EUI: </b>{ModalJson?.EUI}</div>
									</div>
					<table class="table table-striped mt-4" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Fields</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
							{
                                <>
                                    <tr>
                                        <td style={{ width: "50%",height: "30px" }}>DEVICETS</td>
                                        <td style={{ width: "50%",height: "30px",color:(moment(ApiSendData?.at(0)?.DEVICETS,'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')>=moment(ApiSendData?.at(0)?.ENTRYTS,'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')&&moment(ApiSendData?.at(0)?.DEVICETS,'YYYY-MM-DD HH:mm:ss').diff(moment(ApiSendData?.at(0)?.ENTRYTS,'YYYY-MM-DD HH:mm:ss'),'hours')>5)?'#fc544b':moment(ApiSendData?.at(0)?.ENTRYTS,'YYYY-MM-DD HH:mm:ss').diff(moment(ApiSendData?.at(0)?.DEVICETS,'YYYY-MM-DD HH:mm:ss'),'hours')>5?'#fc544b':'#54ca68' }}>{moment(ApiSendData?.at(0)?.DEVICETS,'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50%",height: "30px" }}>BAT_PER ( Set Point : Grater Then Equal To {RSSICHECK?.BAT_PER} )</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.BAT_PER<RSSICHECK?.BAT_PER?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.BAT_PER}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50%",height: "30px" }}>GSM_RSSI ( Set Point : Grater Then Equal To {RSSICHECK?.GSM_RSSI} )</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.GSM_RSSI<RSSICHECK?.GSM_RSSI?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.GSM_RSSI}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50%",height: "30px" }}>RTC_VOLT ( Set Point : Grater Then Equal To 3000 )</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.RTC_VOLT<3000?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.RTC_VOLT}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>PULSE_CNT ( Set Point : Equal To {RSSICHECK?.PULSE_CNT} )</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.PULSE_CNT!=RSSICHECK?.PULSE_CNT?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.PULSE_CNT}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>EXT_XTAL_EN</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.EXT_XTAL_EN==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.EXT_XTAL_EN}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>UPSW</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.UPSW==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.UPSW}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>DOWNSW</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.DOWNSW==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.DOWNSW}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>LEFTSW</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.LEFTSW==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.LEFTSW}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>RIGHTSW</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.RIGHTSW==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.RIGHTSW}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>ENTERSW</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.ENTERSW==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.ENTERSW}</td>
                                    </tr>
									{/* <tr>
                                        <td style={{ width: "50%",height: "30px" }}>OLED_I2C_DETECT</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.OLED_I2C_DETECT==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.OLED_I2C_DETECT}</td>
                                    </tr> */}
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>STM_COMMUNICATE</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.STM_COMMUNICATE==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.STM_COMMUNICATE}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>ESP_STM_BOOT_PIN</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.ESP_STM_BOOT_PIN==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.ESP_STM_BOOT_PIN}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>STM_ESP_WAKE_PIN</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.STM_ESP_WAKE_PIN==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.STM_ESP_WAKE_PIN}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>ESP_STM_REBOOT_PIN</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.ESP_STM_REBOOT_PIN==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.ESP_STM_REBOOT_PIN}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>STM_RTC</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.STM_RTC==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.STM_RTC}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>I2C_EEPROM</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.I2C_EEPROM==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.I2C_EEPROM}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>SPI_EEPROM</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.SPI_EEPROM==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.SPI_EEPROM}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>EXTERNAL_DOOR</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.EXTERNAL_DOOR==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.EXTERNAL_DOOR}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>INTERNAL_DOOR</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.INTERNAL_DOOR==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.INTERNAL_DOOR}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>TAMPER</td>
                                        <td style={{ width: "50%",height: "30px",color:ApiSendData?.at(0)?.TAMPER==0?'#fc544b':'#54ca68' }}>{ApiSendData?.at(0)?.TAMPER}</td>
                                    </tr>
									<tr>
                                        <td style={{ width: "50%",height: "30px" }}>FRS_TIMESTAMP</td>
                                        <td style={{ width: "50%",height: "30px",color:'#54ca68'}}>{moment.utc(ApiSendData?.at(0)?.FRS_TIMESTAMP,'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}</td>
                                    </tr>
                                </>
                            }
						</tbody>
					</table>
				</div>}
             {activeTab=='Raw Data'&&<pre> {JSON.stringify(ApiSendData, null, 2)}</pre>}
            </Modal>
			<Modal title={<><span>Info</span><BootstrapTooltip title={'Copy'}><span className="ml-2" onClick={()=>{
				toast.success('Copied', {
					position: toast.POSITION.TOP_CENTER,
					transition: Slide,
					theme: "colored",
					autoClose: 1000,
				});
				navigator.clipboard.writeText(`${CopyDetails?.C5_DEVICE_SERIAL_NO}\t${CopyDetails?.EUI}\t${CopyDetails?.EPOCH}`)}} style={{cursor:'pointer',font:'20px'}}><i class="fas fa-copy"></i></span></BootstrapTooltip></>} visible={CopyModal} footer='' onCancel={() => {setCopyModal(false)
			setCopyDetails({})
	}} width={800}>
		<table class="table table-striped mt-4" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Fields</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{ width: "50%",height: "30px" }}>Device Serial No</td>
								<td style={{ width: "50%",height: "30px" }}>{CopyDetails?.C5_DEVICE_SERIAL_NO}</td>
							</tr>
							<tr>
								<td style={{ width: "50%",height: "30px" }}>EUI</td>
								<td style={{ width: "50%",height: "30px" }}>{CopyDetails?.EUI}</td>
							</tr>
							
							{/* <tr>
								<td style={{ width: "50%",height: "30px" }}>WIFIMAC</td>
								<td style={{ width: "50%",height: "30px" }}>{CopyDetails?.WIFIMAC?.match(/.{2}/g)?.join(':')}</td>
							</tr> */}
							<tr>
								<td style={{ width: "50%",height: "30px" }}>EPOCH</td>
								<td style={{ width: "50%",height: "30px" }}>{CopyDetails?.EPOCH}</td>
							</tr>
						</tbody>
						</table>
	</Modal>
		<Modal title="Filter" visible={isModalVisible} footer="" onCancel={()=>setIsModalVisible(false)} width={800}>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputPassword4">Modem Name:</label>
						<Select name='Modem_Profile' options={ModemData} isMulti onChange={(e) => {
										setFilter({ ...Filter, ModemList: e.map((item) => (item.value)) })}}/>
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">EUI:</label>
						<input type="text" className="form-control" name="EUI" id="checktext7" placeholder="Enter imei"  onChange={(e) => {
										setFilter({ ...Filter, EUI: e.target.value })}} />
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">Device Serial No:</label>
						<input type="text" className="form-control" name="EUI" id="checktext7" placeholder="Enter Device Serial No"  onChange={(e) => {
										setFilter({ ...Filter, DSRNO: e.target.value })}} />
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">Panel Serial No:</label>
						<input type="text" className="form-control" name="EUI" id="checktext7" placeholder="Enter Panel Serial No"  onChange={(e) => {
										setFilter({ ...Filter, PASRNO: e.target.value })}} />
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class={`btn btn-danger ${filterLoader==true?'disabled btn-progress':''}`} value="Filter" name="filter_customer_form"  onClick={ongetModemConsumerDetailsByFilter}/>
					<Link class="btn btn-light text-dark ml-2" onClick={()=>{setIsModalVisible(false)
					setFilter({ ModemList: [], EUI: '',DSRNO:'',PASRNO:'' })
					}}>
						Reset
					</Link>
				</div>
			</Modal>
				<section class="section">
				
					<div className="row">
						<div className="col-12">
							<div className="card">
							<div className="card-header ">
										<h4 className='d-flex'><span className="mr-2">Modem Details</span> <BootstrapTooltip title='Table Name is lora_modem_details_index' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip>            
                                        <div class="form-group ml-2 mb-0 mr-2">
											<select
												id="inputState"
												class="form-select form-control"
												name="modem_name"
												onChange={(e) => setModemFilter(e.target.value)}
												value={ModemFilter}
												style={{ width: '300px', height: '35px', padding: '0px 15px' }}
											>
												<option value={'All'}>All</option>
													{ModemData?.map((item)=>(<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div>         </h4>

										<div className="card-header-action">
											<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={()=>{ongetModemConsumerDetails(ModemFilter)}}>
										<BootstrapTooltip title="Refresh">
										<span>
												<i class="fa fa-refresh" aria-hidden="true"></i>
												</span>
											</BootstrapTooltip>
											</a>
											<Link to='/AddModemDetailsInLorawan' className="btn btn-info text-white mr-2">
											
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus  text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
											<button type="button" className="btn btn-info mr-2" onClick={()=>setIsModalVisible(true)}>
												<BootstrapTooltip title="Filter">
													<span>
														<i className="fa fa-filter text-white"></i>
													</span>
												</BootstrapTooltip>
											</button>
											<div className="dropdown d-inline mr-2">
												<button
													className="btn btn-info dropdown-toggle mr-2"
													type="button"
													id="dropdownMenuButton3"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													data-placement="bottom"
													title="Download"
												>
													<i className="fa fa-download mr-1"></i>
												</button>
												<div className="dropdown-menu">
												
													<a onClick={() => onDownloadModemConsumerDetailsByFilter('xlsx')} className="dropdown-item" >
														Excel
													</a>
													<a onClick={() => onDownloadModemConsumerDetailsByFilter('csv')} className="dropdown-item">
														CSV
													</a>
												</div>
											</div>
											
										</div>
									</div>
								<div className="card-body">
								{ModLoader ? <> <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
				<GoogleLoader bodyClassName={'tw-h-[70%]'} />
			</div></> :<div className="table-responsive tw-h-screen tw-max-h-screen">
				
										<div id="table-1_wrapper" className="dataTables_wrapper no-footer">
											<div className="dataTables_length" id="table_id_length">
												<label>
													Show{" "}
													<select name="table_id_length" aria-controls="table_id" className="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
														<option value={10}>10</option>
														<option value={25}>25</option>
														<option value={50}>50</option>
														<option value={100}>100</option>
													</select>{" "}
													entries
												</label>
											</div>
											<div id="table-1_filter" className="dataTables_filter"><label className="mr-2">Search:</label><MultiInput
									placeholder="search and enter..."
									onChange={onHandalSearchMulti}
									maxItems={null} // Allow any number of items
									onClear={onItemCleared}
									onClearAll={onClearAllItems}
									onHandalSerch={onHandalSerch}
								/>
								{/* <input type="search" className="mr-2" value={InputText} onChange={(e) => { setInputText(e.target.value.trim()) }} onKeyDown={(e) => (e.code === "Enter" ? onHandalSerch(e) : '')} /> */}
								<div className={`ml-2 btn btn-primary ${serachLoader === true ? 'btn-progress disabled' : ''}`} onClick={onHandalSerch} style={{ padding: '0.2rem 0.8rem', top: '0' }}><i className={`fas fa-search `}></i></div></div>
											<table id="table_id" className="tableCustom table table-striped text-center">
												<thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100'>
													<tr>
															<th class="GSM_Link">Unique Id</th>
															<th>
															Modem Name
															</th>
															<th>EUI</th>
															<th>Device Serial No</th>
															<th>Panel Serial No</th>
															{/* <th>WIFIMAC</th> */}
															<th style={{width:'150px'}}>Test Time Stamp</th>
															<th>Test data</th>
															<th>Test Result</th>
															<th>Copy</th>
															<th>Status</th>
													</tr>
												</thead>
												<tbody>
												{modemDList.length>0&&modemDList.map((item)=>(
													<tr  onMouseEnter={() => {
														var element = document.getElementById(item?.C1_SRNO + 'GSM');
														element.classList.add('within');
														element.classList.remove('display-icon');
													}} onMouseLeave={() => {
														var element = document.getElementById(item?.C1_SRNO + 'GSM');
														element.classList.add('display-icon');
														element.classList.remove('within');
													}} style={{cursor:'pointer'}}>
															<td className="GSM_Link"><a onClick={()=>(UpdateRolesUser(item))} style={{color:'#1890ff'}}>{item?.C1_SRNO}</a>
															<a id={item?.C1_SRNO + 'GSM'} className='display-icon'  onClick={()=>(UpdateRolesUser(item))} ><img src={exports} style={{ height: "10px" }} /></a></td>
															<td>
															{item?.MODEM_PROFILE_NAME}
															</td>
															<td>{item?.EUI}</td>
															<td>{item?.C5_DEVICE_SERIAL_NO}</td>
															<td>{item?.C6_PANEL_SERIAL_NO}</td>
															{/* <td>{item?.WIFIMAC?.match(/.{2}/g)?.join(':')}</td> */}
															<td>{item?.TESTED_AT?moment.utc(item?.TESTED_AT).format("DD-MM-YYYY hh:mm:ss A"):'-'}</td>
															{item && item.TEST_RESULT !== null ? ((item.TEST_RESULT === 1) ? <td><span className="badge badge-success">&#10004;</span></td> : <td><span className="badge badge-danger">&#x2716;</span></td>) : <td>-</td> }
															{console.log(item?.TEST_RESULT)}
															<td className="text-center">
																			{item?.TEST_DATA?<a
																				onClick={() =>onHandalModal(item?.TEST_DATA,item)}
																				class="btn-sm btn-primary btn-sm font-14 text-center"
																			>
																				<i
																					class="fa fa-info-circle "
																					aria-hidden="true"
																					style={{ color: "white" }}
																				></i>
																			</a>:'-'}
																		</td>
															<td><a
																				onClick={() =>onHandalCopyModal(item)}
																				class="btn-sm btn-info btn-sm font-14 text-center"
																			>
																				<BootstrapTooltip title={'Copy'}><span><i
																					class="fas fa-copy"
																					aria-hidden="true"
																					style={{ color: "white" }}
																				></i></span></BootstrapTooltip>
																			</a></td>
																			<td className="text-center">
																	<div className={`badge ${item.STATUS === 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item.STATUS === 1 ? 'Active' : 'Inactive'}</div>
																</td>

													</tr>
												))}
												</tbody>
											</table>
										</div>
									</div>}
									{ModLoader==false&&modemDList.length==0?<h4 className="text-center">No Data Found</h4>:''}
									<><div className='d-flex justify-content-between'>
											<div>Total Number Of Row {numberofPage}</div><Pagination
												className="pagination-bar"
												currentPage={currentPage}
												totalCount={numberofPage || 1}
												pageSize={PageSize}
												onPageChange={(page) => setCurrentPage(page)}
											/></div></>
								</div>
							</div>
						</div>
					</div>
				</section>
		</>
	);
}
