import { DatePicker, Modal, Space, TimePicker } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getAccount, getAreaById, getCircleById, getDivisionById, getGaById, getGSMDataFilterDataIntegration, getProfilNameByAccountId, getRolesById, getScheduleActivityLogs, getSubdevisionById, Insert_consumer_data, InsertApiSchedule, UpdateApiSchedule, UpdateApiScheduleStatus } from '../../../Commoncomponet/Utils'
import swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { style } from '@mui/system';
import MultiInput from '../../../Commoncomponet/MultiInput/MultiInput';
import chroma from 'chroma-js';
import SchedualLog from './SchedualLog';
import { toast } from 'react-toastify';
window.Swal = swal;
const Schedule = (props) => {
    const [report, setReport] = useState({ start_date:'',end_date:'',Consumer_Type:[],Consumer:[] })
    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" });
    const [SubdevisionData, setSubdevisionData] = useState([]);
	const [DivisionData, setDivisionData] = useState([]);
	const [CircleData, setCircleData] = useState([]);
	const [GaData, setGaData] = useState([]);
	const [AccounData, setAccountData] = useState([]);
	const [AreaData, setAreaData] = useState([]);
    const [permision, setpermision] = useState({});
    const [ConsumerData,setConsumerData]=useState([])
	const [ConsumerSearchData,setConsumerSearchData]=useState([])
	const [LoraConsumerSearchData,setLoraConsumerSearchData]=useState([])
	const [loraSearchdata,setloraSearchdata]=useState([])
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const [ConsumerUniqIdArray,setConsumerUniqIdArray]=useState([])
    const [LoraConsumerUniqIdArray,setLoraConsumerUniqIdArray]=useState([])
	const [FilterSerchLoader,setFilterSerchLoader]=useState(false)
	const [searchTerms, setSearchTerms] = useState([]);
	const [SchedualModal,setSchedualModal]=useState(false)
	const [SchedualInput,setSchedualInput]=useState({SCHEDULE_NAME:'',SCHEDULE_TYPE:'',SCHEDULE_TIME:''})
	const [Schedualbtn,setSchedualbtn]=useState(false)
	const [ScheduleUniqueId,setScheduleUniqueId]=useState('')
	
	
    const params = useParams();
    const history = useHistory();

	

	const ongetGaById = async (id) => {
		const data1 = await getGaById(id);
		if (data1.success === true) {
			setGaData(data1.data.data);
		}
	};
    const onHandaleSelection = async () => {
		const data = await getAccount();
		if (data.success === true) {
			setAccountData(data.data.data);
		} else {
			console.log("somthing want wrong");
		}
	};
	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.ROLE);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({
				...obj,
				ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
				ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
				ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
				ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
				ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
				ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
				ROLES_OTHER_PERMISSIONS:JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
			});
            onHandaleSelection()
        }
    }

	const ongetCircle = async (id) => {
		const data1 = await getCircleById(id);
		if (data1.success === true) {
			setCircleData(data1.data.data);
		}
	};
	const ongetSubdevisionById = async (id) => {
		const data1 = await getSubdevisionById(id);
		if (data1.success === true) {
			setSubdevisionData(data1.data.data);
		}
	};
	const ongetDivisionById = async (id) => {
		const data3 = await getDivisionById(id);
		if (data3.success === true) {
			setDivisionData(data3.data.data);
		}
	};
	const ongetArea = async (id) => {
		const data3 = await getAreaById(id);
		if (data3.success === true) {
			setAreaData(data3.data.data);
		}
	};
	const [NameObj,setNameObj]=useState({})
	const ongetProfilNameByAccountId=async(id)=>{
		const data=await getProfilNameByAccountId({ID:id})
		if(data.success===true){
		   setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME||'{}'))
		}else{
		   console.log('something want wrong')
		}
	 }
	useEffect(() => {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
		if (Profile.account !== "") {
			ongetGaById(Profile.account);
			setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([])
		
			ongetProfilNameByAccountId(Profile.account)
			
		} else {
			setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
			setGaData([]);
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);
			if (Object.keys(permision).length > 0) {
			
			}
			setNameObj({})
		}
	}, [Profile.account]);

	useEffect(() => {
		if (Profile.ga !== "") {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
			ongetCircle(Profile.ga);
			setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);
			
		} else {
			setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
			setCircleData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			setAreaData([]);
			if (Object.keys(permision).length > 0) {
			
			}

		}
	}, [Profile.ga]);

	useEffect(() => {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
		if (Profile.circle !== "") {
			ongetDivisionById(Profile.circle);
			setProfile({ ...Profile, division: "", subdivision: "", area: "" });
			setAreaData([]);
			setSubdevisionData([]);

		} else {
			setProfile({ ...Profile, division: "", subdivision: "", area: "" });
			setAreaData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			if (Object.keys(permision).length > 0) {
			
			}

		}
	}, [Profile.circle]);

	useEffect(() => {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
		if (Profile.division !== "") {
			ongetSubdevisionById(Profile.division);
			setAreaData([]);
			setProfile({ ...Profile, subdivision: "", area: "" });
			
		} else {
			setProfile({ ...Profile, subdivision: "", area: "" });
			setSubdevisionData([]);
			setAreaData([]);
			
		}
	}, [Profile.division]);

	useEffect(() => {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
		if (Profile.subdivision !== "") {
			ongetArea(Profile.subdivision);
			setProfile({ ...Profile, area: "" });

		} else {
			setProfile({ ...Profile, area: "" });
			setAreaData([]);
			
		}
	}, [Profile.subdivision]);

	useEffect(() => {
        setConsumerData([])
		setLoraConsumerSearchData([])
		setConsumerUniqIdArray([])
		if (Profile.area !== "") {
		
		} else {
			
		}
	}, [Profile.area]);

    useEffect(()=>{
        onHandaleRoles()
		
},[])

const ongetGSMDataFilterDataIntegration=async(pera_obj)=>{
	setFilterSerchLoader(true)
	var obj={}
	if(pera_obj){
		obj={CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY1_view:permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY5_view:permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view,CATEGORY0:pera_obj?.account,CATEGORY1:pera_obj?.ga,CATEGORY2:pera_obj?.circle,CATEGORY3:pera_obj?.division,CATEGORY4:pera_obj?.subdivision,CATEGORY5:pera_obj?.area,Roles:userInfo?.ROLE,Consumer_Type:pera_obj?.Consumer_Type,Consumer:pera_obj?.Consumer||[]}
	}else{
		 obj={CATEGORY0_view:permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view,CATEGORY1_view:permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view,CATEGORY2_view:permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view,CATEGORY3_view:permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view,CATEGORY4_view:permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view,CATEGORY5_view:permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view,CATEGORY0:Profile?.account,CATEGORY1:Profile?.ga,CATEGORY2:Profile?.circle,CATEGORY3:Profile?.division,CATEGORY4:Profile?.subdivision,CATEGORY5:Profile?.area,Roles:userInfo?.ROLE,Consumer_Type:report?.Consumer_Type,Consumer:report?.Consumer||[]}
	}
	console.log(report,obj,pera_obj);
    const data=await getGSMDataFilterDataIntegration(obj)
    if(data.success===true){
        setConsumerData(data?.data?.data)
        setConsumerSearchData(data?.data?.data)
		setloraSearchdata(data?.data?.loradata)
		setLoraConsumerSearchData(data?.data?.loradata)

		if(props?.UpdateconsumerList?.list){
			setConsumerUniqIdArray(props?.UpdateconsumerList?.list)
		setLoraConsumerUniqIdArray([])

		}else{
        setConsumerUniqIdArray([...data?.data?.data.map((item)=>(item?.UNIQUE_ID)),...data?.data?.loradata.map((item)=>(item?.UNIQUE_ID))])
		setLoraConsumerUniqIdArray([])
		}
	setFilterSerchLoader(false)

    }else{
		setFilterSerchLoader(false)
		setConsumerData([])
        setConsumerSearchData([])
		setLoraConsumerSearchData([])
        setConsumerUniqIdArray([])
    }
}

useEffect(()=>{
	console.log(props);
	if(props?.UpdateconsumerList&&props?.ScheduleUniqueId){
	setProfile({ account: props?.UpdateconsumerList?.Filter_obj?.CATEGORY0||'', ga: props?.UpdateconsumerList?.Filter_obj?.CATEGORY1||'', circle: props?.UpdateconsumerList?.Filter_obj?.CATEGORY2||'', division: props?.UpdateconsumerList?.Filter_obj?.CATEGORY3||'', subdivision: props?.UpdateconsumerList?.Filter_obj?.CATEGORY4||'', area: props?.UpdateconsumerList?.Filter_obj?.CATEGORY5||'' })
	setConsumerUniqIdArray(props?.UpdateconsumerList?.list)
	setScheduleUniqueId(props?.ScheduleUniqueId)
	setSchedualInput({SCHEDULE_NAME:props?.UpdateconsumerList?.all_info?.SCHEDULE_NAME,SCHEDULE_TYPE:props?.UpdateconsumerList?.all_info?.SCHEDULE_TYPE,SCHEDULE_TIME:`${props?.UpdateconsumerList?.all_info?.SCHEDULE_TIME.split(' ')[0]}:${props?.UpdateconsumerList?.all_info?.SCHEDULE_TIME.split(' ')[1]}`})
	setReport({...report,Consumer_Type:props?.UpdateconsumerList?.Filter_obj?.Consumer_Type||[],Consumer:props?.UpdateconsumerList?.Filter_obj?.Consumer||[]})
	ongetGSMDataFilterDataIntegration({ account: props?.UpdateconsumerList?.Filter_obj?.CATEGORY0||'', ga: props?.UpdateconsumerList?.Filter_obj?.CATEGORY1||'', circle: props?.UpdateconsumerList?.Filter_obj?.CATEGORY2||'', division: props?.UpdateconsumerList?.Filter_obj?.CATEGORY3||'', subdivision: props?.UpdateconsumerList?.Filter_obj?.CATEGORY4||'', area: props?.UpdateconsumerList?.Filter_obj?.CATEGORY5||'',Consumer_Type:props?.UpdateconsumerList?.Filter_obj?.Consumer_Type||[],Consumer:props?.UpdateconsumerList?.Filter_obj?.Consumer })
	}
},[props?.UpdateconsumerList,props?.ScheduleUniqueId])

const onHandal=(e,id)=>{
    var arrayCon=ConsumerUniqIdArray
if(e.target.checked==true){
    console.log(e.target.checked);
    arrayCon=[...arrayCon,id]
}else{
    arrayCon=arrayCon.filter((item)=>(item!=id))
}
setConsumerUniqIdArray(arrayCon)
}

const onHandelSchedule=async()=>{
	var Browser_Details={}
	Browser_Details.browser=window?.navigator?.userAgent
	Browser_Details.browser_version=window?.navigator?.appVersion
	Browser_Details.screen_details=window?.screen
	// Browser_Details.screen_details=window?.navigator?.plugins

	if(Profile?.account!=''){
		if(SchedualInput?.SCHEDULE_NAME==''){
			return toast.warn("Please Enter Schedule Name")

		}else if(SchedualInput?.SCHEDULE_TYPE==''){
			return toast.warn("Please Enter Schedule Type")

		}else if(SchedualInput?.SCHEDULE_TIME==''){
			return toast.warn("Please Enter Schedule Time")

		}
	setSchedualbtn(true)

		if(ScheduleUniqueId==''){
    var obj={
        roles_id:userInfo?.ROLE,
        SCHEDULE_NAME:SchedualInput?.SCHEDULE_NAME,SCHEDULE_TYPE:SchedualInput?.SCHEDULE_TYPE,SELECTED_CONSUMER_IDS:JSON.stringify(ConsumerUniqIdArray),FILTER_OBJECT:JSON.stringify({CATEGORY0:Profile?.account,CATEGORY1:Profile?.ga,CATEGORY2:Profile?.circle,CATEGORY3:Profile?.division,CATEGORY4:Profile?.subdivision,CATEGORY5:Profile?.area,Consumer_Type:report?.Consumer_Type,Consumer:report?.Consumer}),CREATED_BY:userInfo?.UNIQUE_ID,SCHEDULE_TIME:`${SchedualInput?.SCHEDULE_TIME.split(':')[1]} ${SchedualInput?.SCHEDULE_TIME.split(':')[0]} * * *`,tableName:JSON.parse(props?.credentialdetails?.TABLE_JSON||'{}')?.schedule,
    }
    swal.fire({
        title: `Are You Sure, Total ${ConsumerUniqIdArray.length} Consumer Data Push Schedule Add`,
        icon: "warning",
        showCancelButton: true,
     }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
    const data=await InsertApiSchedule(obj)
    if(data.success===true){
				swal.fire('', 'Add Schedule Success', 'success');
	setSchedualbtn(false)
	setSchedualModal(false)
    }else{
				swal.fire('', 'Server Error', 'error');
	setSchedualbtn(false)

    }
}else{
	setSchedualbtn(false)

}
})
	}else{
		var obj={
			roles_id:userInfo?.ROLE,
			SCHEDULE_NAME:SchedualInput?.SCHEDULE_NAME,SCHEDULE_TYPE:SchedualInput?.SCHEDULE_TYPE,SELECTED_CONSUMER_IDS:JSON.stringify(ConsumerUniqIdArray),FILTER_OBJECT:JSON.stringify({CATEGORY0:Profile?.account,CATEGORY1:Profile?.ga,CATEGORY2:Profile?.circle,CATEGORY3:Profile?.division,CATEGORY4:Profile?.subdivision,CATEGORY5:Profile?.area,Consumer_Type:report?.Consumer_Type,Consumer:report?.Consumer}),CREATED_BY:userInfo?.UNIQUE_ID,SCHEDULE_TIME:`${SchedualInput?.SCHEDULE_TIME.split(':')[1]} ${SchedualInput?.SCHEDULE_TIME.split(':')[0]} * * *`,tableName:JSON.parse(props?.credentialdetails?.TABLE_JSON||'{}')?.schedule,
		}
		const old_json={
            SCHEDULE_NAME:props?.UpdateconsumerList?.all_info?.SCHEDULE_NAME,
			SCHEDULE_TYPE:props?.UpdateconsumerList?.all_info?.SCHEDULE_TYPE,
			SELECTED_CONSUMER_IDS:JSON.stringify(props?.UpdateconsumerList?.list),
			FILTER_OBJECT:JSON.stringify(props?.UpdateconsumerList?.Filter_obj),
			SCHEDULE_TIME:props?.UpdateconsumerList?.all_info?.SCHEDULE_TIME,
         }
         var old_diff_json={}
      var new_diff_json={}

      Object.keys(old_json).map((item)=>{
         if(old_json[item]!=obj[item]){
            old_diff_json[item]=old_json[item]
            new_diff_json[item]=obj[item]
         }
      })
		swal.fire({
			title: `Are You Sure, Total ${ConsumerUniqIdArray.length} Consumer Data Push Schedule Update`,
			icon: "warning",
			showCancelButton: true,
		 }).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
		const data=await UpdateApiSchedule({...obj,old_json:JSON.stringify(old_diff_json),new_json:JSON.stringify(new_diff_json),CREATED_BY:userInfo?.UNIQUE_ID},ScheduleUniqueId)
		if(data.success===true){
					swal.fire('', 'Update Schedule Success', 'success');
					props.onHandalClean()
		setSchedualbtn(false)
		setSchedualModal(false)
		}else{
					swal.fire('', 'Server Error', 'error');
		setSchedualbtn(false)
	
		}
	}else{
		setSchedualbtn(false)

	}
	})
	}
}else{
toast.error("Please Select Account")
}
}

const onHandalSearchMulti = (newSearchTerms) => {
	setSearchTerms(newSearchTerms);
	if (newSearchTerms.length > 0) {
		const filteredData = [...ConsumerSearchData].filter((item) => {
			const addressCompanyName = JSON.parse(item?.ADDRESS || "{}").address_compony_name?.toLowerCase();
			const searchDataLowerCase = newSearchTerms.map((term) => term.toLowerCase());

			return searchDataLowerCase.some((searchTerm) => {
				return (
					addressCompanyName?.includes(searchTerm) ||
					item?.BUSINESS_PARTNER_NUMBER.toLowerCase().includes(searchTerm) ||
					item?.DEVICE_SERIAL_NO.toLowerCase().includes(searchTerm) ||
					item?.IMEI.toLowerCase().includes(searchTerm) ||
					item?.INSTALLATION_NUMBER.toLowerCase().includes(searchTerm) ||
					item?.PANEL_SERIAL_NO.toLowerCase().includes(searchTerm)
				);
			});
		});

		const LorafilteredData = [...LoraConsumerSearchData].filter((item) => {
			const addressCompanyName = JSON.parse(item?.ADDRESS || "{}").address_compony_name?.toLowerCase();
			const searchDataLowerCase = newSearchTerms.map((term) => term.toLowerCase());

			return searchDataLowerCase.some((searchTerm) => {
				return (
					addressCompanyName?.includes(searchTerm) ||
					item?.BUSINESS_PARTNER_NUMBER.toLowerCase().includes(searchTerm) ||
					item?.DEVICE_SERIAL_NO.toLowerCase().includes(searchTerm) ||
					item?.IMEI.toLowerCase().includes(searchTerm) ||
					item?.INSTALLATION_NUMBER.toLowerCase().includes(searchTerm) ||
					item?.PANEL_SERIAL_NO.toLowerCase().includes(searchTerm)
				);
			});
		});
		setLoraConsumerSearchData(LorafilteredData)
		setConsumerData(filteredData);
	} else {
		console.log("clear all")
		setLoraConsumerSearchData(loraSearchdata)
		setConsumerData(ConsumerSearchData);
	}
};

const onItemCleared = (clearedIndex) => {
	// Create a new array without the cleared item
	const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
	// Update the search results based on the new search terms
	onHandalSearchMulti(newSearchTerms);
};

const onClearAllItems = () => {
	// Reset the search results to the original data
	onHandalSearchMulti([]);

};

const onActive = async () => {
	swal.fire({
	   title: "!Are you Sure,Schedule Status Change In Active",
	   icon: "warning",

	}).then(async (willDelete) => {
	   if (willDelete.isConfirmed) {
		  const data = await UpdateApiScheduleStatus({ SCHEDULE_STATUS:1,tableName:JSON.parse(props?.credentialdetails?.TABLE_JSON||'{}')?.schedule,old_json:JSON.stringify({SCHEDULE_STATUS:0}),new_json:JSON.stringify({SCHEDULE_STATUS:1}),CREATED_BY:userInfo?.UNIQUE_ID },ScheduleUniqueId)
		  if (data.success === true) {
			 toast.success('Schedule is Active')
			 props.onHandalClean()

		  } else {
			 toast.error('something want wrong')
		  }
	   }
	})
 }

 const onInActive = async () => {
	swal.fire({
	   title: "!Are you Sure,Schedule Status Change In InActive",
	   icon: "warning",

	}).then(async (willDelete) => {
	   if (willDelete.isConfirmed) {
		  const data = await UpdateApiScheduleStatus({ SCHEDULE_STATUS:0,tableName:JSON.parse(props?.credentialdetails?.TABLE_JSON||'{}')?.schedule,old_json:JSON.stringify({SCHEDULE_STATUS:1}),new_json:JSON.stringify({SCHEDULE_STATUS:0}),CREATED_BY:userInfo?.UNIQUE_ID },ScheduleUniqueId)
		  if (data.success === true) {
			 toast.success('Schedule is InActive')
			 props.onHandalClean()

		  } else {
			 toast.error('something want wrong')
		  }
	   }
	})
 }


const consumerTypeArray = [
	{ value: "Industrial", label: 'Industrial' },
	{ value: "Residential", label: 'Residential' },
	{ value: "Commercial", label: 'Commercial' },
	{ value: "DRS", label: 'DRS' },
]

const consumerArray = [
	{ value: "GSM", label: 'GSM' },
	{ value: "Lorawan", label: 'Lorawan' }
]

function range(start, end) {
	const arr = [0,1,2,3,4,5,6,16,17,18,19,20,21,22,23];
	return arr;
  }
    return (
        <>
		<Modal title={ScheduleUniqueId?'Update Schedule':"Add Schedule"} maskClosable={false} visible={SchedualModal} footer='' onCancel={() => {setSchedualModal(false)}} width={500}>
		<section className="section mt-3">
					<div className="section-body">
         
                          
						<div className="row">
							<div className="col-12">
									<div className="form-row" >
                    <div className="form-group col-md-12" id="grpeui">
											<label for="inputPassword4 ">Schedule Name :<span className='text-danger'>*</span> </label>
											<input type="text" class="form-control present_street_area" name="address_compony_name"	id="address_compony_name"	placeholder="Schedule Name" onChange={(e)=>{setSchedualInput({...SchedualInput,SCHEDULE_NAME:e.target.value})}} value={SchedualInput?.SCHEDULE_NAME}/>
										</div>
                    <div className="form-group col-md-12" id="grpeui">
											<label for="inputPassword4 ">Schedule Type:<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={(e)=>{setSchedualInput({...SchedualInput,SCHEDULE_TYPE:e.target.value})}} value={SchedualInput?.SCHEDULE_TYPE}>
												<option value="">Select Schedule Type</option>
												<option value="1">Daily</option>
											</select>
										</div>
										<div className="form-group col-md-12" id="grpeui">
											<label for="inputPassword4 ">Schedule Time:<span className='text-danger'>*</span> </label>
											<TimePicker format="HH:mm" name='start' style={{ width: '100%', height: '40px' }}
												showTime
												onChange={(e, dateString)=>(setSchedualInput({...SchedualInput,SCHEDULE_TIME:dateString}))}
												disabledHours={() => range(15,7)}
												 />
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger ${Schedualbtn==true?'disabled btn-progress':''} mr-2`} value={ScheduleUniqueId?"Update":"Add"} id="submitvn" onClick={onHandelSchedule}/>
										<Link className="btn btn-light text-dark" onClick={()=>setSchedualModal(false)}>Cancel</Link>
									</div>
                  				</div>
							</div>
						</div>

				</section>
            </Modal>
			
								 <div className="row">
					<div>
						<div className='mr-3 d-flex' style={{float:'right'}}>
					{ScheduleUniqueId!==''?<div class="dropdown">
                                 <button class={`btn ${props?.UpdateconsumerList?.all_info?.SCHEDULE_STATUS == 1 ? 'btn-success' : props?.UpdateconsumerList?.all_info?.SCHEDULE_STATUS == 0 ? 'btn-danger' : props?.UpdateconsumerList?.all_info?.SCHEDULE_STATUS == 2 ? 'btn-info' : props?.UpdateconsumerList?.all_info?.SCHEDULE_STATUS == 3 ? 'btn-dark' : 'btn-warning'} dropdown-toggle`} type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                                    {props?.UpdateconsumerList?.all_info?.SCHEDULE_STATUS == 1 ? 'Active' : props?.UpdateconsumerList?.all_info?.SCHEDULE_STATUS == 0 ? 'InActive' : props?.UpdateconsumerList?.all_info?.SCHEDULE_STATUS == 2 ? 'Under Maintenance' : props?.UpdateconsumerList?.all_info?.SCHEDULE_STATUS == 3 ? 'Stand By' : 'Pending Configuration'}
                                 </button>
                                 <div class="dropdown-menu !tw-left-[-50px]" aria-labelledby="dropdownMenuButton1">
                                    {props?.UpdateconsumerList?.all_info?.SCHEDULE_STATUS != 1 ? <a class="dropdown-item text-success" onClick={onActive} >Active</a> : ''}
                                    {props?.UpdateconsumerList?.all_info?.SCHEDULE_STATUS != 0 ? <a class="dropdown-item text-danger"  onClick={onInActive}>InActive</a> : ''}
                                 </div>
                              </div>:''}
				<button class="btn btn-primary mr-3 ml-2" onClick={()=>{props?.setComponetTime('')}} >Back</button>
				</div>
				</div>
                    <div className="col-12">
                        <div className="">
                            <div className="card-body ">
                                {/* <div className="form-row" >
								{/* <div className="form-group col-md-3" id="strdate">
                                    <label for="inputPassword4">Select Date<span class="text-danger">*</span> : </label><br></br>
									<DatePicker onChange={(e,dateString)=>setReport({ ...report, start_date: dateString})} format='DD-MM-YYYY' name='start'  style={{ width:'100%',height:'40px' }}
           disabledDate={(current) => {
              return current.isAfter(moment())
            }} />
                                 </div> */}
                                {/* <div className="form-group col-md-3" id="strdate">
                                    <label for="inputPassword4">Start Date<span class="text-danger">*</span> : </label><br></br>
									<DatePicker onChange={(e,dateString)=>setReport({ ...report, start_date: dateString})} format='DD-MM-YYYY' name='start'  style={{ width:'100%',height:'40px' }}
           disabledDate={(current) => {
              return current.isAfter(moment())
            }} />
                                 </div>
                                 <div className="form-group col-md-3" id="strdate">
                                    <label for="inputPassword4">End Date<span class="text-danger">*</span> : </label><br></br>
									<DatePicker onChange={(e,dateString)=>setReport({ ...report, end_date: dateString})} format='DD-MM-YYYY' name='start'  style={{ width:'100%',height:'40px' }}
           disabledDate={(current) => {
              return current.isAfter(moment())
            }} /> */}
                                 {/* </div> */}
                                {/* </div> */}
                                <div class="row">
								<div class="col-12">
											<div
												class="form-row "
												style={{
													borderColor: "#e3e6fc !important",
													position: "relative",
													marginTop: "5px",
												}}
											>
												<div class="col-md-2 ">
													<div class="form-group">
														<label htmlFor="inputPassword4">
															Account<span class="text-danger">*</span>
														</label>
														{/* <Select name='Consumertype' isMulti options={AccounData.map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																		{value:item.UNIQUE_ID,label:item.CATEGORY0}
																	) :{}
																)} onChange={(e) => { setProfile({ ...Profile, account: e.map((item) => (item.value)) }) }} /> */}
														<select
															class="form-control form-select accountoga ga_calueacc"
															name="accountid"
															required=""
															id="accountid"
															onChange={(e) => setProfile({ ...Profile, account: e.target.value })}
															value={Profile?.account}
															
														>
															<option value="">Select Account</option>
															{AccounData &&
																AccounData.map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																		<option key={id} value={item.UNIQUE_ID}>{item.CATEGORY0}</option>
																	) : (
																		""
																	)
																)}
														</select>
													</div>
												</div>
												{Profile.account !== "" && (
													<div class="col-md-2 gagrp">
														<div class="form-group">
															<label htmlFor="inputPassword4">{NameObj?.CATEGORY1||'CATEGORY1'}</label>
															<select
																class="form-control form-select gatocircle ga_calue"
																name="address_location_d"
																id="address_location_d"
																onChange={(e) => setProfile({ ...Profile, ga: e.target.value })}
																value={Profile?.ga}
															>
																<option value="">Select GA</option>
																{GaData &&
																	GaData.map((item, id) =>
																		permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter(
																			(items) => items === item.CATEGORY1_UNIQUE_ID
																		).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																			<option key={id} value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option>
																		) : (
																			""
																		)
																	)}
															</select>
														</div>
													</div>
												)}
												{Profile.ga !== "" && Profile.account !== "" && (
													<div class="col-md-2 circlegrp">
														<div class="form-group">
															<label htmlFor="inputPassword4">{NameObj?.CATEGORY2||'CATEGORY2'}</label>
															<select
																class="form-control circletodivision form-select"
																name="circle_id"
																onChange={(e) => setProfile({ ...Profile, circle: e.target.value })}
																value={Profile?.circle}
															>
																<option value="">Select Circle</option>
																{CircleData &&
																	CircleData.map((item, id) =>
																		permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter(
																			(items) => items === item.CATEGORY2_UNIQUE_ID
																		).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																			<option key={id} value={item.CATEGORY2_UNIQUE_ID}>{item.CATEGORY2_NAME}</option>
																		) : (
																			""
																		)
																	)}
															</select>
														</div>
													</div>
												)}
												{Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
													<div class="col-md-2 divisiongrp">
														<div class="form-group">
															<label htmlFor="inputPassword4">{NameObj?.CATEGORY3||'CATEGORY3'}</label>
															<select
																class="form-control divisiontosubdivision form-select"
																name="division_id"
																id="address_location_d"
																onChange={(e) => setProfile({ ...Profile, division: e.target.value })}
																value={Profile?.division}
															>
																<option value="">Select Division</option>
																{DivisionData &&
																	DivisionData.map((item, id) =>
																		permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter(
																			(items) => items === item.CATEGORY3_UNIQUE_ID
																		).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																			<option key={id} value={item.CATEGORY3_UNIQUE_ID}>{item.CATEGORY3_NAME}</option>
																		) : (
																			""
																		)
																	)}
															</select>
														</div>
													</div>
												)}
												{Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
													<div class="col-md-2 subdivisiongrp">
														<div class="form-group">
															<label htmlFor="inputPassword4">{NameObj?.CATEGORY4||'CATEGORY4'}</label>
															<select
																class="form-control subdivisiontoarea form-select"
																name="sub_division_id"
																id="address_location_d"
																onChange={(e) => setProfile({ ...Profile, subdivision: e.target.value })}
																value={Profile?.subdivision}
															>
																<option value="">Select Sub Division</option>
																{SubdevisionData &&
																	SubdevisionData.map((item, id) =>
																		permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter(
																			(items) => items === item.CATEGORY4_UNIQUE_ID
																		).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																			<option key={id} value={item.CATEGORY4_UNIQUE_ID}>
																				{item.CATEGORY4_NAME}
																			</option>
																		) : (
																			""
																		)
																	)}
															</select>
														</div>
													</div>
												)}
												{Profile.subdivision !== "" &&
													Profile.division !== "" &&
													Profile.circle !== "" &&
													Profile.ga !== "" &&
													Profile.account !== "" && (
														<div class="col-md-2 areagrp">
															<div class="form-group">
																<label htmlFor="inputPassword4">{NameObj?.CATEGORY5||'CATEGORY5'}</label>
																<select
																	class="form-control form-select lastarea cust_ara"
																	name="area_id"
																	id="area_id"
																	onChange={(e) => setProfile({ ...Profile, area: e.target.value })}
																	value={Profile?.area}
																>
																	<option value="">Select Area</option>
																	{AreaData &&
																		AreaData.map((item, id) =>
																			permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter(
																				(items) => items === item.UNIQUE_ID
																			).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? (
																				<option key={id} value={item.UNIQUE_ID}>{item.CATEGORY5_NAME}</option>
																			) : (
																				""
																			)
																		)}
																</select>
															</div>
														</div>
													)}
										</div>
										<div class="col-md-6 areagrp d-flex tw-items-center tw-gap-2">
															<div class="form-group" style={{ width:'100%' }}>
																<label htmlFor="inputPassword4">Consumer Type</label>
																<Select  placeholder='All Consumer Type' value={report?.Consumer_Type?.length>0?consumerTypeArray.filter((item)=>report?.Consumer_Type?.includes(item?.value)):[]} isMulti options={consumerTypeArray} onChange={(e) => {
										setReport({ ...report, Consumer_Type: e.map((item) => (item.value)) })
									}} />
																</div>
																<div class="form-group" style={{ width: '100%' }}>
                                                            <label htmlFor="inputPassword4">Consumer</label>
                                                            <Select placeholder='All Consumer' value={report?.Consumer?.length > 0 ? consumerArray.filter((item) => report?.Consumer?.includes(item?.value)) : []} isMulti options={consumerArray} onChange={(e) => {
                                                                setReport({ ...report, Consumer: e.map((item) => (item.value)) })
                                                            }} />
                                                        </div>
																<div className={`btn btn-primary  ${FilterSerchLoader==true?'btn-progress disabled':''}`} style={{ height:'37px' }} onClick={()=>ongetGSMDataFilterDataIntegration()}><i className='fas fa-search'></i></div>
																</div>
									
											</div>
							</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='tw-px-4'>

                    <div className="card">
                        <div className="card-header">
                            <h4 className="modemconfigurationstatus d-none"></h4>
                            <div className="card-header-action">
                                <div id="filtergrm">
								<MultiInput
									placeholder="search and enter..."
									onChange={onHandalSearchMulti}
									maxItems={null} // Allow any number of items
									onClear={onItemCleared}
									onClearAll={onClearAllItems}
								/>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive meterdata">
                                <table className="table table-striped tw-text-xs tw-w-full">
                                    <thead>
                                        <tr>
                                            <th><input type="checkbox" className="chk_MSSQL1" name="checkAll" checked={ConsumerUniqIdArray.length==(ConsumerData.length+LoraConsumerSearchData.length)?true:false} onClick={(e)=>(e.target.checked==true?setConsumerUniqIdArray([...ConsumerData.map((item)=>(item?.UNIQUE_ID)),...LoraConsumerSearchData.map((item)=>(item?.UNIQUE_ID))]):setConsumerUniqIdArray([]))}/></th>
                                            <th>SRNO</th>
                                            <th>Consumer Name</th>
                                            <th>Modem Serial No</th>
                                            <th>Installation Number</th>
                                            <th>Panel Serial Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                  {[...ConsumerData,...LoraConsumerSearchData].map((item,id)=>(<tr>
                                            <th><input type="checkbox" className="chk_MSSQL1" name="checkAll" checked={ConsumerUniqIdArray.filter((info)=>(info==item?.UNIQUE_ID)).length>0?true:false} onChange={(e)=>onHandal(e,item?.UNIQUE_ID)}/></th>
                                            <th>{id+1}</th>
                                            <th>{JSON.parse(item?.ADDRESS||'{}').address_compony_name}</th>
                                            <th>{item?.DEVICE_SERIAL_NO}</th>
                                            <th>{item?.INSTALLATION_NUMBER}</th>
                                            <th>{item?.PANEL_SERIAL_NO}</th>
                                        </tr>))}  
                                    </tbody>
                                </table>
                            </div>
                        </div>

                       {ConsumerUniqIdArray.length>0?<div className="form-group col-md-12 text-center">
                            <input type="submit" className="btn btn-primary" value={ScheduleUniqueId?"Update Schedule":"Add schedule"} id="sync_btn" name="sync_btn" onClick={()=>{setSchedualModal(true)}}/>

                        </div>:''}
                    </div>
                </div>


        </>
    )
}

export default Schedule