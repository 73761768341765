import React, { useEffect, useState } from 'react'
import { AllConsumerDetailList, AllConsumerSearchList, getRolesById } from '../../Commoncomponet/Utils';
import { BootstrapTooltip } from '../../Commoncomponet/common';
import { ReactComponent as NoDataFoundSvg } from '../../assets/SVGs/no_data_found.svg';
import moment from 'moment';
import Pagination from '../../Commoncomponet/Pagination'
import exports from '../../assets/img/export.png'
import MultiInput from '../../Commoncomponet/MultiInput/MultiInput';

export const AllConsumer = () => {

    const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
	const [getwayList, setGetwayList] = useState([])
    const [CostomFilter,setCostomFilter]=useState({col:''})
    const [Loader,setLoader]=useState(false)
	const [AccountKey, setAccountKey] = useState([])
	const [permision,setpermision]=useState([])
	const [searchTerms, setSearchTerms] = useState([]);
	const [serachLoader, setSerachLoader] = useState(false)

	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))

	const onHandaleRoles = async () => {
		setLoader(true);
		const data = await getRolesById(userInfo?.ROLE)
		if (data.success === true) {
			const obj = data.data.data[0]
			const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),ROLES_OTHER_PERMISSIONS:JSON.parse(obj.ROLES_OTHER_PERMISSIONS) }
				onHandaleList({ CATEGORY1_view: Roles?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY0_view: Roles?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY2_view: Roles?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: Roles?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: Roles?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5_view: Roles?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view, Roles: userInfo?.ROLE })
			
			setpermision(Roles)

		} else {
			console.log('something want wrong')
			setLoader(false)
		}
	}
    const onHandaleList = async (info) => {
		setLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		// setLoader(true);
		// $(".loader").fadeOut("slow");
		const data = await AllConsumerDetailList({ start: firstPageIndex, end: PageSize }, info)
		if (data.success === true) {
			setGetwayList(data.data.data)
			// setGetwayListSerch(data.data.data)
			setnumberofPage(parseInt(data.data.totalCount))
			setLoader(false)

		} else {
			console.log('something went wrong')
			setLoader(false)
		}
	}

    useEffect(()=>{
        onHandaleRoles()
    },[])

	useEffect(()=>{
		if (Object.keys(permision).length > 0) {
		onHandaleList({ CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, Roles: userInfo?.ROLE, Roles: userInfo?.ROLE })
		}
	}, [currentPage, PageSize])

    function getSecondsTodayBydate(ts) {
		var now = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'second')
	}

    const onChangeCostomFilter=()=>{

    }

    const onClickCostumFilter=()=>{

    }

	const onHandalSerch=async()=>{
		setSerachLoader(true)
		if(searchTerms.length==0){
			onHandaleList({ CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, Roles: userInfo?.ROLE, Roles: userInfo?.ROLE })
		
		}else{
		const data = await AllConsumerSearchList({ text: searchTerms, CATEGORY0_view: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY1_view: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY2_view: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3_view: permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view, CATEGORY4_view: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5_view: permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view, Roles: userInfo?.ROLE})
			if (data.success === true) {
				setGetwayList(data.data.data)
				setnumberofPage(parseInt(data?.data?.totalCount||0))
				setSerachLoader(false)

			} else {
				console.log('something want warong')
				setSerachLoader(false)

			}
		}
	}

	const onHandalSearchMulti = (newSearchTerms) => {
		setSearchTerms(newSearchTerms);
		
	};
	
	const onItemCleared = (clearedIndex) => {
		// Create a new array without the cleared item
		const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onHandalSearchMulti(newSearchTerms);
	};
	const onClearAllItems = () => {
		// Reset the search results to the original data
		onHandalSearchMulti([]);
	
	};
  return (
   <>
   <div className="main-content" style={{ minHeight: "562px" }}>
   {Loader ? (
				<div className="loader"></div>
			) : (
				""
			)}
   <section className="section">
    <div className="section-body">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header ">
										<h4  ><span className='mr-2'>All Consumers</span><BootstrapTooltip title='Table Name is gsm_consumer_device_index,lora_consumer_device_index' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div className="card-header-action">
										</div>
									</div>
									<div className="card-body">
										
										<div className="table-responsive tw-h-screen tw-max-h-screen">
											<div id="table-1_wrapper" className="dataTables_wrapper no-footer">
												<div className="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select name="table_id_length" aria-controls="table_id" className="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
												<div id="table-1_filter" className="dataTables_filter"><label className="mr-2">Search:<MultiInput
									placeholder="search and enter..."
									onChange={onHandalSearchMulti}
									maxItems={null} // Allow any number of items
									onClear={onItemCleared}
									onClearAll={onClearAllItems}
									onHandalSerch={onHandalSerch}
								/></label>
								<div className={`ml-2 btn btn-primary ${serachLoader === true ? 'btn-progress disabled' : ''}`} onClick={onHandalSerch} style={{ padding: '0.2rem 0.8rem', top: '0' }}><i className={`fas fa-search `}></i></div>
                                </div>
												<table id="table_id" className="tableCustom table table-striped tw-w-full tw-text-sm text-center">
													<thead className='tw-sticky tw-z-50 tw-top-0 tw-text-xs tw-bg-gray-100'>
														<tr>
															<th className="text-center"><div className=''><span>Unique No</span> </div><div
																className='dropdown'
																id='SRNO'
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
																style={{ padding: '0', float: 'right', color: CostomFilter.col == 'SRNO' ? '#3abaf4' : '' }}
																onClick={(e) => {
																	setCostomFilter({ ...CostomFilter, col: 'SRNO' })
																	console.log(e)
																}}
															><i className={`fas fa-search `} style={{ fontSize: '9px', cursor: 'pointer' }}
															></i></div><div
																className="dropdown-menu "
																x-placement="bottom-start"
																style={{
																	position: "absolute",
																	transform: "translate3d(-5px, 28px, 0px)",
																	top: "0px",
																	left: "-110px",
																	willChange: "transform",
																}}
															><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
																		<input type="text" className="form-control" name="filter_hours" id="filter_less_hours123" placeholder="Search Text" onChange={onChangeCostomFilter} />
																	</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter} /></div>	</th>
															<th style={{ minWidth: '200px' }}><div className=''><span>Consumer Name</span></div><div
																className="dropdown mt-4"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
																style={{ padding: '0', float: 'right', color: CostomFilter.col == `JSON_VALUE(ADDRESS,'$.address_compony_name')` ? '#3abaf4' : '' }}
																onClick={() => (setCostomFilter({ ...CostomFilter, col: `JSON_VALUE(ADDRESS,'$.address_compony_name')` }))}
															><i className={`fas fa-search`} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div><div
																className="dropdown-menu "
																x-placement="bottom-start"
																style={{
																	position: "absolute",
																	transform: "translate3d(-5px, 28px, 0px)",
																	top: "0px",
																	left: "-110px",
																	willChange: "transform",
																}} primary
															><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
																		<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter} />
																	</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter} /></div></th>
															<th style={{ minWidth: '125px' }}><span>Group</span></th>
															<th ><div className=''><span>IMEI</span></div><div
																className="dropdown mt-4"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
																style={{ padding: '0', float: 'right', color: CostomFilter.col == 'IMEI' ? '#3abaf4' : '' }}
																onClick={() => (setCostomFilter({ ...CostomFilter, col: 'IMEI' }))}
															><i className={`fas fa-search `} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div><div
																className="dropdown-menu "
																x-placement="bottom-start"
																style={{
																	position: "absolute",
																	transform: "translate3d(-5px, 28px, 0px)",
																	top: "0px",
																	left: "-110px",
																	willChange: "transform",

																}}
															><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
																		<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter} />
																	</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter} /></div></th>
															<th ><div className=''><span>Customer ID</span></div><div
																className="dropdown mt-4"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
																style={{ padding: '0', float: 'right', color: CostomFilter.col == 'Customer ID' ? '#3abaf4' : '' }}
																onClick={() => (setCostomFilter({ ...CostomFilter, col: 'Customer ID' }))}
															><i className={`fas fa-search `} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div><div
																className="dropdown-menu "
																x-placement="bottom-start"
																style={{
																	position: "absolute",
																	transform: "translate3d(-5px, 28px, 0px)",
																	top: "0px",
																	left: "-110px",
																	willChange: "transform",

																}}
															><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
																		<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter} />
																	</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter} /></div></th>
															<th ><div className=''><span>Installation Number</span></div><div
																className="dropdown mt-4"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
																style={{ padding: '0', float: 'right', color: CostomFilter.col == 'Installation Number' ? '#3abaf4' : '' }}
																onClick={() => (setCostomFilter({ ...CostomFilter, col: 'Installation Number' }))}
															><i className={`fas fa-search `} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div><div
																className="dropdown-menu "
																x-placement="bottom-start"
																style={{
																	position: "absolute",
																	transform: "translate3d(-5px, 28px, 0px)",
																	top: "0px",
																	left: "-110px",
																	willChange: "transform",

																}}
															><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
																		<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter} />
																	</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter} /></div></th>
															<th ><div className=''><span>Business Partner Number</span></div><div
																className="dropdown mt-4"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
																style={{ padding: '0', float: 'right', color: CostomFilter.col == 'Business Partner Number' ? '#3abaf4' : '' }}
																onClick={() => (setCostomFilter({ ...CostomFilter, col: 'Business Partner Number' }))}
															><i className={`fas fa-search `} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div><div
																className="dropdown-menu "
																x-placement="bottom-start"
																style={{
																	position: "absolute",
																	transform: "translate3d(-5px, 28px, 0px)",
																	top: "0px",
																	left: "-110px",
																	willChange: "transform",

																}}
															><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
																		<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter} />
																	</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter} /></div></th>
															<th ><div className=''><span>Modem Serial No</span></div><div
																className="dropdown"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
																style={{ padding: '0', float: 'right', color: CostomFilter.col == 'DEVICE_SERIAL_NO' ? '#3abaf4' : '' }}
																onClick={() => (setCostomFilter({ ...CostomFilter, col: 'DEVICE_SERIAL_NO' }))}
															><i className={`fas fa-search `} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div><div
																className="dropdown-menu "
																x-placement="bottom-start"
																style={{
																	position: "absolute",
																	transform: "translate3d(-5px, 28px, 0px)",
																	top: "0px",
																	left: "-110px",
																	willChange: "transform",

																}}
															><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
																		<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter} />
																	</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter} /></div></th>
															<th style={{ minWidth: '125px' }}><div className=''><span>Panel Serial No</span></div><div
																className="dropdown mt-4"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
																style={{ padding: '0', float: 'right', color: CostomFilter.col == 'PANEL_SERIAL_NO' ? '#3abaf4' : '' }}
																onClick={() => (setCostomFilter({ ...CostomFilter, col: 'PANEL_SERIAL_NO' }))}
															><i className={`fas fa-search `} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div><div
																className="dropdown-menu "
																x-placement="bottom-start"
																style={{
																	position: "absolute",
																	transform: "translate3d(-5px, 28px, 0px)",
																	top: "0px",
																	left: "-110px",
																	willChange: "transform",

																}}
															><div className="form-group mb-0 tw-p-2  checktext31" style={{ marginTop: -10 }}>
																		<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="Search Text" onChange={onChangeCostomFilter} />
																	</div><input type="submit" className="btn btn-primary ml-2" name="filter_hours" id="filter_less_hours" value={'Search'} onClick={onClickCostumFilter} /></div></th>
															<th style={{ minWidth: '100px' }}><div className=''><span>Last Seen</span></div><div
																className="dropdown mt-4"
																data-toggle="dropdown"
																aria-haspopup="true"
																aria-expanded="false"
																style={{ padding: '0', float: 'right', color: CostomFilter.col == 'HEARTBEAT_TS' ? '#3abaf4' : '' }}
																onClick={() => (setCostomFilter({ ...CostomFilter, col: 'HEARTBEAT_TS' }))}
															><i className={`fas fa-search`} style={{ fontSize: '9px', cursor: 'pointer' }}></i></div><div
																className="dropdown-menu "
																x-placement="bottom-start"
																style={{
																	position: "absolute",
																	transform: "translate3d(-5px, 28px, 0px)",
																	top: "0px",
																	left: "-110px",
																	willChange: "transform",

																}}
															><div className="form-group mb-0 tw-p-2 " style={{ marginTop: -10 }}>
																		<input type="radio" className='mr-1' id="checktextLSs" name="check" value={'desc'} />
																		<label for="checktextLSs">Descending</label><br />
																		<input type="radio" className='mr-1' id="checktextLSs2" name="check" value={'asc'}  />
																		<label for="checktextLSs2">Ascending</label>
																	</div></div></th>
															{/* <th style={{ minWidth: '100px' }}><span>Modem Connectvity Status</span></th> */}
															<th ><span>Status</span></th>
															<th style={{ minWidth: '300px' }}><span>Tags</span></th>
															<th><span>Information</span></th>
														</tr>
													</thead>
													<tbody>
														{getwayList.length > 0 && getwayList.map((item, id) => (
															<tr className="trheigth" onMouseEnter={() => {
																var element = document.getElementById(item?.SRNO + 'GSM');
																element.classList.add('within');
																element.classList.remove('display-icon');
															}} onMouseLeave={() => {
																var element = document.getElementById(item?.SRNO + 'GSM');
																element.classList.add('display-icon');
																element.classList.remove('within');
															}}>
																<td className="GSM_Link">
																	<a href={item?.TABLE_TYPE=='GSM'?`/view_existing_consumer_gsm/${item?.UNIQUE_ID}`:`/view_existing_consumer_lorawan/${item?.UNIQUE_ID}`} target='_blank'>{item?.TABLE_TYPE=='GSM'?'gumsops-CON-G'+item?.SRNO:'gumsops-CON-L'+item?.SRNO}</a>
																	<a id={item?.SRNO + 'GSM'} className='display-icon' href={`/view_existing_consumer_gsm/${item?.UNIQUE_ID}`} target='_blank'><img src={exports} style={{ height: "10px" }} /></a></td>
																	{/* {console.log(item?.ADDRESS,item?.UNIQUE_ID)} */}
																<td ><BootstrapTooltip title={JSON.parse(item?.ADDRESS)?.address_compony_name} placement="top"><div className='show-read-more-name'>{JSON.parse(item?.ADDRESS)?.address_compony_name}</div></BootstrapTooltip></td>
																<BootstrapTooltip title={<div>
																	ACCOUNT:{item?.ACCOUNT_NAME}
																	<br />
																	{JSON.parse(AccountKey.filter((inac) => (inac?.UNIQUE_ID === item?.CAT0_ID))[0]?.PROFILE_NAME || '{}')?.CATEGORY1 || 'CATEGORY1'}:{item?.GA_NAME}
																	<br />
																	{JSON.parse(AccountKey.filter((inac) => (inac?.UNIQUE_ID === item?.CAT0_ID))[0]?.PROFILE_NAME || '{}')?.CATEGORY2 || 'CATEGORY2'}:{item?.CIRCLE_NAME}
																	<br />
																	{JSON.parse(AccountKey.filter((inac) => (inac?.UNIQUE_ID === item?.CAT0_ID))[0]?.PROFILE_NAME || '{}')?.CATEGORY3 || 'CATEGORY3'}:{item?.DIVISION_NAME}
																	<br />
																	{JSON.parse(AccountKey.filter((inac) => (inac?.UNIQUE_ID === item?.CAT0_ID))[0]?.PROFILE_NAME || '{}')?.CATEGORY4 || 'CATEGORY4'}:{item?.SUB_DIVISION_NAME}
																	<br />
																	{JSON.parse(AccountKey.filter((inac) => (inac?.UNIQUE_ID === item?.CAT0_ID))[0]?.PROFILE_NAME || '{}')?.CATEGORY5 || 'CATEGORY5'}:{item?.AREA_NAME}
																</div>} placement='top'><td><b className='tw-font-semibold'>ACCOUNT:</b><br />{item?.ACCOUNT_NAME}</td></BootstrapTooltip>
																<td>{item?.IMEI}</td>
																<td>{item?.CUSTOMER_ID}</td>
																<td>{item?.INSTALLATION_NUMBER}</td>
																<td>{item?.BUSINESS_PARTNER_NUMBER}</td>
																<td>{item?.DEVICE_SERIAL_NO}</td>
																<td>{item?.PANEL_SERIAL_NO}</td>
																
																{/* {console.log(getSecondsTodayBydate(item.HEARTBEAT_TS))} */}
																<BootstrapTooltip title={item.HEARTBEAT_TS ? moment.utc(item.HEARTBEAT_TS).format("DD-MM-YYYY hh:mm:ss A") : ''} placement="top"><td>{getSecondsTodayBydate(item.HEARTBEAT_TS) < 86400 ? <div className="badge badge-success badge-shadow text-white">{item?.HEARTBEAT_TS ? moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</div> : <div className="badge badge-danger badge-shadow text-white">{item?.HEARTBEAT_TS ? moment.utc(item.HEARTBEAT_TS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}</div>}</td></BootstrapTooltip>

																{/* <td className="text-center">
																	<div className={`badge ${item?.DEVICE_STATUS === 'online' ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item?.DEVICE_STATUS === 'online' ? 'Online' : 'Offline'}</div>
																</td> */}
																<td className="text-center">
																	<div className={`badge ${item?.CUSTOMER_STATUS == 1 ? 'badge-success' : item?.CUSTOMER_STATUS == -1 ? 'badge-warning' : item?.CUSTOMER_STATUS == 0 ? 'badge-danger' : item?.CUSTOMER_STATUS == 2 ? 'badge-info' : 'badge-dark'} badge-shadow text-white`}>{item?.CUSTOMER_STATUS == 1 ? 'Active' : item?.CUSTOMER_STATUS == -1 ? 'Pending Configuration' : item?.CUSTOMER_STATUS == 0 ? 'Inactive' : item?.CUSTOMER_STATUS == 2 ? 'Under Maintenance' : 'Stand By'}</div>
																</td>

																<td>
																	{typeof item?.TAGS == 'object' && item?.TAGS != 'null' && item?.TAGS?.length > 0 && item?.TAGS?.map((items) => (
																		<div className={`btn btn-icon btn-sm icon-left ml-1 mb-1`} style={{ color: "white", backgroundColor: items?.TAG_COLOR }}><i className="fa fa-tag fa-lg" style={{ marginRight: '4px' }} ></i><BootstrapTooltip title={<><div><span>Name:- </span>{items?.TAG_NAME}</div><div><span>Description:- </span>{items?.DESCRIPTION}</div></>} placement="top"><span className='show-read-more'>{items?.TAG_NAME}</span></BootstrapTooltip></div>
																	))}
																</td>
																<td>
																	<BootstrapTooltip title={'Info'} placement="top" className='Direction'><a href="#" className="btn btn-primary btn-sm font-12 mr-1" style={{ padding: "0px 4px 0px 4px" }}><i className="fa fa-info-circle " aria-hidden="true" ></i></a></BootstrapTooltip>
																	{item?.LONGITUDE && item?.LATITUDE ? <><BootstrapTooltip title={'Direction'} placement="top" className='Direction'><a href={`https://map.google.com/?q=${item?.LATITUDE},${item?.LONGITUDE}`} target="_blank" data-toggle="tooltip" data-placement="Top" title="" className="btn btn-success btn-sm text-white font-12 mr-1" data-original-title="Direction" style={{ padding: "0px 4px 0px 4px" }}> <i className="fas fa-directions" ></i></a></BootstrapTooltip></> : ''}

																	{item?.LONGITUDE && item?.LATITUDE ? <a href={`/gsm-map-view?${item?.UNIQUE_ID}`} target="_blank" className="btn btn-danger btn-sm text-white font-12 " style={{ padding: "0px 4px 0px 4px" }} > <i className="fas fa-map-marker-alt"></i></a> : ''}

																</td>
																{/* <th><BootstrapTooltip title='Info' placement='top'><a className="btn btn-primary btn-sm" style={{ background: '#6777ef', color: 'white', padding: '5px', borderRadius: '5px' }}><i className="fa fa-info-circle" aria-hidden="true"></i></a></BootstrapTooltip></th> */}
															</tr>
														))}


													</tbody>
												</table>
												{(getwayList.length === 0 && numberofPage === 0) && <div className='d-flex tw-justify-center tw-my-3'>
													<div >
														<NoDataFoundSvg />
														<div className='tw-my-1 tw-text-center'><b>No Data Found</b></div>
														<p className='tw-my-1 tw-text-center'>No data could be found that match your filter criteria. Clear your filter or try refining your criteria.</p>
													</div>
												</div>}
											</div>
										</div>
										<><div className='d-flex justify-content-between'>
											<div>Total Number Of Row {numberofPage}</div><Pagination
												className="pagination-bar"
												currentPage={currentPage}
												totalCount={numberofPage || 1}
												pageSize={PageSize}
												onPageChange={(page) => setCurrentPage(page)}
											/></div></>
									</div>
								</div>
							</div>
						</div>
					</div>
					</section>
					</div>
   </>
  )
}
